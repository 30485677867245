import Link from "@tiptap/extension-link";

import { tiptapExtensionPriority } from "@helpers/constants";

import { toggleLink } from "../helpers";

const CustomLink = Link.extend({
  name: "link",
  inclusive: false,
  priority: tiptapExtensionPriority.link,

  addKeyboardShortcuts() {
    return {
      "Mod-k": () => toggleLink(this.editor),
    };
  },
});

export default CustomLink;
