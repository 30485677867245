import { gql } from "@apollo/client";

import CommonFeedbackArtifactFragment from "@graphql/common-feedback-artifact-fragment";

export default gql`
  ${CommonFeedbackArtifactFragment}
  mutation createFeedbackRequestsMutation(
    $title: String!
    $description: JSONString
    $meetingId: Int
    $organizationId: Int!
    $feedbackAdminsCanView: Boolean
    $feedbackCreatorCanView: Boolean
    $feedbackRecipientsCanView: Boolean
    $feedbackRecipientsManagersCanView: Boolean
    $feedbackState: FeedbackState
    $feedbackRecipients: [Int]!
    $feedbackSenderIds: [Int]!
  ) {
    createFeedbackRequests(
      title: $title
      description: $description
      meetingId: $meetingId
      organizationId: $organizationId
      feedbackAdminsCanView: $feedbackAdminsCanView
      feedbackCreatorCanView: $feedbackCreatorCanView
      feedbackRecipientsCanView: $feedbackRecipientsCanView
      feedbackRecipientsManagersCanView: $feedbackRecipientsManagersCanView
      feedbackState: $feedbackState
      feedbackRecipients: $feedbackRecipients
      feedbackSenderIds: $feedbackSenderIds
    ) {
      feedbackArtifacts {
        id
        artifactType
        title
        ...CommonFeedbackArtifactFragment
      }
    }
  }
`;
