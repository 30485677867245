import { useCallback } from "react";

import getActionItemsCollapsibleQuery from "@apps/action-items-collapsible/graphql/get-action-items-collapsible-query";
import cache from "@cache/cache";
import usePusher from "@components/use-pusher/use-pusher";
import { getWatchedQueries } from "@graphql/client";

export default function PusherArtifactReordered({
  channelName,
}: {
  channelName: string;
}) {
  const handleArtifactReordered = useCallback(
    ({
      artifact,
      context_type,
      context_id,
    }: {
      artifact: { id: number };
      context_type: string;
      context_id: string | number;
    }) => {
      // If it's in the cache then we update
      const artifactCacheId = cache.identify(artifact);
      if (artifactCacheId) {
        const watchedQueries = getWatchedQueries(
          getActionItemsCollapsibleQuery
        );
        watchedQueries
          .filter(({ options }: any) => {
            return (
              options.variables.orderContextType === context_type &&
              options.variables.orderContextId === context_id
            );
          })
          .forEach((matchingWatchedQuery) => {
            matchingWatchedQuery.fetchMore({
              variables: {
                after: null,
                merge: true,
                first:
                  matchingWatchedQuery.getCurrentResult().data.artifacts.edges
                    .length,
              },
            });
          });
      }
    },
    []
  );

  usePusher(channelName, "artifact_reordered", handleArtifactReordered);

  return null;
}
