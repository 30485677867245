import { gql } from "@apollo/client";

import { MeetingViewMeetingGroupNewPageFragment } from "./get-meeting-group-query";
import { MeetingParticipantsNewPageFragment } from "./meeting-participants-fragment";
import TopicNodeFragment from "./topic-node-fragment";

const MeetingViewMeetingNodeFragment = gql`
  ${MeetingViewMeetingGroupNewPageFragment}
  ${MeetingParticipantsNewPageFragment}
  ${TopicNodeFragment}
  fragment MeetingViewMeetingNodeNewPageFragment on MeetingNode {
    id
    title
    startDatetime
    startDate
    endDatetime
    externalUrl
    videoConferenceType
    videoConferenceUrl
    allowExternalViewers
    writableSharingUrl
    websocketToken
    hasPreviousMeetings
    hasPreviousMeetingsWithTopics
    ignored
    hasBotInMeeting
    botIsRecording
    botIsWaitingToJoinMeeting
    canFinalize {
      permission
    }
    canDelete {
      permission
    }
    canUpdate {
      permission
    }
    finalizedAt
    isFinalized
    finalizedBy {
      id
      name
      avatar
    }
    isSubmitted
    hasIncompleteMandatoryTopics
    transcriptionStatus
    id
    created
    draft
    isRecurring
    organization {
      id
      name
      effectivePricingTier
      enableMeetingSummarization
      domains
    }
    organizer {
      id
      name
    }
    subject {
      id
    }
    creator {
      id
      name
      firstName
      lastName
      avatar
    }
    transcript {
      id
    }
    ...MeetingParticipantsNewPageFragment
    meetingGroup {
      ...MeetingViewMeetingGroupNewPageFragment
    }
    topics {
      edges {
        node {
          ...TopicNodeNewPageFragment
        }
      }
    }
  }
`;

export default MeetingViewMeetingNodeFragment;
