import { validate } from "email-validator";
import { uniqueId } from "lodash";
import { useCallback, useState } from "react";
import { BasicUser } from "types/topicflow";

import useUserComboboxQuery from "@components/user-combobox/use-user-combobox-query";
import UserCombobox from "@components/user-combobox/user-combobox";
import {
  UserComboboxOption,
  UserComboboxOptionType,
  UserComboboxUserOption,
} from "@components/user-combobox/user-combobox-list";

const DashboardTeamSettingsCombobox = ({
  relationshipUser,
  label,
  excludeUserIds,
  onChange,
}: {
  relationshipUser?: BasicUser | null;
  label: string;
  excludeUserIds: number[];
  onChange: (option: UserComboboxUserOption | null) => void;
}) => {
  const [canLoadData, setCanLoadData] = useState(false);

  const excludeIdsWithoutSelectedUser = excludeUserIds.filter(
    (id) => id !== relationshipUser?.id
  );

  // Hooks
  const { loading, options, query, setQuery } = useUserComboboxQuery({
    queryOptions: {
      skip: !canLoadData,
    },
    selected: relationshipUser
      ? { type: UserComboboxOptionType.USER, ...relationshipUser }
      : undefined,
    excludeUserIds: excludeIdsWithoutSelectedUser,
  });

  // Computed data
  const emailOptionFromQuery = {
    id: -parseInt(uniqueId()),
    name: query,
    email: query,
    avatar: null,
    invited: true,
    type: UserComboboxOptionType.USER,
  } as UserComboboxUserOption;
  const isNonExistingEmail =
    validate(query) &&
    !options.find(
      (option) =>
        option.type === UserComboboxOptionType.USER && option.email === query
    );
  const optionsWithInviteEmailOption = isNonExistingEmail
    ? [emailOptionFromQuery, ...options]
    : options;

  // Handlers
  const handleChangeValue = useCallback(
    (newOption: UserComboboxOption | null) => {
      if (
        newOption &&
        newOption.id &&
        newOption.type === UserComboboxOptionType.USER
      ) {
        onChange(newOption);
      } else {
        onChange(null);
      }
      setQuery("");
    },
    [onChange, setQuery]
  );

  const handleClearValue = () => {
    handleChangeValue(null);
  };

  const handlePreloadData = () => {
    setCanLoadData(true);
  };

  // RENDER
  return (
    <div className="w-56 text-sm">
      <UserCombobox
        searchPlaceholder="Name or email"
        placeholder="Add..."
        aria-label={`${label} combobox`}
        loading={loading}
        width="full"
        query={query}
        showEmail
        value={
          relationshipUser
            ? { type: UserComboboxOptionType.USER, ...relationshipUser }
            : null
        }
        options={optionsWithInviteEmailOption}
        clearable={!!relationshipUser}
        onChangeValue={handleChangeValue}
        onChangeQuery={setQuery}
        onClickButton={handlePreloadData}
        onClearValue={handleClearValue}
      />
    </div>
  );
};

export default DashboardTeamSettingsCombobox;
