import { gql } from "@apollo/client";

export default gql`
  mutation sendMessageToAskAIMutation(
    $chatSessionId: Int
    $meetingId: Int
    $message: String!
  ) {
    sendMessageToChatbot(
      chatSessionId: $chatSessionId
      meetingId: $meetingId
      message: $message
    ) {
      chatSession {
        id
      }
    }
  }
`;
