import { Popover } from "@headlessui/react";
import { FormEvent, useState } from "react";

import Button, { buttonTheme } from "@components/button/button";
import { meetingEditType } from "@helpers/constants";

export const meetingDialogInputClassName =
  "hover:bg-gray-100 rounded text-sm px-2 py-1.5 focus:outline-0 focus:ring-0";

const MeetingRecurringPopover = ({
  onSubmit,
  disabled,
  showAllEventsOption,
}: {
  onSubmit: ({ editType }: { editType: any }) => void;
  disabled: boolean;
  showAllEventsOption: boolean;
}) => {
  const [editType, setEditType] = useState(meetingEditType.thisEventAndFuture);

  const handleSubmitForm = (e: FormEvent) => {
    e.preventDefault();
    onSubmit({ editType });
  };

  return (
    <Popover className="relative">
      <>
        <Popover.Button
          as={Button}
          theme={buttonTheme.primary}
          className="w-full"
          disabled={disabled}
        >
          Save changes
        </Popover.Button>
        <Popover.Panel
          className="absolute bottom-12 left-1/2 z-dropdown w-96 -translate-x-1/2 transform"
          aria-label="Meeting dialog recurring changes popover"
        >
          {({ close }) => (
            <div className="rounded-lg drop-shadow-lg bg-white p-4 border flex flex-col gap-4">
              <div className="font-medium">Edit recurring event</div>
              <div className="flex flex-col gap-2 text-sm">
                <label className="flex items-center gap-2">
                  <input
                    type="radio"
                    name="recurring-event-changes"
                    value={meetingEditType.singleEvent}
                    checked={editType === meetingEditType.singleEvent}
                    onChange={() => setEditType(meetingEditType.singleEvent)}
                  />{" "}
                  This meeting
                </label>
                <label className="flex items-center gap-2">
                  <input
                    type="radio"
                    name="recurring-event-changes"
                    value={meetingEditType.thisEventAndFuture}
                    checked={editType === meetingEditType.thisEventAndFuture}
                    onChange={() =>
                      setEditType(meetingEditType.thisEventAndFuture)
                    }
                  />{" "}
                  This and future meetings
                </label>

                {showAllEventsOption && (
                  <label className="flex items-center gap-2">
                    <input
                      type="radio"
                      name="recurring-event-changes"
                      value={meetingEditType.allEvents}
                      checked={editType === meetingEditType.allEvents}
                      onChange={() => setEditType(meetingEditType.allEvents)}
                    />{" "}
                    All meetings
                  </label>
                )}
              </div>
              <div className="flex gap-2">
                <Button
                  text="Cancel"
                  theme={buttonTheme.default}
                  className="w-1/2"
                  onClick={() => close()}
                />
                <Button
                  text="Apply"
                  type="submit"
                  theme={buttonTheme.primary}
                  className="w-1/2"
                  onClick={(e) => {
                    handleSubmitForm(e);
                    close();
                  }}
                />
              </div>
            </div>
          )}
        </Popover.Panel>
      </>
    </Popover>
  );
};

export default MeetingRecurringPopover;
