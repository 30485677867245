import { gql } from "@apollo/client";

export default gql`
  mutation saveAssessmentDelivery(
    $assessmentDeliveryId: Int!
    $excludedAnswerIds: [Int]
    $summary: String
    $state: AssessmentDeliveryState
  ) {
    updateAssessmentDelivery(
      assessmentDeliveryId: $assessmentDeliveryId
      excludedAnswerIds: $excludedAnswerIds
      summary: $summary
      state: $state
    ) {
      assessmentDelivery {
        id
      }
    }
  }
`;
