import { gql } from "@apollo/client";

export default gql`
  fragment SyncCredentialFragment on UserSyncCredentialsNode {
    id
    provider
    credentialsUid
    hasValidCalendarCredentials
    hasCalendarWriteCredentials
    organization {
      id
      domains
      personal
    }
  }
`;
