import { gql } from "@apollo/client";

import KeyResultFragment from "@graphql/key-result-fragment";

export default gql`
  ${KeyResultFragment}
  mutation manageGoalKeyResult(
    $keyResultId: Int
    $goalId: Int
    $title: String
    $startValue: Float
    $targetValue: Float
    $progressType: GoalProgressType
    $assigneeId: Int
    $kpiId: Int
  ) {
    createOrUpdateKeyResult(
      keyResultId: $keyResultId
      goalId: $goalId
      title: $title
      startValue: $startValue
      targetValue: $targetValue
      progressType: $progressType
      assigneeId: $assigneeId
      kpiId: $kpiId
    ) {
      keyResult {
        ...KeyResultFragment
      }
      goal {
        id
        ... on GoalArtifactNode {
          contributors {
            edges {
              node {
                id
                name
                avatar
              }
            }
          }
          keyResults {
            edges {
              node {
                id
              }
            }
          }
        }
      }
    }
  }
`;
