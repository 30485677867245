import { Node, mergeAttributes } from "@tiptap/core";
import { Editor, ReactNodeViewRenderer } from "@tiptap/react";
import { NodeViewWrapper } from "@tiptap/react";

import { currentUserVar } from "@cache/cache";
import {
  getPluginClickOn,
  getPluginHandlePaste,
} from "@components/wysiwyg/helpers";
import { tiptapExtensionPriority } from "@helpers/constants";
import { isValidUrl } from "@helpers/helpers";

import KpiPlaceholder from "./kpi-placeholder";
import SelectKpiForm from "./select-kpi-form";
import SingleKpiView from "./single-kpi-view";

const KpiEmbedComponent = ({
  node,
  updateAttributes,
  extension,
  deleteNode,
  selected,
  editor,
}: {
  node: any;
  updateAttributes: (attrs: any) => void;
  extension: any;
  deleteNode: () => void;
  selected: boolean;
  editor: Editor;
}) => {
  const currentUser = currentUserVar();
  return (
    <NodeViewWrapper className="mb-3">
      {!node.attrs.id && currentUser.id === node.attrs.createdByUser?.id ? (
        <SelectKpiForm
          updateAttributes={updateAttributes}
          deleteNode={deleteNode}
          editor={editor}
        />
      ) : !node.attrs.id && currentUser.id !== node.attrs.createdByUser?.id ? (
        <KpiPlaceholder node={node} />
      ) : (
        <SingleKpiView
          selected={selected}
          updateAttributes={updateAttributes}
          deleteNode={deleteNode}
          node={node}
          extension={extension}
        />
      )}
    </NodeViewWrapper>
  );
};

const KpiEmbedExtension = Node.create({
  name: "kpi-embed",
  group: "block",
  marks: "",
  draggable: true,
  selectable: true,
  allowGapCursor: true,
  atom: true,
  isolating: true,
  defining: true,

  addAttributes() {
    // Return an object with attribute configuration
    return {
      id: {
        default: null,
        parseHTML: (node: HTMLAnchorElement) => {
          const regexp = new RegExp(`^${window.location.origin}/kpis/(\\d+)$`);
          const regexpResult = regexp.exec(node.href);
          if (!regexpResult) {
            return null;
          }
          return regexpResult[1];
        },
      },
      // used to show placeholder when a user creates a kpi in CRDT
      createdByUser: {
        default: null,
      },
      summaryPeriod: null,
      viewMode: "table",
    };
  },

  addOptions() {
    return {
      relatedArtifactId: null,
      topicId: null,
      meetingId: null,
    };
  },

  parseHTML() {
    return [
      {
        tag: "a",
        priority: tiptapExtensionPriority.explorerLink,
        getAttrs: (node: HTMLElement | string) => {
          if (node && node instanceof HTMLAnchorElement) {
            if (!node.href) {
              return false;
            }
            const regexp = new RegExp(
              `^${window.location.origin}/kpis/(\\d+)$`
            );
            const regexpResult = regexp.exec(node.href);
            if (!regexpResult) {
              return false;
            }
            // if a link created in wysiwyg, we don't embed it
            if (node.classList.contains("js-wysiwyg-link")) {
              return false;
            }
            // only embed if link text is same as href
            if (node.innerText !== node.href) {
              return false;
            }
            return null;
          }
          return false;
        },
      },
    ];
  },

  addNodeView() {
    return ReactNodeViewRenderer(KpiEmbedComponent);
  },

  renderText({ node }) {
    return `${window.location.origin}/kpis/${node.attrs.id}`;
  },

  renderHTML({ HTMLAttributes }) {
    return ["div", mergeAttributes(HTMLAttributes)];
  },

  addCommands() {
    return {
      insertKPIEmbed:
        (attrs: any) =>
        ({ chain }: { chain: () => any }) => {
          return chain().insertContent({ type: this.name, attrs }).run();
        },
    };
  },

  addProseMirrorPlugins() {
    return [
      getPluginHandlePaste(this, (url, view) => {
        if (!url || !isValidUrl(url)) {
          return false;
        }
        const regexp = new RegExp(`^${window.location.origin}/kpis/(\\d+)$`);
        const regexpResult = regexp.exec(url);
        if (!regexpResult) {
          return false;
        }
        const id = regexpResult[1];
        if (!id) {
          return false;
        }
        const currentUser = currentUserVar();
        const { tr } = view.state;
        tr.replaceSelectionWith(
          this.type.create({
            id,
            createdByUser: {
              id: currentUser.id,
              name: currentUser.name,
              avatar: currentUser.avatar,
            },
          })
        );
        view.dispatch(tr);
        return true;
      }),
      getPluginClickOn(this.name),
    ];
  },
});

export default KpiEmbedExtension;
