import Heading from "@components/heading/heading";

const DirectorySyncItem = ({
  directorySyncer,
  orgName,
}: {
  directorySyncer: any;
  orgName: any;
}) => {
  return (
    <div>
      <Heading title={`Syncing organization ${orgName}`} small />
      <p>Directory name: {directorySyncer.name}</p>
      <p>Directory type: {directorySyncer.type}</p>
      <p>Sync status: {directorySyncer.state}</p>
    </div>
  );
};

export default DirectorySyncItem;
