import { gql } from "@apollo/client";

export default gql`
  mutation removeSlackIntegrationMutation($organizationId: Int!) {
    removeSlackIntegration(organizationId: $organizationId) {
      organization {
        id
        hasSlackConnection
      }
    }
  }
`;
