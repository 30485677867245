import { gql } from "@apollo/client";

export default gql`
  query getAssessmentQuestionCategories {
    assessmentQuestionCategories {
      edges {
        node {
          id
          title
        }
      }
    }
  }
`;
