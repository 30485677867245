import {
  ExplorerFilterType,
  explorerGithubType,
  explorerHubspotType,
  explorerJiraType,
  isIntegrationSetup,
} from "../helpers";

const ExplorerIntegrationsNeedQuery = ({
  filters,
  className = "",
}: {
  filters: ExplorerFilterType;
  className?: string;
}) => {
  let title = "Please enter a search query.";
  let docUrl = null;
  let docLink = null;
  if (filters.type === explorerGithubType && filters.search === "") {
    title = "Please enter a Github search query.";
    docUrl =
      "https://docs.github.com/en/search-github/searching-on-github/searching-issues-and-pull-requests";
    docLink = "Github documentation for searching issues and pull requests";
  } else if (!isIntegrationSetup(filters.type)) {
    title =
      filters.type === explorerGithubType
        ? "Please setup the Github integration in your account settings."
        : filters.type === explorerJiraType
        ? "Please setup the Jira integration in your account settings."
        : filters.type === explorerHubspotType
        ? "Please setup the Hubspot integration in your account settings."
        : "Please setup integration";
    docUrl = "/account/integrations";
    docLink = "Account settings";
  }

  return (
    <div className={className}>
      <div className="py-4 flex flex-col gap-3 w-full">
        <div>{title}</div>
        {docUrl && (
          <div>
            <a
              className="text-blue-link hover:underline text-xs tracking-tight"
              href={docUrl}
            >
              {docLink}
            </a>
            .
          </div>
        )}
      </div>
    </div>
  );
};

export default ExplorerIntegrationsNeedQuery;
