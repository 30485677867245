import { RecognitionArtifactComponentFragmentFragment } from "types/graphql-schema";

import useLabel from "@apps/use-label/use-label";
import Avatar from "@components/avatar/avatar";
import Avatars from "@components/avatar/avatars";
import { classNames } from "@helpers/css";
import { assertEdgesNonNull } from "@helpers/helpers";

const ArtifactRecognitionRecipients = ({
  artifact,
}: {
  artifact: RecognitionArtifactComponentFragmentFragment;
}) => {
  const label = useLabel();

  const recipients = artifact.recipients
    ? assertEdgesNonNull(artifact.recipients)
    : [];
  return artifact.recipients?.totalCount === 1 ? (
    <Avatar
      user={recipients[0]}
      size="5"
      tooltipSuffix={` received a ${label("recognition")}`}
    />
  ) : (artifact.recipients?.totalCount || 0) > 1 ? (
    <Avatars
      max={4}
      users={recipients}
      className={classNames(
        "flex items-center",
        recipients.length === 1 ? "w-5" : "pl-2" // otherwise single avatar gets squished
      )}
      avatarClassName={classNames(
        "contrast-100 rounded-full ring-2 -ml-1 ring-gray-200"
      )}
      extraClassName="w-6 h-6 rounded-full -ml-1.5 text-2xs flex items-center justify-center z-1 bg-gray-300 text-gray-600"
      size="5"
    />
  ) : null;
};

export default ArtifactRecognitionRecipients;
