import { gql } from "@apollo/client";

export default gql`
  mutation createOrUpdateMeetingTopicTemplateMutation(
    $title: String!
    $organization: Int!
    $topics: [TemplateTopicInput]!
  ) {
    createOrUpdateTopicTemplate(
      title: $title
      organization: $organization
      publicTemplate: true
      topics: $topics
    ) {
      topicTemplate {
        id
        title
        description
        publicTemplate
        topics {
          edges {
            node {
              id
              title
              description
              discussionNotes
              includesSharedNotes
              includesIndividualNotes
            }
          }
        }
      }
    }
  }
`;
