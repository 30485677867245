import { useQuery } from "@apollo/client";
import { Popover } from "@headlessui/react";
import { PlusCircleIcon, XCircleIcon } from "@heroicons/react/outline";
import a from "indefinite";
import { useState } from "react";
import {
  GetAllOrganizationTeamsQueryQuery,
  GetAllOrganizationTeamsQueryQueryVariables,
} from "types/graphql-schema";

import useLabel from "@apps/use-label/use-label";
import { currentOrganizationVar } from "@cache/cache";
import ComboboxGeneric, {
  ComboboxGenericOption,
} from "@components/combobox/generic-combobox";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { assertEdgesNonNull } from "@helpers/helpers";

import getAllOrganizationTeamsQuery from "../graphql/get-all-organization-teams-query";

export type ArtifactTeamType = {
  id: number;
  title: string;
};

const ArtifactTeamsInput = ({
  teams,
  onRemoveTeam,
  onAddTeam,
  canEdit = false,
  dropdownWidth = "48",
}: {
  teams: ArtifactTeamType[];
  onRemoveTeam: (id: number) => void;
  onAddTeam: (team: ArtifactTeamType) => void;
  canEdit?: boolean;
  dropdownWidth?: string;
}) => {
  const label = useLabel();
  const currentOrganization = currentOrganizationVar();
  const [hasBeenOvered, setHasBeenOvered] = useState(false);
  const { loading, data, refetch } = useQuery<
    GetAllOrganizationTeamsQueryQuery,
    GetAllOrganizationTeamsQueryQueryVariables
  >(getAllOrganizationTeamsQuery, {
    variables: { organizationId: currentOrganization.id },
    skip: !hasBeenOvered,
    onError: onNotificationErrorHandler(),
  });
  const selectedTeamIds = teams.map(({ id }) => id);
  const allTeams = data?.organization?.teams
    ? assertEdgesNonNull(data.organization.teams)
    : [];
  const options = allTeams.map((team) => ({
    value: team.id,
    label: team.title,
  }));

  const handleOnOver = () => {
    if (hasBeenOvered) {
      refetch();
    } else {
      setHasBeenOvered(true);
    }
  };

  const handleSelectTeam = (option: ComboboxGenericOption<number>) => {
    onAddTeam({ id: option.value, title: option.label });
  };

  const validOptions = options.filter(
    ({ value }) => !selectedTeamIds.includes(value)
  );

  return (
    <div className="flex gap-2 flex-wrap items-center">
      {teams.map((team) => (
        <div
          key={team.id}
          className="relative flex items-center gap-2 rounded-full border px-2 text-sm text-emerald-700 group"
        >
          <span>{team.title}</span>
          {canEdit && (
            <button
              aria-label="Remove team button"
              onClick={() => onRemoveTeam(team.id)}
              className="absolute -right-2 -top-2 opacity-0 group-hover:opacity-100 text-gray-400 hover:text-gray-600 bg-gray-50 hover:bg-gray-100 rounded-full shrink-0"
            >
              <XCircleIcon className="h-5 w-5" />
            </button>
          )}
        </div>
      ))}
      <div className="max-w-96">
        {canEdit ? (
          <ComboboxGeneric<number>
            aria-label="Team dropdown"
            placeholder={`Search ${label("team", { pluralize: true })}`}
            loading={loading}
            width={dropdownWidth}
            value={null}
            options={validOptions}
            onChangeValue={handleSelectTeam}
            onClickButton={handleOnOver}
          >
            {({ setReferenceElement, onClickButton }) => (
              <div className={"flex justify-between bg-white rounded-md"}>
                <Popover.Button
                  className="text-gray-500 hover:text-gray-800 hover:underline flex gap-1"
                  aria-label="Add team button"
                  onClick={onClickButton}
                  ref={setReferenceElement}
                >
                  <PlusCircleIcon className="h-5 w-5 flex-none" />
                  {selectedTeamIds.length === 0 && (
                    <div className="text-gray-500 text-sm">
                      Add {a(label("team"))}
                    </div>
                  )}
                </Popover.Button>
              </div>
            )}
          </ComboboxGeneric>
        ) : null}
      </div>
    </div>
  );
};

export default ArtifactTeamsInput;
