import { gql } from "@apollo/client";

import { TopicDataFragment } from "@graphql/fragments";

export default gql`
  ${TopicDataFragment}
  mutation addTemplateToMeetingMutation(
    $meetingId: Int
    $meetingGroupId: Int
    $topicTemplateId: Int!
    $setAsSeriesTemplate: Boolean!
    $allowAdminVisibility: Boolean
    $allowOrgVisibility: Boolean
    $allowManagementTreeVisibility: Boolean
  ) {
    addTemplateToMeeting(
      meetingId: $meetingId
      meetingGroupId: $meetingGroupId
      topicTemplateId: $topicTemplateId
      allowAdminVisibility: $allowAdminVisibility
      allowOrgVisibility: $allowOrgVisibility
      allowManagementTreeVisibility: $allowManagementTreeVisibility
      setAsSeriesTemplate: $setAsSeriesTemplate
    ) {
      meeting {
        id
        topics {
          edges {
            node {
              ...TopicDataFragment
            }
          }
        }
      }
      meetingGroup {
        id
        allowAdminVisibility
        allowOrgVisibility
        allowManagementTreeVisibility
        templateSeries {
          edges {
            node {
              id
              topicTemplates {
                edges {
                  node {
                    id
                    topicTemplate {
                      id
                      title
                      description
                      publicTemplate
                      topics {
                        edges {
                          node {
                            id
                            title
                            description
                            includesIndividualNotes
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
