import cache from "@cache/cache";

export default function removeTopicFromMeeting({
  topic,
  meeting,
}: {
  topic: any;
  meeting: any;
}) {
  const topicCacheId = cache.identify(topic);
  const meetingCacheId = cache.identify(meeting);

  // remove topic into meeting.topics object
  cache.modify({
    id: meetingCacheId,
    fields: {
      topics(cachedTopics) {
        return {
          edges: cachedTopics.edges.filter(
            (edge: any) => edge.node.__ref !== topicCacheId
          ),
        };
      },
    },
  });
}
