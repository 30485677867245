import { useQuery } from "@apollo/client";
import sortBy from "lodash/sortBy";
import moment from "moment";
import {
  GetCommentsOfRelatedTopicQuery,
  GetCommentsOfRelatedTopicQueryVariables,
} from "types/graphql-schema";

import GraphqlError from "@components/error/graphql-error";
import Loading from "@components/loading/loading";
import { onNotificationErrorHandler } from "@components/use-error/use-error";

import Comment from "./components/comment";
import getCommentsOfRelatedTopicQuery from "./graphql/get-comments-of-related-topic-query";

const CommentsOfRelatedTopic = ({
  relatedTopic,
  selectedCommentId,
}: {
  relatedTopic: any;
  selectedCommentId?: number;
}) => {
  const { loading, error, data } = useQuery<
    GetCommentsOfRelatedTopicQuery,
    GetCommentsOfRelatedTopicQueryVariables
  >(getCommentsOfRelatedTopicQuery, {
    variables: { topicId: relatedTopic.id },
    onError: onNotificationErrorHandler(),
  });

  // Render
  if (error) {
    return (
      <GraphqlError
        error={error}
        whatDidNotWork="The comments could not be loaded."
      />
    );
  }
  if (!data && loading) {
    return <Loading />;
  }
  const comments = sortBy(
    data?.comments?.edges ?? [],
    ({ node }: { node: any }) => -moment(node.created).valueOf()
  );

  return (
    <div>
      <h2 className="text-lg font-medium mb-4 text-gray-700 flex items-center justify-between">
        Comments
        {loading && (
          <div className="text-xs font-normal text-gray-500 flex items-center">
            <span className="mr-2">Refreshing</span>
            <Loading mini size="4" />
          </div>
        )}
      </h2>

      <ul aria-label="Comments">
        {comments.map(({ node: comment }: any, index) => (
          <Comment
            key={comment.id}
            topic={relatedTopic}
            comment={comment}
            currentUserId={data?.me?.id}
            selected={selectedCommentId === comment.id.toString()}
            isLastComment={index === comments.length - 1}
          />
        ))}
      </ul>
    </div>
  );
};

export default CommentsOfRelatedTopic;
