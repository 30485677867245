import { useMutation } from "@apollo/client";
import moment from "moment";
import numeral from "numeral";
import { useState } from "react";
import { KpiMeasurementTableFragment } from "types/graphql-schema";

import { TableBodyCell, TableBodyRow } from "@components/table/table";
import TimeAgoCustom from "@components/time-ago/time-ago";
import { onNotificationErrorHandler } from "@components/use-error/use-error";

import deleteKpiMeasurementMutation from "../graphql/delete-kpi-measurement-mutation";
import KPIAddMeasurementDialog from "./add-measurement-dialog";

const KPIViewMeasurement = ({
  measurement,
  kpiId,
}: {
  kpiId: number;
  measurement: KpiMeasurementTableFragment;
}) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [deleteKpiMeasurement, { loading }] = useMutation(
    deleteKpiMeasurementMutation
  );

  const handleClickDelete = () => {
    if (
      window.confirm("Are you sure you want to delete this KPI measurement?")
    ) {
      deleteKpiMeasurement({
        variables: {
          measurementId: measurement.id,
        },
        update(cache) {
          const cacheId = cache.identify(measurement);
          cache.evict({ id: cacheId });
          cache.gc();
        },
        onError: onNotificationErrorHandler(),
      });
    }
  };

  const handleClickEdit = () => {
    setShowEditModal(true);
  };

  // RENDER
  return (
    <TableBodyRow key={measurement.id}>
      <TableBodyCell className="text-gray-800 w-72">
        {moment(measurement.timestamp).format("LLLL")}
      </TableBodyCell>
      <TableBodyCell className="text-gray-800 w-72">
        <TimeAgoCustom date={measurement.created} />
      </TableBodyCell>
      <TableBodyCell>
        {numeral(measurement.measurement).format("0[.]00a")}
      </TableBodyCell>
      <TableBodyCell>
        <div className="flex justify-end gap-6">
          {showEditModal && (
            <KPIAddMeasurementDialog
              kpiId={kpiId}
              onClose={() => setShowEditModal(false)}
              measurementId={measurement.id}
              measurement={String(measurement.measurement)}
              date={measurement.timestamp}
              open
            />
          )}
          {measurement.canUpdate?.permission && (
            <button
              disabled={loading}
              onClick={handleClickEdit}
              className="text-blue-link hover:underline"
            >
              Edit
            </button>
          )}
          {measurement.canDelete?.permission && (
            <button
              disabled={loading}
              onClick={handleClickDelete}
              className="text-blue-link hover:underline"
            >
              Delete
            </button>
          )}
        </div>
      </TableBodyCell>
    </TableBodyRow>
  );
};

export default KPIViewMeasurement;
