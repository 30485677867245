import { useQuery } from "@apollo/client";
import { PlusCircleIcon } from "@heroicons/react/outline";
import { ArrowSmLeftIcon } from "@heroicons/react/solid";
import { compact } from "lodash";
import { useCallback, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  GetKpisQueryQuery,
  GetKpisQueryQueryVariables,
  KpiSummaryPeriod,
  KpiVisibility,
} from "types/graphql-schema";

import useUiPreferenceCache from "@apps/use-ui-preference-cache/use-ui-preference-cache";
import Input from "@components/input/input";
import AppLink from "@components/link/link";
import Loading from "@components/loading/loading";
import Select, { SelectOption } from "@components/select/select";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { assertEdgesNonNull } from "@helpers/helpers";

import getKpisQuery from "../graphql/get-kpis-query";
import { kpiSummaryPagination, kpiSummaryPeriodOptions } from "../helpers";
import KPISummaryTable from "./summary-table";

const KPIList = () => {
  const location = useLocation();
  const urlQueries = new URLSearchParams(location.search);
  const kpiGroupId = urlQueries.get("kpiGroup")
    ? String(urlQueries.get("kpiGroup"))
    : null;

  const [search, setSearch] = useState("");
  const { uiPreferenceCache, saveUiPreference } = useUiPreferenceCache();
  const { data, loading } = useQuery<
    GetKpisQueryQuery,
    GetKpisQueryQueryVariables
  >(getKpisQuery, {
    variables: {
      first: kpiSummaryPagination,
      kpiSummaryPeriod: uiPreferenceCache.kpiListSummaryPeriod,
    },
    onError: onNotificationErrorHandler(),
  });

  const handleChangeKpiSummaryPeriod = useCallback(
    ({ value }: SelectOption<KpiSummaryPeriod>) => {
      saveUiPreference({ kpiListSummaryPeriod: value });
    },
    [saveUiPreference]
  );

  const kpis = (data?.kpis ? assertEdgesNonNull(data.kpis) : []).filter(
    (kpi) => {
      if (search === "") return true;
      return kpi.title.toLowerCase().includes(search.toLowerCase());
    }
  );
  const personalKpis = kpis.filter(
    ({ visibility }) => visibility === KpiVisibility.Personal
  );
  const organizationalKpis = kpis.filter(
    ({ visibility }) => visibility === KpiVisibility.Org
  );
  const teamKpis = kpis.filter(
    ({ visibility }) => visibility === KpiVisibility.Teams
  );
  const kpiGroups = (
    data?.kpiGroups ? assertEdgesNonNull(data.kpiGroups) : []
  ).map((kpiGroup) => ({
    kpiGroup: kpiGroup,
    tableTitle: kpiGroup.title,
    collapsedId: kpiGroup.id,
    kpis: kpis.filter((kpi) => {
      const groups = assertEdgesNonNull(kpi.groups);
      return groups.find((node) => kpiGroup.id === node.id);
    }),
  }));

  const kpisByTeamId = teamKpis.reduce(
    (memo, teamKpi) => {
      const teams = assertEdgesNonNull(teamKpi.teams);
      for (const team of teams) {
        if (!memo[team.id]) {
          memo[team.id] = {
            kpiGroup: null,
            id: team.id,
            tableTitle: team.title,
            collapsedId: `team-${team.id}`,
            kpis: [],
          };
        }
        memo[team.id].kpis = memo[team.id].kpis.concat(teamKpi);
      }

      return memo;
    },
    {} as {
      [key: number]: {
        kpiGroup: null;
        id: number;
        collapsedId: string;
        tableTitle: string;
        kpis: any[];
      };
    }
  );
  const teamKpiGroups = Object.values(kpisByTeamId);
  const kpiTables =
    compact([
      organizationalKpis.length && {
        kpiGroup: null,
        kpis: organizationalKpis,
        tableTitle: "Organization KPIs",
        collapsedId: "organization",
      },
      personalKpis.length && {
        kpiGroup: null,
        kpis: personalKpis,
        tableTitle: "Only you",
        collapsedId: "personal",
      },
      ...teamKpiGroups,
      ...kpiGroups,
    ]).filter(
      (kpiTable) => !kpiGroupId || kpiGroupId === String(kpiTable.collapsedId)
    ) || [];

  // RENDER
  return (
    <div className="w-full flex flex-col gap-6" aria-label="KPIs list">
      {!data && loading && (
        <div className="">
          <Loading>Loading KPIs...</Loading>
        </div>
      )}

      {data && (
        <div className="p-6 bg-white rounded-lg shadow-sm flex flex-col gap-6">
          <div className="flex w-full gap-4 items-center justify-between">
            <Input
              className="w-64"
              type="text"
              placeholder="Search KPIs..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <div className="flex items-center gap-2">
              <Select<KpiSummaryPeriod>
                options={kpiSummaryPeriodOptions}
                value={uiPreferenceCache.kpiListSummaryPeriod}
                onChange={handleChangeKpiSummaryPeriod}
              />
            </div>
          </div>

          {kpis.length || kpiGroups.length ? (
            <div className="flex flex-col gap-6">
              {kpiGroupId && (
                <div>
                  <AppLink
                    to="/kpis"
                    className="text-sm hover:underline text-gray-600 flex gap-2 items-center"
                  >
                    <ArrowSmLeftIcon className="h-4 w-4" />
                    Show all KPI groups
                  </AppLink>
                </div>
              )}
              {kpiTables.map((kpiTable) => (
                <KPISummaryTable
                  collapsedId={kpiTable.collapsedId}
                  kpis={kpiTable.kpis}
                  tableTitle={kpiTable.tableTitle}
                  kpiGroup={kpiTable.kpiGroup}
                  key={kpiTable.collapsedId}
                />
              ))}
            </div>
          ) : (
            <div className="text-gray-500">
              <AppLink
                to="/kpis/new"
                className="text-blue-link hover:underline text-sm flex gap-1 items-center"
              >
                <PlusCircleIcon className="h-4 w-4" />
                Add your first KPI
              </AppLink>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default KPIList;
