import { useMutation } from "@apollo/client";
import { MenuItem } from "@szhsin/react-menu";
import { TbToggleLeft, TbToggleRightFilled } from "react-icons/tb";

import { onNotificationErrorHandler } from "@components/use-error/use-error";

import updateTopicMutation from "../graphql/update-topic-mutation";

const ToggleSharedNotesMenuItem = ({
  topic,
  meeting,
  meetingGroup,
  currentPageMeeting,
  ...props
}: {
  topic: any;
  meeting: any;
  meetingGroup?: any;
  currentPageMeeting?: any;
}) => {
  const [updateTopic] = useMutation(updateTopicMutation);

  const handleToggleSharedNotes = () => {
    const includesSharedNotes = !topic.includesSharedNotes;
    updateTopic({
      variables: {
        topicId: topic.id,
        includesSharedNotes,
      },
      onError: onNotificationErrorHandler(),
      optimisticResponse: {
        createOrUpdateTopic: {
          topic: {
            ...topic,
            includesSharedNotes,
          },
        },
      },
    });
  };

  // Render
  return (
    <MenuItem className="text-sm" onClick={handleToggleSharedNotes} {...props}>
      {topic.includesSharedNotes ? (
        <TbToggleRightFilled className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
      ) : (
        <TbToggleLeft className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
      )}{" "}
      {topic.includesSharedNotes ? "Hide" : "Show"} shared notes
    </MenuItem>
  );
};

export default ToggleSharedNotesMenuItem;
