import { gql } from "@apollo/client";

import ArtifactComponentFragment from "@apps/artifact/graphql/artifact-component-fragment";

export default gql`
  ${ArtifactComponentFragment}
  query getFavouriteArtifactsQuery {
    me {
      id
      favouriteArtifacts {
        edges {
          node {
            ...ArtifactComponentFragment
          }
        }
      }
    }
  }
`;
