import { gql } from "@apollo/client";

export default gql`
  query getOrgWideCompetency($competencyId: Int!) {
    competency(id: $competencyId) {
      id
      title
      description
      appliesTo
      appliesToManagementRoles
      appliesToIcRoles
      appliesToTeams {
        edges {
          node {
            id
            title
          }
        }
      }
      appliesToCareerTracks {
        edges {
          node {
            id
          }
        }
      }
      criteriaUniqueness
      criteria {
        edges {
          node {
            id
            content
            level
          }
        }
      }
    }
  }
`;
