import { gql } from "@apollo/client";

import CommonGoalArtifactFragment from "@graphql/common-goal-artifact-fragment";
import CommonRecognitionArtifactFragment from "@graphql/common-recognition-artifact-fragment";

export const ExplorerMeetingResultFragment = gql`
  fragment ExplorerMeetingResultFragment on MeetingWithRelevantSectionsNode {
    meeting {
      id
      title
      startDatetime
      meetingGroupId
      meetingGroup {
        id
        isFormalOneonone
      }
      participants(first: 2) {
        totalCount
        edges {
          node {
            id
            user {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const ExplorerBaseArtifactFragment = gql`
  ${CommonGoalArtifactFragment}
  ${CommonRecognitionArtifactFragment}
  fragment ExplorerBaseArtifactFragment on BaseArtifactNode {
    id
    specificArtifact {
      id
      artifactType
      title
      created
      updated
      creator {
        id
        name
        avatar
      }
      canDelete {
        permission
      }
      canUpdate {
        permission
      }
      ... on ActionItemArtifactNode {
        isComplete
        actionItemState
        dueDate
        assignee {
          id
          avatar
          name
        }
      }
      ... on GoalArtifactNode {
        ...CommonGoalArtifactFragment
        activities(first: 1, activityType: goal_checkin) {
          edges {
            node {
              created
            }
          }
        }
      }
      ... on DecisionArtifactNode {
        decision
        decisionState
        isDraft
      }
      ... on RecognitionArtifactNode {
        ...CommonRecognitionArtifactFragment
      }
    }
  }
`;

export const ExplorerResultFragment = gql`
  ${ExplorerBaseArtifactFragment}
  ${ExplorerMeetingResultFragment}
  fragment ExplorerResultFragment on SearchUnion {
    ... on MeetingWithRelevantSectionsNode {
      ...ExplorerMeetingResultFragment
    }
    ... on UserNode {
      id
      name
      email
      avatar
    }
    ... on GithubIssueNode {
      id
      title
      url
      repository
      assignees {
        username
        url
      }
      closedAt
      isMerged
    }
    ... on JiraIssueNode {
      id
      title
      url
      assignees {
        username
        url
      }
      closedAt
    }
    ... on HubspotDealNode {
      id
      title
      url
      assignees {
        username
        url
      }
      closedAt
      amount
      closedWon
      stageName
    }
    ...ExplorerBaseArtifactFragment
  }
`;

export default gql`
  ${ExplorerResultFragment}
  query getExplorerResultsQuery(
    $limit: Int!
    $after: String
    $search: String!
    $options: SearchOptions
  ) {
    search(
      searchTerm: $search
      options: $options
      first: $limit
      after: $after
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ...ExplorerResultFragment
        }
      }
    }
  }
`;
