import * as Sentry from "@sentry/react";
import { ArtifactType, GoalScope, GoalState } from "types/graphql-schema";
import { BasicUser } from "types/topicflow";

import useLabel from "@apps/use-label/use-label";
import { currentUserVar } from "@cache/cache";
import Error from "@components/error/error";
import Layout from "@components/layout/layout";

import GoalsCollapsibleContainer from "./goals-collapsible-container";

const Goals = ({
  selectedUser,
  goalDueBetweenDates,
}: {
  selectedUser: BasicUser;
  goalDueBetweenDates?: string[];
}) => {
  const currentUser = currentUserVar();
  const label = useLabel();

  return (
    <>
      <Layout.SidebarSection
        aria-label="Dashboard goal container"
        expandedUiPreferenceKey="dashboardSidebarGoalContainerExpanded"
        title={
          selectedUser.id === currentUser.id
            ? label("goal", { pluralize: true, capitalize: true })
            : `${selectedUser.firstName || selectedUser.name}'s ${label(
                "goal",
                {
                  pluralize: true,
                  capitalize: true,
                }
              )}`
        }
        createArtifactFormOptions={{
          artifactType: ArtifactType.Goal,
          owners: [selectedUser],
        }}
      >
        <GoalsCollapsibleContainer
          title={"Individual"}
          filters={{
            fetchData: true,
            goalScopes: [GoalScope.Career, GoalScope.Personal],
            goalInvolvingUsers: [selectedUser.id],
            goalStates: [GoalState.Open, GoalState.Draft],
            forUserId: selectedUser.id,
            goalDueBetweenDates,
          }}
          localStorageKey="dashboardPersonalGoalsExpanded"
        />
        <GoalsCollapsibleContainer
          title={`${label("team", { capitalize: true })}`}
          filters={{
            fetchData: true,
            goalScopes: [GoalScope.Team],
            teamGoalsOfUser: selectedUser.id,
            goalStates: [GoalState.Open, GoalState.Draft],
            forUserId: selectedUser.id,
            goalDueBetweenDates,
          }}
          localStorageKey="dashboardTeamGoalsExpanded"
        />
        <GoalsCollapsibleContainer
          title={`${label("organization", { capitalize: true })}`}
          filters={{
            fetchData: true,
            goalScopes: [GoalScope.Organization],
            goalStates: [GoalState.Open, GoalState.Draft],
            forUserId: selectedUser.id,
            goalDueBetweenDates,
          }}
          localStorageKey="dashboardOrganizationGoalsExpanded"
        />
      </Layout.SidebarSection>
    </>
  );
};

export default Sentry.withErrorBoundary(Goals, {
  fallback: <Error description={"The goals could not be rendered."} />,
});
