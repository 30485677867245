import useUserComboboxQuery from "@components/user-combobox/use-user-combobox-query";
import UserCombobox from "@components/user-combobox/user-combobox";
import {
  UserComboboxOption,
  UserComboboxOptionType,
  UserComboboxUserOption,
} from "@components/user-combobox/user-combobox-list";
import { graphqlNone } from "@helpers/constants";

export const everybodyOption: UserComboboxUserOption = {
  name: "Everybody",
  id: graphqlNone,
  type: UserComboboxOptionType.USER,
};

const UserReportFilter = ({
  value,
  onChangeValue,
}: {
  value: UserComboboxOption;
  onChangeValue: (value: UserComboboxOption) => void;
}) => {
  const { loading, options, query, setQuery } = useUserComboboxQuery({
    emptyOption: everybodyOption,
    selected: value,
  });

  return (
    <UserCombobox
      aria-label="User filter"
      loading={loading}
      width="64"
      query={query}
      value={value}
      options={options}
      clearable={value.id !== graphqlNone}
      onChangeValue={onChangeValue}
      onChangeQuery={setQuery}
      onClearValue={() => onChangeValue(everybodyOption)}
    />
  );
};

export default UserReportFilter;
