import { gql } from "@apollo/client";

export default gql`
  fragment InvitationFragment on InvitationNode {
    id
    organizationId
    organizationName
    organizationMemberCount
    status
    inviteeEmail
  }
`;
