import TimeAgoCustom from "@components/time-ago/time-ago";

const ArtifactCreated = ({ artifact }: { artifact: { created: string } }) => {
  return (
    <span className="text-xs text-gray-500 tracking-tight">
      <TimeAgoCustom date={artifact.created} />
    </span>
  );
};

export default ArtifactCreated;
