import { currentUserVar } from "@cache/cache";
import Table, {
  TableBody,
  TableContainer,
  TableHeadCell,
  TableHeadRow,
} from "@components/table/table";
import { assertEdgesNonNull } from "@helpers/helpers";

import ExplorerBookmarkTableRow from "./components/bookmark-table-row";

const ExplorerBookmarks = () => {
  const currentUser = currentUserVar();
  const explorerBookmarks = assertEdgesNonNull(currentUser.explorerBookmarks);

  // RENDER
  return (
    <div className="p-4 sm:p-6 flex flex-col gap-4">
      {explorerBookmarks.length > 0 ? (
        <TableContainer>
          <Table>
            <TableHeadRow>
              <TableHeadCell>Bookmark</TableHeadCell>
              <TableHeadCell>Visible to</TableHeadCell>
              <TableHeadCell className="w-36"></TableHeadCell>
            </TableHeadRow>
            <TableBody>
              {explorerBookmarks.map((bookmark) => (
                <ExplorerBookmarkTableRow
                  key={bookmark.id}
                  bookmark={bookmark}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <div className="text-sm text-gray-500">No bookmarks.</div>
      )}
    </div>
  );
};

export default ExplorerBookmarks;
