import { ErrorBoundary } from "@sentry/react";
import { GetArtifactSidebarQueryQuery } from "types/graphql-schema";

import Error from "@components/error/error";
import ArtifactWYSIWYG from "@components/wysiwyg/artifact-wysiwyg";
import { classNames } from "@helpers/css";

const DiscussionNotes = ({
  artifact,
}: {
  artifact: GetArtifactSidebarQueryQuery["artifact"];
}) => {
  return (
    <div aria-label="Artifact sidebar discussion notes">
      <ErrorBoundary
        fallback={
          <Error
            title="An unexpected error occurred."
            description={
              "The editor could not be rendered. We have been notified. Please refresh the page."
            }
          />
        }
      >
        <div className="relative">
          <ArtifactWYSIWYG
            key={artifact.id}
            artifact={artifact}
            placeholder={"Type '/' to format the description..."}
            organizationId={artifact.organization?.id}
            editable
            className={classNames(
              "text-base min-h-24",
              // add padding so the + and drag button are within the editor. Needs to keep some space between buttons and editor so we handle correctly hiding the buttons when mouse leaves editor
              // https://github.com/Topicflow/topicflow/pull/1314
              "py-4 pl-[54px] pr-6"
            )}
            showPlusButton={true}
            isInSidebar={true}
            fixedMenuShowFullScreen={false}
          />
        </div>
      </ErrorBoundary>
    </div>
  );
};

export default DiscussionNotes;
