import { gql } from "@apollo/client";

export default gql`
  query getFullscreenArtifactQuery($artifactId: Int!) {
    artifact(artifactId: $artifactId) {
      id
      title
      artifactType
      websocketToken
      organization {
        id
      }
    }
  }
`;
