import { Editor } from "@tiptap/react";
import { useCallback } from "react";
import {
  RiArrowGoBackLine,
  RiArrowGoForwardLine,
  RiBold,
  RiChatQuoteLine,
  RiCheckboxLine,
  RiCodeBoxLine,
  RiCodeLine,
  RiFormatClear,
  RiH1,
  RiH2,
  RiItalic,
  RiLink,
  RiLinkUnlink,
  RiListOrdered,
  RiListUnordered,
  RiSeparator,
  RiStrikethrough,
  RiThumbUpLine,
} from "react-icons/ri";
import { TbArrowsDiagonal, TbTarget } from "react-icons/tb";
import { useLocation } from "react-router-dom";
import { ArtifactType } from "types/graphql-schema";

import AppLink from "@components/link/link";
import { classNames } from "@helpers/css";
import { getUrl } from "@helpers/helpers";

import { toggleLink } from "../helpers";

const FixedMenu = ({
  editor,
  fixedMenuShowFullScreen,
  fullScreenArtifactId,
  fullScreenArtifactType,
}: {
  editor: Editor;
  fixedMenuShowFullScreen: boolean;
  fullScreenArtifactId?: number;
  fullScreenArtifactType?: ArtifactType;
}) => {
  const location = useLocation();
  const setLink = useCallback(() => {
    toggleLink(editor);
  }, [editor]);

  const insertDecision = useCallback(() => {
    editor.chain().focus().insertDecision({}).run();
  }, [editor]);

  const insertActionItem = useCallback(() => {
    editor.chain().focus().insertActionItem({}).run();
  }, [editor]);

  const insertGoal = useCallback(() => {
    editor.chain().focus().insertGoal({}).run();
  }, [editor]);

  if (!editor) {
    return null;
  }

  const buttonClassName =
    "text-gray-500 hover:text-gray-900 hover:bg-gray-50 rounded";
  const buttonActiveClassName = "text-blue-500 font-bold";
  return (
    <div className="border-b flex gap-4 py-1 px-2 overflow-hidden sticky top-0 bg-white z-1">
      <div className="flex-1 flex gap-1.5 justify-between">
        <div className="flex gap-1.5">
          <div className="flex gap-1.5">
            <button
              type="button"
              tabIndex={-1}
              onClick={() => {
                editor.chain().focus().toggleBold().run();
              }}
              className={classNames(
                buttonClassName,
                editor.isActive("bold") ? buttonActiveClassName : ""
              )}
            >
              <RiBold />
            </button>
            <button
              type="button"
              tabIndex={-1}
              onClick={() => editor.chain().focus().toggleItalic().run()}
              className={classNames(
                buttonClassName,
                editor.isActive("italic") ? buttonActiveClassName : ""
              )}
            >
              <RiItalic />
            </button>
            <button
              type="button"
              tabIndex={-1}
              onClick={() => editor.chain().focus().toggleStrike().run()}
              className={classNames(
                buttonClassName,
                editor.isActive("strike") ? buttonActiveClassName : ""
              )}
            >
              <RiStrikethrough />
            </button>
          </div>

          <div className="flex gap-1.5">
            <button
              type="button"
              tabIndex={-1}
              onClick={() =>
                editor.chain().focus().toggleHeading({ level: 1 }).run()
              }
              className={classNames(
                buttonClassName,
                editor.isActive("heading", { level: 1 })
                  ? buttonActiveClassName
                  : ""
              )}
            >
              <RiH1 />
            </button>
            <button
              type="button"
              tabIndex={-1}
              onClick={() =>
                editor.chain().focus().toggleHeading({ level: 2 }).run()
              }
              className={classNames(
                buttonClassName,
                editor.isActive("heading", { level: 2 })
                  ? buttonActiveClassName
                  : ""
              )}
            >
              <RiH2 />
            </button>
          </div>

          <div className="flex gap-1.5">
            <button
              type="button"
              tabIndex={-1}
              onClick={() => editor.chain().focus().toggleBulletList().run()}
              className={classNames(
                buttonClassName,
                editor.isActive("bulletList") ? buttonActiveClassName : ""
              )}
            >
              <RiListUnordered />
            </button>
            <button
              type="button"
              tabIndex={-1}
              onClick={() => editor.chain().focus().toggleOrderedList().run()}
              className={classNames(
                buttonClassName,
                editor.isActive("orderedList") ? buttonActiveClassName : ""
              )}
            >
              <RiListOrdered />
            </button>
          </div>

          <div className="flex gap-1.5">
            <button
              type="button"
              tabIndex={-1}
              onClick={setLink}
              className={classNames(
                buttonClassName,
                editor.isActive("link") ? buttonActiveClassName : ""
              )}
            >
              {editor.isActive("link") ? <RiLinkUnlink /> : <RiLink />}
            </button>
            <button
              type="button"
              tabIndex={-1}
              onClick={() => editor.chain().focus().toggleCode().run()}
              className={classNames(
                buttonClassName,
                editor.isActive("code") ? buttonActiveClassName : ""
              )}
            >
              <RiCodeLine />
            </button>
            <button
              type="button"
              tabIndex={-1}
              onClick={() => editor.chain().focus().toggleCodeBlock().run()}
              className={classNames(
                buttonClassName,
                editor.isActive("codeBlock") ? buttonActiveClassName : ""
              )}
            >
              <RiCodeBoxLine />
            </button>
            <button
              type="button"
              tabIndex={-1}
              onClick={() => editor.chain().focus().toggleBlockquote().run()}
              className={classNames(
                buttonClassName,
                editor.isActive("blockquote") ? buttonActiveClassName : ""
              )}
            >
              <RiChatQuoteLine />
            </button>
            <button
              type="button"
              tabIndex={-1}
              className={classNames(buttonClassName)}
              onClick={() => editor.chain().focus().setHorizontalRule().run()}
            >
              <RiSeparator />
            </button>
          </div>

          <div className="flex gap-1.5">
            <button
              type="button"
              tabIndex={-1}
              className={classNames(buttonClassName)}
              onClick={() => editor.chain().focus().clearNodes().run()}
            >
              <RiFormatClear />
            </button>
            <button
              type="button"
              tabIndex={-1}
              className={classNames(buttonClassName)}
              onClick={() => editor.chain().focus().undo().run()}
            >
              <RiArrowGoBackLine />
            </button>
            <button
              type="button"
              tabIndex={-1}
              className={classNames(buttonClassName)}
              onClick={() => editor.chain().focus().redo().run()}
            >
              <RiArrowGoForwardLine />
            </button>
          </div>
          <div className="flex gap-1.5">
            <button
              type="button"
              tabIndex={-1}
              className={classNames(buttonClassName)}
              onClick={insertDecision}
            >
              <RiThumbUpLine />
            </button>
            <button
              type="button"
              tabIndex={-1}
              className={classNames(buttonClassName)}
              onClick={insertActionItem}
            >
              <RiCheckboxLine />
            </button>
            <button
              type="button"
              tabIndex={-1}
              className={classNames(buttonClassName)}
              onClick={insertGoal}
            >
              <TbTarget />
            </button>
          </div>
        </div>
        {fixedMenuShowFullScreen && fullScreenArtifactId && (
          <AppLink
            tabIndex={-1}
            className={classNames(buttonClassName)}
            to={{
              pathname: `${getUrl({
                artifactId: fullScreenArtifactId,
                artifactType: fullScreenArtifactType,
              })}/fullscreen`,
              state: {
                previousPathname: location.pathname,
              },
            }}
          >
            <TbArrowsDiagonal />
          </AppLink>
        )}
      </div>
    </div>
  );
};
export default FixedMenu;
