import { gql } from "@apollo/client";

export default gql`
  mutation removeGitHubFromOrganizationMutation($organizationId: Int!) {
    removeOrgGithubIntegration(organizationId: $organizationId) {
      organization {
        id
        hasGithubInstallation
      }
    }
  }
`;
