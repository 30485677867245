import { gql } from "@apollo/client";

export default gql`
  mutation associateArtifactWithTopicOrArtifactMutation(
    $artifactId: Int!
    $topicId: Int
    $otherArtifactId: Int
  ) {
    associateArtifactWithTopicOrArtifact(
      artifactId: $artifactId
      topicId: $topicId
      otherArtifactId: $otherArtifactId
    ) {
      artifact {
        id
      }
    }
  }
`;
