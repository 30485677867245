import googleCalendarLogo from "@static/img/google-calendar.png";
import outlookCalendarLogo from "@static/img/outlook-calendar.png";
import { SyncCredentialsTypeEnum } from "types/graphql-schema";

import { provider as providers } from "@helpers/constants";
import { getAssetUrl } from "@helpers/helpers";

const ProviderImage = ({
  provider,
  size,
}: {
  size: number | string;
  provider?: SyncCredentialsTypeEnum | null;
}) => {
  if (provider === providers.googleCalendar) {
    return (
      <img
        src={getAssetUrl(googleCalendarLogo)}
        alt="Google Calendar"
        className={`h-${size}`}
      />
    );
  }
  if (provider === providers.outlookCalendar) {
    return (
      <img
        src={getAssetUrl(outlookCalendarLogo)}
        alt="Outlook Calendar"
        className={`h-${size}`}
      />
    );
  }
  return null;
};

export default ProviderImage;
