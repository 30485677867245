import { gql } from "@apollo/client";

import GoalAlignmentDialogGoalFragment from "./goal-alignment-dialog-goal-fragment";

export default gql`
  ${GoalAlignmentDialogGoalFragment}
  query getGoalSearchResultsToAlignQuery($search: String!) {
    artifacts(search: $search) {
      edges {
        node {
          id
          ...GoalAlignmentDialogGoalFragment
        }
      }
    }
  }
`;
