import { PropsWithChildren } from "react";

type Props = {
  ariaLabel?: string;
};

const MainContainer: React.FC<PropsWithChildren<Props>> = ({
  children,
  ariaLabel,
}) => (
  <main
    className="flex-1 relative z-0 overflow-y-auto focus:outline-none"
    aria-label={ariaLabel}
  >
    <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">{children}</div>
  </main>
);

export default MainContainer;
