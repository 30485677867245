import { gql } from "@apollo/client";

import CommonGoalArtifactFragment from "@graphql/common-goal-artifact-fragment";

const GoalAlignmentDialogGoalFragment = gql`
  ${CommonGoalArtifactFragment}
  fragment GoalAlignmentDialogGoalFragment on GoalArtifactNode {
    ... on GoalArtifactNode {
      ...CommonGoalArtifactFragment
      parentGoalId
    }
  }
`;

export default GoalAlignmentDialogGoalFragment;
