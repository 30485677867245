import { useMutation, useQuery } from "@apollo/client";
import { ChangeEvent, useState } from "react";
import {
  AlignGoalMutationMutation,
  AlignGoalMutationMutationVariables,
  AlignmentGoalFragmentFragment,
  GetGoalSearchResultsToAlignQueryQuery,
  GetGoalSearchResultsToAlignQueryQueryVariables,
  GetGoalToAlignQueryQuery,
  GetGoalToAlignQueryQueryVariables,
  GoalAlignmentDialogGoalFragmentFragment,
  GoalScope,
} from "types/graphql-schema";

import alignGoalMutation from "@apps/artifact-sidebar/graphql/align-goal-mutation";
import { refreshAlignmentOfGoalIds } from "@apps/goal-alignment/helpers";
import useLabel from "@apps/use-label/use-label";
import { successNotificationVar } from "@cache/cache";
import Input from "@components/input/input";
import Loading from "@components/loading/loading";
import useDebounce from "@components/use-debounce/use-debounce";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { classNames } from "@helpers/css";
import { assertEdgesNonNull, isGoalArtifactNode } from "@helpers/helpers";

import getGoalSearchResultsToAlignQuery from "../graphql/get-goal-search-results-to-align-query";
import getGoalToAlignQuery from "../graphql/get-goal-to-align-query";
import MiniGoalAlignmentTree from "./mini-goal-alignment-tree";
import GoalAlignmentpopoverSearchResult from "./search-result";

const indexedGoalScopes = [
  GoalScope.Organization,
  GoalScope.Team,
  GoalScope.Personal,
];

const GoalAlignmentPopoverContent = ({
  artifactId,
  alignmentType,
  onClose,
}: {
  artifactId: number;
  alignmentType: "child" | "parent";
  onClose: () => void;
}) => {
  const label = useLabel();
  const [query, setQuery] = useState("");
  const debouncedQuery = useDebounce(query.trim(), 300);
  const handleChangeQuery = (e: ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };
  const [updateGoal] = useMutation<
    AlignGoalMutationMutation,
    AlignGoalMutationMutationVariables
  >(alignGoalMutation);
  const { data: dataArtifact, loading: loadingArtifact } = useQuery<
    GetGoalToAlignQueryQuery,
    GetGoalToAlignQueryQueryVariables
  >(getGoalToAlignQuery, {
    variables: { artifactId },
    onError: onNotificationErrorHandler(),
  });
  const { data: dataSearch, loading: loadingSearch } = useQuery<
    GetGoalSearchResultsToAlignQueryQuery,
    GetGoalSearchResultsToAlignQueryQueryVariables
  >(getGoalSearchResultsToAlignQuery, {
    variables: { search: debouncedQuery },
    skip: query.trim().length === 0,
    onError: onNotificationErrorHandler(),
  });
  const goal = dataArtifact?.artifact;

  const indexOfCurrentGoal = indexedGoalScopes.findIndex(
    (scope) => goal && isGoalArtifactNode(goal) && scope === goal.scope
  );
  const allowedGoalScopes =
    alignmentType === "parent"
      ? indexedGoalScopes.slice(0, indexOfCurrentGoal + 1)
      : indexedGoalScopes.slice(indexOfCurrentGoal);

  const searchResults = (
    dataSearch?.artifacts ? assertEdgesNonNull(dataSearch.artifacts) : []
  ).filter(isGoalArtifactNode);

  const errorMatches = [
    {
      match: "Cannot create parent loops",
      title: `You cannot select this ${label(
        "goal"
      )} as it would create an infinite loop.`,
    },
  ];

  const handleSucceedAlignment = (
    goalToRefreshIds: (number | null | undefined | false)[]
  ) => {
    successNotificationVar({
      title: `${label("goal", { capitalize: true })} alignment saved.`,
      timeout: 1000,
    });
    refreshAlignmentOfGoalIds(goalToRefreshIds);
    onClose();
  };

  const handleSelectGoal = (
    selectedGoal:
      | GoalAlignmentDialogGoalFragmentFragment
      | AlignmentGoalFragmentFragment
  ) => {
    if (!goal) return;
    if (goal.__typename !== "GoalArtifactNode") return;
    if (selectedGoal.__typename !== "GoalArtifactNode") return;
    if (!allowedGoalScopes.includes(selectedGoal.scope)) return;
    if (selectedGoal.id === goal.id) return;

    // If we align goal to a new parent:
    // - we have to refresh the old parent goal to show the goal removed
    // - we have the refresh the new parent goal to show the goal added
    // If we align the selected goal as a child of goal
    // - we have to refresh the child goals of goal
    // - we have to refresh the child goals of the old parent of the selected goal
    const goalIdsToRefresh = [
      goal.parentGoalId,
      goal.id,
      selectedGoal.id,
      selectedGoal.parentGoalId,
    ];
    if (alignmentType === "parent") {
      updateGoal({
        variables: {
          goalId: goal.id,
          parentGoalId: selectedGoal.id,
        },
        onError: onNotificationErrorHandler(errorMatches),
        onCompleted: () => {
          handleSucceedAlignment(goalIdsToRefresh);
        },
      });
    } else {
      updateGoal({
        variables: {
          goalId: selectedGoal.id,
          parentGoalId: goal.id,
        },
        onError: onNotificationErrorHandler(errorMatches),
        onCompleted: () => {
          handleSucceedAlignment(goalIdsToRefresh);
        },
      });
    }
  };

  return (
    <>
      {!goal && loadingArtifact && (
        <div className="p-4 gap-6 flex flex-col">
          <Loading />
        </div>
      )}
      {goal && goal.__typename === "GoalArtifactNode" && (
        <div className="p-4 gap-6 flex flex-col">
          <div>
            <div className="flex items-center gap-2 justify-between mb-2">
              <div className="text-base font-semibold text-gray-500">
                Choose a {alignmentType} {label("goal")}
              </div>
            </div>
            <div className="flex items-center gap-2">
              <Input
                className={"border rounded-md shadow-none"}
                onChange={handleChangeQuery}
                value={query}
                placeholder={`Search for a ${label(alignmentType)} ${label(
                  "goal"
                )}...`}
              />
              <Loading
                mini
                size={5}
                className={classNames(
                  "opacity-0",
                  loadingSearch && "opacity-100"
                )}
              />
            </div>
          </div>
          {query.length > 0 ? (
            <div>
              <div className="font-medium text-gray-500 text-xs">
                Search results
              </div>
              <div className="mt-1">
                {searchResults.length === 0 ? (
                  <div className="text-sm text-gray-500">No results</div>
                ) : (
                  <div className="text-sm -mx-4">
                    {searchResults.map((searchResult) => (
                      <GoalAlignmentpopoverSearchResult
                        onSelectGoal={handleSelectGoal}
                        goal={searchResult}
                        goalToAlignTo={goal}
                        allowedGoalScopes={allowedGoalScopes}
                        key={searchResult.id}
                        alignmentType={alignmentType}
                      />
                    ))}
                  </div>
                )}
              </div>
            </div>
          ) : (
            <MiniGoalAlignmentTree
              goalToAlignTo={goal}
              onSelectGoal={handleSelectGoal}
              allowedGoalScopes={allowedGoalScopes}
              alignmentType={alignmentType}
            />
          )}
        </div>
      )}
    </>
  );
};

export default GoalAlignmentPopoverContent;
