import { currentUserVar } from "@cache/cache";
import Loading from "@components/loading/loading";

const SidebarSyncingCalendar = () => {
  const currentUser = currentUserVar();
  if (!currentUser.calendarIsSyncingForFirstTime) {
    return null;
  }
  return (
    <div>
      <div className="bg-white text-base mt-4 font-medium rounded-lg border border-dashed flex flex-col gap-2 justify-center items-center py-6">
        <span className="gradient-basic text-transparent bg-clip-text">
          Calendar is syncing...
        </span>
        <Loading size={6} />
      </div>
    </div>
  );
};
export default SidebarSyncingCalendar;
