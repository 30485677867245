import { useQuery } from "@apollo/client";
import pluralize from "pluralize";
import { useState } from "react";
import { LoggedInUserOrgFragment } from "types/graphql-schema";

import getMeetingsByManagerReportQuery from "@apps/org-settings/graphql/get-meetings-by-manager-report-query";
import useLabel from "@apps/use-label/use-label";
import AppLink from "@components/link/link";
import Loading from "@components/loading/loading";
import {
  Table,
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableHeadCell,
  TableHeadRow,
} from "@components/table/table";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { UserComboboxOption } from "@components/user-combobox/user-combobox-list";
import { graphqlNone } from "@helpers/constants";

import Report1on1ByManagerRow from "./1-on-1-by-manager-row";
import TemplateReportFilter, {
  anyTemplateOption,
} from "./template-report-filter";
import UserReportFilter, { everybodyOption } from "./user-report-filter";

const Report1on1ByManager = ({
  organization,
}: {
  organization: LoggedInUserOrgFragment;
}) => {
  const label = useLabel();
  const [peopleFilter, setPeopleFilter] =
    useState<UserComboboxOption>(everybodyOption);
  const [templateFilter, setTemplateFilter] = useState(anyTemplateOption);

  const { data, loading, fetchMore } = useQuery(
    getMeetingsByManagerReportQuery,
    {
      variables: {
        after: null,
        first: 20,
        organizationId: organization.id,
        personId: peopleFilter.id === graphqlNone ? undefined : peopleFilter.id,
        templateId: templateFilter.value,
      },
      onError: onNotificationErrorHandler(),
    }
  );
  const meetingsByManagerItems =
    data?.meetingsByManagerReport.edges.map(
      ({ node }: { node: any }) => node
    ) || [];
  const userCountWithoutManager = data?.users.totalCount || 0;
  const tableRows = meetingsByManagerItems.reduce((memo: any, item: any) => {
    const managerRow = [
      {
        isManager: true,
        report: item.manager,
        id: `manager-${item.manager.id}`,
        managers: item.manager.managers.edges.map(
          ({ node }: { node: any }) => node
        ),
        lastMeeting: null,
        nextMeeting: null,
      },
    ];
    const rows = item.reports.edges.map(({ node: report }: { node: any }) => ({
      isManager: false,
      id: `report-${report.user.id}-of-manager-${item.manager.id}`,
      report: report.user,
      managers: [item.manager],
      lastMeeting: report.lastMeeting,
      nextMeeting: report.nextMeeting,
    }));
    return memo.concat(managerRow, rows);
  }, []);

  const handleClickMore = () => {
    const after = data.meetingsByManagerReport.pageInfo.endCursor;
    fetchMore({
      variables: { after, merge: true },
    });
  };

  return (
    <div>
      <div className="mt-6 flex items-center justify-between gap-8">
        <div className="flex items-center gap-8">
          <UserReportFilter
            value={peopleFilter}
            onChangeValue={setPeopleFilter}
          />
          <TemplateReportFilter
            organization={organization}
            value={templateFilter}
            onChangeValue={setTemplateFilter}
          />
        </div>
        {userCountWithoutManager > 0 && (
          <div className="text-sm">
            {userCountWithoutManager}{" "}
            {pluralize("user", userCountWithoutManager)} with no managers.{" "}
            <AppLink
              className="text-blue-600 hover:underline"
              to={`/settings/organization/${organization.id}/members?showMembersWithoutManager=true`}
            >
              View users.
            </AppLink>
          </div>
        )}
      </div>

      <Table className="mt-6 w-full">
        <TableHeadRow>
          <TableHeadCell>Name</TableHeadCell>
          <TableHeadCell>Manager</TableHeadCell>
          <TableHeadCell>
            Latest {label("oneonone", { pluralize: false, capitalize: true })}
          </TableHeadCell>
          <TableHeadCell>
            Next {label("oneonone", { pluralize: false, capitalize: true })}
          </TableHeadCell>
        </TableHeadRow>
        <TableBody>
          {loading && !data && (
            <TableBodyRow>
              <td colSpan={4}>
                <div className="flex justify-center mt-8">
                  <Loading mini size="5" />
                </div>
              </td>
            </TableBodyRow>
          )}
          {data &&
            tableRows.length > 0 &&
            tableRows.map((tableRow: any) => (
              <Report1on1ByManagerRow
                key={`${tableRow.id}`}
                tableRow={tableRow}
                organization={organization}
                templateFilter={templateFilter}
              />
            ))}
          {data && tableRows.length === 0 && (
            <TableBodyRow className="border-b text-sm">
              <TableBodyCell colSpan={4} className="py-2 px-4 text-gray-500">
                No users
              </TableBodyCell>
            </TableBodyRow>
          )}
        </TableBody>
      </Table>

      {data?.meetingsByManagerReport.pageInfo.hasNextPage && (
        <div className="mt-4 flex items-center">
          <button
            className="text-gray-500 text-sm mr-4 hover:underline"
            onClick={handleClickMore}
            disabled={loading}
          >
            Load more managers...
          </button>
          {loading && <Loading size="5" mini />}
        </div>
      )}
    </div>
  );
};

export default Report1on1ByManager;
