const CoreValueIcon = ({
  emoji,
  image,
  imagePxSize = 96,
}: {
  emoji?: string | null;
  image?: string | null;
  imagePxSize: number;
}) => {
  const style = { width: `${imagePxSize}px`, height: `${imagePxSize}px` };
  return image ? (
    <img style={style} src={image} alt={image} />
  ) : emoji ? (
    <span style={style} className="flex items-center justify-center">
      {emoji}
    </span>
  ) : (
    // Star emoji as default
    <span style={style} className="flex items-center justify-center" role="img">
      🌟
    </span>
  );
};

export default CoreValueIcon;
