import { gql } from "@apollo/client";

import cache from "@cache/cache";
import removeTopicFromMeeting from "@cache/remove-topic-from-meeting";

export default function removeTopic({
  topic,
  meeting = null,
}: {
  topic: { id: number };
  meeting: { id: number } | null;
}) {
  // deleting obj from cache so if user goes back with browser
  // they won't be able to load data from cache
  const topicCacheId = cache.identify(topic);
  cache.evict({ id: topicCacheId });
  cache.gc();

  // remove query topic(topicId: id)
  cache.writeQuery({
    query: gql`
      query updateTopic($topicId: Int!) {
        topic(topicId: $topicId) {
          id
        }
      }
    `,
    variables: {
      topicId: topic.id,
    },
    data: {
      topic: null,
    },
  });

  if (meeting) {
    removeTopicFromMeeting({ topic, meeting });
  }
}
