import { useQuery } from "@apollo/client";
import moment from "moment";
import { ReactNode } from "react";
import {
  GetMeetingTranscriptQueryQuery,
  GetMeetingTranscriptQueryQueryVariables,
  MeetingViewMeetingNodeNewPageFragmentFragment,
  PricingTier,
} from "types/graphql-schema";

import NoTranscriptCta from "@apps/meeting-new/components/no-transcript-cta";
import getMeetingTranscriptQuery from "@apps/meeting-transcription-dialog/graphql/get-meeting-transcript-query";
import BillingDialogCTA from "@components/billing-dialog-cta/billing-dialog-cta";
import AppLink from "@components/link/link";
import Loading from "@components/loading/loading";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { isPageHidden } from "@helpers/hooks/use-page-visible";

const MeetingSidebarAIContainer = ({
  meeting,
  children,
}: {
  meeting: MeetingViewMeetingNodeNewPageFragmentFragment;
  children: ({
    meeting,
    refetch,
    loading,
  }: {
    meeting: NonNullable<GetMeetingTranscriptQueryQuery["meeting"]>;
    refetch: () => void;
    loading: boolean;
  }) => ReactNode;
}) => {
  const inBetween =
    meeting.startDatetime && meeting.endDatetime
      ? moment().isBetween(
          moment(meeting.startDatetime).subtract(1, "hour"),
          moment(meeting.endDatetime).add(3, "hours")
        )
      : false;
  const { data, loading, refetch } = useQuery<
    GetMeetingTranscriptQueryQuery,
    GetMeetingTranscriptQueryQueryVariables
  >(getMeetingTranscriptQuery, {
    pollInterval: 30000,
    skipPollAttempt: () => {
      // we don't poll if:
      // - meeting page is not visible
      // - not during the meeting time
      // - has no video conference url
      return isPageHidden() || !inBetween || !meeting.videoConferenceUrl;
    },
    variables: { meetingId: meeting.id },
    onError: onNotificationErrorHandler(),
  });

  const canShowAI =
    data?.meeting &&
    (data.meeting?.transcript ||
      meeting.hasPreviousMeetingsWithTopics ||
      (meeting.topics?.edges?.length || 0) > 0);

  if (!meeting.organization || !meeting.meetingGroup) {
    return null;
  }

  const transcriptMeeting = data?.meeting;

  const containerClassName =
    "mt-4 bg-white py-3 px-3 border border-gray-200 rounded-lg text-gray-700 text-sm";
  return meeting.organization.effectivePricingTier !== PricingTier.Ultimate ? (
    <div className={containerClassName}>
      <BillingDialogCTA className="text-blue-link underline">
        <span>Upgrade your plan</span>
      </BillingDialogCTA>{" "}
      to have access to the AI Assist feature.
    </div>
  ) : !meeting.organization?.enableMeetingSummarization ? (
    <div className={containerClassName}>
      Enable AI Meeting Summarization & Assistant in{" "}
      <AppLink
        to={`/settings/organization/${meeting.organization?.id}/settings`}
        className="text-blue-600"
      >
        Organization Settings
      </AppLink>
      .
    </div>
  ) : !data && loading ? (
    <div className={containerClassName}>
      <Loading />
    </div>
  ) : !canShowAI ? (
    <div className={containerClassName}>
      <NoTranscriptCta videoConferenceType={meeting.videoConferenceType} />
    </div>
  ) : transcriptMeeting ? (
    children({ meeting: transcriptMeeting, refetch, loading })
  ) : null;
};

export default MeetingSidebarAIContainer;
