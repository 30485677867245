import { useQuery } from "@apollo/client";
import { useEffect } from "react";
import {
  GetExtensionMeetingQueryQuery,
  GetExtensionMeetingQueryQueryVariables,
} from "types/graphql-schema";

import Meeting from "@apps/meeting-new/components/meeting";
import AIAssistMeetingBot from "@apps/meeting-new/components/meeting-sidebar/ai-assist-bot";
import Container from "@components/container/container";
import GraphqlError from "@components/error/graphql-error";
import Loading from "@components/loading/loading";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { getUrl } from "@helpers/helpers";

import getExtensionMeetingQuery from "../graphql/get-extension-meeting-query";

const ExtensionMeeting = ({ meetingId }: { meetingId: number }) => {
  const { loading, error, data } = useQuery<
    GetExtensionMeetingQueryQuery,
    GetExtensionMeetingQueryQueryVariables
  >(getExtensionMeetingQuery, {
    variables: { meetingId },
    onError: onNotificationErrorHandler(),
  });

  const meeting = data?.meeting;

  useEffect(() => {
    if (meeting) {
      const pathname = getUrl({
        meetingGroupId: meeting.meetingGroup?.id,
        meetingId: meeting.id,
      });
      window.parent.postMessage(
        JSON.stringify({
          type: "set-meeting-url",
          url: `https://app.topicflow.com${pathname}`,
        }),
        "https://meet.google.com"
      );
    }
  }, [meeting]);

  // Renders
  if (error || (!loading && !meeting)) {
    return (
      <Container>
        <div className="mx-4">
          <GraphqlError
            error={error}
            whatDidNotWork="The meeting could not be loaded."
          />
        </div>
      </Container>
    );
  }
  if (!data && loading) {
    return (
      <Container>
        <Loading />
      </Container>
    );
  }
  if (!data || !meeting) {
    return null;
  }

  return (
    <div className="bg-white">
      {meeting.organization?.effectivePricingTier === "ultimate" && (
        <div className="mb-1 bg-white border-b border-gray-200">
          <AIAssistMeetingBot meeting={meeting} />
        </div>
      )}
      <Meeting
        isInExtension
        meeting={meeting}
        opensTopicsInSidebar={true}
        currentPageMeeting={meeting}
      />
    </div>
  );
};

export default ExtensionMeeting;
