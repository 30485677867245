import { gql } from "@apollo/client";

export default gql`
  mutation sendNudgeEmailMutation(
    $managerId: Int!
    $reportId: Int!
    $topicTemplateId: Int
  ) {
    email1on1Nudge(
      managerId: $managerId
      reportId: $reportId
      topicTemplateId: $topicTemplateId
    ) {
      report {
        id
      }
      manager {
        id
      }
    }
  }
`;
