import { gql } from "@apollo/client";

import TopicTemplateFragment from "./topic-template-fragment";

export default gql`
  ${TopicTemplateFragment}
  query getTopicTemplates {
    topicTemplates(adhocTemplate: false) {
      totalCount
      edges {
        node {
          ...TopicTemplateFragment
        }
      }
    }
    topicTemplateCategories {
      edges {
        node {
          ...TopicTemplateCategoryFragment
        }
      }
    }
  }
`;
