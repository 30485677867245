import { useCallback } from "react";

import cache, { currentUserVar } from "@cache/cache";
import usePusher from "@components/use-pusher/use-pusher";

export default function PusherMeetingGroupTranscriptionBotSettingChange({
  channelName,
}: {
  channelName: string;
}) {
  const currentUser = currentUserVar();

  const handleChange = useCallback(
    ({
      meeting_group_id,
      add_transcription_bot_to_meetings_by_default,
    }: {
      meeting_group_id: number;
      add_transcription_bot_to_meetings_by_default: boolean | null;
    }) => {
      const meetingGroupCacheId = cache.identify({
        id: meeting_group_id,
        __typename: "MeetingGroupNode",
      });
      if (meetingGroupCacheId) {
        cache.modify({
          id: meetingGroupCacheId,
          fields: {
            addTranscriptionBotToMeetingsByDefault() {
              return add_transcription_bot_to_meetings_by_default;
            },
          },
        });
      }
    },
    [currentUser]
  );

  usePusher(
    channelName,
    "meeting_group_transcription_bot_setting_change",
    handleChange
  );

  return null;
}
