import moment from "moment";
import { OrganizationNode, PricingTier } from "types/graphql-schema";

import Heading from "@components/heading/heading";
import { classNames } from "@helpers/css";

import SettingsContainer from "./settings-container";

export const ContactSales = ({ pricingTier }: { pricingTier: PricingTier }) => (
  <a
    href={`mailto:hello@topicflow.com?subject=${encodeURIComponent(
      `Topicflow Upgrade (${pricingTier})`
    )}&body=${encodeURIComponent(
      `Hi,\n\nI would like to talk to you about upgrading to the ${pricingTier} tier.\n\nThanks.`
    )}`}
    target="_blank"
    rel="noreferrer"
    className={classNames(
      "inline-block rounded-lg text-center py-2 px-4 border-2 text-sm border-gray-900 text-gray-900 bg-white"
    )}
  >
    Contact Sales
  </a>
);

type BillingProps = {
  organization: Pick<OrganizationNode, "pricingTierValidUntil" | "pricingTier">;
};

const Billing: React.FC<BillingProps> = ({ organization }) => {
  return (
    <div>
      <Heading small title="Billing" />

      <SettingsContainer label="Plan">
        <div className="flex items-center gap-6">
          <ContactSales pricingTier={PricingTier.Ultimate} />
          <a
            href="https://www.topicflow.com/pricing/"
            target="_blank"
            rel="noreferrer"
            className="underline"
          >
            View our detailed plan comparison
          </a>
        </div>
      </SettingsContainer>

      {organization.pricingTier === PricingTier.Pro && (
        <SettingsContainer label="Billing renewal date" className="border-t">
          {organization.pricingTierValidUntil
            ? moment(organization.pricingTierValidUntil).format("LL")
            : "Not specified."}
        </SettingsContainer>
      )}
    </div>
  );
};

export default Billing;
