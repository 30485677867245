import { useMutation } from "@apollo/client";
import moment from "moment";

import getArtifactActivitiesQuery from "@apps/artifact-sidebar/graphql/get-artifact-activities-query";
import ArtifactDatePicker, {
  ArtifactDatePickerProps,
} from "@components/datepicker/artifact-date-picker";
import { onNotificationErrorHandler } from "@components/use-error/use-error";

import updateArtifactMutation from "../graphql/update-artifact-mutation";

const GoalStartDate = ({
  artifact,
  ...props
}: Omit<
  ArtifactDatePickerProps,
  "isCompleted" | "date" | "onChangeDate" | "cannotBeOverdue"
> & {
  artifact: any;
}) => {
  const [updateArtifact] = useMutation(updateArtifactMutation);

  const handleSetStartDate = (date: Date | null) => {
    const startDate = date ? moment(date).format("YYYY-MM-DD") : null;
    updateArtifact({
      variables: {
        artifactId: artifact.id,
        additionalFields: { startDate },
      },
      optimisticResponse: {
        createOrUpdateArtifact: {
          artifact: {
            ...artifact,
            startDate,
          },
          __typename: "CreateOrUpdateArtifactMutation",
        },
      },
      refetchQueries: [getArtifactActivitiesQuery],
      onError: onNotificationErrorHandler(),
    });
  };

  return (
    <ArtifactDatePicker
      isCompleted={artifact.progress !== 0}
      date={artifact.startDate}
      onChangeDate={handleSetStartDate}
      cannotBeOverdue
      {...props}
    />
  );
};

export default GoalStartDate;
