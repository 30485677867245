import { compact } from "lodash";
import { useRouteMatch } from "react-router-dom";
import {
  ArtifactType,
  MeetingViewMeetingNodeNewPageFragmentFragment,
} from "types/graphql-schema";

import ActionItemsCollapsible from "@apps/action-items-collapsible/action-items-collapsible-container";
import { currentOrganizationVar } from "@cache/cache";

import MeetingSidebarSection from "./meeting-sidebar-collapsible-container";
import useMeetingSidebarSection from "./use-meeting-sidebar-collapsible-container";

const ActionItems = ({
  meeting,
}: {
  meeting: MeetingViewMeetingNodeNewPageFragmentFragment;
}) => {
  const { url } = useRouteMatch();
  const currentOrganization = currentOrganizationVar();
  const actionItemStates = compact(currentOrganization?.actionItemStates || []);

  const {
    isFormalOneonone,
    subject,
    filterAssigneeId,
    participantIds,
    toggledAssignee,
    toggledJustMeeting,
  } = useMeetingSidebarSection({
    meeting,
    collapsibleFilterName: "ActionItems",
  });

  const variables = {
    meetingGroupId: toggledJustMeeting ? meeting.meetingGroup?.id : undefined,
    assigneeId: toggledAssignee ? filterAssigneeId : undefined,
    assigneeIds: toggledAssignee ? undefined : participantIds,
  };

  const completeState = actionItemStates.find((state) => state.isComplete);
  const incompleteDefaultState = actionItemStates.find(
    (state) => state.isDefaultIncomplete
  );
  const states = compact([incompleteDefaultState, completeState]);

  if (!meeting.meetingGroup || states.length === 0) return null;

  return (
    <MeetingSidebarSection
      collapsibleFilterName="ActionItems"
      expandedUiPreferenceKey="meetingSidebarActionItemContainerExpanded"
      subject={subject}
      isFormalOneonone={isFormalOneonone}
      toggledAssignee={toggledAssignee}
      toggledJustMeeting={toggledJustMeeting}
      createArtifactFormOptions={{
        artifactType: ArtifactType.ActionItem,
        meetingGroupId: meeting.meetingGroup?.id,
        meetingId: meeting.id,
      }}
      titleLabel="action item"
    >
      {states.map((state, i) => (
        <ActionItemsCollapsible
          key={state.value}
          actionItemState={state}
          roundedBottom={i === states.length - 1}
          uiPreferenceCacheNamespace={"meetingActionItemsExpanded"}
          path={url}
          newSidebarDesign
          queryVariables={{
            ...variables,
            fetchData: true,
            after: null,
            first: 5,
            orderBy: state.isComplete ? "-completed_at" : "-due_date",
            actionItemIsComplete: state.isComplete,
          }}
        />
      ))}
    </MeetingSidebarSection>
  );
};

export default ActionItems;
