import { gql } from "@apollo/client";

export default gql`
  fragment RecentlyCompletedAssessmentDeliveryNewPage on AssessmentDeliveryNode {
    id
    deliveryDatetime
    lastSubmissionDatetime
    complianceProgram {
      id
      title
      dueDate
    }
    group {
      title
      id
      hasSelfAssessment
      assessmentType
    }
    target {
      id
      name
    }
    creator {
      id
      name
    }
    state
  }
  query getMeetingRecentlyCompletedAssessmentDeliveriesNewPageQuery(
    $meetingId: Int!
    $assessmentType: AssessmentType
    $after: String
    $limit: Int
  ) {
    meeting(meetingId: $meetingId) {
      id
      recentlyCompletedAssessmentDeliveries(
        first: $limit
        assessmentType: $assessmentType
        after: $after
      ) {
        pageInfo {
          endCursor
          hasNextPage
        }
        totalCount
        edges {
          node {
            ...RecentlyCompletedAssessmentDeliveryNewPage
          }
        }
      }
    }
  }
`;
