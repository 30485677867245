import googleMeetLogo from "@static/img/meet-64.png";
import teamsLogo from "@static/img/teams.svg";
import zoomLogo from "@static/img/zoom-64.png";
import {
  OnlineConference,
  SyncCredentialsTypeEnum,
} from "types/graphql-schema";

import { getAssetUrl } from "@helpers/helpers";

const VideoConferenceImage = ({
  provider,
  type,
  size,
}: {
  provider?: SyncCredentialsTypeEnum | null;
  type: OnlineConference;
  size: string | number;
}) => {
  if (
    type === OnlineConference.Native &&
    provider === SyncCredentialsTypeEnum.GoogleCalendar
  ) {
    return (
      <img
        src={getAssetUrl(googleMeetLogo)}
        alt="Google Meet"
        className={`h-${size}`}
      />
    );
  }
  if (
    type === OnlineConference.Native &&
    provider === SyncCredentialsTypeEnum.OutlookCalendar
  ) {
    return (
      <img
        src={getAssetUrl(teamsLogo)}
        alt="Microsoft Teams"
        className={`h-${size}`}
      />
    );
  }
  if (type === OnlineConference.Zoom) {
    return (
      <img src={getAssetUrl(zoomLogo)} alt="Zoom" className={`h-${size}`} />
    );
  }
  return null;
};

export default VideoConferenceImage;
