import { sortBy } from "lodash";
import moment from "moment";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { GetKpiSummaryQueryQuery } from "types/graphql-schema";

import { assertNonNull } from "@helpers/helpers";

const KPILineChart = ({
  kpi,
  height = 300,
}: {
  kpi: GetKpiSummaryQueryQuery["kpi"];
  height?: number;
}) => {
  const measurements = sortBy(
    assertNonNull(kpi?.summarizedMeasurements?.edges),
    (edge) => {
      const node = assertNonNull(edge?.node);
      return moment(node.date).unix();
    }
  );
  const data2 = measurements.map((edge) => {
    const node = assertNonNull(edge?.node);
    return {
      name: moment(node.date).format("ll"),
      value: node.aggregateMeasurement,
    };
  });
  return (
    <div className="text-xs">
      <ResponsiveContainer width="100%" height={height}>
        <LineChart width={600} height={height} data={data2}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Line type="monotone" dataKey="value" stroke="#2563EB" connectNulls />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default KPILineChart;
