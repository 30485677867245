import { gql } from "@apollo/client";

export const ActionItemCollapsibleFragment = gql`
  fragment ActionItemCollapsibleFragment on ArtifactInterface {
    id
    title
    artifactType
    created
    canUpdate {
      permission
    }
    organization {
      id
    }
    canDelete {
      permission
    }
    ... on ActionItemArtifactNode {
      isComplete
      actionItemState
      assignee {
        id
        avatar
        name
      }
      dueDate
    }
  }
`;
