import { useCallback } from "react";

import cache from "@cache/cache";
import usePusher from "@components/use-pusher/use-pusher";

export default function PusherGoalProgressUpdated({
  channelName,
}: {
  channelName: string;
}) {
  // Queries & Mutations
  const handleGoalProgressUpdated = useCallback(
    ({
      goal,
    }: {
      goal: { id: number; progress: number; current_value: number };
    }) => {
      // If it's in the cache then we update
      const artifactCacheId = cache.identify(goal);
      if (artifactCacheId) {
        cache.modify({
          id: artifactCacheId,
          fields: {
            progress() {
              return goal.progress;
            },
            currentValue() {
              return goal.current_value;
            },
          },
        });
      }
    },
    []
  );

  usePusher(channelName, "goal_progress_updated", handleGoalProgressUpdated);

  return null;
}
