import { gql } from "@apollo/client";

export default gql`
  fragment KPIFragment on KPINode {
    id
    title
    visibility
    canUpdate {
      permission
      reason
    }
    creator {
      id
      avatar
      name
    }
    organization {
      id
      name
    }
    teams {
      edges {
        node {
          id
          title
        }
      }
    }
    defaultSummaryPeriod
    defaultSummaryMethod
    targetType
    targetValue
    targetCondition
  }
`;
