import { gql } from "@apollo/client";

export const MeetingParticipantsNewPageFragment = gql`
  fragment MeetingParticipantFragment on MeetingParticipantNode {
    id
    status
    participantEmail
    role
    user {
      id
      name
      firstName
      lastName
      avatar
      email
      status
    }
  }
  fragment MeetingParticipantsNewPageFragment on MeetingNode {
    participants {
      edges {
        node {
          ...MeetingParticipantFragment
        }
      }
    }
  }
`;
