import { useCallback } from "react";

import { deleteArtifactFromCache } from "@cache/delete-artifact";
import usePusher from "@components/use-pusher/use-pusher";

export default function PusherArtifactDeleted({
  channelName,
}: {
  channelName: string;
}) {
  // Queries & Mutations
  const handleArtifactDeleted = useCallback(
    ({ artifact }: { artifact: { id: number; __typename: string } }) => {
      deleteArtifactFromCache({ artifact });
    },
    []
  );

  usePusher(channelName, "artifact_deleted", handleArtifactDeleted);

  return null;
}
