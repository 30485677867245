import { gql } from "@apollo/client";

import KeyResultFragment from "@graphql/key-result-fragment";

export const AlignmentGoalFragment = gql`
  ${KeyResultFragment}
  fragment AlignmentGoalFragment on ArtifactInterface {
    id
    ... on GoalArtifactNode {
      artifactType
      title
      updated
      canUpdate {
        permission
      }
      canDelete {
        permission
      }
      firstOwner {
        id
        name
        avatar
      }
      state
      scope
      ownerCount
      dueDate
      goalVisibility
      status
      progress
      progressType
      currentValue
      startValue
      targetValue
      isStale
      owners(first: 1) {
        edges {
          node {
            id
            name
            avatar
          }
        }
      }
      created
      canRead {
        permission
      }
      keyResults {
        edges {
          node {
            id
            ...KeyResultFragment
          }
        }
      }
      parentGoalId
    }
  }
`;

export default gql`
  ${AlignmentGoalFragment}
  query getAlignmentGoalsQuery(
    $first: Int!
    $after: String
    $goalScopes: [GoalScope]
    $goalStates: [GoalState]
    $goalDueBetweenDates: [Date]
    $idsToMergeInApolloCache: [Int]
    $searchGoalOwners: [Int]
    $searchGoalTeams: [Int]
    $hasSearch: Boolean!
  ) {
    artifacts(
      idsToMergeInApolloCache: $idsToMergeInApolloCache
      artifactType: goal
      orderBy: "objective_alignment"
      goalScopes: $goalScopes
      goalStates: $goalStates
      goalDueBetweenDates: $goalDueBetweenDates
      first: $first
      after: $after
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      totalCount
      edges {
        node {
          ...AlignmentGoalFragment
        }
      }
    }
    searchResults: artifacts(
      # idsToMergeInApolloCache: $idsToMergeInApolloCache
      artifactType: goal
      goalScopes: $goalScopes
      goalStates: $goalStates
      goalDueBetweenDates: $goalDueBetweenDates
      goalOwners: $searchGoalOwners
      goalTeams: $searchGoalTeams
    ) @include(if: $hasSearch) {
      edges {
        node {
          id
        }
      }
    }
  }
`;
