// https://v5.reactrouter.com/web/guides/scroll-restoration
import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { TFLocation, TFLocationState } from "types/topicflow";

export default function ScrollToTop() {
  const backgroundRef = useRef<TFLocation | undefined>(undefined);
  const location = useLocation<TFLocationState>();

  useEffect(() => {
    // we prevent the scroll to top when showing a sidebar
    // or hiding a sidebar
    if (!location.state?.background && !backgroundRef.current) {
      window.scrollTo(0, 0);
    }
    backgroundRef.current = location.state?.background;
  }, [location.pathname]);

  return null;
}
