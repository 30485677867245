import { useCallback } from "react";

import cache, { currentUserVar } from "@cache/cache";
import usePusher from "@components/use-pusher/use-pusher";

export default function PusherMeetingUpdated({
  channelName,
}: {
  channelName: string;
}) {
  const currentUser = currentUserVar();

  const handleMeetingUpdated = useCallback(
    ({
      meeting_id,
      has_incomplete_mandatory_topics,
    }: {
      meeting_id: number;
      has_incomplete_mandatory_topics: boolean | null;
    }) => {
      if (
        has_incomplete_mandatory_topics === undefined ||
        has_incomplete_mandatory_topics === null
      ) {
        return;
      }
      const meetingCacheId = cache.identify({
        id: meeting_id,
        __typename: "MeetingNode",
      });
      if (meetingCacheId) {
        cache.modify({
          id: meetingCacheId,
          fields: {
            hasIncompleteMandatoryTopics() {
              return has_incomplete_mandatory_topics;
            },
          },
        });
      }
    },
    [currentUser]
  );

  usePusher(channelName, "meeting_update", handleMeetingUpdated);

  return null;
}
