import { gql } from "@apollo/client";

const MyWorkHistoryItemFragment = gql`
  fragment MyWorkHistoryItemFragment on WorkHistorySummaryNode {
    id
    summary
    modifiedSummary
    meetingCount
    meetingTimeHours
    created
    summaryStartDate
    summaryEndDate
    externalEventData
    subject {
      id
      name
      avatar
    }
    collaborators {
      edges {
        node {
          collaborationCount
          user {
            id
            name
            avatar
          }
        }
      }
    }
    sections {
      edges {
        node {
          id
          title
          modifiedTitle
          content
          modifiedContent
          hidden
        }
      }
    }
  }
`;

export default MyWorkHistoryItemFragment;
