import { gql } from "@apollo/client";

export default gql`
  mutation deleteTeamMutation($id: Int!) {
    deleteTeam(teamId: $id) {
      team {
        id
      }
    }
  }
`;
