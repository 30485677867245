import { gql } from "@apollo/client";

export default gql`
  query getOrganizationIntegrations($organizationId: Int!) {
    organization(pk: $organizationId) {
      id
      integrations {
        edges {
          node {
            id
            integrationType
            creator {
              id
              name
            }
            created
            socialAuth {
              id
              extraData
            }
          }
        }
      }
    }
  }
`;
