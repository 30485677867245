import { gql } from "@apollo/client";

export default gql`
  mutation sendOneononeProgramReminder($complianceProgramId: Int!) {
    emailOneononeReminder(complianceProgramId: $complianceProgramId) {
      complianceProgram {
        id
      }
    }
  }
`;
