import { gql } from "@apollo/client";

export default gql`
  query getRefreshedUserData {
    me {
      id
      tiptapAiJwt
    }
  }
`;
