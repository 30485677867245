import { useMutation, useQuery } from "@apollo/client";
import * as Sentry from "@sentry/browser";
import { withErrorBoundary } from "@sentry/react";
import { Editor } from "@tiptap/core";
import { NodeViewWrapper } from "@tiptap/react";
import a from "indefinite";
import { defer, pull, uniq } from "lodash";
import { createRef, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  ArtifactType,
  CreatedArtifactFragmentFragment,
  GetWysiwygArtifactQueryQuery,
  GetWysiwygArtifactQueryQueryVariables,
} from "types/graphql-schema";
import { TFLocationState } from "types/topicflow";

import ArtifactCreationDialog from "@apps/artifact-creation-dialog/artifact-creation-dialog";
import { getRecognitionRecipientTitle } from "@apps/artifact/artifact";
import useLabel from "@apps/use-label/use-label";
import { currentUserVar } from "@cache/cache";
import AppError from "@components/error/error";
import AppLink from "@components/link/link";
import Loading from "@components/loading/loading";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import useMountedState from "@components/use-mounted-state/use-mounted-state";
import ArtifactWYSIWYG from "@components/wysiwyg/artifact-wysiwyg";
import { batchClient } from "@graphql/client";
import { classNames } from "@helpers/css";
import {
  errorMatches,
  getUrl,
  matchApolloErrorMessage,
  toWithBackground,
} from "@helpers/helpers";

import ArtifactComment from "./artifact-comment";
import ArtifactError from "./artifact-error";
import ArtifactIcon from "./artifact-icon";
import ArtifactInfos from "./artifact-infos";
import ArtifactToolbar from "./artifact-toolbar";
import ArtifactComponentGoalKeyResults from "./goals/key-results";
import associateArtifactWithTopicOrArtifactMutation from "./graphql/associate-artifact-with-topic-mutation";
import getArtifactQuery from "./graphql/get-wysiwyg-artifact-query";
import { deleteArtifact } from "./helpers";

/**
 * We need a useQuery for 2 reasons:
 * - To fetch initially the artifact using the extension attr id
 * - Then to keep the artifact data up to date when its cache is updated in another view
 *
 * Then we use useMutation to create or update the artifact. We define a no-cache policy and ignore api response
 * to prevent the cache to be updated after the api call succeed, instead we update the cache optimistically before the api call.
 * We prevent async update of the artifact which would provide a broken UX while user types.
 *
 */
const ArtifactComponent = ({
  node,
  updateAttributes,
  selected,
  editor,
  getPos,
  extension,
  deleteNode,
}: {
  node: any;
  updateAttributes: (attributes: any) => void;
  selected: boolean;
  editor: Editor;
  getPos: () => number;
  extension: any;
  deleteNode: () => void;
}) => {
  const isMounted = useMountedState();
  const ref = createRef<HTMLDivElement | undefined>();
  const currentUser = currentUserVar();
  const location = useLocation<TFLocationState>();
  const {
    id,
    createdByUser,
    commentId,
    // copyId is used when pasting/dropping an artifact link in the notes
    // this is to delay thing while we associated the artifact with topic on the backend
    // once associated we can set the `id` and clear `copyId`.
    // if we don't do that, the artifact will be rendered before being associated with topic
    // which might lead to the artifact not being readable by meeting participants.
    copyId,
  } = node.attrs;
  const artifactTypeName = node.type.name;
  const isActionItem = artifactTypeName === ArtifactType.ActionItem;
  const isDecision = artifactTypeName === ArtifactType.Decision;
  const isFeedback = artifactTypeName === ArtifactType.Feedback;
  const isDocument = artifactTypeName === ArtifactType.Document;
  const isGoal = artifactTypeName === ArtifactType.Goal;
  const isRecognition = artifactTypeName === ArtifactType.Recognition;
  const l = useLabel();
  const parentArtifactId = (editor.options.editorProps as any)
    .relatedArtifactId;
  const isCreatedByCurrentUser = createdByUser?.id === currentUser.id;
  const artifactId = id || copyId;
  const isBeingCopied = !id && copyId;
  const expandedUserIds = String(node.attrs.expandedUserIds).split(",");
  const [descriptionIsExpanded, setDescriptionIsExpanded] = useState(
    // we do not expand by default when the artifact component is rendered in an artifact wysiwyg
    !parentArtifactId && expandedUserIds.includes(String(currentUser.id))
  );
  const [showCreateArtifactDialog, setShowCreateArtifactDialog] = useState(
    isCreatedByCurrentUser && !artifactId
  );

  // HOOKS
  // skip if the artifact has not been attributed an id (not existing yet)
  // skip when another participant to copying an artifact until it is associated topic topic/artifact
  const isBeingCopiedByAnotherUser = isBeingCopied && !isCreatedByCurrentUser;
  const skip = !artifactId || isBeingCopiedByAnotherUser;

  const { data, loading, error } = useQuery<
    GetWysiwygArtifactQueryQuery,
    GetWysiwygArtifactQueryQueryVariables
  >(getArtifactQuery, {
    fetchPolicy: "cache-and-network",
    variables: { artifactId: Number(artifactId) },
    skip,
    onError: (errors) => {
      if (matchApolloErrorMessage(errors, `Invalid id ${id}`)) {
        return;
      }
      Sentry.captureException(errors);
    },
    client: batchClient,
  });
  const artifact = data?.artifact;
  const artifactType = data?.artifact?.artifactType || node.type.name;

  const seemsDeleted = id && data && data.artifact === null;
  const [associateArtifactWithTopicOrArtifact] = useMutation(
    associateArtifactWithTopicOrArtifactMutation
  );

  // if existing artifact was added to notes, we need to associate it with the topic
  // so other participants can see the artifact being loaded when they have read permissions
  useEffect(() => {
    if (!createdByUser) {
      window.console.error("need to have a created by user");
    }
    if (!copyId || id) {
      return;
    }
    if (!isCreatedByCurrentUser) {
      // only current user can associate an artifact
      return;
    }

    if (extension.options.topicId || extension.options.relatedArtifactId) {
      associateArtifactWithTopicOrArtifact({
        variables: {
          artifactId: copyId,
          topicId:
            extension.options.topicId || extension.options.relatedArtifactId,
        },
        onError: onNotificationErrorHandler(),
        onCompleted: () => {
          if (isMounted()) {
            updateAttributes({ copyId: null, id: copyId });
          }
        },
      });
    } else {
      setTimeout(() => {
        if (isMounted()) {
          updateAttributes({ copyId: null, id: copyId });
        }
      }, 6000); // 1s + debounce time of tiptap backend sync https://github.com/Topicflow/topicflow/blob/68e94db6bc205171d6345ee240fcd51d6104e6c1/websocket/server.js#L92
    }
  }, [id, copyId]);

  // HANDLERS
  const handleDelete = (callback?: () => void) => {
    deleteArtifact({ artifact, deleteNode, callback });
  };

  const handleCloseCreateArtifactDialog = (
    createdArtifact?: CreatedArtifactFragmentFragment
  ) => {
    if (!createdArtifact) {
      deleteNode();
    } else if (createdArtifact.artifactType !== artifactType) {
      // in case user created another type of artifact in the dialog
      // we remove previous one and add the one that was created in dialog
      deleteNode();
      editor
        .chain()
        .focus(getPos())
        .insertContent({
          type: createdArtifact.artifactType,
          attrs: { uuid: node.attrs.uuid, id: createdArtifact.id },
        })
        .run();
    } else {
      updateAttributes({ id: createdArtifact.id });
      setShowCreateArtifactDialog(false);
    }
  };

  const handleToggleDescription = () => {
    // persist the expanded state only for top level artifact
    if (!parentArtifactId) {
      const newExpandedUserIds = descriptionIsExpanded
        ? pull(expandedUserIds, String(currentUser.id))
        : [...expandedUserIds, currentUser.id];
      const cleanedExpandedIds = newExpandedUserIds.filter(
        (expandedUserId) => expandedUserId && Number(expandedUserId)
      );
      updateAttributes({ expandedUserIds: uniq(cleanedExpandedIds).join(",") });
    }
    setDescriptionIsExpanded(!descriptionIsExpanded);
  };

  // update uuid attributes if there is one in database
  useEffect(() => {
    if (id && artifact?.uuid && artifact.uuid !== node.attrs.uuid) {
      defer(() => {
        if (isMounted()) {
          updateAttributes({ uuid: artifact.uuid });
        }
      });
    }
  }, [artifact, id, node.attrs.uuid]);

  // Make sure we close any create dialog if id is set
  useEffect(() => {
    if (artifactId) {
      setShowCreateArtifactDialog(false);
    }
  }, [artifactId]);

  // Browsers tend to bug because of the draggable attribute on the parent DOM element of this component
  // It seems that contentEditable=true does not play well with draggable=true.
  // https://github.com/ueberdosis/tiptap/issues/1668#issuecomment-997755214
  // https://discuss.prosemirror.net/t/nested-draggable-editors-behave-differently-in-all-browsers/3807/13
  useEffect(() => {
    const parentNode = ref.current?.parentNode as HTMLElement;
    if (parentNode) {
      parentNode.setAttribute("draggable", "false");
    }
  }, [ref]);

  // Focus on description field when it is expanded.
  useEffect(() => {
    const st = null;
    if (descriptionIsExpanded && ref.current) {
      setTimeout(() => {
        const el = ref.current?.querySelector(
          ".js-topic-discussion-notes-input"
        ) as any;
        if (el) {
          el.editor.chain().focus().run();
        }
      }, 300);
    }
    return function cleanup() {
      if (st) clearTimeout(st);
    };
  }, [descriptionIsExpanded]);

  const handleSavedComment = (comment?: { id: number }) => {
    updateAttributes({ commentId: comment?.id || null });
  };

  const handleRemoveComment = () => {
    updateAttributes({ commentId: null });
  };

  const sidebarTo = artifact
    ? toWithBackground({
        pathname: getUrl({
          artifactId: artifact.id,
          artifactType: artifactTypeName,
          meetingGroupId: extension.options.meetingGroupId,
          meetingId: extension.options.meetingId,
        }),
        location,
      })
    : "";

  return (
    <NodeViewWrapper
      className={classNames(
        "relative",
        "pl-3 pr-2 py-0.5 rounded-lg mt-2 mb-3 flex border",
        isActionItem && "bg-violet-50 border-violet-100",
        isDecision && "bg-emerald-50 border-emerald-100",
        isDocument && "bg-gray-50 border-gray-200",
        isGoal && "bg-blue-50 border-blue-100",
        isRecognition && "bg-amber-50 border-amber-200",
        isFeedback && "bg-fuchsia-50 border-fuchsia-200",
        selected &&
          editor?.options?.editable &&
          "ring-2 ring-blue-200 ring-offset-2 extension-is-selected"
      )}
      ref={ref}
    >
      {showCreateArtifactDialog && (
        <ArtifactCreationDialog
          formOptions={{
            artifactType: artifactTypeName,
            meetingId: extension.options.meetingId,
            title: node.attrs.title || "",
          }}
          onClose={handleCloseCreateArtifactDialog}
        />
      )}
      {!artifact && loading ? (
        <div className="text-gray-500 text-sm py-1 italic flex items-center gap-2">
          <Loading mini size="5" className="shrink-0" />
          Loading {l(artifactTypeName)}
        </div>
      ) : isBeingCopiedByAnotherUser ? (
        <div className="text-gray-500 text-sm py-0.5 italic flex items-center gap-2">
          <Loading mini size="4" className="shrink-0" />
          <span className="flex-1">
            <span className="font-medium text-gray-600">
              {createdByUser.name}
            </span>{" "}
            is adding {a(l(artifactTypeName))}
          </span>
        </div>
      ) : !id && !isCreatedByCurrentUser ? (
        <div className="text-gray-500 text-sm py-0.5 italic flex items-center gap-2">
          <Loading mini size="4" className="shrink-0" />
          <span className="flex-1">
            <span className="font-medium text-gray-600">
              {createdByUser.name}
            </span>{" "}
            is creating {a(l(artifactTypeName))}
          </span>
        </div>
      ) : error &&
        errorMatches(
          error,
          "You do not have permission to access the instance with id"
        ) ? ( // USER DOES NOT HAVE PERMISSIONS TO VIEW ARTIFACT
        <ArtifactError onDeleteNode={handleDelete}>
          You don't have the permission to view this {l(artifactTypeName)}.
        </ArtifactError>
      ) : (error && errorMatches(error, "Invalid id")) || seemsDeleted ? ( // ARTIFACT HAS BEEN DELETED OR NO ACCESS
        <ArtifactError onDeleteNode={handleDelete}>
          This {l(artifactTypeName)} does not exist anymore.
        </ArtifactError>
      ) : error ? ( // UNKNOWN ERROR
        <ArtifactError onDeleteNode={handleDelete}>
          An unexpected error occurred. This {l(artifactTypeName)} can not be
          displayed.
        </ArtifactError>
      ) : artifact && artifactTypeName !== artifact?.artifactType ? ( // When artifact is added as wrong type
        <ArtifactError onDeleteNode={handleDelete}>
          <>
            We cannot display this {a(l(artifactTypeName))} because it is{" "}
            <a
              href={getUrl({
                artifactId: artifact.id,
                artifactType: artifact.artifactType,
              })}
              target="_blank"
              rel="noreferrer"
            >
              {a(l(artifact.artifactType))}
            </a>
            .
          </>
        </ArtifactError>
      ) : artifact ? (
        // ARTIFACT EXIST
        <div
          className={classNames(
            "flex-1",
            // Do not remove min-w-0, as it prevents long text/url to wrap correctly
            // https://imgur.com/a/S90aplB
            "min-w-0"
          )}
        >
          <div
            className={classNames(
              "flex flex-1 items-start min-w-0 gap-2",
              "@container/wysiwyg-artifact",
              "group", // for showing toolbar when hovering this container
              "not-prose" // do not apply tailwind typography to the component
            )}
          >
            <ArtifactToolbar
              syncedArtifact={artifact}
              onToggleDescription={handleToggleDescription}
              onRemoveFromNotes={handleDelete}
              descriptionIsExpanded={descriptionIsExpanded}
              extension={extension}
              onSavedComment={handleSavedComment}
            />
            <ArtifactIcon node={node} syncedArtifact={artifact} />
            {isRecognition || isFeedback ? (
              <AppLink
                to={sidebarTo}
                className="flex flex-1 items-center py-0.5 min-w-0 hover:underline"
              >
                <div>
                  <div className="font-medium">
                    {artifact?.__typename === "FeedbackArtifactNode"
                      ? artifact.title
                      : getRecognitionRecipientTitle(artifact)}
                  </div>
                  {artifact?.__typename === "RecognitionArtifactNode" && (
                    <div className="text-gray-600">{artifact.title}</div>
                  )}
                </div>
              </AppLink>
            ) : id ? (
              <AppLink
                to={sidebarTo}
                className="flex flex-1 items-center py-0.5 min-w-0 text-gray-600 font-medium hover:underline"
              >
                {artifact?.__typename === "DecisionArtifactNode"
                  ? artifact?.decision
                  : artifact?.title}
              </AppLink>
            ) : (
              <span className="flex flex-1 items-center py-0.5 min-w-0 text-gray-600 font-medium hover:underline gap-2">
                Creating {l(artifactTypeName)}
              </span>
            )}
            <ArtifactInfos
              syncedArtifact={artifact}
              commentId={node.attrs.commentId}
              descriptionIsExpanded={descriptionIsExpanded}
              onToggleDescription={handleToggleDescription}
              loading={loading}
              onDelete={handleDelete}
              onSavedComment={handleSavedComment}
              onRemoveComment={handleRemoveComment}
            />
          </div>
          {artifact.__typename === "GoalArtifactNode" && (
            <ArtifactComponentGoalKeyResults artifactId={artifact.id} />
          )}
          {descriptionIsExpanded && (
            <div
              key={`editor-expanded-${String(descriptionIsExpanded)}`}
              className="relative -ml-3 -mr-2 border-t border-t-black/10"
              aria-label="Artifact description container"
            >
              <ArtifactWYSIWYG
                artifact={artifact}
                className="bg-white/60 pl-5 pr-5 py-2 -mb-0.5 pb-4 rounded-b-lg"
                editable
                showFixedMenu={false}
                organizationId={artifact.organization?.id}
                showPlusButton={false}
              />
            </div>
          )}
          {commentId && (
            <ArtifactComment
              commentId={commentId}
              artifact={artifact}
              meetingGroupId={extension.options.meetingGroupId}
              meetingId={extension.options.meetingId}
              onRemoveCommentAttribute={handleSavedComment}
            />
          )}
        </div>
      ) : null}
    </NodeViewWrapper>
  );
};

export default withErrorBoundary(ArtifactComponent, {
  fallback: (
    <NodeViewWrapper>
      <AppError description={"This artifact could not be displayed."} />
    </NodeViewWrapper>
  ),
});
