import { gql } from "@apollo/client";

export default gql`
  mutation requestExportMutation {
    requestExport {
      user {
        email
      }
    }
  }
`;
