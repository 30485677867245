import { useState } from "react";
import { MeetingViewMeetingNodeNewPageFragmentFragment } from "types/graphql-schema";

import CloseButton from "@components/close-button/close-button";
import Sidebar from "@components/sidebar/sidebar";
import { classNames } from "@helpers/css";

import SuggestedTopicsSidebarPastTopics from "./components/past-topics";
import SuggestedTopicsSidebarTemplates from "./components/template-topics";

export enum suggestedTopicsTabIds {
  templates,
  pastTopics,
}
const tabs = [
  {
    id: suggestedTopicsTabIds.pastTopics,
    label: "Topics from last meetings",
  },
  {
    id: suggestedTopicsTabIds.templates,
    label: "Templates",
  },
];

const SuggestedTopicsSidebar = ({
  meeting,
  onClose,
  defaultTabId = suggestedTopicsTabIds.pastTopics,
}: {
  meeting: MeetingViewMeetingNodeNewPageFragmentFragment;
  onClose: () => void;
  defaultTabId?: suggestedTopicsTabIds;
}) => {
  const [activeTab, setActiveTab] = useState(defaultTabId);

  return (
    <Sidebar show onClose={onClose}>
      <div
        className="px-6 flex flex-col gap-6"
        aria-label="Suggested topics sidebar"
      >
        <div className="flex justify-between items-center">
          <h2 className="font-medium text-2xl">Suggested topics</h2>
          <CloseButton onClick={onClose} />
        </div>

        <div
          className="px-1 flex gap-6 text-gray-500 border-b"
          aria-label="Topics tabs"
        >
          {tabs.map((tab) => (
            <button
              key={tab.id}
              className={classNames(
                "tracking-tight sm:tracking-normal md:px-1 py-1.5 font-medium text-slate-500 shrink-0 border-b-3 border-b-transparent",
                tab.id === activeTab
                  ? "border-b-3 border-b-blue-700 text-slate-700"
                  : "hover:border-b-gray-200"
              )}
              onClick={() => setActiveTab(tab.id)}
            >
              {tab.label}
            </button>
          ))}
        </div>

        {activeTab === suggestedTopicsTabIds.templates ? (
          <SuggestedTopicsSidebarTemplates meeting={meeting} />
        ) : activeTab === suggestedTopicsTabIds.pastTopics ? (
          <SuggestedTopicsSidebarPastTopics meeting={meeting} />
        ) : null}
      </div>
    </Sidebar>
  );
};

export default SuggestedTopicsSidebar;
