import { gql } from "@apollo/client";

export default gql`
  fragment ComplianceProgramAssessmentGroup on AssessmentGroupNode {
    id
    title
    assessmentType
    hasSelfAssessment
    sections {
      edges {
        node {
          id
          title
          description
          questions {
            edges {
              node {
                id
                question {
                  ... on RangeAssessmentQuestionNode {
                    id
                    title
                    description
                    startValue
                    endValue
                    labels
                    questionType
                  }
                  ... on MultiChoiceAssessmentQuestionNode {
                    id
                    title
                    description
                    options
                    questionType
                  }
                  ... on TextAssessmentQuestionNode {
                    id
                    title
                    description
                    questionType
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  query getComplianceProgramAssessmentAnswers(
    $complianceProgramId: Int!
    $includesUserMissingAssessments: Boolean!
    $assessmentType: AssessmentType!
    $selfAssessment: Boolean!
    $after: String
  ) {
    complianceProgram(pk: $complianceProgramId) {
      id
      performanceAssessmentGroup {
        ...ComplianceProgramAssessmentGroup
      }
      managerAssessmentGroup {
        ...ComplianceProgramAssessmentGroup
      }
      peerAssessmentGroup {
        ...ComplianceProgramAssessmentGroup
      }
      assessments(
        state: submitted
        assessmentType: $assessmentType
        selfAssessment: $selfAssessment
        first: 20
        after: $after
      ) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            id
            state
            responder {
              id
              name
              avatar
            }
            target {
              id
              name
              avatar
            }
            answers {
              edges {
                node {
                  ... on RangeAssessmentAnswerNode {
                    id
                    question {
                      id
                    }
                    integerAnswer
                  }
                  ... on MultiChoiceAssessmentAnswerNode {
                    id
                    question {
                      id
                    }
                    choices
                  }
                  ... on TextAssessmentAnswerNode {
                    id
                    question {
                      id
                    }
                    textAnswer
                  }
                }
              }
            }
            group {
              id
            }
          }
        }
      }
      usersMissingAssessment @include(if: $includesUserMissingAssessments) {
        edges {
          node {
            responder {
              id
              name
              avatar
            }
            target {
              id
              name
              avatar
            }
            assessmentGroup {
              id
            }
          }
        }
      }
    }
  }
`;
