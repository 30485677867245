import { gql } from "@apollo/client";

import WYSIWYGArtifactFragment from "./artifact-fragment";

const getWysiwygArtifactQuery = gql`
  ${WYSIWYGArtifactFragment}
  query getWysiwygArtifactQuery($artifactId: Int!) {
    artifact(artifactId: $artifactId) {
      ...WYSIWYGArtifactFragment
    }
  }
`;

export default getWysiwygArtifactQuery;
