import { gql } from "@apollo/client";

import KPISummarizedMeasurementFragment from "@apps/kpis/graphql/kpi-summarized-measurement-fragment";

export default gql`
  ${KPISummarizedMeasurementFragment}

  query getEmbedKpiQuery(
    $kpiId: Int!
    $summaryPeriod: KPISummaryPeriod
    $summaryMethod: KPISummaryMethod
    $first: Int = 4
  ) {
    kpi(kpiId: $kpiId) {
      id
      title
      created
      targetType
      targetValue
      targetCondition
      defaultSummaryPeriod
      canUpdate {
        permission
        reason
      }
      currentMeasurement {
        id
        measurement
      }
      summarizedMeasurements(
        first: $first
        summaryMethod: $summaryMethod
        summaryPeriod: $summaryPeriod
      ) {
        edges {
          node {
            ...KPISummarizedMeasurementFragment
          }
        }
      }
    }
  }
`;
