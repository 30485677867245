// more efficient than moment().isBetween
export const nowIsBetweenDates = (
  startDate: string | Date,
  endDate: string | Date
) => {
  const sd = typeof startDate === "string" ? new Date(startDate) : startDate;
  const ed = typeof endDate === "string" ? new Date(endDate) : endDate;
  const now = new Date();

  return now >= sd && now <= ed;
};

export const dateIsBetweenDates = (
  relativeDate: string | Date,
  startDate: string | Date,
  endDate: string | Date
) => {
  const sd = typeof startDate === "string" ? new Date(startDate) : startDate;
  const ed = typeof endDate === "string" ? new Date(endDate) : endDate;
  const rd =
    typeof relativeDate === "string" ? new Date(relativeDate) : relativeDate;
  return rd >= sd && rd <= ed;
};

export const addSecondsToDate = (date: Date, seconds: number) => {
  date.setSeconds(date.getSeconds() + seconds);
  return date;
};

export const resetDateSeconds = (date: Date) => {
  date.setSeconds(0, 0);
  return date;
};
