import { useMutation } from "@apollo/client";
import moment from "moment";
import { FormEvent, useState } from "react";
import DatePicker from "react-datepicker";
import { useLocation } from "react-router-dom";
import {
  CreateOrUpdateKpiMeasurementMutationMutation,
  CreateOrUpdateKpiMeasurementMutationMutationVariables,
} from "types/graphql-schema";

import Button, { buttonTheme } from "@components/button/button";
import Input from "@components/input/input";
import Loading from "@components/loading/loading";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import {
  classNames,
  inputBorderClassName,
  inputFocusClassName,
} from "@helpers/css";

import createOrUpdateKpiMeasurementMutation from "../graphql/create-or-update-kpi-measurement-mutation";
import getKpiQuery from "../graphql/get-kpi-query";

const KPIAddMeasurementForm = ({
  kpi,
  onCreated,
}: {
  kpi: {
    id: number;
  };
  onCreated: () => void;
}) => {
  const [formValidationError, setFormValidationError] = useState("");
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const urlDate = searchParams.has("date")
    ? moment(searchParams.get("date"))
        .hours(moment().hours())
        .minutes(moment().minutes())
    : moment();
  const [form, setForm] = useState<{
    measurement: string;
    datetime: Date | null;
  }>({
    measurement: "0",
    datetime: urlDate.toDate(),
  });
  const [createKpiMeasurement, { loading }] = useMutation<
    CreateOrUpdateKpiMeasurementMutationMutation,
    CreateOrUpdateKpiMeasurementMutationMutationVariables
  >(createOrUpdateKpiMeasurementMutation);

  const handleSubmitForm = (e: FormEvent) => {
    e.preventDefault();
    if (Number.isNaN(form.measurement)) {
      setFormValidationError("Measurement value has to be a valid number");
      return;
    }
    if (!form.datetime) {
      setFormValidationError("Please enter a valid date");
      return;
    }
    if (moment(form.datetime).isAfter(moment().add(1, "minute"))) {
      setFormValidationError("Please enter a date that is not in the future.");
      return;
    }

    createKpiMeasurement({
      variables: {
        measurement: Number(form.measurement),
        timestamp: moment(form.datetime).format(),
        kpiId: kpi.id,
      },
      onError: onNotificationErrorHandler(),
      refetchQueries: [getKpiQuery],
      onCompleted: onCreated,
    });
  };

  // RENDER
  return (
    <form
      className="flex flex-col gap-6 border rounded-lg mb-6 p-6 bg-gray-50"
      onSubmit={handleSubmitForm}
    >
      <div className="max-w-96">
        <Input
          label="Measurement value"
          value={form.measurement}
          onChange={(e) =>
            setForm({
              ...form,
              measurement: e.target.value,
            })
          }
        />
      </div>
      <div className="max-w-96">
        <DatePicker
          selected={form.datetime}
          onChange={(datetime) => setForm({ ...form, datetime })}
          timeInputLabel="Time:"
          dateFormat="MM/dd/yyyy h:mm aa"
          maxDate={new Date()}
          showTimeInput
          placeholderText="Time stamp"
          className={classNames(
            "px-4 py-2 text-sm w-full",
            inputBorderClassName,
            inputFocusClassName
          )}
        />
      </div>
      {formValidationError && (
        <div className="text-sm text-red-700">{formValidationError}</div>
      )}
      <div className="flex gap-2">
        <Button
          disabled={loading}
          text="Add measurement"
          theme={buttonTheme.primary}
          type="submit"
        />
        {loading && <Loading mini size="5" />}
      </div>
    </form>
  );
};

export default KPIAddMeasurementForm;
