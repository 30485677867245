import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import {
  GetDashboardSelectedUserQueryQuery,
  GetDashboardSelectedUserQueryQueryVariables,
} from "types/graphql-schema";

import getDashboardSelectedUserQuery from "@apps/dashboard-new/graphql/get-dashboard-selected-user-query";
import MeetingsLayout from "@apps/meetings/layout";
import { MeetingsViewEnum } from "@apps/use-ui-preference-cache/use-ui-preference-cache";
import { currentUserVar } from "@cache/cache";
import AppError from "@components/error/error";
import Loading from "@components/loading/loading";
import useDocumentTitle from "@components/use-document-title/use-document-title";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { UserComboboxOptionType } from "@components/user-combobox/user-combobox-list";

import Calendar from "./calendar";

const CalendarPage = () => {
  const currentUser = currentUserVar();
  useDocumentTitle("Calendar");

  const { userId } = useParams<{ userId: string | undefined }>();
  const selectedUserId = userId ? parseInt(userId) : currentUser.id;

  const { data, loading } = useQuery<
    GetDashboardSelectedUserQueryQuery,
    GetDashboardSelectedUserQueryQueryVariables
  >(getDashboardSelectedUserQuery, {
    variables: { userId: selectedUserId },
    onError: onNotificationErrorHandler(),
  });
  const selectedUser = data?.user;

  // RENDER
  return !data && loading ? (
    <div className="mt-10">
      <Loading />
    </div>
  ) : !selectedUser ? (
    <AppError title="User cannot be found" />
  ) : (
    <MeetingsLayout
      view={MeetingsViewEnum.calendar}
      selectedUser={{ type: UserComboboxOptionType.USER, ...selectedUser }}
    >
      <Calendar selectedUser={selectedUser} />
    </MeetingsLayout>
  );
};

export default CalendarPage;
