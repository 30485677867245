import { XIcon } from "@heroicons/react/outline";

import Loading from "@components/loading/loading";

const ArtifactSidebarLoading = ({ onClose }: { onClose: () => void }) => {
  return (
    <div className="-mt-3 px-6" aria-label="Loading sidebar topic">
      <div className="flex justify-end">
        <button
          type="button"
          className="ml-3 bg-white rounded p-1 text-gray-400 hover:text-gray-500 hover:bg-gray-100"
          aria-label="Close artifact sidebar"
          onClick={onClose}
        >
          <span className="sr-only">Close sidebar</span>
          <XIcon className="h-6 w-6" />
        </button>
      </div>
      <div className="pt-16">
        <Loading>Loading...</Loading>
      </div>
    </div>
  );
};

export default ArtifactSidebarLoading;
