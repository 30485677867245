import { gql } from "@apollo/client";

import topicTemplateCategoryFragment from "./topic-template-category-fragment";
import TopicTemplateFragment from "./topic-template-fragment";

export default gql`
  ${TopicTemplateFragment}
  ${topicTemplateCategoryFragment}
  query getTopicTemplateToEditQuery(
    $templateId: Int!
    $hasTemplateId: Boolean!
  ) {
    topicTemplate(topicTemplateId: $templateId) @include(if: $hasTemplateId) {
      ...TopicTemplateFragment
      canUpdateRequirements
    }
    topicTemplateCategories {
      edges {
        node {
          ...TopicTemplateCategoryFragment
        }
      }
    }
  }
`;
