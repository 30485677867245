import { gql } from "@apollo/client";

const CommonFeedbackArtifactFragment = gql`
  fragment CommonFeedbackArtifactFragment on FeedbackArtifactNode {
    id
    artifactType
    title
    recipientsCanView
    recipientsManagersCanView
    adminsCanView
    creatorCanView
    feedbackState
    message
    creator {
      id
      name
      avatar
    }
    feedbackRecipients: recipients {
      totalCount
      edges {
        node {
          id
          avatar
          name
        }
      }
    }
    sender {
      id
      avatar
      name
    }
    created
  }
`;

export default CommonFeedbackArtifactFragment;
