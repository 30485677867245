import { Route, Switch, useRouteMatch } from "react-router-dom";

import { isAdminVar } from "@cache/cache";
import AppLink from "@components/link/link";
import useDocumentTitle from "@components/use-document-title/use-document-title";

import ComplianceProgram from "../programs/components/compliance-program";
import CompliancePrograms from "../programs/components/compliance-programs";
import ComplianceProgramEdit from "../programs/components/edit-compliance-program";
import ComplianceProgramUserActivity from "./components/compliance-program-user-activity";

const Programs = () => {
  useDocumentTitle("Programs");
  const { path } = useRouteMatch();
  const isAdmin = isAdminVar();

  return (
    <div aria-label="Programs" className="flex flex-col flex-1 fs-unmask">
      <div className="flex flex-wrap items-center justify-between h-14 px-4 sm:px-6 border-b bg-gray-50">
        <AppLink
          className="mb-2 sm:mb-0 text-2xl font-semibold mr-8 flex items-center"
          to="/programs"
        >
          Programs
        </AppLink>
      </div>
      <div className="p-6">
        <div className="bg-white flex rounded-lg shadow-md ">
          <Switch>
            <Route exact path={path}>
              <CompliancePrograms />
            </Route>
            <Route exact path={`${path}/:complianceProgramId(\\d+)`}>
              <ComplianceProgramUserActivity />
            </Route>
            {isAdmin && (
              <Route exact path={`${path}/:complianceProgramId(\\d+)/admin`}>
                <ComplianceProgram />
              </Route>
            )}
            {isAdmin && (
              <Route
                exact
                path={[
                  `${path}/new`,
                  `${path}/:complianceProgramId(\\d+)/edit`,
                  `${path}/:complianceProgramId(\\d+)/duplicate`,
                ]}
              >
                <ComplianceProgramEdit />
              </Route>
            )}
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default Programs;
