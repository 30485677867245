import { useQuery } from "@apollo/client";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  GetAssessmentQuery,
  GetAssessmentQueryVariables,
} from "types/graphql-schema";

import AskAI from "@apps/ask-ai/ask-ai";
import RelatedRequirements from "@apps/assessments/components/new-assessment-page/related-requirements";
import Feedbacks from "@apps/dashboard-new/components/feedbacks";
import Recognitions from "@apps/dashboard-new/components/recognitions";
import useLabel from "@apps/use-label/use-label";
import WorkHistorySidebar from "@apps/work-history/components/work-history-sidebar";
import { currentOrganizationVar } from "@cache/cache";
import Layout from "@components/layout/layout";
import { ToggleButtonGroupType } from "@components/toggle-button-group/toggle-button-group";
import useDocumentTitle from "@components/use-document-title/use-document-title";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { classNames } from "@helpers/css";
import { assertEdgesNonNull, assertNonNull } from "@helpers/helpers";

import getAssessmentQuery from "../../graphql/get-assessment-query";
import { bgClassName } from "../../helpers";
import AssessmentGoalSidebar from "./assessment-goal-sidebar";
import AssessmentRightCol from "./assessment-right-col";
import AssessmentSidebarDetails from "./assessment-sidebar-details";

type AssessmentAnswer = {
  questionId: number;
  integerAnswer?: number | null;
  textAnswer: string | null;
  choices?: (number | null)[] | null;
  comment: string | null;
};

enum AssessmentSidebarTab {
  details = "Details",
  artifacts = "Items",
  workHistory = "Work history",
  askAI = "Ask AI",
}

const getAnswersFromAssessment = (
  assessment: GetAssessmentQuery["assessment"]
) => {
  const answersEdges = assertEdgesNonNull(assertNonNull(assessment).answers);
  return answersEdges.map((a) => ({
    questionId: a.question.id,
    integerAnswer:
      a.__typename === "RangeAssessmentAnswerNode" ? a.integerAnswer : null,
    textAnswer:
      a.__typename === "TextAssessmentAnswerNode" ? a.textAnswer : null,
    choices:
      a.__typename === "MultiChoiceAssessmentAnswerNode"
        ? [...a.choices]
        : null,
    comment: a.comment,
  }));
};

const AssessmentNew = () => {
  const [chatSessionId, setChatSessionId] = useState<null | number>(null);
  const [activeTab, setActiveTab] = useState(AssessmentSidebarTab.details);
  const [proposedAnswers, setProposedAnswers] = useState<AssessmentAnswer[]>(
    []
  );
  const { assessmentId: assessmentIdParam } = useParams<{
    assessmentId: string;
  }>();
  const assessmentId = parseInt(assessmentIdParam);
  const organization = currentOrganizationVar();
  const label = useLabel();
  useDocumentTitle(label("review", { capitalize: true, pluralize: true }));

  const { data: myAssessmentsData, loading: isLoadingAssessment } = useQuery<
    GetAssessmentQuery,
    GetAssessmentQueryVariables
  >(getAssessmentQuery, {
    variables: {
      assessmentId,
      organizationId: organization?.id,
    },
    onCompleted: (response) => {
      setProposedAnswers(getAnswersFromAssessment(response.assessment));
    },
    onError: onNotificationErrorHandler(),
  });

  const assessment = useMemo(
    () => (myAssessmentsData ? myAssessmentsData.assessment : null),
    [myAssessmentsData]
  );
  const complianceProgram = useMemo(
    () => (assessment ? assertNonNull(assessment.complianceProgram) : null),
    [assessment]
  );
  const complainceProgramPeriod = useMemo(
    () =>
      complianceProgram?.periodStartDate && complianceProgram?.periodEndDate
        ? [complianceProgram.periodStartDate, complianceProgram.periodEndDate]
        : undefined,
    [complianceProgram]
  );
  const target = useMemo(
    () => (assessment ? assertNonNull(assessment.target) : null),
    [assessment]
  );

  const relatedMeetings = assessment?.relatedMeetings
    ? assertEdgesNonNull(assessment?.relatedMeetings)
    : [];

  const handleChangeTab = (
    tab: ToggleButtonGroupType<AssessmentSidebarTab>
  ) => {
    if (tab.value !== undefined) setActiveTab(tab.value);
  };

  useEffect(() => {
    // reset chat session when changing assessment id
    setChatSessionId(null);
  }, [assessmentId]);

  return (
    <Layout className="bg-white">
      <Layout.Header
        title={label("review", { capitalize: true, pluralize: true })}
        sidebarExpandedUiPreferenceKey="assessmentSidebarExpanded"
        className="bg-white"
      />
      <Layout.Container loading={isLoadingAssessment}>
        <Layout.Main sidebarExpandedUiPreferenceKey="assessmentSidebarExpanded">
          {assessment ? (
            <div className="p-8">
              <div
                className={classNames(
                  "flex flex-col lg:flex-row",
                  "border rounded-lg"
                )}
                aria-label="Assessments > performance assessment form"
              >
                <AssessmentRightCol
                  myAssessmentsData={myAssessmentsData}
                  isLoadingAssessment={isLoadingAssessment}
                  proposedAnswers={proposedAnswers}
                  onChangeProposedAnswers={setProposedAnswers}
                  newDesign
                />
              </div>
            </div>
          ) : (
            <div
              className={classNames(bgClassName, "flex-1 flex justify-center")}
            >
              {label("review", { capitalize: true })} not found
            </div>
          )}
        </Layout.Main>
        <Layout.Sidebar<AssessmentSidebarTab>
          sidebarExpandedUiPreferenceKey="assessmentSidebarExpanded"
          className="bg-white"
          onChangeTab={handleChangeTab}
          tabs={[
            {
              title: AssessmentSidebarTab.details,
              value: AssessmentSidebarTab.details,
              active: activeTab === AssessmentSidebarTab.details,
            },
            {
              title: AssessmentSidebarTab.artifacts,
              value: AssessmentSidebarTab.artifacts,
              active: activeTab === AssessmentSidebarTab.artifacts,
            },
            {
              title: AssessmentSidebarTab.workHistory,
              value: AssessmentSidebarTab.workHistory,
              active: activeTab === AssessmentSidebarTab.workHistory,
            },
            {
              title: AssessmentSidebarTab.askAI,
              value: AssessmentSidebarTab.askAI,
              active: activeTab === AssessmentSidebarTab.askAI,
            },
          ]}
          fullHeight={activeTab === AssessmentSidebarTab.askAI}
        >
          {activeTab === AssessmentSidebarTab.askAI ? (
            <AskAI
              chatSessionId={chatSessionId}
              onChangeChatSessionId={setChatSessionId}
            />
          ) : activeTab === AssessmentSidebarTab.workHistory ? (
            <WorkHistorySidebar
              userId={assertNonNull(assessment?.target).id}
              startDate={moment().subtract(7, "days").format("YYYY-MM-DD")}
              endDate={moment().format("YYYY-MM-DD")}
            />
          ) : activeTab === AssessmentSidebarTab.details ? (
            <Layout.SidebarSection>
              <AssessmentSidebarDetails
                myAssessmentsData={myAssessmentsData}
                isLoadingAssessment={isLoadingAssessment}
              />
            </Layout.SidebarSection>
          ) : activeTab === AssessmentSidebarTab.artifacts ? (
            <>
              {relatedMeetings.length === 0 &&
                !organization.featureFlags.goals &&
                !organization.featureFlags.recognitions &&
                !organization.featureFlags.feedbacks && (
                  <Layout.SidebarSection>
                    <Layout.SidebarSubSectionEmpty>
                      No related data.
                    </Layout.SidebarSubSectionEmpty>
                  </Layout.SidebarSection>
                )}
              <RelatedRequirements relatedMeetings={relatedMeetings} />

              {organization.featureFlags.goals && target && (
                <AssessmentGoalSidebar
                  selectedUser={target}
                  goalDueBetweenDates={complainceProgramPeriod}
                />
              )}

              {organization.featureFlags.recognitions && target && (
                <Recognitions
                  selectedUser={target}
                  createdBetweenDates={complainceProgramPeriod}
                />
              )}
              {organization.featureFlags.feedbacks && target && (
                <Feedbacks
                  selectedUser={target}
                  createdBetweenDates={complainceProgramPeriod}
                />
              )}
            </>
          ) : null}
        </Layout.Sidebar>
      </Layout.Container>
    </Layout>
  );
};

export default AssessmentNew;
