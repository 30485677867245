import Cookies from "js-cookie";
import Pusher from "pusher-js";

let pusher_client = null;
if (import.meta.env.NODE_ENV !== "test") {
  if (import.meta.env.DEV) {
    Pusher.logToConsole = String(import.meta.env.VITE_PUSHER_SHOW_LOG) === "1";
  }
  pusher_client = new Pusher(import.meta.env.VITE_PUSHER_CLIENT_ID, {
    cluster: "us3",
    auth: { headers: { "X-CSRFToken": Cookies.get("csrftoken") } },
  });
}

export const pusher = pusher_client;
