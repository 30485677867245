import Loading from "@components/loading/loading";

export const CollapsiblePaginate = ({
  hasNextPage,
  loading,
  onClickMore,
}: {
  hasNextPage: boolean;
  loading: boolean;
  onClickMore: () => void;
}) => {
  return hasNextPage ? (
    <div className="px-4 py-2 flex items-center justify-center">
      {loading ? (
        <Loading size="5" mini />
      ) : (
        <button
          className="text-gray-400 hover:text-gray-500 text-center text-xs mr-4 hover:bg-gray-100 rounded px-1.5 py-0.5"
          onClick={onClickMore}
          disabled={loading}
        >
          View more
        </button>
      )}
    </div>
  ) : null;
};

export default CollapsiblePaginate;
