import { EditorContent, useEditor } from "@tiptap/react";
import { ArtifactType } from "types/graphql-schema";

import { classNames } from "@helpers/css";

const WYSIWYGOverlayDefaultNotes = ({
  extensions,
  overlayValue,
  className,
  disabled,
  editorProps = {},
  extraContext,
  onSetDefaultNotes,
}: {
  disabled: boolean;
  extensions: any;
  className?: string;
  overlayValue?: any;
  editorProps?: any;
  extraContext: {
    topicId?: number;
    relatedTopicId?: number;
    meetingId?: number;
    meetingDate?: string;
    meetingGroupId?: number;
    organizationId?: number;
    relatedArtifactId?: number;
    relatedArtifactType?: ArtifactType;
  };
  onSetDefaultNotes: () => void;
}) => {
  const editorOverlay = useEditor({
    autofocus: false,
    editable: false,
    // when creating/updating extensions, never rename extension.name
    // otherwise previous discussion notes won't be able to
    // render the content of that extension.
    extensions,
    editorProps: {
      attributes: {
        class: classNames(
          "prose max-w-full pl-7 focus:outline-none break-words relative js-overlay-default-notes",
          className
        ),
      },
      ...extraContext,
      ...editorProps,
    },
    content: overlayValue,
  });

  return (
    <div
      onClick={!disabled ? onSetDefaultNotes : undefined}
      className={classNames(
        "js-overlay-default-notes-cta",
        !disabled && "cursor-pointer"
      )}
    >
      <div className="relative group pointer-events-none">
        <div className="flex absolute inset-0 items-center justify-center bg-white/50 z-1 gap-4" />
        <EditorContent editor={editorOverlay} />
      </div>
    </div>
  );
};

export default WYSIWYGOverlayDefaultNotes;
