import { gql } from "@apollo/client";

export default gql`
  mutation removeZoomIntegrationMutation {
    removeZoomIntegration {
      user {
        id
        hasZoomAuth
      }
    }
  }
`;
