import { useLazyQuery } from "@apollo/client";
import { captureException } from "@sentry/react";
import { useCallback } from "react";

import cache from "@cache/cache";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import usePusher from "@components/use-pusher/use-pusher";
import { matchApolloErrorMessage } from "@helpers/helpers";

import getTopicQuery from "../graphql/get-topic-query";

export default function PusherTopicReordered({
  channelName,
}: {
  channelName: string;
}) {
  const [fetchTopic] = useLazyQuery(getTopicQuery, {
    onError: onNotificationErrorHandler(),
  });

  const handleTopicMoved = useCallback(
    ({ topic }: { topic: { id: number } }) => {
      // If it's in the cache then we update
      const topicCacheId = cache.identify(topic);
      if (topicCacheId) {
        fetchTopic({
          variables: { topicId: topic.id },
        }).catch((errors) => {
          if (matchApolloErrorMessage(errors, "Invalid id")) {
            return;
          }
          captureException(errors);
        });
      }
    },
    []
  );

  usePusher(channelName, "topics_reordered", handleTopicMoved);

  return null;
}
