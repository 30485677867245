import { useLazyQuery } from "@apollo/client";
import { useCallback } from "react";

import getCommentsQuery from "@apps/comments/graphql/get-comments-query";
import cache, { currentUserVar } from "@cache/cache";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import usePusher from "@components/use-pusher/use-pusher";

export default function PusherCommentAddedOrUpdated({
  channelName,
}: {
  channelName: string;
}) {
  const currentUser = currentUserVar();

  const [fetchComments] = useLazyQuery(getCommentsQuery, {
    onError: onNotificationErrorHandler(),
  });

  const handleCommentAddedOrUpdated = useCallback(
    ({
      topic,
      artifact,
    }: {
      topic: { id: number };
      artifact: { id: number };
    }) => {
      if (topic) {
        const topicCacheId = cache.identify(topic);
        if (topicCacheId) {
          fetchComments({ variables: { topicId: topic.id } });
        }
      }
      if (artifact) {
        const artifactCacheId = cache.identify(artifact);
        if (artifactCacheId) {
          fetchComments({ variables: { artifactId: artifact.id } });
        }
      }
    },
    [fetchComments, currentUser]
  );

  usePusher(channelName, "comment_added", handleCommentAddedOrUpdated);
  usePusher(channelName, "comment_updated", handleCommentAddedOrUpdated);
  return null;
}
