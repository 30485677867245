import { gql } from "@apollo/client";

const CommonGoalArtifactFragment = gql`
  fragment CommonGoalArtifactFragment on GoalArtifactNode {
    id
    title
    created
    artifactType
    state
    status
    scope
    goalVisibility
    dueDate
    startDate
    progress
    progressType
    startValue
    currentValue
    targetValue
    isStale
    firstOwner {
      id
      avatar
      name
    }
    ownerCount
    contributorCount
    canDelete {
      permission
    }
    canUpdate {
      permission
    }
    canRead {
      permission
    }
  }
`;

export default CommonGoalArtifactFragment;
