import { gql } from "@apollo/client";

export default gql`
  mutation removeUserFromOrganizationMutation(
    $organizationId: Int!
    $userToRemoveId: Int!
  ) {
    removeUserFromOrganization(
      orgId: $organizationId
      userToRemoveId: $userToRemoveId
    ) {
      org {
        id
      }
    }
  }
`;
