import { gql } from "@apollo/client";

import IndividualNoteNodeFragment from "./individual-note-node-fragment";

export default gql`
  ${IndividualNoteNodeFragment}
  mutation toggleTopicIndividualNoteVisibilityNewPageMutation(
    $topicIds: [Int]!
    $hidden: Boolean
    $enabled: Boolean
  ) {
    toggleIndividualNoteVisibility(
      topicIds: $topicIds
      hidden: $hidden
      enabled: $enabled
    ) {
      topics(topicIds: $topicIds) {
        edges {
          node {
            id
            includesIndividualNotesForCurrentUser
            includesSharedNotes
            individualNotes {
              edges {
                node {
                  ...IndividualNoteNodeFragment
                }
              }
            }
          }
        }
      }
    }
  }
`;
