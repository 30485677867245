import { gql } from "@apollo/client";

export default gql`
  query getTopic($topicId: Int!) {
    topic(topicId: $topicId) {
      id
      title
      description
      discussionNotes
      includesIndividualNotes
      includesIndividualNotesForCurrentUser
      state
      created
      assignee {
        id
        avatar
      }
      creator {
        id
        name
      }
      meeting {
        id
        topics {
          edges {
            node {
              id
            }
          }
        }
      }
    }
  }
`;
