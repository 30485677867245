import { gql } from "@apollo/client";

const UserMentionFragment = gql`
  fragment UserMentionFragment on UserNode {
    id
    name
    avatar
  }
`;

export default UserMentionFragment;
