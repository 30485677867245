import { gql } from "@apollo/client";

const createOrUpdateRatingAnswerMutation = gql`
  mutation createOrUpdateRatingAnswerMutation(
    $integerAnswer: Int!
    $meetingId: Int
    $ratingAnswerId: Int
    $ratingArtifactId: Int
    $meetingRatingRequirementId: Int
  ) {
    createOrUpdateRatingAnswer(
      integerAnswer: $integerAnswer
      meetingId: $meetingId
      meetingRatingRequirementId: $meetingRatingRequirementId
      ratingAnswerId: $ratingAnswerId
      ratingArtifactId: $ratingArtifactId
    ) {
      ratingRequirement {
        id
        ratingArtifact {
          id
          canAnswer
          canViewAnswers
          artifactType
          answers {
            totalCount
            edges {
              node {
                id
                integerAnswer
                creator {
                  id
                }
              }
            }
          }
        }
      }
      ratingAnswer {
        id
        integerAnswer
        creator {
          id
        }
        createdInMeeting {
          id
        }
        rating {
          id
        }
        createdFromArtifact {
          id
          canAnswer
          canViewAnswers
          answers {
            totalCount
            edges {
              node {
                id
                integerAnswer
                creator {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default createOrUpdateRatingAnswerMutation;
