import { useQuery } from "@apollo/client";
import { useRef, useState } from "react";
import { useEffect } from "react";
import {
  GetInlineCommentsQueryQuery,
  GetInlineCommentsQueryQueryVariables,
} from "types/graphql-schema";

import { currentUserVar } from "@cache/cache";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import useWindowSize from "@components/use-window-size/use-window-size.jsx";
import { assertEdgesNonNull } from "@helpers/helpers";

import getInlineCommentsQuery from "../../graphql/get-inline-comments-query";
import CommentForm from "./comment-form.jsx";
import CommentItem from "./comment-item.jsx";

const CommentContainer = ({
  uuid,
  atMentionSuggestions,
  uploadVariable,
  topicId,
  artifactId,
  onResolve,
  onCancel,
}: {
  uuid: string;
  atMentionSuggestions: any;
  uploadVariable: () => void;
  topicId?: number;
  artifactId?: number;
  onResolve: () => void;
  onCancel: () => void;
}) => {
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const currentUser = currentUserVar();

  const [scrollContainerPosition, setScrollContainerPosition] = useState(0);
  const [scrollContainerHeights, setScrollContainerHeights] = useState<{
    offsetHeight: number;
    scrollHeight: number;
  }>({
    offsetHeight: 0,
    scrollHeight: 0,
  });
  useWindowSize();

  const {
    data,
    loading: loadingFetch,
    refetch,
  } = useQuery<
    GetInlineCommentsQueryQuery,
    GetInlineCommentsQueryQueryVariables
  >(getInlineCommentsQuery, {
    skip: !uuid,
    variables: {
      uuid,
    },
    onError: onNotificationErrorHandler(),
  });

  const handleScrollContainer = () => {
    if (scrollContainerRef.current) {
      setScrollContainerPosition(scrollContainerRef.current.scrollTop);
    }
  };

  const updateScrollHeights = () => {
    setTimeout(() => {
      setScrollContainerHeights({
        offsetHeight: scrollContainerRef.current?.offsetHeight || 0,
        scrollHeight: scrollContainerRef.current?.scrollHeight || 0,
      });
    }, 0);
  };

  useEffect(() => {
    updateScrollHeights();
  }, [scrollContainerPosition, data]);

  const comments = data?.comments ? assertEdgesNonNull(data.comments) : [];
  const isScrolling =
    scrollContainerHeights.offsetHeight < scrollContainerHeights.scrollHeight;
  const scrollIsAtTheEnd =
    scrollContainerPosition + scrollContainerHeights.offsetHeight ===
    scrollContainerHeights.scrollHeight;
  const canResolve = comments?.[0]?.creator?.id === currentUser.id;
  return (
    <div
      className="flex flex-col w-72 divide-y"
      aria-label="Comment menu container"
    >
      {comments.length > 0 && (
        <div className="relative">
          <div
            className="overflow-y-scroll max-h-96 flex flex-col divide-y relative"
            ref={scrollContainerRef}
            onScroll={handleScrollContainer}
          >
            {comments.map((node) => (
              <CommentItem
                key={node.id}
                comment={node}
                onDelete={refetch}
                topicId={topicId}
                uuid={uuid}
                uploadVariable={uploadVariable}
                atMentionSuggestions={atMentionSuggestions}
              />
            ))}
          </div>
          {isScrolling && scrollContainerPosition > 0 && (
            <div className="absolute bg-gradient-to-b from-black/10 via-transparent h-4 w-full top-0 left-0 right-0 rounded-t">
              &nbsp;
            </div>
          )}
          {isScrolling && !scrollIsAtTheEnd && (
            <div className="absolute bg-gradient-to-t from-black/10 via-transparent h-4 w-full bottom-0 left-0 right-0">
              &nbsp;
            </div>
          )}
        </div>
      )}
      <CommentForm
        atMentionSuggestions={atMentionSuggestions}
        uploadVariable={uploadVariable}
        uuid={uuid}
        canResolve={canResolve}
        topicId={topicId}
        artifactId={artifactId}
        isLoading={!data && loadingFetch}
        onResolveComment={onResolve}
        onCancel={onCancel}
      />
    </div>
  );
};
export default CommentContainer;
