import { gql } from "@apollo/client";

export default gql`
  mutation createTemplateFromMeetingMutation(
    $meetingId: Int!
    $title: String!
  ) {
    createTemplateFromMeeting(meetingId: $meetingId, title: $title) {
      meeting {
        id
      }
      topicTemplate {
        id
      }
    }
  }
`;
