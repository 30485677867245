import { useMutation, useQuery } from "@apollo/client";
import { range } from "lodash";
import moment from "moment";
import { useCallback, useState } from "react";
import { GetRatingQuery, GetRatingQueryVariables } from "types/graphql-schema";

import Button, { buttonTheme } from "@components/button/button";
import AppLink, { useLink } from "@components/link/link";
import Loading from "@components/loading/loading";
import {
  Table,
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableContainer,
  TableFooter,
  TableHeadCell,
  TableHeadRow,
} from "@components/table/table";
import TimeAgoCustom from "@components/time-ago/time-ago";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import TextareaWysiwyg from "@components/wysiwyg/textarea-wysiwyg";
import { classNames } from "@helpers/css";
import {
  assertEdgesNonNull,
  assertNonNull,
  getUrl,
  parseStringToJSON,
} from "@helpers/helpers";
import useConfirm from "@helpers/hooks/use-confirm";
import usePkParams from "@helpers/hooks/use-pk-params";
import { pluralize } from "@helpers/string";

import deleteRatingMutation from "../graphql/delete-rating-mutation";
import getRatingQuery from "../graphql/get-rating-query";

const RatingsView = () => {
  const ratingId = usePkParams("ratingId");
  const link = useLink();
  const [displayedTabId, setDisplayedTabId] = useState<string | null>(null);
  const [deleteRating, { loading: loadingDelete }] =
    useMutation(deleteRatingMutation);
  const { data, loading, fetchMore } = useQuery<
    GetRatingQuery,
    GetRatingQueryVariables
  >(getRatingQuery, {
    variables: {
      ratingId,
      after: null,
    },
    onError: onNotificationErrorHandler(),
  });
  const { ConfirmationDialog, confirm } = useConfirm(
    "Are you sure?",
    "Are you sure you want to delete this rating?"
  );

  const handleClickDelete = useCallback(async () => {
    const confirmation = await confirm();
    if (confirmation) {
      deleteRating({
        variables: { ratingId },
        onError: onNotificationErrorHandler(),
        onCompleted: () => {
          link.redirect("/ratings");
        },
      });
    }
  }, [confirm, deleteRating, link, ratingId]);

  const handleClickViewMore = () => {
    fetchMore({
      variables: {
        after: assertNonNull(data?.rating?.answers?.pageInfo.endCursor),
        merge: true,
      },
    });
  };

  const rating = data?.rating;
  const labelsByAnswer = rating
    ? range(rating.startValue, rating.endValue).reduce(
        (memo: any, value, index) => {
          memo[value] = rating.labels[index] || value;
          return memo;
        },
        {}
      )
    : {};
  const templates = data ? assertEdgesNonNull(data.topicTemplates) : [];

  const tabs = [
    {
      id: null,
      name: `Answers (${rating?.answers?.totalCount || 0})`,
    },
    {
      id: "templates",
      name: `Templates (${templates.length})`,
    },
  ];

  // RENDER
  return (
    <div className="w-full flex flex-col gap-6" aria-label="Ratings view">
      <ConfirmationDialog />
      {(loading && !data) || !rating ? (
        <Loading>Loading rating...</Loading>
      ) : (
        <>
          <div>
            <div className="flex justify-between items-center">
              <div className="text-xl font-medium">{rating.title}</div>
              <div className="shrink-0 flex">
                <div className="flex gap-4">
                  {rating.canUpdate?.permission && (
                    <Button
                      text="Download csv"
                      url={`/ratings/export/${rating.id}`}
                      disabled={loadingDelete}
                    />
                  )}
                  {rating.canUpdate?.permission && (
                    <Button
                      text="Delete"
                      disabled={loadingDelete}
                      theme={buttonTheme.redDanger}
                      onClick={handleClickDelete}
                    />
                  )}
                  {rating.canUpdate?.permission && (
                    <Button
                      text="Edit"
                      disabled={loadingDelete}
                      theme={buttonTheme.primary}
                      to={`/ratings/${rating.id}/edit`}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="mt-1 text-xs text-gray-400 ">
              Created by <span>{rating.creator?.name}</span> on{" "}
              <span>{moment(rating.created).format("LLLL")}</span>
            </div>
            <div className="mt-2 text-sm text-gray-600">
              <TextareaWysiwyg value={parseStringToJSON(rating.description)} />
            </div>
          </div>

          <nav className="-mb-px flex gap-6" aria-label="Tabs">
            {tabs.map((tab) => (
              <button
                key={tab.id}
                className={classNames(
                  tab.id === displayedTabId
                    ? "border-blue-700 text-gray-700"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                  "whitespace-nowrap py-2 px-1 border-b-3 font-medium text-base"
                )}
                aria-current={tab.id === displayedTabId ? "page" : undefined}
                onClick={() => setDisplayedTabId(tab.id)}
              >
                {tab.name}
              </button>
            ))}
          </nav>
          {displayedTabId === "templates" ? (
            <div className="text-gray-600 text-sm">
              <div>
                This rating is used in {templates.length}{" "}
                {pluralize("template", templates.length)}.
              </div>
              <ul className="mt-4 list-disc ml-6">
                {templates.map((template) => (
                  <li key={template.id}>
                    <AppLink
                      to={`/templates/${template.id}`}
                      className="hover:underline text-blue-600"
                    >
                      {template.title}
                    </AppLink>
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            <div>
              <div className="text-xs text-gray-400 mb-3">
                Average:{" "}
                {Math.floor((rating?.answers?.averageRating ?? 0) * 100) / 100}
              </div>
              {rating?.answers && rating?.answers?.edges.length > 0 ? (
                <div>
                  <TableContainer>
                    <Table>
                      <TableHeadRow>
                        <TableHeadCell>User</TableHeadCell>
                        <TableHeadCell>Answer value</TableHeadCell>
                        <TableHeadCell>Answer label</TableHeadCell>
                        <TableHeadCell>Created</TableHeadCell>
                        <TableHeadCell>Meeting</TableHeadCell>
                      </TableHeadRow>
                      <TableBody>
                        {rating.answers.edges.map((edge) => (
                          <TableBodyRow key={edge?.node?.id}>
                            <TableBodyCell className="text-gray-800">
                              {edge?.node?.creator.name}
                            </TableBodyCell>
                            <TableBodyCell>
                              {edge?.node?.integerAnswer}
                            </TableBodyCell>
                            <TableBodyCell>
                              {labelsByAnswer[
                                assertNonNull(edge?.node?.integerAnswer)
                              ] || ""}
                            </TableBodyCell>
                            <TableBodyCell>
                              <TimeAgoCustom
                                date={assertNonNull(edge?.node?.created)}
                              />
                            </TableBodyCell>
                            <TableBodyCell>
                              {edge?.node?.createdInMeeting && (
                                <AppLink
                                  to={getUrl({
                                    meetingGroupId:
                                      edge?.node?.createdInMeeting
                                        .meetingGroupId,
                                    meetingId: edge?.node?.createdInMeeting.id,
                                  })}
                                  className="hover:underline text-blue-600"
                                >
                                  {edge?.node?.createdInMeeting.title} (
                                  {moment(
                                    edge?.node?.createdInMeeting.startDatetime
                                  ).format("D MMM, YYYY")}
                                  )
                                </AppLink>
                              )}
                            </TableBodyCell>
                          </TableBodyRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {data?.rating?.answers?.pageInfo.hasNextPage && (
                    <TableFooter>
                      <div className="flex justify-center">
                        {loading ? (
                          <Loading mini size="5" />
                        ) : (
                          <button
                            onClick={handleClickViewMore}
                            className="text-gray-600 hover:underline"
                          >
                            View more
                          </button>
                        )}
                      </div>
                    </TableFooter>
                  )}
                </div>
              ) : (
                <div className="text-gray-500 italic">No answers</div>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default RatingsView;
