import { gql } from "@apollo/client";

import ArtifactComponentFragment from "@apps/artifact/graphql/artifact-component-fragment";

export default gql`
  ${ArtifactComponentFragment}
  fragment AskAISessionChatMessage on ChatMessageNode {
    id
    content
    role
    autogenerated
    actionItems {
      edges {
        node {
          id
          ...ArtifactComponentFragment
        }
      }
    }
    goals {
      edges {
        node {
          id
          ...ArtifactComponentFragment
        }
      }
    }
    recognitions {
      edges {
        node {
          id
          ...ArtifactComponentFragment
        }
      }
    }
    suggestedRecognition {
      title
      coreValue {
        id
        title
        emoji
        image
      }
      suggestedAssignee {
        id
        name
        avatar
      }
    }
    createdArtifact {
      id
      ...ArtifactComponentFragment
    }
  }
`;
