import { gql } from "@apollo/client";

export default gql`
  fragment AccountSyncCredentialFragment on UserSyncCredentialsNode {
    id
    provider
    lastCalendarSyncStart
    lastCalendarSyncEnd
    hasValidCalendarCredentials
    hasCalendarWriteCredentials
    credentialsUid
    organization {
      id
      name
      personal
    }
  }
`;
