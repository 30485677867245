import { gql } from "@apollo/client";

export const AssessmentNominationFragment = gql`
  fragment AssessmentNomination on AssessmentOpenForNominationNode {
    id
    complianceProgram {
      id
      title
    }
    assessmentGroup {
      id
      title
      assessmentType
    }
    targetUser {
      id
      name
      avatar
    }
    nominations {
      totalCount
    }
  }
`;

export default gql`
  ${AssessmentNominationFragment}
  query getAssessmentNominations($targetId: Int, $after: String) {
    assessmentsOpenForNominations(
      targetId: $targetId
      after: $after
      first: 10
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ...AssessmentNomination
        }
      }
    }
  }
`;
