import { gql } from "@apollo/client";

export const DashboardTeamUserFragment = gql`
  fragment DashboardTeamUserFragment on UserNode {
    id
    name
    firstName
    email
    avatar
  }
`;
