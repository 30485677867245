import { gql } from "@apollo/client";

export default gql`
  mutation deleteTopicTemplateCategoryMutation($topicTemplateCategoryId: Int!) {
    deleteTopicTemplateCategory(
      topicTemplateCategoryId: $topicTemplateCategoryId
    ) {
      ok
    }
  }
`;
