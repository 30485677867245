import { ArrowRightIcon } from "@heroicons/react/outline";
import { FormEventHandler, useState } from "react";

import Button from "@components/button/button";
import Modal from "@components/modal/modal";
import ModalTitle from "@components/modal/modal-title";
import Select from "@components/select/select";

const CalendarChangeOrganizationDialog = ({
  open,
  me,
  calendar,
  onClose,
  onSaveNewOrganization,
}: {
  open: any;
  me: any;
  calendar: any;
  onClose: any;
  onSaveNewOrganization: any;
}) => {
  const nonPersonalOrganizations = me.organizations.edges.filter(
    ({ node }: { node: any }) => !node.personal
  );
  const personalOrganization = me.organizations.edges.find(
    ({ node }: { node: any }) => node.personal
  ).node;
  const emptyOrganizationOption = {
    value: calendar.organization ? personalOrganization.id : null,
    label: "None",
  };
  const currentOrganizationValue = calendar.organization
    ? calendar.organization.id
    : emptyOrganizationOption.value;
  const organizationOptions = [emptyOrganizationOption]
    .concat(
      nonPersonalOrganizations.map(({ node: organization }: { node: any }) => ({
        label: organization.name,
        value: organization.id,
      }))
    )
    .filter(({ value }) => value !== currentOrganizationValue);
  const [selectedOrganization, setSelectedOrganization] = useState(
    organizationOptions[0]?.value
  );
  const selectedOrganizationLabel = organizationOptions.find(
    ({ value }: { value: any }) => selectedOrganization === value
  )?.label;

  const handleChangeSelectedOrganization = ({ value }: { value: any }) => {
    setSelectedOrganization(value);
  };

  const handleSubmitForm: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    onSaveNewOrganization(selectedOrganization);
  };

  return (
    <Modal open={open} onClose={onClose}>
      <form
        onSubmit={handleSubmitForm}
        className="px-4 py-5 sm:p-6"
        aria-label="Change organization dialog"
      >
        <ModalTitle onClose={onClose}>
          Move calendar events to another organization
        </ModalTitle>
        <div className="mt-8 rounded-md bg-gray-100 p-4 flex gap-8">
          <div className="flex-1">
            <div className="font-medium mb-1 text-sm">From</div>
            {calendar.organization && !calendar.organization.personal ? (
              <div>{calendar.organization.name}</div>
            ) : (
              <div className="text-sm italic text-gray-600 mt-2">
                No organizations
              </div>
            )}
          </div>
          <ArrowRightIcon className="mt-6 h-6 w-6 text-gray-500" />
          <div className="flex-1">
            <div className="font-medium mb-1 text-sm">To</div>
            <div className="">
              <Select
                width="full"
                aria-label="Organization select"
                options={organizationOptions}
                value={selectedOrganization}
                onChange={handleChangeSelectedOrganization}
              />
            </div>
          </div>
        </div>
        <div className="mt-8 text-sm">
          {currentOrganizationValue === emptyOrganizationOption.value ? ( // no organizations assigned currently
            <div>
              <div className="font-medium">
                For past and future meetings you own in this calendar...
              </div>
              <ul className="list-disc ml-6 mt-1 text-gray-800">
                <li>
                  Meeting notes will be able to be shared with other members of{" "}
                  {selectedOrganizationLabel}.
                </li>
                <li>
                  Action items and Decisions will be visible to other members of{" "}
                  {selectedOrganizationLabel}
                </li>
              </ul>
            </div>
          ) : (
            <div>
              <div className="font-medium">
                For past meetings you own in this calendar...
              </div>
              <ul className="list-disc ml-6 mt-1 text-gray-800">
                <li>
                  Meeting notes, Action items and Decisions will remain
                  shareable to members of
                </li>
              </ul>
              <div className="font-medium mt-4">
                For future meetings you own in this calendar...
              </div>
              <ul className="list-disc ml-6 mt-1 text-gray-800">
                <li>
                  Meeting notes, Action items and Decisions will not be
                  shareable to PXC Global
                </li>
              </ul>
            </div>
          )}
        </div>
        <div className="mt-12 flex gap-4">
          <Button type="submit" theme="primary" text="Save changes" />
          <Button type="button" text="Cancel" onClick={onClose} />
        </div>
      </form>
    </Modal>
  );
};

export default CalendarChangeOrganizationDialog;
