import { gql } from "@apollo/client";

import organizationFragment from "./organization-fragment";
import syncCredentialFragment from "./sync-credential-fragment";

export default gql`
  ${syncCredentialFragment}
  ${organizationFragment}
  query getLoggedInUserOnboardingQuery {
    loggedIn
    me {
      id
      email
      emails
      emailsWithNonFreeDomains
      name
      firstName
      lastName
      avatar
      syncCredentials {
        edges {
          node {
            ...SyncCredentialFragment
          }
        }
      }
      organizations(personal: false) {
        edges {
          node {
            ...OrganizationFragment
          }
        }
      }
    }
  }
`;
