import { Children, PropsWithChildren, cloneElement } from "react";

import { classNames } from "@helpers/css";

const RoundedShadowContainer = ({
  children,
  className = "",
}: PropsWithChildren<{
  className?: string;
}>) => {
  return (
    <div
      className={classNames(
        "rounded-lg shadow divide divide-y divide-gray-100",
        className
      )}
    >
      {Children.map(children, (child: any, i) =>
        cloneElement(child, {
          roundedTop: i === 0,
          roundedBottom: i === Children.count(children) - 1,
        })
      )}
    </div>
  );
};

export default RoundedShadowContainer;
