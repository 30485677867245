import { Menu } from "@szhsin/react-menu";
import { compact } from "lodash";
import { ArtifactType } from "types/graphql-schema";

import { ExplorerFilterType } from "../helpers";
import ExplorerMenuButton from "./explorer-menu-button";
import ExplorerMenuOptions from "./explorer-menu-options";

const ExplorerGroupByOptions = ({
  filters,
  onChangeFilters,
}: {
  filters: ExplorerFilterType;
  onChangeFilters: (filters: ExplorerFilterType) => void;
}) => {
  const groupByOptions = compact([
    {
      value: "None",
      filters: { groupBy: null },
      label: "None",
    },
    filters.type === ArtifactType.ActionItem && {
      value: "actionItemState",
      filters: { groupBy: "actionItemState" },
      label: "State",
    },
    filters.type === ArtifactType.Goal && {
      value: "goalState",
      filters: { groupBy: "goalState" },
      label: "State",
    },
  ]);

  // RENDER
  return (
    <Menu
      className="text-sm z-dropdown"
      portal
      menuButton={
        <ExplorerMenuButton
          options={groupByOptions}
          filters={filters}
          prefix="Group by"
          filterKey="groupBy"
        />
      }
    >
      <ExplorerMenuOptions
        options={groupByOptions}
        onSelectMenuItem={onChangeFilters}
        filterKey="groupBy"
      />
    </Menu>
  );
};

export default ExplorerGroupByOptions;
