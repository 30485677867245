import { useMutation, useQuery } from "@apollo/client";
import { noop } from "lodash";
import { FormEventHandler, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import Button from "@components/button/button";
import Error from "@components/error/error";
import GraphqlError from "@components/error/graphql-error";
import Input from "@components/input/input";
import AppLink from "@components/link/link";
import Loading from "@components/loading/loading";
import Onboarding from "@components/onboarding/onboarding";
import ProviderImage from "@components/provider-image/provider-image";
import Select from "@components/select/select";
import Tooltip from "@components/tooltip/tooltip";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { orgMembership } from "@helpers/constants";
import { errorMatches } from "@helpers/helpers";

import createOrganizationMutation from "../graphql/create-organization-mutation";
import getOrganizationQuery from "../graphql/get-organization-query";
import CalendarConnectCTA from "./calendar/calendar-connect-cta";
import CalendarSelected from "./calendar/calendar-selected";
import CalendarUseCTA from "./calendar/calendar-use-cta";
import OnboardingCreateOrganizationInvite from "./create-organization/invite";

const onboardingCreateOrganizationSteps = {
  createOrgForm: 0,
  connectCalendar: 1,
  invitePeople: 2,
  finish: 3,
};

const OnboardingCreateOrganization = ({
  currentUser,
}: {
  currentUser: any;
}) => {
  const history = useHistory();
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const orgIdParam = urlParams.get("organization");
  const urlOrganizationId = orgIdParam ? parseInt(orgIdParam, 10) : null;
  const [step, setStep] = useState(
    urlOrganizationId // restart at connect calendar after redirecting from calendar oauth process
      ? onboardingCreateOrganizationSteps.connectCalendar
      : onboardingCreateOrganizationSteps.createOrgForm
  );
  const [email, setEmail] = useState(currentUser.emails[0]);
  const [name, setName] = useState("");
  const [membership, setMembership] = useState(
    orgMembership.anyoneWithValidDomain
  );
  const [createOrganization, { data, loading, error, reset }] = useMutation(
    createOrganizationMutation
  );
  const {
    data: orgData,
    loading: orgLoading,
    error: orgError,
  } = useQuery(getOrganizationQuery, {
    variables: { organizationId: urlOrganizationId },
    skip: !urlOrganizationId || data || loading || !step,
    onError: onNotificationErrorHandler(),
  });

  const organization =
    data?.createOrUpdateOrganization.organization || orgData?.organization;

  const emailDomain = email.split("@").pop();
  const selectedEmailIsNonFreeDomain =
    currentUser.emailsWithNonFreeDomains.includes(email);

  const calendarsMatchingOrganization =
    currentUser?.syncCredentials.edges.filter(
      ({ node }: { node: any }) =>
        !node.organization || node.organization?.id === organization?.id
    );
  const isNotAssociatedToAnOrganization = (node: any) => !node.organization;
  const calendarIsAssociatedToAnOrganization = (node: any) =>
    !!node.organization;

  const handleSubmitCreateOrgForm: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    createOrganization({
      variables: {
        name,
        membershipSetting: membership,
        associatedDomainName: selectedEmailIsNonFreeDomain ? emailDomain : null,
      },
      onError: noop,
      onCompleted: () => {
        setStep(onboardingCreateOrganizationSteps.connectCalendar);
      },
    });
  };

  const handleChangeEmail = ({ value }: { value: string }) => {
    reset();
    setEmail(value);
  };

  useEffect(() => {
    if (organization?.id) {
      history.replace({
        pathname: "/onboarding/create-organization",
        search: `?organization=${organization.id}`,
      });
    }
  }, [step, organization]);

  return (
    <div className="text-gray-800 flex flex-col gap-6">
      <Onboarding.Title>Create a new organization</Onboarding.Title>
      <div className="text-gray-500 text-base">
        Organizations add features to help teams share meeting notes, action
        items, decisions, and more. Learn about organizations in Topicflow.
      </div>

      {/* Create org form */}
      {!organization &&
      step === onboardingCreateOrganizationSteps.createOrgForm ? (
        <form
          className="bg-white shadow text-sm rounded-md px-8 py-6"
          onSubmit={handleSubmitCreateOrgForm}
        >
          <Onboarding.SubTitle className="mb-8">
            Organization Basics
          </Onboarding.SubTitle>
          <div className="flex mb-4">
            <div className="w-48 pt-2 text-base">Organization owner</div>
            <div className="flex-1">
              <Select
                width="full"
                options={currentUser.emails.map((email: string) => ({
                  value: email,
                  label: email,
                }))}
                value={email}
                onChange={handleChangeEmail}
                aria-label="Organization owner email selector"
              />
              <div className="mt-1 text-xs text-gray-500">
                Don't see the right email? Add an email to your account
              </div>
            </div>
          </div>
          <div className="flex mb-4">
            <div className="w-48 pt-2 text-base">Organization name</div>
            <div className="flex-1">
              <Input
                aria-label="Organization name"
                value={name}
                required
                onChange={(e) => setName(e.target.value)}
              />
            </div>
          </div>
          <div className="flex mb-4">
            <div className="w-48 text-base">Membership</div>
            {selectedEmailIsNonFreeDomain ? (
              <div className="flex-1">
                <label className="mb-1 flex items-center gap-3">
                  <input
                    type="radio"
                    name="membership"
                    value={orgMembership.anyoneWithValidDomain}
                    checked={membership === orgMembership.anyoneWithValidDomain}
                    onChange={() =>
                      setMembership(orgMembership.anyoneWithValidDomain)
                    }
                  />{" "}
                  <span className="">
                    Allow people using{" "}
                    <span className="font-medium">@{emailDomain}</span> to join
                    this organization
                  </span>
                </label>
                <label className="flex items-center gap-3">
                  <input
                    type="radio"
                    name="membership"
                    value={orgMembership.manualInvitesOnly}
                    checked={membership === orgMembership.manualInvitesOnly}
                    onChange={() =>
                      setMembership(orgMembership.manualInvitesOnly)
                    }
                  />{" "}
                  Manual invitation only
                </label>
              </div>
            ) : (
              <div className="flex-1 text-sm">
                <div className="font-medium mb-1">Manual invitation only</div>
                <div className="text-gray-500">
                  Organizations created with free email services must invite
                  members manually.
                </div>
              </div>
            )}
          </div>

          {error &&
          errorMatches(
            error,
            "You cannot use this domain name for your organization"
          ) ? (
            <Error
              description={`An organization using ${emailDomain} already exists. Use an email address with a different domain name.`}
            />
          ) : (
            error && <GraphqlError error={error} className="mt-4" />
          )}

          <div className="flex justify-center mt-8">
            <Tooltip
              text={
                name.trim().length === 0
                  ? "Please enter an organization name"
                  : ""
              }
            >
              <span>
                <Button
                  type="submit"
                  theme="primary"
                  disabled={name.trim().length === 0 || loading}
                  text="Create organization"
                />
              </span>
            </Tooltip>
          </div>
        </form>
      ) : orgLoading ? (
        <Loading>Loading organization...</Loading>
      ) : organization ? (
        <Onboarding.SuccessStep
          stepName="Created"
          aria-label="Organization created"
        >
          <div>
            <Onboarding.SubTitle>{organization.name}</Onboarding.SubTitle>
            <div className="text-gray-500 text-sm font-normal">1 member</div>
          </div>
        </Onboarding.SuccessStep>
      ) : orgError ? (
        <GraphqlError error={orgError} />
      ) : (
        <div className="text-gray-600 text-sm flex justify-center">
          <div className="flex items-center gap-1">
            The organization does not exist.
            <AppLink to="/" className={Onboarding.textButtonClassName}>
              Back to the app
            </AppLink>
          </div>
        </div>
      )}

      {organization && (
        <>
          {/* Connect calendar */}
          {step === onboardingCreateOrganizationSteps.connectCalendar ? (
            <div className="bg-white shadow text-base rounded-md px-8 py-6 text-gray-700">
              <Onboarding.SubTitle className="mb-4">
                Choose a calendar to use for {organization.name}
              </Onboarding.SubTitle>
              <div className="mb-6">
                Events you own in this calendar will be added to the
                organization, enabling additional sharing options, access to org
                templates, and more.
              </div>

              {calendarsMatchingOrganization.map(({ node }: { node: any }) =>
                isNotAssociatedToAnOrganization(node) ? (
                  <CalendarUseCTA
                    key={node.id}
                    provider={node.provider}
                    syncCredentialId={node.id}
                    email={node.credentialsUid}
                    organizationId={organization.id}
                    organizationName={organization.name}
                  />
                ) : (
                  <CalendarSelected
                    key={node.id}
                    email={node.credentialsUid}
                    provider={node.provider}
                  />
                )
              )}

              <div className="mt-4">
                <CalendarConnectCTA
                  organizationId={organization.id}
                  redirectUrl={`/onboarding/create-organization?organization=${organization.id}`}
                />
              </div>
              <div className="mt-6 flex justify-center">
                <AppLink
                  to={`/onboarding/create-organization/?organization=${organization.id}`}
                  className={Onboarding.textButtonClassName}
                  onClick={(e) => {
                    e.preventDefault();
                    setStep(onboardingCreateOrganizationSteps.invitePeople);
                  }}
                >
                  {calendarsMatchingOrganization.find(
                    ({ node }: { node: any }) =>
                      calendarIsAssociatedToAnOrganization(node)
                  )
                    ? "Next"
                    : "Skip"}
                </AppLink>
              </div>
            </div>
          ) : (
            step > onboardingCreateOrganizationSteps.connectCalendar &&
            calendarsMatchingOrganization.map(
              ({ node }: { node: any }) =>
                !isNotAssociatedToAnOrganization(node) && (
                  <Onboarding.SuccessStep key={node.id} stepName="Synced">
                    <>
                      <ProviderImage provider={node.provider} size="8" />
                      {node.credentialsUid}
                    </>
                  </Onboarding.SuccessStep>
                )
            )
          )}

          {/* Invite people */}
          {step >= onboardingCreateOrganizationSteps.invitePeople && (
            <OnboardingCreateOrganizationInvite
              showDoneStep={
                step > onboardingCreateOrganizationSteps.invitePeople
              }
              organization={organization}
              onNextStep={() =>
                setStep(onboardingCreateOrganizationSteps.finish)
              }
            />
          )}

          {/* Finish */}
          {step === onboardingCreateOrganizationSteps.finish && (
            <div className="flex justify-center">
              <AppLink to="/" className={Onboarding.textButtonClassName}>
                Finish
              </AppLink>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default OnboardingCreateOrganization;
