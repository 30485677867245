import { gql } from "@apollo/client";

export default gql`
  query getKpisToConnectToKrQuery {
    kpis {
      edges {
        node {
          id
          title
          visibility
          currentMeasurement {
            id
            measurement
          }
          measurements(orderBy: "-timestamp", first: 1) {
            edges {
              node {
                id
                measurement
                timestamp
              }
            }
          }
        }
      }
    }
  }
`;
