import { ReactElement } from "react";

import { classNames } from "@helpers/css";

const SidebarRow = ({
  children,
  className = "",
}: {
  children?: ReactElement | ReactElement[];
  className?: string;
}) => (
  <div
    className={classNames(
      "px-4 sm:px-6 flex gap-2 items-center w-full",
      className
    )}
  >
    {children}
  </div>
);

export default SidebarRow;
