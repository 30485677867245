import { gql } from "@apollo/client";

export default gql`
  mutation updateMemberMutation(
    $organizationId: Int!
    $role: OrganizationRole
    $userId: Int!
    $startDate: Date
    $employeeId: String
    $position: String
    $jobLevel: String
    $membershipStatus: AccountToOrganizationMembershipStatus
  ) {
    updateOrganizationMembership(
      organizationId: $organizationId
      userId: $userId
      role: $role
      startDate: $startDate
      employeeId: $employeeId
      position: $position
      jobLevel: $jobLevel
      membershipStatus: $membershipStatus
    ) {
      member {
        id
        role
        user {
          id
        }
        startDate
        employeeId
        position
        jobLevel
      }
    }
  }
`;
