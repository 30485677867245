import update from "immutability-helper";

import cache from "@cache/cache";

export default function addTopicToMeeting({
  topic,
  meeting,
  afterTopic,
  beforeTopic,
}: {
  topic: any;
  meeting: any;
  afterTopic: any;
  beforeTopic: any;
}) {
  const topicCacheId = cache.identify(topic);
  const meetingCacheId = cache.identify(meeting);

  // we add the topic in the meeting object
  cache.modify({
    id: meetingCacheId,
    fields: {
      topics(cachedTopics) {
        const existingAlready = cachedTopics.edges.findIndex(
          (edge: any) => edge.node.__ref === topicCacheId
        );
        if (existingAlready >= 0) {
          return; // we don't add it again
        }
        let index = 0;
        if (afterTopic) {
          const afterTopicCacheId = cache.identify(afterTopic);
          const afterIndex = cachedTopics.edges.findIndex(
            (edge: any) => edge.node.__ref === afterTopicCacheId
          );
          if (afterIndex >= 0) {
            index = afterIndex + 1;
          }
        } else if (beforeTopic) {
          const beforeTopicCacheId = cache.identify(beforeTopic);
          const beforeIndex = cachedTopics.edges.findIndex(
            (edge: any) => edge.node.__ref === beforeTopicCacheId
          );
          if (beforeIndex > 0) {
            index = beforeIndex - 1;
          }
        } else {
          // if the topic is not added before or after another topic
          // we just add it at the end of the list
          index = cachedTopics.edges.length;
        }
        const newEdges = update(cachedTopics.edges, {
          $splice: [[index, 0, { node: { __ref: topicCacheId } }]],
        });
        return {
          ...cachedTopics,
          edges: newEdges,
        };
      },
    },
  });
}
