import {
  GoalAlignmentDialogGoalFragmentFragment,
  GoalScope,
} from "types/graphql-schema";

import { getLabel } from "@apps/use-label/use-label";

export const getGoalAlignmentDisabledText = (
  referenceGoal: GoalAlignmentDialogGoalFragmentFragment,
  pickableGoal: GoalAlignmentDialogGoalFragmentFragment,
  allowedGoalScopes: GoalScope[],
  alignmentType: "child" | "parent",
  canCauseParentLoops = false
) => {
  const label = getLabel();
  return referenceGoal.id === pickableGoal.id
    ? `You cannot select the same ${label(
        "goal"
      )} as the one you are aligning to.`
    : canCauseParentLoops && alignmentType === "parent"
    ? `You cannot select a ${label("goal")} that is a descendant of the ${label(
        "goal"
      )} you are aligning. This would lead to a circular dependency (infinite loop).`
    : alignmentType === "child" &&
      (canCauseParentLoops || pickableGoal.parentGoalId === referenceGoal.id)
    ? `You cannot select a ${label("goal")} that is an ancestor of the ${label(
        "goal"
      )} you are aligning to. This would lead to a circular dependency (infinite loop).`
    : alignmentType === "parent" &&
      referenceGoal.parentGoalId === pickableGoal.id
    ? `This is already the parent ${label("goal")}.`
    : !allowedGoalScopes.includes(pickableGoal.scope)
    ? `Can only select ${allowedGoalScopes.join(", ")} ${label("goal", {
        pluralize: true,
      })}.`
    : undefined;
};
