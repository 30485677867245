import { gql } from "@apollo/client";

import CommonGoalArtifactFragment from "@graphql/common-goal-artifact-fragment";

export default gql`
  ${CommonGoalArtifactFragment}
  query getKpiGoalsQuery($kpiId: Int!, $first: Int = 20, $after: String) {
    kpi(kpiId: $kpiId) {
      id
      linkedKeyResults(last: $first, after: $after) {
        totalCount
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            id
            goal {
              id

              activities(first: 1, activityType: goal_checkin) {
                edges {
                  node {
                    created
                  }
                }
              }
              ...CommonGoalArtifactFragment
            }
          }
        }
      }
      linkedGoals {
        edges {
          node {
            id

            activities(first: 1, activityType: goal_checkin) {
              edges {
                node {
                  created
                }
              }
            }
            ...CommonGoalArtifactFragment
          }
        }
      }
    }
  }
`;
