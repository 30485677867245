import * as Sentry from "@sentry/react";
import {
  ArtifactType,
  GoalScope,
  GoalState,
  MeetingViewMeetingNodeNewPageFragmentFragment,
} from "types/graphql-schema";

import useLabel from "@apps/use-label/use-label";
import Error from "@components/error/error";

import MeetingGoalCollapsible from "./goal-collapsible";
import MeetingSidebarSection from "./meeting-sidebar-collapsible-container";
import useMeetingSidebarSection from "./use-meeting-sidebar-collapsible-container";

const Goals = ({
  meeting,
}: {
  meeting: MeetingViewMeetingNodeNewPageFragmentFragment;
}) => {
  const label = useLabel();

  const {
    isFormalOneonone,
    subject,
    filterAssigneeId,
    participantIds,
    toggledAssignee,
    toggledJustMeeting,
  } = useMeetingSidebarSection({
    meeting,
    collapsibleFilterName: "Goals",
  });

  const commonFilters = {
    first: 5,
    meetingGroupId: toggledJustMeeting ? meeting.meetingGroup?.id : undefined,
    goalInvolvingUsers: toggledAssignee ? [filterAssigneeId] : participantIds,
    asUsers: participantIds,
    goalScopes: isFormalOneonone
      ? undefined
      : [GoalScope.Personal, GoalScope.Team, GoalScope.Organization],
  };

  return (
    <MeetingSidebarSection
      collapsibleFilterName="Goals"
      expandedUiPreferenceKey="meetingSidebarGoalsContainerExpanded"
      subject={subject}
      isFormalOneonone={isFormalOneonone}
      toggledAssignee={toggledAssignee}
      toggledJustMeeting={toggledJustMeeting}
      createArtifactFormOptions={{
        artifactType: ArtifactType.Goal,
        meetingId: meeting.id,
      }}
      titleLabel="goal"
    >
      <MeetingGoalCollapsible
        expandedUiPreferenceKey="meetingOpenGoalsExpanded"
        meeting={meeting}
        title={`Open ${label("goal", {
          pluralize: true,
        })}`}
        variables={{
          goalState: GoalState.Open,
          fetchData: true,
          ...commonFilters,
        }}
      />
      <MeetingGoalCollapsible
        expandedUiPreferenceKey="meetingDraftGoalsExpanded"
        meeting={meeting}
        title={`Draft ${label("goal", {
          pluralize: true,
        })}`}
        variables={{
          goalState: GoalState.Draft,
          fetchData: true,
          ...commonFilters,
        }}
      />
      <MeetingGoalCollapsible
        expandedUiPreferenceKey="meetingClosedGoalsExpanded"
        meeting={meeting}
        title={`Recently completed`}
        variables={{
          goalState: GoalState.Closed,
          goalsCompletedInTheLastXDays: 30,
          fetchData: true,
          ...commonFilters,
        }}
      />
    </MeetingSidebarSection>
  );
};

export default Sentry.withErrorBoundary(Goals, {
  fallback: <Error description={"The goals could not be rendered."} />,
});
