import { gql } from "@apollo/client";

export default gql`
  query getMeetingEmbedQuery($meetingId: Int!) {
    meeting(meetingId: $meetingId) {
      id
      title
      startDatetime
      meetingGroupId
    }
  }
`;
