import { KpiSummaryMethod, KpiSummaryPeriod } from "types/graphql-schema";

export const getRatingScaleClassName = (
  startValue: number,
  endValue: number
) => {
  const scaleDiff = endValue - startValue + 1;
  return scaleDiff === 2
    ? "grid-cols-2"
    : scaleDiff === 3
    ? "grid-cols-3"
    : scaleDiff === 4
    ? "grid-cols-4 "
    : scaleDiff === 5
    ? "sm:grid-cols-5 "
    : scaleDiff === 6
    ? "sm:grid-cols-6"
    : scaleDiff === 7
    ? "sm:grid-cols-7"
    : scaleDiff === 8
    ? "sm:grid-cols-8"
    : scaleDiff === 9
    ? "sm:grid-cols-9"
    : scaleDiff === 10
    ? "sm:grid-cols-10"
    : null;
};

export const kpiSummaryPagination = 15;

export const kpiSummaryPeriodOptions = [
  {
    value: KpiSummaryPeriod.Daily,
    label: "Daily",
  },
  {
    value: KpiSummaryPeriod.Weekly,
    label: "Weekly",
  },
  {
    value: KpiSummaryPeriod.Monthly,
    label: "Monthly",
  },
];

export const kpiSummaryMethodOptions = [
  {
    value: KpiSummaryMethod.Average,
    label: "Average",
  },
  {
    value: KpiSummaryMethod.LastValue,
    label: "Last value",
  },
  {
    value: KpiSummaryMethod.Sum,
    label: "Sum",
  },
];
