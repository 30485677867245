import { range, sortBy } from "lodash";
import moment from "moment";
import { Fragment, useEffect, useRef, useState } from "react";
import { CalendarEventFragmentFragment } from "types/graphql-schema";

import {
  hourInPixels,
  minimumEventHeight,
  minuteInPixels,
  topPixelMargin,
} from "@apps/calendar/calendar";
import { currentUserVar } from "@cache/cache";
import { classNames } from "@helpers/css";

import DayColumn from "./day-column";

const Week = ({
  meetings,
  selectedDay,
  onChangeSelectedDay,
}: {
  meetings: CalendarEventFragmentFragment[];
  selectedDay: string;
  onChangeSelectedDay: (day: string) => void;
  showSharedEventsBanner: boolean;
}) => {
  // HOOKS
  const [, setNow] = useState(Date.now()); // used to refresh page and today UI: https://github.com/Topicflow/topicflow/issues/1089
  const currentUser = currentUserVar();
  const container = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (currentUser.status !== "demo") {
      // scroll to 8am
      setTimeout(() => {
        if (!container.current) {
          return;
        }
        const startOfDayInPixels = 8 * 60 * minuteInPixels;
        container.current.scrollTo({
          top: startOfDayInPixels,
          behavior: "smooth",
        });
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const si = setInterval(() => {
      setNow(Date.now());
    }, 1000 * 60); // every minute
    return function cleanup() {
      clearInterval(si);
    };
  }, []);

  const hourLabels = [
    "12AM",
    "1AM",
    "2AM",
    "3AM",
    "4AM",
    "5AM",
    "6AM",
    "7AM",
    "8AM",
    "9AM",
    "10AM",
    "11AM",
    "12PM",
    "1PM",
    "2PM",
    "3PM",
    "4PM",
    "5PM",
    "6PM",
    "7PM",
    "8PM",
    "9PM",
    "10PM",
    "11PM",
  ];
  const firstDayOfWeek = moment(selectedDay)
    .startOf("isoWeek")
    .format("YYYY-MM-DD");
  const lastDayOfWeek = moment(selectedDay)
    .endOf("isoWeek")
    .format("YYYY-MM-DD");
  const beforeSelectedDay = moment(selectedDay)
    .subtract(1, "day")
    .format("YYYY-MM-DD");
  const afterSelectedDay = moment(selectedDay)
    .add(1, "day")
    .format("YYYY-MM-DD");
  const weekdayLabels = range(0, 7).map((dayIndex) => {
    const date = moment(firstDayOfWeek)
      .add(dayIndex, "days")
      .format("YYYY-MM-DD");
    return {
      date,
      isToday: moment().format("YYYY-MM-DD") === date,
      isSelected: date === selectedDay,
      day: moment(firstDayOfWeek).add(dayIndex, "days").format("D"),
      short: moment(firstDayOfWeek).add(dayIndex, "days").format("ddd"),
      long: moment(firstDayOfWeek).add(dayIndex, "days").format("ddd"),
    };
  });
  const threedayLabels = range(-1, 2).map((dayIndex) => {
    const date = moment(selectedDay).add(dayIndex, "days").format("YYYY-MM-DD");
    return {
      date,
      isToday: moment().format("YYYY-MM-DD") === date,
      isSelected: date === selectedDay,
      day: moment(selectedDay).add(dayIndex, "days").format("D"),
      short: moment(selectedDay).add(dayIndex, "days").format("ddd"),
      long: moment(selectedDay).add(dayIndex, "days").format("ddd"),
    };
  });

  // we take meetings of current week and the previous/next day (used for 3 day view)
  const filterStart = moment(firstDayOfWeek).subtract(1, "day").startOf("day");
  const filterEnd = moment(lastDayOfWeek).add(1, "day").endOf("day");
  const filteredMeetings = meetings.filter((meeting) =>
    moment(meeting.startDatetime).isBetween(
      filterStart,
      filterEnd,
      undefined,
      "[]"
    )
  );

  const sortedMeetings = sortBy(filteredMeetings, "startDatetime");
  const eventsOfWeek = sortBy(
    sortedMeetings.map((meeting) => {
      const duration = moment(meeting.endDatetime).diff(
        meeting.startDatetime,
        "minutes"
      );
      return {
        ...meeting,
        startInMinutes: moment(meeting.startDatetime).diff(
          moment(meeting.startDatetime).startOf("day"),
          "minutes"
        ),
        duration,
        weekDay: moment(meeting.startDatetime).isoWeekday(),
        height: Math.max(duration * minuteInPixels, minimumEventHeight),
      };
    }),
    "startInMinutes"
  );
  const eventsByWeekday = range(1, 8).map((day) => {
    const dayOfWeek = moment(firstDayOfWeek)
      .isoWeekday(day)
      .format("YYYY-MM-DD");
    return {
      day: dayOfWeek,
      events: eventsOfWeek.filter(({ startDatetime }) =>
        moment(startDatetime).isSame(dayOfWeek, "day")
      ),
    };
  });
  const threeDayEventsByDay = [
    beforeSelectedDay,
    selectedDay,
    afterSelectedDay,
  ].map((day) => {
    return {
      day: moment(day).format("YYYY-MM-DD"),
      events: eventsOfWeek.filter(({ startDatetime }) =>
        moment(startDatetime).isSame(day, "day")
      ),
    };
  });

  // RENDER
  return (
    <div className="absolute inset-0 overflow-y-scroll" ref={container}>
      <div
        id="calendar"
        aria-label="Calendar week container"
        className="flex flex-auto flex-col"
      >
        <div
          style={{ width: "165%" }}
          className="flex max-w-full flex-none flex-col md:max-w-full"
        >
          <div
            className={classNames(
              "sticky top-14 z-30 shadow flex-none bg-white @4xl/calendar:pr-8"
            )}
          >
            {/* mobile */}
            <div className="grid grid-cols-7 text-sm leading-6 text-gray-500 @lg/calendar:hidden">
              {weekdayLabels.map((dayLabel) => (
                <button
                  type="button"
                  className="flex flex-col items-center pt-2 pb-3"
                  key={dayLabel.date}
                  onClick={() => onChangeSelectedDay(dayLabel.date)}
                >
                  {dayLabel.short}{" "}
                  <span
                    className={classNames(
                      "mt-1 flex h-8 w-8 items-center justify-center rounded-full font-semibold text-gray-900",
                      dayLabel.isSelected
                        ? " bg-indigo-600 text-white"
                        : dayLabel.isToday
                        ? "bg-indigo-100"
                        : ""
                    )}
                  >
                    {dayLabel.day}
                  </span>
                </button>
              ))}
            </div>

            {/* 3 days */}
            <div className="-mr-px hidden @lg/calendar:grid @4xl/calendar:hidden grid-cols-3 text-sm leading-6 text-gray-500 ">
              <div className="col-end-1 w-14" />
              {threedayLabels.map((dayLabel) => (
                <button
                  type="button"
                  className="flex flex-col items-center pt-2 pb-3"
                  key={dayLabel.date}
                  onClick={() => onChangeSelectedDay(dayLabel.date)}
                >
                  {dayLabel.short}{" "}
                  <span
                    className={classNames(
                      "mt-1 flex h-8 w-8 items-center justify-center font-semibold rounded-full",
                      dayLabel.isSelected
                        ? " bg-indigo-600 text-white"
                        : dayLabel.isToday
                        ? "bg-indigo-100"
                        : ""
                    )}
                  >
                    {dayLabel.day}
                  </span>
                </button>
              ))}
            </div>

            {/* 7 days */}
            <div className="-mr-px hidden grid-cols-7 divide-x divide-gray-100 border-r border-gray-100 text-sm leading-6 text-gray-500 @4xl/calendar:grid">
              <div className="col-end-1 w-14" />
              {weekdayLabels.map((dayLabel) => (
                <div
                  className="flex items-center justify-center py-3"
                  key={dayLabel.day}
                >
                  <span className={classNames("h-8 flex items-center")}>
                    {dayLabel.long}{" "}
                    <span
                      className={classNames(
                        "ml-1.5 items-center justify-center rounded-full font-semibold text-gray-900",
                        dayLabel.isSelected
                          ? "flex h-8 w-8 bg-indigo-600 text-white"
                          : dayLabel.isToday
                          ? "flex h-8 w-8 bg-indigo-100"
                          : ""
                      )}
                    >
                      {dayLabel.day}
                    </span>
                  </span>
                </div>
              ))}
            </div>
          </div>

          <div className="flex flex-auto overflow-hidden">
            <div className="sticky left-0 z-10 w-14 flex-none bg-white ring-1 ring-gray-100" />
            <div className="grid flex-auto grid-cols-1 grid-rows-1">
              {/* Horizontal lines */}
              <div
                className="col-start-1 col-end-2 row-start-1 grid divide-y divide-gray-100"
                style={{
                  gridTemplateRows: `repeat(48, minmax(${
                    hourInPixels / 2
                  }px, 1fr))`,
                }}
              >
                <div
                  className="row-end-1"
                  style={{ height: topPixelMargin }}
                ></div>
                {hourLabels.map((hourLabel) => (
                  <Fragment key={hourLabel}>
                    <div>
                      <div className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                        {hourLabel}
                      </div>
                    </div>
                    <div />
                  </Fragment>
                ))}
              </div>

              {/* Vertical lines */}
              <div className="col-start-1 col-end-2 row-start-1 hidden grid-rows-1 divide-x divide-gray-100 @4xl/calendar:grid @4xl/calendar:grid-cols-7">
                <div className="col-start-1 row-span-full" />
                <div className="col-start-2 row-span-full" />
                <div className="col-start-3 row-span-full" />
                <div className="col-start-4 row-span-full" />
                <div className="col-start-5 row-span-full" />
                <div className="col-start-6 row-span-full" />
                <div className="col-start-7 row-span-full" />
                <div className="col-start-8 row-span-full w-8" />
              </div>

              {/* 7 Day */}
              <div className="relative col-start-1 col-end-2 row-start-1 hidden grid-cols-1 @4xl/calendar:grid @4xl/calendar:grid-cols-7 @4xl/calendar:pr-8 divide-x">
                {eventsByWeekday.map(({ day, events }) => (
                  <DayColumn
                    events={events}
                    day={day}
                    key={day}
                    selectedDay={selectedDay}
                  />
                ))}
              </div>

              {/* 3 days */}
              <div className="relative col-start-1 col-end-2 row-start-1 grid grid-cols-1 @lg/calendar:grid-cols-3 @4xl/calendar:hidden divide-x">
                {threeDayEventsByDay.map(({ day, events }) => (
                  <DayColumn
                    events={events}
                    day={day}
                    key={day}
                    selectedDay={selectedDay}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Week;
