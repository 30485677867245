import { useLazyQuery } from "@apollo/client";
import { captureException } from "@sentry/react";
import { useCallback } from "react";

import { onNotificationErrorHandler } from "@components/use-error/use-error";
import usePusher from "@components/use-pusher/use-pusher";
import { matchApolloErrorMessage } from "@helpers/helpers";

import getTopicQuery from "../graphql/get-topic-query";

export default function PusherTopicCreated({
  channelName,
}: {
  channelName: string;
}) {
  // Queries & Mutations
  const [getTopic] = useLazyQuery(getTopicQuery, {
    onError: onNotificationErrorHandler(),
  });

  const handleTopicCreated = useCallback(
    ({ topic }: { topic: { id: number } }) => {
      // we fetch the new topic and the meeting.topics it belongs to
      // that will update the list of topics.
      getTopic({
        variables: {
          topicId: topic.id,
        },
      }).catch((errors) => {
        if (matchApolloErrorMessage(errors, "Invalid id")) {
          return;
        }
        captureException(errors);
      });
    },
    [getTopic]
  );
  usePusher(channelName, "topic_created", handleTopicCreated);

  return null;
}
