import { sortBy } from "lodash";
import { useState } from "react";
import { AssessmentNominationFragment } from "types/graphql-schema";

import AssessmenNominationtDialog from "@apps/assessments/components/my-assessments-tabs/nomination-dialog";
import Avatar from "@components/avatar/avatar";
import Button, { buttonTheme } from "@components/button/button";
import Table, {
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableContainer,
  TableHeadCell,
  TableHeadRow,
} from "@components/table/table";

const ComplianceProgramUserActivityAssessmentNominationItem = ({
  assessmentOpenForNomination,
}: {
  assessmentOpenForNomination: AssessmentNominationFragment;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClickNominate = () => {
    setIsOpen(true);
  };
  const handleCloseNominationDialog = () => {
    setIsOpen(false);
  };

  return (
    <TableBodyRow key={assessmentOpenForNomination.targetUser?.id}>
      <TableBodyCell>
        <div className="flex items-center gap-2 text-gray-800">
          <Avatar user={assessmentOpenForNomination.targetUser} size="5" />
          {assessmentOpenForNomination.targetUser?.name}
        </div>
      </TableBodyCell>
      <TableBodyCell>
        <div className="text-gray-800">
          <span className="font-medium capitalize">
            {assessmentOpenForNomination.assessmentGroup.assessmentType}:
          </span>{" "}
          {assessmentOpenForNomination.assessmentGroup.title}
          {isOpen && (
            <AssessmenNominationtDialog
              assessmentNomination={assessmentOpenForNomination}
              onClose={handleCloseNominationDialog}
            />
          )}
        </div>
      </TableBodyCell>
      <TableBodyCell>
        <div className="flex items-center justify-between gap-2">
          <div>{assessmentOpenForNomination.nominations.totalCount}</div>
          <Button
            theme={buttonTheme.lightBlue}
            onClick={handleClickNominate}
            text="Nominate"
          />
        </div>
      </TableBodyCell>
    </TableBodyRow>
  );
};

const ComplianceProgramUserActivityAssessmentNominations = ({
  assessmentOpenForNominations,
}: {
  complianceProgramId: number;
  assessmentOpenForNominations: AssessmentNominationFragment[];
}) => {
  const sortedAssessmentOpenForNominations = sortBy(
    assessmentOpenForNominations,
    [
      (assessmentOpenForNomination) =>
        assessmentOpenForNomination.nominations.totalCount,
    ]
  );
  return (
    <TableContainer>
      <Table>
        <TableHeadRow>
          <TableHeadCell width="72">Target</TableHeadCell>
          <TableHeadCell>Assessment</TableHeadCell>
          <TableHeadCell width="64">Nominations</TableHeadCell>
        </TableHeadRow>
        <TableBody>
          {sortedAssessmentOpenForNominations.map(
            (assessmentOpenForNomination) => (
              <ComplianceProgramUserActivityAssessmentNominationItem
                key={assessmentOpenForNomination.id}
                assessmentOpenForNomination={assessmentOpenForNomination}
              />
            )
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ComplianceProgramUserActivityAssessmentNominations;
