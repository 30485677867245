import { gql } from "@apollo/client";

export const SuggestedTemplateCategoryFragment = gql`
  fragment SuggestedTemplateCategoryFragment on TemplateCategoryNode {
    id
    title
  }
`;

export const SuggestedTemplateTopicsFragment = gql`
  fragment SuggestedTemplateTopicsFragment on TopicNode {
    id
    title
    isMandatory
    description
    discussionNotes
    includesIndividualNotes
  }
`;

export const SuggestedTopicTemplateFragment = gql`
  ${SuggestedTemplateCategoryFragment}
  ${SuggestedTemplateTopicsFragment}
  fragment SuggestedTopicTemplateFragment on TopicTemplateNode {
    id
    title
    allowAdminVisibility
    allowOrgVisibility
    allowManagementTreeVisibility
    categories {
      edges {
        node {
          ...SuggestedTemplateCategoryFragment
        }
      }
    }
    topics {
      edges {
        node {
          ...SuggestedTemplateTopicsFragment
        }
      }
    }
  }
`;

export default gql`
  ${SuggestedTopicTemplateFragment}
  ${SuggestedTemplateTopicsFragment}
  query getGlobalTemplatesQuery($meetingId: Int!) {
    topicTemplates(adhocTemplate: false) {
      edges {
        node {
          ...SuggestedTopicTemplateFragment
        }
      }
    }
    meeting(meetingId: $meetingId) {
      id
      suggestedTemplates {
        edges {
          node {
            ...SuggestedTopicTemplateFragment
          }
        }
      }
    }
  }
`;
