import { LegacyRef, PropsWithChildren, forwardRef } from "react";

import AppLink, { toObjectProps } from "@components/link/link";
import Tooltip from "@components/tooltip/tooltip";
import { classNames } from "@helpers/css";

export const buttonTheme: {
  default: string;
  primary: string;
  lightBlue: string;
  green: string;
  text: string;
  iconGray: string;
  redDanger: string;
} = {
  default: "default",
  primary: "primary",
  lightBlue: "lightBlue",
  green: "green",
  text: "text",
  iconGray: "iconGray",
  redDanger: "redDanger",
};

type ButtonTheme = keyof typeof buttonTheme;

interface Props {
  type?: "button" | "submit" | "reset";
  text?: string;
  testId?: string;
  theme?: ButtonTheme | Omit<string, ButtonTheme>;
  disabled?: boolean;
  className?: string;
  small?: boolean;
  mini?: boolean;
  rounded?: boolean;
  icon?: boolean;
  url?: string | null;
  to?: string | toObjectProps;
  tooltip?: string | null | false | undefined;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const Button = forwardRef<
  HTMLAnchorElement | HTMLButtonElement,
  PropsWithChildren<Props>
>(
  (
    {
      type = "button",
      text,
      testId = "",
      theme = "default",
      disabled = false,
      className = "",
      small = false,
      mini = false,
      icon = false,
      rounded = false,
      children,
      onClick,
      url = null,
      to = null,
      tooltip = "",
      ...props
    },
    ref
  ) => {
    let themeClassName = "";
    const defaultThemeClassName = classNames(
      "border-gray-300",
      "bg-white text-gray-700 focus:ring-0 focus:outline-none",
      !disabled && "hover:bg-gray-50"
    );
    if (theme === buttonTheme.primary) {
      themeClassName = classNames(
        "border-transparent",
        "text-white bg-blue-700 focus:ring-blue-500",
        !disabled && "hover:bg-blue-600"
      );
    } else if (theme === buttonTheme.redDanger) {
      themeClassName = classNames(
        "border-red-700 text-red-700 bg-white focus:ring-red-500",
        !disabled && "hover:bg-red-700 hover:text-white",
        disabled && "opacity-70"
      );
    } else if (theme === buttonTheme.lightBlue) {
      themeClassName = classNames(
        "border-transparent",
        "bg-blue-50 text-blue-700 font-normal focus:ring-blue-50",
        !disabled && "hover:bg-blue-100"
      );
    } else if (theme === buttonTheme.green) {
      themeClassName = classNames(
        "border border-green-700",
        "text-green-700 font-normal focus:ring-green-50 bg-green-50",
        !disabled && "hover:bg-green-100"
      );
    } else if (theme === buttonTheme.text) {
      themeClassName = classNames(
        "border-transparent text-gray-700 font-normal",
        !disabled && "hover:bg-gray-100"
      );
    } else if (theme === buttonTheme.iconGray) {
      themeClassName = classNames(
        "border-transparent text-gray-400",
        !disabled && "hover:bg-black/5 hover:text-gray-700"
      );
    } else {
      themeClassName = defaultThemeClassName;
    }

    const buttonClassName = classNames(
      "inline-flex justify-center font-medium rounded-md border focus:outline-none",
      "fs-unmask",
      themeClassName,
      disabled ? "opacity-50 cursor-default" : "",
      mini
        ? "text-xs py-0.5 px-2 tracking-tight"
        : small
        ? "text-sm py-[3px] px-3"
        : "text-sm py-2 px-4",
      icon && "px-0.5 py-0.5",
      rounded && "rounded-full",
      className
    );

    const button = to ? (
      <AppLink
        disabled={disabled}
        to={to}
        data-testid={testId}
        className={buttonClassName}
      >
        {children || text}
      </AppLink>
    ) : url ? (
      <a
        href={url}
        data-testid={testId}
        className={buttonClassName}
        ref={ref as unknown as LegacyRef<HTMLAnchorElement>}
      >
        {children || text}
      </a>
    ) : (
      <button
        ref={ref as unknown as LegacyRef<HTMLButtonElement>}
        type={type}
        disabled={disabled}
        data-testid={testId}
        className={buttonClassName}
        onClick={onClick}
        {...props}
      >
        {children || text}
      </button>
    );
    if (tooltip) {
      return (
        <Tooltip text={tooltip}>
          <span>{button}</span>
        </Tooltip>
      );
    }
    return button;
  }
);

export default Button;
