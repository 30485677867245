import { gql } from "@apollo/client";

const dismissInsightMutation = gql`
  mutation dismissInsightMutation(
    $type: InsightType!
    $userId: Int!
    $reportId: Int
    $managerId: Int
    $complianceProgramId: Int
    $assessmentGroupId: Int
    $artifactId: Int
  ) {
    dismissInsight(
      type: $type
      userId: $userId
      reportId: $reportId
      managerId: $managerId
      complianceProgramId: $complianceProgramId
      assessmentGroupId: $assessmentGroupId
      artifactId: $artifactId
    ) {
      user {
        id
      }
    }
  }
`;

export default dismissInsightMutation;
