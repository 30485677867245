import { DashboardInsightFragment } from "types/graphql-schema";

import useLabel from "@apps/use-label/use-label";
import { currentUserVar } from "@cache/cache";
import { assertNonNull } from "@helpers/helpers";

import { InsightButton, InsightLayout } from "../insight";

const NominatePeersInsight = ({
  insight,
  onDismissInsight,
}: {
  insight: DashboardInsightFragment;
  onDismissInsight: () => void;
}) => {
  const label = useLabel();
  const currentUser = currentUserVar();
  const complianceProgram = assertNonNull(insight.complianceProgram);

  return (
    <InsightLayout
      user={currentUser}
      onDismissInsight={onDismissInsight}
      isRequired={!!insight.complianceProgram?.id}
    >
      <div className="flex-1">
        <div>
          Nominate peers for {label("review", { pluralize: true })}
          {": "}
          {complianceProgram.title}
        </div>
      </div>

      <div>
        <InsightButton url={"/assessments?tab=nominations"}>
          Go to nominations
        </InsightButton>
      </div>
    </InsightLayout>
  );
};

export default NominatePeersInsight;
