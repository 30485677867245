import { XIcon } from "@heroicons/react/outline";

import CalendarConnectNew from "@apps/account/components/calendar-connect-new";
import Modal from "@components/modal/modal";

const ConnectCalendarModal = ({
  open = false,
  next = "/",
  onClose,
}: {
  open?: boolean;
  next?: string;
  onClose: (bool: boolean) => void;
}) => {
  return (
    <Modal open={open}>
      <div
        className="p-6 flex justify-between items-start gap-8"
        aria-label="Connect calendar modal"
      >
        <div className="flex-1">
          <CalendarConnectNew next={next} />
        </div>
        <button
          onClick={() => onClose(false)}
          className="p-1 rounded hover:bg-gray-100 text-gray-700"
          aria-label="Close modal"
        >
          <XIcon className="w-7 h-7" />
        </button>
      </div>
    </Modal>
  );
};

export default ConnectCalendarModal;
