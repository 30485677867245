import { XIcon } from "@heroicons/react/outline";
import { ReactElement, RefObject } from "react";

import Modal from "@components/modal/modal";

const WYSIWYGDialog = ({
  title,
  focusRef,
  children,
  onClose,
  ...props
}: {
  title: string;
  focusRef?: RefObject<HTMLElement | null>;
  children: ReactElement;
  onClose: () => void;
}) => {
  return (
    <Modal open onClose={onClose} initialFocus={focusRef} {...props}>
      <div className="p-6 bg-white flex flex-col gap-4 rounded">
        <div className="text-xl flex justify-between items-start gap-12">
          <span>{title}</span>
          <button
            type="button"
            className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            onClick={onClose}
          >
            <span className="sr-only">Close panel</span>
            <XIcon className="h-6 w-6" />
          </button>
        </div>
        <div>{children}</div>
      </div>
    </Modal>
  );
};

export default WYSIWYGDialog;
