import { gql } from "@apollo/client";

export default gql`
  query getUserQuery($userId: Int!) {
    user(userId: $userId) {
      id
      name
      firstName
      email
      avatar
    }
  }
`;
