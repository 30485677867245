import { gql } from "@apollo/client";

import ChatSessionMessageFragment from "./chat-session-message-fragment";

export default gql`
  ${ChatSessionMessageFragment}
  mutation linkArtifactToChatMessageMutation(
    $chatSessionId: Int!
    $messageId: String!
    $artifactId: Int!
  ) {
    linkCreatedArtifactToChatMessage(
      chatSessionId: $chatSessionId
      messageId: $messageId
      artifactId: $artifactId
    ) {
      chatSession {
        id
        messages {
          edges {
            node {
              id
              ...AskAISessionChatMessage
            }
          }
        }
      }
    }
  }
`;
