import { classNames } from "@helpers/css";

const AppLabel = ({
  label,
  className = "",
}: {
  label: string;
  className?: string;
}) => (
  <span
    className={classNames(
      "text-2xs bg-gray-200 text-gray-500 rounded px-1 py-0.5 uppercase tracking-tighter hover:no-underline",
      className
    )}
  >
    {label}
  </span>
);

export default AppLabel;
