import { useQuery } from "@apollo/client";
import { useCallback, useState } from "react";
import {
  GetKpiQueryQuery,
  GetKpiSummaryQueryQuery,
  GetKpiSummaryQueryQueryVariables,
  KpiSummaryMethod,
  KpiSummaryPeriod,
} from "types/graphql-schema";

import Loading from "@components/loading/loading";
import Select, { SelectOption } from "@components/select/select";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { assertNonNull } from "@helpers/helpers";

import getKpiSummaryQuery from "../graphql/get-kpi-summary-query";
import {
  kpiSummaryMethodOptions,
  kpiSummaryPagination,
  kpiSummaryPeriodOptions,
} from "../helpers";
import KPILineChart from "./kpi-line-chart";
import KPISummaryTable from "./summary-table";

const KPISummary = ({ kpi: kpiProp }: { kpi: GetKpiQueryQuery["kpi"] }) => {
  const kpi = assertNonNull(kpiProp);
  const [summaryPeriod, setSummaryPeriod] = useState(kpi.defaultSummaryPeriod);
  const [summaryMethod, setSummaryMethod] = useState(kpi.defaultSummaryMethod);
  const { data, loading } = useQuery<
    GetKpiSummaryQueryQuery,
    GetKpiSummaryQueryQueryVariables
  >(getKpiSummaryQuery, {
    variables: {
      kpiId: kpi.id,
      first: kpiSummaryPagination,
      summaryPeriod,
      summaryMethod,
    },
    onError: onNotificationErrorHandler(),
  });

  const handleChangeSummaryPeriod = useCallback(
    ({ value }: SelectOption<KpiSummaryPeriod>) => setSummaryPeriod(value),
    []
  );
  const handleChangeSummaryMethod = useCallback(
    ({ value }: SelectOption<KpiSummaryMethod>) => setSummaryMethod(value),
    []
  );

  const kpiWithMeasurements = data?.kpi;

  return loading ? (
    <Loading>Loading measurements</Loading>
  ) : kpiWithMeasurements ? (
    <div className="flex flex-col gap-6">
      <div>
        <div className="flex justify-start gap-4">
          <Select
            onChange={handleChangeSummaryPeriod}
            className="mt-1"
            value={summaryPeriod}
            options={kpiSummaryPeriodOptions}
          />
          <Select
            onChange={handleChangeSummaryMethod}
            className="mt-1"
            value={summaryMethod}
            options={kpiSummaryMethodOptions}
          />
        </div>
      </div>
      <KPISummaryTable kpis={[kpiWithMeasurements]} />
      <div className="border rounded-md p-6">
        <KPILineChart kpi={kpiWithMeasurements} />
      </div>
    </div>
  ) : (
    <div className="text-gray-500 text-sm">No KPI Summary</div>
  );
};

export default KPISummary;
