import { gql } from "@apollo/client";

import organizationFragment from "./organization-fragment";
import syncCredentialFragment from "./sync-credential-fragment";

export default gql`
  ${syncCredentialFragment}
  ${organizationFragment}
  mutation joinOrganizationMutation($organizationId: Int!) {
    joinOrganization(organizationId: $organizationId) {
      user {
        id
        syncCredentials {
          edges {
            node {
              ...AccountSyncCredentialFragment
            }
          }
        }
        organizationsAvailableToJoin {
          organizationId
          organizationName
          organizationMemberCount
        }
        organizations {
          edges {
            node {
              ...AccountOrganizationFragment
            }
          }
        }
      }
    }
  }
`;
