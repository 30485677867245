import { gql } from "@apollo/client";

export default gql`
  query getPreviousMeetingsQuery(
    $meetingGroupId: Int!
    $beforeDate: DateTime!
    $after: String
    $limit: Int!
  ) {
    meetings(
      meetingGroupId: $meetingGroupId
      startDatetime_Lte: $beforeDate
      first: $limit
      after: $after
      orderBy: "-start_datetime"
      draft: false
      ignored: false
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          title
          startDatetime
          endDatetime
          meetingGroupId
        }
      }
    }
  }
`;
