import { PropsWithChildren } from "react";

import { classNames } from "@helpers/css";

type Props = {
  label: string;
  description?: string;
  className?: string;
};

const SettingsContainer: React.FC<PropsWithChildren<Props>> = ({
  label,
  description,
  children,
  className,
}) => (
  <div className={classNames("grid sm:grid-cols-7 gap-8 py-8", className)}>
    <div className="col-span-2">
      <div className="mt-1.5 text-gray-800 font-medium">{label}</div>
      {description && (
        <div className="mt-1.5 text-gray-400 text-xs">{description}</div>
      )}
    </div>
    <div className="col-span-5 text-gray-800 text-sm">{children}</div>
  </div>
);

export default SettingsContainer;
