import { useMutation, useQuery } from "@apollo/client";
import { isString, uniqBy } from "lodash";
import {
  ChangeEvent,
  FormEventHandler,
  MouseEvent,
  useCallback,
  useState,
} from "react";
import { Prompt, Redirect, useLocation, useParams } from "react-router-dom";
import {
  CreateOrUpdateTopicTemplateMutationMutation,
  CreateOrUpdateTopicTemplateMutationMutationVariables,
  FinalizePermissions,
  GetTopicTemplateToEditQueryQuery,
  GetTopicTemplateToEditQueryQueryVariables,
  TemplateTopicInput,
  TopicTemplateCategoryFragmentFragment,
} from "types/graphql-schema";
import { TFLocationState } from "types/topicflow";

import { MeetingOrTemplateVisibility } from "@apps/meeting-new/helpers";
import useLabel from "@apps/use-label/use-label";
import { currentOrganizationVar, currentUserVar } from "@cache/cache";
import BillingDialogCTA from "@components/billing-dialog-cta/billing-dialog-cta";
import Button, { buttonTheme } from "@components/button/button";
import Input from "@components/input/input";
import { useLink } from "@components/link/link";
import Loading from "@components/loading/loading";
import MeetingContentAccess from "@components/meeting-content-access/meeting-content-access";
import Select, { SelectOption } from "@components/select/select";
import CustomTextareaAutosize from "@components/textarea-autosize/textarea-autosize";
import {
  onNotificationErrorHandler,
  useNotificationError,
} from "@components/use-error/use-error";
import {
  classNames,
  inputBorderClassName,
  inputFocusClassName,
} from "@helpers/css";
import {
  assertEdgesNonNull,
  assertNonNull,
  parseStringToJSON,
} from "@helpers/helpers";

import createOrUpdateTopicTemplateMutation from "../graphql/create-or-update-topic-template-mutation";
import getTemplateToEditQuery from "../graphql/get-template-to-edit-query";
import getTemplatesQuery from "../graphql/get-templates-query";
import {
  EditTemplateTopicType,
  emptyTemplate,
  getNewTopic,
  isSameObject,
  removeIdFromTopics,
  removeTopicsWithoutTitles,
} from "../helpers";
import TemplateEditTopics from "./edit-topics";

const TemplateEdit = () => {
  const currentUser = currentUserVar();
  const label = useLabel();
  const link = useLink();
  const { onError } = useNotificationError();
  const location = useLocation<TFLocationState>();
  const organization = currentOrganizationVar();
  const [initialFormTopics, setInitialFormTopics] = useState<
    EditTemplateTopicType[]
  >([getNewTopic()]);
  const [formTopics, _setFormTopics] =
    useState<EditTemplateTopicType[]>(initialFormTopics);
  const { templateId: templateIdParam } = useParams<{ templateId: string }>();
  const templateId = parseInt(templateIdParam);
  const isNew = isNaN(templateId);
  const isDuplicate =
    !isNaN(templateId) && location.pathname.includes("/duplicate");
  const backUrl =
    location.state?.previousPathname ||
    (isNew ? "/templates" : `/templates/${templateId}`);

  const [
    updateTemplate,
    { loading: loadingUpdateTemplate, called, data: mutationData },
  ] = useMutation<
    CreateOrUpdateTopicTemplateMutationMutation,
    CreateOrUpdateTopicTemplateMutationMutationVariables
  >(createOrUpdateTopicTemplateMutation);
  const hasTemplateId = !!templateId;
  const { data, loading } = useQuery<
    GetTopicTemplateToEditQueryQuery,
    GetTopicTemplateToEditQueryQueryVariables
  >(getTemplateToEditQuery, {
    variables: {
      templateId: hasTemplateId ? templateId : -1,
      hasTemplateId,
    },
    onCompleted: (response) => {
      const existingTemplate = response.topicTemplate;
      if (existingTemplate) {
        const templateTopics = existingTemplate.topicsList;
        const topicsWithDiscussionNotes = templateTopics.map((node) => ({
          ...node,
          discussionNotes: parseStringToJSON(node?.discussionNotes),
          defaultSubjectNotes: parseStringToJSON(node?.defaultSubjectNotes),
          defaultFacilitatorNotes: parseStringToJSON(
            node?.defaultFacilitatorNotes
          ),
        }));
        const updatedTopics = isDuplicate
          ? removeIdFromTopics(topicsWithDiscussionNotes)
          : topicsWithDiscussionNotes;
        setFormTopics(updatedTopics);
        setInitialFormTopics(updatedTopics);
        const updatedTemplate = {
          ...existingTemplate,
          title: `${existingTemplate.title}${
            isDuplicate ? " (duplicate)" : ""
          }`,
        };
        setFormTemplate(updatedTemplate);
        setInitialFormTemplate(updatedTemplate);
      }
    },
    onError: onNotificationErrorHandler(),
  });
  const orgCategories = (
    data ? assertEdgesNonNull(data.topicTemplateCategories) : []
  ).filter((node) => node.organization?.id);
  const template = data?.topicTemplate
    ? assertNonNull(data.topicTemplate)
    : null;

  const topicsToSave = formTopics
    .filter(({ title }) => title)
    .map((topic) => ({
      id: isString(topic.id) && topic.id.includes("topic-") ? null : topic.id,
      isMandatory: topic.isMandatory,
      notesRequirement: topic.notesRequirement,
      title: topic.title,
      description: topic.description,
      includesIndividualNotes: topic.includesIndividualNotes,
      includesSharedNotes: topic.includesSharedNotes,
      discussionNotes: topic.discussionNotes,
      defaultSubjectNotes: JSON.stringify(topic.defaultSubjectNotes),
      defaultFacilitatorNotes: JSON.stringify(topic.defaultFacilitatorNotes),
    }));

  const defaultTemplate = {
    ...emptyTemplate,
    // If the user doesn't have permission to create org templates, set the default to be a personal template
    // and the picker will be disabled
    publicTemplate: currentUser.paidFeatures.canCreateOrgTemplates,
  };
  const [initialFormTemplate, setInitialFormTemplate] =
    useState(defaultTemplate);
  const [formTemplate, setFormTemplate] = useState(defaultTemplate);

  const handleUpdateTemplate = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const organizationId = isNew || isDuplicate ? organization.id : null;
    const topicTemplateId =
      !isNew && !isDuplicate && template ? template.id : null;
    const categories = formTemplate.categoriesList?.map((category) =>
      assertNonNull(category?.id)
    );
    const topics: TemplateTopicInput[] = topicsToSave.map(
      ({ id, discussionNotes, ...topicsToSave }) => ({
        ...topicsToSave,
        id: typeof id === "string" ? undefined : id,
        discussionNotes:
          typeof discussionNotes === "string"
            ? discussionNotes
            : JSON.stringify(discussionNotes),
      })
    );
    updateTemplate({
      variables: {
        ...formTemplate,
        title: formTemplate.title || "",
        publicTemplate: !!formTemplate.publicTemplate,
        allowAdminVisibility: !!formTemplate.allowAdminVisibility,
        allowManagementTreeVisibility:
          !!formTemplate.allowManagementTreeVisibility,
        allowOrgVisibility: !!formTemplate.allowOrgVisibility,
        categories,
        topicTemplateId,
        topics,
        organizationId,
      },
      onError,
      update(cache, response) {
        if (!isNew && !isDuplicate) {
          return null;
        }
        cache.updateQuery(
          {
            query: getTemplatesQuery,
          },
          (data: any) => {
            if (!data) {
              return null;
            }
            const edges = uniqBy(
              [
                ...data.topicTemplates.edges,
                {
                  __typename: "TopicTemplateNodeEdge",
                  node: response.data?.createOrUpdateTopicTemplate
                    ?.topicTemplate,
                },
              ],
              ({ node }) => node.id
            );
            return {
              ...data,
              topicTemplates: {
                ...data.topicTemplates,
                edges: edges,
                totalCount: data.topicTemplates.totalCount + 1,
              },
            };
          }
        );
      },
      onCompleted: ({ createOrUpdateTopicTemplate }) => {
        link.redirect(
          `/templates/${
            assertNonNull(createOrUpdateTopicTemplate?.topicTemplate).id
          }`
        );
      },
    });
  };

  const setFormTopics = (updatedFormTopics: any[]) => {
    if (
      updatedFormTopics.length === 0 ||
      updatedFormTopics[updatedFormTopics.length - 1].title
    ) {
      _setFormTopics(updatedFormTopics.concat(getNewTopic()));
    } else {
      _setFormTopics(updatedFormTopics);
    }
  };

  const handleChangeVisibilityTemplate = useCallback(
    (option: SelectOption<boolean>) => {
      setFormTemplate({
        ...formTemplate,
        publicTemplate: option.value,
      });
    },
    [formTemplate]
  );

  const handleChangeMeetingType = useCallback(
    (option: SelectOption<boolean>) => {
      setFormTemplate({
        ...formTemplate,
        oneononeTemplate: option.value,
        // we disable org visibility if a 1-on-1 type
        allowOrgVisibility: option.value
          ? false
          : formTemplate.allowOrgVisibility,
      });
    },
    [formTemplate]
  );

  const handleChangeFinalizePermissions = useCallback(
    (option: SelectOption<FinalizePermissions>) => {
      setFormTemplate({
        ...formTemplate,
        finalizePermissions: option.value,
      });
    },
    [formTemplate]
  );

  const handleChangeContentAccess = useCallback(
    ({
      allowManagementTreeVisibility,
      allowOrgVisibility,
      allowAdminVisibility,
    }: MeetingOrTemplateVisibility) => {
      setFormTemplate({
        ...formTemplate,
        allowManagementTreeVisibility,
        allowOrgVisibility,
        allowAdminVisibility,
      });
    },
    [formTemplate]
  );

  const handleToggleCategory =
    (node: TopicTemplateCategoryFragmentFragment) =>
    (e: ChangeEvent<HTMLInputElement>) => {
      const categories = assertNonNull(formTemplate.categoriesList);
      const newCategories = e.target.checked
        ? [...categories, node]
        : categories.filter((category) => category?.id !== node.id);
      setFormTemplate({
        ...formTemplate,
        categoriesList: newCategories,
      });
    };

  const handleFormSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    (e) => {
      e.preventDefault();
    },
    []
  );

  const handleClickDiscard = () => {
    link.redirect(backUrl);
  };

  const isCategoryIsSelected = useCallback(
    (category: TopicTemplateCategoryFragmentFragment) => {
      const categories = assertNonNull(formTemplate.categoriesList);
      return !!categories.find((node) => category.id === node?.id);
    },
    [formTemplate]
  );

  const showPrompt =
    !called &&
    !mutationData &&
    (((isNew || isDuplicate) && !!formTemplate.title) ||
      (template &&
        (!isSameObject(
          removeTopicsWithoutTitles(formTopics),
          removeTopicsWithoutTitles(initialFormTopics)
        ) ||
          !isSameObject(formTemplate, initialFormTemplate))));

  const canSaveTooltip =
    formTemplate.title?.trim().length === 0
      ? "Please enter a template title"
      : topicsToSave.length === 0
      ? "Please add a topic in the template agenda"
      : loadingUpdateTemplate
      ? "Saving the template"
      : null;

  // RENDER
  if (data && !loading && !template && !isNew) {
    return <Redirect to="/templates" />;
  }
  // if editing existing template but we don't have access
  if (!isNew && !isDuplicate && data && !template?.canUpdate.permission) {
    return <Redirect to="/templates" />;
  }
  if (!data && loading) {
    return (
      <div className="flex-1 flex justify-center p-10">
        <Loading>Loading template..</Loading>
      </div>
    );
  }

  return (
    <form
      className="flex-1"
      aria-label="Template form"
      onSubmit={handleFormSubmit}
    >
      <Prompt
        when={!!showPrompt}
        message="Are you sure you want to leave? The changes you made will be lost."
      />
      <div className="flex items-center justify-between px-4 sm:px-6 py-4">
        <div className="text-xl font-medium">
          {isDuplicate ? "Duplicate" : isNew ? "New" : "Edit"} template
        </div>
        <div className="flex justify-end items-center gap-2 sm:gap-4">
          {loadingUpdateTemplate && <Loading size="5" mini />}
          <Button onClick={handleClickDiscard} theme={buttonTheme.text}>
            Discard changes
          </Button>
          <Button
            type="button"
            onClick={handleUpdateTemplate}
            tooltip={canSaveTooltip}
            disabled={!!canSaveTooltip}
            theme={buttonTheme.primary}
          >
            {`${loadingUpdateTemplate ? "Saving" : "Save"} template`}
          </Button>
        </div>
      </div>
      <div className="sm:flex">
        <div className="sm:w-96 flex flex-col text-sm gap-3 pt-4 pb-6 px-4 sm:px-6">
          <div className="flex flex-col gap-5 sm:gap-7">
            <div className="flex flex-col gap-2">
              <div className="text-gray-500 text-xs uppercase font-semibold">
                Template name
              </div>
              <Input
                aria-label="Template title input"
                value={formTemplate.title}
                onChange={(e) =>
                  setFormTemplate({ ...formTemplate, title: e.target.value })
                }
                autoFocus
              />
            </div>

            <div className="flex flex-col gap-2">
              <div className="text-gray-500 text-xs uppercase font-semibold">
                Description
              </div>
              <CustomTextareaAutosize
                aria-label="Template description input"
                value={formTemplate.description}
                onChange={(e) =>
                  setFormTemplate({
                    ...formTemplate,
                    description: e.target.value,
                  })
                }
                minRows={2}
                placeholder="Template description..."
                className={classNames(
                  inputBorderClassName,
                  inputFocusClassName,
                  "px-4 py-2 block w-full sm:text-sm"
                )}
              />
            </div>

            <div className="flex flex-col gap-2">
              <div className="text-gray-500 text-xs uppercase font-semibold">
                Type
              </div>
              <Select
                aria-label="Template type select"
                value={formTemplate.oneononeTemplate}
                onChange={handleChangeMeetingType}
                options={[
                  {
                    label: label("oneonone", { capitalize: true }),
                    value: true,
                  },
                  {
                    label: "Meeting",
                    value: false,
                  },
                ]}
              />
            </div>

            {formTemplate.oneononeTemplate && (
              <div className="flex flex-col gap-2">
                <div className="text-gray-500 text-xs uppercase font-semibold">
                  Finalization content locking
                </div>
                <Select<FinalizePermissions>
                  aria-label="Template finalize input"
                  onChange={handleChangeFinalizePermissions}
                  value={formTemplate.finalizePermissions}
                  className="w-64 sm:w-full"
                  options={[
                    {
                      value: FinalizePermissions.FacilitatorsAndAdmins,
                      label: "Facilitator & admins",
                    },
                    {
                      value: FinalizePermissions.AdminsOnly,
                      label: "Admins only",
                    },
                  ]}
                />
              </div>
            )}

            <div className="flex flex-col gap-2">
              <div className="text-gray-500 text-xs uppercase font-semibold">
                Content access
              </div>
              <MeetingContentAccess
                allowAdminVisibility={!!formTemplate.allowAdminVisibility}
                allowManagementTreeVisibility={
                  !!formTemplate.allowManagementTreeVisibility
                }
                allowOrgVisibility={!!formTemplate.allowOrgVisibility}
                isFormalOneonone={!!formTemplate.oneononeTemplate}
                onChange={handleChangeContentAccess}
              />
              <div className="text-xs text-gray-500">
                This defines who can view the meeting notes by default.
                Participants can change these settings.
              </div>
            </div>

            <div className="flex flex-col gap-2">
              <div className="text-gray-500 text-xs uppercase font-semibold">
                Who can find and use this template?
              </div>
              <Select
                aria-label="Template visibility input"
                onChange={handleChangeVisibilityTemplate}
                value={formTemplate.publicTemplate}
                className="w-64 sm:w-full"
                disabled={
                  !currentUser.paidFeatures?.canCreateOrgTemplates &&
                  formTemplate.publicTemplate === false
                }
                options={[
                  {
                    value: true,
                    label: `All ${label("organization")} members`,
                  },
                  {
                    value: false,
                    label: "Only you",
                  },
                ]}
              />
              {formTemplate.publicTemplate ? (
                <div className="text-xs text-gray-500">
                  Members of your organization can see this template.
                </div>
              ) : (
                <div className="text-xs text-gray-500">
                  Only you can see this template.
                  {!currentUser.paidFeatures?.canCreateOrgTemplates && (
                    <span>
                      {" "}
                      <BillingDialogCTA className="text-blue-link underline">
                        Upgrade
                      </BillingDialogCTA>{" "}
                      to create public templates
                    </span>
                  )}
                </div>
              )}
            </div>

            <div className="flex flex-col gap-2">
              <div className="text-gray-500 text-xs uppercase font-semibold">
                Categories
              </div>
              <div className="flex flex-col gap-1">
                {orgCategories
                  .filter((node) => node.organization?.id)
                  .map((node) => (
                    <label
                      key={node.id}
                      className="flex items-center gap-1 text-gray-800"
                    >
                      <input
                        type="checkbox"
                        value={node.id}
                        checked={isCategoryIsSelected(node)}
                        onChange={handleToggleCategory(node)}
                      />
                      {node.title}
                    </label>
                  ))}
              </div>
            </div>
          </div>
        </div>
        <div className="flex-1 p-4 pt-4 sm:pb-6 sm:px-6 flex flex-col gap-6 sm:gap-10">
          <div className="flex flex-col gap-10">
            <div className="flex flex-col gap-2">
              <div className="text-gray-500 text-xs uppercase font-semibold">
                Agenda
              </div>
              <div className="flex flex-col border border-gray-300 bg-white rounded-lg">
                <TemplateEditTopics
                  oneononeTemplate={!!formTemplate.oneononeTemplate}
                  formTopics={formTopics}
                  onChangeFormTopics={setFormTopics}
                  autoFocus
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default TemplateEdit;
