import { gql } from "@apollo/client";

export default gql`
  fragment OrganizationFragment on OrganizationNode {
    id
    name
    personal
    domains
    members {
      totalCount
    }
  }
`;
