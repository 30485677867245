import { Popover } from "@headlessui/react";
import { TopicDataFragmentFragment } from "types/graphql-schema";

import TopicAssignee from "@apps/topic-assignee/topic-assignee";
import Avatar from "@components/avatar/avatar";
import { classNames } from "@helpers/css";

const Assignee = ({
  topic,
  assignableUsers,
}: {
  topic: TopicDataFragmentFragment;
  assignableUsers: any[];
}) => {
  const selectedAssignee = topic.assignee ? topic.assignee?.name : "Unassigned";

  // Render
  return (
    <div className="flex items-center bg-white">
      <div className="flex items-center uppercase text-sm font-medium text-gray-500 w-28">
        Assigned to
      </div>
      <div className="flex-1">
        <TopicAssignee topic={topic} assignableUsers={assignableUsers}>
          {({ setReferenceElement }) => (
            <Popover.Button
              aria-label="Action item people dropdown"
              className={classNames(
                "text-sm text-slate-600 flex items-center gap-1 rounded py-1 px-2",
                topic.canUpdate.permission && "hover:bg-black/5"
              )}
              ref={setReferenceElement}
            >
              {topic.assignee && (
                <Avatar user={topic.assignee} size="4" className="mr-1" />
              )}
              {selectedAssignee}
            </Popover.Button>
          )}
        </TopicAssignee>
      </div>
    </div>
  );
};

export default Assignee;
