import { gql } from "@apollo/client";

import ChatSessionMessageFragment from "./chat-session-message-fragment";

export default gql`
  ${ChatSessionMessageFragment}
  query getAskAIChatSessionMessagesQuery($chatSessionId: Int!) {
    chatSession(chatSessionId: $chatSessionId) {
      id
      messages {
        edges {
          node {
            id
            ...AskAISessionChatMessage
          }
        }
      }
    }
  }
`;
