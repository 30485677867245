import { gql } from "@apollo/client";

export default gql`
  query getPastTopicsQuery(
    $meetingGroupId: Int!
    $search: String
    $after: String
  ) {
    topics(
      meetingInMeetingGroupId: $meetingGroupId
      orderBy: "-meeting__start_datetime"
      search: $search
      first: 20
      after: $after
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          title
          discussionNotes
          isMandatory
          meeting {
            id
            title
            startDatetime
            meetingGroupId
          }
        }
      }
    }
  }
`;
