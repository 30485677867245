import { gql } from "@apollo/client";

export const GoalOverviewRelatedEntityGoalFragment = gql`
  fragment GoalOverviewRelatedEntityGoalFragment on GoalArtifactNode {
    id
    title
    scope
    state
    status
    progress
    isStale
    canUpdate {
      permission
    }
    startValue
    targetValue
    progressType
    currentValue
    activities(first: 1, activityType: goal_checkin) {
      edges {
        node {
          id
          created
        }
      }
    }
  }
`;

export default gql`
  ${GoalOverviewRelatedEntityGoalFragment}
  fragment GoalOverviewRelatedEntityUserFragment on UserNode {
    id
    name
    avatar
    ownedGoals(artifactType: goal, goalDueBetweenDates: $goalDueBetweenDates) {
      edges {
        node {
          id
          ... on GoalArtifactNode {
            ...GoalOverviewRelatedEntityGoalFragment
          }
        }
      }
    }
  }
  fragment GoalOverviewRelatedEntityTeamFragment on TeamNode {
    id
    title
    teamGoals(
      artifactType: goal
      goalScope: team
      goalDueBetweenDates: $goalDueBetweenDates
    ) {
      edges {
        node {
          id
          ... on GoalArtifactNode {
            ...GoalOverviewRelatedEntityGoalFragment
          }
        }
      }
    }
  }
  query getGoalOverviewRelatedEntitiesQuery(
    $teamId: Int!
    $userId: Int!
    $organizationId: Int!
    $hasTeamId: Boolean!
    $hasUserId: Boolean!
    $hasOrganizationId: Boolean!
    $goalDueBetweenDates: [Date]!
  ) {
    user(userId: $userId) @include(if: $hasUserId) {
      id
      directReports {
        edges {
          node {
            ...GoalOverviewRelatedEntityUserFragment
          }
        }
      }
      managers {
        edges {
          node {
            ...GoalOverviewRelatedEntityUserFragment
          }
        }
      }
      teams {
        edges {
          node {
            ...GoalOverviewRelatedEntityTeamFragment
          }
        }
      }
    }
    team(teamId: $teamId) @include(if: $hasTeamId) {
      id
      members {
        edges {
          node {
            ...GoalOverviewRelatedEntityUserFragment
          }
        }
      }
    }
    organization(pk: $organizationId) @include(if: $hasOrganizationId) {
      id
      teams {
        edges {
          node {
            ...GoalOverviewRelatedEntityTeamFragment
          }
        }
      }
    }
  }
`;
