import { gql } from "@apollo/client";

export default gql`
  query getOrganizationNotifications($organizationId: Int!) {
    organization(pk: $organizationId) {
      id
      slackNotifications {
        edges {
          node {
            id
            channel
            recognitions
            goalCheckins
          }
        }
      }
      hasSlackConnection
      publicSlackChannels {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`;
