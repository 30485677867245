import { DashboardInsightFragment } from "types/graphql-schema";
import { BasicUser } from "types/topicflow";

import useLabel from "@apps/use-label/use-label";
import { assertNonNull } from "@helpers/helpers";

import { InsightButton, InsightLayout } from "../insight";

const AssessmentInsight = ({
  insight,
  onDismissInsight,
  selectedUser,
}: {
  insight: DashboardInsightFragment;
  onDismissInsight: () => void;
  selectedUser: BasicUser;
}) => {
  const label = useLabel();
  const complianceProgram = assertNonNull(insight.complianceProgram);

  return (
    <InsightLayout
      user={selectedUser}
      onDismissInsight={onDismissInsight}
      isRequired={!!insight.complianceProgram?.id}
    >
      <div className="flex-1">
        <div>
          Complete your {label("review", { pluralize: true })}
          {": "}
          {complianceProgram.title}
        </div>
      </div>
      <div>
        <InsightButton url="/assessments">
          Go to {label("review", { pluralize: true })}
        </InsightButton>
      </div>
    </InsightLayout>
  );
};

export default AssessmentInsight;
