import {
  ChevronDownIcon,
  ChevronRightIcon,
  LockClosedIcon,
  LockOpenIcon,
  PencilIcon,
  UserGroupIcon,
} from "@heroicons/react/outline";
import { noop, sortBy } from "lodash";
import moment from "moment";
import { useEffect, useRef } from "react";
import { TbBuilding, TbTag, TbUserCircle } from "react-icons/tb";
import {
  GetKpiSummaryQueryQuery,
  KpiGroupNode,
  KpiVisibility,
} from "types/graphql-schema";

import useLabel from "@apps/use-label/use-label";
import useUiPreferenceCache from "@apps/use-ui-preference-cache/use-ui-preference-cache";
import Avatar from "@components/avatar/avatar";
import AppLink from "@components/link/link";
import Tooltip from "@components/tooltip/tooltip";
import { classNames } from "@helpers/css";
import { assertEdgesNonNull, assertNonNull } from "@helpers/helpers";

import KPISummaryTableRow from "./summary-table-row";

const widthFirstColumnClassName = "w-56";
const heightClassName = "h-14";
const measurementCellClassName = "text-center px-2 w-36 flex-none";
const measurementHeaderCellClassName = classNames(measurementCellClassName);
export const measurementRowCellClassName = classNames(
  measurementCellClassName,
  heightClassName,
  "py-2 text-gray-500 text-center border-b border-r border-gray-300 text-sm"
);

const KPISummaryTableTitle = ({
  kpiGroup,
  tableTitle,
  collapsedId,
}: {
  kpiGroup: KpiGroupNode;
  tableTitle: string;
  collapsedId: string | number;
}) => {
  const { saveUiPreference, uiPreferenceCache } = useUiPreferenceCache();

  const handleToggleTable = () => {
    const collapsed =
      uiPreferenceCache.kpiSummaryTableCollapsedIds.includes(collapsedId);
    saveUiPreference({
      kpiSummaryTableCollapsedIds: collapsed
        ? uiPreferenceCache.kpiSummaryTableCollapsedIds.filter(
            (id: string | number) => id !== collapsedId
          )
        : uiPreferenceCache.kpiSummaryTableCollapsedIds.concat(collapsedId),
    });
  };

  return (
    <div className={classNames("flex-1 flex items-center gap-1.5")}>
      {collapsedId === "organization" ? (
        <TbBuilding className="h-4 w-4 text-gray-500 shrink-0" />
      ) : collapsedId === "personal" ? (
        <TbUserCircle className="h-4 w-4 text-gray-500 shrink-0" />
      ) : String(collapsedId).startsWith("team-") ? (
        <UserGroupIcon className="h-4 w-4 text-gray-500 shrink-0" />
      ) : (
        <TbTag className="h-4 w-4 text-gray-500 shrink-0" />
      )}
      <Tooltip text={tableTitle}>
        <AppLink
          to={`/kpis?kpiGroup=${collapsedId}`}
          className="hover:underline truncate"
        >
          {tableTitle}
        </AppLink>
      </Tooltip>
      <button onClick={handleToggleTable}>
        {uiPreferenceCache.kpiSummaryTableCollapsedIds.includes(collapsedId) ? (
          <ChevronRightIcon className="h-4 w-4 shrink-0" />
        ) : (
          <ChevronDownIcon className="h-4 w-4 shrink-0" />
        )}
      </button>
      {kpiGroup && (
        <AppLink
          to={`/kpis/group/${kpiGroup.id}`}
          className="rounded p-0.5 hover:bg-gray-200 text-gray-500 shrink-0"
        >
          <PencilIcon className="h-3 w-3" />
        </AppLink>
      )}
    </div>
  );
};

const KPISummaryTable = ({
  kpiGroup,
  kpis,
  collapsedId,
  tableTitle = "KPIs",
  onAddValueValue = noop,
}: {
  kpiGroup?: any;
  kpis: GetKpiSummaryQueryQuery["kpi"][];
  collapsedId?: any;
  tableTitle?: string;
  onAddValueValue?: () => void;
}) => {
  const label = useLabel();
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const { uiPreferenceCache } = useUiPreferenceCache();
  const isCollapsed =
    uiPreferenceCache.kpiSummaryTableCollapsedIds.includes(collapsedId);

  const firstKpiNodeMeasurements = sortBy(
    kpis?.[0]?.summarizedMeasurements.edges,
    (edge) => {
      const node = assertNonNull(edge?.node);
      return moment(node.date).unix();
    }
  );
  const hasDates = firstKpiNodeMeasurements.length;

  useEffect(() => {
    if (hasDates && scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft =
        scrollContainerRef.current.scrollWidth;
    }
  }, [hasDates]);

  if (kpis.length === 0) {
    return (
      <div className="border border-gray-300 rounded-md overflow-hidden bg-white">
        <div className="text-xs font-medium bg-gray-50 text-gray-600 tracking-tight">
          <div className={classNames("p-2 flex items-center")}>
            <KPISummaryTableTitle
              tableTitle={tableTitle}
              kpiGroup={kpiGroup}
              collapsedId={collapsedId}
            />
          </div>
        </div>
        {!isCollapsed && (
          <div className="border-t border-gray-300 text-xs p-2 text-gray-500 text-center tracking-tight">
            No KPIs
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="flex w-full">
      <div className="flex-none relative">
        <div className="absolute top-0 -right-1 bottom-0 w-1 bg-gradient-to-r from-gray-800/10 " />
        <div
          className={classNames(
            widthFirstColumnClassName,
            "border border-gray-300 text-xs font-medium p-2 bg-gray-50 text-gray-600 tracking-tight rounded-tl-md",
            isCollapsed && "rounded-bl-md"
          )}
        >
          <KPISummaryTableTitle
            tableTitle={tableTitle}
            kpiGroup={kpiGroup}
            collapsedId={collapsedId}
          />
        </div>
        {!isCollapsed &&
          kpis.map((kpi, i) => {
            if (!kpi) {
              return null;
            }
            return (
              <div
                className={classNames(
                  "border-b border-l border-r border-gray-300 text-sm px-2 py-1.5 flex-none flex flex-col items-start justify-center",
                  widthFirstColumnClassName,
                  heightClassName,
                  i === kpis.length - 1 && "rounded-bl-md"
                )}
                key={kpi.id}
              >
                <AppLink
                  to={`/kpis/${kpi.id}`}
                  className="font-medium text-blue-link hover:underline w-full truncate tracking-tight"
                >
                  <Tooltip text={kpi.title}>
                    <span>{kpi.title}</span>
                  </Tooltip>
                </AppLink>
                {kpi.visibility === KpiVisibility.Org ? (
                  <Tooltip
                    text={`Visible to members of ${
                      kpi.organization?.name
                    } ${label("organization")}`}
                  >
                    <div className="text-gray-500 text-xs mt-1 tracking-tight flex items-center gap-1">
                      <LockOpenIcon className="h-3 w-3" />
                      {kpi.organization?.name}
                    </div>
                  </Tooltip>
                ) : kpi.visibility === KpiVisibility.Personal ? (
                  <Tooltip text={`Visible only to ${kpi.creator?.name}`}>
                    <div className="flex items-center gap-1 text-gray-500 text-xs min-w-0 mt-1 tracking-tight">
                      <LockClosedIcon className="h-3 w-3" />
                      <div className="flex-1 truncate">{kpi.creator?.name}</div>
                      <Avatar user={kpi.creator} size="3" />
                    </div>
                  </Tooltip>
                ) : kpi.visibility === KpiVisibility.Teams ? (
                  <Tooltip
                    text={`Visible only to ${label("team", {
                      pluralize: true,
                    })} members.`}
                  >
                    <div className="flex items-center gap-1 text-gray-500 text-xs min-w-0 mt-1 tracking-tight">
                      <LockOpenIcon className="h-3 w-3" />
                      <div className="flex-1 truncate">
                        {assertEdgesNonNull(kpi.teams)
                          .map(({ title }) => title)
                          .join(", ")}
                      </div>
                    </div>
                  </Tooltip>
                ) : null}
              </div>
            );
          })}
      </div>
      <div
        className="flex-1 overflow-x-auto overscroll-x-none"
        ref={scrollContainerRef}
      >
        <div className="flex ">
          {firstKpiNodeMeasurements.map((edge, i) => {
            const node = assertNonNull(edge?.node);
            return (
              <div
                key={node.date}
                className={classNames(
                  "border-r border-b border-t border-gray-300 text-xs font-medium p-2 bg-gray-50 text-gray-600 tracking-tight",
                  measurementHeaderCellClassName,
                  i === firstKpiNodeMeasurements.length - 1 && "rounded-tr-md",
                  i === firstKpiNodeMeasurements.length - 1 &&
                    isCollapsed &&
                    "rounded-br-md"
                )}
              >
                {moment(node.date).format("ll")}
              </div>
            );
          })}
        </div>
        {!isCollapsed &&
          kpis.map((kpi, i) => {
            if (!kpi) {
              return null;
            }
            return (
              <KPISummaryTableRow
                key={kpi.id}
                kpi={kpi}
                isLastKpi={i === kpis.length - 1}
                onAddValue={onAddValueValue}
              />
            );
          })}
      </div>
    </div>
  );
};

export default KPISummaryTable;
