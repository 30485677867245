import { currentOrganizationVar } from "@cache/cache";
import {
  chromeExtensionUrl,
  onlineVideoConferenceType,
} from "@helpers/constants";

import { OnlineVideoConference } from "../../../types/graphql-schema";

const NoTranscriptCta = ({
  videoConferenceType,
}: {
  videoConferenceType: OnlineVideoConference | null | undefined;
}) => {
  const currentOrganization = currentOrganizationVar();
  return (
    <div>
      No transcript has been recorded for this meeting. Use the{" "}
      {videoConferenceType === onlineVideoConferenceType.teams ? (
        <a
          href={`/settings/organization/${currentOrganization.id}/integrations`}
          target="_blank"
          rel="noreferrer"
          className="text-blue-link hover:underline"
        >
          Teams integration
        </a>
      ) : (
        <a
          href={chromeExtensionUrl}
          target="_blank"
          rel="noreferrer"
          className="text-blue-link hover:underline"
        >
          Topicflow chrome extension
        </a>
      )}{" "}
      to save the transcript.
    </div>
  );
};

export default NoTranscriptCta;
