import { useQuery } from "@apollo/client";
import {
  GetKpiMeasurementsQueryQuery,
  GetKpiMeasurementsQueryQueryVariables,
} from "types/graphql-schema";

import Loading from "@components/loading/loading";
import {
  Table,
  TableBody,
  TableContainer,
  TableFooter,
  TableHeadCell,
  TableHeadRow,
} from "@components/table/table";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { assertEdgesNonNull } from "@helpers/helpers";

import getKpiMeasurementsQuery from "../graphql/get-kpi-measurements-query";
import KPIViewMeasurement from "./measurement";

const KPIMeasurementsTable = ({
  kpi,
}: {
  kpi: {
    id: number;
  };
}) => {
  const { data, loading, fetchMore } = useQuery<
    GetKpiMeasurementsQueryQuery,
    GetKpiMeasurementsQueryQueryVariables
  >(getKpiMeasurementsQuery, {
    variables: {
      kpiId: kpi.id,
      after: null,
    },
    onError: onNotificationErrorHandler(),
  });

  const handleClickViewMore = () => {
    if (data?.kpi?.measurements) {
      fetchMore({
        variables: {
          after: data.kpi.measurements.pageInfo.endCursor,
          merge: true,
        },
      });
    }
  };

  const measurements = data?.kpi?.measurements
    ? assertEdgesNonNull(data.kpi.measurements)
    : [];

  // RENDER
  return loading ? (
    <Loading>Loading measurements</Loading>
  ) : (
    <div>
      {measurements.length > 0 ? (
        <div>
          <TableContainer>
            <Table>
              <TableHeadRow>
                <TableHeadCell roundedLeft>Timestamp</TableHeadCell>
                <TableHeadCell>Created</TableHeadCell>
                <TableHeadCell>Value</TableHeadCell>
                <TableHeadCell roundedRight></TableHeadCell>
              </TableHeadRow>
              <TableBody>
                {measurements.map((measurement) => (
                  <KPIViewMeasurement
                    key={measurement.id}
                    measurement={measurement}
                    kpiId={kpi.id}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TableFooter>
            {data?.kpi?.measurements?.pageInfo.hasNextPage && (
              <div className="flex justify-center">
                {loading ? (
                  <Loading mini size="5" />
                ) : (
                  <button
                    onClick={handleClickViewMore}
                    className="text-gray-500 hover:underline"
                  >
                    View more
                  </button>
                )}
              </div>
            )}
          </TableFooter>
        </div>
      ) : (
        <div className="text-gray-500 italic text-sm">No measurements</div>
      )}
    </div>
  );
};

export default KPIMeasurementsTable;
