import { UserActivityComplianceGroupAssessmentDeliveryFragment } from "types/graphql-schema";

import Avatar from "@components/avatar/avatar";
import Button, { buttonTheme } from "@components/button/button";
import Table, {
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableContainer,
  TableHeadCell,
  TableHeadRow,
} from "@components/table/table";

const ComplianceProgramUserActivityAssessmentDelivery = ({
  assessmentDelivery,
}: {
  assessmentDelivery: UserActivityComplianceGroupAssessmentDeliveryFragment;
}) => {
  return (
    <TableBodyRow key={assessmentDelivery.target?.id}>
      <TableBodyCell>
        <div className="flex items-center gap-2 text-gray-800">
          <Avatar user={assessmentDelivery.target} size="5" />
          {assessmentDelivery.target?.name}
        </div>
      </TableBodyCell>
      <TableBodyCell>
        <div className="flex items-center justify-between gap-2 text-gray-800">
          <div>
            <span className="font-medium capitalize">
              {assessmentDelivery.group.assessmentType}:
            </span>{" "}
            {assessmentDelivery.group.title}
          </div>
          <Button
            theme={buttonTheme.lightBlue}
            text="Deliver"
            to={`/assessments/assessment/delivery/${assessmentDelivery.id}`}
          />
        </div>
      </TableBodyCell>
    </TableBodyRow>
  );
};

const ComplianceProgramUserActivityAssessmentDeliveries = ({
  assessmentDeliveries,
}: {
  complianceProgramId: number;
  assessmentDeliveries: UserActivityComplianceGroupAssessmentDeliveryFragment[];
}) => {
  return (
    <TableContainer>
      <Table>
        <TableHeadRow>
          <TableHeadCell width="72">Target</TableHeadCell>
          <TableHeadCell>Assessment</TableHeadCell>
        </TableHeadRow>
        <TableBody>
          {assessmentDeliveries.map((assessmentDelivery) => (
            <ComplianceProgramUserActivityAssessmentDelivery
              key={assessmentDelivery.id}
              assessmentDelivery={assessmentDelivery}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ComplianceProgramUserActivityAssessmentDeliveries;
