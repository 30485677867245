import { gql } from "@apollo/client";

import TopicTemplateFragment from "@apps/templates/graphql/topic-template-fragment";

export default gql`
  ${TopicTemplateFragment}
  query getTemplateSeriesQuery($meetingGroupId: Int!) {
    meetingGroup(meetingGroupId: $meetingGroupId) {
      id
      isFormalOneonone
      allowAdminVisibility
      templateSeries {
        edges {
          node {
            id
            topicTemplates {
              edges {
                node {
                  id
                  topicTemplate {
                    ...TopicTemplateFragment
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
