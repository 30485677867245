import { gql } from "@apollo/client";

export default gql`
  fragment AssessmentNomineeResponder on UserNode {
    id
    name
    avatar
    activeAssessmentNominationCount
  }

  fragment AssessmentNominee on AssessmentNominationNode {
    id
    responder {
      ...AssessmentNomineeResponder
    }
  }

  query getAssessmentProgramNominees($programId: Int!, $targetId: Int!) {
    complianceProgram(pk: $programId) {
      id
      nominationsForUser(userId: $targetId) {
        edges {
          node {
            id
            ...AssessmentNominee
          }
        }
      }
    }
  }
`;
