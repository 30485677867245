import { gql } from "@apollo/client";

const KeyResultFragment = gql`
  fragment KeyResultFragment on KeyResultNode {
    id
    title
    startValue
    currentValue
    targetValue
    progressType
    kpi {
      id
      title
      currentMeasurement {
        id
        measurement
      }
    }
    canUpdate {
      permission
    }
    assignee {
      id
      name
      avatar
      email
    }
  }
`;

export default KeyResultFragment;
