import { MdEventRepeat } from "react-icons/md";
import { ComplianceProgramRecurrence } from "types/graphql-schema";

import Tooltip from "@components/tooltip/tooltip";

const recurrenceTooltip: { [key in ComplianceProgramRecurrence]: string } = {
  [ComplianceProgramRecurrence.None]: "No recurrence",
  [ComplianceProgramRecurrence.Monthly]: "Repeats monthly",
  [ComplianceProgramRecurrence.Quarterly]: "Repeats quarterly",
  [ComplianceProgramRecurrence.Yearly]: "Repeats yearly",
};

const ComplianceProgramRecurrencTooltip = ({
  recurrence,
}: {
  recurrence: ComplianceProgramRecurrence;
}) => {
  return (
    <Tooltip text={recurrenceTooltip[recurrence]}>
      <MdEventRepeat className="h-4 w-4" />
    </Tooltip>
  );
};

export default ComplianceProgramRecurrencTooltip;
