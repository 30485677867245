import { useReactiveVar } from "@apollo/client";
import { Editor } from "@tiptap/core";
import { EditorContent, useEditor } from "@tiptap/react";
import { useEffect } from "react";

import { currentTiptapJWTVar, currentUserVar } from "@cache/cache";
import { classNames } from "@helpers/css";

import BubbleMenu from "./extensions/bubble-menu";
import { getExtensions } from "./helpers";
import { allSuggestion } from "./mentions/all-suggestions";

const FeedbackMessageWYSIWYG = ({
  value,
  autofocus = false,
  editable = false,
  className,
  placeholder = "Feedback message...",
  editorProps = {},
  artifactId,
  organizationId,
  onUpdateContent,
}: {
  autofocus?: boolean;
  className?: string;
  editorProps?: any;
  editable: boolean;
  placeholder?: string;
  artifactId: number;
  organizationId: number;
  value: any;
  onUpdateContent?: ({ editor }: { editor: Editor }) => void;
}) => {
  const currentUser = currentUserVar();
  const tiptapAiJwt = useReactiveVar(currentTiptapJWTVar);
  const { id, name, avatar } = currentUser;

  // need to refactor this at some point
  const atMentionSuggestions = allSuggestion({ artifactId });

  const context = {
    organizationId,
    currentUser: { id, name, avatar },
  };
  const extensions = getExtensions({
    context,
    placeholder,
    uploadVariable: { artifactId },
    emptyPlaceholder: null,
    atMentionSuggestions,
    paidFeatures: currentUser.paidFeatures,
    tiptapAiJwt,
    history: undefined,
  });

  const editor = useEditor(
    {
      autofocus,
      editable,
      // when creating/updating extensions, never rename extension.name
      // otherwise previous discussion notes won't be able to
      // render the content of that extension.
      extensions: extensions,
      editorProps: {
        attributes: {
          class: classNames(
            "prose max-w-full pl-7 focus:outline-none break-words relative js-feedback-message",
            className
          ),
        },
        ...context,
        ...editorProps,
      },
    },
    [tiptapAiJwt]
  );

  useEffect(() => {
    if (onUpdateContent) {
      editor?.on("update", onUpdateContent);
    }
    return function cleanup() {
      if (onUpdateContent) {
        editor?.off("update", onUpdateContent);
      }
    };
  }, [onUpdateContent, editor]);

  useEffect(() => {
    if (editor) {
      editor.chain().setContent(value).run();
    }
  }, [editor]);

  return (
    <>
      {editor && !editor.isDestroyed && (
        <BubbleMenu
          editor={editor}
          organizationId={context.organizationId}
          enableComment={false}
        />
      )}
      <EditorContent editor={editor} />
    </>
  );
};

export default FeedbackMessageWYSIWYG;
