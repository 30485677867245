import { gql } from "@apollo/client";

export default gql`
  mutation updateOrganizationMembershipMutation(
    $userId: Int!
    $organizationId: Int!
    $position: String
  ) {
    updateOrganizationMembership(
      userId: $userId
      organizationId: $organizationId
      position: $position
    ) {
      member {
        id
        position
      }
    }
  }
`;
