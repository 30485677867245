import { gql } from "@apollo/client";

import GoalTeamsFragment from "@apps/artifact-sidebar/graphql/goal-teams-fragment";
import CommonGoalArtifactFragment from "@graphql/common-goal-artifact-fragment";

export default gql`
  ${CommonGoalArtifactFragment}
  ${GoalTeamsFragment}
  mutation updateGoalTeamsMutation(
    $artifactId: Int!
    $additionalFields: AdditionalArtifactInput!
  ) {
    createOrUpdateArtifact(
      artifactId: $artifactId
      additionalFields: $additionalFields
    ) {
      artifact {
        id
        ... on GoalArtifactNode {
          ...CommonGoalArtifactFragment
          ...GoalTeamsFragment
        }
      }
    }
  }
`;
