import { TbTarget, TbTargetArrow } from "react-icons/tb";
import { GoalScope, GoalState } from "types/graphql-schema";

import useLabel from "@apps/use-label/use-label";
import Tooltip from "@components/tooltip/tooltip";
import { classNames, getGoalTextColorClassName } from "@helpers/css";

type GoalIconProps = {
  state?: GoalState;
  scope?: GoalScope;
  size?: string | number;
  className?: string;
};

const GoalIcon = ({
  size = "5",
  state = GoalState.Draft,
  scope = GoalScope.Personal,
  className = "",
}: GoalIconProps) => {
  const label = useLabel();
  let suffix = "";
  if (scope === GoalScope.Personal) {
    suffix = "Individual ";
  } else if (scope === GoalScope.Team) {
    suffix = `${label("team", { capitalize: true })} `;
  } else if (scope === GoalScope.Organization) {
    suffix = `${label("organization", { capitalize: true })} `;
  } else if (scope === GoalScope.Career) {
    suffix = `Career `;
  }
  return (
    <Tooltip text={`${suffix}${label("goal")}`}>
      <span>
        {state === GoalState.Closed ? (
          <TbTargetArrow
            className={classNames(
              `w-${size} h-${size} ${getGoalTextColorClassName(scope)}`,
              className
            )}
          />
        ) : (
          <TbTarget
            className={classNames(
              `w-${size} h-${size} ${getGoalTextColorClassName(scope)}`,
              className
            )}
          />
        )}
      </span>
    </Tooltip>
  );
};

export default GoalIcon;
