import { PropsWithChildren } from "react";

import { classNames } from "@helpers/css";

type Props = {
  label: string;
  name?: string;
  className?: string;
};
const Label: React.FC<PropsWithChildren<Props>> = ({
  label,
  name,
  children,
  className = "",
}) => {
  return (
    <div>
      <label
        htmlFor={name}
        className={classNames(
          "block text-sm font-medium text-gray-700 mb-1",
          className
        )}
      >
        {label}
      </label>
      {children}
    </div>
  );
};

export default Label;
