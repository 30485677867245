import { useMutation } from "@apollo/client";
import { useCallback, useMemo, useState } from "react";
import {
  AssessmentType,
  SendAssessmentReminderMutation,
  SendAssessmentReminderMutationVariables,
} from "types/graphql-schema";

import useLabel from "@apps/use-label/use-label";
import { successNotificationVar } from "@cache/cache";
import Avatar from "@components/avatar/avatar";
import Button, { buttonTheme } from "@components/button/button";
import Table, {
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableContainer,
  TableHeadCell,
  TableHeadRow,
} from "@components/table/table";
import { useNotificationError } from "@components/use-error/use-error";

import sendAssessmentReminderMutation from "../graphql/send-assessment-reminder-mutation";
import { ProgramTab, ProgramTabType } from "./compliance-program";

const MAX_MISSING_ASSESSMENTS = 10;

const MissingAssessmentTable = ({
  assessmentType,
  complianceProgramId,
  usersMissingAssessment,
  tab,
}: {
  assessmentType: AssessmentType;
  complianceProgramId: number;
  usersMissingAssessment: {
    responder: { id: number; name: string };
    target: { id: number; name: string };
  }[];
  tab: ProgramTab;
}) => {
  const label = useLabel();
  const { onError } = useNotificationError();
  const [
    isMissingAssessmentTableExpanded,
    setIsMissingAssessmentTableExpanded,
  ] = useState(false);

  const [sendAssessmentReminder, { loading: isSendingAssessmentReminder }] =
    useMutation<
      SendAssessmentReminderMutation,
      SendAssessmentReminderMutationVariables
    >(sendAssessmentReminderMutation);

  const sendAssessmentEmailReminder = useCallback(() => {
    sendAssessmentReminder({
      variables: { complianceProgramId },
      onError,
      onCompleted: () => successNotificationVar({ title: "Reminder sent" }),
    });
  }, [complianceProgramId, onError, sendAssessmentReminder]);

  const missingAssessmentTableCutoff = useMemo(
    () => Math.min(MAX_MISSING_ASSESSMENTS, usersMissingAssessment.length),
    [usersMissingAssessment]
  );

  return (
    <div className="mt-8">
      <div className="flex items-center mb-2">
        <div className="font-bold mr-4">{`Missing ${label("review", {
          pluralize: true,
        })}`}</div>
        <Button
          theme={buttonTheme.lightBlue}
          disabled={isSendingAssessmentReminder}
          onClick={sendAssessmentEmailReminder}
        >
          Send email reminder
        </Button>
      </div>
      <TableContainer aria-label="Missing an assessment table">
        <Table>
          <TableHeadRow>
            <TableHeadCell>Name</TableHeadCell>
            {tab.type === ProgramTabType.ASSESSMENTS && (
              <TableHeadCell>
                {assessmentType === AssessmentType.Performance
                  ? "Manager"
                  : assessmentType === AssessmentType.Peer
                  ? "Peer"
                  : "Direct report"}
              </TableHeadCell>
            )}
          </TableHeadRow>
          <TableBody>
            {usersMissingAssessment
              .slice(
                0,
                isMissingAssessmentTableExpanded
                  ? undefined
                  : missingAssessmentTableCutoff
              )
              .map((node, index) => {
                const responder = node.responder;
                const target = node.target;

                return (
                  <TableBodyRow
                    key={`${tab.assessmentGroup}${responder.id}${target.id}${index}`}
                  >
                    <TableBodyCell>
                      <div className="flex items-center">
                        <Avatar className="mr-2" size="8" user={target} />{" "}
                        {target.name}
                      </div>
                    </TableBodyCell>
                    {tab.type === ProgramTabType.ASSESSMENTS && (
                      <TableBodyCell>{responder.name}</TableBodyCell>
                    )}
                  </TableBodyRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {!isMissingAssessmentTableExpanded &&
        missingAssessmentTableCutoff < usersMissingAssessment.length && (
          <div className="mt-2 flex items-center justify-center">
            <Button
              onClick={() => setIsMissingAssessmentTableExpanded(true)}
              theme={buttonTheme.text}
            >
              + {usersMissingAssessment.length - missingAssessmentTableCutoff}{" "}
              more
            </Button>
          </div>
        )}
    </div>
  );
};

export default MissingAssessmentTable;
