import { gql } from "@apollo/client";

export default gql`
  mutation removeHubspotIntegrationMutation {
    removeHubspotIntegration {
      user {
        id
        hasHubspotAuth
      }
    }
  }
`;
