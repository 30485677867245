import { useMemo } from "react";
import {
  AssessmentQuestionType,
  GetComplianceProgramForReportQuery,
  RangeAssessmentQuestionNode,
} from "types/graphql-schema";
import { BasicUser } from "types/topicflow";

import {
  getManagerEffectivenessAnswerPercentage,
  matchesReportsToFilter,
  matchesTeamsFilter,
  matchesUserFilter,
} from "@apps/reporting/helpers";
import Avatar from "@components/avatar/avatar";
import Table, {
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableContainer,
  TableHeadCell,
  TableHeadRow,
} from "@components/table/table";
import { assertNonNull } from "@helpers/helpers";

import { TeamPickerTeam } from "../team-picker";

const ManagerEffectivenessTable = ({
  questions,
  complianceProgram,
  userFilterList,
  reportsToFilterList,
  teamFilterList,
}: {
  complianceProgram: NonNullable<
    GetComplianceProgramForReportQuery["complianceProgram"]
  >;
  questions: Pick<
    RangeAssessmentQuestionNode,
    "id" | "title" | "startValue" | "endValue" | "labels" | "questionType"
  >[];
  userFilterList: BasicUser[];
  reportsToFilterList: BasicUser[];
  teamFilterList: TeamPickerTeam[];
}) => {
  const filteredQuestions = useMemo(
    () =>
      questions.filter((q) => q.questionType === AssessmentQuestionType.Range),
    [questions]
  );
  return (
    <TableContainer scroll>
      <Table>
        <TableHeadRow>
          <TableHeadCell>Name</TableHeadCell>
          <TableHeadCell>Responses/Reports</TableHeadCell>
          {filteredQuestions.map((question) => {
            return (
              <TableHeadCell key={question.id}>{question.title}</TableHeadCell>
            );
          })}
        </TableHeadRow>
        <TableBody>
          {complianceProgram.managerEffectivenessReport.edges.map((rowEdge) => {
            const row = assertNonNull(rowEdge?.node);
            if (!matchesUserFilter(userFilterList, row.manager)) {
              return null;
            }
            if (!matchesReportsToFilter(reportsToFilterList, row.manager)) {
              return null;
            }
            if (!matchesTeamsFilter(teamFilterList, row.manager)) {
              return null;
            }
            return (
              <TableBodyRow key={row.manager.id}>
                <TableBodyCell>
                  <div className="flex items-center">
                    <Avatar className="mr-2" size="6" user={row.manager} />{" "}
                    {row.manager.name}
                  </div>
                </TableBodyCell>
                <TableBodyCell>
                  {row.assessments.totalCount}/
                  {row.manager.directReports.totalCount}
                </TableBodyCell>
                {filteredQuestions.map((question) => {
                  const questionData = row.questions.edges.find(
                    (q) => q?.node?.question.id === question.id
                  )?.node;

                  if (!questionData) {
                    return <TableBodyCell key={question.id}>N/A</TableBodyCell>;
                  }
                  return (
                    <TableBodyCell key={question.id}>
                      {Math.round(
                        getManagerEffectivenessAnswerPercentage(
                          question,
                          questionData.avgAnswer
                        )
                      )}
                      %
                    </TableBodyCell>
                  );
                })}
              </TableBodyRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ManagerEffectivenessTable;
