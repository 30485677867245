import Loading from "@components/loading/loading";

const KpiPlaceholder = ({ node }: { node: any }) => {
  return (
    <div
      className="flex items-center text-xs italic gap-4 border py-2 px-4 rounded bg-gray-50"
      contentEditable={false}
    >
      <Loading mini size="4" />
      <div>
        {node.attrs.createdByUser?.name || "A meeting participant"} is creating
        a kpi.
      </div>
    </div>
  );
};

export default KpiPlaceholder;
