import { gql } from "@apollo/client";

export default gql`
  mutation submitOneononeNewPage(
    $meetingId: Int!
    $unhideIndividualNotes: Boolean
  ) {
    submitOneonone(
      meetingId: $meetingId
      unhideIndividualNotes: $unhideIndividualNotes
    ) {
      meeting {
        id
        isSubmitted
        topics {
          edges {
            node {
              individualNotes {
                edges {
                  node {
                    id
                    created
                    creator {
                      id
                    }
                    hidden
                    enabled
                    notes
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
