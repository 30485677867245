import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import {
  GetMeetingGroupSettingsQueryQuery,
  GetMeetingGroupSettingsQueryQueryVariables,
} from "types/graphql-schema";

import Container from "@components/container/container";
import GraphqlError from "@components/error/graphql-error";
import Loading from "@components/loading/loading";
import { onNotificationErrorHandler } from "@components/use-error/use-error";

import MeetingSettingsHeader from "./components/header";
import MeetingSettingsContent from "./components/settings-content";
import getMeetingGroupSettingsQuery from "./graphql/get-meeting-group-settings-query";

const MeetingSettings = () => {
  let { meetingGroupId } = useParams<{
    meetingGroupId: any;
  }>();
  meetingGroupId = parseInt(meetingGroupId);
  const { loading, error, data } = useQuery<
    GetMeetingGroupSettingsQueryQuery,
    GetMeetingGroupSettingsQueryQueryVariables
  >(getMeetingGroupSettingsQuery, {
    variables: {
      meetingGroupId: meetingGroupId,
    },
    onError: onNotificationErrorHandler(),
  });

  // Renders
  if (loading || !data) {
    return (
      <Container>
        <Loading />
      </Container>
    );
  }
  if ((error && !data) || !data.meetingGroup) {
    return (
      <Container>
        <GraphqlError
          error={new Error("Meeting group could not be loaded")}
          whatDidNotWork="We can't show you this meeting right now."
        />
      </Container>
    );
  }

  return (
    <div
      className="min-h-full flex flex-col items-stretch min-w-0 "
      aria-label="Meeting page"
    >
      <MeetingSettingsHeader meetingGroup={data.meetingGroup} />
      <MeetingSettingsContent meetingGroup={data.meetingGroup} />
    </div>
  );
};

export default MeetingSettings;
