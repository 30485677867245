import { gql } from "@apollo/client";

export default gql`
  query getRating($ratingId: Int!, $first: Int = 20, $after: String) {
    rating(ratingId: $ratingId) {
      id
      title
      description
      startValue
      endValue
      labels
      created
      creator {
        id
        name
      }
      organization {
        id
        name
      }
      canDelete {
        permission
        reason
      }
      canUpdate {
        permission
        reason
      }
      answers(first: $first, after: $after, orderBy: "-created") {
        totalCount
        averageRating
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            id
            created
            creator {
              id
              name
            }
            integerAnswer
            createdInMeeting {
              id
              title
              meetingGroupId
              startDatetime
            }
          }
        }
      }
    }
    topicTemplates(includesRatingId: $ratingId) {
      edges {
        node {
          id
          title
        }
      }
    }
  }
`;
