import { gql } from "@apollo/client";

export default gql`
  mutation deleteDraftMeetingNewPageMutation($meetingId: Int!) {
    deleteMeeting(meetingId: $meetingId) {
      meeting {
        id
      }
    }
  }
`;
