import { gql } from "@apollo/client";

const getKPIsQuery = gql`
  query getKPIsQuery {
    kpis {
      edges {
        node {
          id
          title
          description
        }
      }
    }
  }
`;

export default getKPIsQuery;
