import { classNames } from "@helpers/css";

export const CollapsibleLoading = ({
  roundedBottom = false,
}: {
  roundedBottom?: boolean;
}) => {
  return (
    <div
      className={classNames(
        "flex justify-between gap-4 bg-white px-4 py-3",
        roundedBottom && "rounded-b-lg"
      )}
    >
      <div className="skeleton h-6 flex-1 rounded-lg" />
      <div className="skeleton h-6 w-16 rounded-lg" />
    </div>
  );
};

export default CollapsibleLoading;
