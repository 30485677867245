import { gql } from "@apollo/client";

export default gql`
  query getCommentsOfRelatedTopic($topicId: Int!) {
    me {
      id
      avatar
      name
      avatar
    }
    comments(relatedToTopic: $topicId) {
      totalCount
      edges {
        node {
          id
          creator {
            id
            name
            avatar
          }
          created
          comment
        }
      }
    }
  }
`;
