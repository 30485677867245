import { ArrowSmRightIcon } from "@heroicons/react/outline";
import { compact, sortBy, truncate } from "lodash";
import numeral from "numeral";
import {
  ArtifactActivityFragmentFragment,
  ArtifactChangedFieldNode,
  CommentNode,
  GoalArtifactSidebarFragmentFragment,
  GoalState,
  GoalStatus,
  KeyResultNode,
} from "types/graphql-schema";

import ActivityComment from "@apps/activities/components/comment";
import useLabel from "@apps/use-label/use-label";
import GoalIcon from "@components/goal-icon/goal-icon";
import GoalStatusPill from "@components/goal-status-pill/goal-status-pill";
import KeyResultIcon from "@components/key-result-icon/key-result-icon";
import Tooltip from "@components/tooltip/tooltip";

export const maxKrTitleLength = 150;
const ActivityGoalCheckin = ({
  activity,
  artifact,
}: {
  artifact: GoalArtifactSidebarFragmentFragment;
  activity: ArtifactActivityFragmentFragment;
}) => {
  const goal = artifact;
  const label = useLabel();
  if (!activity?.changedFields || activity.changedFields.length === 0) {
    return null;
  }
  const sortedChangedFields = sortBy(activity.changedFields, (changedField) => {
    if (changedField?.fieldName === "comment") return 0;
    if (changedField?.fieldName === "status") return 1;
    if (changedField?.fieldName === "current_value") return 2;
    if (changedField?.fieldName?.startsWith("key_result_")) return 3;
    return 4;
  });
  const changedFields = sortedChangedFields.map((changedField) => {
    if (changedField?.fieldName === "comment") {
      const comment = changedField.newValueObject as CommentNode;
      return (
        <div key={changedField.fieldName}>
          <ActivityComment comment={comment} artifact={artifact} />
        </div>
      );
    }
    if (changedField?.fieldName === "status") {
      const newGoalStatus = changedField.newValue as GoalStatus;
      const previousGoalStatus = changedField.previousValue as GoalStatus;
      return (
        <div key={changedField.fieldName} className="flex items-center gap-1">
          <span className="font-medium">Status: </span>
          <div className="w-4 flex justify-center">
            <GoalStatusPill
              status={previousGoalStatus}
              state={GoalState.Open}
              size="3"
            />
          </div>
          {label(previousGoalStatus, { capitalize: true })}
          <ArrowSmRightIcon className="h-4 w-4 text-gray-500" />
          <div className="w-4 flex justify-center">
            <GoalStatusPill
              status={newGoalStatus}
              state={GoalState.Open}
              size="3"
            />
          </div>
          {label(newGoalStatus, { capitalize: true })}
        </div>
      );
    }
    if (changedField?.fieldName === "current_value") {
      return (
        <div key={changedField.fieldName} className="flex gap-1 items-center">
          <GoalIcon scope={goal.scope} state={GoalState.Open} size="4" />{" "}
          <span className="font-medium">
            {label("goal", { capitalize: true })} current value:{" "}
          </span>
          {numeral(changedField.previousValue).format("0[.][0000000000000]")}
          <ArrowSmRightIcon className="h-4 w-4 text-gray-500" />
          {numeral(changedField.newValue).format("0[.][0000000000000]")}
        </div>
      );
    }
    if (changedField?.fieldName?.startsWith("key_result_")) {
      const keyResult = changedField.newValueObject as KeyResultNode;
      const currentValueChange = changedField?.changedFields?.find(
        (field) => field?.fieldName === "current_value"
      ) as ArtifactChangedFieldNode;
      return (
        <div key={changedField.fieldName} className="flex gap-1 items-start">
          <KeyResultIcon className="mt-0.5" />
          <div className="flex gap-1 items-center flex-wrap">
            <Tooltip
              text={
                keyResult.title.length > maxKrTitleLength
                  ? keyResult.title
                  : null
              }
            >
              <span className="font-medium">
                {truncate(keyResult.title, { length: maxKrTitleLength })}:
              </span>
            </Tooltip>
            {numeral(currentValueChange?.previousValue).format(
              "0[.][0000000000000]"
            )}
            <ArrowSmRightIcon className="h-4 w-4 text-gray-500" />
            {numeral(currentValueChange?.newValue).format(
              "0[.][0000000000000]"
            )}
          </div>
        </div>
      );
    }
    return null;
  });
  return (
    <div className="flex flex-col gap-2 mt-2">{compact(changedFields)}</div>
  );
};

export default ActivityGoalCheckin;
