import { gql } from "@apollo/client";

export default gql`
  query getMeetingByGoogleMeetUrlQuery($googleMeetUrl: String!) {
    meeting(googleMeetUrl: $googleMeetUrl) {
      id
      meetingGroup {
        id
      }
    }
  }
`;
