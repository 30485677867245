import { gql } from "@apollo/client";

export default gql`
  mutation createOrUpdateTemplateSeriesForMeetingMutation(
    $meetingGroupId: Int!
    $templateId: Int
    $templateSeriesId: Int
    $topics: [TemplateTopicInput]!
  ) {
    createOrUpdateTemplateSeriesForMeeting(
      meetingGroupId: $meetingGroupId
      templateId: $templateId
      templateSeriesId: $templateSeriesId
      topics: $topics
    ) {
      templateSeries {
        id
        title
        description
        id
        topicTemplates {
          edges {
            node {
              id
              topicTemplate {
                id
                title
                description
                publicTemplate
                topics {
                  edges {
                    node {
                      id
                      title
                      description
                      discussionNotes
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
