import { compact, uniq } from "lodash";
import { useMemo } from "react";
import {
  MeetingViewMeetingNodeNewPageFragmentFragment,
  TopicIndividualNotesFragmentFragment,
} from "types/graphql-schema";

import { MeetingWebsocketType } from "@apps/meeting-new/context";
import { currentUserVar } from "@cache/cache";
import { getYDocAndWebsocketProvider } from "@components/wysiwyg/helpers";
import {
  assertEdgesNonNull,
  assertEdgesNonNullWithStringId,
} from "@helpers/helpers";

const useMeetingWebsocketProvider = (
  meeting: MeetingViewMeetingNodeNewPageFragmentFragment
) => {
  const currentUser = currentUserVar();

  const meetingParticipantUserIds = useMemo(() => {
    const participants = assertEdgesNonNullWithStringId(meeting.participants);
    return compact(participants.map((participant) => participant.user?.id));
  }, [meeting.participants]);
  const participantIdsWithNotes = useMemo(() => {
    const topics = assertEdgesNonNull(meeting.topics);
    const individualNotes = topics.reduce((memo, topic) => {
      return [...memo, ...assertEdgesNonNull(topic.individualNotes)];
    }, [] as TopicIndividualNotesFragmentFragment[]);
    const enabledNotes = individualNotes.filter(
      (individualNote) => individualNote.enabled
    );
    const participantIdsWithNotes = enabledNotes.map(
      (individualNote) => individualNote.creator.id
    );
    return uniq(compact(participantIdsWithNotes));
  }, [meeting.topics]);
  const websocketUserIds = meeting.meetingGroup?.isFormalOneonone
    ? meetingParticipantUserIds
    : participantIdsWithNotes;

  // Build provider websocket
  const meetingWebsocketContext = useMemo(() => {
    const websocketId = `meeting-${meeting.id}`;
    const websocketToken = meeting.websocketToken || "";
    const invididualMeetingWebsocketContext = websocketUserIds.reduce(
      (memo, participantUserId) => {
        if (!participantUserId) return memo;
        const publicWebsocketId = `meeting-${meeting.id}-participant-${participantUserId}`;
        const privateWebsocketId = `${publicWebsocketId}-private`;
        return {
          ...memo,
          [`${publicWebsocketId}`]: getYDocAndWebsocketProvider(
            publicWebsocketId,
            websocketToken
          ),
          // only current user can have a private websocket provider.
          ...(currentUser.id === participantUserId
            ? {
                [`${privateWebsocketId}`]: getYDocAndWebsocketProvider(
                  privateWebsocketId,
                  websocketToken
                ),
              }
            : {}),
        };
      },
      {} as { [key: string]: MeetingWebsocketType }
    );

    return {
      sharedNotes: getYDocAndWebsocketProvider(websocketId, websocketToken),
      ...invididualMeetingWebsocketContext,
    };
  }, [websocketUserIds, meeting.websocketToken, meeting.id, currentUser.id]);

  return meetingWebsocketContext;
};

export default useMeetingWebsocketProvider;
