import { compact, uniq } from "lodash";

import { getWatchedQuery } from "@graphql/client";

import getAlignmentGoalsQuery from "./graphql/get-alignment-goals-query";

export const refreshAlignmentOfGoalIds = (
  goalIds: (number | undefined | boolean | null)[]
) => {
  const watchedQuery = getWatchedQuery(getAlignmentGoalsQuery);
  const goalIdsToRefresh = uniq(compact(goalIds));
  if (watchedQuery && goalIdsToRefresh.length > 0) {
    watchedQuery.fetchMore({
      variables: {
        idsToMergeInApolloCache: goalIdsToRefresh,
        merge: true,
      },
    });
  }
};
