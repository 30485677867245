import { gql } from "@apollo/client";

export default gql`
  mutation removeJiraIntegrationMutation {
    removeJiraIntegration {
      user {
        id
        hasJiraAuth
      }
    }
  }
`;
