import { useCallback, useMemo, useState } from "react";
import {
  AttendeeRole,
  AttendeeStatus,
  DashboardInsightFragment,
} from "types/graphql-schema";

import MeetingDialog from "@apps/meeting-dialog/meeting-dialog";
import useLabel from "@apps/use-label/use-label";
import { assertNonNull } from "@helpers/helpers";

import { InsightButton, InsightLayout } from "../insight";

const ScheduleOneOnOneInsight = ({
  insight,
  onDismissInsight,
}: {
  insight: DashboardInsightFragment;
  onDismissInsight: () => void;
}) => {
  const label = useLabel();
  const [showCreateMeetingModal, setShowCreateMeetingModal] = useState(false);

  const handleClickCreateMeeting = useCallback(() => {
    setShowCreateMeetingModal(true);
  }, []);
  const handleCloseCreateMeeting = useCallback(() => {
    setShowCreateMeetingModal(false);
  }, []);

  const manager = useMemo(() => assertNonNull(insight.manager), [insight]);
  const report = useMemo(() => assertNonNull(insight.report), [insight]);

  return (
    <InsightLayout
      user={manager}
      onDismissInsight={onDismissInsight}
      isRequired={!!insight.complianceProgram?.id}
    >
      <div className="flex-1">
        No {label("oneonone", { pluralize: false, capitalize: false })}{" "}
        scheduled between {manager.firstName || manager.name} and{" "}
        {report.firstName || report.name}
      </div>
      <div>
        {showCreateMeetingModal && (
          <MeetingDialog
            onClose={handleCloseCreateMeeting}
            formOptions={{
              isFormalOneonone: true,
              attendees: [
                {
                  ...report,
                  role: AttendeeRole.Required,
                  participantStatus: AttendeeStatus.NotResponded,
                },
                {
                  ...manager,
                  role: AttendeeRole.Required,
                  participantStatus: AttendeeStatus.NotResponded,
                },
              ],
            }}
          />
        )}
        <InsightButton onClick={handleClickCreateMeeting}>
          Schedule {label("oneonone", { pluralize: false, capitalize: false })}
        </InsightButton>
      </div>
    </InsightLayout>
  );
};

export default ScheduleOneOnOneInsight;
