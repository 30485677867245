import { gql } from "@apollo/client";

export default gql`
  mutation updateCommentMutation($commentId: Int!, $comment: String!) {
    createOrUpdateComment(commentId: $commentId, comment: $comment) {
      comment {
        id
        comment
      }
    }
  }
`;
