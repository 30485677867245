import { gql } from "@apollo/client";

import ArtifactMentionFragment from "@graphql/artifact-mention-fragment";
import UserMentionFragment from "@graphql/user-mention-fragment";

export default gql`
  ${ArtifactMentionFragment}
  ${UserMentionFragment}
  query searchAtMentionsQuery(
    $limit: Int!
    $search: String!
    $onlyUsers: Boolean!
  ) {
    users(first: $limit, search: $search) {
      edges {
        node {
          ...UserMentionFragment
        }
      }
    }
    artifacts(
      search: $search
      artifactTypes: [action_item, decision, goal, document, recognition]
      first: $limit
    ) @exclude(if: $onlyUsers) {
      edges {
        node {
          ...ArtifactMentionFragment
        }
      }
    }
  }
`;
