import { useQuery } from "@apollo/client";
import { sortBy, uniqBy } from "lodash";
import {
  GetArtifactExplorerTeamsQueryQuery,
  GetArtifactExplorerTeamsQueryQueryVariables,
} from "types/graphql-schema";

import useLabel from "@apps/use-label/use-label";
import { currentOrganizationVar } from "@cache/cache";
import ComboboxGeneric, {
  ComboboxGenericOption,
} from "@components/combobox/generic-combobox";
import { useNotificationError } from "@components/use-error/use-error";
import { graphqlNone } from "@helpers/constants";
import { assertEdgesNonNull } from "@helpers/helpers";

import getArtifactExplorerTeamsQuery from "../graphql/get-artifact-explorer-teams-query";
import ExplorerComboboxButton from "./explorer-combobox-button";

const ExplorerTeamCombobox = ({
  teamId,
  prefix = "",
  ariaLabel = "Explorer team combobox",
  onSelectTeamId,
  unassignedText = "No assigned team",
  className = "",
}: {
  teamId?: number | null;
  prefix: string;
  ariaLabel: string;
  onSelectTeamId: (teamId: number | null) => void;
  unassignedText: string;
  className: string;
}) => {
  const currentOrganization = currentOrganizationVar();
  const unassignedOption = {
    title: unassignedText,
    label: unassignedText,
    id: graphqlNone,
    value: graphqlNone,
  };

  // Hooks
  const label = useLabel();
  const { onError } = useNotificationError();
  // should we load teams in the currentOrganization?
  const { data, loading } = useQuery<
    GetArtifactExplorerTeamsQueryQuery,
    GetArtifactExplorerTeamsQueryQueryVariables
  >(getArtifactExplorerTeamsQuery, {
    variables: {
      organizationId: currentOrganization.id,
    },
    onError,
  });

  // Computed data
  const teams = data?.organization?.teams
    ? assertEdgesNonNull(data.organization.teams)
    : [];
  const mergedOptions = sortBy(teams, ({ title }) => title.toLowerCase());
  const options = uniqBy([unassignedOption, ...mergedOptions], "id").map(
    (option) => ({
      ...option,
      value: option.id,
      label: option.title,
    })
  );
  const selectedOption = options.find(({ value }) => value === teamId) || null;

  const handleChangeValue = (option: ComboboxGenericOption<number | null>) => {
    onSelectTeamId(option.value);
  };

  const handleClearValue = () => {
    onSelectTeamId(null);
  };

  return (
    <div className="flex-1 min-w-0">
      <ComboboxGeneric<number | null>
        aria-label={ariaLabel}
        loading={loading}
        width="full"
        value={selectedOption}
        options={options}
        clearable={!!selectedOption}
        onChangeValue={handleChangeValue}
        onClearValue={handleClearValue}
        searchPlaceholder={`Search ${label("team", { pluralize: 0 })}...`}
      >
        {({ value, setReferenceElement, clearable }) => (
          <ExplorerComboboxButton
            prefix={prefix}
            clearable={clearable}
            ariaLabel="Explorer team picker button"
            setReferenceElement={setReferenceElement}
            label={value?.label || "All"}
            onClear={handleClearValue}
            className={className}
          />
        )}
      </ComboboxGeneric>
    </div>
  );
};

export default ExplorerTeamCombobox;
