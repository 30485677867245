import getFreshLogo from "@static/img/getfresh-gradient@2x.png";
import raviImg from "@static/img/ravi@2x.png";
import rickImg from "@static/img/rick@2x.png";
import logoDev from "@static/img/topicflow-logo-dev.png";
import logo from "@static/img/topicflow-logo.svg";
import { useRef, useState } from "react";
import { TbCaretDownFilled } from "react-icons/tb";
import { useLocation } from "react-router-dom";

import ButtonSSO from "@components/button-sso/button-sso";
import AppLink from "@components/link/link";
import Loading from "@components/loading/loading";
import Modal from "@components/modal/modal";
import { classNames } from "@helpers/css";
import { getAssetUrl } from "@helpers/helpers";

interface Props {
  isRegisterPage?: boolean;
}

const CreateDemoDialog = ({ onClose }: { onClose: any }) => {
  const focusRef = useRef(null);
  const [showExtraLoader, setShowExtraLoader] = useState(false);
  setTimeout(() => {
    setShowExtraLoader(true);
  }, 2000);
  return (
    <Modal
      onClose={onClose}
      initialFocus={focusRef}
      dialogClassName="md:max-w-md"
    >
      <div
        className="px-4 py-8 md:max-w-lg"
        aria-label="Set agenda as template dialog"
      >
        <div className="mt-4 flex align-middle items-center flex-col text-gray-800 text-center">
          <Loading size="8" mini className="shrink-0 inline-block mb-6" />
          <div className="font-semibold mb-4">
            Building your personal demo account
          </div>
          {showExtraLoader && (
            <div className="text-gray-500 mb-4">Just a few more seconds...</div>
          )}
        </div>
      </div>
    </Modal>
  );
};

const RegisterPageQuote = ({ version }: { version: any }) => {
  const quoteData = {
    default: {
      text: "Topicflow has quickly become our most used meeting management app. It has helped us stay on track with project action items, efficiently find previous notes, and simplify our note-taking process.",
      img: getFreshLogo,
      author: "",
      authorTitle: "",
    },
    hr: {
      text: "The search feature and the depth of integration are the two things that have made Topicflow indispensable.",
      author: "Rick Brooks-Hill",
      img: rickImg,
      authorTitle: "Co-founder @ Goldpan",
    },
    product: {
      text: "Topicflow is a way to capture action items from recurring meetings and hold team members to account for meeting responsibilities.",
      author: "Ravi Patel",
      img: raviImg,
      authorTitle: "Product Manager @ Ananda Cooperative",
    },
  };
  const quote =
    version === "1"
      ? quoteData.hr
      : version === "2"
      ? quoteData.product
      : quoteData.default;

  return (
    <div className="hidden md:flex lg:mt-0 md:max-w-[360px] lg:max-w-[500px] flex-none justify-center items-center flex-col gap-10 gradient-pale py-8 px-12 lg:py-12 lg:px-16 rounded-lg">
      <div className="relative px-3 lg:px-6 py-8 bg-white/40 rounded-lg">
        <div className="absolute -bottom-6 left-1/2 -ml-8 w-18 h-9">
          <TbCaretDownFilled className="w-18 h-9 text-white/40" />
        </div>
        <div className="gradient-basic text-transparent bg-clip-text text-center text-base lg:text-lg quote">
          {quote.text}
        </div>
      </div>
      {quote === quoteData.default ? (
        <div
          className="flex w-full h-[54px] bg-no-repeat bg-center bg-contain"
          style={{
            backgroundImage: `url(${getAssetUrl(quote.img)})`,
          }}
        />
      ) : (
        <div className="flex flex-row gap-6 items-center">
          <div
            className="flex h-16 w-16 bg-no-repeat bg-center bg-contain"
            style={{
              backgroundImage: `url(${getAssetUrl(quote.img)})`,
            }}
          />
          <div className="flex flex-col gap-1 text-sm">
            <div className="font-semibold  text-gray-700">
              <span className="">{quote.author}</span>
            </div>
            <div className="text-gray-500">
              <span className="">{quote.authorTitle}</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const Login: React.FC<Props> = ({ isRegisterPage = false }) => {
  const location = useLocation();
  const params = new URL(document.location.toString()).searchParams;
  const redirectUrl = ["/", "/login", "/logout", "/register"].includes(
    location.pathname
  )
    ? params.get("next") || "/"
    : location.pathname;
  const ssoInviteKeyUrl = params.get("inviteKey")
    ? `&invite_key=${params.get("inviteKey")}`
    : "";
  const audience = params.get("a") || "";
  const [openCreateDemoDialog, setOpenCreateDemoDialog] = useState(false);

  const onClickDemo = () => {
    setOpenCreateDemoDialog(true);
    return true;
  };

  const buttonInviteKeyUrl = params.get("inviteKey")
    ? `&inviteKey=${params.get("inviteKey")}`
    : "";
  const otherPageUrl = `${
    isRegisterPage ? "/login" : "/register"
  }?${buttonInviteKeyUrl}${redirectUrl === "/" ? "" : "&next=" + redirectUrl}`;
  return (
    <>
      {openCreateDemoDialog && (
        <CreateDemoDialog onClose={() => setOpenCreateDemoDialog(false)} />
      )}
      <div
        className={classNames(
          "min-h-full md:min-h-0 lg:mx-auto md:mt-4 lg:mt-12 mb-12 md:rounded-md bg-white p-4 flex flex-col md:flex-row",
          isRegisterPage ? "lg:max-w-[1200px]" : "lg:w-[680px]"
        )}
      >
        <div className="p-4 pr-8 lg:p-16 text-gray-800">
          <div className="mb-10">
            <AppLink
              to="/"
              className="font-light text-2xl flex items-center"
              id="sidebar-logo"
            >
              {import.meta.env.DEV ? (
                <img
                  src={getAssetUrl(logoDev)}
                  alt="Topicflow dev"
                  className="block h-8"
                />
              ) : (
                <img
                  src={getAssetUrl(logo)}
                  alt="Topicflow"
                  className="block h-8"
                />
              )}
            </AppLink>
          </div>
          <div>
            <h1 className="text-2xl font-semibold">
              {isRegisterPage
                ? "Sign up by connecting your calendar"
                : "Log in"}
            </h1>
            {isRegisterPage && (
              <>
                <div className="mt-6">
                  Topicflow connects your calendar events with a meeting space
                  where your team can take notes, capture decisions, plan action
                  items, and set goals in real time. And it's free!
                </div>
              </>
            )}
            <div className="my-8 flex flex-row items-center gap-4 flex-wrap">
              <ButtonSSO
                sso="google"
                url={`/sso/google?${ssoInviteKeyUrl}&next=${redirectUrl}&request_calendar_scope=true&request_write_scope=true`}
                text={`${isRegisterPage ? "Sign up" : "Log in"} with Google`}
              />
              <ButtonSSO
                sso="microsoft"
                url={`/sso/microsoft?${ssoInviteKeyUrl}&next=${redirectUrl}&request_calendar_scope=true&request_write_scope=true`}
                text={`${isRegisterPage ? "Sign up" : "Log in"} with Microsoft`}
              />
            </div>
            {isRegisterPage && (
              <>
                <div className="mt-6 text-sm text-gray-500">
                  * You will be asked to allow calendar access during signup.
                </div>

                <div className="mt-6 text-gray-500">
                  Not sure yet?
                  <a
                    href={`/create-demo-account${
                      redirectUrl === "/" ? "" : "?next=" + redirectUrl
                    }`}
                    className="ml-2 font-semibold gradient-basic text-transparent bg-clip-text"
                    onClick={onClickDemo}
                  >
                    Try a live demo account
                  </a>
                </div>
              </>
            )}
            {isRegisterPage && (
              <div className="mt-6 text-gray-500">
                <AppLink to={otherPageUrl}>
                  {isRegisterPage
                    ? "Already have a Topicflow account?"
                    : "Don't have a Topicflow account?"}{" "}
                </AppLink>
                <AppLink
                  to={otherPageUrl}
                  className="text-blue-600 font-semibold hover:underline"
                >
                  {isRegisterPage ? "Log in" : "Sign up"}
                </AppLink>
              </div>
            )}
            <div className="mt-14 text-gray-500 text-sm">
              By continuing, I agree to the{" "}
              <a
                href="https://www.topicflow.com/privacy-policy/"
                target="_blank"
                rel="noreferrer"
                className="hover:underline font-medium"
              >
                Privacy Policy
              </a>{" "}
              and{" "}
              <a
                href="https://www.topicflow.com/terms-of-service-saas/"
                target="_blank"
                rel="noreferrer"
                className="hover:underline font-medium"
              >
                Terms of Service
              </a>
              .
            </div>
          </div>
        </div>
        {isRegisterPage && <RegisterPageQuote version={audience} />}
      </div>
    </>
  );
};
export default Login;
