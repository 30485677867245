const CalendarAssignedAutomatically = ({
  email,
  organizationName,
}: {
  email: string;
  organizationName: string;
}) => {
  return (
    <div
      className="mt-4 text-emerald-700 text-base"
      aria-label="Calendar connection container"
    >
      Your calendar <span className="font-semibold">{email}</span> was assigned
      to {organizationName} automatically. Events you own will be shared with
      other members.
    </div>
  );
};

export default CalendarAssignedAutomatically;
