import { useQuery } from "@apollo/client";
import { useState } from "react";
import {
  GetOrganizationTeamsQueryQuery,
  GetOrganizationTeamsQueryQueryVariables,
  OrgSettingsOrganizationFragmentFragment,
} from "types/graphql-schema";

import TeamEditDialog from "@apps/org-settings/components/team-edit-dialog";
import useLabel from "@apps/use-label/use-label";
import Button from "@components/button/button";
import Heading from "@components/heading/heading";
import Loading from "@components/loading/loading";
import Placeholder from "@components/placeholder/placeholder";
import {
  Table,
  TableBody,
  TableHeadCell,
  TableHeadRow,
} from "@components/table/table";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { assertEdgesNonNull } from "@helpers/helpers";

import getOrganizationTeamsQuery from "../graphql/get-organization-teams-query";
import TeamItem from "./team-item";

const Teams = ({
  organization,
}: {
  organization: OrgSettingsOrganizationFragmentFragment;
}) => {
  const label = useLabel();
  const { data, loading, fetchMore } = useQuery<
    GetOrganizationTeamsQueryQuery,
    GetOrganizationTeamsQueryQueryVariables
  >(getOrganizationTeamsQuery, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    variables: {
      after: null,
      organizationId: organization.id,
    },
    onError: onNotificationErrorHandler(),
  });

  const teams = data?.organization?.teams
    ? assertEdgesNonNull(data.organization.teams)
    : [];

  const handleClickMore = () => {
    const after = data?.organization?.teams?.pageInfo.endCursor;
    if (after) {
      fetchMore({
        variables: { after, merge: true },
      });
    }
  };

  const totalCount = data?.organization?.teams?.totalCount || 0;
  const [showCreateDialog, setShowCreateDialog] = useState(false);

  return (
    <div className="pb-12" aria-label="Org settings teams">
      {showCreateDialog && (
        <TeamEditDialog
          organization={organization}
          onClose={() => {
            setShowCreateDialog(false);
          }}
        />
      )}
      <div className="flex justify-between items-center">
        <Heading
          small
          title={`${label("team", { pluralize: true, capitalize: true })}${
            totalCount ? ` (${totalCount})` : ""
          }`}
        />
        <div>
          <Button
            small
            theme="primary"
            text={`New ${label("team")}`}
            onClick={() => {
              setShowCreateDialog(true);
            }}
          />
        </div>
      </div>

      {loading && !data && (
        <div className="flex justify-center mt-8">
          <Loading mini size="5" />
        </div>
      )}
      {teams.length > 0 && (
        <Table className="mt-6 w-full">
          <TableHeadRow>
            <TableHeadCell>Name</TableHeadCell>
            <TableHeadCell>Members</TableHeadCell>
            <TableHeadCell></TableHeadCell>
          </TableHeadRow>
          <TableBody>
            {teams.map((team) => (
              <TeamItem team={team} organization={organization} key={team.id} />
            ))}
          </TableBody>
        </Table>
      )}
      {teams.length === 0 && !loading && (
        <Placeholder className="mt-6">
          No {label("team", { pluralize: 0 })}.
        </Placeholder>
      )}
      {data?.organization?.teams?.pageInfo.hasNextPage && (
        <div className="flex items-center border-t py-2">
          <button
            className="text-gray-500 text-sm mr-4 hover:underline"
            onClick={handleClickMore}
            disabled={loading}
          >
            Load more {label("team", { pluralize: true })}...
          </button>
          {loading && <Loading size="5" mini />}
        </div>
      )}
    </div>
  );
};

export default Teams;
