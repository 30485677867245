import { useQuery } from "@apollo/client";
import { XIcon } from "@heroicons/react/outline";
import githubLogo from "@static/img/github.png";
import { Node, mergeAttributes } from "@tiptap/core";
import { NodeViewWrapper, ReactNodeViewRenderer } from "@tiptap/react";
import moment from "moment";

import AppLink from "@components/link/link";
import Loading from "@components/loading/loading";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { regexp } from "@helpers/constants";
import { classNames } from "@helpers/css";
import { getAssetUrl } from "@helpers/helpers";

import getGithubIssueQuery from "../graphql/get-url-preview";

const GithubIssueComponent = ({
  node,
  selected,
  deleteNode,
}: {
  node: any;
  selected: boolean;
  deleteNode: () => void;
}) => {
  const url = node.attrs.url;
  const isGithub = regexp.githubIssueUrl.exec(url);

  const {
    data,
    error,
    loading: loadingQuery,
  } = useQuery(getGithubIssueQuery, {
    variables: { url },
    skip: !url,
    onError: onNotificationErrorHandler(),
  });
  const issue = data?.urlToInfoCard;

  const renderIssue = (issue: any) => {
    return (
      <div className="flex-1">
        <div className="flex justify-between gap-3">
          <div className="flex-1">
            <a href={url} className="font-medium hover:underline">
              {issue.title}
            </a>
          </div>
        </div>
        <div className="tracking-tight text-2xs text-gray-500">
          <a
            href={url}
            className="hover:underline font-mono"
            target="_blank"
            rel="noreferrer"
          >
            #{issue.id}
          </a>
          {issue.assignees.length > 0 ? (
            <span>
              {" "}
              -{" "}
              <a
                href={`https://github.com/${issue.assignees}`}
                className="hover:underline"
                target="_blank"
                rel="noreferrer"
              >
                {issue.assignees}
              </a>
            </span>
          ) : (
            " - unassigned"
          )}
          {issue.closedAt
            ? ` - Closed on ${moment(issue.closedAt).format("LLL")}`
            : ""}
        </div>
      </div>
    );
  };

  return (
    <NodeViewWrapper className="w-full mb-3 not-prose">
      <div
        className={classNames(
          "flex items-start text-sm relative border rounded-lg py-1.5 px-3 no-underline bg-gray-50 gap-2",
          selected && "ring-2 ring-blue-200 ring-offset-2 extension-is-selected"
        )}
        contentEditable={false}
      >
        <div className="pt-0.5">
          {isGithub && (
            <img
              src={getAssetUrl(githubLogo)}
              alt="GitHub"
              className="block h-4 w-4"
            />
          )}
        </div>
        {issue ? (
          renderIssue(issue)
        ) : (
          <div className="flex-1 flex gap-2 items-center truncate min-w-0 tracking-tight">
            <div>
              <a href={url} className="hover:underline font-medium">
                {url}
              </a>
              {error &&
                isGithub &&
                String(error).toLowerCase().includes("github not setup") && (
                  <AppLink
                    to="/account/integrations"
                    className="ml-2 text-blue-link hover:underline text-xs"
                  >
                    Setup Github preview
                  </AppLink>
                )}
            </div>
            {loadingQuery && <Loading mini size="4" />}
          </div>
        )}
        <button
          className="shrink-0 p-px rounded text-gray-400 hover:text-gray-700"
          onClick={deleteNode}
        >
          <XIcon className="h-5 w-5" />
        </button>
      </div>
    </NodeViewWrapper>
  );
};

const DeprecatedGithubIssue = Node.create({
  name: "githubIssue",
  group: "block",
  marks: "",
  draggable: true,
  selectable: true,
  allowGapCursor: true,
  atom: true,
  isolating: true,
  defining: true,

  addAttributes() {
    return {
      url: {
        default: null,
      },
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(GithubIssueComponent);
  },

  renderHTML({ HTMLAttributes }) {
    return ["div", mergeAttributes(HTMLAttributes)];
  },
});

export default DeprecatedGithubIssue;
