import { gql } from "@apollo/client";

export default gql`
  query getHubspotStagesQuery {
    hubspotPipelineStages {
      edges {
        node {
          id
          stageName
          pipelineName
        }
      }
    }
  }
`;
