import { useMutation } from "@apollo/client";
import { useState } from "react";
import {
  AddTemplateToMeetingMutationMutation,
  AddTemplateToMeetingMutationMutationVariables,
  MeetingViewMeetingNodeNewPageFragmentFragment,
  SuggestedTemplateTopicsFragmentFragment,
  SuggestedTopicTemplateFragmentFragment,
} from "types/graphql-schema";

import { MeetingOrTemplateVisibility } from "@apps/meeting-new/helpers";
import TemplateNoteAccessModal from "@apps/templates/components/note-access-modal";
import addTemplateToMeetingMutation from "@apps/templates/graphql/add-template-to-meeting-mutation";
import { onNotificationErrorHandler } from "@components/use-error/use-error";

import SuggestedTopicGroup from "./suggested-topic-group";

const SuggestedTopicsSidebarTemplate = ({
  template,
  meeting,
  topics,
}: {
  topics: SuggestedTemplateTopicsFragmentFragment[];
  template: SuggestedTopicTemplateFragmentFragment;
  meeting: MeetingViewMeetingNodeNewPageFragmentFragment;
}) => {
  const [showChangeAccessModal, setShowChangeAccessModal] = useState(false);
  const [addTemplateToMeeting, { loading: loadingAddTemplateToMeeting }] =
    useMutation<
      AddTemplateToMeetingMutationMutation,
      AddTemplateToMeetingMutationMutationVariables
    >(addTemplateToMeetingMutation);

  const handleAddTemplateToMeeting = ({
    allowAdminVisibility,
    allowOrgVisibility,
    allowManagementTreeVisibility,
  }: MeetingOrTemplateVisibility) => {
    addTemplateToMeeting({
      variables: {
        meetingId: meeting.id,
        topicTemplateId: template.id,
        setAsSeriesTemplate: true,
        allowAdminVisibility,
        allowOrgVisibility,
        allowManagementTreeVisibility,
      },
      onError: onNotificationErrorHandler(),
    });
  };

  const handleClickApplyTemplate = () => {
    if (meeting.meetingGroup) {
      const differentVisibility =
        template.allowAdminVisibility !==
          meeting.meetingGroup.allowAdminVisibility ||
        template.allowManagementTreeVisibility !==
          meeting.meetingGroup.allowManagementTreeVisibility ||
        template.allowOrgVisibility !== meeting.meetingGroup.allowOrgVisibility;
      if (differentVisibility) {
        setShowChangeAccessModal(true);
      } else {
        handleAddTemplateToMeeting({
          allowAdminVisibility: meeting.meetingGroup.allowAdminVisibility,
          allowOrgVisibility: meeting.meetingGroup.allowOrgVisibility,
          allowManagementTreeVisibility:
            meeting.meetingGroup.allowManagementTreeVisibility,
        });
      }
    }
  };

  const handleConfirmChangeAccess = ({
    allowAdminVisibility,
    allowOrgVisibility,
    allowManagementTreeVisibility,
  }: MeetingOrTemplateVisibility) => {
    setShowChangeAccessModal(false);
    handleAddTemplateToMeeting({
      allowAdminVisibility,
      allowOrgVisibility,
      allowManagementTreeVisibility,
    });
  };

  return (
    <>
      {showChangeAccessModal && meeting.meetingGroup && (
        <TemplateNoteAccessModal
          meetingGroup={meeting?.meetingGroup}
          isRecurring={meeting.isRecurring}
          template={template}
          onCancel={() => setShowChangeAccessModal(false)}
          onConfirm={handleConfirmChangeAccess}
        />
      )}
      <SuggestedTopicGroup
        meeting={meeting}
        title={template.title}
        topics={topics}
        groupActionText="Apply template"
        groupActionLoading={loadingAddTemplateToMeeting}
        onClickGroupAction={handleClickApplyTemplate}
        key={template.id}
      />
    </>
  );
};

export default SuggestedTopicsSidebarTemplate;
