import * as Sentry from "@sentry/react";
import { compact } from "lodash";
import {
  ArtifactType,
  MeetingViewMeetingNodeNewPageFragmentFragment,
} from "types/graphql-schema";

import useLabel from "@apps/use-label/use-label";
import useUiPreferenceCache from "@apps/use-ui-preference-cache/use-ui-preference-cache";
import Error from "@components/error/error";

import MeetingFeedbackCollapsible from "./feedback-collapsible";
import MeetingSidebarSection from "./meeting-sidebar-collapsible-container";
import useMeetingSidebarSection from "./use-meeting-sidebar-collapsible-container";

const Feedbacks = ({
  meeting,
}: {
  meeting: MeetingViewMeetingNodeNewPageFragmentFragment;
}) => {
  const label = useLabel();
  const { isFormalOneonone, subject, participantIds } =
    useMeetingSidebarSection({
      meeting,
      collapsibleFilterName: "Feedback",
    });

  const { uiPreferenceCache } = useUiPreferenceCache();

  const commonFilters = {
    first: 5,
  };

  if (!subject || !isFormalOneonone) return null;

  return (
    <MeetingSidebarSection
      collapsibleFilterName="Feedback"
      expandedUiPreferenceKey="meetingSidebarFeedbackContainerExpanded"
      subject={subject}
      isFormalOneonone={isFormalOneonone}
      toggledAssignee={false}
      toggledJustMeeting={false}
      createArtifactFormOptions={{
        artifactType: ArtifactType.Feedback,
        meetingId: meeting.id,
        meetingGroupId: meeting.meetingGroup?.id,
      }}
      titleLabel="feedback"
    >
      <MeetingFeedbackCollapsible
        expandedUiPreferenceKey="meetingReceivedFeedbackExpanded"
        title={`${label("feedback")} received by ${subject.firstName}`}
        variables={{
          fetchData: true,
          ...commonFilters,
          feedbackRecipients: compact([subject.id]),
          feedbackSenders: uiPreferenceCache.oneononeFeedbackOnlyParticipants
            ? participantIds
            : undefined,
        }}
      />
      <MeetingFeedbackCollapsible
        expandedUiPreferenceKey="meetingGivenFeedbackExpanded"
        title={`${label("feedback")} given by ${
          subject.firstName || "subject"
        }`}
        variables={{
          ...commonFilters,
          fetchData: true,
          feedbackSenders: compact([subject.id]),
          feedbackRecipients: uiPreferenceCache.oneononeFeedbackOnlyParticipants
            ? participantIds
            : undefined,
        }}
      />
    </MeetingSidebarSection>
  );
};

export default Sentry.withErrorBoundary(Feedbacks, {
  fallback: <Error description={"The feedback could not be rendered."} />,
});
