import { gql } from "@apollo/client";

import CommonGoalArtifactFragment from "@graphql/common-goal-artifact-fragment";

export const DashboardGoalFragment = gql`
  ${CommonGoalArtifactFragment}
  fragment DashboardGoalFragment on ArtifactInterface {
    id
    title
    artifactType
    created
    isStale
    canDelete {
      permission
    }
    canUpdate {
      permission
    }
    organization {
      id
    }
    ... on GoalArtifactNode {
      activities(first: 1, activityType: goal_checkin) {
        edges {
          node {
            created
          }
        }
      }
      ...CommonGoalArtifactFragment
    }
  }
`;
