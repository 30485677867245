import { useLocation } from "react-router-dom";
import {
  ArtifactActivityFragmentFragment,
  BaseArtifactNode,
} from "types/graphql-schema";
import { TFLocationState } from "types/topicflow";

import AppLink from "@components/link/link";
import { getUrl, toWithBackground } from "@helpers/helpers";

const ActivityArtifactReferencedFromArtifact = ({
  activity,
}: {
  activity: ArtifactActivityFragmentFragment;
}) => {
  const location = useLocation<TFLocationState>();
  if (!activity?.changedFields?.[0]?.newValueObject) {
    return null;
  }
  const otherArtifact = activity.changedFields[0]
    .newValueObject as BaseArtifactNode;
  return (
    <AppLink
      to={toWithBackground({
        pathname: getUrl({
          artifactId: otherArtifact.id,
          artifactType: otherArtifact.artifactType,
        }),
        location,
      })}
    >
      {otherArtifact.title}
    </AppLink>
  );
};

export default ActivityArtifactReferencedFromArtifact;
