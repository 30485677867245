import { gql } from "@apollo/client";

export default gql`
  mutation saveAssessmentNominees(
    $complianceProgramId: Int!
    $assessmentGroupId: Int!
    $responderIds: [Int]!
    $targetId: Int!
  ) {
    setAssessmentNominations(
      complianceProgramId: $complianceProgramId
      assessmentGroupId: $assessmentGroupId
      responderIds: $responderIds
      targetId: $targetId
    ) {
      complianceProgram {
        id
        nominationsForUser(userId: $targetId) {
          edges {
            node {
              id
              responder {
                id
                name
                avatar
              }
            }
          }
        }
      }
      assessmentOpenForNomination {
        id
        nominations {
          totalCount
        }
      }
    }
  }
`;
