import numeral from "numeral";
import { GoalState, GoalStatus } from "types/graphql-schema";

import useLabel from "@apps/use-label/use-label";
import GoalStatusPill from "@components/goal-status-pill/goal-status-pill";
import Tooltip from "@components/tooltip/tooltip";
import { classNames } from "@helpers/css";

export type GoalProgressPillProps = {
  progress: number;
  status: GoalStatus;
  state: GoalState;
  isStale: boolean;
  className?: string;
  hideTooltip?: boolean;
};

const GoalProgressPill = ({
  status,
  state,
  isStale,
  progress,
  className = "",
  hideTooltip = false,
}: GoalProgressPillProps) => {
  const isClosed = state === GoalState.Closed;
  const label = useLabel();

  const staleText = isStale
    ? " has not been updated for more than 2 weeks and"
    : "";
  let tooltipText = `This ${label("goal")}${staleText} has no status.`;
  if (isClosed) {
    tooltipText = `${label("goal", { capitalize: true })} is closed.`;
  }
  if (status === GoalStatus.OnTrack) {
    tooltipText = `${label("goal")}${staleText} is on track.`;
  }
  if (status === GoalStatus.OffTrack) {
    tooltipText = `${label("goal")}${staleText} is off track.`;
  }
  if (status === GoalStatus.AtRisk) {
    tooltipText = `${label("goal")}${staleText} is at risk.`;
  }

  return (
    <Tooltip text={hideTooltip ? null : tooltipText}>
      <div
        className={classNames(
          "flex justify-center min-w-14 items-center gap-1 px-2 text-sm rounded-full",
          isClosed && "bg-gray-500 text-gray-200",
          !isClosed &&
            status === GoalStatus.OnTrack &&
            "bg-emerald-200 text-emerald-800",
          !isClosed &&
            status === GoalStatus.OffTrack &&
            "bg-rose-200 text-rose-800",
          !isClosed &&
            status === GoalStatus.AtRisk &&
            "bg-amber-200 text-amber-800",
          !isClosed &&
            status === GoalStatus.None &&
            "bg-gray-200 text-gray-800",
          className
        )}
      >
        <GoalStatusPill status={status} state={state} isStale={isStale} />
        {numeral(progress).format("0,0[.]0[0]")}%
      </div>
    </Tooltip>
  );
};

export default GoalProgressPill;
