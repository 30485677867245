import { gql } from "@apollo/client";

export default gql`
  mutation prepareFileUploadMutation(
    $filename: String!
    $contentType: String!
    $topicId: Int
    $artifactId: Int
    $recognitionCoreValueId: Int
  ) {
    prepareFileUpload(
      filename: $filename
      topicId: $topicId
      artifactId: $artifactId
      recognitionCoreValueId: $recognitionCoreValueId
      contentType: $contentType
    ) {
      fileUploadUrl
      fileViewingUrl
    }
  }
`;
