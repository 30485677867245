import { useQuery } from "@apollo/client";
import { Listbox } from "@headlessui/react";
import { SelectorIcon, XIcon } from "@heroicons/react/outline";
import { withErrorBoundary } from "@sentry/react";
import { Editor } from "@tiptap/react";
import { sortBy } from "lodash";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import {
  GetEmbedKpiGroupQueryQuery,
  GetEmbedKpiGroupQueryQueryVariables,
  KpiSummarizedMeasurementFragmentFragment,
  KpiSummaryPeriod,
} from "types/graphql-schema";

import { KPISummaryTableCell } from "@apps/kpis/components/summary-table-row";
import { kpiSummaryPeriodOptions } from "@apps/kpis/helpers";
import ErrorContainer from "@components/error/error";
import AppLink from "@components/link/link";
import Loading from "@components/loading/loading";
import Select from "@components/select/select";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { batchClient } from "@graphql/client";
import {
  classNames,
  inputBorderClassName,
  inputFocusClassName,
} from "@helpers/css";
import { assertEdgesNonNull } from "@helpers/helpers";

import getEmbedKpiGroupQuery from "../graphql/get-embed-kpi-group-query";
import KpiView from "./kpi-view";
import { viewOptions } from "./kpi-view";

const KPIGroupViewMeasurementCell = ({
  kpi,
  limit,
  onAddValue,
}: {
  kpi: any;
  limit: number;
  onAddValue: () => void;
}) => {
  const measurements = sortBy(
    kpi.summarizedMeasurements.edges,
    ({ node }) => -moment(node.date).unix()
  )
    .slice(0, limit)
    .reverse();
  return measurements.map(({ node }, i) => (
    <td
      className={classNames("!border !p-0 !border-b-0 !border-r-0")}
      key={node.date}
    >
      <KPISummaryTableCell
        key={i}
        kpi={kpi}
        isLastCell={i === measurements.length - 1}
        measurement={node}
        previousMeasurement={measurements[i - 1]?.node}
        className={classNames(
          "px-2 h-14 py-2 text-gray-500 text-center text-sm"
        )}
        onAddValue={onAddValue}
      />
    </td>
  ));
};

const KpiGroupView = ({
  node,
  deleteNode,
  selected,
  updateAttributes,
  editor,
}: {
  node: any;
  deleteNode: () => void;
  selected: boolean;
  updateAttributes: (attributes: any) => void;
  editor: Editor;
}) => {
  const [summaryPeriod, setSummaryPeriod] = useState(
    node.attrs.summaryPeriod || KpiSummaryPeriod.Monthly
  );
  const [viewMode, setViewMode] = useState(node.attrs.viewMode || "table");
  const scrollContainerRef = useRef(null);
  const measurementCellClassName = "text-center px-2 w-36 flex-none";
  const measurementHeaderCellClassName = classNames(measurementCellClassName);

  useEffect(() => {
    if (summaryPeriod) {
      try {
        updateAttributes({ ...node.attrs, summaryPeriod: summaryPeriod });
      } catch {}
    }
  }, [summaryPeriod]);

  useEffect(() => {
    if (viewMode) {
      try {
        updateAttributes({ ...node.attrs, viewMode: viewMode });
      } catch {}
    }
  }, [viewMode]);

  useEffect(() => {
    if (
      node.attrs.summaryPeriod !== summaryPeriod &&
      node.attrs.summaryPeriod
    ) {
      setSummaryPeriod(node.attrs.summaryPeriod);
    }
    if (node.attrs.viewMode !== viewMode && node.attrs.viewMode) {
      setViewMode(node.attrs.viewMode);
    }
  }, [node]);

  const limit = 3;
  const id = node.attrs.id ? String(node.attrs.id) : "";
  const hasTeamId = id.includes("team-");
  const cleanedId = Number(id.replace("team-", ""));
  const { data, loading, refetch } = useQuery<
    GetEmbedKpiGroupQueryQuery,
    GetEmbedKpiGroupQueryQueryVariables
  >(getEmbedKpiGroupQuery, {
    variables: {
      kpiGroupId: cleanedId,
      teamId: cleanedId || -1,
      hasTeamId: hasTeamId,
      summaryPeriod: summaryPeriod,
      first: limit + 3,
    },
    client: batchClient,
    onError: onNotificationErrorHandler(),
  });
  const kpiGroup = hasTeamId ? data?.team : data?.kpiGroup;
  const kpis = kpiGroup?.kpis ? assertEdgesNonNull(kpiGroup.kpis) : [];
  const firstMeasurements = (
    kpis?.[0]
      ? kpis[0].summarizedMeasurements.edges.map((edge) => edge!.node)
      : []
  ) as KpiSummarizedMeasurementFragmentFragment[];

  const handleChangeSummaryPeriod = ({ value }: { value: KpiSummaryPeriod }) =>
    setSummaryPeriod(value);
  const handleChangeView = ({ value }: { value: string }) => setViewMode(value);

  const firstSortedMeasurements = sortBy(firstMeasurements, (node) =>
    moment(node.date).unix()
  );

  return (
    <div
      className={classNames(
        "flex flex-col gap-2 border rounded-lg bg-gray-50 not-prose",
        selected && "ring-2 ring-blue-200 ring-offset-2"
      )}
      contentEditable={false}
      aria-label="Artifact kpi view"
    >
      {loading ? (
        <div className="p-2">
          <Loading mini size="5" />
        </div>
      ) : kpiGroup ? (
        <div>
          <div className="flex justify-between mb-1 px-2 pt-1">
            <AppLink
              to={`/kpis/?kpiGroup=${node.attrs.id}`}
              className="font-medium text-sm hover:underline ml-1"
            >
              {kpiGroup?.title}
            </AppLink>
            {editor.options.editable && (
              <button
                onClick={deleteNode}
                className="text-gray-600 p-0.5 rounded hover:bg-gray-200 hover:text-gray-800"
              >
                <XIcon className="h-5 w-5" />
              </button>
            )}
          </div>

          <div className={"flex gap-2 mb-2 px-2"}>
            {summaryPeriod && (
              <Select
                onChange={handleChangeSummaryPeriod}
                value={summaryPeriod}
                options={kpiSummaryPeriodOptions}
              >
                {({ selected, setReferenceElement, disabled }) => (
                  <Listbox.Button
                    className={classNames(
                      "bg-white relative w-full pl-1.5 pr-6 py-1 text-left cursor-default text-gray-800 text-xs",
                      inputBorderClassName,
                      inputFocusClassName
                    )}
                    ref={setReferenceElement}
                  >
                    <span className="block truncate">{selected?.label}</span>
                    {!disabled && (
                      <span className="absolute inset-y-0 right-0 flex items-center pr-1.5 pointer-events-none">
                        <SelectorIcon className="h-4 w-4 text-gray-400" />
                      </span>
                    )}
                  </Listbox.Button>
                )}
              </Select>
            )}
            {viewMode && (
              <Select
                onChange={handleChangeView}
                value={viewMode}
                options={viewOptions}
              >
                {({ selected, setReferenceElement, disabled }) => (
                  <Listbox.Button
                    className={classNames(
                      "bg-white relative w-full pl-1.5 pr-6 py-1 text-left cursor-default text-gray-800 text-xs",
                      inputBorderClassName,
                      inputFocusClassName
                    )}
                    ref={setReferenceElement}
                  >
                    <span className="block truncate">{selected?.label}</span>
                    {!disabled && (
                      <span className="absolute inset-y-0 right-0 flex items-center pr-1.5 pointer-events-none">
                        <SelectorIcon className="h-4 w-4 text-gray-400" />
                      </span>
                    )}
                  </Listbox.Button>
                )}
              </Select>
            )}
          </div>

          <div className="flex flex-col gap-2">
            {viewMode === "table" ? (
              <div
                className="overflow-x-auto overscroll-none"
                ref={scrollContainerRef}
              >
                <table className="rounded-b-lg w-full">
                  <thead>
                    <tr>
                      <th
                        className={classNames(
                          "!border !border-gray-300 !border-r-0 !border-l-0 text-xs font-medium p-2 bg-gray-50 text-gray-600 tracking-tight !text-center",
                          measurementHeaderCellClassName
                        )}
                      ></th>
                      {sortBy(
                        firstSortedMeasurements,
                        (node) => -moment(node.date).unix()
                      )
                        .slice(0, limit)
                        .reverse()
                        .map((node) => (
                          <th
                            key={node.date}
                            className={classNames(
                              "!border !border-gray-300 !border-r-0 text-xs font-medium p-2 bg-gray-50 text-gray-600 tracking-tight !text-center",
                              measurementHeaderCellClassName
                            )}
                          >
                            {moment(node.date).format("ll")}
                          </th>
                        ))}
                    </tr>
                  </thead>
                  <tbody>
                    {kpis.map((kpi) => (
                      <tr key={kpi.id}>
                        <td
                          className={classNames(
                            "!border !p-0 !border-b-0 !border-r-0 !border-l-0 text-sm"
                          )}
                        >
                          <AppLink
                            to={`/kpis/${kpi.id}`}
                            className="font-medium text-blue-link hover:underline w-full line-clamp-2 tracking-tight ml-1"
                          >
                            {kpi.title}
                          </AppLink>
                        </td>
                        <KPIGroupViewMeasurementCell
                          kpi={kpi}
                          limit={limit}
                          onAddValue={refetch}
                        />
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="flex flex-col gap-2 p-2">
                {kpis.map((node) => (
                  <KpiView
                    isEmbeddedInKpiGroup
                    key={node.id}
                    kpi={node}
                    selected={selected}
                    viewMode={viewMode}
                    setViewMode={setViewMode}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default withErrorBoundary(KpiGroupView, {
  fallback: (
    <ErrorContainer description={"This KPI embed could not be displayed."} />
  ),
});
