import { gql } from "@apollo/client";

import WYSIWYGArtifactFragment from "./artifact-fragment";

const updateArtifactMutation = gql`
  ${WYSIWYGArtifactFragment}
  mutation updateArtifactMutation(
    $additionalFields: AdditionalArtifactInput
    $artifactId: Int
    $artifactType: ArtifactType
    $meetingId: Int
    $title: String
    $relatedTopicId: Int
    $organizationId: Int
    $relatedArtifactId: Int
    $uuid: String
  ) {
    createOrUpdateArtifact(
      title: $title
      meetingId: $meetingId
      artifactId: $artifactId
      uuid: $uuid
      artifactType: $artifactType
      topicId: $relatedTopicId
      organizationId: $organizationId
      relatedArtifactId: $relatedArtifactId
      additionalFields: $additionalFields
    ) {
      artifact {
        ...WYSIWYGArtifactFragment
      }
    }
  }
`;

export default updateArtifactMutation;
