import { gql } from "@apollo/client";

export default gql`
  query getCompliancePrograms(
    $applicableUser: Int
    $organizationId: Int!
    $state: ComplianceProgramState
    $assessmentType: AssessmentType
    $search: String
    $after: String
    $dueDateGte: Date
    $dueDateLte: Date
  ) {
    compliancePrograms(
      applicableUser: $applicableUser
      organizationId: $organizationId
      state: $state
      search: $search
      assessmentType: $assessmentType
      orderBy: "state,-due_date"
      first: 10
      after: $after
      dueDate_Gte: $dueDateGte
      dueDate_Lte: $dueDateLte
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          title
          created
          updated
          dueDate
          startDate
          periodStartDate
          periodEndDate
          state
          recurrence
          requiredTopicTemplates {
            edges {
              node {
                id
                title
              }
            }
          }
          canUpdate {
            permission
          }
          performanceAssessmentGroup {
            id
            title
          }
          managerAssessmentGroup {
            id
            title
          }
          peerAssessmentGroup {
            id
            title
          }
        }
      }
    }
  }
`;
