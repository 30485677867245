import {
  GoalAlignmentDialogGoalFragmentFragment,
  GoalScope,
} from "types/graphql-schema";

import { classNames } from "@helpers/css";

import { getGoalAlignmentDisabledText } from "../helpers";
import AlignmentPopoverGoalItem from "./alignment-goal-item";

const GoalAlignmentpopoverSearchResult = ({
  goal,
  goalToAlignTo,
  onSelectGoal,
  allowedGoalScopes,
  alignmentType,
}: {
  allowedGoalScopes: GoalScope[];
  goalToAlignTo: GoalAlignmentDialogGoalFragmentFragment;
  goal: GoalAlignmentDialogGoalFragmentFragment;
  alignmentType: "child" | "parent";
  onSelectGoal: (selectedGoal: GoalAlignmentDialogGoalFragmentFragment) => void;
}) => {
  if (
    goal.__typename !== "GoalArtifactNode" ||
    goalToAlignTo.__typename !== "GoalArtifactNode"
  )
    return null;

  const handleSelectGoal = () => {
    onSelectGoal(goal);
  };

  const disabledText = getGoalAlignmentDisabledText(
    goalToAlignTo,
    goal,
    allowedGoalScopes,
    alignmentType
  );
  return (
    <button
      key={goal.id}
      onClick={handleSelectGoal}
      className={classNames(
        "block w-full text-left px-6 py-0.5",
        disabledText ? "cursor-default" : "cursor-pointer hover:bg-gray-50"
      )}
      disabled={!!disabledText}
    >
      <AlignmentPopoverGoalItem disabledText={disabledText} goal={goal} />
    </button>
  );
};

export default GoalAlignmentpopoverSearchResult;
