import { gql } from "@apollo/client";

export default gql`
  fragment TopicTemplateCategoryFragment on TemplateCategoryNode {
    id
    title
    ordinal
    organization {
      id
    }
    canUpdate {
      permission
    }
    canDelete {
      permission
    }
  }
`;
