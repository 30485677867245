import { gql } from "@apollo/client";

export default gql`
  query getAssessmentsToDeliver(
    $organizationId: Int!
    $currentUserId: Int!
    $after: String
    $orderBy: String!
  ) {
    assessmentDeliveries(
      state: draft
      creatorId: $currentUserId
      organizationId: $organizationId
      first: 10
      after: $after
      orderBy: $orderBy
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          state
          target {
            id
            name
            email
            firstName
            avatar
          }
          complianceProgram {
            id
            title
            dueDate
          }
          group {
            id
            title
            assessmentType
          }
        }
      }
    }
  }
`;
