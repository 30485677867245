import { useQuery } from "@apollo/client";
import { compact, sortBy } from "lodash";
import moment from "moment";
import {
  GetRelatedTopicArtifactsQuery,
  GetRelatedTopicArtifactsQueryVariables,
} from "types/graphql-schema";

import Artifact from "@apps/artifact/artifact";
import RelatedTopicComments from "@apps/comments/related-topic-comments";
import AppLink from "@components/link/link";
import Loading from "@components/loading/loading";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { dateFormat } from "@helpers/constants";
import { classNames } from "@helpers/css";
import { getArtifactBgColor } from "@helpers/helpers";

import getRelatedTopicArtifactsQuery from "../graphql/get-related-topic-artifacts-query";

const AllTime = ({
  topic,
  meetingGroup,
}: {
  topic: any;
  meetingGroup: any;
}) => {
  const { data, loading, fetchMore } = useQuery<
    GetRelatedTopicArtifactsQuery,
    GetRelatedTopicArtifactsQueryVariables
  >(getRelatedTopicArtifactsQuery, {
    variables: {
      topicId: topic.id,
      meetingGroupId: meetingGroup.id,
    },
    onError: onNotificationErrorHandler(),
    notifyOnNetworkStatusChange: true,
  });

  const handleClickLoadMore = () => {
    fetchMore({
      variables: {
        after: data?.topic?.relatedTopics?.pageInfo.endCursor,
        merge: true,
      },
    });
  };

  const currentTopicArtifacts = data?.topic?.artifacts?.edges.length
    ? {
        meeting: data.topic.meeting,
        artifacts: data.topic.artifacts.edges.map(
          (artifactEdge: any) => artifactEdge.node
        ),
      }
    : null;

  const artifactsGroupedByTopicArray = (
    data?.topic?.relatedTopics?.edges.map((edge: any) => ({
      meeting: edge.node.meeting,
      artifacts: edge.node.artifacts.edges.map(
        (artifactEdge: any) => artifactEdge.node
      ),
    })) || []
  ).filter(({ artifacts }) => artifacts.length > 0);

  const sortedArtifactsGroupedByTopicArray = sortBy(
    compact([currentTopicArtifacts, ...artifactsGroupedByTopicArray]),
    ({ meeting }) => {
      return -meeting.startDatetime;
    }
  );

  return (
    <div className="p-6 bg-white">
      {!data && loading ? (
        <Loading>loading...</Loading>
      ) : sortedArtifactsGroupedByTopicArray.length > 0 ? (
        <div className="">
          {sortedArtifactsGroupedByTopicArray.map(({ meeting, artifacts }) => (
            <div key={`${meeting.id}`} className="flex gap-4 mb-8">
              <div className="w-24 shrink-0">
                <AppLink
                  to={`/meeting/${meeting.meetingGroupId}/${meeting.id}`}
                  className="bg-gray-200 rounded-md text-center text-sm text-gray-700 p-1 hover:underline"
                >
                  {moment(meeting.startDatetime).format(
                    dateFormat.shortMonthDay
                  )}
                </AppLink>
              </div>
              <div className="flex-1 shrink flex flex-col gap-2">
                {artifacts.map((artifact: any) => (
                  <div
                    key={artifact.id}
                    className={classNames(
                      "px-3 py-1 rounded-lg",
                      getArtifactBgColor(artifact)
                    )}
                  >
                    <Artifact artifact={artifact} />
                  </div>
                ))}
              </div>
            </div>
          ))}
          {data?.topic?.relatedTopics?.pageInfo.hasNextPage && (
            <div className="flex items-center">
              <button
                className="py-2 text-sm font-normal text-gray-500 mr-2"
                onClick={handleClickLoadMore}
                disabled={loading}
              >
                Load more...
              </button>
              {loading && <Loading mini size="4" />}
            </div>
          )}
          <div className="border-t mt-8 pt-8">
            <RelatedTopicComments relatedTopic={topic} />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default AllTime;
