import { gql } from "@apollo/client";

export default gql`
  query getRatingArtifactAnswers($artifactId: Int!) {
    artifact(artifactId: $artifactId) {
      id
      ... on RatingArtifactNode {
        rating {
          id
          title
          startValue
          endValue
          labels
        }
        canAnswer
        canViewAnswers
        answers {
          totalCount
          edges {
            node {
              id
              created
              creator {
                id
                name
              }
              integerAnswer
            }
          }
        }
      }
    }
  }
`;
