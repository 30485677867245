import logoDev from "@static/img/topicflow-logo-dev.png";
import logo from "@static/img/topicflow-logo.svg";
import size from "lodash/size";
import { ReactElement, useEffect } from "react";

import Notification from "@apps/main/components/notification";
import { errorNotificationVar } from "@cache/cache";
import Link from "@components/link/link";
import { getAssetUrl } from "@helpers/helpers";

const OnboardingLayout = ({ children }: { children: ReactElement }) => {
  useEffect(() => {
    if (size(window.errorMessages) > 0) {
      errorNotificationVar({
        title: "Error",
        description: window.errorMessages[0],
        timeout: null,
      });
      window.errorMessages = [];
    }
  }, []);
  return (
    <div className="mx-4 md:mx-auto md:w-192 mt-16 mb-12">
      <div className="p-12 text-gray-800">
        <div className="mb-12">
          <Link
            to="/"
            className="font-light text-2xl flex items-center"
            id="sidebar-logo"
          >
            {import.meta.env.DEV ? (
              <img
                src={getAssetUrl(logoDev)}
                alt="Topicflow dev"
                className="block h-6"
              />
            ) : (
              <img
                src={getAssetUrl(logo)}
                alt="Topicflow"
                className="block h-8"
              />
            )}
          </Link>
        </div>
        {children}
      </div>
      <Notification />
    </div>
  );
};

export default OnboardingLayout;
