import { gql } from "@apollo/client";

import organizationFragment from "./organization-fragment";

export default gql`
  ${organizationFragment}
  query getOrganizationQuery($organizationId: Int!) {
    organization(pk: $organizationId) {
      ...OrganizationFragment
    }
  }
`;
