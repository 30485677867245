import { XIcon } from "@heroicons/react/outline";
import { TopicSidebarTopicNodeFragment } from "types/graphql-schema";

import TopicDropdownMenu from "@apps/topic-dropdown-menu/topic-dropdown-menu";
import Loading from "@components/loading/loading";

const Actions = ({
  topic,
  loading,
  isInSidebar,
  focusRef,
  onClose,
  onDelete,
}: {
  topic: TopicSidebarTopicNodeFragment;
  loading: boolean;
  isInSidebar: boolean;
  focusRef: any;
  onClose: () => void;
  onDelete: () => void;
}) => {
  // Render
  return (
    <div className="flex items-center justify-between bg-white">
      <div className="flex items-center uppercase text-sm font-medium text-gray-500">
        <span className="mr-2">Topic details</span>
        {loading && <Loading mini size="6" />}
      </div>
      <div className="flex items-center">
        <div className="flex">
          <TopicDropdownMenu
            topic={topic}
            className="bg-white rounded p-1 text-gray-400 hover:text-gray-500 hover:bg-gray-100"
            portal={!isInSidebar}
            onDelete={onDelete}
            isInSidebar
          />
        </div>
        <button
          type="button"
          className="ml-3 bg-white rounded p-1 text-gray-400 hover:text-gray-500 hover:bg-gray-100"
          aria-label="Close topic sidebar"
          ref={focusRef}
          onClick={onClose}
        >
          <span className="sr-only">Close topic sidebar</span>
          <XIcon className="h-6 w-6" />
        </button>
      </div>
    </div>
  );
};

export default Actions;
