import { gql } from "@apollo/client";

export const TopicDataFragment = gql`
  fragment TopicDataFragment on TopicNode {
    id
    title
    description
    discussionNotes
    includesSharedNotes
    created
    state
    eventChannelName
    isMandatory
    creator {
      id
      name
    }
    assignee {
      id
      avatar
      name
    }
    canDelete {
      permission
    }
    canUpdate {
      permission
    }
    previousTopic {
      id
      discussionNotes
    }
  }
`;
