import { gql } from "@apollo/client";

export default gql`
  mutation createOrUpdateSlackNotificationsForMeetingGroupMutation(
    $meetingGroupId: Int
    $notificationId: Int
    $channel: String
  ) {
    createOrUpdateSlackNotificationsForMeetingGroup(
      meetingGroupId: $meetingGroupId
      notificationId: $notificationId
      channel: $channel
      sendSummaryNotification: true
      sendPreparationNotification: true
      sendEmptyAgendaReminder: true
    ) {
      meetingGroup {
        id
        slackNotifications {
          edges {
            node {
              id
              channel
            }
          }
        }
      }
    }
  }
`;
