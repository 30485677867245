import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/outline";
import { Menu } from "@szhsin/react-menu";
import { pull, sortBy, union } from "lodash";
import { MouseEvent, useState } from "react";
import { TbPlaylistAdd } from "react-icons/tb";
import { useLocation } from "react-router-dom";
import {
  AlignmentGoalFragmentFragment,
  ArtifactType,
  GoalScope,
} from "types/graphql-schema";
import { TFLocationState } from "types/topicflow";

import GoalKeyResultItem from "@apps/artifact-sidebar/components/goal-key-result-item";
import Artifact from "@apps/artifact/artifact";
import ArtifactDropdownMenu, {
  ArtifactDropdownAlignmentSubMenu,
} from "@apps/artifact/artifact-dropdown-menu";
import { GoalProgressView } from "@apps/goal-progress/goal-progress";
import useUiPreferenceCache from "@apps/use-ui-preference-cache/use-ui-preference-cache";
import { useLink } from "@components/link/link";
import { classNames } from "@helpers/css";
import { assertEdgesNonNull, getUrl, toWithBackground } from "@helpers/helpers";

import {
  AlignmentChildGoalIdsByParentIdType,
  AlignmentGoalsByIdType,
} from "./goal-alignment";
import GoalAlignmentTree from "./goal-alignment-tree";

const indentWidth = 20;

const GoalAlignmentTreeItem = ({
  goal,
  isShowingKeyResults,
  goalsById,
  childGoalIdsByParentId,
  indent,
  searchResultGoalIds,
  goalIdsInSearchResultPaths,
}: {
  goal: { __typename: "GoalArtifactNode" } & AlignmentGoalFragmentFragment;
  goalsById: AlignmentGoalsByIdType;
  childGoalIdsByParentId: AlignmentChildGoalIdsByParentIdType;
  indent: number;
  isShowingKeyResults: boolean;
  searchResultGoalIds?: number[];
  goalIdsInSearchResultPaths?: number[];
}) => {
  const [buttonRef, setButtonRef] = useState<HTMLElement | null>(null);
  const link = useLink();
  const location = useLocation<TFLocationState>();
  const { uiPreferenceCache, saveUiPreference } = useUiPreferenceCache();
  const isInASearch =
    searchResultGoalIds !== undefined &&
    goalIdsInSearchResultPaths !== undefined;
  const isExpanded = isInASearch
    ? searchResultGoalIds.includes(goal.id) ||
      goalIdsInSearchResultPaths.includes(goal.id)
    : (uiPreferenceCache.objectiveAlignmentExpandedIds || []).includes(goal.id);

  const childGoalIds = childGoalIdsByParentId[goal.id] || [];
  const childGoals = childGoalIds.map((id) => goalsById[id]);
  const sortedChildGoals = sortBy(childGoals, (childGoal) =>
    childGoal.scope === GoalScope.Organization
      ? 1
      : childGoal.scope === GoalScope.Team
      ? 2
      : 3
  );

  const handleToggleChildGoals = () => {
    const objectiveAlignmentExpandedIds = isExpanded
      ? pull(uiPreferenceCache.objectiveAlignmentExpandedIds, goal.id)
      : union(uiPreferenceCache.objectiveAlignmentExpandedIds, [goal.id]);
    saveUiPreference({ objectiveAlignmentExpandedIds });
  };

  const handleClickGoalContainer = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    const target = e.target as HTMLElement;
    if (target && target.tagName === "DIV" && target.role === "button") {
      const pathname = getUrl({
        artifactId: goal.id,
        artifactType: ArtifactType.Goal,
      });
      link.redirect(toWithBackground({ pathname, location }));
    }
  };

  return (
    <div key={goal.id} className="">
      <div
        key={goal.id}
        className="p-1 rounded-md group hover:bg-blue-600/5 flex items-start gap-1"
        style={{ paddingLeft: `${indent * indentWidth + 4}px` }}
        onClick={handleClickGoalContainer}
        role="button"
      >
        <button
          className={classNames(
            "z-1 mt-[8px] p-1 rounded hover:bg-black/5 shrink-0 text-gray-500",
            (childGoalIds.length === 0 || isInASearch) && "opacity-0"
          )}
          onClick={handleToggleChildGoals}
        >
          {isExpanded ? (
            <ChevronDownIcon className="h-3 w-3" />
          ) : (
            <ChevronRightIcon className="h-3 w-3" />
          )}
        </button>
        <div
          key={goal.id}
          className={classNames(
            "flex-1 rounded-md bg-white border px-2 py-2",
            isShowingKeyResults && goal.keyResults.edges.length > 0 && "pb-2",
            searchResultGoalIds?.includes(goal.id) && "bg-amber-50"
          )}
        >
          <Artifact
            artifact={goal}
            showTitleOnlyOnOneLine
            hideActionDropdown
            goalProgressView={GoalProgressView.pillAndProgressBar}
          />

          {isShowingKeyResults && goal.keyResults.edges.length > 0 && (
            <div className="mt-2 text-sm text-gray-700">
              {assertEdgesNonNull(goal.keyResults).map((keyResult) => (
                <GoalKeyResultItem
                  key={keyResult.id}
                  className="py-0.5 pl-0 pr-0 gap-2.5 hover:bg-blue-50 rounded-md"
                  goalArtifact={goal}
                  keyResult={keyResult}
                  isReadOnly
                />
              ))}
            </div>
          )}
        </div>
        <div className="mt-1.5 ml-1 flex items-center opacity-0 group-hover:opacity-100 gap-2">
          {goal.canUpdate.permission && (
            <div className="hidden sm:block">
              <Menu
                portal
                align="end"
                transition={false}
                aria-label="Artifact dropdown menu list"
                className="text-sm not-prose z-dropdown fs-unmask"
                menuButton={
                  <button
                    className={classNames(
                      "text-gray-600 rounded hover:bg-black/5 p-0.5 flex"
                    )}
                    data-testid="artifact-dropdown-button"
                    aria-label="Artifact dropdown menu button"
                    ref={setButtonRef}
                  >
                    <TbPlaylistAdd className="h-5 w-5 text-gray-600 " />
                  </button>
                }
              >
                <ArtifactDropdownAlignmentSubMenu
                  artifact={goal}
                  buttonRef={buttonRef}
                />
              </Menu>
            </div>
          )}
          <ArtifactDropdownMenu
            artifact={goal}
            size="5"
            portal={true}
            className="text-gray-600 hover:bg-black/5 p-0.5"
          />
        </div>
      </div>
      {isExpanded && sortedChildGoals.length > 0 && (
        <div className="relative">
          {isExpanded && sortedChildGoals.length > 0 && (
            <div
              className="w-px absolute top-2 bottom-2 left-3.5 bg-black/5 -ml-px"
              style={{ marginLeft: `${(indent + 1) * indentWidth}px` }}
            />
          )}
          <GoalAlignmentTree
            goals={sortedChildGoals}
            goalsById={goalsById}
            childGoalIdsByParentId={childGoalIdsByParentId}
            isShowingKeyResults={isShowingKeyResults}
            indent={indent + 1}
            searchResultGoalIds={searchResultGoalIds}
            goalIdsInSearchResultPaths={goalIdsInSearchResultPaths}
          />
        </div>
      )}
    </div>
  );
};

export default GoalAlignmentTreeItem;
