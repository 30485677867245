import { RecognitionCoreValueNode } from "types/graphql-schema";

import CoreValueIcon from "@components/core-value-icon/core-value-icon";

const RecognitionCoreValueIcon = ({
  artifact,
}: {
  artifact?: {
    recognitionCoreValue?: Pick<
      RecognitionCoreValueNode,
      "emoji" | "image"
    > | null;
  };
}) => {
  return (
    <CoreValueIcon
      emoji={artifact?.recognitionCoreValue?.emoji}
      image={artifact?.recognitionCoreValue?.image}
      imagePxSize={20}
    />
  );
};

export default RecognitionCoreValueIcon;
