import { gql } from "@apollo/client";

import organizationFragment from "./organization-fragment";

export default gql`
  ${organizationFragment}
  mutation createOrganizationMutation(
    $name: String!
    $membershipSetting: OrganizationMembershipSetting!
    $associatedDomainName: String
  ) {
    createOrUpdateOrganization(
      name: $name
      membershipSetting: $membershipSetting
      associatedDomainName: $associatedDomainName
    ) {
      organization {
        ...OrganizationFragment
      }
    }
  }
`;
