import { useMutation } from "@apollo/client";
import { useCallback, useState } from "react";
import {
  SetRelationshipMutationFromAccountMutation,
  SetRelationshipMutationFromAccountMutationVariables,
} from "types/graphql-schema";
import { BasicUser } from "types/topicflow";

import setRelationshipMutationFromAccount from "@apps/account/graphql/set-relationship-mutation";
import Loading from "@components/loading/loading";
import { useNotificationError } from "@components/use-error/use-error";
import useUserComboboxQuery from "@components/user-combobox/use-user-combobox-query";
import UserCombobox from "@components/user-combobox/user-combobox";
import {
  UserComboboxOption,
  UserComboboxOptionType,
  UserComboboxUserOption,
} from "@components/user-combobox/user-combobox-list";

const OrganizationManagerCombobox = ({
  manager,
  organization,
  excludeUserIds,
}: {
  manager: BasicUser | null;
  organization: {
    id: number;
  };
  excludeUserIds: number[];
}) => {
  const [canLoadData, setCanLoadData] = useState(false);
  const { onError } = useNotificationError();
  const [setRelationship, { loading: relationshipLoading }] = useMutation<
    SetRelationshipMutationFromAccountMutation,
    SetRelationshipMutationFromAccountMutationVariables
  >(setRelationshipMutationFromAccount);

  const selectedUser: UserComboboxUserOption | undefined = manager
    ? { type: UserComboboxOptionType.USER, ...manager }
    : undefined;
  const { loading, options, query, setQuery } = useUserComboboxQuery({
    queryOptions: {
      skip: !canLoadData,
    },
    selected: selectedUser,
    excludeUserIds,
  });

  // Handlers
  const removeManager = useCallback(() => {
    if (manager) {
      return setRelationship({
        variables: {
          organizationId: organization.id,
          clearRelationship: true,
          otherUserId: manager.id,
        },
        onError,
      });
    }
    return Promise.resolve();
  }, [manager, onError, organization, setRelationship]);

  const handleChangeManager = useCallback(
    (newManager: UserComboboxOption | null) => {
      removeManager().then(() => {
        if (newManager?.id && newManager.type === UserComboboxOptionType.USER) {
          setRelationship({
            variables: {
              organizationId: organization.id,
              clearRelationship: null,
              otherUserId: newManager.id,
            },
            onError,
          });
        }
      });
    },
    [onError, organization, removeManager, setRelationship]
  );

  const handlePreloadData = useCallback(() => {
    setCanLoadData(true);
  }, []);

  const handleClearValue = useCallback(() => {
    handleChangeManager(null);
  }, [handleChangeManager]);

  return (
    <div className="flex gap-2 items-center">
      <div className="flex-1" onMouseEnter={handlePreloadData}>
        <UserCombobox
          aria-label="Manager combobox"
          disabled={relationshipLoading}
          loading={loading}
          width="full"
          query={query}
          value={selectedUser ?? null}
          placeholder="Add manager..."
          options={options}
          clearable={!!selectedUser?.id}
          onChangeValue={handleChangeManager}
          onChangeQuery={setQuery}
          onClickButton={handlePreloadData}
          onClearValue={handleClearValue}
        />
      </div>
      {relationshipLoading && (
        <div>
          <Loading mini size="5" />
        </div>
      )}
    </div>
  );
};

export default OrganizationManagerCombobox;
