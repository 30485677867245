import { HocuspocusProvider } from "@hocuspocus/provider";
import { createContext } from "react";
import { Doc } from "yjs";

export type MeetingWebsocketType = {
  ydoc?: Doc;
  providerWebsocket: HocuspocusProvider | null;
};

export const MeetingWebsocketProviderContext = createContext<{
  [key: string]: MeetingWebsocketType;
}>({ sharedNotes: { ydoc: undefined, providerWebsocket: null } });
