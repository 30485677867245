import { gql } from "@apollo/client";

export default gql`
  query searchGoalsToAlignQuery(
    $excludeIds: [Int]!
    $goalScopes: [GoalScope]!
    $goalParentIds: [Int]
    $search: String!
  ) {
    artifacts(
      artifactType: goal
      first: 10
      excludeIds: $excludeIds
      goalScopes: $goalScopes
      goalParentIds: $goalParentIds
      search: $search
      orderBy: "objective_alignment"
    ) {
      edges {
        node {
          id
          ... on GoalArtifactNode {
            title
            scope
            state
          }
        }
      }
    }
  }
`;
