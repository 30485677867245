import moment from "moment/moment";

import Layout from "@components/layout/layout";
import Link from "@components/link/link";
import { classNames } from "@helpers/css";

interface Meeting {
  id: number;
  meetingGroupId: number;
  startDatetime?: string | null | undefined;
  endDatetime?: string | null | undefined;
  title?: string | null | undefined;
  draft: boolean;
}
const RelatedRequirements = ({
  relatedMeetings,
}: {
  relatedMeetings: Meeting[];
}) => {
  if (!relatedMeetings.length) {
    return null;
  }
  return (
    <Layout.SidebarSection title="Related meetings">
      <Layout.SidebarSubSectionList>
        {relatedMeetings.map((meeting) => {
          const isInProgress = meeting.startDatetime
            ? moment().isBetween(meeting.startDatetime, meeting.endDatetime)
            : false;
          return (
            <Layout.SidebarSubSectionListItem>
              <div className="flex">
                <div
                  className={classNames(
                    "relative mt-1 w-3 h-3 rounded-full shrink-0 mr-2",
                    moment().isBefore(meeting.startDatetime) && "bg-gray-300",
                    moment().isAfter(meeting.endDatetime) && "bg-gray-300",
                    meeting.draft && "bg-amber-300",
                    isInProgress && "bg-emerald-500"
                  )}
                >
                  {isInProgress && (
                    <div className="absolute top-0 w-3 h-3 rounded-full bg-emerald-500 animate-ping" />
                  )}
                </div>
                <Link
                  to={`/meeting/${meeting.meetingGroupId}/${meeting.id}`}
                  className="text-blue-link text-sm"
                >
                  {meeting.title}
                </Link>
                <span className="text-sm text-gray-500 ml-auto">
                  {moment(meeting.startDatetime).format("MMM DD hh:mm A")}
                </span>
              </div>
            </Layout.SidebarSubSectionListItem>
          );
        })}
      </Layout.SidebarSubSectionList>
    </Layout.SidebarSection>
  );
};
export default RelatedRequirements;
