import { gql } from "@apollo/client";

const deleteTranscriptMessagesMutation = gql`
  mutation deleteMeetingTranscriptMessages(
    $meetingId: Int!
    $removeMessageIds: [String]
  ) {
    updateMeetingTranscript(
      meetingId: $meetingId
      removeMessageIds: $removeMessageIds
    ) {
      meeting {
        id
        transcript {
          orderedTranscriptData {
            text
            speaker
            created
            messageId
          }
          userMapping
        }
      }
    }
  }
`;

export default deleteTranscriptMessagesMutation;
