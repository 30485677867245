import { gql } from "@apollo/client";

export default gql`
  mutation ignoreMeetingMutation($meetingId: Int!, $ignore: Boolean!) {
    ignoreMeeting(meetingId: $meetingId, ignore: $ignore) {
      meeting {
        id
        ignored
        meetingGroup {
          id
        }
      }
    }
  }
`;
