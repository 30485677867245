import { MenuItem } from "@szhsin/react-menu";
import { OnlineVideoConference } from "types/graphql-schema";

import { onlineVideoConferenceType } from "@helpers/constants";

const VideoConferenceMenu = ({
  videoConferenceUrl,
  videoConferenceType,
}: {
  videoConferenceUrl: string;
  videoConferenceType: OnlineVideoConference;
}) => (
  <MenuItem
    className={"text-sm"}
    href={videoConferenceUrl}
    target="_blank"
    onClick={({ syntheticEvent }) => {
      syntheticEvent.preventDefault();
      syntheticEvent.stopPropagation();
      window.open(videoConferenceUrl, "_blank");
    }}
  >
    Open{" "}
    {videoConferenceType === onlineVideoConferenceType.google
      ? "Google Meet"
      : videoConferenceType === onlineVideoConferenceType.zoom
      ? "Zoom"
      : videoConferenceType === onlineVideoConferenceType.teams
      ? "Microsoft Teams"
      : videoConferenceType === onlineVideoConferenceType.whereby
      ? "Whereby"
      : videoConferenceType === onlineVideoConferenceType.aroundco
      ? "Around"
      : "video conference link"}
  </MenuItem>
);

export default VideoConferenceMenu;
