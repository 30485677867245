import { compact, sortBy } from "lodash";
import moment from "moment";
import { useState } from "react";
import {
  AttendeeRole,
  AttendeeStatus,
  GetUserActivityInComplianceProgramQuery,
  UserActivityComplianceProgramMeetingFragment,
} from "types/graphql-schema";
import { BasicUser } from "types/topicflow";

import MeetingDialog from "@apps/meeting-dialog/meeting-dialog";
import { currentUserVar } from "@cache/cache";
import Avatar from "@components/avatar/avatar";
import Button, { buttonTheme } from "@components/button/button";
import AppLink from "@components/link/link";
import Table, {
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableContainer,
  TableHeadCell,
  TableHeadRow,
} from "@components/table/table";
import { classNames } from "@helpers/css";
import {
  assertEdgesNonNull,
  assertEdgesNonNullWithStringId,
  getUrl,
} from "@helpers/helpers";

import getUserActivityInComplianceProgramQuery from "../graphql/get-user-activity-in-compliance-program-query";

enum UserRelationEnum {
  manager,
  report,
}
const ComplianceProgramUserActivityMeetingItem = ({
  user,
  matchingOneonone,
  userRelation,
  requiredTopicTemplateId,
}: {
  user: BasicUser & { email: string };
  userRelation: UserRelationEnum;
  requiredTopicTemplateId: number;
  matchingOneonone?: UserActivityComplianceProgramMeetingFragment | null;
}) => {
  const [isShowingDialog, setIsShowingDialog] = useState(false);
  const currentUser = currentUserVar();

  const handleCreateMeeting = () => {
    setIsShowingDialog(true);
  };

  return (
    <TableBodyRow key={user.id}>
      <TableBodyCell>
        <div className="flex items-center gap-2 text-gray-800">
          <Avatar user={user} size="5" />
          {user.name}
        </div>
      </TableBodyCell>
      <TableBodyCell className="">
        <span
          className={classNames(
            "inline-block py-2 rounded-md w-36 text-center",
            matchingOneonone?.isFinalized
              ? "bg-green-100 text-green-700"
              : matchingOneonone
              ? "bg-amber-100 text-amber-700"
              : "bg-gray-100 text-gray-600"
          )}
        >
          {matchingOneonone && matchingOneonone.isFinalized
            ? "Finalized"
            : matchingOneonone &&
              moment().isBefore(matchingOneonone.startDatetime, "hour")
            ? "Scheduled"
            : matchingOneonone
            ? "Unfinalized"
            : "Not scheduled"}
        </span>
      </TableBodyCell>
      <TableBodyCell>
        {matchingOneonone ? (
          <div>
            <AppLink
              to={getUrl({
                meetingGroupId: matchingOneonone.meetingGroupId,
                meetingId: matchingOneonone.id,
              })}
              className="text-blue-link hover:underline"
            >
              {matchingOneonone.title}
            </AppLink>{" "}
            <span className="text-xs tracking-tight">
              ({moment(matchingOneonone.startDatetime).format("LLL")})
            </span>
          </div>
        ) : (
          <Button
            small
            theme={buttonTheme.primary}
            onClick={handleCreateMeeting}
            text="Create meeting"
          />
        )}

        {isShowingDialog && (
          <MeetingDialog
            onClose={() => setIsShowingDialog(false)}
            formOptions={{
              templateId: requiredTopicTemplateId,
              isFormalOneonone: true,
              facilitatorId:
                userRelation === UserRelationEnum.report
                  ? currentUser.id
                  : user.id,
              attendees: compact([
                {
                  ...currentUser,
                  role: AttendeeRole.Required,
                  participantStatus: AttendeeStatus.NotResponded,
                },
                user.email && {
                  ...user,
                  role: AttendeeRole.Required,
                  participantStatus: AttendeeStatus.NotResponded,
                },
              ]),
            }}
            refetchQueries={[getUserActivityInComplianceProgramQuery]}
          />
        )}
      </TableBodyCell>
    </TableBodyRow>
  );
};

export const getReportsAndManagersWithMatchingOneonones = (
  complianceProgram: NonNullable<
    GetUserActivityInComplianceProgramQuery["complianceProgram"]
  >
) => {
  const currentUser = currentUserVar();

  const matchingOneonones = assertEdgesNonNull(
    complianceProgram.matchingOneonones
  );
  const reports = assertEdgesNonNull(currentUser.directReports).map((user) => ({
    ...user,
    type: UserRelationEnum.report,
  }));
  const managers = assertEdgesNonNull(currentUser.managers).map((user) => ({
    ...user,
    type: UserRelationEnum.manager,
  }));
  const reportsAndManagers = [...reports, ...managers];
  const items = reportsAndManagers.map((user) => {
    const userIds = sortBy([currentUser.id, user.id]);
    const matchingOneonone = matchingOneonones.find((oneonone) => {
      const participantIds = sortBy(
        assertEdgesNonNullWithStringId(oneonone.participants).map(
          (participant) => participant.user?.id
        )
      );
      return userIds.every((userId) => participantIds.includes(userId));
    });
    return { user, matchingOneonone };
  });
  return sortBy(items, (item) =>
    !item.matchingOneonone ? 0 : item.matchingOneonone.isFinalized ? 2 : 1
  );
};

const ComplianceProgramUserActivityMeetingList = ({
  complianceProgram,
  requiredTopicTemplateId,
}: {
  requiredTopicTemplateId: number;
  complianceProgram: NonNullable<
    GetUserActivityInComplianceProgramQuery["complianceProgram"]
  >;
}) => {
  const reportsAndManagersWithMatchingOneonone =
    getReportsAndManagersWithMatchingOneonones(complianceProgram);

  return (
    <TableContainer>
      <Table>
        <TableHeadRow>
          <TableHeadCell width="72">Name</TableHeadCell>
          <TableHeadCell width="56">Status</TableHeadCell>
          <TableHeadCell>Meeting</TableHeadCell>
        </TableHeadRow>
        <TableBody>
          {reportsAndManagersWithMatchingOneonone.map(
            (userWithMatchingOneonone) => (
              <ComplianceProgramUserActivityMeetingItem
                key={userWithMatchingOneonone.user.id}
                user={userWithMatchingOneonone.user}
                userRelation={userWithMatchingOneonone.user.type}
                matchingOneonone={userWithMatchingOneonone.matchingOneonone}
                requiredTopicTemplateId={requiredTopicTemplateId}
              />
            )
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ComplianceProgramUserActivityMeetingList;
