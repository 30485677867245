import { gql } from "@apollo/client";

export default gql`
  mutation cancelMeetingMutation(
    $meetingId: Int!
    $editType: MeetingEditTypeEnum!
  ) {
    cancelMeeting(meetingId: $meetingId, editType: $editType) {
      meeting {
        id
        status
      }
    }
  }
`;
