import { gql } from "@apollo/client";

export default gql`
  mutation deleteCommentMutation($commentId: Int!) {
    deleteComment(commentId: $commentId) {
      topic {
        id
      }
    }
  }
`;
