import { withErrorBoundary } from "@sentry/react";
import { useLocation } from "react-router-dom";
import {
  AlignmentGoalFragmentFragment,
  ArtifactComponentFragmentFragment,
  GoalState,
} from "types/graphql-schema";
import { TFLocationState } from "types/topicflow";

import GoalProgress, {
  GoalProgressView,
} from "@apps/goal-progress/goal-progress";
import useLabel, { getLabel } from "@apps/use-label/use-label";
import Avatar from "@components/avatar/avatar";
import DraftLabel from "@components/draft-label/draft-label";
import Error from "@components/error/error";
import GoalVisibilityIcon from "@components/goal-visibility-icon/goal-visibility-icon";
import Link from "@components/link/link";
import TimeAgoCustom from "@components/time-ago/time-ago";
import { classNames } from "@helpers/css";
import { getUrl, toWithBackground } from "@helpers/helpers";
import { pluralize } from "@helpers/string";

import ArtifactDropdownMenu from "./artifact-dropdown-menu";
import ActionItemAssignee from "./components/artifact-assignee";
import ArtifactCreated from "./components/artifact-created";
import ArtifactDueDate from "./components/artifact-due-date";
import ArtifactIcon from "./components/artifact-icon";
import GoalParticipants from "./components/goal-participants";
import ArtifactRecognitionRecipients from "./components/recognition-recipients";

export const recognitionRecipientListString = (artifact: any, max = 3) => {
  if (artifact.recipients.totalCount === 1)
    return artifact.recipients.edges[0]?.node.name;
  if (artifact.recipients.totalCount > 1) {
    const userNames = artifact.recipients.edges
      .slice(0, max)
      .map(({ node }: { node: any }) => node.name);
    const remaining = artifact.recipients.totalCount - userNames.length;
    const listNames =
      remaining > 0
        ? userNames.concat(`${remaining} ${pluralize("person", remaining)}`)
        : userNames;
    const lf = new Intl.ListFormat("en");
    return lf.format(listNames);
  }
  return `${artifact.recipients.totalCount} ${pluralize(
    "person",
    artifact.recipients.totalCount
  )}`;
};

export const getRecognitionRecipientTitle = (artifact: any) => {
  const label = getLabel();
  return `${recognitionRecipientListString(artifact)} received a ${label(
    "recognition"
  )}${
    artifact.recognitionCoreValue
      ? ` for ${artifact.recognitionCoreValue.title}`
      : ""
  } from ${artifact.creator.name}`;
};

const Artifact = ({
  artifact,
  size = 5,
  urlPrefix = "",
  hideActionDropdown = false,
  showTitleOnlyOnOneLine = false,
  goalProgressView = GoalProgressView.pillAndProgressText,
}: {
  artifact:
    | ({ __typename: "GoalArtifactNode" } & AlignmentGoalFragmentFragment)
    | ArtifactComponentFragmentFragment;
  urlPrefix?: string;
  size?: number;
  hideActionDropdown?: boolean;
  showTitleOnlyOnOneLine?: boolean;
  goalProgressView?: GoalProgressView;
}) => {
  const location = useLocation<TFLocationState>();
  const isActionItem = artifact.__typename === "ActionItemArtifactNode";
  const isDecision = artifact.__typename === "DecisionArtifactNode";
  const isGoal = artifact.__typename === "GoalArtifactNode";
  const isDocument = artifact.__typename === "DocumentArtifactNode";
  const isRecognition = artifact.__typename === "RecognitionArtifactNode";
  const isRating = artifact.__typename === "RatingArtifactNode";
  const l = useLabel();
  const title =
    (isDecision
      ? artifact.decision
      : isRating
      ? artifact.rating?.title
      : isRecognition
      ? getRecognitionRecipientTitle(artifact)
      : artifact.title) || "";

  const artifactUrl = getUrl({
    artifactId: artifact.id,
    artifactType: artifact.artifactType,
  });

  return (
    <div
      key={artifact.id}
      aria-label={`${l(artifact.artifactType)} container`}
      className="fs-mask @container/artifact"
    >
      <div key={artifact.id} className="flex items-start gap-1.5 min-w-0">
        <ArtifactIcon artifact={artifact} size={size} className="mt-px" />
        <Link
          className={classNames(
            "text-sm text-gray-700 flex-1 break-word group mt-px",
            showTitleOnlyOnOneLine ? "truncate" : "line-clamp-2"
          )}
          to={toWithBackground({
            pathname: `${urlPrefix}${artifactUrl}`,
            location,
          })}
        >
          <span className="no-underline group-hover:underline-offset-2 group-hover:underline group-hover:text-blue-600 group-hover:decoration-blue-300">
            {title}
          </span>
          {((isGoal && artifact.state === GoalState.Draft) ||
            (isDecision && artifact.isDraft)) && (
            <DraftLabel className="ml-1" />
          )}
          {isGoal && (
            <GoalVisibilityIcon
              showOnlyIfPrivate
              goalVisibility={artifact.goalVisibility}
              className="ml-1 inline-block"
              size="4"
            />
          )}
        </Link>

        <div className="flex items-center gap-2.5">
          {(isActionItem || isGoal) && (
            <div className="hidden sm:flex items-center text-xs -mx-1">
              <ArtifactDueDate artifact={artifact} labelName="due date" />
            </div>
          )}
          {isGoal && (
            <GoalProgress
              withPopover={true}
              artifact={artifact}
              goalProgressView={goalProgressView}
            />
          )}
          {isActionItem && (
            <ActionItemAssignee
              artifact={artifact}
              showName={false}
              className="shrink-0"
            />
          )}
          {isGoal && <GoalParticipants artifact={artifact} />}
          {isDocument && artifact.updated && (
            <span className="hidden @xl/artifact:block text-gray-500 text-xs tracking-tight">
              <TimeAgoCustom date={artifact.updated} prefix="Last updated: " />
            </span>
          )}
          {isDocument && (
            <Avatar
              user={artifact.creator}
              size="5"
              tooltipPrefix="Created by "
            />
          )}
          {isRecognition && (
            <div className="hidden @xl/artifact:block">
              <ArtifactCreated artifact={artifact} />
            </div>
          )}
          {isRecognition && (
            <ArtifactRecognitionRecipients artifact={artifact} />
          )}

          {!hideActionDropdown && (
            <div className="flex">
              <ArtifactDropdownMenu
                artifact={artifact}
                size="5"
                portal={true}
                className="hover:bg-black/5 p-0.5"
              />
            </div>
          )}
        </div>
      </div>

      {isRecognition && (
        <div className="text-sm mt-1 text-gray-500 ml-7">{artifact.title}</div>
      )}
    </div>
  );
};

export default withErrorBoundary(Artifact, {
  fallback: <Error description={"The artifact could not be rendered."} />,
});
