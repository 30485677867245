import { gql } from "@apollo/client";

export default gql`
  mutation deleteProgram($complianceProgramId: Int!) {
    deleteComplianceProgram(complianceProgramId: $complianceProgramId) {
      organization {
        id
      }
    }
  }
`;
