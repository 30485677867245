import { Children, cloneElement } from "react";

// https://react-tooltip.com/
export const Tooltip = ({
  children,
  text,
  delay = 100,
}: {
  children: any;
  text: any;
  delay?: number | false;
}) => {
  if (!text) {
    return children;
  }
  return Children.map(children, function (child) {
    return cloneElement(child, {
      "data-tooltip-id": "topicflow-tooltip",
      "data-tooltip-html": text,
      "data-tooltip-delay-show": delay,
      "data-tooltip-delay-hide": 0,
      "data-tooltip-class-name": "topicflow-tooltip",
    });
  });
};

export default Tooltip;
