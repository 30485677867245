import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import {
  CancelMeetingMutationMutation,
  CancelMeetingMutationMutationVariables,
  DeleteDraftMeetingNewPageMutationMutation,
  DeleteDraftMeetingNewPageMutationMutationVariables,
  MeetingEditTypeEnum,
} from "types/graphql-schema";

import getSidebarDataQuery from "@apps/main/graphql/get-sidebar-data-query";
import deleteDraftMeetingMutation from "@apps/meeting-new/graphql/delete-draft-meeting-mutation";
import { useNotificationError } from "@components/use-error/use-error";
import { assertNonNull } from "@helpers/helpers";
import useConfirm from "@helpers/hooks/use-confirm";

import cancelMeetingMutation from "../graphql/cancel-meeting-mutation";

const useTrashMeeting = ({
  draft,
  meetingId,
  onCompleted,
}: {
  draft: boolean;
  meetingId?: number | null;
  onCompleted?: () => void;
}) => {
  const { onError } = useNotificationError({
    errorMatches: [
      {
        match: "only the organizer of a meeting can cancel it",
        title: "The meeting can only be cancelled by its organizer.",
      },
    ],
  });

  const {
    ConfirmationDialog: ConfirmationDeleteDialog,
    confirm: confirmDelete,
  } = useConfirm(
    "Are you sure?",
    "Are you sure you want to delete this draft meeting?"
  );
  const {
    ConfirmationDialog: ConfirmationCancelDialog,
    confirm: confirmCancel,
  } = useConfirm(
    "Are you sure?",
    "Are you sure you want to cancel this meeting?"
  );

  const [deleteDraftMeeting, { loading: loadingDeleteDraft }] = useMutation<
    DeleteDraftMeetingNewPageMutationMutation,
    DeleteDraftMeetingNewPageMutationMutationVariables
  >(deleteDraftMeetingMutation, {
    refetchQueries: [getSidebarDataQuery],
  });
  const [cancelMeeting, { loading: loadingCancel }] = useMutation<
    CancelMeetingMutationMutation,
    CancelMeetingMutationMutationVariables
  >(cancelMeetingMutation, {
    refetchQueries: [getSidebarDataQuery],
  });

  const handleTrashMeeting = useCallback(
    async (editType: MeetingEditTypeEnum) => {
      if (draft) {
        const confirmation = await confirmDelete();
        if (confirmation) {
          deleteDraftMeeting({
            variables: { meetingId: assertNonNull(meetingId) },
            onCompleted,
            onError,
          });
        }
      } else {
        const confirmation = await confirmCancel();
        if (confirmation) {
          cancelMeeting({
            variables: {
              meetingId: assertNonNull(meetingId),
              editType: assertNonNull(editType),
            },
            onCompleted,
            onError,
          });
        }
      }
    },
    [
      cancelMeeting,
      confirmCancel,
      confirmDelete,
      deleteDraftMeeting,
      draft,
      meetingId,
      onCompleted,
      onError,
    ]
  );

  return {
    ConfirmationCancelDialog,
    ConfirmationDeleteDialog,
    isTrashingMeeting: loadingCancel || loadingDeleteDraft,
    handleTrashMeeting,
  };
};

export default useTrashMeeting;
