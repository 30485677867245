import { Popover } from "@headlessui/react";
import { useState } from "react";
import { BasicUser } from "types/topicflow";

import { currentUserVar } from "@cache/cache";
import Avatar from "@components/avatar/avatar";
import useUserComboboxQuery from "@components/user-combobox/use-user-combobox-query";
import UserCombobox from "@components/user-combobox/user-combobox";
import {
  UserComboboxOption,
  UserComboboxOptionType,
  UserComboboxUserOption,
} from "@components/user-combobox/user-combobox-list";
import { classNames } from "@helpers/css";

const ActionItemPeopleDropdown = ({
  actionItem,
  onChangeAssignee,
  unassignedId = -1,
  canChange = true,
  showName = true,
  className = "",
}: {
  actionItem: {
    assignee?: BasicUser | null;
  };
  onChangeAssignee: (assignee: null | UserComboboxUserOption) => void;
  unassignedId?: number;
  canChange?: boolean;
  showName?: boolean;
  className?: string;
}) => {
  const currentUser = currentUserVar();
  const [canLoadData, setCanLoadData] = useState(false);
  const emptyOption: UserComboboxUserOption = {
    id: unassignedId,
    name: "Unassigned",
    type: UserComboboxOptionType.USER,
  };
  const selectedUser: UserComboboxUserOption = actionItem.assignee?.id
    ? { type: UserComboboxOptionType.USER, ...actionItem.assignee }
    : emptyOption;
  const { loading, options, query, setQuery } = useUserComboboxQuery({
    queryOptions: {
      skip: !canLoadData,
    },
    selected: selectedUser,
    emptyOption,
  });

  const handleChangeAssignee = (option: UserComboboxOption) => {
    if (option.type === UserComboboxOptionType.USER) onChangeAssignee(option);
  };

  const handleClearAssignee = () => {
    onChangeAssignee(null);
  };

  const handlePreloadData = () => {
    setCanLoadData(true);
  };

  const selectedAssigneeName = actionItem.assignee
    ? actionItem.assignee?.name
    : "Unassigned";
  const selectedClassName = classNames(
    "text-base flex items-center gap-1",
    actionItem.assignee?.name
      ? "text-gray-600"
      : "text-gray-400 hover:text-gray-600",
    actionItem.assignee?.id === currentUser.id && " font-semibold text-gray-800"
  );

  return canChange ? (
    <UserCombobox
      aria-label="Action item people dropdown"
      loading={loading}
      width="64"
      query={query}
      value={selectedUser}
      options={options}
      onChangeValue={handleChangeAssignee}
      onChangeQuery={setQuery}
      onClickButton={handlePreloadData}
      onClearValue={handleClearAssignee}
    >
      {({ setReferenceElement, onClickButton }) => (
        <div className={"flex justify-between bg-white rounded-md"}>
          <Popover.Button
            className={classNames("flex items-center text-sm", className)}
            onClick={onClickButton}
            ref={setReferenceElement}
          >
            <Avatar
              user={actionItem.assignee}
              tooltipPrefix="Assigned to "
              size="5"
              className={classNames(showName && "mr-1")}
              unassignedTooltipText="Unassigned"
            />
            {showName && selectedAssigneeName}
          </Popover.Button>
        </div>
      )}
    </UserCombobox>
  ) : (
    <span className={classNames(selectedClassName, "hover:bg-white")}>
      {selectedAssigneeName}
    </span>
  );
};

export default ActionItemPeopleDropdown;
