import { gql } from "@apollo/client";

export default gql`
  mutation orgSettingsCreateOrUpdateCoreValue(
    $organizationId: Int!
    $id: Int
    $title: String
    $description: String
    $emoji: String
    $image: String
    $status: RecognitionCoreValueStatus
  ) {
    createOrUpdateCoreValue(
      organizationId: $organizationId
      id: $id
      title: $title
      description: $description
      emoji: $emoji
      image: $image
      status: $status
    ) {
      coreValue {
        id
        title
        description
        emoji
        image
        status
      }
    }
  }
`;
