import { CheckIcon } from "@heroicons/react/outline";
import React, { PropsWithChildren } from "react";

import { classNames } from "@helpers/css";

const Onboarding: {
  Title: React.FC<PropsWithChildren<{ className?: string }>>;
  SubTitle: React.FC<PropsWithChildren<{ className?: string }>>;
  CompletedStep: React.FC<PropsWithChildren>;
  SkippedStep: React.FC<PropsWithChildren>;
  textButtonClassName: string;
  SuccessStep: React.FC<
    PropsWithChildren<
      React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLDivElement>,
        HTMLDivElement
      > & { stepName: string }
    >
  >;
} = {
  Title: ({ children, className = "" }) => (
    <h1 className={classNames("text-2xl font-semibold", className)}>
      {children}
    </h1>
  ),
  SubTitle: ({ children, className = "" }) => (
    <h2
      className={classNames("text-xl font-semibold text-gray-700", className)}
    >
      {children}
    </h2>
  ),
  SuccessStep: ({ stepName, children, ...props }) => (
    <div
      {...props}
      className="flex justify-between items-center text-emerald-700 border border-emerald-600 bg-emerald-50 rounded-md px-8 py-6 text-sm"
    >
      <Onboarding.SubTitle className="text-gray-800 flex gap-4 items-center">
        {children}
      </Onboarding.SubTitle>
      <div>
        <Onboarding.CompletedStep>{stepName}</Onboarding.CompletedStep>
      </div>
    </div>
  ),
  CompletedStep: ({ children }) => (
    <span className="text-base font-semibold px-4 py-2 bg-emerald-100 text-emerald-700 rounded-md flex items-center gap-1">
      <CheckIcon className="h-5 w-5" /> {children}
    </span>
  ),
  SkippedStep: ({ children }) => (
    <span className="text-base font-semibold px-4 py-2 bg-gray-200 text-gray-500 rounded-md flex items-center gap-1">
      {children}
    </span>
  ),
  textButtonClassName:
    "text-base text-blue-600 font-medium hover:underline inline-block px-4 py-2",
};

export default Onboarding;
