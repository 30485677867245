import { useMutation } from "@apollo/client";
import pluralize from "pluralize";
import { OrganizationInfoNode } from "types/graphql-schema";

import joinOrganizationMutation from "@apps/account/graphql/join-organization-mutation";
import Button from "@components/button/button";
import { useNotificationError } from "@components/use-error/use-error";
import { assertNonNull } from "@helpers/helpers";

const OrganizationInvitation = ({
  organization,
}: {
  organization: Pick<
    OrganizationInfoNode,
    "organizationId" | "organizationMemberCount" | "organizationName"
  >;
}) => {
  const [joinOrganization, { loading }] = useMutation(joinOrganizationMutation);
  const { onError } = useNotificationError();

  const handleJoinOrganization = () => {
    joinOrganization({
      variables: {
        organizationId: organization.organizationId,
      },
      onError,
    });
  };

  return (
    <div
      className="px-4 py-3 rounded border bg-white flex justify-between items-center"
      key={organization.organizationId}
    >
      <div>
        <div className="font-medium">{organization.organizationName}</div>
        <div className="text-sm text-gray-600">
          {organization.organizationMemberCount}{" "}
          {pluralize(
            "member",
            assertNonNull(organization.organizationMemberCount)
          )}
        </div>
      </div>
      <div>
        <Button
          disabled={loading}
          onClick={handleJoinOrganization}
          text="Join now"
          theme="primary"
        />
      </div>
    </div>
  );
};

export default OrganizationInvitation;
