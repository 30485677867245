import { useMutation } from "@apollo/client";
import { StarIcon, XIcon } from "@heroicons/react/outline";
import { StarIcon as SolidStarIcon } from "@heroicons/react/solid";
import { Ref } from "react";
import { GetArtifactSidebarQueryQuery } from "types/graphql-schema";

import ArtifactDropdownMenu from "@apps/artifact/artifact-dropdown-menu";
import { currentUserVar } from "@cache/cache";
import Loading from "@components/loading/loading";
import Tooltip from "@components/tooltip/tooltip";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { assertEdgesNonNull } from "@helpers/helpers";

import addFavouriteArtifactMutation from "../graphql/add-favourite-artifact-mutation";
import removeFavouriteArtifactMutation from "../graphql/remove-favourite-artifact-mutation";

const Actions = ({
  artifact,
  loading,
  focusRef,
  onClose,
  onDelete,
}: {
  artifact: GetArtifactSidebarQueryQuery["artifact"];
  loading: boolean;
  focusRef: Ref<HTMLButtonElement>;
  onClose: () => void;
  onDelete: () => void;
}) => {
  const currentUser = currentUserVar();
  const favouriteIds = assertEdgesNonNull(currentUser.favouriteArtifacts).map(
    ({ id }) => id
  );
  const isFavourite = favouriteIds.includes(artifact.id);

  const [addFavouriteArtifact, { loading: loadingAdd }] = useMutation(
    addFavouriteArtifactMutation
  );
  const [removeFavouriteArtifact, { loading: loadingRemove }] = useMutation(
    removeFavouriteArtifactMutation
  );
  const loadingToggleFavourite = loadingRemove || loadingAdd;

  const handleClickFavouriteButton = () => {
    const variables = { artifactId: artifact.id };
    if (isFavourite) {
      removeFavouriteArtifact({
        variables,
        onError: onNotificationErrorHandler(),
      });
    } else {
      addFavouriteArtifact({
        variables,
        onError: onNotificationErrorHandler(),
      });
    }
  };

  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center gap-2">
        {loading && <Loading mini size="5" />}
        {loadingToggleFavourite ? (
          <Loading mini size="5" />
        ) : (
          <Tooltip text="Mark as favourite">
            <button
              type="button"
              className="bg-white rounded p-0.5 text-gray-400 hover:text-gray-500 hover:bg-gray-100"
              aria-label="Favourite artifact button"
              onClick={handleClickFavouriteButton}
            >
              <span className="sr-only">Favourite artifact</span>
              {isFavourite ? (
                <SolidStarIcon className="h-6 w-6 text-yellow-500" />
              ) : (
                <StarIcon className="h-6 w-6" />
              )}
            </button>
          </Tooltip>
        )}
        <div className="flex">
          <ArtifactDropdownMenu
            size="6"
            artifact={artifact}
            className="bg-white rounded p-0.5 text-gray-400 hover:text-gray-500 hover:bg-gray-100"
            portal={false} // because we are in a sidebar and already in portal
            onDelete={onDelete}
          />
        </div>
        <button
          type="button"
          className="bg-white rounded p-0.5 text-gray-400 hover:text-gray-500 hover:bg-gray-100"
          aria-label="Close artifact sidebar"
          onClick={onClose}
          ref={focusRef}
        >
          <span className="sr-only">Close artifact sidebar</span>
          <XIcon className="h-6 w-6" />
        </button>
      </div>
    </div>
  );
};

export default Actions;
