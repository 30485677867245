import { useMutation } from "@apollo/client";
import { XIcon } from "@heroicons/react/solid";
import { range, uniqueId } from "lodash";
import pluralize from "pluralize";
import { MouseEvent, useState } from "react";
import { MdRadioButtonChecked, MdRadioButtonUnchecked } from "react-icons/md";
import { useLocation } from "react-router-dom";
import { TFLocationState } from "types/topicflow";

import createOrUpdateRatingAnswerMutation from "@apps/ratings/graphql/create-or-update-rating-answer-mutation";
import { currentUserVar } from "@cache/cache";
import AppLink from "@components/link/link";
import Tooltip from "@components/tooltip/tooltip";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { isEmptyValue } from "@components/wysiwyg/helpers";
import TextareaWysiwyg from "@components/wysiwyg/textarea-wysiwyg";
import { artifactType } from "@helpers/constants";
import { classNames } from "@helpers/css";
import { getUrl, parseStringToJSON, toWithBackground } from "@helpers/helpers";

import { getRatingScaleClassName } from "../helpers";
import RatingAnswersModal from "./answers-modal";

const Rating = ({
  rating,
  meetingId,
  meetingGroupId,
  ratingRequirement = null,
  artifact = null,
  onDelete = null,
}: {
  rating: any;
  meetingId: number;
  meetingGroupId: number;
  ratingRequirement: any;
  artifact: any;
  onDelete: (() => void) | null;
}) => {
  const canViewAnswers = artifact
    ? artifact.canViewAnswers
    : ratingRequirement
    ? ratingRequirement.canViewAnswers
    : null;
  const canAnswer = artifact
    ? artifact.canAnswer
    : ratingRequirement
    ? ratingRequirement.canAnswer
    : null;
  if (canViewAnswers === null || canViewAnswers === undefined) {
    window.console.error("Rating should have canViewAnswers");
  }
  if (canAnswer === null || canAnswer === undefined) {
    window.console.error("Rating should have canAnswer");
  }

  const [showAnswersModal, setShowAnswersModal] = useState(false);
  const location = useLocation<TFLocationState>();
  const currentUser = currentUserVar();
  const [saveAnswer, { loading }] = useMutation(
    createOrUpdateRatingAnswerMutation
  );
  const answers = artifact?.answers;
  const currentUserAnswer = answers?.edges.find(
    (edge: any) => edge.node.creator.id === currentUser.id
  )?.node;

  const handleClickAnswer = (integerAnswer: any) => (e: MouseEvent) => {
    if (!canAnswer) {
      return;
    }
    e.preventDefault();
    const tempAnswerId = currentUserAnswer?.id || -1;
    const newAnswer = {
      creator: currentUser,
      id: tempAnswerId,
      integerAnswer,
      __typename: "RatingAnswerNode",
    };
    const newAnswers = (answers?.edges || [])
      .filter((edge: any) => edge.node.id !== tempAnswerId)
      .concat({ node: newAnswer });
    // In some cases (locked meeting) there are no artifacts attached to the meeting rating requirement
    // so we fake it for the optimistic update.
    const updatedArtifact = {
      ...(artifact
        ? artifact
        : {
            id: uniqueId("artifact-"),
            artifactType: artifactType.rating,
            __typename: "RatingArtifactNode",
            canViewAnswers: false,
            canAnswer: true,
          }),
      answers: {
        ...answers,
        edges: newAnswers,
      },
    };

    const optimisticResponse = {
      createOrUpdateRatingAnswer: {
        ratingRequirement: ratingRequirement
          ? {
              ...ratingRequirement,
              ratingArtifact: updatedArtifact,
            }
          : null,
        ratingAnswer: {
          createdFromArtifact: updatedArtifact,
          createdInMeeting: {
            id: meetingId,
            __typename: "MeetingNode",
          },
          rating: {
            ...rating,
          },
          ...newAnswer,
        },
        __typename: "CreateOrUpdateRatingAnswerMutation",
      },
    };

    saveAnswer({
      variables: {
        integerAnswer,
        meetingId: meetingId,
        ratingArtifactId: artifact?.id,
        meetingRatingRequirementId: ratingRequirement?.id,
        ratingAnswerId: currentUserAnswer?.id,
      },
      onError: onNotificationErrorHandler(),
      optimisticResponse,
    });
  };
  const description = parseStringToJSON(rating.description);

  return (
    <div
      className="flex-1 flex flex-col gap-4 text-gray-800"
      contentEditable={false}
    >
      {showAnswersModal && (
        <RatingAnswersModal
          artifact={artifact}
          rating={rating}
          onClose={() => setShowAnswersModal(false)}
        />
      )}
      <div>
        <div className="flex gap-2 items-center">
          <div className="font-medium text-lg flex-1">
            {artifact ? (
              <AppLink
                to={toWithBackground({
                  pathname: getUrl({
                    meetingGroupId: meetingGroupId,
                    meetingId: meetingId,
                    artifactId: artifact.id,
                    artifactType: artifact.artifactType,
                  }),
                  location,
                })}
                className="hover:underline"
              >
                {rating.title}
              </AppLink>
            ) : (
              rating.title
            )}
          </div>
          {onDelete && (
            <Tooltip text="Remove">
              <button
                className="rounded p-1 text-gray-600 hover:bg-black/5"
                onClick={onDelete}
              >
                <XIcon className="h-5 w-5" />
              </button>
            </Tooltip>
          )}
        </div>
        {!isEmptyValue(description) && <TextareaWysiwyg value={description} />}
      </div>
      <div
        className={classNames(
          `gap-2 grid`,
          getRatingScaleClassName(rating.startValue, rating.endValue)
        )}
      >
        {range(rating.startValue, rating.endValue + 1).map((value, index) => (
          <button
            contentEditable={false}
            key={value}
            className={classNames(
              "flex flex-col gap-1 items-center border bg-gray-100 cursor-default rounded p-1",
              currentUserAnswer?.integerAnswer === value &&
                "bg-blue-100 border-blue-200",
              canAnswer && "hover:bg-gray-200 cursor-pointer",
              canAnswer &&
                currentUserAnswer?.integerAnswer === value &&
                "hover:bg-blue-100"
            )}
            onClick={handleClickAnswer(value)}
            disabled={!canAnswer || loading}
          >
            <div className="text-sm font-medium text-gray-500 w-full text-center tracking-tighter leading-tight flex-1">
              {rating.labels?.[index] || value}
            </div>
            <div className="flex w-full justify-center">
              {currentUserAnswer?.integerAnswer === value ? (
                <MdRadioButtonChecked className="h-4 w-4 text-blue-600" />
              ) : (
                <MdRadioButtonUnchecked className="h-4 w-4 text-gray-500" />
              )}
            </div>
          </button>
        ))}
      </div>
      {answers && (
        <div className="text-xs text-gray-500">
          {canViewAnswers ? (
            <button
              onClick={() => setShowAnswersModal(true)}
              className="hover:underline"
            >
              {answers.totalCount} {pluralize("answer", answers.totalCount)}
            </button>
          ) : (
            <span>
              {answers.totalCount} {pluralize("answer", answers.totalCount)}
            </span>
          )}
        </div>
      )}
    </div>
  );
};

export default Rating;
