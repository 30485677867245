import { max, min } from "lodash";
import { GoalArtifactNode } from "types/graphql-schema";

import GoalStatusPill from "@components/goal-status-pill/goal-status-pill";
import { getProgressLabel } from "@helpers/helpers";

const GoalProgressBar = ({
  goal,
  canUpdateProgress,
  onClickUpdateProgress,
}: {
  goal: Pick<
    GoalArtifactNode,
    | "status"
    | "state"
    | "progress"
    | "progressType"
    | "startValue"
    | "currentValue"
    | "targetValue"
  >;
  canUpdateProgress: boolean;
  onClickUpdateProgress: () => void;
}) => {
  const progressWidth = min([100, max([1, goal.progress])]);
  const label = getProgressLabel(goal);
  return (
    <button
      className="w-full bg-gray-50 rounded-full h-6 m-0 p-0 relative overflow-hidden group/goal-progress"
      aria-label="Goal progress bar container"
      onClick={onClickUpdateProgress}
      disabled={!canUpdateProgress}
      type="button"
    >
      <div className="bg-blue-100 h-6" style={{ width: `${progressWidth}%` }} />
      <div className="absolute inset-0 flex items-center justify-center text-sm font-medium text-gray-700 gap-2 group-hover/goal-progress:underline">
        <GoalStatusPill status={goal.status} state={goal.state} />
        {label}
      </div>
    </button>
  );
};

export default GoalProgressBar;
