import { gql } from "@apollo/client";

import KPISummarizedMeasurementFragment from "@apps/kpis/graphql/kpi-summarized-measurement-fragment";

export default gql`
  ${KPISummarizedMeasurementFragment}
  query getEmbedKpiGroupQuery(
    $kpiGroupId: Int!
    $teamId: Int!
    $hasTeamId: Boolean!
    $summaryPeriod: KPISummaryPeriod
    $first: Int = 4
  ) {
    kpiGroup(kpiGroupId: $kpiGroupId) @skip(if: $hasTeamId) {
      id
      title
      kpis {
        edges {
          node {
            id
            title
            created
            targetType
            targetValue
            targetCondition
            defaultSummaryPeriod
            canUpdate {
              permission
              reason
            }
            currentMeasurement {
              id
              measurement
            }
            summarizedMeasurements(
              first: $first
              summaryPeriod: $summaryPeriod
            ) {
              edges {
                node {
                  ...KPISummarizedMeasurementFragment
                }
              }
            }
          }
        }
      }
    }
    team(teamId: $teamId) @include(if: $hasTeamId) {
      id
      title
      kpis {
        edges {
          node {
            id
            title
            created
            targetType
            targetValue
            targetCondition
            defaultSummaryPeriod
            canUpdate {
              permission
              reason
            }
            currentMeasurement {
              id
              measurement
            }
            summarizedMeasurements(
              first: $first
              summaryPeriod: $summaryPeriod
            ) {
              edges {
                node {
                  ...KPISummarizedMeasurementFragment
                }
              }
            }
          }
        }
      }
    }
  }
`;
