import { useReactiveVar } from "@apollo/client";
import { Portal } from "@headlessui/react";
import { XCircleIcon } from "@heroicons/react/outline";
import { CheckCircleIcon } from "@heroicons/react/outline";
import { XIcon } from "@heroicons/react/solid";
import { useEffect, useMemo } from "react";

import { NotificationType, notificationVar } from "@cache/cache";
import { delay } from "@helpers/constants";
import { classNames } from "@helpers/css";

export default function Notification() {
  const notificationRV = useReactiveVar(notificationVar);
  const notification = useMemo(
    () =>
      notificationRV && {
        title: "",
        description: "",
        type: NotificationType.success,
        timeout: delay.notificationHidesAfter,
        ...notificationRV,
      },
    [notificationRV]
  );

  const handleHideNotification = () => {
    notificationVar(null);
  };

  // show error when a new one is set and hide after delay
  useEffect(() => {
    if (notification) {
      let timer: number | null = null;
      if (notification.timeout) {
        timer = window.setTimeout(() => {
          handleHideNotification();
        }, notification.timeout);
      }
      return () => {
        if (timer) {
          clearTimeout(timer);
        }
      };
    }
  }, [notification]);

  return (
    <Portal>
      <div
        aria-live="assertive"
        className="z-notification fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
      >
        <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
          {notification && (
            <div
              className={classNames(
                "max-w-sm w-full bg-white shadow-lg rounded-lg border-l border-l-4 pointer-events-auto overflow-hidden",
                notification.type === NotificationType.error &&
                  "border-l-rose-500",
                notification.type === NotificationType.success &&
                  "border-l-emerald-500"
              )}
              aria-label={`Notification ${notification.type}`}
            >
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    {notification.type === "success" ? (
                      <CheckCircleIcon className="h-6 w-6 text-emerald-600" />
                    ) : (
                      <XCircleIcon className="h-6 w-6 text-rose-600" />
                    )}
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <div className="text-sm font-medium text-gray-800">
                      {notification?.title || "An error occurred."}
                    </div>
                    <div className="mt-1 text-sm text-gray-500">
                      {notification?.description}
                    </div>
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      className="bg-white pt-0.5 rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onClick={handleHideNotification}
                      aria-label="Notification close button"
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-5 w-5" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Portal>
  );
}
