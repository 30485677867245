import { gql } from "@apollo/client";

export default gql`
  mutation ExportComplianceProgramToCsvMutation($complianceProgramId: Int!) {
    exportComplianceProgramToCsv(complianceProgramId: $complianceProgramId) {
      complianceProgram {
        id
      }
    }
  }
`;
