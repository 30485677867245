import { useCallback } from "react";

import { currentUserVar } from "@cache/cache";
import removeCommentFromCache from "@cache/remove-comment";
import usePusher from "@components/use-pusher/use-pusher";

export default function PusherCommentDeleted({
  channelName,
}: {
  channelName: string;
}) {
  const currentUser = currentUserVar();

  const handleCommentDeleted = useCallback(
    ({
      comment,
      topic,
      artifact,
    }: {
      comment: { id: number };
      artifact?: { id: number };
      topic?: { id: number };
    }) => {
      // topicId and artifactId need to be undefined rather than null, because otherwise
      // they won't match the key in the cache properly
      removeCommentFromCache({
        commentId: comment.id,
        topicId: topic?.id || undefined,
        artifactId: artifact?.id || undefined,
      });
    },
    [currentUser]
  );
  usePusher(channelName, "comment_deleted", handleCommentDeleted);

  return null;
}
