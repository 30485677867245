import { gql } from "@apollo/client";

export default gql`
  mutation updateGoalParticipantsMutation(
    $artifactId: Int!
    $additionalFields: AdditionalArtifactInput!
  ) {
    createOrUpdateArtifact(
      artifactId: $artifactId
      additionalFields: $additionalFields
    ) {
      artifact {
        ... on GoalArtifactNode {
          id
          ownerCount
          contributorCount
          canRead {
            permission
          }
          keyResults {
            edges {
              node {
                id
                assignee {
                  id
                }
              }
            }
          }
          firstOwner {
            id
            avatar
            name
          }
          owners {
            totalCount
            edges {
              node {
                id
                name
                avatar
              }
            }
          }
          contributors {
            totalCount
            edges {
              node {
                id
                name
                avatar
              }
            }
          }
        }
      }
    }
  }
`;
