import { gql } from "@apollo/client";

import CommonRecognitionArtifactFragment from "@graphql/common-recognition-artifact-fragment";

export default gql`
  ${CommonRecognitionArtifactFragment}
  query getNewDashboardRecognitionsQuery(
    $asUser: Int
    $recognitionRecipient: Int
    $createdBetweenDates: [Date]
    $createdBy: Int
    $fetchData: Boolean!
    $after: String
    $first: Int!
  ) {
    artifacts(
      artifactType: recognition
      recognitionRecipient: $recognitionRecipient
      createdBy: $createdBy
      createdBetweenDates: $createdBetweenDates
      asUser: $asUser
      orderBy: "-created"
      first: $first
      after: $after
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      totalCount
      edges @include(if: $fetchData) {
        node {
          id
          ... on RecognitionArtifactNode {
            artifactType
            title
            created
            updated
            creator {
              id
              name
              avatar
            }
            canDelete {
              permission
            }
            canUpdate {
              permission
            }
            ...CommonRecognitionArtifactFragment
          }
        }
      }
    }
  }
`;
