import { useMutation, useQuery } from "@apollo/client";
import { SwitchHorizontalIcon, XIcon } from "@heroicons/react/outline";
import { uniq } from "lodash";
import { useRef } from "react";
import {
  GetGoalParticipantsQueryQuery,
  GetGoalParticipantsQueryQueryVariables,
  GoalArtifactNode,
} from "types/graphql-schema";

import useLabel from "@apps/use-label/use-label";
import Avatar from "@components/avatar/avatar";
import Loading from "@components/loading/loading";
import Modal from "@components/modal/modal";
import ModalTitle from "@components/modal/modal-title";
import InfoTooltip from "@components/tooltip/info-tooltip";
import Tooltip from "@components/tooltip/tooltip";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { UserComboboxOption } from "@components/user-combobox/user-combobox-list";
import { assertEdgesNonNull } from "@helpers/helpers";

import getGoalParticipantsQuery from "../graphql/get-goal-participants-query";
import updateGoalParticipantsMutation from "../graphql/update-goal-participants-mutation";
import GoalParticipantsUserPicker from "./goal-participants-add-user-picker";

const GoalParticipant = ({
  artifact,
  participant,
  switchToRole,
  canRemove,
  onClickRemove,
  onClickSwitchRole,
}: {
  artifact: GoalArtifactNode;
  participant: any;
  switchToRole: string;
  canRemove: boolean;
  onClickRemove: (id: number) => void;
  onClickSwitchRole: (id: number) => void;
}) => {
  const label = useLabel();

  const handleClickRemove = () => {
    const hasKRAssigned = artifact?.keyResults?.edges.find(
      (edge) => edge?.node?.assignee?.id === participant.id
    );
    if (hasKRAssigned) {
      if (
        window.confirm(
          `Are you sure you want to remove ${
            participant.name
          }. They have ${label("key result", {
            pluralize: true,
          })} assigned to them. If yes, they'll be unassigned from the ${label(
            "key result",
            { pluralize: true }
          )}.`
        )
      )
        onClickRemove(participant.id);
    } else {
      onClickRemove(participant.id);
    }
  };

  const handleClickSwitch = () => {
    onClickSwitchRole(participant.id);
  };

  return (
    <div
      className="py-2 px-1 flex gap-2 justify-between items-center text-sm hover:bg-gray-50"
      key={participant.id}
    >
      <div className="flex items-center gap-2 truncate flex-1">
        <Avatar user={participant} size="5" />
        <div className="flex-1 truncate">{participant.name}</div>
      </div>
      {canRemove && (
        <Tooltip text={`Move to ${switchToRole}`}>
          <button
            className="text-gray-500 p-0.5 bg-gray-100 rounded-lg hover:bg-gray-300 hover:text-gray-700"
            onClick={handleClickSwitch}
            aria-label="Switch participant button"
          >
            <SwitchHorizontalIcon className="h-5 w-5" />
          </button>
        </Tooltip>
      )}
      {canRemove && (
        <Tooltip text={`Remove ${participant.name}`}>
          <button
            className="text-gray-500 p-0.5 bg-gray-100 rounded-lg hover:bg-gray-300 hover:text-gray-700"
            onClick={handleClickRemove}
            aria-label="Remove participant button"
          >
            <XIcon className="h-5 w-5" />
          </button>
        </Tooltip>
      )}
    </div>
  );
};

const GoalParticipantsDialog = ({
  artifact,
  onClose,
}: {
  artifact: any;
  onClose?: () => void;
}) => {
  const focusRef = useRef<HTMLDivElement | null>(null);
  const { data, loading } = useQuery<
    GetGoalParticipantsQueryQuery,
    GetGoalParticipantsQueryQueryVariables
  >(getGoalParticipantsQuery, {
    variables: { artifactId: artifact.id },
    onError: onNotificationErrorHandler(),
  });
  const owners =
    data?.artifact && data.artifact.__typename === "GoalArtifactNode"
      ? assertEdgesNonNull(data.artifact.owners)
      : [];
  const ownerIds = owners.map(({ id }) => id);
  const contributors =
    data?.artifact && data.artifact.__typename === "GoalArtifactNode"
      ? assertEdgesNonNull(data.artifact.contributors)
      : [];
  const contributorIds = contributors.map(({ id }) => id);
  const excludeIds = ownerIds.concat(contributorIds);

  const [updateArtifactOwners, { loading: loadingOwners }] = useMutation(
    updateGoalParticipantsMutation
  );
  const [updateArtifactContributors, { loading: loadingContributors }] =
    useMutation(updateGoalParticipantsMutation);

  const updateOwners = (newOwnerIds: number[]) => {
    updateArtifactOwners({
      variables: {
        artifactId: artifact.id,
        additionalFields: { ownerIds: newOwnerIds },
      },
      refetchQueries: [getGoalParticipantsQuery],
      onError: onNotificationErrorHandler(),
    });
  };

  const updateContributors = (newContributorIds: number[]) => {
    updateArtifactContributors({
      variables: {
        artifactId: artifact.id,
        additionalFields: { contributorIds: newContributorIds },
      },
      refetchQueries: [getGoalParticipantsQuery],
      onError: onNotificationErrorHandler(),
    });
  };

  const switchOwnersAndContributors = (
    newOwnerIds: number[],
    newContributorIds: number[]
  ) => {
    updateArtifactContributors({
      variables: {
        artifactId: artifact.id,
        additionalFields: {
          contributorIds: newContributorIds,
          ownerIds: newOwnerIds,
        },
      },
      refetchQueries: [getGoalParticipantsQuery],
      onError: onNotificationErrorHandler(),
    });
  };

  const handleAddOwner = (option: UserComboboxOption | null) => {
    if (option?.id) updateOwners(uniq(ownerIds.concat(option.id)));
  };

  const handleAddContributor = (option: UserComboboxOption | null) => {
    if (option?.id) updateContributors(uniq(contributorIds.concat(option.id)));
  };

  const handleRemoveOwner = (ownerId: number) => {
    updateOwners(ownerIds.filter((id) => ownerId !== id));
  };

  const handleRemoveContributor = (contributorId: number) => {
    updateContributors(contributorIds.filter((id) => contributorId !== id));
  };

  const handleSwitchToOwner = (contributorId: number) => {
    const newContributorIds = contributorIds.filter(
      (id) => contributorId !== id
    );
    const newOwnerIds = uniq(ownerIds.concat(contributorId));
    switchOwnersAndContributors(newOwnerIds, newContributorIds);
  };

  const handleSwitchToContributor = (ownerId: number) => {
    const newOwnerIds = ownerIds.filter((id) => ownerId !== id);
    const newContributorIds = uniq(contributorIds.concat(ownerId));
    switchOwnersAndContributors(newOwnerIds, newContributorIds);
  };

  if (
    (!data?.artifact && !loading) ||
    (data?.artifact &&
      data.artifact.__typename === "GoalArtifactNode" &&
      !data.artifact.canRead?.permission)
  ) {
    return null;
  }

  return (
    <Modal
      open
      onClose={onClose}
      initialFocus={focusRef}
      aria-label="Goal participant dialog"
    >
      <div className="p-6 bg-white rounded-md flex flex-col w-full gap-6">
        <ModalTitle onClose={onClose}>{artifact.title}</ModalTitle>
        <div>
          {loading ? (
            <Loading>Loading owners and contributors</Loading>
          ) : (
            <div className="grid grid-cols-2 gap-8">
              <div className="flex flex-col gap-2">
                <div className="font-medium text-sm flex items-center gap-1">
                  Owners ({owners.length})
                  <InfoTooltip text="Owners are individuals that are accountable for achieving the goal. In most cases there should be only one owner." />
                </div>
                <div className="divide-y border-t border-b">
                  {owners.map((owner) => (
                    <GoalParticipant
                      artifact={artifact}
                      participant={owner}
                      key={owner.id}
                      onClickRemove={handleRemoveOwner}
                      onClickSwitchRole={handleSwitchToContributor}
                      switchToRole="contributor"
                      canRemove={
                        artifact.canUpdate.permission && owners.length > 1
                      }
                    />
                  ))}
                </div>
                {loadingOwners ? (
                  <Loading mini size="5" />
                ) : artifact.canUpdate.permission ? (
                  <div className="px-1">
                    <GoalParticipantsUserPicker
                      goal={artifact}
                      excludeIds={excludeIds}
                      onSelectUser={handleAddOwner}
                      buttonText="Add an owner"
                      canChange
                    />
                  </div>
                ) : null}
              </div>

              <div className="flex flex-col gap-2">
                <div className="font-medium text-sm flex items-center gap-1">
                  Contributors ({contributors.length})
                  <InfoTooltip text="Contributors are individuals that contribute to achieving the goal but are not accountable for its achievement." />
                </div>
                <div className="divide-y border-t border-b">
                  {contributors.map((contributor) => (
                    <GoalParticipant
                      artifact={artifact}
                      participant={contributor}
                      key={contributor.id}
                      onClickRemove={handleRemoveContributor}
                      onClickSwitchRole={handleSwitchToOwner}
                      switchToRole="owner"
                      canRemove={artifact.canUpdate.permission}
                    />
                  ))}
                </div>
                {loadingContributors ? (
                  <Loading mini size="5" />
                ) : artifact.canUpdate.permission ? (
                  <div className="px-1">
                    <GoalParticipantsUserPicker
                      goal={artifact}
                      excludeIds={excludeIds}
                      onSelectUser={handleAddContributor}
                      buttonText="Add a contributor"
                      canChange
                    />
                  </div>
                ) : null}
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default GoalParticipantsDialog;
