import { gql } from "@apollo/client";

export default gql`
  mutation updateTopicAssigneeMutation($topicId: Int, $assignee: Int) {
    createOrUpdateTopic(topicId: $topicId, assignee: $assignee) {
      topic {
        id
        assignee {
          id
          name
          avatar
        }
      }
    }
  }
`;
