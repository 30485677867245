import { gql } from "@apollo/client";

export default gql`
  mutation removeFavouriteArtifactMutation($artifactId: Int!) {
    removeFavouriteArtifact(artifactId: $artifactId) {
      artifact {
        id
      }
      user {
        id
        favouriteArtifacts {
          edges {
            node {
              id
            }
          }
        }
      }
    }
  }
`;
