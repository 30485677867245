import { FeedbackState } from "types/graphql-schema";

import { classNames } from "@helpers/css";

import AppLabel from "./app-label";
import DraftLabel from "./draft-label";

const FeedbackStateLabel = ({
  feedbackState,
  className = "",
}: {
  feedbackState: FeedbackState;
  className?: string;
}) => {
  if (feedbackState === FeedbackState.Draft)
    return <DraftLabel className={className} />;
  if (feedbackState === FeedbackState.Requested)
    return (
      <AppLabel
        label="requested"
        className={classNames("bg-amber-200 text-amber-700", className)}
      />
    );
  if (feedbackState === FeedbackState.Sent)
    return (
      <AppLabel
        label="sent"
        className={classNames("bg-emerald-100 text-emerald-700", className)}
      />
    );
};

export default FeedbackStateLabel;
