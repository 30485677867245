import Report1on1ByManager from "@apps/reporting/components/oneonone-report/1-on-1-by-manager";
import { currentOrganizationVar } from "@cache/cache";

const OneononeReport = () => {
  const organization = currentOrganizationVar();

  return (
    <div className="flex-1 bg-white px-6 pb-6 rounded-lg">
      <Report1on1ByManager organization={organization} />
    </div>
  );
};

export default OneononeReport;
