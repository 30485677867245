import moment from "moment";
import { BasicUser } from "types/topicflow";

import Avatar from "@components/avatar/avatar";

const WorkHistorySummaryHeader = ({
  workHistoryItem,
}: {
  workHistoryItem: {
    summaryStartDate: string;
    summaryEndDate: string;
    created: string;
    subject?: BasicUser | null;
  };
}) => {
  return (
    <div className="px-8 py-4 border-b">
      <div className="flex items-center">
        <Avatar user={workHistoryItem.subject} />
        <div className="ml-4">
          <div className="font-bold text-lg">
            {workHistoryItem.subject?.name}
          </div>
          <div className="text-xs text-gray-500">{`${moment(
            workHistoryItem.summaryStartDate
          ).format("MMM D, YYYY")} - ${moment(
            workHistoryItem.summaryEndDate
          ).format("MMM D, YYYY")}`}</div>
        </div>
      </div>
    </div>
  );
};

export default WorkHistorySummaryHeader;
