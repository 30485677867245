import { useQuery } from "@apollo/client";
import { useCallback, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  GetAssessmentsToDeliverQuery,
  GetAssessmentsToDeliverQueryVariables,
} from "types/graphql-schema";

import getAssessmentsToDeliver from "@apps/assessments/graphql/get-assessments-to-deliver";
import useLabel from "@apps/use-label/use-label";
import { currentOrganizationVar, currentUserVar } from "@cache/cache";
import Avatar from "@components/avatar/avatar";
import Button, { buttonTheme } from "@components/button/button";
import Loading from "@components/loading/loading";
import {
  Table,
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableContainer,
  TableFooter,
  TableHeadCell,
  TableHeadRow,
  TableSortDir,
} from "@components/table/table";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { assessmentTypeLabels } from "@helpers/constants";
import { assertEdgesNonNull, assertNonNull } from "@helpers/helpers";

enum SortKey {
  targetName = "name",
  program = "compliance_program__title",
  assessmentType = "group__assessment_type",
}

const MyAssessmentsToDeliver = ({
  userFilter,
}: {
  userFilter: number | null;
}) => {
  const location = useLocation();
  const label = useLabel();
  const currentUser = assertNonNull(currentUserVar());
  const organization = currentOrganizationVar();
  const [sortKey, setSortKey] = useState<SortKey | "id">("id");
  const [sortDir, setSortDir] = useState<TableSortDir>("desc");

  const { data, loading, fetchMore } = useQuery<
    GetAssessmentsToDeliverQuery,
    GetAssessmentsToDeliverQueryVariables
  >(getAssessmentsToDeliver, {
    variables: {
      organizationId: organization.id,
      currentUserId: currentUser.id,
      orderBy: `${sortDir === "desc" ? "-" : ""}${sortKey}`,
    },
    onError: onNotificationErrorHandler(),
  });

  const toDeliver = useMemo(
    () =>
      data
        ? assertEdgesNonNull(data.assessmentDeliveries).filter(
            (assessmentDelivery) =>
              userFilter === null ||
              assessmentDelivery.target?.id === userFilter
          )
        : [],
    [data, userFilter]
  );

  const handleLoadMore = () => {
    if (data?.assessmentDeliveries.pageInfo.hasNextPage) {
      fetchMore({
        variables: {
          merge: true,
          after: data.assessmentDeliveries.pageInfo.endCursor,
        },
      });
    }
  };

  const handleColumnSort = useCallback(
    (newSortKey: SortKey) => {
      if (newSortKey !== sortKey) {
        setSortKey(newSortKey);
      } else {
        setSortDir(sortDir === "asc" ? "desc" : "asc");
      }
    },
    [sortDir, sortKey]
  );

  if (loading) {
    return <Loading className="p-6 w-full mx-auto">Loading</Loading>;
  }

  return (
    <>
      <TableContainer aria-label="Previous assessments">
        <Table>
          <TableHeadRow>
            <TableHeadCell
              sorted={sortKey === SortKey.targetName ? sortDir : undefined}
              onClick={() => handleColumnSort(SortKey.targetName)}
            >
              Subject
            </TableHeadCell>
            <TableHeadCell
              sorted={sortKey === SortKey.program ? sortDir : undefined}
              onClick={() => handleColumnSort(SortKey.program)}
            >
              Program
            </TableHeadCell>
            <TableHeadCell
              sorted={sortKey === SortKey.assessmentType ? sortDir : undefined}
              onClick={() => handleColumnSort(SortKey.assessmentType)}
            >
              {`${label("review", { capitalize: true })} type`}
            </TableHeadCell>
            <TableHeadCell className="w-52"></TableHeadCell>
          </TableHeadRow>
          <TableBody>
            {!loading && toDeliver.length === 0 && (
              <TableBodyRow>
                <TableBodyCell colSpan={3}>
                  {`No ${label("review", { pluralize: true })} to deliver.`}
                </TableBodyCell>
              </TableBodyRow>
            )}
            {toDeliver.map((assessmentDelivery) => {
              const target = assertNonNull(assessmentDelivery.target);
              return (
                <TableBodyRow key={assessmentDelivery.id}>
                  <TableBodyCell>
                    <div className="flex items-center">
                      <Avatar className="mr-2" size="8" user={target} />{" "}
                      {target.name}
                    </div>
                  </TableBodyCell>
                  <TableBodyCell>
                    {assessmentDelivery.complianceProgram?.title}
                  </TableBodyCell>
                  <TableBodyCell>
                    {
                      assessmentTypeLabels[
                        assessmentDelivery.group.assessmentType
                      ]
                    }
                  </TableBodyCell>
                  <TableBodyCell className="text-right">
                    <Button
                      theme={buttonTheme.lightBlue}
                      to={{
                        pathname: `/assessments/assessment/delivery/${assessmentDelivery.id}`,
                        state: {
                          previousPathname: `${location.pathname}${location.search}`,
                        },
                      }}
                    >
                      Review and deliver
                    </Button>
                  </TableBodyCell>
                </TableBodyRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <TableFooter>
        {data?.assessmentDeliveries.pageInfo.hasNextPage && (
          <div className="flex justify-center">
            {loading ? (
              <Loading mini size="5" />
            ) : (
              <button
                onClick={handleLoadMore}
                className="text-gray-500 hover:underline"
              >
                View more
              </button>
            )}
          </div>
        )}
      </TableFooter>
    </>
  );
};

export default MyAssessmentsToDeliver;
