import { compact } from "lodash";

import { getLabel } from "@apps/use-label/use-label";
import { currentOrganizationVar } from "@cache/cache";

export const meetingPaddingClassName = "px-6";

const currentMeetingOption = {
  value: "current-meeting-group",
  label: "in this meeting series",
};
const allMeetingsOption = {
  value: "all-meetings",
  label: "all meetings",
};
export const searchContextOptions = [currentMeetingOption, allMeetingsOption];

export type MeetingOrTemplateVisibility = {
  allowAdminVisibility: boolean;
  allowOrgVisibility: boolean;
  allowManagementTreeVisibility: boolean;
};

export const getMeetingAllowVisibilityLabel = (
  {
    allowAdminVisibility,
    allowOrgVisibility,
    allowManagementTreeVisibility,
  }: MeetingOrTemplateVisibility,
  shortVersion = false
): string => {
  const label = getLabel();
  const orgLabel = label("organization");
  const currentOrganization = currentOrganizationVar();
  if (allowOrgVisibility)
    return shortVersion
      ? `${orgLabel} members`
      : `all ${currentOrganization.name} members`;
  const parts = shortVersion
    ? compact([
        "participants",
        allowManagementTreeVisibility && "management tree",
        allowAdminVisibility && `admins`,
      ])
    : compact([
        "meeting participants",
        allowManagementTreeVisibility && "participants' management tree",
        allowAdminVisibility && `${currentOrganization.name} admins`,
      ]);
  if (parts.length === 1) {
    return parts.join("") + " only";
  }
  const last = parts.pop();
  return parts.join(", ") + " and " + last;
};
