import { useMutation } from "@apollo/client";
import { SyncCredentialsTypeEnum } from "types/graphql-schema";

import { errorNotificationVar } from "@cache/cache";
import Button from "@components/button/button";
import ProviderImage from "@components/provider-image/provider-image";
import { onNotificationErrorHandler } from "@components/use-error/use-error";

import linkCalendarToOrganizationOnboardingMutation from "../../graphql/link-calendar-to-organization-mutation";

const CalendarUseCTA = ({
  provider,
  syncCredentialId,
  email,
  organizationId,
  organizationName,
}: {
  provider?: SyncCredentialsTypeEnum | null;
  syncCredentialId: number;
  email: string;
  organizationId: number;
  organizationName: string;
}) => {
  const [linkCalendarToOrganization, { loading: linkLoading }] = useMutation(
    linkCalendarToOrganizationOnboardingMutation,
    {
      onError: () => {
        errorNotificationVar({
          title: `We could not use this calendar in the ${organizationName} organization.`,
        });
      },
    }
  );

  const handleClickUseThisCalendar = () => {
    linkCalendarToOrganization({
      variables: {
        organizationId,
        credentialsId: syncCredentialId,
      },
      onError: onNotificationErrorHandler(),
    });
  };

  return (
    <div
      className="w-full flex items-center justify-between mt-4 border border-gray-300 rounded-md p-4 text-lg font-medium text-gray-700"
      aria-label="Calendar connection container"
    >
      <div className="flex items-center gap-4">
        <ProviderImage provider={provider} size="6" />
        {email}
      </div>
      <Button
        theme="primary"
        disabled={linkLoading}
        onClick={handleClickUseThisCalendar}
        text="Use this calendar"
      />
    </div>
  );
};

export default CalendarUseCTA;
