import { gql } from "@apollo/client";

export default gql`
  mutation updateTopicSharedOrIndividualNotesMutation(
    $topicId: Int
    $includesIndividualNotes: Boolean
    $includesSharedNotes: Boolean
  ) {
    createOrUpdateTopic(
      topicId: $topicId
      includesIndividualNotes: $includesIndividualNotes
      includesSharedNotes: $includesSharedNotes
    ) {
      topic {
        id
        includesIndividualNotes
        includesSharedNotes
      }
    }
  }
`;
