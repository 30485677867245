import { groupBy } from "lodash";
import { MeetingTranscriptFragmentFragment } from "types/graphql-schema";
import { MeetingViewMeetingNodeNewPageFragmentFragment } from "types/graphql-schema";

import useLabel from "@apps/use-label/use-label";
import { assertEdgesNonNull } from "@helpers/helpers";

import MeetingSummaryGroupTranscriptionSuggestedArtifact from "../prep/summary-group-transcription-suggested-artifact";
import MeetingSidebarAIContainer from "./ai-container";

const AIAssistSuggestions = ({
  meeting,
}: {
  meeting: MeetingTranscriptFragmentFragment;
}) => {
  const label = useLabel();
  const suggestedArtifacts = (
    meeting.transcript?.suggestedArtifacts
      ? assertEdgesNonNull(meeting.transcript.suggestedArtifacts)
      : []
  ).filter(({ hidden }: { hidden: boolean }) => !hidden);

  const suggestedArtifactsGroupByType = groupBy(
    suggestedArtifacts,
    "artifactType"
  );

  return (
    <div className="flex flex-col gap-4 text-sm">
      {Object.keys(suggestedArtifactsGroupByType).map((artifactType) => (
        <div key={artifactType} className="mb-3">
          <div className="font-medium mb-1 text-gray-700">
            Suggested {label(artifactType, { pluralize: true })}
          </div>
          <div className="text-gray-500 flex flex-col w-full border divide-y divide-gray-200 bg-white empty:hidden border-gray-200 rounded-lg">
            {suggestedArtifactsGroupByType[artifactType].map(
              (suggestedArtifact) => (
                <div key={suggestedArtifact.id} className="px-3 py-2">
                  <MeetingSummaryGroupTranscriptionSuggestedArtifact
                    meeting={meeting}
                    suggestedArtifact={suggestedArtifact}
                  />
                </div>
              )
            )}
          </div>
        </div>
      ))}

      {suggestedArtifacts.length === 0 && (
        <div className="text-gray-500">No suggestions.</div>
      )}
    </div>
  );
};

const MeetingSidebarAISugestions = ({
  meeting,
}: {
  meeting: MeetingViewMeetingNodeNewPageFragmentFragment;
}) => {
  return (
    <MeetingSidebarAIContainer meeting={meeting}>
      {({ meeting: meetingWithTranscript }) => (
        <AIAssistSuggestions meeting={meetingWithTranscript} />
      )}
    </MeetingSidebarAIContainer>
  );
};

export default MeetingSidebarAISugestions;
