import { BookmarkIcon, UserCircleIcon } from "@heroicons/react/outline";
import githubLogo from "@static/img/github.png";
import hubspotLogo from "@static/img/hubspot.png";
import jiraLogo from "@static/img/jira-icon.png";
import { ArtifactType } from "types/graphql-schema";

import ArtifactIcon from "@apps/artifact/components/artifact-icon";
import { getAssetUrl } from "@helpers/helpers";

import {
  ExplorerTypeType,
  explorerGithubType,
  explorerHubspotType,
  explorerJiraType,
  explorerUserType,
  testIsAllowedArtifactType,
} from "../helpers";

const ExplorerBookmarkIcon = ({
  type,
}: {
  type: ExplorerTypeType | "search";
}) => {
  const className = "h-4 w-4 text-gray-500";
  return type && type !== "search" && testIsAllowedArtifactType(type) ? (
    <ArtifactIcon
      isStatic
      artifact={{ artifactType: type as ArtifactType }}
      size="4"
    />
  ) : type === explorerUserType ? (
    <UserCircleIcon className="h-4 w-4 text-gray-500" />
  ) : type === explorerGithubType ? (
    <img src={getAssetUrl(githubLogo)} alt="GitHub" className={className} />
  ) : type === explorerJiraType ? (
    <img src={getAssetUrl(jiraLogo)} alt="Jira" className={className} />
  ) : type === explorerHubspotType ? (
    <img
      src={getAssetUrl(hubspotLogo)}
      alt="Hubspot"
      className="block h-4 w-4"
    />
  ) : (
    <BookmarkIcon className={className} />
  );
};

export default ExplorerBookmarkIcon;
