import { gql } from "@apollo/client";

import { AssessmentNominationFragment } from "@apps/assessments/graphql/get-assessment-nominations-query";

export default gql`
  fragment UserActivityComplianceGroupAssessmentDelivery on AssessmentDeliveryNode {
    id
    state
    deliveryDatetime
    lastSubmissionDatetime
    target {
      id
      name
      email
      firstName
      avatar
    }
    complianceProgram {
      id
      title
    }
    group {
      id
      title
      assessmentType
    }
  }
  fragment UserActivityComplianceGroupAssessmentGroup on AssessmentGroupNode {
    id
    title
    assessmentType
    hasSelfAssessment
    assessments(responderId: $responderId) {
      edges {
        node {
          id
          state
          target {
            id
            name
            avatar
          }
        }
      }
    }
  }
  fragment UserActivityComplianceProgramMeeting on MeetingNode {
    id
    title
    meetingGroupId
    isFinalized
    startDatetime
    meetingGroup {
      id
      topicTemplate {
        id
      }
    }
    participants {
      edges {
        node {
          id
          status
          user {
            id
            name
            avatar
            email
          }
        }
      }
    }
  }
  ${AssessmentNominationFragment}
  query getUserActivityInComplianceProgram(
    $complianceProgramId: Int!
    $responderId: Int!
  ) {
    assessmentsOpenForNominations(complianceProgramId: $complianceProgramId) {
      edges {
        node {
          ...AssessmentNomination
        }
      }
    }
    assessmentDeliveries(complianceProgramId: $complianceProgramId) {
      edges {
        node {
          ...UserActivityComplianceGroupAssessmentDelivery
        }
      }
    }
    complianceProgram(pk: $complianceProgramId) {
      id
      title
      dueDate
      startDate
      state
      canUpdate {
        permission
        reason
      }
      requiredTopicTemplates {
        edges {
          node {
            id
            title
          }
        }
      }
      matchingOneonones(participants: [$responderId]) {
        edges {
          node {
            ...UserActivityComplianceProgramMeeting
          }
        }
      }
      usersMissingAssessment {
        edges {
          node {
            responder {
              id
              name
              avatar
            }
            target {
              id
              name
              avatar
            }
            assessmentGroup {
              id
            }
          }
        }
      }
      performanceAssessmentGroup {
        ...UserActivityComplianceGroupAssessmentGroup
      }
      managerAssessmentGroup {
        ...UserActivityComplianceGroupAssessmentGroup
      }
      peerAssessmentGroup {
        ...UserActivityComplianceGroupAssessmentGroup
      }
      assessments(responderId: $responderId) {
        edges {
          node {
            id
            state
            target {
              id
              name
              avatar
            }
            group {
              id
            }
          }
        }
      }
    }
  }
`;
