import { WebSocketStatus } from "@hocuspocus/provider";
import { useEffect, useState } from "react";

const WebsocketStatus = ({
  isRealtimeEditorSchemaUpToDate,
  isFocused,
  status,
}: {
  isRealtimeEditorSchemaUpToDate: boolean;
  isFocused: boolean;
  status?: WebSocketStatus;
}) => {
  const [debouncedStatus, setDebouncedStatus] =
    useState<WebSocketStatus | null>(null);
  useEffect(() => {
    const timer = setTimeout(() => setDebouncedStatus(status || null), 5000);
    return () => {
      clearTimeout(timer);
    };
  }, [status]);

  // readOnlyMode is defined in `vite.html`
  if (window.readOnlyMode) {
    return (
      <div className="bg-amber-100 text-xs text-amber-600 rounded-md my-1 py-1 px-2">
        <span className="font-medium">Topicflow is in read-only mode...</span>{" "}
        Your notes are being saved locally, and will be synchronized the next
        time you visit
      </div>
    );
  }

  if (isFocused && !isRealtimeEditorSchemaUpToDate) {
    return (
      <div className="bg-amber-100 text-xs text-amber-600 rounded-md my-1 py-1 px-2">
        <span className="font-medium">
          A new version of Topicflow is available.
        </span>{" "}
        This field has been disabled until you reload the page.
      </div>
    );
  }

  return isFocused && debouncedStatus === "connecting" ? (
    <div className="bg-amber-100 text-xs text-amber-600 rounded-md my-1 py-1 px-2">
      <span className="font-medium">Trying to connect...</span> Your notes are
      being saved locally, and will be synchronized once a connection is
      re-established.
    </div>
  ) : null;
};

export default WebsocketStatus;
