import { gql } from "@apollo/client";

export default gql`
  mutation setRelationshipMutationFromAccount(
    $organizationId: Int!
    $clearRelationship: Boolean
    $otherUserId: Int!
    $userId: Int
    $relationshipType: RelationshipType = managed_by
  ) {
    setRelationship(
      organizationId: $organizationId
      relationshipType: $relationshipType
      clearRelationship: $clearRelationship
      otherUserId: $otherUserId
      userId: $userId
    ) {
      me {
        id
        relationships {
          edges {
            node {
              id
              organization {
                id
              }
              relationshipWithOtherUser
              otherUser {
                id
              }
            }
          }
        }
        managers {
          edges {
            node {
              id
              name
              avatar
            }
          }
        }
      }
    }
  }
`;
