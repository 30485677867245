import { useMutation } from "@apollo/client";
import { compact, uniqBy } from "lodash";
import { useEffect, useState } from "react";
import {
  DashboardPeopleTableUserFragmentFragment,
  RelationshipType,
} from "types/graphql-schema";

import {
  currentOrganizationVar,
  currentUserVar,
  errorNotificationVar,
} from "@cache/cache";
import Button, { buttonTheme } from "@components/button/button";
import Modal from "@components/modal/modal";
import ModalTitle from "@components/modal/modal-title";
import { useComponentError } from "@components/use-error/use-error";

import getPeopleTableQuery from "../graphql/get-people-table-query";
import setCurrentUserRelationshipsMutation from "../graphql/set-current-user-relationships-mutation";
import DashboardTeamSettingsCombobox from "./team-settings-combobox";

const isNotAnInvite = (id: number) => id > 0;

const DashboardTeamSettingsAddButton = ({
  managers,
  reports,
  favouriteUsers,
  onClose,
}: {
  managers: (DashboardPeopleTableUserFragmentFragment & {
    invited?: boolean;
  })[];
  reports: (DashboardPeopleTableUserFragmentFragment & {
    invited?: boolean;
  })[];
  favouriteUsers: (DashboardPeopleTableUserFragmentFragment & {
    invited?: boolean;
  })[];
  onClose: () => void;
}) => {
  const currentUser = currentUserVar();
  const currentOrganization = currentOrganizationVar();
  const { onError } = useComponentError();

  const [localManagers, setLocalManagers] = useState(managers);
  const [localReports, setLocalReports] = useState(reports);
  const [localFavorites, setLocalFavorites] = useState(favouriteUsers);
  const excludeUserIdsWithoutFavorite = compact([
    currentUser,
    ...localManagers,
    ...localReports,
  ])
    .map(({ id }) => id)
    .filter(isNotAnInvite); // filter our invite email values
  const localFavoriteIds = localFavorites
    .map(({ id }) => id)
    .filter(isNotAnInvite); // filter our invite email values
  const excludeUserIdsWithFavourite =
    excludeUserIdsWithoutFavorite.concat(localFavoriteIds);

  const [setCurrentUserRelationships, { loading }] = useMutation(
    setCurrentUserRelationshipsMutation
  );

  const removeUserIdFromLocalFavorites = (idToRemove: number) => {
    setLocalFavorites(localFavorites.filter(({ id }) => id !== idToRemove));
  };

  const handleChangeManager = (oldManager: any) => (newManager: any) => {
    if (!oldManager) {
      setLocalManagers(uniqBy(localManagers.concat(newManager), "id"));
    } else if (!newManager) {
      setLocalManagers(localManagers.filter(({ id }) => id !== oldManager.id));
    } else {
      const i = localManagers.findIndex(({ id }) => id === oldManager.id);
      const newLocalManagers = [...localManagers];
      newLocalManagers[i] = newManager;
      setLocalManagers(uniqBy(newLocalManagers, "id"));
    }
    if (newManager) {
      removeUserIdFromLocalFavorites(newManager.id);
    }
  };

  const handleChangeReport = (oldReport: any) => (newReport: any) => {
    if (!oldReport) {
      setLocalReports(uniqBy(localReports.concat(newReport), "id"));
    } else if (!newReport) {
      setLocalReports(localReports.filter(({ id }) => id !== oldReport.id));
    } else {
      const i = localReports.findIndex(({ id }) => id === oldReport.id);
      const newLocalReports = [...localReports];
      newLocalReports[i] = newReport;
      setLocalReports(uniqBy(newLocalReports, "id"));
    }
    if (newReport) {
      removeUserIdFromLocalFavorites(newReport.id);
    }
  };

  const handleChangeFavorite = (oldFavorite: any) => (newFavorite: any) => {
    if (!oldFavorite) {
      setLocalFavorites(uniqBy(localFavorites.concat(newFavorite), "id"));
    } else if (!newFavorite) {
      setLocalFavorites(
        localFavorites.filter(({ id }) => id !== oldFavorite.id)
      );
    } else {
      const i = localFavorites.findIndex(({ id }) => id === oldFavorite.id);
      const newLocalFavorites = [...localFavorites];
      newLocalFavorites[i] = newFavorite;
      setLocalFavorites(uniqBy(newLocalFavorites, "id"));
    }
  };

  const handleClickSave = (closePopover: () => void) => () => {
    const newRelationships = compact([
      ...localManagers
        .filter(({ id }) => id > 0)
        .map(({ id }) => ({
          otherUserId: id,
          relationshipType: RelationshipType.ManagedBy,
        })),
      ...localReports
        .filter(({ id }) => id > 0)
        .map(({ id }) => ({
          otherUserId: id,
          relationshipType: RelationshipType.Manages,
        })),
    ]);
    const newFavoriteIds = localFavorites
      .filter(({ id }) => id > 0)
      .map(({ id }) => [id]);
    const invitations = compact([
      ...localManagers
        .filter(({ id, invited }) => !isNotAnInvite(id) && invited)
        .map(({ email }) => ({
          inviteeEmail: email,
          inviteMessage: "",
          organizationId: currentOrganization.id,
          role: "member",
          relationships: [
            {
              otherUserId: currentUser.id,
              relationshipType: RelationshipType.Manages,
            },
          ],
        })),
      ...localReports
        .filter(({ id, invited }) => !isNotAnInvite(id) && invited)
        .map(({ email }) => ({
          inviteeEmail: email,
          inviteMessage: "",
          organizationId: currentOrganization.id,
          role: "member",
          relationships: [
            {
              otherUserId: currentUser.id,
              relationshipType: RelationshipType.ManagedBy,
            },
          ],
        })),
      ...localFavorites
        .filter(({ id, invited }) => !isNotAnInvite(id) && invited)
        .map(({ email }) => ({
          inviteeEmail: email,
          inviteMessage: "",
          organizationId: currentOrganization.id,
          role: "member",
          favourite: true,
        })),
    ]);
    setCurrentUserRelationships({
      variables: {
        organizationId: currentOrganization.id,
        userId: currentUser.id,
        relationships: newRelationships,
        favouriteUserIds: newFavoriteIds,
        invitations,
      },
      refetchQueries: [getPeopleTableQuery],
      onError,
      onCompleted: (data) => {
        const errorMessage =
          data.createInvitations.invitationErrors[0]?.message;
        if (errorMessage) {
          errorNotificationVar({
            title: errorMessage,
            timeout: 5000,
          });
        }
        closePopover();
      },
    });
  };

  useEffect(() => {
    setLocalManagers(managers);
    setLocalReports(reports);
    setLocalFavorites(favouriteUsers);
  }, [managers, reports, favouriteUsers]);

  // RENDER
  return (
    <Modal open onClose={onClose} dialogClassName="w-104">
      <div
        className="flex flex-col gap-6 p-4"
        aria-label="Dashboard relationship form popover"
      >
        <ModalTitle onClose={onClose}>My team</ModalTitle>
        <div
          className="flex justify-between w-full"
          aria-label="Dashboard relationship manager dropdown"
        >
          <div className="shrink-0 text-gray-500 uppercase pt-2.5 font-semibold text-xs tracking-wide">
            Manager
          </div>
          <div className="text-sm flex flex-col gap-2">
            {localManagers.map((manager) => (
              <DashboardTeamSettingsCombobox
                relationshipUser={manager}
                label="Manager"
                key={manager.id}
                excludeUserIds={excludeUserIdsWithoutFavorite}
                onChange={handleChangeManager(manager)}
              />
            ))}
            <DashboardTeamSettingsCombobox
              key={null}
              relationshipUser={null}
              label="Manager"
              excludeUserIds={excludeUserIdsWithoutFavorite}
              onChange={handleChangeManager(null)}
            />
          </div>
        </div>
        <div
          className="flex justify-between w-full"
          aria-label="Dashboard relationship report dropdown"
        >
          <div className="shrink-0 text-gray-500 uppercase pt-2.5 font-semibold text-xs tracking-wide">
            Direct reports
          </div>
          <div className="text-sm flex flex-col gap-2">
            {localReports.map((report) => (
              <DashboardTeamSettingsCombobox
                relationshipUser={report}
                key={report.id}
                excludeUserIds={excludeUserIdsWithoutFavorite}
                onChange={handleChangeReport(report)}
                label="Report"
              />
            ))}
            <DashboardTeamSettingsCombobox
              key={null}
              relationshipUser={null}
              excludeUserIds={excludeUserIdsWithoutFavorite}
              onChange={handleChangeReport(null)}
              label="Report"
            />
          </div>
        </div>
        <div
          className="flex justify-between w-full"
          aria-label="Dashboard relationship favorite dropdown"
        >
          <div className="shrink-0 text-gray-500 uppercase pt-2.5 font-semibold text-xs tracking-wide">
            Favorites
          </div>
          <div className="text-sm flex flex-col gap-2">
            {localFavorites.map((favorite) => (
              <DashboardTeamSettingsCombobox
                relationshipUser={favorite}
                key={favorite.id}
                excludeUserIds={excludeUserIdsWithFavourite}
                onChange={handleChangeFavorite(favorite)}
                label="Favorite"
              />
            ))}
            <DashboardTeamSettingsCombobox
              key={null}
              relationshipUser={null}
              excludeUserIds={excludeUserIdsWithFavourite}
              onChange={handleChangeFavorite(null)}
              label="Favorite"
            />
          </div>
        </div>
        <div className="grid grid-cols-2 border-t gap-4 pt-4">
          <Button text="Cancel" onClick={onClose} />
          <Button
            text="Save"
            disabled={loading}
            theme={buttonTheme.primary}
            onClick={handleClickSave(onClose)}
          />
        </div>
      </div>
    </Modal>
  );
};

export default DashboardTeamSettingsAddButton;
