import { ArrowNarrowRightIcon, EyeOffIcon } from "@heroicons/react/outline";
import moment from "moment";
import { useState } from "react";
import { LoggedInUserOrgFragment } from "types/graphql-schema.js";

import Avatar from "@components/avatar/avatar";
import AppLink from "@components/link/link";
import { TableBodyCell, TableBodyRow } from "@components/table/table";
import Tooltip from "@components/tooltip/tooltip";

import NudgeDialog from "./nudge-dialog.js";

const Report1on1ByManagerRow = ({
  tableRow,
  templateFilter,
  organization,
}: {
  tableRow: any;
  templateFilter: any;
  organization: LoggedInUserOrgFragment;
}) => {
  const [showNudgeDialog, setShowNudgeDialog] = useState(false);

  return (
    <TableBodyRow className="border-b text-sm">
      <TableBodyCell>
        <div className="text-gray-800 flex  gap-2 items-center">
          {!tableRow.isManager && (
            <ArrowNarrowRightIcon className="h-5 w-5 text-gray-400 mr-1" />
          )}
          <Avatar user={tableRow.report} size="5" className="flex-none" />
          <Tooltip text={tableRow.report.email}>
            <div className="font-medium">{tableRow.report.name}</div>
          </Tooltip>
        </div>
      </TableBodyCell>
      <TableBodyCell>
        <div className="text-sm  flex items-center">
          {tableRow.managers[0]?.name || ""}
        </div>
      </TableBodyCell>
      <TableBodyCell>
        <div className="text-sm  flex items-center">
          {tableRow.lastMeeting && (
            <div>
              <span>
                {moment(tableRow.lastMeeting.startDatetime).format("lll")}
              </span>
              {tableRow.lastMeeting.meeting ? (
                <AppLink
                  to={`/meeting/${tableRow.lastMeeting.meeting.meetingGroupId}/${tableRow.lastMeeting.id}`}
                  className="hover:underline text-blue-600 ml-2"
                >
                  {tableRow.lastMeeting.meeting.title}
                </AppLink>
              ) : (
                <Tooltip text="You do not have access to this meeting">
                  <span className="text-gray-500 ml-3">
                    <EyeOffIcon className="inline mr-1 text-gray-600 h-4 w-4" />
                    <span>Private meeting</span>
                  </span>
                </Tooltip>
              )}
            </div>
          )}
        </div>
      </TableBodyCell>
      <TableBodyCell>
        <div className="text-sm  flex items-center">
          {showNudgeDialog && (
            <NudgeDialog
              report={tableRow.report}
              manager={tableRow.managers[0]}
              organization={organization}
              templateFilter={templateFilter}
              onClose={() => setShowNudgeDialog(false)}
            />
          )}
          {tableRow.nextMeeting ? (
            <div>
              <span>
                {moment(tableRow.nextMeeting.startDatetime).format("lll")}
              </span>
              {tableRow.nextMeeting.meeting ? (
                <AppLink
                  to={`/meeting/${tableRow.nextMeeting.meeting.meetingGroupId}/${tableRow.nextMeeting.id}`}
                  className="hover:underline text-blue-600 ml-2"
                >
                  {tableRow.nextMeeting.meeting.title}
                </AppLink>
              ) : (
                <Tooltip text="You do not have access to this meeting">
                  <span className="text-gray-500 ml-3">
                    <EyeOffIcon className="inline mr-1 text-gray-600 h-4 w-4" />
                    <span>Private meeting</span>
                  </span>
                </Tooltip>
              )}
            </div>
          ) : (
            !tableRow.isManager && (
              <button
                onClick={() => setShowNudgeDialog(true)}
                className="text-blue-600 hover:underline"
              >
                Nudge
              </button>
            )
          )}
        </div>
      </TableBodyCell>
    </TableBodyRow>
  );
};

export default Report1on1ByManagerRow;
