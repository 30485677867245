import { makeVar } from "@apollo/client";
import { withErrorBoundary } from "@sentry/react";
import { compact } from "lodash";
import { useState } from "react";
import {
  ActionItemCollapsibleFragmentFragment,
  ArtifactType,
  GetActionItemsCollapsibleQueryQueryVariables,
} from "types/graphql-schema";
import { BasicUser } from "types/topicflow";

import ActionItemsCollapsible from "@apps/action-items-collapsible/action-items-collapsible-container";
import ArtifactCreationDialog from "@apps/artifact-creation-dialog/artifact-creation-dialog";
import useLabel from "@apps/use-label/use-label";
import { currentOrganizationVar } from "@cache/cache";
import Error from "@components/error/error";
import Layout from "@components/layout/layout";

export const droppedActionVar = makeVar(null);

const ActionItems = ({ selectedUser }: { selectedUser: BasicUser }) => {
  const [isShowingCreateArtifactModal, setIsShowingCreateArtifactModal] =
    useState(false);
  const label = useLabel();
  const organization = currentOrganizationVar();
  const filterResults =
    (queryVariables: GetActionItemsCollapsibleQueryQueryVariables) =>
    (node: ActionItemCollapsibleFragmentFragment) =>
      node.__typename === "ActionItemArtifactNode" &&
      node.assignee?.id === queryVariables.assigneeId;

  // RENDER
  const defaultIncompleteState = compact(organization.actionItemStates).find(
    (state) => state.isDefaultIncomplete
  );
  const defaultCompleteState = compact(organization.actionItemStates).find(
    (state) => state.isComplete
  );
  const actionItemStates = compact([
    defaultIncompleteState,
    defaultCompleteState,
  ]);
  return (
    <>
      {isShowingCreateArtifactModal && (
        <ArtifactCreationDialog
          formOptions={{
            artifactType: ArtifactType.ActionItem,
            assignee: selectedUser,
          }}
          onClose={() => setIsShowingCreateArtifactModal(false)}
        />
      )}
      <Layout.SidebarSection
        title={label("action item", { pluralize: true, capitalize: true })}
        expandedUiPreferenceKey="dashboardSidebarActionItemContainerExpanded"
        aria-label="Dashboard action item container"
        createArtifactFormOptions={{
          artifactType: ArtifactType.ActionItem,
          assignee: selectedUser,
        }}
      >
        {actionItemStates.map((actionItemState) => (
          <ActionItemsCollapsible
            newSidebarDesign
            key={actionItemState?.value}
            actionItemState={actionItemState}
            uiPreferenceCacheNamespace={"meetingActionItemsExpanded"}
            filterResults={filterResults}
            queryVariables={{
              after: null,
              assigneeId: selectedUser.id,
              first: 5,
              orderBy: actionItemState.isComplete
                ? "-completed_at"
                : "-due_date",
              actionItemIsComplete: actionItemState.isComplete,
              fetchData: true,
            }}
          />
        ))}
      </Layout.SidebarSection>
    </>
  );
};

export default withErrorBoundary(ActionItems, {
  fallback: <Error description={"The action items could not be rendered."} />,
});
