import { useMutation } from "@apollo/client";
import { compact, uniq } from "lodash";
import { GoalArtifactSidebarFragmentFragment } from "types/graphql-schema";

import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { assertEdgesNonNull } from "@helpers/helpers";

import updateGoalTeamsMutation from "../graphql/update-goal-teams-mutation";
import ArtifactTeamsInput, { ArtifactTeamType } from "./artifact-teams-input";

const ArtifactTeams = ({
  artifact,
  dropdownWidth = "48",
}: {
  artifact: GoalArtifactSidebarFragmentFragment;
  dropdownWidth?: string;
}) => {
  const [updateArtifact] = useMutation(updateGoalTeamsMutation);

  const artifactTeamEdges = artifact?.teams?.edges || [];
  const artifactTeams = artifact.teams
    ? assertEdgesNonNull(artifact.teams)
    : [];

  const handleSelectTeam = (team: ArtifactTeamType) => {
    const newTeamEdges = [
      ...artifactTeamEdges,
      {
        node: { ...team, __typename: "TeamNode" },
        __typename: "TeamNodeEdge",
      },
    ];
    const teamIds = uniq(compact(newTeamEdges.map((edge) => edge?.node?.id)));
    updateArtifact({
      variables: {
        artifactId: artifact.id,
        additionalFields: { teamIds },
      },
      optimisticResponse: {
        createOrUpdateArtifact: {
          artifact: {
            ...artifact,
            teams: {
              ...artifact.teams,
              edges: newTeamEdges,
            },
          },
          __typename: "CreateOrUpdateArtifactMutation",
        },
      },
      onError: onNotificationErrorHandler(),
    });
  };

  const handleRemoveTeam = (id: number) => {
    const newTeamEdges = artifactTeamEdges.filter(
      (edge) => edge?.node?.id !== id
    );
    const newTeamIds = compact(newTeamEdges.map((edge) => edge?.node?.id));
    updateArtifact({
      variables: {
        artifactId: artifact.id,
        additionalFields: {
          teamIds: newTeamIds,
        },
      },
      optimisticResponse: {
        createOrUpdateArtifact: {
          artifact: {
            ...artifact,
            teams: {
              ...artifact.teams,
              edges: newTeamEdges,
            },
          },
          __typename: "CreateOrUpdateArtifactMutation",
        },
      },
      onError: onNotificationErrorHandler(),
    });
  };

  return (
    <ArtifactTeamsInput
      teams={artifactTeams}
      canEdit={artifact.canUpdate.permission}
      dropdownWidth={dropdownWidth}
      onAddTeam={handleSelectTeam}
      onRemoveTeam={handleRemoveTeam}
    />
  );
};

export default ArtifactTeams;
