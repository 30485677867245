import * as Sentry from "@sentry/react";
import { ArtifactType, FeedbackState } from "types/graphql-schema";
import { BasicUser } from "types/topicflow";

import useLabel from "@apps/use-label/use-label";
import { currentUserVar } from "@cache/cache";
import Error from "@components/error/error";
import Layout from "@components/layout/layout";

import FeedbacksCollapsibleContainer from "./feedbacks-collapsible-container";

const DashboardFeedback = ({
  selectedUser,
  createdBetweenDates,
}: {
  selectedUser: BasicUser;
  createdBetweenDates?: string[];
}) => {
  const currentUser = currentUserVar();
  const label = useLabel();

  return (
    <>
      <Layout.SidebarSection
        expandedUiPreferenceKey="dashboardSidebarFeedbackContainerExpanded"
        aria-label="Dashboard feedback container"
        title={
          selectedUser.id === currentUser.id
            ? label("feedback", { pluralize: true, capitalize: true })
            : `${selectedUser.firstName || selectedUser.name}'s ${label(
                "feedback",
                {
                  pluralize: true,
                  capitalize: true,
                }
              )}`
        }
        createArtifactFormOptions={{
          artifactType: ArtifactType.Feedback,
          feedbackRecipients:
            selectedUser.id !== currentUser.id ? [selectedUser] : [],
        }}
      >
        <FeedbacksCollapsibleContainer
          title={`${label("feedback", { capitalize: true })} received`}
          filters={{
            fetchData: true,
            feedbackRecipient: selectedUser.id,
            asUser: selectedUser.id,
            feedbackState: FeedbackState.Sent,
            first: 5,
            createdBetweenDates,
          }}
          localStorageKey="dashboardFeedbackReceivedExpanded"
        />
        <FeedbacksCollapsibleContainer
          title={`${label("feedback", { capitalize: true })} given`}
          filters={{
            fetchData: true,
            feedbackSender: selectedUser.id,
            asUser: selectedUser.id,
            feedbackState: FeedbackState.Sent,
            first: 5,
            createdBetweenDates,
          }}
          localStorageKey="dashboardFeedbackGivenExpanded"
          roundedBottom
        />
      </Layout.SidebarSection>
    </>
  );
};

export default Sentry.withErrorBoundary(DashboardFeedback, {
  fallback: <Error description={"The feedback could not be rendered."} />,
});
