import { XIcon } from "@heroicons/react/outline";
import { BasicUser } from "types/topicflow";

import Avatar from "@components/avatar/avatar";
import RecipientDropdown from "@components/people-dropdown/recipient-dropdown";
import { UserComboboxUserOption } from "@components/user-combobox/user-combobox-list";

const RecipientForm = ({
  recipients,
  onRemoveRecipient,
  onAddRecipient,
  canChange,
  showCurrentUser,
  maxRecipients,
  excludeUserIds = [],
  placeholder,
}: {
  recipients: BasicUser[];
  excludeUserIds?: number[];
  onRemoveRecipient: (recipient: BasicUser) => void;
  onAddRecipient: (option: UserComboboxUserOption) => void;
  canChange: boolean;
  showCurrentUser?: boolean;
  maxRecipients?: number;
  placeholder?: string;
}) => {
  return (
    <div className="flex-1 flex gap-2 flex-wrap">
      {recipients.map((recipient) => (
        <div
          className="border rounded-lg px-2 py-1 text-sm flex items-center gap-1"
          key={recipient.id}
        >
          <Avatar user={recipient} size="4" />
          {recipient.name}
          {canChange && (
            <button
              className="ml-0.5 rounded-full p-0.5 border bg-gray-50 text-gray-600 hover:text-white hover:bg-blue-600 hover:border-blue-600"
              onClick={() => onRemoveRecipient(recipient)}
            >
              <XIcon className="h-3 w-3" />
            </button>
          )}
        </div>
      ))}
      {canChange &&
        (maxRecipients === undefined || recipients.length < maxRecipients) && (
          <RecipientDropdown
            recipients={recipients}
            showCurrentUser={showCurrentUser}
            onChangeRecipient={onAddRecipient}
            excludeUserIds={excludeUserIds}
            placeholder={placeholder}
          />
        )}
    </div>
  );
};

export default RecipientForm;
