import { useQuery } from "@apollo/client";
import { ArrowSmRightIcon } from "@heroicons/react/outline";
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import {
  GetRecognitionBoardCoreValuesQueryQuery,
  GetRecognitionBoardCoreValuesQueryQueryVariables,
  GetRecognitionBoardQueryQueryVariables,
} from "types/graphql-schema";
import { DateRangeEnum } from "types/topicflow";

import useLabel from "@apps/use-label/use-label";
import { currentOrganizationVar } from "@cache/cache";
import Avatar from "@components/avatar/avatar";
import CoreValueIcon from "@components/core-value-icon/core-value-icon";
import Label from "@components/label/label";
import Loading from "@components/loading/loading";
import { Select, SelectOption } from "@components/select/select";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import useUserComboboxQuery from "@components/user-combobox/use-user-combobox-query";
import UserCombobox from "@components/user-combobox/user-combobox";
import {
  UserComboboxOption,
  UserComboboxOptionType,
} from "@components/user-combobox/user-combobox-list";
import { classNames } from "@helpers/css";
import { assertEdgesNonNull, dateRangeToDateArray } from "@helpers/helpers";

import getRecognitionBoardCoreValuesQuery from "./graphql/get-recognition-board-core-values-query";
import {
  RecognitionBoardDateRangeType,
  RecognitionBoardExtraDateRangeEnum,
} from "./recognition-board";

const RecognitionBoardFilters = ({
  coreValueId,
  recipientId,
  createdBetweenDates,
  createdDateRange,
  receivedByMembersOfTeamId,
  onChangeCoreValueId,
  onChangeRecipient,
  onChangeCreatedBetweenDates,
  onChangeCreatedDateRange,
}: {
  coreValueId: GetRecognitionBoardQueryQueryVariables["coreValueId"];
  recipientId: GetRecognitionBoardQueryQueryVariables["recipientId"];
  receivedByMembersOfTeamId: GetRecognitionBoardQueryQueryVariables["receivedByMembersOfTeamId"];
  createdBetweenDates: string[];
  createdDateRange: RecognitionBoardDateRangeType;
  onChangeCoreValueId: (
    coreValueId: GetRecognitionBoardQueryQueryVariables["coreValueId"]
  ) => void;
  onChangeRecipient: (
    recipientId: GetRecognitionBoardQueryQueryVariables["recipientId"],
    receivedByMembersOfTeamId: GetRecognitionBoardQueryQueryVariables["receivedByMembersOfTeamId"]
  ) => void;
  onChangeCreatedBetweenDates: (dates: string[]) => void;
  onChangeCreatedDateRange: (range: RecognitionBoardDateRangeType) => void;
}) => {
  const label = useLabel();
  const currentOrganization = currentOrganizationVar();
  const {
    options: recipientOptions,
    query,
    setQuery,
  } = useUserComboboxQuery({
    types: [UserComboboxOptionType.USER, UserComboboxOptionType.TEAM],
  });
  const selectedRecipientOption = recipientId
    ? recipientOptions.find(
        ({ id, type }) =>
          type === UserComboboxOptionType.USER && id === recipientId
      ) || null
    : recipientOptions.find(
        ({ id, type }) =>
          type === UserComboboxOptionType.TEAM &&
          id === receivedByMembersOfTeamId
      ) || null;

  const createdBetweenDatesVariable =
    createdDateRange === RecognitionBoardExtraDateRangeEnum.all
      ? null
      : createdBetweenDates;
  const { data: dataCoreValues, loading: loadingCoreValues } = useQuery<
    GetRecognitionBoardCoreValuesQueryQuery,
    GetRecognitionBoardCoreValuesQueryQueryVariables
  >(getRecognitionBoardCoreValuesQuery, {
    variables: {
      organizationId: currentOrganization.id,
      recipientId,
      receivedByMembersOfTeamId,
      coreValueId,
      createdBetweenDates: createdBetweenDatesVariable,
      statsStartDate: createdBetweenDatesVariable?.[0],
      statsEndDate: createdBetweenDatesVariable?.[1],
    },
    onError: onNotificationErrorHandler(),
  });

  const stats = dataCoreValues?.organization?.recognitionStats;

  const cachedCoreValueOptions = assertEdgesNonNull(
    currentOrganization.coreValues
  ).map((coreValue) => ({ ...coreValue, totalCount: 0 }));
  const coreValues = dataCoreValues?.organization?.coreValues
    ? assertEdgesNonNull(dataCoreValues.organization.coreValues).map(
        ({ recognitions, ...coreValue }) => ({
          ...coreValue,
          totalCount: recognitions?.totalCount || 0,
        })
      )
    : cachedCoreValueOptions;

  const coreValueOptions = coreValues.map(
    ({ id, title, emoji, image, totalCount }) => ({
      value: id,
      label: title,
      emoji,
      image,
      total: totalCount,
    })
  );

  const dateRangeOptions = Object.values(DateRangeEnum).map((dateRange) => ({
    value: dateRange,
    label: label(dateRange, { capitalize: true }),
    description: dateRangeToDateArray({
      range: dateRange,
      quarterStartMonth: currentOrganization.quarterStartMonth,
    })
      .map((date) => moment(date).format("ll"))
      .join(" - "),
  }));
  const dueBetweenDatesOptions = [
    {
      value: RecognitionBoardExtraDateRangeEnum.all,
      label: `Anytime`,
    },
    ...dateRangeOptions,
    {
      value: RecognitionBoardExtraDateRangeEnum.custom,
      label: "Custom date range",
    },
  ];

  const handleChangeRecipient = (option?: UserComboboxOption) => {
    if (option?.type === UserComboboxOptionType.USER) {
      onChangeRecipient(option?.id || null, null);
    } else if (option?.type === UserComboboxOptionType.TEAM) {
      onChangeRecipient(null, option?.id || null);
    } else {
      onChangeRecipient(null, null);
    }
  };

  const handleChangeCreatedDateRange = (
    option: SelectOption<RecognitionBoardDateRangeType>
  ) => {
    if (
      option.value !== RecognitionBoardExtraDateRangeEnum.custom &&
      option.value !== RecognitionBoardExtraDateRangeEnum.all
    ) {
      onChangeCreatedBetweenDates(
        dateRangeToDateArray({
          range: option.value,
          quarterStartMonth: currentOrganization.quarterStartMonth,
        })
      );
    }
    onChangeCreatedDateRange(option.value);
  };

  const handleChangeCreatedStartDate = (newDate: Date | null) => {
    if (newDate) {
      const newCreatedBetweenDates = [...createdBetweenDates];
      newCreatedBetweenDates[0] = moment(newDate).format("YYYY-MM-DD");
      onChangeCreatedBetweenDates(newCreatedBetweenDates);
    }
  };

  const handleChangeCreatedEndDate = (newDate: Date | null) => {
    if (newDate) {
      const newCreatedBetweenDates = [...createdBetweenDates];
      newCreatedBetweenDates[1] = moment(newDate).format("YYYY-MM-DD");
      onChangeCreatedBetweenDates(newCreatedBetweenDates);
    }
  };

  const buttonClassName =
    "text-left py-2 px-3 bg-white text-sm flex justify-between items-center gap-1";
  const hoverButtonClassName = "hover:bg-gray-50";
  const activeButtonClassName = "bg-tfcolor/10 hover:bg-tfcolor/10 font-medium";
  const countClassName =
    "rounded-md bg-tfcolor/10 text-tfcolor px-1 py-0.5 text-xs font-medium";
  const containerClassName =
    "flex flex-col rounded-md shadow-inner border border-gray-300 text-gray-800 overflow-hidden divide-y bg-white";
  const userWhoReceivedMostRecognition = stats?.userWhoReceivedMostRecognition;
  const userWhoGaveMostRecognition = stats?.userWhoGaveMostRecognition;
  return (
    <div className="w-56 flex flex-col gap-4">
      <div className={containerClassName}>
        <button
          className={classNames(
            buttonClassName,
            hoverButtonClassName,
            !coreValueId && activeButtonClassName
          )}
          onClick={() => onChangeCoreValueId(null)}
        >
          <div>All {label("recognition", { pluralize: true })}</div>
          {loadingCoreValues ? (
            <Loading mini size={5} />
          ) : (
            <div className={countClassName}>{stats?.recognitionCount || 0}</div>
          )}
        </button>
        {coreValueOptions.map((coreValueOption) => (
          <button
            className={classNames(
              buttonClassName,
              hoverButtonClassName,
              coreValueId === coreValueOption.value && activeButtonClassName
            )}
            key={coreValueOption.value}
            onClick={() => onChangeCoreValueId(coreValueOption.value)}
          >
            <div className="flex items-center gap-1">
              <CoreValueIcon
                emoji={coreValueOption.emoji}
                image={coreValueOption.image}
                imagePxSize={16}
              />
              {coreValueOption.label}
            </div>
            {!loadingCoreValues && (
              <div className={countClassName}>{coreValueOption.total}</div>
            )}
          </button>
        ))}
      </div>

      <div>
        <Label label="Recipient" />
        <UserCombobox
          onChangeValue={handleChangeRecipient}
          options={recipientOptions}
          value={selectedRecipientOption}
          placeholder="All recipients"
          clearable={!!selectedRecipientOption}
          query={query}
          onChangeQuery={setQuery}
          onClearValue={handleChangeRecipient}
        />
      </div>
      <div>
        <Label label="Created" />
        <Select<RecognitionBoardDateRangeType>
          onChange={handleChangeCreatedDateRange}
          options={dueBetweenDatesOptions}
          value={createdDateRange}
        />
        {createdDateRange === RecognitionBoardExtraDateRangeEnum.custom &&
          createdBetweenDates && (
            <div className="mt-1 flex justify-end gap-0.5 items-center">
              <ReactDatePicker
                placeholderText="Start date"
                selected={moment(createdBetweenDates[0]).toDate()}
                onChange={handleChangeCreatedStartDate}
                dateFormat="MMM d, yyyy"
                className={classNames("w-24 tracking-tight py-0.5 text-xs")}
                maxDate={moment(createdBetweenDates[1]).toDate()}
              />
              <ArrowSmRightIcon className="text-gray-500 w-4 h-4" />
              <ReactDatePicker
                selected={moment(createdBetweenDates[1]).toDate()}
                placeholderText="End date"
                onChange={handleChangeCreatedEndDate}
                dateFormat="MMM d, yyyy"
                className={classNames("w-24 tracking-tight py-0.5 text-xs")}
                minDate={moment(createdBetweenDates[0]).toDate()}
              />
            </div>
          )}
      </div>

      {userWhoReceivedMostRecognition && userWhoGaveMostRecognition && (
        <div className="border-t border-black/10 mt-4 pt-6">
          <Label label="Stats" />

          <div className="flex flex-col gap-4">
            <div className={containerClassName}>
              <div
                className={classNames(
                  buttonClassName,
                  "bg-gray-50 text-gray-500 text-sm tracking-tight"
                )}
              >
                Most{" "}
                {label("recognition", { pluralize: true, capitalize: true })}{" "}
                Received
              </div>
              {userWhoReceivedMostRecognition?.id &&
                stats?.userWhoReceivedMostRecognitionCount && (
                  <div className={classNames(buttonClassName)}>
                    <div className="flex items-center gap-1">
                      <Avatar user={userWhoReceivedMostRecognition} size="5" />
                      <span>{stats?.userWhoReceivedMostRecognition?.name}</span>
                    </div>
                    <div className={countClassName}>
                      {stats?.userWhoGaveMostRecognitionCount}
                    </div>
                  </div>
                )}
            </div>

            <div className={containerClassName}>
              <div
                className={classNames(
                  buttonClassName,
                  "bg-gray-50 text-gray-500 text-sm tracking-tight"
                )}
              >
                Most{" "}
                {label("recognition", { pluralize: true, capitalize: true })}{" "}
                Given
              </div>

              {userWhoGaveMostRecognition?.id &&
                stats?.userWhoGaveMostRecognitionCount && (
                  <div className={classNames(buttonClassName)}>
                    <div className="flex items-center gap-1">
                      <Avatar user={userWhoGaveMostRecognition} size="5" />
                      <span>{stats?.userWhoGaveMostRecognition?.name}</span>
                    </div>
                    <div className={countClassName}>
                      {stats?.userWhoGaveMostRecognitionCount}
                    </div>
                  </div>
                )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RecognitionBoardFilters;
