import { useQuery } from "@apollo/client";
import { compact, orderBy, uniqBy } from "lodash";

import getOrganizationTemplatesQuery from "@apps/org-settings/graphql/get-organization-templates-query";
import ComboboxGeneric from "@components/combobox/generic-combobox";
import { onNotificationErrorHandler } from "@components/use-error/use-error";

export const anyTemplateOption = {
  label: "Any template",
  value: null,
};

const TemplateReportFilter = ({
  organization,
  value,
  onChangeValue,
}: {
  organization: any;
  value: any;
  onChangeValue: any;
}) => {
  const { data, loading } = useQuery(getOrganizationTemplatesQuery, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    variables: {
      organizationId: organization.id,
    },
    onError: onNotificationErrorHandler(),
  });
  const options =
    data?.topicTemplates.edges.map(({ node }: { node: any }) => ({
      value: node.id,
      label: node.title,
    })) || [];

  const mergedOptions = uniqBy(
    compact([value].concat(options, anyTemplateOption)),
    "value"
  );
  const filterOptions = orderBy(
    mergedOptions,
    [({ value }) => !!value, ({ label }) => label.toLowerCase()],
    ["asc", "asc"]
  );

  return (
    <ComboboxGeneric
      aria-label="Template filter"
      loading={loading}
      width="64"
      value={value}
      options={filterOptions}
      clearable={!!value.value}
      onChangeValue={onChangeValue}
      onClearValue={() => onChangeValue(anyTemplateOption)}
    />
  );
};

export default TemplateReportFilter;
