import { useMemo } from "react";
import { DashboardInsightFragment } from "types/graphql-schema";
import { BasicUser } from "types/topicflow";

import { currentUserVar } from "@cache/cache";
import { assertEdgesNonNull, assertNonNull } from "@helpers/helpers";

import { InsightButton, InsightLayout } from "../insight";

const FinalizeComplianceProgramMeetingInsight = ({
  insight,
  selectedUser,
  onDismissInsight,
}: {
  insight: DashboardInsightFragment;
  selectedUser: BasicUser;
  onDismissInsight: () => void;
}) => {
  const currentUser = currentUserVar();
  const report = useMemo(() => assertNonNull(insight.report), [insight]);
  const matchingOneonones = useMemo(
    () => assertEdgesNonNull(insight.matchingOneononesForComplianceProgram),
    [insight]
  );

  return (
    <InsightLayout
      user={report}
      onDismissInsight={onDismissInsight}
      isRequired={!!insight.complianceProgram?.id}
    >
      <div className="flex-1">
        <div>
          {currentUser.id === selectedUser.id
            ? "You are required to "
            : `${selectedUser.name} is required to `}
          finalize a meeting with {report.firstName || report.name}.
        </div>
      </div>
      <div>
        <InsightButton
          url={`/meeting/${matchingOneonones[0].meetingGroupId}/${matchingOneonones[0].id}`}
        >
          Finalize 1-on-1
        </InsightButton>
      </div>
    </InsightLayout>
  );
};

export default FinalizeComplianceProgramMeetingInsight;
