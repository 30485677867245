import { gql } from "@apollo/client";

import CommonGoalArtifactFragment from "@graphql/common-goal-artifact-fragment";
import CommonRecognitionArtifactFragment from "@graphql/common-recognition-artifact-fragment";
import KeyResultFragment from "@graphql/key-result-fragment";

export default gql`
  ${KeyResultFragment}
  ${CommonGoalArtifactFragment}
  ${CommonRecognitionArtifactFragment}
  query getPusherArtifactQuery($artifactId: Int!, $keyResultLimit: Int!) {
    artifact(artifactId: $artifactId) {
      id
      title
      artifactType
      canDelete {
        permission
      }
      ... on ActionItemArtifactNode {
        isComplete
        actionItemState
        dueDate
        assignee {
          id
          avatar
          name
        }
      }
      ... on GoalArtifactNode {
        ...CommonGoalArtifactFragment
        keyResults(first: $keyResultLimit) {
          edges {
            node {
              ...KeyResultFragment
            }
          }
        }
      }
      ... on DecisionArtifactNode {
        decision
        isDraft
        decisionState
      }
      ... on RecognitionArtifactNode {
        ...CommonRecognitionArtifactFragment
      }
    }
  }
`;
