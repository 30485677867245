import { XIcon } from "@heroicons/react/solid";
import { ChangeEvent } from "react";
import { OrganizationRole } from "types/graphql-schema";

import Input from "@components/input/input";
import Select, { SelectOption } from "@components/select/select";

import { InviteInputType } from "../invite-dialog";

interface Props {
  invites: InviteInputType[];
  showRoleSelector?: boolean;
  onChange: (emails: InviteInputType[]) => void;
}

const adminOption = {
  value: OrganizationRole.Administrator,
  label: "Admin",
};
const memberOption = { value: OrganizationRole.Member, label: "Member" };
const roleOptions = [adminOption, memberOption];

const InviteDialogInviteList: React.FC<Props> = ({
  invites,
  showRoleSelector = true,
  onChange,
}) => {
  const handleRemoveAttendee = (attendeeToRemove: InviteInputType) => {
    onChange(invites.filter(({ email }) => attendeeToRemove.email !== email));
  };

  const handleChangeRole =
    (attendeeToChange: InviteInputType) =>
    (option: SelectOption<OrganizationRole>) => {
      const newAttendees = invites.map((attendee) =>
        attendeeToChange.email === attendee.email
          ? { ...attendee, role: option.value }
          : attendee
      );
      onChange(newAttendees);
    };

  const handleChangeEmail =
    (attendeeToChange: InviteInputType) =>
    (e: ChangeEvent<HTMLInputElement>) => {
      const newAttendees = invites.map((attendee) =>
        attendeeToChange.uuid === attendee.uuid
          ? { ...attendee, email: e.target.value }
          : attendee
      );
      onChange(newAttendees);
    };

  return (
    <div className={"text-sm flex flex-col gap-1"}>
      {invites.map((selectedItem, index) => (
        <div
          key={`selected-item-${index}`}
          className="flex items-center gap-2 py-0.5 text-gray-800 group"
        >
          <Input
            type="text"
            placeholder="email@domain.com"
            value={selectedItem.email}
            onChange={handleChangeEmail(selectedItem)}
            className="w-72"
            aria-label="Invite dialog email field"
          />
          {showRoleSelector && (
            <div className="w-28">
              <Select
                value={selectedItem.role || OrganizationRole.Member}
                options={roleOptions}
                className="z-dropdown"
                showSelectedIcon={false}
                maxHeight={false}
                placement="bottom-start"
                onChange={handleChangeRole(selectedItem)}
              />
            </div>
          )}
          {selectedItem.email.trim().length > 0 && invites.length > 1 && (
            <button
              className="text-gray-300 hover:text-gray-600 p-0.5"
              type="button"
              aria-label="Remove participant"
              onClick={(e) => {
                e.stopPropagation();
                handleRemoveAttendee(selectedItem);
              }}
            >
              <XIcon className="w-4 h-4" />
            </button>
          )}
        </div>
      ))}
    </div>
  );
};

export default InviteDialogInviteList;
