import { gql } from "@apollo/client";

export default gql`
  query getTopicMeetings(
    $topicId: Int!
    $meetingGroupId: Int!
    $startDatetime: DateTime!
    $excludeIds: [Int]!
  ) {
    topic(topicId: $topicId) {
      id
    }
    meetingGroup(meetingGroupId: $meetingGroupId) {
      id
      meetings(
        first: 5
        startDatetime_Gte: $startDatetime
        status: confirmed
        ignored: false
        excludeIds: $excludeIds
      ) {
        edges {
          node {
            id
            title
            startDatetime
          }
        }
      }
    }
  }
`;
