import { useMutation, useQuery } from "@apollo/client";
import asanaLogo from "@static/img/asana.png";
import { NodeViewWrapper } from "@tiptap/react";
import { compact } from "lodash";
import moment from "moment";
import { useEffect } from "react";

import { currentOrganizationVar, currentUserVar } from "@cache/cache";
import Dropdown from "@components/dropdown/dropdown";
import AppLink from "@components/link/link";
import Loading from "@components/loading/loading";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { getAssetUrl } from "@helpers/helpers";

import createAsanaArtifactMutation from "../graphql/create-asana-artifact-mutation";
import getAsanaArtifactQuery from "../graphql/get-asana-artifact-query";

const AsanaArtifactComponent = ({
  node,
  updateAttributes,
  deleteNode,
  extension,
}: {
  node: any;
  updateAttributes: (attributes: any) => void;
  deleteNode: () => void;
  extension: any;
}) => {
  const currentUser = currentUserVar();
  const currentOrganization = currentOrganizationVar();
  const url = node.attrs.url;
  const id = node.attrs.id;
  const createdByUser = node.attrs.createdByUser;
  const [
    createAsanaArtifact,
    { data: dataMutation, loading: loadingMutation },
  ] = useMutation(createAsanaArtifactMutation);
  const {
    data,
    error,
    loading: loadingQuery,
  } = useQuery(getAsanaArtifactQuery, {
    variables: { id },
    skip: !id,
    onError: onNotificationErrorHandler(),
  });

  const artifact =
    dataMutation?.createOrUpdateArtifact.artifact || data?.artifact;
  const title = artifact?.title || data?.asanaTaskPreview?.title;
  const loading = loadingQuery || loadingMutation;

  const handleCreateAsanaArtifact = () => {
    createAsanaArtifact({
      onError: onNotificationErrorHandler(),
      variables: {
        title,
        meetingId: extension.options.meetingId,
        relatedTopicId: extension.options.relatedTopicId,
        relatedArtifactId: extension.options.relatedArtifactId,
        organizationId: currentOrganization.id,
        additionalFields: { url },
      },
    });
  };

  // Create new artifact if we only have the url
  useEffect(() => {
    if (!id && url && createdByUser.id === currentUser.id) {
      handleCreateAsanaArtifact();
    }
  }, [id, url]);

  // Adds attribute id on node extension when artifact is created
  useEffect(() => {
    if (!id && artifact?.id) {
      updateAttributes({ id: artifact.id });
    }
  }, [artifact, id]);

  const metadata = compact([
    artifact?.dueDate && (
      <span key="due-date">Due {moment(artifact.dueDate).format("ll")}</span>
    ),
    artifact?.assignee && <span key="assignee">{artifact.assignee.name}</span>,
    artifact &&
      (artifact.completedAt ? (
        <span key="completed" className="text-emerald-700">
          Completed {moment(artifact.completedAt).format("ll")}
        </span>
      ) : (
        <span key="incomplete">Incomplete</span>
      )),
  ]);

  return (
    <NodeViewWrapper className="border bg-gray-50 rounded-lg py-1 pl-3 pr-2 flex gap-2 mb-3">
      <div className="w-4 not-prose flex pt-1 shrink-0">
        <img
          src={getAssetUrl(asanaLogo)}
          alt="Asana"
          className="block h-4 w-4 flex-none"
        />
      </div>
      <div className="flex-1 truncate">
        <a
          href={node.attrs.url}
          target="_blank"
          className="font-medium no-underline hover:underline text-sm text-gray-600"
          rel="noreferrer"
        >
          {title || node.attrs.url}
        </a>
        {/* if artifact created or is not synced and user has not connected to Asana, prompt to connect to Asana */}
        {!artifact?.isSynced && !currentUser.hasAsanaAuth && (
          <div className="text-xs text-gray-500 mt-1.5 flex gap-4">
            <AppLink
              to="/account/integrations"
              className="text-blue-link font-normal no-underline hover:underline"
            >
              Connect to Asana to update
            </AppLink>
          </div>
        )}
        {/* if artifact exist but has been synced and user is connected, then prompt user to refres */}
        {artifact && !artifact.isSynced && currentUser.hasAsanaAuth && (
          <div className="text-xs text-gray-500 mt-1.5 flex gap-4">
            <button
              onClick={handleCreateAsanaArtifact}
              className="text-blue-link font-normal no-underline hover:underline"
            >
              Refresh
            </button>
          </div>
        )}
        {String(error).toLowerCase().includes("not a recognized id") && (
          <div className="text-xs text-gray-500 mt-1.5 flex gap-4">
            This url is not a valid Asana task, or you do not have access.
          </div>
        )}
        {artifact && artifact.isSynced && metadata.length > 0 && (
          <div className="text-xs text-gray-500 mt-1.5 flex gap-4">
            {metadata}
          </div>
        )}
      </div>
      <div className="pt-1">
        {loading ? (
          <Loading mini size="5" />
        ) : (
          <Dropdown
            options={[
              { label: "Delete", onClick: deleteNode },
              { label: "Refresh", onClick: handleCreateAsanaArtifact },
            ]}
          />
        )}
      </div>
    </NodeViewWrapper>
  );
};

export default AsanaArtifactComponent;
