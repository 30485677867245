import { gql } from "@apollo/client";

export default gql`
  mutation inviteUsers($invitationRequests: [InvitationInput]!) {
    createInvitations(invitationRequests: $invitationRequests) {
      invitations {
        id
        inviteKey
        inviteeEmail
      }
      invitationErrors {
        inviteeEmail
        message
      }
    }
  }
`;
