import { useQuery } from "@apollo/client";
import { ArtifactType, DecisionState, GoalState } from "types/graphql-schema";

import ArtifactIcon from "@apps/artifact/components/artifact-icon";
import DraftLabel from "@components/draft-label/draft-label";
import GoalVisibilityIcon from "@components/goal-visibility-icon/goal-visibility-icon";
import { onNotificationErrorHandler } from "@components/use-error/use-error";

import getWysiwygArtifactQuery from "./graphql/get-wysiwyg-artifact-query";

const WysiwygArtifactIcon = ({
  syncedArtifact,
  node,
}: {
  syncedArtifact: any;
  node: any;
}) => {
  const { data } = useQuery(getWysiwygArtifactQuery, {
    fetchPolicy: "cache-only", // rely only on cached content to render this view
    variables: { artifactId: Number(syncedArtifact.id) },
    onError: onNotificationErrorHandler(),
  });
  const artifactTypeName = node.type.name;
  const isActionItem = artifactTypeName === ArtifactType.ActionItem;
  const isDecision = artifactTypeName === ArtifactType.Decision;
  const isGoal = artifactTypeName === ArtifactType.Goal;
  const isDocument = artifactTypeName === ArtifactType.Document;
  const isFeedback = artifactTypeName === ArtifactType.Feedback;
  const artifact = data?.artifact || syncedArtifact;

  return isActionItem ? (
    <span className="flex items-start gap-1" contentEditable={false}>
      <div className="mt-1">
        <ArtifactIcon artifact={artifact} size="5" />
      </div>
    </span>
  ) : isDecision ? (
    <span className="flex items-start gap-1" contentEditable={false}>
      <span className="text-emerald-600 mt-1" contentEditable={false}>
        <ArtifactIcon artifact={artifact} size="5" />
      </span>
      {artifact.decisionState === DecisionState.Draft && (
        <div>
          <DraftLabel />
        </div>
      )}
    </span>
  ) : isDocument ? (
    <span className="text-gray-500 mt-1" contentEditable={false}>
      <ArtifactIcon artifact={artifact} size="5" />
    </span>
  ) : isFeedback ? (
    <span className="text-gray-500 mt-1" contentEditable={false}>
      <ArtifactIcon artifact={artifact} size="5" />
    </span>
  ) : artifactTypeName === ArtifactType.Recognition ? (
    <span className="text-amber-500 mt-1" contentEditable={false}>
      <ArtifactIcon artifact={artifact} size="5" />
    </span>
  ) : isGoal ? (
    <div className="flex items-start gap-1">
      <div className="mt-1">
        <ArtifactIcon artifact={artifact} size="5" />
      </div>
      {artifact.state === GoalState.Draft && (
        <div>
          <DraftLabel />
        </div>
      )}
      <GoalVisibilityIcon
        goalVisibility={artifact.goalVisibility}
        size="4"
        showOnlyIfPrivate
        className="mt-1.5"
      />
    </div>
  ) : null;
};

export default WysiwygArtifactIcon;
