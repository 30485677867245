import { useMutation } from "@apollo/client";
import { XIcon } from "@heroicons/react/outline";
import { useState } from "react";
import {
  LoggedInUserOrgFragment,
  SendNudgeEmailMutationMutation,
  SendNudgeEmailMutationMutationVariables,
} from "types/graphql-schema";
import { BasicUser } from "types/topicflow";

import sendNudgeEmailMutation from "@apps/org-settings/graphql/send-nudge-email-mutation";
import useLabel from "@apps/use-label/use-label";
import { successNotificationVar } from "@cache/cache";
import Avatar from "@components/avatar/avatar";
import Button, { buttonTheme } from "@components/button/button";
import Modal from "@components/modal/modal";
import { SelectOption } from "@components/select/select";
import { onNotificationErrorHandler } from "@components/use-error/use-error";

import TemplateReportFilter from "./template-report-filter";

const NudgeDialog = ({
  report,
  manager,
  templateFilter = null,
  hideTemplateSelector = false,
  organization,
  onClose,
}: {
  report: BasicUser;
  manager: BasicUser;
  templateFilter?: SelectOption<number | null> | null;
  hideTemplateSelector?: boolean;
  organization: LoggedInUserOrgFragment;
  onClose: () => void;
}) => {
  const label = useLabel();
  const [selectedTemplate, setSelectedTemplate] = useState<
    SelectOption<number | null>
  >(
    templateFilter && templateFilter.value !== null
      ? templateFilter
      : {
          value: null,
          label: "Using any template",
        }
  );
  const [sendNudgeEmail, { loading }] = useMutation<
    SendNudgeEmailMutationMutation,
    SendNudgeEmailMutationMutationVariables
  >(sendNudgeEmailMutation);

  const handleClickSendNudge = () => {
    sendNudgeEmail({
      variables: {
        managerId: manager.id,
        reportId: report.id,
        topicTemplateId: selectedTemplate.value || null,
      },
      onError: onNotificationErrorHandler(),
      onCompleted: () => {
        successNotificationVar({
          title: "Nudge sent.",
        });
        onClose();
      },
    });
  };

  return (
    <Modal open onClose={onClose}>
      <div className="px-6 py-4 flex flex-col gap-4">
        <div className="flex items-center justify-between">
          <div className="text-xl font-medium">Send a nudge</div>
          <button onClick={onClose}>
            <XIcon className="h-6 w-6 text-gray-500" />
          </button>
        </div>
        <div className="tracking-tight">
          Send an email notification to{" "}
          <span className="font-medium">{manager.name}</span> (the manager) to
          set up a {label("oneonone", { pluralize: false, capitalize: false })}.
        </div>
        <div className="border rounded py-8 px-2 flex flex-col gap-4">
          <div className="flex justify-center gap-4">
            <div className="flex-1">
              <div className="flex items-center gap-1.5 justify-end">
                <Avatar user={manager} size="5" />
                <span>{manager.name}</span>
              </div>
            </div>
            <div>
              <span className="text-gray-500">and</span>{" "}
            </div>
            <div className="flex-1">
              <div className="flex items-center gap-1.5 flex-1">
                <Avatar user={report} size="5" />
                <span>{report.name}</span>
              </div>
            </div>
          </div>
          {!hideTemplateSelector && (
            <div className="flex justify-center z-dropdown">
              <TemplateReportFilter
                organization={organization}
                value={selectedTemplate}
                onChangeValue={setSelectedTemplate}
              />
            </div>
          )}
        </div>
      </div>
      <div className="border-t px-6 py-4 grid grid-cols-2 gap-4">
        <Button onClick={onClose}>Cancel</Button>
        <Button
          theme={buttonTheme.primary}
          onClick={handleClickSendNudge}
          disabled={loading}
        >
          Send nudge by email
        </Button>
      </div>
    </Modal>
  );
};

export default NudgeDialog;
