import { useQuery } from "@apollo/client";
import { compact, uniqBy } from "lodash";
import moment from "moment";
import { useCallback, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  AssessmentGroupNode,
  AssessmentNode,
  ComplianceProgramNode,
  GetMyAssessmentsQuery,
  GetMyAssessmentsQueryVariables,
} from "types/graphql-schema";
import { BasicUser } from "types/topicflow";

import getMyAssessmentsQuery from "@apps/assessments/graphql/get-my-assessments-query";
import useCreateEmptyAssessment from "@apps/assessments/hooks/use-create-empty-assessment";
import useLabel from "@apps/use-label/use-label";
import { currentOrganizationVar, currentUserVar } from "@cache/cache";
import Avatar from "@components/avatar/avatar";
import Button, { buttonTheme } from "@components/button/button";
import Loading from "@components/loading/loading";
import {
  Table,
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableContainer,
  TableFooter,
  TableHeadCell,
  TableHeadRow,
  TableSortDir,
} from "@components/table/table";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { assessmentTypeLabels } from "@helpers/constants";
import { assertEdgesNonNull, assertNonNull } from "@helpers/helpers";

const AssessmentRow = ({
  program,
  assessmentGroup,
  target,
  existingAssessment,
  isDisabled,
  onCreateEmptyAssessment,
}: {
  program: Pick<ComplianceProgramNode, "id" | "title" | "dueDate">;
  assessmentGroup: Pick<AssessmentGroupNode, "title" | "assessmentType">;
  target: BasicUser;
  existingAssessment?: Pick<AssessmentNode, "id"> | null;
  isDisabled: boolean;
  onCreateEmptyAssessment: () => void;
}) => {
  const location = useLocation();
  const currentUser = currentUserVar();
  return (
    <TableBodyRow key={`${program.id}_${target.id}`} className="h-16">
      <TableBodyCell>
        <div className="flex items-center">
          <Avatar className="mr-2" size="8" user={target} /> {target.name}
          {target.id === currentUser.id && " (Self assessment)"}
        </div>
      </TableBodyCell>
      <TableBodyCell>{program.title}</TableBodyCell>
      <TableBodyCell>
        {assessmentTypeLabels[assessmentGroup.assessmentType]}
      </TableBodyCell>
      <TableBodyCell>
        {moment(program.dueDate).format("MMM D, YYYY")}
      </TableBodyCell>
      <TableBodyCell className="text-right">
        {!existingAssessment ? (
          <Button
            theme={buttonTheme.lightBlue}
            disabled={isDisabled}
            onClick={onCreateEmptyAssessment}
          >
            Start assessment
          </Button>
        ) : (
          <Button
            theme={buttonTheme.lightBlue}
            disabled={isDisabled}
            to={{
              pathname: `/assessments/assessment/${existingAssessment.id}`,
              state: {
                previousPathname: `${location.pathname}${location.search}`,
              },
            }}
          >
            Continue assessment
          </Button>
        )}
      </TableBodyCell>
    </TableBodyRow>
  );
};

enum SortKey {
  dueDate = "due_date",
  program = "title",
  assessmentType = "assessment_type",
}

const MyAssessmentsToAnswer = ({
  userFilter,
}: {
  userFilter: number | null;
}) => {
  const label = useLabel();
  const { handleCreateEmptyAssessment, isLoading: isCreatingEmptyAssessment } =
    useCreateEmptyAssessment("/assessments");
  const currentUser = assertNonNull(currentUserVar());
  const organization = currentOrganizationVar();
  const [sortKey, setSortKey] = useState<SortKey>(SortKey.dueDate);
  const [sortDir, setSortDir] = useState<TableSortDir>("desc");

  const { data, loading, fetchMore } = useQuery<
    GetMyAssessmentsQuery,
    GetMyAssessmentsQueryVariables
  >(getMyAssessmentsQuery, {
    variables: {
      organizationId: organization.id,
      currentUserId: currentUser.id,
      orderBy: `${sortDir === "desc" ? "-" : ""}${sortKey}`,
    },
    onError: onNotificationErrorHandler(),
  });

  const applicablePrograms = useMemo(() => {
    return data
      ? uniqBy(
          [
            ...assertEdgesNonNull(
              data.unmetPerformanceAssessmentCompliancePrograms
            ),
            ...assertEdgesNonNull(
              data.unmetManagerEffectivenessCompliancePrograms
            ),
            ...assertEdgesNonNull(data.unmetPeerAssessmentCompliancePrograms),
          ],
          (program) => {
            return program.id;
          }
        )
      : [];
  }, [data]);

  const hasMorePerformance =
    !!data?.unmetPerformanceAssessmentCompliancePrograms.pageInfo.hasNextPage;
  const hasMorePeer =
    data?.unmetPeerAssessmentCompliancePrograms.pageInfo.hasNextPage;
  const hasMoreManager =
    data?.unmetManagerEffectivenessCompliancePrograms.pageInfo.hasNextPage;
  const hasMorePages = hasMorePerformance || hasMorePeer || hasMoreManager;
  const handleLoadMore = () => {
    if (hasMorePages) {
      fetchMore({
        variables: {
          merge: true,
          afterPerformance: hasMorePerformance
            ? data.unmetPerformanceAssessmentCompliancePrograms.pageInfo
                .endCursor
            : undefined,
          afterManager: hasMoreManager
            ? data.unmetManagerEffectivenessCompliancePrograms.pageInfo
                .endCursor
            : undefined,
          afterPeer: hasMorePeer
            ? data.unmetPeerAssessmentCompliancePrograms.pageInfo.endCursor
            : undefined,
        },
      });
    }
  };

  const handleColumnSort = useCallback(
    (newSortKey: SortKey) => {
      if (newSortKey !== sortKey) {
        setSortKey(newSortKey);
      } else {
        setSortDir(sortDir === "asc" ? "desc" : "asc");
      }
    },
    [sortDir, sortKey]
  );

  if (loading) {
    return <Loading className="p-6 w-full mx-auto">Loading</Loading>;
  }

  const tableRows = applicablePrograms.map((program) => {
    const programAssessmentGroups = compact([
      program.performanceAssessmentGroup,
      program.managerAssessmentGroup,
      program.peerAssessmentGroup,
    ]);
    const missingAssessments = program.usersMissingAssessment.edges
      .map((edge) => assertNonNull(edge?.node))
      .filter(
        (missingAssessment) => missingAssessment.responder.id === currentUser.id
      )
      .filter(
        (missingAssessment) =>
          userFilter === null || missingAssessment.target.id === userFilter
      );

    return missingAssessments.map((missingAssessment) => {
      const assessmentGroup = assertNonNull(
        programAssessmentGroups.find(
          (g) => g.id === missingAssessment.assessmentGroupId
        )
      );
      const existingAssessment = program.assessments.edges.find(
        (edge) =>
          edge?.node?.target?.id === missingAssessment.target.id &&
          edge.node.group.id === missingAssessment.assessmentGroupId
      )?.node;
      return (
        <AssessmentRow
          key={`${assessmentGroup.id}_${missingAssessment.target.id}`}
          target={missingAssessment.target}
          program={program}
          assessmentGroup={assessmentGroup}
          existingAssessment={existingAssessment}
          isDisabled={isCreatingEmptyAssessment}
          onCreateEmptyAssessment={() =>
            handleCreateEmptyAssessment(
              program.id,
              assessmentGroup.id,
              missingAssessment.target.id
            )
          }
        />
      );
    });
  });

  const tableRowsWithContent = tableRows.filter((row) => row.length > 0);

  return applicablePrograms.length === 0 ? (
    <div className="text-gray-500">
      {`You have no ${label("review", {
        pluralize: true,
      })} to complete at this time`}
    </div>
  ) : (
    <>
      <TableContainer aria-label="Current assessments">
        <Table>
          <TableHeadRow>
            <TableHeadCell>Subject</TableHeadCell>
            <TableHeadCell
              sorted={sortKey === SortKey.program ? sortDir : undefined}
              onClick={() => handleColumnSort(SortKey.program)}
            >
              Program
            </TableHeadCell>
            <TableHeadCell>{`${label("review")} type`}</TableHeadCell>
            <TableHeadCell
              sorted={sortKey === SortKey.dueDate ? sortDir : undefined}
              onClick={() => handleColumnSort(SortKey.dueDate)}
            >
              Due date
            </TableHeadCell>
            <TableHeadCell className="w-64"></TableHeadCell>
          </TableHeadRow>
          <TableBody>
            {!loading && tableRowsWithContent.length === 0 && (
              <TableBodyRow>
                <TableBodyCell colSpan={5}>
                  {`You have no ${label("review", {
                    pluralize: true,
                  })} to complete at this time`}
                </TableBodyCell>
              </TableBodyRow>
            )}
            {tableRows}
          </TableBody>
        </Table>
      </TableContainer>
      <TableFooter>
        {hasMorePages && (
          <div className="flex justify-center">
            {loading ? (
              <Loading mini size="5" />
            ) : (
              <button
                onClick={handleLoadMore}
                className="text-gray-500 hover:underline"
              >
                View more
              </button>
            )}
          </div>
        )}
      </TableFooter>
    </>
  );
};

export default MyAssessmentsToAnswer;
