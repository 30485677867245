import { gql } from "@apollo/client";

import MyWorkHistoryItemFragment from "./my-work-history-item-fragment";

export default gql`
  ${MyWorkHistoryItemFragment}
  mutation updateMyWorkHistory($id: Int!, $data: WorkHistorySummaryInput!) {
    updateWorkHistory(workHistorySummaryId: $id, data: $data) {
      workHistorySummary {
        ...MyWorkHistoryItemFragment
      }
    }
  }
`;
