import { ExclamationIcon } from "@heroicons/react/outline";

import { currentUserVar } from "@cache/cache";
import BillingDialogCTA from "@components/billing-dialog-cta/billing-dialog-cta";
import Tooltip from "@components/tooltip/tooltip";
import { assertEdgesNonNull, assertNonNull } from "@helpers/helpers";

const SidebarBillingCta = ({
  menuIsCollapsed,
}: {
  menuIsCollapsed: boolean;
}) => {
  const currentUser = assertNonNull(currentUserVar());
  const organizations = assertEdgesNonNull(currentUser.organizations);

  // we want to show the trial notice if:
  // 1. The user _only_ has a personal org, and the personal org is on a trial
  // 2. Or, the user has a non-personal org on a trial

  const orgsWithTrials = organizations.filter(
    (org) => org.daysLeftOnTrial !== null
  );
  const nonPersonalOrgsWithTrials = orgsWithTrials.filter(
    (org) => org.personal === false
  );
  const nonPersonalOrgs = organizations.filter((org) => org.personal === false);

  // pick the first non-personal org trial, or the first personal org trial if there aren't any non-personal orgs
  const daysLeftOnTrial =
    nonPersonalOrgsWithTrials.length > 0
      ? assertNonNull(nonPersonalOrgsWithTrials[0].daysLeftOnTrial)
      : orgsWithTrials.length > 0 && nonPersonalOrgs.length === 0
      ? assertNonNull(orgsWithTrials[0].daysLeftOnTrial)
      : null;

  return daysLeftOnTrial !== null ? (
    menuIsCollapsed ? (
      <div className="flex justify-center">
        <Tooltip
          text={
            daysLeftOnTrial > 0
              ? `There are ${daysLeftOnTrial} days left in your ultimate-tier trial.`
              : "Your trial has expired."
          }
        >
          <span>
            <BillingDialogCTA className="text-yellow-600">
              <ExclamationIcon className="h-6 w-6" />
            </BillingDialogCTA>{" "}
          </span>
        </Tooltip>
      </div>
    ) : (
      <div className="px-4 py-4 border rounded border-yellow-300 bg-yellow-50 mx-4 mt-4 text-gray-600 text-sm">
        {daysLeftOnTrial > 0 ? (
          <div>
            There are {daysLeftOnTrial} days left in your ultimate-tier trial.{" "}
            <BillingDialogCTA className="text-blue-500 hover:underline">
              Contact us
            </BillingDialogCTA>{" "}
            to upgrade, or continue using the free tier.
          </div>
        ) : (
          <div>
            Your trial has expired.
            <BillingDialogCTA className="text-blue-500 hover:underline">
              Contact us
            </BillingDialogCTA>{" "}
            to upgrade.
          </div>
        )}
      </div>
    )
  ) : null;
};

export default SidebarBillingCta;
