import googleLogo2 from "@static/img/btn_google_signin_dark_normal_web@2x.png";
import googleLogo from "@static/img/google-24@2x.png";
import microsoftLogo from "@static/img/microsoft-24@2x.png";
import microsoftLogo2 from "@static/img/ms-symbollockup_signin_light.png";
import { PropsWithChildren } from "react";

import { classNames } from "@helpers/css";
import { getAssetUrl } from "@helpers/helpers";

type Props = {
  sso: string;
  text?: string;
  testId?: string;
  disabled?: boolean;
  className?: string;
  small?: boolean;
  url: string | null;
  onClick?: () => void;
};
const ButtonSSO: React.FC<PropsWithChildren<Props>> = ({
  sso = "google",
  text,
  testId = "",
  disabled = false,
  className = "",
  small = false,
  children,
  url = null,
  onClick = () => {},
}) => {
  const useOfficialButton = waffle.flag_is_active("use-official-sso-buttons");
  const button = {
    className: classNames(
      "inline-flex text-gray-800 rounded-full border font-medium items-center",
      disabled ? "opacity-50 cursor-default" : "hover:bg-gray-50",
      small ? "gap-3 py-1.5 px-3 text-sm" : "gap-4 py-3 pl-4 pr-5 text-base",
      className
    ),
    icon: "",
    text: text,
    width: 0,
    height: 0,
  };

  if (sso === "google") {
    button.icon = googleLogo2;
    button.text = text || "Sign in with Google";
    button.width = 191;
    button.height = 46;
  } else if (sso === "microsoft") {
    button.width = 215;
    button.height = 41;
    button.icon = microsoftLogo2;
    button.text = text || "Sign in with Microsoft";
  }
  if (useOfficialButton) {
    return url ? (
      <a href={url} data-testid={testId} aria-label={button.text}>
        <img
          src={getAssetUrl(button.icon)}
          alt={button.text}
          className={classNames("shrink-0")}
          width={button.width}
          height={button.height}
        />
      </a>
    ) : (
      <button onClick={onClick} data-testid={testId} aria-label={button.text}>
        <img
          src={getAssetUrl(button.icon)}
          alt={button.text}
          className={classNames("shrink-0")}
          width={button.width}
          height={button.height}
        />
      </button>
    );
  }

  if (sso === "google") {
    button.icon = googleLogo;
    button.text = text || "Sign in with Google";
  } else if (sso === "microsoft") {
    button.icon = microsoftLogo;
    button.text = text || "Sign in with Microsoft";
  }
  return url ? (
    <a
      href={url}
      data-testid={testId}
      className={button.className}
      aria-label={button.text}
    >
      <div className="">
        <img
          src={getAssetUrl(button.icon)}
          alt={button.text}
          className={classNames("shrink-0", small ? "h-4" : "h-6")}
        />
      </div>
      {children || button.text}
    </a>
  ) : (
    <button
      onClick={onClick}
      data-testid={testId}
      className={button.className}
      aria-label={button.text}
    >
      <div className="">
        <img
          src={getAssetUrl(button.icon)}
          alt={button.text}
          className={classNames("shrink-0", small ? "h-4" : "h-6")}
        />
      </div>
      {children || button.text}
    </button>
  );
};

export default ButtonSSO;
