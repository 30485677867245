import { useQuery } from "@apollo/client";
import { compact, uniqBy } from "lodash";
import { useState } from "react";
import { ArtifactType, GetKpiGoalsQueryQuery } from "types/graphql-schema";

import ArtifactCreationDialog from "@apps/artifact-creation-dialog/artifact-creation-dialog";
import Artifact from "@apps/artifact/artifact";
import useLabel from "@apps/use-label/use-label";
import Button, { buttonTheme } from "@components/button/button";
import Loading from "@components/loading/loading";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { assertEdgesNonNull } from "@helpers/helpers";

import getKpiGoalsQuery from "../graphql/get-kpi-goals-query";

const KPIGoals = ({
  kpi,
}: {
  kpi: {
    id: number;
    title: string;
  };
}) => {
  const [showArtifactCreationDialog, setShowArtifactCreationDialog] =
    useState(false);
  const label = useLabel();
  const { data, loading } = useQuery<GetKpiGoalsQueryQuery>(getKpiGoalsQuery, {
    variables: {
      kpiId: kpi.id,
      after: null,
    },
    onError: onNotificationErrorHandler(),
  });

  const goalsFromKr = data?.kpi
    ? assertEdgesNonNull(data.kpi.linkedKeyResults).map((node) => node.goal)
    : [];
  const goalsFromKPI = data?.kpi
    ? assertEdgesNonNull(data.kpi.linkedGoals).map((node) => node)
    : [];
  const goals = uniqBy(compact(goalsFromKr.concat(goalsFromKPI)), "id");

  // RENDER
  return loading ? (
    <Loading>Loading {label("goal", { pluralize: true })}</Loading>
  ) : (
    <div>
      {goals.length > 0 ? (
        <div>
          <div className="border rounded-lg divide-y">
            {goals.map((goal) => (
              <div key={goal.id} className="px-4 py-2">
                <Artifact artifact={goal} />
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="text-gray-500 italic text-sm">
          No {label("goal", { pluralize: true })} connected to this KPI.
        </div>
      )}
      <div className="mt-8">
        {showArtifactCreationDialog && (
          <ArtifactCreationDialog
            formOptions={{
              artifactType: ArtifactType.Goal,
              kpi: {
                id: kpi.id,
                title: kpi.title,
              },
            }}
            hideTypeSelector
            onClose={() => {
              setShowArtifactCreationDialog(false);
            }}
            refetchQueries={[getKpiGoalsQuery]}
          />
        )}
        <Button
          theme={buttonTheme.primary}
          onClick={() => setShowArtifactCreationDialog(true)}
          text={`Create ${label("goal")}`}
          small
        />
      </div>
    </div>
  );
};

export default KPIGoals;
