import { gql } from "@apollo/client";

import invitationFragment from "./invitation-fragment";

export default gql`
  ${invitationFragment}
  query getInvitationQuery($inviteKey: String!) {
    invitation(inviteKey: $inviteKey) {
      ...InvitationFragment
    }
  }
`;
