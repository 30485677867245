import { gql } from "@apollo/client";

export default gql`
  mutation createNoteCommentMutation(
    $uuid: String!
    $comment: String!
    $topicId: Int
    $artifactId: Int
  ) {
    createOrUpdateComment(
      comment: $comment
      textUuid: $uuid
      topicId: $topicId
      artifactId: $artifactId
    ) {
      comment {
        id
        creator {
          id
          name
          avatar
        }
        created
        comment
      }
    }
  }
`;
