import { gql } from "@apollo/client";

export default gql`
  mutation sendAssessmentReminder($complianceProgramId: Int!) {
    emailAssessmentReminder(complianceProgramId: $complianceProgramId) {
      complianceProgram {
        id
      }
    }
  }
`;
