import ListItem from "@tiptap/extension-list-item";

const CustomListItem = ListItem.extend({
  name: "listItem",
  content: "block+", // to allow artifacts in list
  addKeyboardShortcuts() {
    return {
      Enter: () => this.editor.commands.splitListItem(this.name),
      Tab: () => {
        this.editor.commands.sinkListItem(this.name);
        return true;
      },
      "Shift-Tab": () => this.editor.commands.liftListItem(this.name),
    };
  },
});

export default CustomListItem;
