import { compact, sortBy, uniqBy } from "lodash";

import { currentUserVar } from "@cache/cache";
import ComboboxGeneric from "@components/combobox/generic-combobox";
import { ComboboxGenericOption } from "@components/combobox/generic-combobox";
import { assertEdgesNonNull } from "@helpers/helpers";

import ExplorerComboboxButton from "./explorer-combobox-button";

const ExplorerOrganizationCombobox = ({
  prefix,
  organizationId,
  ariaLabel,
  onSelectOrganizationId,
  className,
}: {
  prefix: string;
  ariaLabel: string;
  organizationId?: number | null;
  onSelectOrganizationId: (organizationId: number | null) => void;
  className: string;
}) => {
  const currentUser = currentUserVar();
  const organizations = assertEdgesNonNull(currentUser.organizations);
  const matchingOrganization = organizations.find(
    ({ id }) => id === organizationId
  );

  // Computed data
  const mergedOptions = sortBy(
    compact([matchingOrganization].concat(organizations)),
    ({ name }) => name.toLowerCase()
  );
  const options = uniqBy(mergedOptions, "id").map((option) => ({
    ...option,
    label: option.name,
    value: option.id,
  }));
  const selectedOption =
    options.find(({ value }) => value === organizationId) || null;

  const handleChangeValue = (option: ComboboxGenericOption<number | null>) => {
    onSelectOrganizationId(option.value);
  };

  const handleClearValue = () => {
    onSelectOrganizationId(null);
  };

  return (
    <div className="flex-1 min-w-0">
      <ComboboxGeneric<number | null>
        width="full"
        value={selectedOption}
        options={options}
        clearable={!!selectedOption}
        onChangeValue={handleChangeValue}
        onClearValue={handleClearValue}
        aria-label={ariaLabel}
        placeholder="Search organization..."
        name="organization-combobox"
      >
        {({ value, setReferenceElement, clearable }) => (
          <ExplorerComboboxButton
            prefix={prefix}
            ariaLabel="Explorer organization picker button"
            setReferenceElement={setReferenceElement}
            label={value?.label || "All"}
            clearable={clearable}
            onClear={handleClearValue}
            className={className}
          />
        )}
      </ComboboxGeneric>
    </div>
  );
};

export default ExplorerOrganizationCombobox;
