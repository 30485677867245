import { gql } from "@apollo/client";

export const SidebarMeetingFragment = gql`
  fragment SidebarMeetingFragment on MeetingNode {
    id
    title
    draft
    isRecurring
    startDate
    startDatetime
    endDatetime
    externalUrl
    ignored
    videoConferenceType
    videoConferenceUrl
    meetingGroup {
      id
      isFormalOneonone
      facilitator {
        id
        name
      }
      hasTemplate
    }
    organizer {
      id
      name
    }
    canDelete {
      permission
    }
    canUpdate {
      permission
    }
  }
`;

export default gql`
  ${SidebarMeetingFragment}
  query getSidebarDataQuery(
    $participants: [Int]!
    $startDatetimeGte: DateTime!
    $startDatetimeLte: DateTime!
  ) {
    meetings(
      startDatetime_Gte: $startDatetimeGte
      startDatetime_Lte: $startDatetimeLte
      orderBy: "startDatetime"
      ignored: false
      participants: $participants
      status: confirmed
    ) {
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          id
          ...SidebarMeetingFragment
        }
      }
    }
    unscheduledMeetings: meetings(
      unscheduled: true
      participants: $participants
      ignored: false
    ) {
      edges {
        node {
          id
          ...SidebarMeetingFragment
        }
      }
    }
    me {
      id
      calendarIsSyncingForFirstTime
      hasCalendarSyncError
    }
  }
`;
