import { gql } from "@apollo/client";

export default gql`
  query refreshCalendarsQuery {
    loggedIn
    me {
      id
      syncCredentials {
        edges {
          node {
            id
            provider
            credentialsUid
            hasValidCalendarCredentials
            hasCalendarWriteCredentials
          }
        }
      }
    }
  }
`;
