import { gql } from "@apollo/client";

export default gql`
  mutation saveOrgWideCompetency(
    $competencyId: Int
    $organizationId: Int
    $title: String
    $description: String
    $appliesTo: CompetencyAppliesTo
    $criteriaUniqueness: CompetencyCriteriaUniqueness
    $appliesToManagementRoles: Boolean
    $appliesToIcRoles: Boolean
    $appliesToTeams: [Int]
    $appliesToCareerTracks: [Int]
    $criteria: [CompetencyCriteriaInput]
  ) {
    createOrUpdateCompetency(
      competencyId: $competencyId
      organizationId: $organizationId
      title: $title
      description: $description
      appliesTo: $appliesTo
      criteriaUniqueness: $criteriaUniqueness
      appliesToManagementRoles: $appliesToManagementRoles
      appliesToIcRoles: $appliesToIcRoles
      appliesToTeams: $appliesToTeams
      appliesToCareerTracks: $appliesToCareerTracks
      criteria: $criteria
    ) {
      competency {
        id
        title
        description
        appliesTo
      }
    }
  }
`;
