import { useMutation, useQuery } from "@apollo/client";
import { EyeOffIcon } from "@heroicons/react/outline";
import { flatMap } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  AssessmentDeliveryState,
  AssessmentQuestionResponseVisibility,
  AssessmentQuestionType,
  GetAssessmentDeliveryQuery,
  GetAssessmentDeliveryQueryVariables,
  MultiChoiceAssessmentAnswerNode,
  MultiChoiceAssessmentQuestionNode,
  RangeAssessmentAnswerNode,
  RangeAssessmentQuestionNode,
  SaveAssessmentDeliveryMutation,
  SaveAssessmentDeliveryMutationVariables,
  TextAssessmentAnswerNode,
  TextAssessmentQuestionNode,
} from "types/graphql-schema";
import { BasicUser, TFLocationState } from "types/topicflow";

import useLabel from "@apps/use-label/use-label";
import { successNotificationVar } from "@cache/cache";
import Avatar from "@components/avatar/avatar";
import Button, { buttonTheme } from "@components/button/button";
import { useLink } from "@components/link/link";
import Loading from "@components/loading/loading";
import Tooltip from "@components/tooltip/tooltip";
import useDebounce from "@components/use-debounce/use-debounce";
import {
  onNotificationErrorHandler,
  useNotificationError,
} from "@components/use-error/use-error";
import { isEmptyValue } from "@components/wysiwyg/helpers";
import TextareaWysiwyg from "@components/wysiwyg/textarea-wysiwyg";
import { classNames } from "@helpers/css";
import { assertEdgesNonNull, assertNonNull } from "@helpers/helpers";
import useConfirm from "@helpers/hooks/use-confirm";

import getAssessmentDelivery from "../graphql/get-assessment-delivery";
import updateAssessmentDeliveryMutation from "../graphql/update-assessment-delivery-mutation";
import { bgClassName } from "../helpers";
import AssessmentQuestionMultiSelectOptions from "./assessment-question-multi-select-options";
import AssessmentQuestionRangeOptions from "./assessment-question-range-options";

export const AssessmentDeliveryQuestion = ({
  canUpdate,
  excludedAnswerIds,
  answers,
  index,
  question,
  isQuestionWeightingEnabled,
  weight,
  totalQuestionWeight,
  target,
  onUpdateExcludedAnswers,
}: {
  canUpdate: boolean;
  excludedAnswerIds: number[];
  isQuestionWeightingEnabled: boolean;
  answers: ((
    | Pick<
        RangeAssessmentAnswerNode,
        "__typename" | "id" | "integerAnswer" | "comment"
      >
    | Pick<
        MultiChoiceAssessmentAnswerNode,
        "__typename" | "id" | "choices" | "comment"
      >
    | Pick<
        TextAssessmentAnswerNode,
        "__typename" | "id" | "textAnswer" | "comment"
      >
  ) & { question: { id: number }; responder?: BasicUser | null })[];
  index: number;
  question:
    | Pick<
        RangeAssessmentQuestionNode,
        | "__typename"
        | "id"
        | "title"
        | "description"
        | "labels"
        | "labelDescriptions"
        | "startValue"
        | "endValue"
        | "questionType"
        | "isCommentMandatory"
        | "responseVisibility"
      >
    | Pick<
        MultiChoiceAssessmentQuestionNode,
        | "__typename"
        | "id"
        | "title"
        | "description"
        | "options"
        | "optionDescriptions"
        | "questionType"
        | "isCommentMandatory"
        | "responseVisibility"
      >
    | Pick<
        TextAssessmentQuestionNode,
        | "__typename"
        | "id"
        | "title"
        | "description"
        | "questionType"
        | "isCommentMandatory"
        | "responseVisibility"
      >;
  weight: number;
  totalQuestionWeight: number;
  target?: BasicUser | null;
  onUpdateExcludedAnswers: (excludedAnswerIds: number[]) => void;
}) => {
  if (!canUpdate && answers.length === 0) {
    return null;
  }

  const hiddenFromSubject =
    question.responseVisibility ===
    AssessmentQuestionResponseVisibility.HiddenFromSubject;
  return (
    <div
      key={question.id}
      className="border-b py-6"
      aria-label={`Assessment question: ${question.title}`}
    >
      <div className="font-bold ">
        <span>
          {index + 1}. {question.title}
        </span>
        {!canUpdate && hiddenFromSubject && (
          <Tooltip text="Question hidden from subject">
            <EyeOffIcon className="text-gray-400 h-5 w-5 inline-block ml-2" />
          </Tooltip>
        )}
      </div>
      {!isEmptyValue(question.description) && (
        <TextareaWysiwyg
          className="max-w-max text-gray-400"
          value={question.description}
        />
      )}

      {hiddenFromSubject && canUpdate && (
        <div className="mt-2 text-sm text-gray-400">
          This question is hidden from the subject.
        </div>
      )}

      <div className="mt-4 flex flex-col gap-4">
        {answers?.map((answer) => {
          const comment = answer?.comment ?? "{}";
          const textAnswer =
            answer.__typename === "TextAssessmentAnswerNode"
              ? answer.textAnswer
              : "{}";
          const isExcluded = excludedAnswerIds.includes(answer.id);

          const weightedScore = answer &&
            answer.__typename === "RangeAssessmentAnswerNode" &&
            question.questionType === AssessmentQuestionType.Range && (
              <span>
                Weighted score:{" "}
                {(
                  (weight * assertNonNull(answer.integerAnswer)) /
                  totalQuestionWeight
                ).toFixed(1)}
              </span>
            );
          const responder = (
            <div className="flex items-center text-sm mb-2">
              {canUpdate && (
                <input
                  className="mr-2"
                  type="checkbox"
                  checked={!isExcluded}
                  disabled={hiddenFromSubject}
                  onChange={(evt) => {
                    const newAnswers = !evt.target.checked
                      ? excludedAnswerIds.concat([answer.id])
                      : excludedAnswerIds.filter((a) => a !== answer.id);
                    onUpdateExcludedAnswers(newAnswers);
                  }}
                />
              )}
              <Avatar className="mr-1" user={answer.responder} size="4" />{" "}
              {answer.responder?.name ?? "Anonymous"}
              {answer.responder?.id === target?.id ? " (Self)" : ""}
              {isExcluded ? " [excluded]" : ""}
            </div>
          );

          if (isExcluded) {
            return <div key={answer.id}>{responder}</div>;
          }

          if (question.__typename === "RangeAssessmentQuestionNode") {
            return (
              <div key={answer.id}>
                {responder}
                <AssessmentQuestionRangeOptions
                  answer={
                    answer.__typename === "RangeAssessmentAnswerNode"
                      ? answer.integerAnswer
                      : null
                  }
                  disabled
                  labels={question.labels}
                  labelDescriptions={question.labelDescriptions}
                  startValue={question.startValue}
                  endValue={question.endValue}
                  showIntegerValues={isQuestionWeightingEnabled}
                />
                {answer && !isEmptyValue(answer.comment) && (
                  <div className="mt-2 flex flex-col gap-2">
                    <div className="flex items-center justify-between">
                      <div className="text-gray-500 text-xs uppercase font-semibold">
                        Comment
                      </div>
                      {isQuestionWeightingEnabled && (
                        <div className="text-sm text-gray-500">
                          {weightedScore}
                        </div>
                      )}
                    </div>
                    <TextareaWysiwyg
                      editable={false}
                      className="mt-1 bg-white"
                      value={comment}
                    />
                  </div>
                )}
                {answer &&
                  isEmptyValue(answer.comment) &&
                  isQuestionWeightingEnabled && (
                    <div className="mt-2 flex justify-end">
                      <div className="text-sm text-gray-500">
                        {weightedScore}
                      </div>
                    </div>
                  )}
              </div>
            );
          }
          if (question.__typename === "MultiChoiceAssessmentQuestionNode") {
            return (
              <div key={answer.id}>
                {responder}
                <AssessmentQuestionMultiSelectOptions
                  choices={
                    answer.__typename === "MultiChoiceAssessmentAnswerNode"
                      ? answer.choices
                      : null
                  }
                  disabled
                  options={question.options}
                  optionDescriptions={question.optionDescriptions}
                />
                {answer && !isEmptyValue(answer.comment) && (
                  <div className="mt-2 flex flex-col gap-2">
                    <div className="flex items-center justify-between">
                      <div className="text-gray-500 text-xs uppercase font-semibold">
                        Comment
                      </div>
                    </div>
                    <TextareaWysiwyg
                      editable={false}
                      className="mt-1 bg-white"
                      value={comment}
                    />
                  </div>
                )}
              </div>
            );
          }

          if (question.__typename === "TextAssessmentQuestionNode") {
            return (
              <div key={answer.id}>
                {responder}
                <TextareaWysiwyg
                  key={answer.id}
                  editable={false}
                  className="bg-white"
                  value={textAnswer}
                  deps={[answer.id]}
                />
              </div>
            );
          }
          return null;
        })}
      </div>
    </div>
  );
};

export const AssessmentDeliveryContent = ({
  assessmentDeliveryData,
  showHiddenQuestions,
  excludedAnswerIds,
  summary,
  canUpdate,
  isDraft,
  onUpdateDeliveryData,
}: {
  showHiddenQuestions: boolean;
  assessmentDeliveryData: GetAssessmentDeliveryQuery;
  summary: JSON | string | null;
  excludedAnswerIds: number[];
  canUpdate: boolean;
  isDraft: boolean;
  onUpdateDeliveryData?: (attrs: any) => void;
}) => {
  const assessmentGroup = useMemo(
    () =>
      assessmentDeliveryData?.assessmentDelivery
        ? assessmentDeliveryData.assessmentDelivery?.group
        : null,
    [assessmentDeliveryData]
  );
  const target = useMemo(
    () =>
      assessmentDeliveryData?.assessmentDelivery
        ? assessmentDeliveryData.assessmentDelivery?.target
        : null,
    [assessmentDeliveryData]
  );

  const sectionNodes = useMemo(() => {
    if (!assessmentGroup) {
      return [];
    }
    return assertEdgesNonNull(assessmentGroup.sections);
  }, [assessmentGroup]);

  const questionNodes = useMemo(() => {
    if (!assessmentGroup) {
      return [];
    }
    return flatMap(sectionNodes, (section) =>
      assertEdgesNonNull(section.questions)
    );
  }, [assessmentGroup, sectionNodes]);

  const allAnswers = useMemo(() => {
    if (!assessmentDeliveryData?.assessmentDelivery) {
      return [];
    }
    const allAnswers = assertEdgesNonNull(
      assessmentDeliveryData?.assessmentDelivery.answers
    );
    return allAnswers;
  }, [assessmentDeliveryData]);

  const totalQuestionWeight = useMemo(
    () => questionNodes.reduce((sum, node) => sum + node.weight, 0),
    [questionNodes]
  );

  const totalWeightedScore = useMemo(() => {
    return questionNodes
      .filter(
        (questionNode) =>
          questionNode.question.__typename === "RangeAssessmentQuestionNode"
      )
      .reduce((sum, node) => {
        const answer = allAnswers.find(
          (answer) => answer.question.id === node.question.id
        );
        if (!answer || answer.__typename !== "RangeAssessmentAnswerNode") {
          return sum;
        }
        return (
          sum +
          (node.weight * assertNonNull(answer.integerAnswer)) /
            totalQuestionWeight
        );
      }, 0);
  }, [allAnswers, questionNodes, totalQuestionWeight]);
  return (
    <>
      {sectionNodes.map((section) => {
        const sectionQuestions = assertEdgesNonNull(section.questions);
        const hasVisibleQuestions = sectionQuestions.some((question) => {
          const answers = allAnswers.filter(
            (answer) => answer.question.id === question.question.id
          );
          return answers.length > 0;
        });
        if (!hasVisibleQuestions) {
          return null;
        }
        const heading = section.title ? (
          <div>
            <div className="font-bold text-xl">{section.title}</div>
            {section.description && !isEmptyValue(section.description) && (
              <TextareaWysiwyg
                editable={false}
                className="mt-1 bg-white"
                value={section.description}
              />
            )}
          </div>
        ) : null;

        return (
          <div key={section.id}>
            {heading}
            {section.questions.edges
              .filter((edge) => {
                if (
                  edge?.node?.question.responseVisibility ===
                    AssessmentQuestionResponseVisibility.HiddenFromSubject &&
                  !showHiddenQuestions
                )
                  return false;
                return true;
              })
              .map((edge, index) => {
                const node = assertNonNull(edge?.node);
                const { question, weight } = node;
                const answers = allAnswers.filter(
                  (answer) => answer.question.id === question.id
                );
                return (
                  <AssessmentDeliveryQuestion
                    key={question.id}
                    canUpdate={!!canUpdate && isDraft}
                    excludedAnswerIds={excludedAnswerIds}
                    onUpdateExcludedAnswers={(newExcludedAnswerIds) => {
                      if (onUpdateDeliveryData) {
                        onUpdateDeliveryData({
                          excludedAnswerIds: newExcludedAnswerIds,
                        });
                      }
                    }}
                    answers={answers}
                    question={question}
                    weight={weight}
                    totalQuestionWeight={totalQuestionWeight}
                    index={index}
                    isQuestionWeightingEnabled={
                      !!assessmentGroup?.isQuestionWeightingEnabled
                    }
                    target={target}
                  />
                );
              })}
          </div>
        );
      })}

      {((canUpdate && isDraft) || !isEmptyValue(summary)) && (
        <>
          <div className="text-gray-500 text-xs uppercase font-semibold">
            Summary
          </div>
          <TextareaWysiwyg
            editable={!!canUpdate && isDraft}
            className="mt-1 bg-white"
            value={summary}
            onChangeValue={(newSummary) => {
              if (onUpdateDeliveryData)
                onUpdateDeliveryData({
                  summary: newSummary,
                });
            }}
          />
        </>
      )}

      <div className="text-sm text-gray-500">
        {assessmentGroup?.isQuestionWeightingEnabled && (
          <span>Total weighted score: {totalWeightedScore.toFixed(1)}</span>
        )}
      </div>
    </>
  );
};

type DeliveryData = {
  excludedAnswerIds: number[];
  summary: string | null;
};

const AssessmentDelivery = () => {
  const { assessmentDeliveryId: assessmentDeliveryIdParam } = useParams<{
    assessmentDeliveryId: string;
  }>();
  const assessmentDeliveryId = parseInt(assessmentDeliveryIdParam);
  const location = useLocation<TFLocationState>();
  const backUrl = location.state?.previousPathname || "/assessments";
  const { onError } = useNotificationError();
  const link = useLink();
  const label = useLabel();

  const [proposedDeliveryData, setProposedDeliveryData] =
    useState<DeliveryData | null>(null);
  const [isSaving, setIsSaving] = useState(false);
  const debouncedDeliveryData = useDebounce(proposedDeliveryData, 500);

  const { data: assessmentDeliveryData, loading: isLoadingAssessment } =
    useQuery<GetAssessmentDeliveryQuery, GetAssessmentDeliveryQueryVariables>(
      getAssessmentDelivery,
      {
        variables: {
          assessmentDeliveryId,
        },
        onCompleted: (response) => {
          if (response.assessmentDelivery) {
            const excludedAnswerIds = response.assessmentDelivery
              .excludedAnswerIds as number[];
            setProposedDeliveryData({
              summary: response.assessmentDelivery.summary,
              excludedAnswerIds,
            });
          }
        },
        onError: onNotificationErrorHandler(),
      }
    );

  const [saveAssessmentDelivery] = useMutation<
    SaveAssessmentDeliveryMutation,
    SaveAssessmentDeliveryMutationVariables
  >(updateAssessmentDeliveryMutation);

  const canUpdate = useMemo(
    () =>
      assessmentDeliveryData?.assessmentDelivery &&
      assessmentDeliveryData.assessmentDelivery.canUpdate?.permission,
    [assessmentDeliveryData]
  );

  useEffect(() => {
    if (
      !assessmentDeliveryData?.assessmentDelivery ||
      !canUpdate ||
      debouncedDeliveryData === null
    ) {
      return;
    }
    saveAssessmentDelivery({
      variables: {
        assessmentDeliveryId,
        ...debouncedDeliveryData,
      },
      onCompleted: () => setIsSaving(false),
      onError,
    });
    // autosave, only fire to backend when debounced data changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedDeliveryData]);

  const assessmentGroup = useMemo(
    () =>
      assessmentDeliveryData?.assessmentDelivery
        ? assessmentDeliveryData.assessmentDelivery?.group
        : null,
    [assessmentDeliveryData]
  );
  const program = useMemo(
    () =>
      assessmentDeliveryData?.assessmentDelivery
        ? assessmentDeliveryData.assessmentDelivery?.complianceProgram
        : null,
    [assessmentDeliveryData]
  );
  const target = useMemo(
    () =>
      assessmentDeliveryData?.assessmentDelivery
        ? assessmentDeliveryData.assessmentDelivery?.target
        : null,
    [assessmentDeliveryData]
  );

  const handleUpdateDeliveryData = useCallback(
    (data: Partial<DeliveryData>) => {
      if (proposedDeliveryData === null) {
        return;
      }
      setProposedDeliveryData({ ...proposedDeliveryData, ...data });
      setIsSaving(true);
    },
    [proposedDeliveryData]
  );

  const {
    ConfirmationDialog: DeliverConfirmationDialog,
    confirm: confirmDeliver,
  } = useConfirm(
    "Are you sure?",
    `This will deliver the responses you have selected to ${
      assessmentDeliveryData?.assessmentDelivery?.target?.name
    }. Any further responses to this ${label(
      "review"
    )} with automatically be added to the delivery package. Do you want to continue?`
  );
  const handleDeliver = useCallback(async () => {
    const confirmation = await confirmDeliver();
    if (confirmation) {
      saveAssessmentDelivery({
        variables: {
          assessmentDeliveryId,
          state: AssessmentDeliveryState.Delivered,
        },
        onCompleted: () => {
          successNotificationVar({
            title: `${label("review", {
              capitalize: true,
            })} package delivered`,
          });
          link.redirect(backUrl);
        },
        onError,
      });
    }
  }, [
    confirmDeliver,
    saveAssessmentDelivery,
    assessmentDeliveryId,
    onError,
    label,
    link,
    backUrl,
  ]);

  const {
    ConfirmationDialog: ReopenConfirmationDialog,
    confirm: confirmReopen,
  } = useConfirm(
    "Are you sure?",
    `Reopening the ${label(
      "review"
    )} delivery package will remove the subject's access until it is redelivered.`
  );
  const handleReopen = useCallback(async () => {
    const confirmation = await confirmReopen();
    if (confirmation) {
      saveAssessmentDelivery({
        variables: {
          assessmentDeliveryId,
          state: AssessmentDeliveryState.Draft,
        },
        onCompleted: () => {
          successNotificationVar({
            title: `${label("review", {
              capitalize: true,
            })} package reverted to in progress`,
          });
        },
        refetchQueries: [getAssessmentDelivery],
        onError,
      });
    }
  }, [
    assessmentDeliveryId,
    confirmReopen,
    onError,
    saveAssessmentDelivery,
    label,
  ]);

  if (isLoadingAssessment || !proposedDeliveryData) {
    return (
      <Loading className={classNames(bgClassName, "p-6 w-full mx-auto")}>
        Loading
      </Loading>
    );
  }

  if (!assessmentGroup || !program) {
    return (
      <div
        className={classNames(bgClassName, "flex-1 flex justify-center p-10")}
      >
        {label("review", { capitalize: true })} delivery package not found
      </div>
    );
  }

  const isDraft =
    assessmentDeliveryData?.assessmentDelivery?.state !==
    AssessmentDeliveryState.Delivered;

  return (
    <div className="flex-1">
      <ReopenConfirmationDialog />
      <DeliverConfirmationDialog />
      <div
        className={classNames(bgClassName, "flex flex-col lg:flex-row")}
        aria-label="Assessments > performance assessment form"
      >
        <div className="w-full lg:w-72 flex flex-col text-sm gap-4 bg-gray-50 border-b lg:border-r p-6 rounded-t-lg lg:rounded-tr-none lg:rounded-l-lg">
          <div className="font-bold text-lg">{assessmentGroup.title}</div>
          <div className="flex flex-col gap-1">
            <div className="mt-2 italic text-gray-500">Subject:</div>
            <div className="flex items-center">
              <Avatar className="mr-1" user={assertNonNull(target)} size="8" />{" "}
              {target?.name}
            </div>
          </div>
          <div className="text-gray-500">
            Program: <span className="font-bold">{program.title}</span>
          </div>
          <div className="text-gray-500">
            Program period:{" "}
            <span className="font-bold">
              {program.periodStartDate && program.periodEndDate
                ? `${moment(program.periodStartDate).format(
                    "MMM D, YYYY"
                  )} - ${moment(program.periodEndDate).format("MMM D, YYYY")}`
                : "Not set"}
            </span>
          </div>
          <div className="text-gray-500">
            Due date:{" "}
            <span className="font-bold">
              {moment(program.dueDate).format("MMM D, YYYY")}
            </span>
          </div>
          {!isDraft && (
            <div className="text-gray-500">
              Delivery:{" "}
              <span className="font-bold">
                {assessmentDeliveryData.assessmentDelivery.deliveryDatetime
                  ? `Delivered by ${
                      assessmentDeliveryData.assessmentDelivery.creator?.name
                    } on ${moment(
                      assessmentDeliveryData.assessmentDelivery.deliveryDatetime
                    ).format("MMM D, YYYY @ h:mma")}`
                  : "Immediate access"}
              </span>
              {canUpdate &&
                assessmentDeliveryData.assessmentDelivery.deliveryDatetime && (
                  <div className="mt-2 flex items-center">
                    <Button disabled={isSaving} onClick={handleReopen}>
                      Reopen delivery package
                    </Button>
                    {isSaving && <Loading className="mr-4" mini size="5" />}
                  </div>
                )}
            </div>
          )}
        </div>
        <div className="flex-1 p-6 flex flex-col gap-4 bg-white rounded-b-lg lg:rounded-r-lg">
          <div className="flex items-center justify-between gap-6">
            <Button to={backUrl}>Back</Button>
          </div>
          {assessmentDeliveryData && (
            <AssessmentDeliveryContent
              assessmentDeliveryData={assessmentDeliveryData}
              canUpdate={!!canUpdate}
              isDraft={isDraft}
              excludedAnswerIds={proposedDeliveryData.excludedAnswerIds}
              summary={proposedDeliveryData.summary}
              onUpdateDeliveryData={handleUpdateDeliveryData}
              showHiddenQuestions
            />
          )}

          {canUpdate && isDraft && (
            <div className="mt-2 flex items-center justify-end">
              {isSaving && <Loading className="mr-4" mini size="5" />}
              <Button
                theme={buttonTheme.primary}
                disabled={isSaving}
                onClick={handleDeliver}
              >
                Deliver to{" "}
                {assessmentDeliveryData?.assessmentDelivery?.target?.name}
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AssessmentDelivery;
