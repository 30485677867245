import { useQuery } from "@apollo/client";
import a from "indefinite";
import { compact } from "lodash";
import { useEffect, useRef } from "react";
import {
  ArtifactType,
  FeedbackState,
  GetArtifactSidebarQueryQuery,
  GetArtifactSidebarQueryQueryVariables,
} from "types/graphql-schema";

import ArtifactActivities from "@apps/artifact-sidebar/components/artifact-activities";
import SidebarLabel from "@apps/artifact-sidebar/components/sidebar-label";
import SidebarRow from "@apps/artifact-sidebar/components/sidebar-row";
import ArtifactActionItemCompleteToggle from "@apps/artifact/components/action-item-complete-toggle";
import ActionItemAssignee from "@apps/artifact/components/artifact-assignee";
import ArtifactDueDate from "@apps/artifact/components/artifact-due-date";
import DecisionStateComponent from "@apps/artifact/components/decision-state";
import useLabel from "@apps/use-label/use-label";
import { currentOrganizationVar } from "@cache/cache";
import Avatar from "@components/avatar/avatar";
import AppLink from "@components/link/link";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { maxKeyResults } from "@helpers/constants";
import { getUrlUsingTypename } from "@helpers/helpers";

import Actions from "./components/actions";
import ArtifactSidebarCannotRead from "./components/cannot-read";
import DiscussionNotes from "./components/discussion-notes";
import ArtifactSidebarError from "./components/error";
import ArtifactSidebarFeedback from "./components/feedback";
import ArtifactSidebarHeader from "./components/header";
import ArtifactSidebarLoading from "./components/loading";
import ArtifactSidebarRecognition from "./components/recognition";
import ArtifactSidebarGoalSection from "./goal-section";
import getArtifactSidebarQuery from "./graphql/get-artifact-sidebar-query";

const ArtifactSidebarContent = ({
  artifactId,
  meetingId,
  meetingGroupId,
  onClose,
}: {
  artifactId: number;
  meetingId?: number;
  meetingGroupId?: number;
  onClose: (option: { redirect: boolean }) => void;
}) => {
  const currentOrganization = currentOrganizationVar();
  const actionItemStates = currentOrganization.actionItemStates
    ? compact(currentOrganization.actionItemStates)
    : [];
  const completeActionItemState = actionItemStates.find(
    (state) => state.isComplete
  );
  const focusRef = useRef(null);
  const label = useLabel();
  const { loading, error, data, previousData } = useQuery<
    GetArtifactSidebarQueryQuery,
    GetArtifactSidebarQueryQueryVariables
  >(getArtifactSidebarQuery, {
    variables: {
      artifactId: artifactId,
      keyResultLimit: maxKeyResults,
    },
    errorPolicy: "ignore",
    onError: onNotificationErrorHandler(),
  });
  const artifact = data?.artifact;

  const handleCloseSidebar = () => {
    onClose({ redirect: false });
  };
  const handleDeleteArtifact = () => {
    onClose({ redirect: true });
  };

  useEffect(() => {
    // when the sidebar is displayed but then it is not accessible anymore
    // happens usually when deleting an artifact
    if (data?.artifact === null && previousData?.artifact?.id) {
      onClose({ redirect: true });
    }
  }, [data, previousData, onClose]);

  return !data && loading ? (
    <ArtifactSidebarLoading onClose={handleCloseSidebar} />
  ) : error ? (
    <ArtifactSidebarError onClose={handleCloseSidebar} />
  ) : artifact && !artifact.canRead.permission ? (
    <ArtifactSidebarCannotRead onClose={handleCloseSidebar} />
  ) : artifact ? (
    <div className="-mt-6" aria-label="Sidebar artifact" ref={focusRef}>
      <div className="px-4 sm:px-6 py-3 bg-white flex justify-between gap-4">
        {artifact.__typename === "RecognitionArtifactNode" ? (
          <div className="flex-1 flex items-center gap-2 pt-2">
            <Avatar user={artifact.creator} size={6} />
            {artifact.creator ? (
              <div className="text-normal font-medium text-gray-600">
                <AppLink
                  to={getUrlUsingTypename(artifact.creator)}
                  className="hover:underline"
                >
                  {artifact.creator.name}
                </AppLink>{" "}
                gave {a(label("recognition"))} to:
              </div>
            ) : (
              <div className="text-normal font-medium text-gray-600">
                {a(label("recognition"))} was given to:
              </div>
            )}
          </div>
        ) : (
          <ArtifactSidebarHeader artifact={artifact} />
        )}
        <div className="pt-2">
          <Actions
            focusRef={focusRef}
            artifact={artifact}
            onClose={handleCloseSidebar}
            onDelete={handleDeleteArtifact}
            loading={loading}
          />
        </div>
      </div>

      {artifact.__typename === "ActionItemArtifactNode" && (
        <div className="px-4 sm:px-6 pb-6 bg-white flex gap-6 items-center text-gray-700">
          <div className="-ml-1 flex items-center">
            <ArtifactActionItemCompleteToggle
              artifact={artifact}
              className="px-1 py-1 hover:bg-gray-100 rounded"
            />
            <span className="text-sm text-gray-700">
              {completeActionItemState?.label || "Done"}
            </span>
          </div>
          <div className="flex items-center">
            <ActionItemAssignee
              artifact={artifact}
              className="hover:bg-gray-100 rounded px-1 py-1"
            />
          </div>
          <div className="flex items-center group">
            <ArtifactDueDate
              emptyValue="Due date"
              labelName="due date"
              artifact={artifact}
              size="5"
              alwaysShowCalendarIcon
              className="text-sm px-2 py-1"
            />
          </div>
        </div>
      )}

      {artifact.__typename === "GoalArtifactNode" && (
        <ArtifactSidebarGoalSection artifact={artifact} />
      )}

      {artifact.__typename === "DecisionArtifactNode" && (
        <div className="pb-4 bg-white">
          <SidebarRow>
            <SidebarLabel>State</SidebarLabel>
            <DecisionStateComponent artifact={artifact} />
          </SidebarRow>
        </div>
      )}

      {artifact.artifactType === ArtifactType.Recognition && (
        <ArtifactSidebarRecognition artifact={artifact} />
      )}
      {artifact.artifactType === ArtifactType.Feedback && (
        <ArtifactSidebarFeedback artifact={artifact} />
      )}

      <div className="border-t border-b bg-white fs-mask">
        <DiscussionNotes
          key={artifact.id} // important to make sure discussion notes are refreshed when topic changes
          artifact={artifact}
        />
      </div>
      {(artifact.__typename !== "FeedbackArtifactNode" ||
        (artifact.__typename === "FeedbackArtifactNode" &&
          artifact.feedbackState === FeedbackState.Sent)) && (
        <div className="p-4 sm:p-6">
          <ArtifactActivities
            artifact={artifact}
            meetingId={meetingId}
            meetingGroupId={meetingGroupId}
          />
        </div>
      )}
    </div>
  ) : (
    <ArtifactSidebarError onClose={handleCloseSidebar} />
  );
};

export default ArtifactSidebarContent;
