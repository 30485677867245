import clickupLogo from "@static/img/clickup.png";
import githubLogo from "@static/img/github.png";
import hubspotLogo from "@static/img/hubspot.png";
import jiraLogo from "@static/img/jira-icon.png";
import salesforceLogo from "@static/img/salesforce.png";

import { classNames } from "@helpers/css";
import { getAssetUrl } from "@helpers/helpers";

export enum IntegrationLogoType {
  Github = "Github",
  Jira = "Jira",
  Salesforce = "Salesforce",
  Clickup = "Clickup",
  Hubspot = "Hubspot",
}

export const getIntegrationLogoType = (node: { __typename: string }) => {
  if (node.__typename === "GithubIssueNode") {
    return IntegrationLogoType.Github;
  } else if (node.__typename === "JiraIssueNode") {
    return IntegrationLogoType.Jira;
  } else if (node.__typename === "SalesforceOpportunityNode") {
    return IntegrationLogoType.Salesforce;
  } else if (node.__typename === "ClickupTaskNode") {
    return IntegrationLogoType.Clickup;
  } else if (node.__typename === "HubspotDealNode") {
    return IntegrationLogoType.Hubspot;
  }
  return undefined;
};

const IntegrationLogo = ({
  type,
  size = 4,
  className = "",
}: {
  type?: IntegrationLogoType;
  size?: number;
  className?: string;
}) =>
  type === IntegrationLogoType.Github ? (
    <img
      src={getAssetUrl(githubLogo)}
      alt="GitHub"
      className={classNames(`h-${size} w-${size}`, className)}
    />
  ) : type === IntegrationLogoType.Jira ? (
    <img
      src={getAssetUrl(jiraLogo)}
      alt="Jira"
      className={classNames(`h-${size} w-${size}`, className)}
    />
  ) : type === IntegrationLogoType.Salesforce ? (
    <img
      src={getAssetUrl(salesforceLogo)}
      alt="Salesforce"
      className={classNames(`h-${size} w-${size}`, className)}
    />
  ) : type === IntegrationLogoType.Clickup ? (
    <img
      src={getAssetUrl(clickupLogo)}
      alt="Clickup"
      className="block h-4 w-4"
    />
  ) : type === IntegrationLogoType.Hubspot ? (
    <img
      src={getAssetUrl(hubspotLogo)}
      alt="Hubspot"
      className="block h-4 w-4"
    />
  ) : null;

export default IntegrationLogo;
