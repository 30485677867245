import { gql } from "@apollo/client";

export default gql`
  mutation saveAssessmentGroup(
    $title: String
    $organizationId: Int
    $assessmentGroupId: Int
    $sections: [AssessmentGroupSectionInput]
    $assessmentType: AssessmentType
    $delivery: AssessmentGroupDelivery
    $providers: AssessmentGroupProviders
    $anonymity: AssessmentGroupAnonymity
    $isQuestionWeightingEnabled: Boolean
  ) {
    createOrUpdateAssessmentGroup(
      organizationId: $organizationId
      assessmentGroupId: $assessmentGroupId
      title: $title
      assessmentType: $assessmentType
      delivery: $delivery
      providers: $providers
      anonymity: $anonymity
      sections: $sections
      isQuestionWeightingEnabled: $isQuestionWeightingEnabled
    ) {
      assessmentGroup {
        id
      }
    }
  }
`;
