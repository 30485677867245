import { useQuery } from "@apollo/client";
import moment from "moment";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  GetGoalOverviewSelectedEntityQueryQuery,
  GetGoalOverviewSelectedEntityQueryQueryVariables,
} from "types/graphql-schema";
import { DateRangeEnum } from "types/topicflow";

import useLabel from "@apps/use-label/use-label";
import { currentOrganizationVar, currentUserVar } from "@cache/cache";
import { useLink } from "@components/link/link";
import { Select, SelectOption } from "@components/select/select";
import useDocumentTitle from "@components/use-document-title/use-document-title";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import useUserComboboxQuery from "@components/user-combobox/use-user-combobox-query";
import UserCombobox from "@components/user-combobox/user-combobox";
import {
  UserComboboxOption,
  UserComboboxOptionType,
  UserComboboxOrgOption,
  UserComboboxUserOption,
} from "@components/user-combobox/user-combobox-list";
import { dateRangeToDateArray, isBackgroundLocation } from "@helpers/helpers";

import GoalOverviewGoalListStats from "./goal-list-stats";
import getGoalOverviewSelectedEntityQuery from "./graphql/get-goal-overview-selected-entity-query";
import GoalPageHeader from "./header";
import GoalOverviewRelatedEntities from "./related-entities";

const GoalOverview = () => {
  const label = useLabel();
  const link = useLink();
  const location = useLocation();
  const currentUser = currentUserVar();
  const currentOrganization = currentOrganizationVar();
  useDocumentTitle(`${label("goal", { capitalize: true })} overview`);
  const { id, type } = useParams<{
    id?: string;
    type?: UserComboboxOptionType;
  }>();

  const currentUserOption = {
    id: currentUser.id,
    name: currentUser.name,
    type: UserComboboxOptionType.USER,
    avatar: currentUser.avatar,
    email: currentUser.email,
  } as UserComboboxUserOption;
  const currentOrgOption = {
    id: currentOrganization.id,
    name: currentOrganization.name,
    type: UserComboboxOptionType.ORG,
  } as UserComboboxOrgOption;
  const defaultSelected =
    id === undefined || type === undefined
      ? currentUserOption
      : id === String(currentUser.id) && type === UserComboboxOptionType.USER
      ? currentUserOption
      : id === String(currentOrganization.id) &&
        type === UserComboboxOptionType.ORG
      ? currentOrgOption
      : null;

  const [selected, setSelected] = useState<UserComboboxOption | null>(
    defaultSelected
  );
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeEnum>(
    DateRangeEnum.thisQuarter
  );
  const userId = type === UserComboboxOptionType.USER && id ? parseInt(id) : -1;
  const teamId = type === UserComboboxOptionType.TEAM && id ? parseInt(id) : -1;
  const organizationId =
    type === UserComboboxOptionType.ORG && id ? parseInt(id) : -1;
  useQuery<
    GetGoalOverviewSelectedEntityQueryQuery,
    GetGoalOverviewSelectedEntityQueryQueryVariables
  >(getGoalOverviewSelectedEntityQuery, {
    variables: {
      userId: userId,
      hasUserId: userId > 0,
      teamId: teamId,
      hasTeamId: teamId > 0,
      organizationId: organizationId,
      hasOrganizationId: organizationId > 0,
    },
    onCompleted: (response) => {
      if (response.user) {
        setSelected({
          id: response.user.id,
          name: response.user.name,
          type: UserComboboxOptionType.USER,
          avatar: response.user.avatar,
          email: response.user.email,
        });
      } else if (response.team) {
        setSelected({
          id: response.team.id,
          title: response.team.title,
          type: UserComboboxOptionType.TEAM,
        });
      } else if (response.organization) {
        setSelected({
          id: response.organization.id,
          name: response.organization.name,
          type: UserComboboxOptionType.ORG,
        });
      }
    },
    onError: onNotificationErrorHandler(),
  });

  const { options, setQuery, query } = useUserComboboxQuery({
    types: [
      UserComboboxOptionType.ORG,
      UserComboboxOptionType.TEAM,
      UserComboboxOptionType.USER,
    ],
    selected: selected ? selected : undefined,
  });

  const dateRangeOptions = Object.values(DateRangeEnum).map((dateRange) => ({
    value: dateRange,
    label: label(dateRange, { capitalize: true }),
    selected: selectedDateRange === dateRange,
    description: dateRangeToDateArray({
      range: dateRange,
      quarterStartMonth: currentOrganization.quarterStartMonth,
    })
      .map((date) => moment(date).format("ll"))
      .join(" - "),
  }));

  const handleChangeDateRange = (option: SelectOption<DateRangeEnum>) => {
    setSelectedDateRange(option.value);
  };

  useEffect(() => {
    if (selected?.id && selected?.type && isBackgroundLocation(location)) {
      link.replace(`/goals/${selected.type}/${selected.id}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  return (
    <div aria-label="Goals" className="flex flex-col flex-1 w-full">
      <GoalPageHeader selected={selected} />
      <div className="mx-auto max-w-7xl w-full">
        <div className="p-6 flex flex-col gap-8">
          <div className="flex items-center gap-6 justify-between">
            <div className="flex items-center gap-6">
              <UserCombobox
                options={options}
                value={selected}
                onChangeValue={setSelected}
                query={query}
                onChangeQuery={setQuery}
                placeholder="Loading..."
              />
              <Select<DateRangeEnum>
                options={dateRangeOptions}
                value={selectedDateRange}
                onChange={handleChangeDateRange}
              />
            </div>
          </div>
          {selected && (
            <GoalOverviewGoalListStats
              selected={selected}
              selectedDateRange={selectedDateRange}
            />
          )}
          {selected && (
            <GoalOverviewRelatedEntities
              selected={selected}
              selectedDateRange={selectedDateRange}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default GoalOverview;
