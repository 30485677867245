import { Popover } from "@headlessui/react";
import { useState } from "react";
import { BasicUser } from "types/topicflow";

import { currentUserVar } from "@cache/cache";
import Avatar from "@components/avatar/avatar";
import useUserComboboxQuery from "@components/user-combobox/use-user-combobox-query";
import UserCombobox from "@components/user-combobox/user-combobox";
import {
  UserComboboxOption,
  UserComboboxOptionType,
  UserComboboxUserOption,
} from "@components/user-combobox/user-combobox-list";
import { graphqlNone } from "@helpers/constants";
import { classNames } from "@helpers/css";

const KeyResultAssigneeDropdown = ({
  keyResult,
  onChangeAssignee,
  canChange = false,
  showName = false,
  className = "",
}: {
  keyResult: {
    assignee?: BasicUser;
  };
  className: string;
  onChangeAssignee: (option: UserComboboxUserOption) => void;
  canChange: boolean;
  showName?: boolean;
}) => {
  const unassigned: UserComboboxUserOption = {
    id: graphqlNone,
    name: "Unassigned",
    avatar: null,
    type: UserComboboxOptionType.USER,
  };
  const currentUser = currentUserVar();
  const [canLoadData, setCanLoadData] = useState(false);
  const selectedUser: UserComboboxOption = keyResult.assignee?.id
    ? { type: UserComboboxOptionType.USER, ...keyResult.assignee }
    : unassigned;
  const { loading, options, query, setQuery } = useUserComboboxQuery({
    queryOptions: {
      skip: !canLoadData,
    },
    selected: selectedUser,
    emptyOption: unassigned,
  });

  const handleChangeOwner = (option: UserComboboxOption) => {
    if (option.type === UserComboboxOptionType.USER) {
      onChangeAssignee(option);
    }
  };

  const handlePreloadData = () => {
    setCanLoadData(true);
  };

  const selectedName = keyResult.assignee
    ? keyResult.assignee.name
    : "Unassigned";
  const selectedClassName = classNames(
    "text-base flex items-center gap-1",
    keyResult.assignee?.name
      ? "text-gray-600"
      : "text-gray-400 hover:text-gray-600",
    keyResult.assignee?.id === currentUser.id && " font-semibold text-gray-800"
  );

  return canChange ? (
    <UserCombobox
      aria-label="Key result assignee dropdown"
      loading={loading}
      width="full"
      query={query}
      value={
        keyResult.assignee
          ? { type: UserComboboxOptionType.USER, ...keyResult.assignee }
          : unassigned
      }
      options={options}
      onChangeValue={handleChangeOwner}
      onChangeQuery={setQuery}
      onClickButton={handlePreloadData}
    >
      {({ setReferenceElement, onClickButton }) => (
        <div className={"flex justify-between bg-white rounded-md"}>
          <Popover.Button
            className={classNames("flex items-center text-sm", className)}
            onClick={onClickButton}
            ref={setReferenceElement}
          >
            <Avatar
              user={keyResult.assignee}
              tooltipPrefix="Assigned to "
              size="5"
              className={classNames(showName && "mr-1")}
              unassignedTooltipText="Unassigned"
            />
            {showName && selectedName}
          </Popover.Button>
        </div>
      )}
    </UserCombobox>
  ) : (
    <span className={classNames(selectedClassName, "hover:bg-white")}>
      {selectedName}
    </span>
  );
};

export default KeyResultAssigneeDropdown;
