import { useQuery } from "@apollo/client";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import Loading from "@components/loading/loading";
import { onNotificationErrorHandler } from "@components/use-error/use-error";

import OnboardingCreateOrganization from "./components/create-organization";
import OnboardingOrgInvitation from "./components/invitation";
import getLoggedInUserOnboardingQuery from "./graphql/get-logged-in-user-query";

const OnboardingRoutes = () => {
  const { path } = useRouteMatch();
  const { data, loading } = useQuery(getLoggedInUserOnboardingQuery, {
    variables: {},
    fetchPolicy: "network-only",
    context: {
      disableErrorNotifications: true,
    },
    onError: onNotificationErrorHandler(),
  });
  const currentUser = data?.me;

  return loading ? (
    <Loading>Loading...</Loading>
  ) : (
    <div>
      <Switch>
        <Route exact path={`${path}/create-organization`}>
          <OnboardingCreateOrganization currentUser={currentUser} />
        </Route>
        <Route exact path={`${path}/invitations/:inviteKey`}>
          <OnboardingOrgInvitation currentUser={currentUser} />
        </Route>
      </Switch>
    </div>
  );
};

export default OnboardingRoutes;
