import { ChangeEvent, useCallback } from "react";

import { MeetingOrTemplateVisibility } from "@apps/meeting-new/helpers";
import useLabel from "@apps/use-label/use-label";
import { currentOrganizationVar } from "@cache/cache";
import Tooltip from "@components/tooltip/tooltip";

const MeetingContentAccess = ({
  onChange,
  allowManagementTreeVisibility,
  allowOrgVisibility,
  allowAdminVisibility,
  isFormalOneonone,
}: {
  isFormalOneonone: boolean;
  onChange: ({
    allowAdminVisibility,
    allowOrgVisibility,
    allowManagementTreeVisibility,
  }: MeetingOrTemplateVisibility) => void;
} & MeetingOrTemplateVisibility) => {
  const label = useLabel();
  const organization = currentOrganizationVar();

  const handleToggleAdminVisibility = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange({
        allowManagementTreeVisibility,
        allowOrgVisibility,
        allowAdminVisibility: e.target.checked,
      });
    },
    [allowManagementTreeVisibility, allowOrgVisibility, onChange]
  );

  const handleToggleOrgVisibility = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const newAllowOrgVisibility = e.target.checked;
      onChange({
        allowOrgVisibility: newAllowOrgVisibility,
        allowAdminVisibility: newAllowOrgVisibility
          ? true
          : allowAdminVisibility,
        allowManagementTreeVisibility: newAllowOrgVisibility
          ? true
          : allowManagementTreeVisibility,
      });
    },
    [onChange, allowAdminVisibility, allowManagementTreeVisibility]
  );

  const handleToggleManagementTreeVisibility = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange({
        allowAdminVisibility,
        allowOrgVisibility,
        allowManagementTreeVisibility: e.target.checked,
      });
    },
    [allowAdminVisibility, allowOrgVisibility, onChange]
  );

  return (
    <div className="flex flex-col gap-1 text-gray-800">
      <label className="flex gap-2 items-center cursor-pointer">
        <input
          type="checkbox"
          className="shrink-0"
          name="participants"
          checked={true}
          disabled
        />
        Participants
      </label>
      <Tooltip text="Participants' management tree">
        <label className="flex gap-2 items-center cursor-pointer">
          <input
            type="checkbox"
            className="shrink-0"
            name="allowManagementTreeVisibility"
            checked={allowManagementTreeVisibility}
            onChange={handleToggleManagementTreeVisibility}
            disabled={allowOrgVisibility}
          />
          Management tree
        </label>
      </Tooltip>
      <Tooltip text={`${organization.name} ${label("organization")} admins`}>
        <label className="flex gap-2 items-center cursor-pointer">
          <input
            type="checkbox"
            className="shrink-0"
            name="allowAdminVisibility"
            checked={allowAdminVisibility}
            onChange={handleToggleAdminVisibility}
            disabled={allowOrgVisibility}
          />
          Admins
        </label>
      </Tooltip>
      <Tooltip
        text={`All ${organization.name} ${label("organization")} members`}
      >
        <label className="flex gap-2 items-center cursor-pointer">
          <input
            type="checkbox"
            className="shrink-0"
            name="allowOrgVisibility"
            checked={allowOrgVisibility}
            onChange={handleToggleOrgVisibility}
            disabled={isFormalOneonone}
          />
          All {organization.name} members
        </label>
      </Tooltip>
    </div>
  );
};

export default MeetingContentAccess;
