import { ApolloError, NetworkStatus, useQuery } from "@apollo/client";
import { uniq } from "lodash";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { RiCollapseDiagonal2Line, RiExpandDiagonal2Line } from "react-icons/ri";
import {
  AlignmentGoalFragmentFragment,
  GetAlignmentGoalsQueryQuery,
  GetAlignmentGoalsQueryQueryVariables,
  GetGoalOverviewSelectedEntityQueryQuery,
  GetGoalOverviewSelectedEntityQueryQueryVariables,
  GoalScope,
  GoalState,
} from "types/graphql-schema";
import { DateRangeEnum } from "types/topicflow";

import getGoalOverviewSelectedEntityQuery from "@apps/goal-overview/graphql/get-goal-overview-selected-entity-query";
import GoalPageHeader from "@apps/goal-overview/header";
import useLabel from "@apps/use-label/use-label";
import useUiPreferenceCache from "@apps/use-ui-preference-cache/use-ui-preference-cache";
import { currentOrganizationVar } from "@cache/cache";
import { useLink } from "@components/link/link";
import Loading from "@components/loading/loading";
import { Select, SelectOption } from "@components/select/select";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import useUserComboboxQuery from "@components/user-combobox/use-user-combobox-query";
import UserCombobox from "@components/user-combobox/user-combobox";
import {
  UserComboboxOption,
  UserComboboxOptionType,
} from "@components/user-combobox/user-combobox-list";
import { classNames } from "@helpers/css";
import {
  assertEdgesNonNull,
  dateRangeToDateArray,
  isGoalArtifactNode,
} from "@helpers/helpers";
import useUrlQueryParams from "@helpers/hooks/use-url-query-params";

import GoalAlignmentTree from "./goal-alignment-tree";
import getAlignmentGoalsQuery from "./graphql/get-alignment-goals-query";

export enum AllDateRangeEnum {
  all = "all",
}

export type AlignedGoalType = {
  __typename: "GoalArtifactNode";
} & AlignmentGoalFragmentFragment;

export type AlignmentGoalsByIdType = {
  [key: number]: AlignedGoalType;
};

export type AlignmentChildGoalIdsByParentIdType = {
  [key: number]: number[];
};

type GoalAlignmentDateRangeType = AllDateRangeEnum | DateRangeEnum;

export const goalAlignmentPageCount = 50;

const getCachedGoals = (
  data: undefined | GetAlignmentGoalsQueryQuery,
  networkStatus: NetworkStatus
) => {
  return networkStatus === NetworkStatus.loading && data?.artifacts
    ? assertEdgesNonNull(data.artifacts).filter(isGoalArtifactNode)
    : [];
};

const getPathParentIds = (
  goalId: number,
  goalsById: AlignmentGoalsByIdType
): number[] => {
  const goal = goalsById[goalId];
  if (!goal || !goal.parentGoalId) return [];
  return [goal.parentGoalId].concat(
    getPathParentIds(goal.parentGoalId, goalsById)
  );
};

export const isGoalInSearchResultPath = (
  goalId: number,
  searchResultGoalIds?: number[],
  goalIdsInSearchResultPaths?: number[]
) => {
  return (
    searchResultGoalIds === undefined ||
    goalIdsInSearchResultPaths === undefined ||
    searchResultGoalIds?.includes(goalId) ||
    goalIdsInSearchResultPaths?.includes(goalId)
  );
};

export const useFetchAlignedGoals = ({
  variables,
}: {
  variables: GetAlignmentGoalsQueryQueryVariables;
}): {
  goals: AlignedGoalType[];
  loading: boolean;
  goalsById: AlignmentGoalsByIdType;
  goalIdsInSearchResultPaths?: number[];
  searchResultGoalIds?: number[];
  childGoalIdsByParentId: AlignmentChildGoalIdsByParentIdType;
} => {
  const handleError = (error: ApolloError) => {
    setLoading(false);
    onNotificationErrorHandler()(error);
  };

  const [loading, setLoading] = useState(true);
  const { data, fetchMore, networkStatus } = useQuery<
    GetAlignmentGoalsQueryQuery,
    GetAlignmentGoalsQueryQueryVariables
  >(getAlignmentGoalsQuery, {
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    variables,
    onError: handleError,
  });

  const [cachedGoals, setCachedGoals] = useState<AlignedGoalType[]>(
    getCachedGoals(data, networkStatus)
  );

  // Load more goals or switch between cached and fresh goals
  useEffect(() => {
    if (networkStatus !== NetworkStatus.ready) return;
    if (!data?.artifacts?.pageInfo) return;

    // fetch more goals if there are more
    if (data.artifacts.pageInfo.hasNextPage) {
      fetchMore({
        variables: {
          merge: true,
          after: data?.artifacts?.pageInfo.endCursor,
        },
      });
    }

    if (data.artifacts.pageInfo.hasNextPage === false) {
      setCachedGoals([]);
      setLoading(false);
    }
  }, [data, networkStatus, fetchMore]);

  // Reset cached goals when variables change
  useEffect(() => {
    setCachedGoals([]);
    setLoading(true);
  }, [variables]);

  useEffect(() => {
    // if we have cached data, set the goals to the cached data
    if (networkStatus === NetworkStatus.loading && data) {
      setCachedGoals(getCachedGoals(data, networkStatus));
    }
  }, [data, networkStatus]);

  const searchResultGoalIds = useMemo(() => {
    return !loading && data?.searchResults?.edges
      ? assertEdgesNonNull(data.searchResults).map(({ id }) => id)
      : undefined;
  }, [loading, data]);

  // If we have cached goals, use them, otherwise use the fresh goals
  const goals = useMemo(() => {
    return !loading && data?.artifacts
      ? assertEdgesNonNull(data.artifacts).filter(isGoalArtifactNode)
      : loading && variables.hasSearch
      ? []
      : cachedGoals;
  }, [cachedGoals, loading, data, variables]);

  const goalsById: AlignmentGoalsByIdType = useMemo(
    () =>
      goals.reduce((acc, goal) => {
        acc[goal.id] = goal;
        return acc;
      }, {} as AlignmentGoalsByIdType),
    [goals]
  );

  const childGoalIdsByParentId: AlignmentChildGoalIdsByParentIdType = useMemo(
    () =>
      goals.reduce((acc, goal) => {
        const parentId = goal.parentGoalId;
        if (!parentId) return acc;
        if (!acc[parentId]) acc[parentId] = [];
        acc[parentId].push(goal.id);
        return acc;
      }, {} as AlignmentChildGoalIdsByParentIdType),
    [goals]
  );

  // const searchResultGoalIds = searchResultGoalIds === undefined ? undefined : searchResultGoalIds;
  const goalIdsInSearchResultPaths =
    searchResultGoalIds === undefined
      ? undefined
      : uniq(
          searchResultGoalIds.reduce((memo, goalId) => {
            const pathParentIds = getPathParentIds(goalId, goalsById);
            return memo.concat(pathParentIds);
          }, [] as number[])
        );

  return {
    goals,
    goalsById,
    childGoalIdsByParentId,
    searchResultGoalIds,
    goalIdsInSearchResultPaths,
    loading,
  };
};

const GoalAlignment = () => {
  const label = useLabel();

  const link = useLink();
  const currentOrganization = currentOrganizationVar();
  const { uiPreferenceCache, saveUiPreference } = useUiPreferenceCache();
  const { dateRange, contextId, contextType } = useUrlQueryParams<{
    dateRange: DateRangeEnum;
    contextId?: string;
    contextType?: UserComboboxOptionType;
  }>({
    dateRange: DateRangeEnum.thisQuarter,
  });

  const [selectedEntity, setSelectedEntity] =
    useState<UserComboboxOption | null>(null);
  const [selectedDateRange, setSelectedDateRange] =
    useState<GoalAlignmentDateRangeType>(dateRange);

  const variables = useMemo(() => {
    return {
      first: goalAlignmentPageCount,
      after: null,
      goalDueBetweenDates:
        selectedDateRange === AllDateRangeEnum.all
          ? undefined
          : dateRangeToDateArray({
              range: selectedDateRange,
              quarterStartMonth: currentOrganization.quarterStartMonth,
            }),
      goalStates: uiPreferenceCache.objectiveAlignmentIsShowingClosed
        ? null
        : [GoalState.Draft, GoalState.Open],
      goalScopes: [GoalScope.Organization, GoalScope.Team, GoalScope.Personal],
      searchGoalOwners:
        contextId && contextType === UserComboboxOptionType.USER
          ? [parseInt(contextId)]
          : undefined,
      searchGoalTeams:
        contextId && contextType === UserComboboxOptionType.TEAM
          ? [parseInt(contextId)]
          : undefined,
      hasSearch: !!contextType && !!contextId,
    };
  }, [
    currentOrganization.quarterStartMonth,
    selectedDateRange,
    uiPreferenceCache.objectiveAlignmentIsShowingClosed,
    contextId,
    contextType,
  ]);

  const {
    goals,
    goalsById,
    childGoalIdsByParentId,
    searchResultGoalIds,
    goalIdsInSearchResultPaths,
    loading,
  } = useFetchAlignedGoals({ variables });
  const dateRangeOptions = Object.values(DateRangeEnum).map((dateRange) => ({
    value: dateRange,
    label: label(dateRange, { capitalize: true }),
    selected: selectedDateRange === dateRange,
    description: dateRangeToDateArray({
      range: dateRange,
      quarterStartMonth: currentOrganization.quarterStartMonth,
    })
      .map((date) => moment(date).format("ll"))
      .join(" - "),
  }));

  const allDateRangeOptions = [
    {
      value: AllDateRangeEnum.all,
      label: "Anytime",
      selected: selectedDateRange === null,
    },
    ...dateRangeOptions,
  ];

  const userId =
    contextType === UserComboboxOptionType.USER && contextId
      ? parseInt(contextId)
      : -1;
  const teamId =
    contextType === UserComboboxOptionType.TEAM && contextId
      ? parseInt(contextId)
      : -1;
  useQuery<
    GetGoalOverviewSelectedEntityQueryQuery,
    GetGoalOverviewSelectedEntityQueryQueryVariables
  >(getGoalOverviewSelectedEntityQuery, {
    variables: {
      userId: userId,
      hasUserId: userId > 0,
      teamId: teamId,
      hasTeamId: teamId > 0,
      organizationId: -1,
      hasOrganizationId: false,
    },
    onCompleted: (response) => {
      if (response.user) {
        setSelectedEntity({
          id: response.user.id,
          name: response.user.name,
          type: UserComboboxOptionType.USER,
          avatar: response.user.avatar,
          email: response.user.email,
        });
      } else if (response.team) {
        setSelectedEntity({
          id: response.team.id,
          title: response.team.title,
          type: UserComboboxOptionType.TEAM,
        });
      }
    },
    onError: onNotificationErrorHandler(),
  });

  const handleChangeDateRange = (
    option: SelectOption<GoalAlignmentDateRangeType>
  ) => {
    const search = new URLSearchParams(window.location.search);
    if (option.value !== DateRangeEnum.thisQuarter) {
      search.set("dateRange", option.value);
    } else {
      search.delete("dateRange");
    }
    link.replace({
      pathname: "/goal-alignment",
      search: search.toString(),
    });
    setSelectedDateRange(option.value);
  };

  const handleChangeEntity = (newEntity?: UserComboboxOption) => {
    const search = new URLSearchParams(window.location.search);
    if (newEntity) {
      search.set("contextId", String(newEntity.id));
      search.set("contextType", newEntity.type);
    } else {
      search.delete("contextId");
      search.delete("contextType");
    }
    link.replace({
      pathname: "/goal-alignment",
      search: search.toString(),
    });
    setSelectedEntity(newEntity || null);
  };

  const topLevelGoals = useMemo(
    () =>
      goals.filter(
        (goal) =>
          !goal.parentGoalId &&
          [GoalScope.Organization, GoalScope.Team].includes(goal.scope) &&
          isGoalInSearchResultPath(
            goal.id,
            searchResultGoalIds,
            goalIdsInSearchResultPaths
          )
      ),
    [goals, goalIdsInSearchResultPaths, searchResultGoalIds]
  );

  const unalignedGoals = useMemo(
    () =>
      goals.filter(
        (goal) =>
          goal.scope !== GoalScope.Organization &&
          !goal.parentGoalId &&
          isGoalInSearchResultPath(
            goal.id,
            searchResultGoalIds,
            goalIdsInSearchResultPaths
          )
      ),
    [goals, goalIdsInSearchResultPaths, searchResultGoalIds]
  );

  const handleExpandAll = () => {
    saveUiPreference({
      objectiveAlignmentExpandedIds: goals.map((goal) => goal.id),
    });
  };
  const handleCollapseAll = () => {
    saveUiPreference({ objectiveAlignmentExpandedIds: [] });
  };

  const { options, setQuery, query } = useUserComboboxQuery({
    types: [UserComboboxOptionType.TEAM, UserComboboxOptionType.USER],
    selected: selectedEntity,
  });

  const noResultsInSearch =
    searchResultGoalIds !== undefined && searchResultGoalIds.length === 0;

  return (
    <div aria-label="Goal alignment" className="flex flex-col flex-1 fs-unmask">
      <GoalPageHeader />
      <div className="pb-16">
        <div className="max-w-screen-xl mx-auto p-4 w-full flex flex-col gap-8">
          <div className="flex items-center gap-6 text-sm flex-wrap justify-between">
            <div className="flex items-center gap-6">
              <UserCombobox
                options={options}
                value={selectedEntity}
                onChangeValue={handleChangeEntity}
                query={query}
                onChangeQuery={setQuery}
                placeholder={`Filter...`}
                clearable={selectedEntity !== null}
                onClearValue={handleChangeEntity}
                className="min-w-36"
              />
              <Select<GoalAlignmentDateRangeType>
                options={allDateRangeOptions}
                value={selectedDateRange}
                onChange={handleChangeDateRange}
              />
              <label className="flex items-center gap-1 tracking-tight shrink-0">
                <input
                  type="checkbox"
                  checked={
                    uiPreferenceCache.objectiveAlignmentIsShowingKeyResults
                  }
                  onChange={(e) =>
                    saveUiPreference({
                      objectiveAlignmentIsShowingKeyResults: e.target.checked,
                    })
                  }
                />
                Show {label("key result", { pluralize: true })}
              </label>
              <label className="flex items-center gap-1 tracking-tight shrink-0">
                <input
                  type="checkbox"
                  checked={uiPreferenceCache.objectiveAlignmentIsShowingClosed}
                  onChange={(e) =>
                    saveUiPreference({
                      objectiveAlignmentIsShowingClosed: e.target.checked,
                    })
                  }
                />
                Show closed {label("goal", { pluralize: true })}
              </label>
              {loading && goals.length > 0 && (
                <div className="flex items-center gap-2 text-sm text-gray-500">
                  <Loading mini size={5} /> Updating alignment
                </div>
              )}
            </div>
            <div className="flex items-center gap-6">
              <button
                className="flex items-center gap-1.5 tracking-tight -mx-2 px-2 py-1 rounded-md hover:bg-gray-100"
                onClick={handleExpandAll}
              >
                <RiExpandDiagonal2Line className="w-4 h-4 text-gray-500" />
                Expand all
              </button>
              <button
                className="flex items-center gap-1.5 tracking-tight -mx-2 px-2 py-1 rounded-md hover:bg-gray-100"
                onClick={handleCollapseAll}
              >
                <RiCollapseDiagonal2Line className="w-4 h-4 text-gray-500" />
                Collapse all
              </button>
            </div>
          </div>
          {goals.length === 0 && loading && (
            <Loading>Loading {label("goal", { pluralize: true })}</Loading>
          )}

          {!loading &&
            ((topLevelGoals.length === 0 && unalignedGoals.length === 0) ||
              noResultsInSearch) && (
              <div className="text-sm text-gray-500">
                No {label("goal", { pluralize: true })}.
              </div>
            )}

          {!noResultsInSearch && topLevelGoals.length > 0 && (
            <div>
              <div className="text-xl font-medium mb-4">
                Aligned {label("goal", { pluralize: true, capitalize: true })}
              </div>
              <div className="-ml-1">
                <GoalAlignmentTree
                  goals={topLevelGoals}
                  goalsById={goalsById}
                  childGoalIdsByParentId={childGoalIdsByParentId}
                  isShowingKeyResults={
                    uiPreferenceCache.objectiveAlignmentIsShowingKeyResults
                  }
                  indent={0}
                  searchResultGoalIds={searchResultGoalIds}
                  goalIdsInSearchResultPaths={goalIdsInSearchResultPaths}
                />
              </div>
            </div>
          )}

          {!noResultsInSearch && unalignedGoals.length > 0 && (
            <div
              className={classNames(
                topLevelGoals.length > 0 && "border-t pt-8"
              )}
            >
              <div className="text-xl font-medium mb-4">
                Unaligned {label("goal", { pluralize: true, capitalize: true })}
              </div>
              <div className="-ml-1">
                <GoalAlignmentTree
                  goals={unalignedGoals}
                  goalsById={goalsById}
                  childGoalIdsByParentId={childGoalIdsByParentId}
                  isShowingKeyResults={
                    uiPreferenceCache.objectiveAlignmentIsShowingKeyResults
                  }
                  indent={0}
                  searchResultGoalIds={searchResultGoalIds}
                  goalIdsInSearchResultPaths={goalIdsInSearchResultPaths}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default GoalAlignment;
