import { gql } from "@apollo/client";

export default gql`
  query getGoalParticipantsQuery($artifactId: Int!) {
    artifact(artifactId: $artifactId) {
      id
      ... on GoalArtifactNode {
        canRead {
          permission
        }
        keyResults {
          edges {
            node {
              id
              title
              assignee {
                id
              }
            }
          }
        }
        owners {
          totalCount
          edges {
            node {
              id
              avatar
              name
            }
          }
        }
        contributors {
          edges {
            node {
              id
              avatar
              name
            }
          }
        }
      }
    }
  }
`;
