import { Popover } from "@headlessui/react";
import { BasicUser } from "types/topicflow";

import Avatar from "@components/avatar/avatar";
import useUserComboboxQuery from "@components/user-combobox/use-user-combobox-query";
import UserCombobox from "@components/user-combobox/user-combobox";
import {
  UserComboboxOption,
  UserComboboxOptionType,
  UserComboboxUserOption,
} from "@components/user-combobox/user-combobox-list";
import { graphqlNone } from "@helpers/constants";
import { classNames } from "@helpers/css";

const GoalKeyResultAssignee = ({
  assignee,
  onChange,
}: {
  assignee?: BasicUser | null;
  onChange: (user: UserComboboxUserOption | null) => void;
}) => {
  const emptyOption: UserComboboxOption = {
    id: graphqlNone,
    email: "Unassigned",
    name: "Unassigned",
    avatar: "",
    type: UserComboboxOptionType.USER,
  };
  const selectedUser = assignee
    ? ({
        ...assignee,
        type: UserComboboxOptionType.USER,
      } as UserComboboxUserOption)
    : emptyOption;
  const { loading, options, query, setQuery } = useUserComboboxQuery({
    emptyOption,
    selected: selectedUser,
  });

  return (
    <UserCombobox
      loading={loading}
      width="64"
      query={query}
      value={selectedUser || null}
      options={options}
      onChangeValue={(opt) => {
        if (opt.type === UserComboboxOptionType.USER) {
          onChange(opt);
        }
      }}
      onChangeQuery={setQuery}
      onClearValue={() => onChange(null)}
    >
      {({ setReferenceElement, onClickButton }) => (
        <div className={"flex justify-between bg-white rounded-md"}>
          <Popover.Button
            className={classNames("flex items-center text-sm")}
            onClick={onClickButton}
            ref={setReferenceElement}
          >
            {selectedUser.id === graphqlNone ? (
              <Avatar user={null} tooltipPrefix="Assigned to " size="5" />
            ) : (
              <Avatar
                user={selectedUser}
                tooltipPrefix="Assigned to "
                size="5"
              />
            )}
          </Popover.Button>
        </div>
      )}
    </UserCombobox>
  );
};

export default GoalKeyResultAssignee;
