import { gql } from "@apollo/client";

import topicTemplateCategoryFragment from "./topic-template-category-fragment";

export const TemplateAppTopic = gql`
  fragment TemplateAppTopic on TopicNode {
    id
    title
    description
    discussionNotes
    defaultSubjectNotes
    defaultFacilitatorNotes
    includesIndividualNotes
    includesSharedNotes
    isMandatory
    notesRequirement
  }
`;

const TopicTemplateFragment = gql`
  ${topicTemplateCategoryFragment}
  ${TemplateAppTopic}
  fragment TopicTemplateFragment on TopicTemplateNode {
    id
    title
    description
    globalTemplate
    publicTemplate
    oneononeTemplate
    finalizePermissions
    managerInstructions
    reportInstructions
    allowAdminVisibility
    allowOrgVisibility
    allowManagementTreeVisibility
    organization {
      id
    }
    canDelete {
      permission
      reason
    }
    canUpdate {
      permission
      reason
    }
    creator {
      id
      name
      avatar
    }
    categoriesList {
      ...TopicTemplateCategoryFragment
    }
    topicsList {
      ...TemplateAppTopic
    }
  }
`;

export default TopicTemplateFragment;
