import { DashboardInsightFragment } from "types/graphql-schema";

import { assertEdgesNonNull } from "@helpers/helpers";

import { InsightButton, InsightLayout } from "../insight";

const FeedbackRequestInsight = ({
  insight,
  onDismissInsight,
}: {
  insight: DashboardInsightFragment;
  onDismissInsight: () => void;
}) => {
  const feedback = insight.artifact;
  if (feedback?.__typename !== "FeedbackArtifactNode") return null;
  const recipients = feedback.recipients
    ? assertEdgesNonNull(feedback.recipients)
    : [];
  if (recipients.length === 0) return null;
  const recipient = recipients[0];
  return (
    <InsightLayout
      user={recipient}
      onDismissInsight={onDismissInsight}
      isRequired={true}
    >
      <div className="flex-1">
        <div>{recipient.name} requested your feedback</div>
      </div>
      <div>
        <InsightButton url={`/feedback/${feedback.id}`}>Respond</InsightButton>
      </div>
    </InsightLayout>
  );
};

export default FeedbackRequestInsight;
