import * as Sentry from "@sentry/react";
import { useState } from "react";
import { ArtifactType, GoalState } from "types/graphql-schema";
import { BasicUser } from "types/topicflow";

import ArtifactCreationDialog from "@apps/artifact-creation-dialog/artifact-creation-dialog";
import GoalsCollapsibleContainer from "@apps/dashboard-new/components/goals-collapsible-container";
import useLabel from "@apps/use-label/use-label";
import { currentUserVar } from "@cache/cache";
import Error from "@components/error/error";
import Layout from "@components/layout/layout";

const AssessmentGoalSidebar = ({
  selectedUser,
  goalDueBetweenDates,
}: {
  selectedUser: BasicUser;
  goalDueBetweenDates?: string[];
}) => {
  const currentUser = currentUserVar();
  const [isShowingCreateGoalDialog, setIsShowingCreateGoalDialog] =
    useState(false);
  const label = useLabel();

  return (
    <>
      {isShowingCreateGoalDialog && (
        <ArtifactCreationDialog
          formOptions={{
            artifactType: ArtifactType.Goal,
            owners: [selectedUser],
          }}
          onClose={() => setIsShowingCreateGoalDialog(false)}
        />
      )}
      <Layout.SidebarSection
        options={[
          {
            label: `Create ${label("goal")}`,
            onClick: () => setIsShowingCreateGoalDialog(true),
          },
        ]}
        expandedUiPreferenceKey="dashboardSidebarGoalContainerExpanded"
        title={
          selectedUser.id === currentUser.id
            ? label("goal", { pluralize: true, capitalize: true })
            : `${selectedUser.firstName || selectedUser.name}'s ${label(
                "goal",
                {
                  pluralize: true,
                  capitalize: true,
                }
              )}`
        }
      >
        <GoalsCollapsibleContainer
          title={"Owned"}
          filters={{
            fetchData: true,
            owners: [selectedUser.id],
            goalStates: [GoalState.Open, GoalState.Draft, GoalState.Closed],
            forUserId: selectedUser.id,
            goalDueBetweenDates,
          }}
          localStorageKey="assessmentOwnedGoalsExpanded"
        />
        <GoalsCollapsibleContainer
          title={"Contributing"}
          filters={{
            fetchData: true,
            contributors: [selectedUser.id],
            goalStates: [GoalState.Open, GoalState.Draft, GoalState.Closed],
            forUserId: selectedUser.id,
            goalDueBetweenDates,
          }}
          localStorageKey="assessmentContributingGoalsExpanded"
        />
      </Layout.SidebarSection>
    </>
  );
};

export default Sentry.withErrorBoundary(AssessmentGoalSidebar, {
  fallback: <Error description={"The goals could not be rendered."} />,
});
