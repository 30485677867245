import { gql } from "@apollo/client";

export default gql`
  query getOrganizationCareerTrack($careerTrackId: Int!) {
    careerTrack(id: $careerTrackId) {
      id
      title
      team {
        id
        title
      }
      roles {
        edges {
          node {
            id
            title
            level
            roleType
            competencies {
              edges {
                node {
                  id
                  title
                  appliesTo
                  criteriaUniqueness
                  criteria {
                    edges {
                      node {
                        id
                        content
                        level
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
