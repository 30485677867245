import { GoalState, GoalStatus } from "types/graphql-schema";

import { classNames } from "@helpers/css";

export type GoalStatusPillProps = {
  status: GoalStatus;
  state: GoalState;
  isStale?: boolean;
  size?: string;
};

const GoalStatusPill = ({
  status,
  state,
  isStale = false,
  size = "3",
}: GoalStatusPillProps) => {
  const pillClassName = `h-${size} w-${size} rounded-full border-2 box-border`;
  const bgClassName =
    status === GoalStatus.OnTrack
      ? "bg-emerald-600 border-emerald-600"
      : status === GoalStatus.AtRisk
      ? "bg-amber-500 border-amber-500"
      : status === GoalStatus.OffTrack
      ? "bg-rose-600 border-rose-600"
      : status === GoalStatus.None
      ? "bg-gray-400 border-gray-400"
      : "";
  const staleClassName =
    isStale && state !== GoalState.Closed ? "bg-white" : "";
  return (
    <div
      className={classNames(pillClassName, bgClassName, staleClassName)}
      aria-label={`Goal status pill ${status}`}
    />
  );
};

export default GoalStatusPill;
