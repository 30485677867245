import { compact } from "lodash";
import { PropsWithChildren, useEffect } from "react";
import { Route, RouteProps, Switch, useLocation } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { CreatedArtifactFragmentFragment } from "types/graphql-schema";
import { TFLocationState } from "types/topicflow";

import Account from "@apps/account/account";
import ArtifactCreationDialog from "@apps/artifact-creation-dialog/artifact-creation-dialog";
import ArtifactFullscreen from "@apps/artifact-fullscreen/artifact-fullscreen";
import ArtifactSidebarRoute from "@apps/artifact-sidebar/artifact-sidebar-route";
import Assessments from "@apps/assessments/page";
import CalendarPage from "@apps/calendar/page";
import ConnectCalendarModal from "@apps/connect-calendar-modal/connect-calendar-modal";
import DashboardNew from "@apps/dashboard-new/dashboard";
import Error404 from "@apps/error-404";
import ExplorerPage from "@apps/explorer/page";
import FeedbackPage from "@apps/feedback/feedback";
import GoalAlignment from "@apps/goal-alignment/goal-alignment";
import GoalOverview from "@apps/goal-overview/page";
import GoogleCalSetup from "@apps/google-cal-setup";
import InviteDialog from "@apps/invite-dialog/invite-dialog";
import KPIs from "@apps/kpis/page";
import MeetingDialog from "@apps/meeting-dialog/meeting-dialog";
import NewMeetingPage from "@apps/meeting-new/page";
import MeetingSettings from "@apps/meeting-settings/settings";
import MeetingsPage from "@apps/meetings/page";
import OrgSettings from "@apps/org-settings/org-settings";
import Programs from "@apps/programs/page";
import Pusher from "@apps/pusher/pusher";
import Ratings from "@apps/ratings/page";
import RecognitionBoard from "@apps/recognition-board/recognition-board";
import Reporting from "@apps/reporting/page";
import Templates from "@apps/templates/page";
import TopicSidebar from "@apps/topic-sidebar/topic-sidebar";
import WorkHistory from "@apps/work-history/page";
import { currentOrganizationVar, currentUserVar } from "@cache/cache";
import BillingDialog from "@components/billing-dialog-cta/billing-dialog";
import { useLink } from "@components/link/link";
import { artifactTypeUrl } from "@helpers/constants";
import { getUrl, toWithBackground } from "@helpers/helpers";

import Layout from "./components/layout";

export const artifactTypePaths = Object.values(artifactTypeUrl)
  .concat("artifact")
  .join("|");

const CustomRouteWithPusher: React.FC<RouteProps> = ({
  children,
  ...props
}) => {
  return (
    <Route {...props}>
      <Layout>
        <Pusher>{children}</Pusher>
      </Layout>
    </Route>
  );
};

const CustomRoute: React.FC<PropsWithChildren<RouteProps>> = ({
  children,
  ...props
}) => {
  return (
    <Route {...props}>
      <Layout>{children}</Layout>
    </Route>
  );
};

const Routes: React.FC = () => {
  const currentUser = currentUserVar();
  const link = useLink();
  const currentOrganization = currentOrganizationVar();
  const location = useLocation<TFLocationState>();
  const background = location.state?.background;

  const handleRedirectHomepage = () => {
    link.redirect("/");
  };

  useEffect(() => {
    if (!window.Cypress && !import.meta.env.DEV && window.Intercom) {
      window.Intercom("update");
    }
  }, [location]);

  // Implemented for the product tour in Intercom
  // we need to redirect to /connect-calendar when
  // the user clicks anchor tag #connect-calendar
  // This prevents a full page refresh
  useEffect(() => {
    if (location.hash === "#connect-calendar") {
      link.replace(
        toWithBackground({
          pathname: "/connect-calendar",
          location,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.hash]);

  const handleCloseArtifactCreationDialog = (
    artifact?: CreatedArtifactFragmentFragment
  ) => {
    if (artifact) {
      link.redirect(
        toWithBackground({
          pathname: getUrl({
            artifactId: artifact.id,
            artifactType: artifact.artifactType,
          }),
          location,
        })
      );
    } else {
      link.redirect("/");
    }
  };

  return (
    <>
      {/* Pages */}
      <Switch location={background || location}>
        <CustomRouteWithPusher exact path={["/", "/dashboard"]}>
          <Redirect to={`/dashboard/user/${currentUser.id}`} />
        </CustomRouteWithPusher>
        <CustomRouteWithPusher path="/dashboard/user/:userId">
          <DashboardNew />
        </CustomRouteWithPusher>
        <CustomRouteWithPusher
          exact
          path={["/calendar/user/:userId", "/calendar"]}
        >
          <CalendarPage />
        </CustomRouteWithPusher>
        <CustomRouteWithPusher
          path={[
            "/meetings/:type/user/:userId",
            "/meetings/:type",
            "/meetings",
          ]}
        >
          <MeetingsPage />
        </CustomRouteWithPusher>
        <CustomRouteWithPusher path="/recognition-board">
          <RecognitionBoard />
        </CustomRouteWithPusher>
        <CustomRouteWithPusher path="/feedbacks">
          <FeedbackPage />
        </CustomRouteWithPusher>
        <CustomRouteWithPusher path="/explorer">
          <ExplorerPage />
        </CustomRouteWithPusher>
        <CustomRouteWithPusher path="/templates">
          <Templates />
        </CustomRouteWithPusher>
        <CustomRouteWithPusher path="/assessments">
          <Assessments />
        </CustomRouteWithPusher>
        <CustomRouteWithPusher path="/programs">
          <Programs />
        </CustomRouteWithPusher>
        <CustomRouteWithPusher path="/work-history">
          <WorkHistory />
        </CustomRouteWithPusher>
        <CustomRouteWithPusher path="/reporting">
          <Reporting />
        </CustomRouteWithPusher>
        <CustomRouteWithPusher path="/ratings">
          <Ratings />
        </CustomRouteWithPusher>
        <CustomRouteWithPusher path="/kpis">
          <KPIs />
        </CustomRouteWithPusher>
        <CustomRouteWithPusher path={["/goals/:type/:id", "/goals"]}>
          <GoalOverview />
        </CustomRouteWithPusher>
        <CustomRouteWithPusher path="/goal-alignment">
          <GoalAlignment />
        </CustomRouteWithPusher>
        <CustomRouteWithPusher path="/account">
          <Account />
        </CustomRouteWithPusher>
        <CustomRouteWithPusher path="/google-cal-setup">
          <GoogleCalSetup />
        </CustomRouteWithPusher>
        <CustomRouteWithPusher
          path={[
            "/settings/organization/:organizationId",
            "/settings/organization",
          ]}
        >
          <OrgSettings />
        </CustomRouteWithPusher>
        <CustomRouteWithPusher path="/invite">
          <DashboardNew />
          <InviteDialog organization={currentOrganization} />
        </CustomRouteWithPusher>
        <CustomRouteWithPusher path="/connect-calendar">
          <DashboardNew />
          <ConnectCalendarModal open={true} onClose={handleRedirectHomepage} />
        </CustomRouteWithPusher>
        <CustomRouteWithPusher path="/billing">
          <DashboardNew />
          <BillingDialog
            onClose={() => {
              link.redirect("/");
            }}
            organization={currentOrganization}
          />
        </CustomRouteWithPusher>

        <CustomRouteWithPusher path="/new-meeting">
          <MeetingDialog onClose={() => {}} />
        </CustomRouteWithPusher>

        {/* MEETING GROUP, MEETING & TOPIC */}
        <CustomRouteWithPusher path="/meeting/:meetingGroupId/settings">
          <MeetingSettings />
        </CustomRouteWithPusher>
        <CustomRouteWithPusher path="/meeting/:meetingGroupId">
          <NewMeetingPage />
        </CustomRouteWithPusher>

        <CustomRouteWithPusher path="/topic/:topicId">
          <DashboardNew />
          <TopicSidebar />
        </CustomRouteWithPusher>
        <CustomRouteWithPusher
          path={Object.values(artifactTypeUrl).map((path) => `/${path}-new`)}
        >
          <DashboardNew />
          <ArtifactCreationDialog onClose={handleCloseArtifactCreationDialog} />
        </CustomRouteWithPusher>
        <CustomRouteWithPusher
          exact
          path={`/(${artifactTypePaths})/:artifactId/fullscreen`}
        >
          <ArtifactFullscreen />
        </CustomRouteWithPusher>
        <CustomRouteWithPusher path={`/(${artifactTypePaths})/:artifactId`}>
          <DashboardNew />
          <ArtifactSidebarRoute />
        </CustomRouteWithPusher>

        {/* login/register links */}
        <CustomRouteWithPusher path={["/login", "/register"]}>
          <Redirect to={`/dashboard/user/${currentUser.id}`} />
        </CustomRouteWithPusher>
        {/* Not found */}
        <CustomRouteWithPusher path="*">
          <Error404 />
        </CustomRouteWithPusher>
      </Switch>

      {/* Sidebars */}
      {background && (
        <>
          <CustomRoute
            path={Object.values(artifactTypeUrl).map((path) => `/${path}-new`)}
          >
            <ArtifactCreationDialog
              onClose={handleCloseArtifactCreationDialog}
            />
          </CustomRoute>
          <CustomRoute path="/connect-calendar">
            <ConnectCalendarModal
              open={true}
              onClose={handleRedirectHomepage}
            />
          </CustomRoute>

          <CustomRoute path="/invite">
            <InviteDialog organization={currentOrganization} />
          </CustomRoute>

          <CustomRoute
            exact
            path={compact([
              `/(${artifactTypePaths})/:artifactId`,
              `/meeting/:meetingGroupId/:meetingId/(${artifactTypePaths})/:artifactId`,
            ])}
          >
            <ArtifactSidebarRoute />
          </CustomRoute>
          <CustomRoute
            path={[
              "/topic/:topicId",
              "/meeting/:meetingGroupId/:meetingId/topic/:topicId",
            ]}
          >
            <TopicSidebar />
          </CustomRoute>
        </>
      )}
    </>
  );
};

export default Routes;
