import { gql } from "@apollo/client";

import TopicTemplateFragment from "./topic-template-fragment";

export default gql`
  ${TopicTemplateFragment}
  mutation createOrUpdateTopicTemplateMutation(
    $title: String!
    $organizationId: Int
    $topicTemplateId: Int
    $oneononeTemplate: Boolean
    $categories: [Int]
    $publicTemplate: Boolean!
    $allowAdminVisibility: Boolean!
    $allowOrgVisibility: Boolean!
    $allowManagementTreeVisibility: Boolean!
    $finalizePermissions: FinalizePermissions
    $description: String
    $managerInstructions: String
    $reportInstructions: String
    $topics: [TemplateTopicInput]
  ) {
    createOrUpdateTopicTemplate(
      organization: $organizationId
      finalizePermissions: $finalizePermissions
      categories: $categories
      oneononeTemplate: $oneononeTemplate
      description: $description
      managerInstructions: $managerInstructions
      reportInstructions: $reportInstructions
      topicTemplateId: $topicTemplateId
      publicTemplate: $publicTemplate
      allowOrgVisibility: $allowOrgVisibility
      allowAdminVisibility: $allowAdminVisibility
      allowManagementTreeVisibility: $allowManagementTreeVisibility
      topics: $topics
      title: $title
    ) {
      topicTemplate {
        ...TopicTemplateFragment
      }
    }
  }
`;
