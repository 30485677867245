import { useLazyQuery, useMutation } from "@apollo/client";
import { PencilIcon, TrashIcon } from "@heroicons/react/outline";
import { range } from "lodash";
import { useCallback, useEffect, useState } from "react";
import {
  DeleteOrgWideCareerTrackMutation,
  DeleteOrgWideCareerTrackMutationVariables,
  GetOrganizationCareerTracksQuery,
  GetOrganizationCareerTracksQueryVariables,
} from "types/graphql-schema";

import deleteOrgWideCareerTrackMutation from "@apps/org-settings/graphql/delete-org-wide-career-track-mutation";
import getOrganizationCareerTracksQuery from "@apps/org-settings/graphql/get-organization-career-tracks-query";
import useLabel from "@apps/use-label/use-label";
import { currentOrganizationVar, successNotificationVar } from "@cache/cache";
import Button, { buttonTheme } from "@components/button/button";
import Layout from "@components/layout/layout";
import AppLink from "@components/link/link";
import Loading from "@components/loading/loading";
import { useNotificationError } from "@components/use-error/use-error";
import useUserComboboxQuery from "@components/user-combobox/use-user-combobox-query";
import UserCombobox from "@components/user-combobox/user-combobox";
import {
  UserComboboxOption,
  UserComboboxOptionType,
  UserComboboxTeamOption,
} from "@components/user-combobox/user-combobox-list";
import { classNames } from "@helpers/css";
import { assertEdgesNonNull, assertNonNull } from "@helpers/helpers";
import useConfirm from "@helpers/hooks/use-confirm";

const editCareerTrackUrl = (organizationId: number, competencyId: number) =>
  `/settings/organization/${organizationId}/career-track/edit/${competencyId}`;

const JobLadder = ({ organizationId }: { organizationId: number }) => {
  const label = useLabel();
  const { onError } = useNotificationError();
  const organization = currentOrganizationVar();
  const [selectedTeam, setSelectedTeam] = useState<UserComboboxOption | null>(
    organization.teams?.edges[0]
      ? {
          id: assertNonNull(organization.teams.edges[0].node?.id),
          title: assertNonNull(organization.teams.edges[0].node?.title),
          type: UserComboboxOptionType.TEAM,
        }
      : null
  );
  const [levelCount, setLevelCount] = useState(0);

  const {
    confirm: confirmDeleteCareerTrack,
    ConfirmationDialog: ConfirmationDialogDeleteCareerTrack,
  } = useConfirm(
    `Are you sure?`,
    `Are you sure you want to remove this Career Track?`
  );

  const [fetchCareerTracks, { data, loading }] = useLazyQuery<
    GetOrganizationCareerTracksQuery,
    GetOrganizationCareerTracksQueryVariables
  >(getOrganizationCareerTracksQuery, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    onError,
    onCompleted: (data) => {
      const tracks = assertEdgesNonNull(data.careerTracks);

      const maxLevel = tracks.reduce((max, track) => {
        const roles = assertEdgesNonNull(track.roles);
        const level = roles.reduce((roleMax, role) => {
          return Math.max(roleMax, role.level);
        }, 0);
        return Math.max(max, level);
      }, 0);
      setLevelCount(maxLevel);
    },
  });

  useEffect(() => {
    if (selectedTeam) {
      fetchCareerTracks({
        variables: {
          organizationId,
          teamId: selectedTeam.id,
        },
      });
    }
  }, [fetchCareerTracks, organizationId, selectedTeam]);

  const [deleteCareerTrack, { loading: isDeleting }] = useMutation<
    DeleteOrgWideCareerTrackMutation,
    DeleteOrgWideCareerTrackMutationVariables
  >(deleteOrgWideCareerTrackMutation, {
    onCompleted: () => {
      successNotificationVar({
        title: `Career Track deleted`,
      });
    },
    refetchQueries: [getOrganizationCareerTracksQuery],
  });

  const handleDeleteCareerTrack = useCallback(
    async (careerTrackId: number) => {
      const confirmed = await confirmDeleteCareerTrack();
      if (confirmed) {
        deleteCareerTrack({
          variables: {
            careerTrackId,
          },
          onError,
        });
      }
    },
    [confirmDeleteCareerTrack, deleteCareerTrack, onError]
  );

  const { options, setQuery, query } = useUserComboboxQuery({
    types: [UserComboboxOptionType.TEAM],
    selected: selectedTeam ? selectedTeam : undefined,
  });

  const careerTracks = data?.careerTracks
    ? assertEdgesNonNull(data.careerTracks)
    : [];

  return (
    <div className="mb-6">
      <ConfirmationDialogDeleteCareerTrack />

      <div className="flex justify-between items-center mb-6">
        <div className="font-bold">Job Ladders and Roles</div>
        <div className="flex items-center gap-2">
          <UserCombobox
            options={options}
            value={selectedTeam}
            onChangeValue={setSelectedTeam}
            query={query}
            onChangeQuery={setQuery}
            placeholder={`${label("team", { capitalize: true })}: None`}
          />
          <Button
            text="Add Career track"
            disabled={!selectedTeam}
            to={`/settings/organization/${organizationId}/career-track/new?teamStr=${
              selectedTeam?.id
            }:${(selectedTeam as UserComboboxTeamOption)?.title}`}
          />
        </div>
      </div>

      {loading && (
        <div className="flex justify-center mt-8">
          <Loading />
        </div>
      )}
      {!loading && selectedTeam && careerTracks.length === 0 && (
        <div className="flex justify-center mt-8">
          <div className="text-gray-500">No Career Tracks yet</div>
        </div>
      )}
      {!loading && !selectedTeam && careerTracks.length === 0 && (
        <div className="flex justify-center mt-8">
          <div className="text-gray-500">
            Select a {label("team")} to view Career Tracks
          </div>
        </div>
      )}

      {!loading && selectedTeam && careerTracks.length > 0 && (
        <div className="m-auto w-full">
          <div className="relative overflow-x-auto">
            <table className="text-sm table-fixed">
              <thead>
                <tr>
                  <th className="p-3 w-80 max-w-80 min-w-80 border border-l-0 sticky left-0 bg-white text-left">
                    Track
                  </th>
                  {range(levelCount).map((level) => {
                    return (
                      <th key={level} className="p-3 border text-left">
                        Level {level + 1}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {careerTracks.map((careerTrack) => {
                  return (
                    <tr key={careerTrack.id}>
                      <td className="p-3 border border-l-0 font-medium sticky w-80 max-w-80 min-w-80 left-0 bg-white">
                        <div className="flex justify-between items-center">
                          <div>
                            <AppLink
                              to={editCareerTrackUrl(
                                organizationId,
                                careerTrack.id
                              )}
                              className="hover:underline"
                            >
                              {careerTrack.title}
                            </AppLink>
                          </div>
                          <div className="flex items-center">
                            <Button
                              theme={buttonTheme.text}
                              icon
                              className={classNames(
                                "text-inherit",
                                Layout.headerIconButtonClassName
                              )}
                              to={editCareerTrackUrl(
                                organizationId,
                                careerTrack.id
                              )}
                              disabled={isDeleting}
                            >
                              <PencilIcon className="h-4 w-4" />
                            </Button>
                            <Button
                              theme={buttonTheme.text}
                              icon
                              className={classNames(
                                "text-inherit",
                                Layout.headerIconButtonClassName
                              )}
                              onClick={() => {
                                handleDeleteCareerTrack(careerTrack.id);
                              }}
                              disabled={isDeleting}
                            >
                              <TrashIcon className="h-4 w-4" />
                            </Button>
                          </div>
                        </div>
                      </td>
                      {range(levelCount).map((level) => {
                        const role = assertEdgesNonNull(careerTrack.roles).find(
                          (role) => role.level === level + 1
                        );
                        return (
                          <td
                            className="p-3 border w-56 max-w-56 min-w-56"
                            key={level}
                          >
                            {role ? role.title : "-"}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default JobLadder;
