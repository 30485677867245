import { useLazyQuery, useMutation } from "@apollo/client";
import { Popover } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { FormEvent, MouseEvent, useEffect, useRef, useState } from "react";
import { AttendeeStatus, SyncCredentialsTypeEnum } from "types/graphql-schema";

import CalendarConnectNew from "@apps/account/components/calendar-connect-new";
import { currentUserVar } from "@cache/cache";
import Button, { buttonTheme } from "@components/button/button";
import Loading from "@components/loading/loading";
import Modal from "@components/modal/modal";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { meetingEditType, providerUrl } from "@helpers/constants";
import { classNames } from "@helpers/css";
import { assertEdgesNonNull } from "@helpers/helpers";

import refreshCalendarsQuery from "../graphql/refresh-calendars-query";
import updateAttendanceStatusMutation from "../graphql/update-attendance-status-mutation";
import { meetingDialogAction } from "../meeting-dialog";
import { Attendee } from "./attendee-input";

const popupParams = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=560,height=760`;

const MeetingDialogCurrentUserAttendance = ({
  action,
  meetingId,
  meetingGroupId,
  isRecurring,
  attendees,
  loadingExistingMeeting,
  onChangeAttendeeStatus,
}: {
  action: meetingDialogAction;
  meetingId?: number | null;
  meetingGroupId?: number | null;
  isRecurring: boolean;
  loadingExistingMeeting: boolean;
  attendees: Attendee[];
  onChangeAttendeeStatus: (status: AttendeeStatus) => void;
}) => {
  const [attendanceStatus, setAttendanceStatus] =
    useState<null | AttendeeStatus>(null);
  const [editType, setEditType] = useState(meetingEditType.allEvents);
  const calendarPopupRef = useRef<Window | null>(null);
  const [showConnectCalendarModal, setShowConnectCalendarModal] =
    useState(false);
  const [calendarPopupIsOpened, setCalendarPopupIsOpened] = useState(false);
  const currentUser = currentUserVar();
  const currentAttendee = attendees.find(({ id }) => id === currentUser.id);
  const calendars = assertEdgesNonNull(currentUser.syncCredentials);
  const currentUserCalendar = calendars.find(
    (node) => node.credentialsUid === currentAttendee?.email
  );

  const hasNoValidCalendar =
    !currentUserCalendar || !currentUserCalendar.hasCalendarWriteCredentials;
  const [updateAttendanceStatus, { loading }] = useMutation(
    updateAttendanceStatusMutation
  );
  const [refreshCalendars, { loading: loadingRefreshCalendars }] = useLazyQuery(
    refreshCalendarsQuery,
    {
      onError: onNotificationErrorHandler(),
    }
  );

  const handleGiveCalendarAccess = (e: MouseEvent) => {
    e.preventDefault();
    if (currentUserCalendar?.provider) {
      const url = `/sso/${
        providerUrl[currentUserCalendar.provider]
      }?request_calendar_scope=true&request_write_scope=true&next=/sso/dialog-confirmation`;
      calendarPopupRef.current = window.open(
        url,
        "calendarAccess",
        popupParams
      );
      setCalendarPopupIsOpened(true);
    }
  };

  const handleShowConnectCalendar = (e: MouseEvent<HTMLButtonElement>) => {
    e?.preventDefault();
    setShowConnectCalendarModal(true);
  };

  const handleClickConnectCalendar = (option: {
    provider: SyncCredentialsTypeEnum;
    allowWriteAccess: boolean;
  }) => {
    setShowConnectCalendarModal(false);
    const url = `/sso/${
      providerUrl[option.provider]
    }?request_calendar_scope=true&request_write_scope=${String(
      option.allowWriteAccess
    )}&next=/sso/dialog-confirmation`;
    calendarPopupRef.current = window.open(url, "calendarAccess", popupParams);
    setCalendarPopupIsOpened(true);
  };

  // detect if dialog is opened/closed
  useEffect(() => {
    let interval: any;
    // if dialog is opened, then we check until it gets closed
    if (calendarPopupIsOpened) {
      interval = setInterval(() => {
        if (calendarPopupRef.current?.closed) {
          setCalendarPopupIsOpened(false);
        }
      }, 500);

      // when dialog was closed by user, we refresh calendars
    } else if (!calendarPopupIsOpened && calendarPopupRef.current) {
      refreshCalendars();
      calendarPopupRef.current = null;
    }
    return function cleanup() {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [calendarPopupIsOpened]);

  if (
    loadingExistingMeeting ||
    action !== meetingDialogAction.readonly ||
    !currentAttendee
  ) {
    return null;
  }
  const buttonClassName =
    "rounded-full bg-gray-200 px-4 py-1.5 enabled:hover:bg-gray-300";

  const allLoading =
    loadingRefreshCalendars || loading || loadingExistingMeeting;

  const handleClickUpdateStatus = (status: AttendeeStatus) => () => {
    if (isRecurring) {
      setAttendanceStatus(status);
    } else {
      handleUpdateAttendanceStatus(status);
    }
  };

  const handleUpdateAttendanceStatus = (status: AttendeeStatus) => {
    updateAttendanceStatus({
      variables: {
        meetingId:
          !isRecurring || editType === meetingEditType.singleEvent
            ? meetingId
            : null,
        meetingGroupId:
          isRecurring && editType === meetingEditType.allEvents
            ? meetingGroupId
            : null,
        status,
      },
      onError: onNotificationErrorHandler(),
    });
    onChangeAttendeeStatus(status);
  };

  const handleSubmitForm = (e: FormEvent) => {
    e.preventDefault();
    handleUpdateAttendanceStatus(
      attendanceStatus || AttendeeStatus.NotResponded
    );
  };

  return (
    <div>
      <Modal open={showConnectCalendarModal} className="z-notification">
        <div
          className="p-6 flex justify-between items-start"
          aria-label="Connect calendar modal"
        >
          <div className="flex-1">
            <CalendarConnectNew
              next="/sso/dialog-confirmation"
              onClickButton={handleClickConnectCalendar}
            />
          </div>
          <button
            onClick={() => setShowConnectCalendarModal(false)}
            className="p-1 rounded hover:bg-gray-100 text-gray-700"
            aria-label="Close modal"
          >
            <XIcon className="w-7 h-7" />
          </button>
        </div>
      </Modal>
      <Popover className="relative">
        <div
          className="flex items-center justify-center py-4 gap-2 text-sm"
          aria-label="User attendance status container"
        >
          <span className="font-bold mr-4">Going?</span>
          <Popover.Button
            disabled={
              allLoading ||
              currentAttendee.participantStatus === AttendeeStatus.Accepted ||
              hasNoValidCalendar
            }
            className={classNames(
              buttonClassName,
              currentAttendee.participantStatus === AttendeeStatus.Accepted &&
                "bg-emerald-200 enabled:hover:bg-emerald-200 text-emerald-900"
            )}
            onClick={handleClickUpdateStatus(AttendeeStatus.Accepted)}
          >
            Yes
          </Popover.Button>
          <Popover.Button
            disabled={
              allLoading ||
              currentAttendee.participantStatus === AttendeeStatus.Declined ||
              hasNoValidCalendar
            }
            className={classNames(
              buttonClassName,
              currentAttendee.participantStatus === AttendeeStatus.Declined &&
                "bg-red-100 enabled:hover:bg-red-100 text-red-700"
            )}
            onClick={handleClickUpdateStatus(AttendeeStatus.Declined)}
          >
            No
          </Popover.Button>
          <Popover.Button
            disabled={
              allLoading ||
              currentAttendee.participantStatus === AttendeeStatus.Tentative ||
              hasNoValidCalendar
            }
            className={classNames(
              buttonClassName,
              currentAttendee.participantStatus === AttendeeStatus.Tentative &&
                "bg-gray-900 enabled:hover:bg-gray-900 text-gray-100"
            )}
            onClick={handleClickUpdateStatus(AttendeeStatus.Tentative)}
          >
            Maybe
          </Popover.Button>
          {isRecurring && (
            <Popover.Panel
              className="absolute bottom-12 left-1/2 z-dropdown w-96 -translate-x-1/2 transform"
              aria-label="Meeting dialog recurring changes popover"
            >
              {({ close }) => (
                <div className="rounded-lg drop-shadow-lg bg-white p-4 border flex flex-col gap-4">
                  <div className="font-medium">Change attendance for</div>
                  <div className="flex flex-col gap-2 text-sm">
                    <label className="flex items-center gap-2">
                      <input
                        type="radio"
                        name="recurring-event-changes"
                        value={meetingEditType.singleEvent}
                        checked={editType === meetingEditType.singleEvent}
                        onChange={() =>
                          setEditType(meetingEditType.singleEvent)
                        }
                      />{" "}
                      This meeting
                    </label>
                    <label className="flex items-center gap-2">
                      <input
                        type="radio"
                        name="recurring-event-changes"
                        value={meetingEditType.allEvents}
                        checked={editType === meetingEditType.allEvents}
                        onChange={() => setEditType(meetingEditType.allEvents)}
                      />{" "}
                      All meetings
                    </label>
                  </div>
                  <div className="flex gap-2">
                    <Button
                      text="Cancel"
                      theme={buttonTheme.default}
                      className="w-1/2"
                      onClick={() => close()}
                    />
                    <Button
                      text="Apply"
                      type="submit"
                      theme={buttonTheme.primary}
                      className="w-1/2"
                      onClick={(e) => {
                        handleSubmitForm(e);
                        close();
                      }}
                    />
                  </div>
                </div>
              )}
            </Popover.Panel>
          )}
        </div>
      </Popover>
      {loadingRefreshCalendars && (
        <div className="flex justify-center">
          <Loading mini size="5" />
        </div>
      )}
      {!loadingRefreshCalendars && !currentUserCalendar && (
        <div className="text-xs text-gray-600 text-center mt-2">
          Please{" "}
          <button
            onClick={handleShowConnectCalendar}
            className="text-blue-600 hover:underline"
          >
            connect {currentAttendee.email || "your"} calendar
          </button>{" "}
          in order to change your attendance status.
        </div>
      )}
      {!loadingRefreshCalendars &&
        currentUserCalendar &&
        !currentUserCalendar.hasCalendarWriteCredentials && (
          <div className="text-xs text-gray-600 text-center mt-2">
            <button
              onClick={handleGiveCalendarAccess}
              className="text-blue-600 hover:underline"
            >
              Allow calendar write access
            </button>{" "}
            on {currentAttendee.email || "your"} calendar in order to change
            your attendance status.
          </div>
        )}
    </div>
  );
};

export default MeetingDialogCurrentUserAttendance;
