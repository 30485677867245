export const base64ToBlob = (base64: string, mimeType: string) => {
  // Decode the base64 string
  const byteCharacters = atob(base64.split(",")[1]);

  // Create an array to store the binary data
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  // Convert the array to a typed array (Uint8Array)
  const byteArray = new Uint8Array(byteNumbers);

  // Create a Blob from the typed array
  return new Blob([byteArray], { type: mimeType });
};

export const getUploadedImageToResizedSquareImageUrl: (
  file: File,
  maxSize: number
) => Promise<{ file: File; url: string }> = (file: File, maxSize: number) => {
  if (file.type === "image/svg+xml") {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = function (readerEvent: ProgressEvent<FileReader>) {
        if (!readerEvent?.target?.result) {
          return reject("Cannot upload file");
        }
        const parser = new DOMParser();
        const svgDoc = parser.parseFromString(
          String(readerEvent.target.result),
          "image/svg+xml"
        );
        const svgElement = svgDoc.documentElement as HTMLElement & {
          viewBox: any;
        };

        // Get the current dimensions from the SVG (if they exist)
        let width =
          parseFloat(svgElement.getAttribute("width") || "0") ||
          svgElement.viewBox.baseVal.width;
        let height =
          parseFloat(svgElement.getAttribute("height") || "0") ||
          svgElement.viewBox.baseVal.height;

        if (width !== height) {
          return reject(
            "Upload a square image: width & height must be the same."
          );
        }

        // Calculate new dimensions while maintaining aspect ratio
        if (height > maxSize) {
          width = maxSize;
          height = maxSize;
        }

        // Update the SVG's width and height attributes
        svgElement.setAttribute("width", width);
        svgElement.setAttribute("height", height);

        // Convert the resized SVG back to a string
        const serializer = new XMLSerializer();
        const resizedSvgString = serializer.serializeToString(svgElement);

        // Create a Blob from the SVG string
        const blob = new Blob([resizedSvgString], { type: "image/svg+xml" });
        const newFile = new File([blob], file.name, {
          type: blob.type,
          lastModified: new Date().getTime(), // Optional, sets the last modified date
        });

        // Create a URL for the resized SVG and display it
        const resizedImageUrl = URL.createObjectURL(blob);

        resolve({ file: newFile, url: resizedImageUrl });
      };

      // Read the SVG file as text
      reader.readAsText(file);
    });
  }

  if (file.type.startsWith("image/")) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      const reader = new FileReader();
      reader.onload = function (readerEvent: ProgressEvent<FileReader>) {
        if (img && readerEvent.target?.result) {
          img.src = String(readerEvent.target.result);
        }
      };

      img.onload = function () {
        let width = img.width;
        let height = img.height;

        if (img.width !== img.height) {
          return reject(
            "Upload a square image: width & height must be the same."
          );
        }

        if (img.width < maxSize) {
          return reject(
            `Image width & height must be bigger than ${maxSize}px.`
          );
        }

        // Calculate new dimensions while maintaining aspect ratio
        if (width > height) {
          if (width > maxSize) {
            height *= maxSize / width;
            width = maxSize;
          }
        } else {
          if (height > maxSize) {
            width *= maxSize / height;
            height = maxSize;
          }
        }

        // Set canvas dimensions to the new dimensions
        canvas.width = width;
        canvas.height = height;

        // Draw the resized image on the canvas
        if (ctx) ctx.drawImage(img, 0, 0, width, height);

        // Convert the canvas content to a data URL (Base64 string)
        const resizedImageUrl = canvas.toDataURL(file.type, 0.9);

        canvas.toBlob(function (blob) {
          if (blob) {
            const newFile = new File([blob], file.name, {
              type: blob.type,
              lastModified: new Date().getTime(), // Optional, sets the last modified date
            });
            resolve({ file: newFile, url: resizedImageUrl });
          }
        }, file.type || "image/png");
      };

      // Start reading the file as a Data URL
      reader.readAsDataURL(file);
    });
  }
  return Promise.reject("File not supported");
};
