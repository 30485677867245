import { Listbox } from "@headlessui/react";
import { SelectorIcon } from "@heroicons/react/outline";
import { RecognitionCoreValueNode } from "types/graphql-schema";

import CoreValueIcon from "@components/core-value-icon/core-value-icon";
import Select, { SelectOption } from "@components/select/select";
import { classNames, listBoxButtonClassName } from "@helpers/css";

type InputProps = {
  selectedCoreValueId?: number | null;
  coreValues: Pick<
    RecognitionCoreValueNode,
    "emoji" | "image" | "id" | "title"
  >[];
  onChange: (opt: SelectOption<number> | null) => void;
  className?: string;
};

export const CoreValueDropdownInput: React.FC<InputProps> = ({
  selectedCoreValueId,
  coreValues,
  onChange,
  className = "",
}) => {
  if (coreValues.length === 0) {
    return (
      <div className="text-gray-400 text-sm">
        Go to organization settings to configure Core Values
      </div>
    );
  }

  return (
    <Select<number>
      options={coreValues.map((coreValue) => ({
        value: coreValue.id,
        label: (
          <span className="flex items-center gap-1.5 max-w-64">
            <CoreValueIcon
              imagePxSize={16}
              image={coreValue.image}
              emoji={coreValue.emoji}
            />
            <span className="flex-1 truncate">{coreValue.title}</span>
          </span>
        ),
      }))}
      value={selectedCoreValueId}
      onChange={onChange}
    >
      {({ selected, setReferenceElement }) => (
        <Listbox.Button
          className={classNames(
            listBoxButtonClassName({ disabled: false }),
            className
          )}
          aria-label="Core value dropdown button"
          ref={setReferenceElement}
        >
          <span className="block truncate">
            {selected ? selected.label : "Choose a core value"}
          </span>
          <SelectorIcon className="h-4 w-4 text-gray-400 pointer-events-none" />
        </Listbox.Button>
      )}
    </Select>
  );
};

export default CoreValueDropdownInput;
