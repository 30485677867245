import { useQuery } from "@apollo/client";
import {
  GetGoalOverviewRelatedEntitiesQueryQuery,
  GetGoalOverviewRelatedEntitiesQueryQueryVariables,
  GoalOverviewRelatedEntityGoalFragmentFragment,
  GoalOverviewRelatedEntityTeamFragmentFragment,
  GoalOverviewRelatedEntityUserFragmentFragment,
} from "types/graphql-schema";
import { DateRangeEnum } from "types/topicflow";

import useLabel from "@apps/use-label/use-label";
import { currentOrganizationVar } from "@cache/cache";
import Loading from "@components/loading/loading";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import {
  UserComboboxOption,
  UserComboboxOptionType,
} from "@components/user-combobox/user-combobox-list";
import { assertEdgesNonNull, dateRangeToDateArray } from "@helpers/helpers";

import getGoalOverviewRelatedEntitiesQuery from "./graphql/get-goal-overview-related-entities-query";
import GoalOverviewRelatedEntitiesTable, {
  GoalOverviewUserOrTeamType,
} from "./related-entities-table";

const GoalOverviewRelatedEntity = ({
  title,
  loading,
  entities,
  entityLabel,
}: {
  title: string;
  loading?: boolean;
  entities: (GoalOverviewUserOrTeamType & {
    goals: GoalOverviewRelatedEntityGoalFragmentFragment[];
  })[];
  entityLabel: string;
}) => (
  <div>
    <div className="font-medium mb-2 flex items-center gap-2">
      {title}
      {loading === true && <Loading mini size={4} />}
    </div>
    <div className="flex flex-col gap-4">
      <GoalOverviewRelatedEntitiesTable
        entities={entities}
        entityLabel={entityLabel}
      />
    </div>
  </div>
);

const mapToEntities = (
  entity:
    | GoalOverviewRelatedEntityUserFragmentFragment
    | GoalOverviewRelatedEntityTeamFragmentFragment
) => ({
  ...entity,
  goals: (entity.__typename === "UserNode"
    ? (assertEdgesNonNull(
        entity.ownedGoals
      ) as GoalOverviewRelatedEntityGoalFragmentFragment[])
    : assertEdgesNonNull(
        entity.teamGoals
      )) as GoalOverviewRelatedEntityGoalFragmentFragment[],
});

const GoalOverviewRelatedEntities = ({
  selected,
  selectedDateRange,
}: {
  selected: UserComboboxOption;
  selectedDateRange: DateRangeEnum;
}) => {
  const label = useLabel();
  const currentOrganization = currentOrganizationVar();

  const { data, loading } = useQuery<
    GetGoalOverviewRelatedEntitiesQueryQuery,
    GetGoalOverviewRelatedEntitiesQueryQueryVariables
  >(getGoalOverviewRelatedEntitiesQuery, {
    variables: {
      goalDueBetweenDates: dateRangeToDateArray({
        range: selectedDateRange,
        quarterStartMonth: currentOrganization.quarterStartMonth,
      }),
      userId: selected.id,
      hasUserId: selected.type === UserComboboxOptionType.USER,
      teamId: selected.id,
      hasTeamId: selected.type === UserComboboxOptionType.TEAM,
      organizationId: selected.id,
      hasOrganizationId: selected.type === UserComboboxOptionType.ORG,
    },
    onError: onNotificationErrorHandler(),
  });

  const teamMembers =
    selected.type === UserComboboxOptionType.TEAM && data?.team?.members
      ? assertEdgesNonNull(data.team.members)
      : [];

  const reports =
    selected.type === UserComboboxOptionType.USER && data?.user?.directReports
      ? assertEdgesNonNull(data.user.directReports)
      : [];

  const managers =
    selected.type === UserComboboxOptionType.USER && data?.user?.managers
      ? assertEdgesNonNull(data.user.managers)
      : [];

  const userTeams =
    selected.type === UserComboboxOptionType.USER && data?.user?.teams
      ? assertEdgesNonNull(data.user.teams)
      : [];

  const orgTeams =
    selected.type === UserComboboxOptionType.ORG && data?.organization?.teams
      ? assertEdgesNonNull(data.organization.teams)
      : [];

  return (
    <>
      {selected.type === UserComboboxOptionType.USER && (
        <GoalOverviewRelatedEntity
          title={`${selected.name}'s Reports`}
          entities={reports.map(mapToEntities)}
          entityLabel="report"
          loading={loading}
        />
      )}

      {selected.type === UserComboboxOptionType.USER && (
        <GoalOverviewRelatedEntity
          title={`${selected.name}'s Manager`}
          entities={managers.map(mapToEntities)}
          entityLabel="manager"
        />
      )}

      {selected.type === UserComboboxOptionType.USER && (
        <GoalOverviewRelatedEntity
          title={`${selected.name}'s ${label("team", {
            pluralize: true,
            capitalize: true,
          })}`}
          entityLabel="team"
          entities={userTeams.map(mapToEntities)}
        />
      )}

      {selected.type === UserComboboxOptionType.TEAM && (
        <GoalOverviewRelatedEntity
          title={`${selected.title}'s Members`}
          entityLabel="member"
          entities={teamMembers.map(mapToEntities)}
          loading={loading}
        />
      )}

      {selected.type === UserComboboxOptionType.ORG && (
        <GoalOverviewRelatedEntity
          title={`${selected.name} ${label("team", {
            pluralize: true,
            capitalize: true,
          })}`}
          entityLabel="team"
          entities={orgTeams.map(mapToEntities)}
          loading={loading}
        />
      )}
    </>
  );
};

export default GoalOverviewRelatedEntities;
