import { ExplorerFilterType } from "@apps/explorer/helpers";
import ComboboxGeneric, {
  ComboboxGenericOption,
} from "@components/combobox/generic-combobox";
import useHubspotComboboxQuery from "@components/combobox/use-hubspot-combobox-query";

import ExplorerComboboxButton from "../../explorer/components/explorer-combobox-button";

const ExplorerHubspotStageCombobox = ({
  stageId = null,
  prefix = "",
  ariaLabel = "Explorer hubspot stages combobox",
  onSelectStageId,
  className = "",
}: {
  stageId?: number | null;
  prefix: string;
  ariaLabel: string;
  onSelectStageId: (stageId: ExplorerFilterType["hubspotStageId"]) => void;
  className: string;
}) => {
  // Hooks
  const { options, selectedOption, loading } = useHubspotComboboxQuery({
    stageId,
  });

  const comboboxLoading = loading;

  const handleChangeValue = (
    option: ComboboxGenericOption<number | null | undefined>
  ) => {
    onSelectStageId(option.value || null);
  };

  const handleClearValue = () => {
    onSelectStageId(null);
  };

  return (
    <div className="flex-1 min-w-0">
      <ComboboxGeneric<number | null | undefined>
        aria-label={ariaLabel}
        loading={comboboxLoading}
        width="full"
        value={selectedOption}
        options={options}
        clearable={!!selectedOption}
        onChangeValue={handleChangeValue}
      >
        {({ value, onClickButton, clearable, setReferenceElement }) => (
          <ExplorerComboboxButton
            prefix={prefix}
            ariaLabel="Explorer stages combobox"
            setReferenceElement={setReferenceElement}
            onClickButton={onClickButton}
            label={value?.label || "All"}
            clearable={clearable}
            onClear={handleClearValue}
            className={className}
          />
        )}
      </ComboboxGeneric>
    </div>
  );
};

export default ExplorerHubspotStageCombobox;
