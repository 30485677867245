import {
  FilterIcon,
  PencilIcon,
  PlusCircleIcon,
  RefreshIcon,
  XIcon,
} from "@heroicons/react/outline";
import { Node, mergeAttributes } from "@tiptap/core";
import { Editor, ReactNodeViewRenderer } from "@tiptap/react";
import { NodeViewWrapper } from "@tiptap/react";
import { compact, defer } from "lodash";
import { useEffect, useState } from "react";
import {
  ArtifactType,
  CreatedArtifactFragmentFragment,
} from "types/graphql-schema";

import ArtifactCreationDialog from "@apps/artifact-creation-dialog/artifact-creation-dialog";
import FilterModal from "@apps/explorer/components/filter-modal";
import ExplorerContent, {
  getResultUniqueId,
} from "@apps/explorer/explorer-content";
import {
  ExplorerFilterType,
  getDefaultFilters,
  getExplorerFiltersUrl,
  getSanitizedExplorerFiltersFromUrlSearch,
  getTitleForExplorerUrl,
  testIsAllowedArtifactType,
} from "@apps/explorer/helpers";
import useExplorerQuery from "@apps/explorer/use-explorer-query";
import { currentUserVar } from "@cache/cache";
import Button, { buttonTheme } from "@components/button/button";
import Loading from "@components/loading/loading";
import Tooltip from "@components/tooltip/tooltip";
import { tiptapExtensionPriority } from "@helpers/constants";
import { classNames } from "@helpers/css";
import { getTiptapSchema, isValidUrl } from "@helpers/helpers";

import { getPluginClickOn, getPluginHandlePaste } from "../helpers";

const defaultHrefValue = null;

const ExplorerLinkComponent = ({
  node,
  selected,
  deleteNode,
  updateAttributes,
  getPos,
  editor,
}: {
  node: any;
  selected: boolean;
  deleteNode: () => void;
  updateAttributes: (attributes: any) => void;
  getPos: () => number;
  editor: Editor;
}) => {
  const isInTemplate = !!(editor.options?.editorProps as any)?.isTemplate;
  const defaultFilters = getDefaultFilters();
  const currentUser = currentUserVar();
  const currentUserHasCreatedNode =
    currentUser.id === parseInt(node.attrs.creatorId);
  const { href } = node.attrs;
  const hasNoHref = href === defaultHrefValue;
  const urlSearch = href ? node.attrs.href.split("?")[1] : null;
  const filters = getSanitizedExplorerFiltersFromUrlSearch(urlSearch);
  const [selection, setSelection] = useState<string[]>([]);
  const [showFilterDialog, setShowFilterDialog] = useState(hasNoHref);
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const [isSelecting, setIsSelecting] = useState(false);

  const pagination = 10;
  const {
    loading,
    data,
    refetch,
    results,
    pageInfo,
    fetchMore,
    loadingFirstTime,
    needSearchQueryForIntegrations,
  } = useExplorerQuery(
    filters,
    pagination,
    hasNoHref ? { skip: true } : { skip: false }
  );

  const artifactIds = compact(
    results.map((result) =>
      result.__typename === "BaseArtifactNode"
        ? result.specificArtifact?.id
        : null
    )
  );
  const artifactIdsString = artifactIds.join(",");
  const userIds = compact(
    results
      .filter((result) => result.__typename === "UserNode")
      .map((result) => result.id)
  );
  const userIdsString = userIds.join(",");

  const handleClickRefresh = () => {
    refetch();
  };

  const handleCloseFilterDialog = () => {
    setShowFilterDialog(false);
  };

  const handleClickDelete = () => {
    deleteNode();
  };

  const handleFetchMore = () => {
    fetchMore({
      variables: { after: pageInfo.endCursor, merge: true },
    });
  };

  const handleChangeTitle = () => {
    const newTitle =
      window.prompt("Title?", node.attrs.title) || node.attrs.title;
    updateAttributes({ title: newTitle?.trim() || "Explorer" });
  };

  const handleClickSaveDialogFilters = (dialogFilters: ExplorerFilterType) => {
    const url = getExplorerFiltersUrl(dialogFilters);
    updateAttributes({ href: `${document.location.origin}${url}` });
    handleCloseFilterDialog();
  };

  const handleClickBookmark = (bookmarkFilters: ExplorerFilterType) => () => {
    const newFilters = {
      ...defaultFilters,
      ...filters,
      ...bookmarkFilters,
    };
    const url = getExplorerFiltersUrl(newFilters);
    updateAttributes({ href: `${document.location.origin}${url}` });
    handleCloseFilterDialog();
  };

  const handleClickAddSelectedItems = () => {
    const selectedResults = results.filter((result) => {
      const uniqueResultId = getResultUniqueId(result);
      return selection.includes(uniqueResultId);
    });
    const content = compact(selectedResults)
      .map((result) =>
        result.__typename === "BaseArtifactNode"
          ? getTiptapSchema(result.specificArtifact)
          : getTiptapSchema(result)
      )
      .filter((val) => val);

    if (content.length > 0) {
      editor
        .chain()
        .focus(getPos() + 1)
        .insertContent(content)
        .run();
    }
    setSelection([]);
    setIsSelecting(false);
  };

  const handleToggleSelecting = () => {
    if (isSelecting) {
      setSelection([]);
    }
    setIsSelecting(!isSelecting);
  };

  const handleCheckResult = (result: any) => () => {
    const resultUniqueId = getResultUniqueId(result);
    if (selection.includes(resultUniqueId)) {
      setSelection(selection.filter((id) => id !== resultUniqueId));
    } else {
      setSelection(selection.concat(resultUniqueId));
    }
  };

  const handleCloseCreateDialog = (
    createdArtifact?: CreatedArtifactFragmentFragment
  ) => {
    if (createdArtifact) {
      refetch();
    }
    setShowCreateDialog(false);
  };

  useEffect(() => {
    if (
      !isInTemplate &&
      !loading &&
      !hasNoHref &&
      currentUserHasCreatedNode &&
      (node.attrs.artifactIds !== artifactIdsString ||
        node.attrs.userIds !== userIdsString)
    ) {
      defer(() => {
        // seems to prevent crashing of explorer...
        updateAttributes({
          artifactIds: artifactIdsString,
          userIds: userIdsString,
        });
      });
    }
  }, [artifactIdsString, userIdsString, loading, currentUserHasCreatedNode]);

  const isActionItemType = filters.type === ArtifactType.ActionItem;
  const isGoalType = filters.type === ArtifactType.Goal;
  const createArtifactFormOptions = {
    artifactType:
      filters.type && testIsAllowedArtifactType(filters.type)
        ? (filters.type as ArtifactType)
        : undefined,
    ...(isActionItemType && filters.actionItemState?.[0]
      ? { actionItemState: filters.actionItemState[0] }
      : {}),
    ...(isGoalType && filters.goalState?.[0]
      ? { goalState: filters.goalState[0] }
      : {}),
    ...(isGoalType && filters.goalScope ? { scope: filters.goalScope } : {}),
  };

  return (
    <NodeViewWrapper
      className={classNames(
        "shadow-sm border rounded-lg p-2 text-sm my-3 bg-gray-50 not-prose",
        selected && "border-blue-400"
      )}
    >
      {showCreateDialog && (
        <ArtifactCreationDialog
          formOptions={createArtifactFormOptions}
          onClose={handleCloseCreateDialog}
        />
      )}
      {showFilterDialog && currentUserHasCreatedNode && (
        <FilterModal
          filters={filters}
          onClose={handleCloseFilterDialog}
          onChangeFilters={handleClickSaveDialogFilters}
          onClickBookmark={handleClickBookmark}
        />
      )}
      {(loading || hasNoHref) && !currentUserHasCreatedNode ? (
        <div className="flex items-center gap-2 text-gray-500">
          <Loading mini size="4" />
          <span>
            {node.attrs.creatorName} is loading results from the explorer.
          </span>
        </div>
      ) : hasNoHref && currentUserHasCreatedNode ? (
        <div className="text-gray-500 flex justify-between items-center gap-4">
          <button
            className="text-blue-link hover:underline"
            onClick={() => setShowFilterDialog(true)}
          >
            Set search
          </button>
          <button onClick={handleClickDelete}>
            <XIcon className="h-5 w-5 text-gray-400 hover:text-gray-800" />
          </button>
        </div>
      ) : loading && !data ? (
        <Loading size="6">Loading results from explorer</Loading>
      ) : (
        <div>
          <div className="font-medium mb-2 px-1 flex items-center justify-between">
            <div className="flex-1 flex items-center gap-1.5">
              <a
                rel="noreferrer"
                href={node.attrs.href}
                target="_blank"
                className="hover:underline"
              >
                {node.attrs.title}
              </a>
              {editor.options.editable && (
                <Tooltip text="Edit title">
                  <button
                    className="shrink-0 text-gray-400 hover:text-gray-800"
                    onClick={handleChangeTitle}
                  >
                    <PencilIcon className="h-4 w-4" />
                  </button>
                </Tooltip>
              )}
            </div>
            <div className="flex gap-2 sm:gap-4 items-center">
              {loading && <Loading size="5" mini />}
              {!loading &&
                !isInTemplate &&
                node.attrs.creatorId === currentUser.id && (
                  <button
                    className="text-xs text-gray-400 hover:text-gray-800 font-normal"
                    onClick={handleToggleSelecting}
                  >
                    {!isSelecting ? "Select..." : "Cancel selection"}
                  </button>
                )}
              {!loading && (
                <Tooltip text="refresh results">
                  <button onClick={handleClickRefresh}>
                    <RefreshIcon className="h-5 w-5 text-gray-400 hover:text-gray-800" />
                  </button>
                </Tooltip>
              )}
              {editor.options.editable && !isInTemplate && (
                <Tooltip text="Create">
                  <button onClick={() => setShowCreateDialog(true)}>
                    <PlusCircleIcon className="h-5 w-5 text-gray-400 hover:text-gray-800" />
                  </button>
                </Tooltip>
              )}
              {editor.options.editable && (
                <Tooltip text="Edit filters">
                  <button
                    className="shrink-0 text-gray-400 hover:text-gray-800"
                    onClick={() => setShowFilterDialog(true)}
                  >
                    <FilterIcon className="h-5 w-5" />
                  </button>
                </Tooltip>
              )}
              {editor.options.editable && (
                <button onClick={handleClickDelete}>
                  <XIcon className="h-5 w-5 text-gray-400 hover:text-gray-800" />
                </button>
              )}
            </div>
          </div>
          <div>
            <ExplorerContent
              filters={filters}
              loading={loading}
              loadingFirstTime={loadingFirstTime}
              needSearchQueryForIntegrations={needSearchQueryForIntegrations}
              onClickMore={handleFetchMore}
              pageInfo={pageInfo}
              results={results}
              resultClassName="flex-1 py-2 px-3 gap-2 block relative"
              onSelectResult={handleCheckResult}
              selectionActivated={isSelecting}
              selection={selection}
            />
          </div>
          {isSelecting && (
            <div className="mt-2 flex items-center gap-2">
              <Button
                theme={buttonTheme.primary}
                disabled={selection.length === 0}
                tooltip={
                  selection.length === 0 ? "Select items in the list above" : ""
                }
                small
                text={`Show only the selected items`}
                onClick={handleClickAddSelectedItems}
              />
              <Button
                small
                text="Cancel"
                onClick={() => setIsSelecting(false)}
              />
            </div>
          )}
        </div>
      )}
    </NodeViewWrapper>
  );
};

const ExplorerExtension = Node.create({
  name: "explorer",
  group: "block",
  marks: "",
  draggable: true,
  selectable: true,
  allowGapCursor: true,
  atom: true,
  isolating: true,
  defining: true,

  addOptions() {
    return {
      onShowDialog: null,
    };
  },

  addAttributes() {
    return {
      href: {
        default: null,
        renderHTML: (attributes) => {
          return { href: attributes.href };
        },
      },
      artifactIds: {
        default: null,
      },
      userIds: {
        default: null,
      },
      creatorName: {
        default: null,
        parseHTML: (node) => {
          if (node.tagName.toLowerCase() === "a") {
            const currentUser = currentUserVar();
            return currentUser.name;
          }
          return null;
        },
      },
      creatorId: {
        default: null,
        parseHTML: (node) => {
          if (node.tagName.toLowerCase() === "a") {
            const currentUser = currentUserVar();
            return currentUser.id;
          }
          return null;
        },
      },
      title: {
        default: "Explorer",
        parseHTML: (node: HTMLAnchorElement) => {
          if (node.tagName.toLowerCase() === "a") {
            if (node.title) return node.title;
            return getTitleForExplorerUrl(node.href);
          }
          return "Explorer";
        },
        renderHTML: (attributes) => {
          return { title: attributes.title };
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "a",
        priority: tiptapExtensionPriority.explorerLink,
        getAttrs: (node: HTMLElement | string) => {
          if (
            node &&
            node instanceof HTMLAnchorElement &&
            node.href.startsWith(`${document.location.origin}/explorer`) &&
            new URL(node.href).pathname === "/explorer"
          ) {
            return null;
          }
          return false;
        },
      },
    ];
  },

  renderHTML({ HTMLAttributes, node }) {
    return ["a", mergeAttributes(HTMLAttributes), node.attrs.title];
  },

  renderText({ node }) {
    return `${node.attrs.href}`;
  },

  addNodeView() {
    return ReactNodeViewRenderer(ExplorerLinkComponent);
  },

  addCommands() {
    return {
      addEmptyExplorer:
        (attrs) =>
        ({ chain }) => {
          return chain()
            .insertContent({
              type: this.name,
              attrs,
            })
            .run();
        },
    };
  },

  addProseMirrorPlugins() {
    return [
      getPluginClickOn(this.name),
      getPluginHandlePaste(this, (text, view) => {
        if (
          !text ||
          !isValidUrl(text) ||
          !text.startsWith(`${document.location.origin}/explorer`)
        ) {
          return false;
        }

        // Detect explorer url
        const { tr } = view.state;
        tr.replaceSelectionWith(this.type.create({ href: text }));
        view.dispatch(tr);
        return true;
      }),
    ];
  },
});

export default ExplorerExtension;
