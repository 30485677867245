import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/solid";
import {
  Fragment,
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from "react";

import RoundedShadowContainer from "@components/rounded-shadow-container/rounded-shadow-container";
import { classNames } from "@helpers/css";

interface Props {
  container?: React.ComponentType;
  startOpen?: boolean;
  roundedTop?: boolean;
  roundedBottom?: boolean;
  title: string;
  titleRightSide?: any;
  newSidebarDesign?: boolean;
  onToggle?: (isOpen: boolean) => void;
}

const CollapsibleContainer: React.FC<PropsWithChildren<Props>> = ({
  container,
  children,
  startOpen = true,
  roundedTop = false,
  roundedBottom = false,
  title,
  titleRightSide = null,
  onToggle,
  newSidebarDesign = false,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(startOpen);

  useEffect(() => {
    setIsOpen(startOpen);
  }, [startOpen]);

  const handleClickTitle = useCallback(() => {
    if (onToggle) {
      onToggle(!isOpen);
    }
    setIsOpen(!isOpen);
  }, [isOpen, onToggle]);

  const Container = newSidebarDesign
    ? Fragment
    : container ?? RoundedShadowContainer;
  return (
    <Container>
      <div
        className={classNames(
          newSidebarDesign ? "" : isOpen ? "text-gray-800" : "text-gray-500",
          !newSidebarDesign && "divide-y divide-gray-100"
          // don't use container queries, or it'll mess up the action items drag and drop
          // https://github.com/Topicflow/topicflow/issues/1608
        )}
      >
        <div
          className={classNames(
            "fs-unmask",
            newSidebarDesign
              ? "flex items-center justify-between"
              : "bg-gray-50 hover:bg-gray-100 uppercase py-2 px-3 flex items-center justify-between tracking-tight",
            newSidebarDesign && isOpen && "mb-2",
            !newSidebarDesign && roundedTop && "rounded-t-lg",
            !newSidebarDesign && roundedBottom && !isOpen && "rounded-b-lg"
          )}
          role="button"
          onClick={handleClickTitle}
        >
          {newSidebarDesign ? (
            <div
              className={classNames(
                "font-medium text-sm flex-1 flex items-center",
                isOpen ? "text-gray-700" : "text-gray-400 hover:text-gray-700"
              )}
            >
              {title}
            </div>
          ) : (
            <div className="uppercase text-xs font-semibold flex items-center">
              {isOpen ? (
                <ChevronDownIcon className="h-5 w-5 mr-2" />
              ) : (
                <ChevronRightIcon className="h-5 w-5 mr-2" />
              )}
              {title}
            </div>
          )}
          {titleRightSide}
        </div>
        {isOpen && (
          <div
            className={classNames(
              !newSidebarDesign && "bg-white",
              !newSidebarDesign && roundedBottom && "rounded-b-lg"
            )}
          >
            {children}
          </div>
        )}
      </div>
    </Container>
  );
};

export default CollapsibleContainer;
