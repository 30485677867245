import { MouseEvent } from "react";

import Tooltip from "@components/tooltip/tooltip";
import { classNames } from "@helpers/css";

export type ToggleButtonGroupType<T> = {
  tooltip?: string;
  className?: string;
  title: string;
  active: boolean;
  disabled?: boolean;
  value?: T;
};

export enum ToggleButtonGroupTheme {
  roundedCompact,
  roundedNormal,
  buttons,
  sidebar,
}

export const ToggleButtonGroup = <T,>({
  buttons,
  onClick,
  className = "",
  theme = ToggleButtonGroupTheme.roundedCompact,
}: {
  className?: string;
  onClick: (
    button: ToggleButtonGroupType<T>,
    e: MouseEvent<HTMLButtonElement>
  ) => void;
  buttons: ToggleButtonGroupType<T>[];
  theme?: ToggleButtonGroupTheme;
}) => {
  const handleClick =
    (button: ToggleButtonGroupType<T>) =>
    (e: MouseEvent<HTMLButtonElement>) => {
      onClick(button, e);
    };

  return (
    <div
      className={classNames(
        "flex flex-wrap shrink-0",
        (theme === ToggleButtonGroupTheme.roundedCompact ||
          theme === ToggleButtonGroupTheme.roundedNormal) &&
          "gap-0.5 p-0.5 rounded-full bg-black/10",
        theme === ToggleButtonGroupTheme.buttons &&
          "rounded bg-white border divide-x",
        theme === ToggleButtonGroupTheme.sidebar && "gap-4",
        className
      )}
    >
      {buttons.map((button) => (
        <Tooltip key={button.title} text={button.tooltip}>
          <button
            type="button"
            className={classNames(
              "shrink-0",
              (theme === ToggleButtonGroupTheme.roundedCompact ||
                theme === ToggleButtonGroupTheme.roundedNormal) &&
                "px-1.5 py-px tracking-tight rounded-full text-xs font-medium",
              theme === ToggleButtonGroupTheme.roundedNormal && "px-3 py-0.5",
              theme === ToggleButtonGroupTheme.roundedCompact ||
                theme === ToggleButtonGroupTheme.roundedNormal
                ? button.active
                  ? "bg-gray-50 text-gray-800"
                  : "hover:text-gray-800 text-gray-600"
                : null,
              theme === ToggleButtonGroupTheme.buttons &&
                "px-3 py-1.5 text-sm text-gray-600",
              theme === ToggleButtonGroupTheme.buttons
                ? button.active
                  ? "bg-blue-50 text-blue-800"
                  : "hover:text-gray-800 hover:bg-gray-50"
                : null,
              theme === ToggleButtonGroupTheme.sidebar &&
                "text-sm font-semibold px-1",
              theme === ToggleButtonGroupTheme.sidebar
                ? button.active
                  ? "text-gray-700"
                  : "text-gray-400 hover:text-gray-800"
                : null,
              button.className
            )}
            disabled={button.disabled}
            onClick={handleClick(button)}
          >
            {button.title}
          </button>
        </Tooltip>
      ))}
    </div>
  );
};
