import { useMutation } from "@apollo/client";
import { ChangeEventHandler, useEffect, useState } from "react";
import { GetAccountQuery } from "types/graphql-schema";

import { currentUserVar } from "@cache/cache";
import Heading from "@components/heading/heading";
import Select, { SelectOption } from "@components/select/select";
import SettingsLayout from "@components/settings-layout/settings-layout";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { assertNonNull } from "@helpers/helpers";
import timezones from "@helpers/timezones";

import updateUserMutation from "../graphql/update-user-mutation";

const linkOptions = [
  {
    value: "always",
    label: "For all events",
  },
  {
    value: "meetings_with_topics",
    label: "Only for events with agenda items in Topicflow",
  },
  {
    value: "never",
    label: "Never add links to my calendar events",
  },
];

const Preferences = ({ me }: { me: GetAccountQuery["me"] }) => {
  const currentUser = currentUserVar();
  const selectedLinkOption = currentUser.eventLinkInDescriptionPreference;
  const timezoneOptions = timezones.map((timezone) => ({
    label: timezone,
    value: timezone,
  }));

  const [timezone, setTimezone] = useState<string | null | undefined>(null);
  const [updateUser] = useMutation(updateUserMutation);

  const handleChangeTimezone = (option: SelectOption<string>) => {
    const value = option.value;
    setTimezone(value);
    updateUser({
      variables: { userId: assertNonNull(me).id, timezone: value },
      onError: onNotificationErrorHandler(),
    });
  };

  const handleChangeSelectedLinkOption: ChangeEventHandler<HTMLInputElement> = (
    e
  ) => {
    updateUser({
      variables: {
        userId: currentUser.id,
        eventLinkInDescriptionPreference: e.target.value,
      },
      onError: onNotificationErrorHandler(),
    });
  };

  useEffect(() => {
    setTimezone(assertNonNull(me).timezone);
  }, [me]);

  return (
    <div>
      <Heading small title="Preferences" />

      <SettingsLayout.Container>
        <SettingsLayout.Row>
          <SettingsLayout.Left label="* Only affects events where you are the organizer">
            Automatically add a Topicflow link to calendar events
          </SettingsLayout.Left>
          <SettingsLayout.Right>
            <div className="flex flex-col">
              {linkOptions.map(({ value, label }) => (
                <div
                  key={value}
                  className="text-sm text-gray-800 flex items-center py-1"
                >
                  <input
                    type="radio"
                    name="link-option"
                    id={`input-link-option-${value}`}
                    value={value}
                    defaultChecked={selectedLinkOption === value}
                    onChange={handleChangeSelectedLinkOption}
                  />
                  <label
                    className="ml-2 cursor-pointer"
                    htmlFor={`input-link-option-${value}`}
                  >
                    {label}
                  </label>
                </div>
              ))}
              <div className="text-gray-500 text-sm mt-2">
                Events created using Topicflow will always include a link to the
                agenda.
              </div>
            </div>
          </SettingsLayout.Right>
        </SettingsLayout.Row>

        <SettingsLayout.Row>
          <SettingsLayout.Left>Timezone</SettingsLayout.Left>
          <SettingsLayout.Right>
            <div className="lg:w-96">
              {timezone && (
                <Select
                  width="full"
                  value={timezone}
                  options={timezoneOptions}
                  onChange={handleChangeTimezone}
                  aria-label="Timezone select"
                />
              )}
            </div>
          </SettingsLayout.Right>
        </SettingsLayout.Row>
      </SettingsLayout.Container>
    </div>
  );
};

export default Preferences;
