import { LockClosedIcon, LockOpenIcon } from "@heroicons/react/outline";
import { GoalVisibility } from "types/graphql-schema";

import Tooltip from "@components/tooltip/tooltip";
import { classNames } from "@helpers/css";

type GoalVisibilityIconProps = {
  goalVisibility: GoalVisibility;
  size?: string;
  className?: string;
  showOnlyIfPrivate?: boolean;
};

const GoalVisibilityIcon = ({
  goalVisibility,
  size = "5",
  showOnlyIfPrivate = false,
  className = "",
}: GoalVisibilityIconProps) => {
  return goalVisibility === GoalVisibility.Public && !showOnlyIfPrivate ? (
    <LockOpenIcon
      className={classNames(`w-${size} h-${size} text-gray-400`, className)}
      aria-label="Public goal icon"
    />
  ) : goalVisibility === GoalVisibility.Private ? (
    <Tooltip text="Goal can be seen only by participants">
      <LockClosedIcon
        className={classNames(`w-${size} h-${size} text-gray-500`, className)}
        aria-label="Private goal icon"
      />
    </Tooltip>
  ) : null;
};

export default GoalVisibilityIcon;
