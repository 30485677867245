import { useMutation } from "@apollo/client";
import { DuplicateIcon } from "@heroicons/react/outline";
import { MenuItem, MenuItemProps } from "@szhsin/react-menu";

import { errorNotificationVar, successNotificationVar } from "@cache/cache";
import AppLink from "@components/link/link";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { getFormattedDatetime, getUrl } from "@helpers/helpers";

import copyTopicToMeetingMutation from "../graphql/copy-topic-to-meeting-mutation";

const DuplicateToCurrentMeetingMenuItem = ({
  topic,
  meeting,
  meetingGroup,
  currentPageMeeting,
  ...props
}: MenuItemProps & {
  topic: any;
  meeting: any;
  meetingGroup: any;
  currentPageMeeting: any;
}) => {
  const [copyTopicToMeeting] = useMutation(copyTopicToMeetingMutation, {
    onError: (e) => {
      errorNotificationVar({
        description: "The topic could not be added to the current meeting.",
      });
    },
  });

  const handleClickScrollTo = (path: string) => () => {
    const el = document.querySelector(path);
    if (el) {
      el.scrollIntoView();
    }
  };

  /* Handler */
  const handleDuplicateToCurrentMeeting = () => {
    copyTopicToMeeting({
      variables: {
        topicIds: [topic.id],
        meetingId: currentPageMeeting.id,
      },
      onCompleted: ({ copyTopicsToMeeting }) => {
        const copiedTopic = copyTopicsToMeeting.copiedTopics[0];
        const path = `#meeting-${currentPageMeeting.id}-topic-${copiedTopic.id}`;
        successNotificationVar({
          title: "Topic duplicated",
          description: (
            <div className="text-gray-800">
              Topic duplicated to{" "}
              <AppLink
                className="text-blue-600 hover:underline"
                to={`${getUrl({
                  meetingGroupId: currentPageMeeting.meetingGroup.id,
                  meetingId: currentPageMeeting.id,
                })}${path}`}
                onClick={handleClickScrollTo(path)}
              >
                {currentPageMeeting.title}
              </AppLink>{" "}
              <span className="text-gray-500">
                ({getFormattedDatetime(currentPageMeeting.startDatetime)})
              </span>
            </div>
          ),
        });
      },
      onError: onNotificationErrorHandler(),
    });
  };

  // Render
  return (
    <MenuItem
      className="text-sm"
      onClick={handleDuplicateToCurrentMeeting}
      {...props}
    >
      <DuplicateIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />{" "}
      Duplicate to current meeting
    </MenuItem>
  );
};

export default DuplicateToCurrentMeetingMenuItem;
