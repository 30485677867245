// from import googleUrl from "google-url-helper";
const googleUrls = {
  spreadsheet: {
    match: /\/spreadsheets\/d\/([\w-.]+)/,
    base: "https://docs.google.com/spreadsheets/d/",
  },
  document: {
    match: /\/document\/d\/([\w-.]+)/,
    base: "https://docs.google.com/document/d/",
  },
  folder: {
    match: /\/folders\/([\w-.]+)/,
    base: "https://drive.google.com/drive/folders/",
  },
  file: {
    match: /\/file\/d\/([\w-.]+)/,
    base: "https://drive.google.com/file/d/",
  },
  presentation: {
    match: /\/presentation\/d\/([\w-.]+)/,
    base: "https://docs.google.com/presentation/d/",
  },
  form: {
    match: /\/forms\/d\/([\w-.]+)/,
  },
  general: {
    match: /\/d\/([\w-.]+)/,
  },
};

export const parseGoogleUrl = (url: string) => {
  let match = url.match(googleUrls.spreadsheet.match);
  if (match) {
    return { id: match[1], type: "spreadsheet" };
  }
  match = url.match(googleUrls.folder.match);
  if (match) {
    return { id: match[1], type: "folder" };
  }
  match = url.match(googleUrls.document.match);
  if (match) {
    return { id: match[1], type: "document" };
  }
  match = url.match(googleUrls.file.match);
  if (match) {
    return { id: match[1], type: "file" };
  }
  match = url.match(googleUrls.form.match);
  if (match) {
    return { id: match[1], type: "form" };
  }
  match = url.match(googleUrls.presentation.match);
  if (match) {
    return { id: match[1], type: "presentation" };
  }
  match = url.match(googleUrls.general.match);
  if (match) {
    return { id: match[1], type: "unknown" };
  }
  return null;
};
