import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  ArtifactType,
  GetRecognitionBoardQueryQueryVariables,
} from "types/graphql-schema";
import { DateRangeEnum } from "types/topicflow";

import ArtifactCreationDialog from "@apps/artifact-creation-dialog/artifact-creation-dialog";
import useLabel from "@apps/use-label/use-label";
import { currentOrganizationVar } from "@cache/cache";
import Button, { buttonTheme } from "@components/button/button";
import AppLink, { useLink } from "@components/link/link";
import { dateRangeToDateArray, isBackgroundLocation } from "@helpers/helpers";

import getRecognitionBoardCoreValuesQuery from "./graphql/get-recognition-board-core-values-query";
import getRecognitionBoardQuery from "./graphql/get-recognition-board-query";
import RecognitionBoardFilters from "./recognition-board-filters";
import RecognitionBoardList from "./recognition-board-list";

export enum RecognitionBoardExtraDateRangeEnum {
  all = "all",
  custom = "custom",
}
export type RecognitionBoardDateRangeType =
  | RecognitionBoardExtraDateRangeEnum
  | DateRangeEnum;

const RecognitionBoard = () => {
  const label = useLabel();
  const link = useLink();
  const location = useLocation();

  const getFilters = () => {
    const params = new URLSearchParams(location.search);
    if (params.toString()) {
      const coreValueId = params.get("coreValueId");
      const recipientId = params.get("recipientId");
      const receivedByMembersOfTeamId = params.get("receivedByMembersOfTeamId");
      return {
        coreValueId: coreValueId ? parseInt(coreValueId) : null,
        recipientId: recipientId ? parseInt(recipientId) : null,
        receivedByMembersOfTeamId: receivedByMembersOfTeamId
          ? parseInt(receivedByMembersOfTeamId)
          : null,
      };
    } else {
      return {
        coreValueId: null,
        recipientId: null,
        receivedByMembersOfTeamId: null,
      };
    }
  };

  const currentOrganization = currentOrganizationVar();
  const [createdDateRange, setCreatedDateRange] =
    useState<RecognitionBoardDateRangeType>(DateRangeEnum.thisQuarter);
  const [createdBetweenDates, setCreatedBetweenDates] = useState<string[]>(
    dateRangeToDateArray({
      range: DateRangeEnum.thisQuarter,
      quarterStartMonth: currentOrganization.quarterStartMonth,
    })
  );
  const [recipientId, setRecipientId] = useState<
    GetRecognitionBoardQueryQueryVariables["recipientId"]
  >(getFilters().recipientId);
  const [receivedByMembersOfTeamId, setReceivedByMembersOfTeamId] = useState<
    GetRecognitionBoardQueryQueryVariables["receivedByMembersOfTeamId"]
  >(getFilters().receivedByMembersOfTeamId);
  const [coreValueId, setCoreValueId] = useState<
    GetRecognitionBoardQueryQueryVariables["coreValueId"]
  >(getFilters().coreValueId);
  const [showRecognitionDialog, setShowRecognitionDialog] = useState(false);

  const handleCloseArtifactCreationDialog = useCallback(() => {
    setShowRecognitionDialog(false);
  }, []);

  const handleChangeRecipient = (
    recipientId?: number | null,
    receivedByMembersOfTeamId?: number | null
  ) => {
    setRecipientId(recipientId);
    setReceivedByMembersOfTeamId(receivedByMembersOfTeamId);
  };

  useEffect(() => {
    const qs = new URLSearchParams();
    if (coreValueId) {
      qs.append("coreValueId", String(coreValueId));
    } else {
      qs.delete("coreValueId");
    }
    if (recipientId) {
      qs.append("recipientId", String(recipientId));
    } else {
      qs.delete("recipientId");
    }
    if (receivedByMembersOfTeamId) {
      qs.append("receivedByMembersOfTeamId", String(receivedByMembersOfTeamId));
    } else {
      qs.delete("receivedByMembersOfTeamId");
    }
    const qsParams =
      Array.from(qs.keys()).length > 0 ? `?${qs.toString()}` : "";
    if (isBackgroundLocation(location))
      link.replace(`/recognition-board${qsParams}`);
  }, [coreValueId, recipientId, receivedByMembersOfTeamId]);

  // Render
  return (
    <div aria-label="Recognition board" className="flex flex-col flex-1">
      <div className="flex flex-wrap items-center justify-between h-14 px-4 sm:px-6 border-b bg-gray-50">
        <AppLink
          className="mb-2 sm:mb-0 text-xl font-medium mr-8 flex items-center"
          to="/recognition-board"
        >
          {label("recognition", { capitalize: true })} board
        </AppLink>

        <div className="flex items-center gap-4">
          <Button
            theme={buttonTheme.primary}
            onClick={() => setShowRecognitionDialog(true)}
            text={`Give a ${label("recognition")}`}
            small
          />
        </div>
      </div>
      {showRecognitionDialog && (
        <ArtifactCreationDialog
          formOptions={{
            artifactType: ArtifactType.Recognition,
          }}
          onClose={handleCloseArtifactCreationDialog}
          hideTypeSelector
          refetchQueries={[
            getRecognitionBoardCoreValuesQuery,
            getRecognitionBoardQuery,
          ]}
        />
      )}
      <div className="flex flex-col sm:flex-row gap-8 p-6">
        <RecognitionBoardFilters
          coreValueId={coreValueId}
          recipientId={recipientId}
          receivedByMembersOfTeamId={receivedByMembersOfTeamId}
          createdBetweenDates={createdBetweenDates}
          createdDateRange={createdDateRange}
          onChangeCoreValueId={setCoreValueId}
          onChangeRecipient={handleChangeRecipient}
          onChangeCreatedBetweenDates={setCreatedBetweenDates}
          onChangeCreatedDateRange={setCreatedDateRange}
        />
        <RecognitionBoardList
          coreValueId={coreValueId}
          receivedByMembersOfTeamId={receivedByMembersOfTeamId}
          recipientId={recipientId}
          createdBetweenDates={createdBetweenDates}
          createdDateRange={createdDateRange}
          onShowCreationDialog={() => setShowRecognitionDialog(true)}
        />
      </div>
    </div>
  );
};

export default RecognitionBoard;
