import { Route, Switch, useRouteMatch } from "react-router-dom";

import AppLink from "@components/link/link";
import useDocumentTitle from "@components/use-document-title/use-document-title";

import RatingsEdit from "./components/edit";
import RatingsList from "./components/list";
import RatingsView from "./components/view";

const Ratings = () => {
  useDocumentTitle("Ratings");
  const { path } = useRouteMatch();

  // RENDER
  return (
    <div aria-label="Ratings" className="flex flex-col flex-1">
      <div className="flex flex-wrap items-center justify-between h-14 px-4 sm:px-6 border-b bg-gray-50">
        <AppLink
          className="mb-2 sm:mb-0 text-xl font-medium mr-8 flex items-center"
          to="/ratings"
        >
          Ratings
        </AppLink>
      </div>
      <div className="p-6">
        <div className="bg-white flex rounded-lg shadow overflow-hidden p-6">
          <Switch>
            <Route exact path={[`${path}`, `${path}/group/:groupId`]}>
              <RatingsList />
            </Route>
            <Route
              exact
              path={[
                `${path}/new`,
                `${path}/:ratingId(\\d+)/edit`,
                `${path}/:ratingId(\\d+)/duplicate`,
              ]}
            >
              <RatingsEdit />
            </Route>
            <Route exact path={`${path}/:ratingId(\\d+)`}>
              <RatingsView />
            </Route>
            {/*
              <Route exact path={`${path}/:ratingId(\\d+)/report`}>
                <TemplateReport />
              </Route>
              */}
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default Ratings;
