import { useMutation } from "@apollo/client";
import { useState } from "react";
import {
  DeleteExplorerBookmarkMutationMutation,
  DeleteExplorerBookmarkMutationMutationVariables,
  ExplorerBookmarkFragmentFragment,
} from "types/graphql-schema";

import useLabel from "@apps/use-label/use-label";
import AppLink from "@components/link/link";
import { TableBodyCell, TableBodyRow } from "@components/table/table";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import useConfirm from "@helpers/hooks/use-confirm";

import deleteExplorerBookmarkMutation from "../graphql/delete-explorer-bookmark-mutation";
import { getExplorerFiltersUrl } from "../helpers";
import ExplorerBookmarkEditDialog from "./bookmark-edit-dialog";
import ExplorerBookmarkIcon from "./bookmark-icon";

const ExplorerBookmarkTableRow = ({
  bookmark,
}: {
  bookmark: ExplorerBookmarkFragmentFragment;
}) => {
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [deleteBookmark] = useMutation<
    DeleteExplorerBookmarkMutationMutation,
    DeleteExplorerBookmarkMutationMutationVariables
  >(deleteExplorerBookmarkMutation);
  const label = useLabel();
  const filters = JSON.parse(bookmark.filters);
  const { ConfirmationDialog, confirm } = useConfirm(
    "Confirm",
    "Are you sure you want to delete this bookmark?"
  );

  const handleClickDelete = async () => {
    const confirmation = await confirm();
    if (confirmation) {
      deleteBookmark({
        variables: { explorerBookmarkId: bookmark.id },
        onError: onNotificationErrorHandler(),
      });
    }
  };

  // RENDER
  return (
    <TableBodyRow key={bookmark.id}>
      <TableBodyCell className="flex items-center gap-1">
        <ExplorerBookmarkIcon type={filters.type} />
        <AppLink
          to={getExplorerFiltersUrl(filters)}
          className="text-blue-link hover:underline"
        >
          {bookmark.title}
        </AppLink>
      </TableBodyCell>
      <TableBodyCell>
        {bookmark.private
          ? "Only you"
          : label("organization", { capitalize: true })}
      </TableBodyCell>
      <TableBodyCell className="flex gap-6 justify-end items-center">
        {showEditDialog && (
          <ExplorerBookmarkEditDialog
            bookmark={{
              ...bookmark,
              filters,
            }}
            onClose={() => setShowEditDialog(false)}
          />
        )}
        <ConfirmationDialog />
        {bookmark.canUpdate.permission && (
          <button
            className="text-blue-link hover:underline"
            onClick={() => setShowEditDialog(true)}
          >
            Edit
          </button>
        )}
        {bookmark.canDelete.permission && (
          <button
            className="text-blue-link hover:underline"
            onClick={handleClickDelete}
          >
            Delete
          </button>
        )}
      </TableBodyCell>
    </TableBodyRow>
  );
};

export default ExplorerBookmarkTableRow;
