import { gql } from "@apollo/client";

export default gql`
  mutation removeClickupIntegrationMutation {
    removeClickupIntegration {
      user {
        id
        hasClickupAuth
      }
    }
  }
`;
