import Container from "@components/container/container";
import Heading from "@components/heading/heading";
import Link from "@components/link/link";

export default function Error404() {
  return (
    <Container>
      <Heading title="This page does not exist" />
      The page was not found. Go to the{" "}
      <Link to="/" className="text-blue-600 hover:underline">
        homepage
      </Link>
      .
    </Container>
  );
}
