import { gql } from "@apollo/client";
import { ReactRenderer } from "@tiptap/react";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import { compact, groupBy, memoize, uniq, uniqBy } from "lodash";
import tippy from "tippy.js";
import {
  ArtifactType,
  BaseArtifactNode,
  DecisionArtifactNode,
  GetCachedMeetingGroupSuggestionsQueryQuery,
  GetCachedMeetingGroupSuggestionsQueryQueryVariables,
  GetCachedMeetingSuggestionsQueryQuery,
  GetCachedMeetingSuggestionsQueryQueryVariables,
  GoalArtifactNode,
  UserNode,
} from "types/graphql-schema";

import MeetingGroupGoalsFragment from "@apps/meeting-new/graphql/meeting-group-goals-fragment";
import { MeetingParticipantsNewPageFragment } from "@apps/meeting-new/graphql/meeting-participants-fragment";
import cache, {
  CurrentUserType,
  artifactNodeTypes,
  artifactsVar,
  currentUserVar,
  usersVar,
} from "@cache/cache";
import ArtifactAssigneeFragment from "@graphql/artifact-assignee-fragment";
import ArtifactMentionFragment from "@graphql/artifact-mention-fragment";
import client from "@graphql/client";
import { MeetingGroupParticipantsFragment } from "@graphql/meeting-group-participants-fragment";
import UserMentionFragment from "@graphql/user-mention-fragment";
import { tooltipZIndex } from "@helpers/constants";
import { assertEdgesNonNull } from "@helpers/helpers";

import MentionList from "./mention-list";
import searchAtMentionsQuery from "./search-at-mentions-query";

const getMeetingGroupCachedSuggestionsQuery = gql`
  ${MeetingGroupParticipantsFragment}
  ${MeetingGroupGoalsFragment}
  query getCachedMeetingGroupSuggestionsQuery(
    $meetingGroupId: Int!
    $flowId: Int
    $hasMeeting: Boolean!
    $participantSearch: String = ""
  ) {
    meetingGroup(meetingGroupId: $meetingGroupId) {
      id
      ...MeetingGroupParticipantsFragment
    }
  }
`;
const getMeetingCachedSuggestionsQuery = gql`
  ${MeetingParticipantsNewPageFragment}
  query getCachedMeetingSuggestionsQuery(
    $meetingId: Int
    $participantSearch: String = ""
  ) {
    meeting(meetingId: $meetingId) {
      id
      ...MeetingParticipantsNewPageFragment
    }
  }
`;

const mapUserNodeToMentions = (node: null | UserNode) =>
  node
    ? {
        ...node,
        type: "user",
        id: node.id,
        label: node.name,
      }
    : null;

const mapUserEdgesToMentions = ({ node }: { node: UserNode }) =>
  mapUserNodeToMentions(node);

const mapUserParticipantNodeToMentions = (node: any) =>
  node && node.user
    ? {
        ...node.user,
        type: "user",
        id: node.user.id,
        label: node.user.name,
      }
    : null;

const mapUserParticipantsEdgesToMentions = (edge: any) =>
  mapUserParticipantNodeToMentions(edge?.node!);

const mapArtifactNodeToMention = (
  node: null | BaseArtifactNode | GoalArtifactNode | DecisionArtifactNode
) =>
  node
    ? {
        ...node,
        type: node.artifactType,
        id: node.id,
        label:
          (node.__typename === "DecisionArtifactNode"
            ? node.decision
            : node.title) || "",
      }
    : null;
const mapArtifactEdgesToMentions = ({
  node,
}: {
  node: BaseArtifactNode | DecisionArtifactNode;
}) => mapArtifactNodeToMention(node);

const limitPerGroup = 4;

const filterAndLimitMentions = (mentions: any, query: string) =>
  uniqBy(compact(mentions), "id")
    .filter((item: any) =>
      query.length > 0
        ? item.label.toLowerCase().includes(query.toLowerCase())
        : true
    )
    .slice(0, limitPerGroup);

let lastPromise: any = null;

const searchApi = ({
  query,
  onlyUsers,
}: {
  query: string;
  onlyUsers: boolean;
}) => {
  return client.query({
    query: searchAtMentionsQuery,
    fetchPolicy: "network-only", // default policy does not work on client.query
    variables: {
      search: query,
      onlyUsers,
      limit: limitPerGroup,
    },
  });
};
const debouncedSearchApi = AwesomeDebouncePromise(searchApi, 500);

const getUserRelatedUsers = memoize((user: CurrentUserType) => {
  const managerIds = (
    user?.managers ? assertEdgesNonNull(user.managers) : []
  ).map(({ id }) => id);
  const directReportIds = (
    user?.directReports ? assertEdgesNonNull(user.directReports) : []
  ).map(({ id }) => id);
  const favouriteGroups = user?.favouritesGroups
    ? assertEdgesNonNull(user.favouritesGroups)
    : [];
  const favouriteUserIds = favouriteGroups.reduce((memo, favouriteGroup) => {
    return [
      ...memo,
      ...assertEdgesNonNull(favouriteGroup.users).map(({ id }) => id),
    ];
  }, [] as number[]);
  const relatedUserIds = uniq(
    compact([user?.id, ...managerIds, ...directReportIds, ...favouriteUserIds])
  );
  return relatedUserIds.map((id) =>
    mapUserNodeToMentions(
      client.readFragment({
        id: `UserNode:${id}`,
        fragment: UserMentionFragment,
      })
    )
  );
});

const getCachedMentions = ({
  artifactId,
  meetingGroupId,
  meetingId,
  query,
  onlyUsers,
  currentUser,
}: {
  artifactId?: number;
  meetingGroupId?: number;
  meetingId?: number;
  query: string;
  onlyUsers: boolean;
  currentUser: CurrentUserType;
}) => {
  const cachedRelatedUsers = getUserRelatedUsers(currentUser);
  // Artifact assignees && assignable users
  const cachedArtifactAssignee = artifactId
    ? artifactNodeTypes
        .map((artifactNodeType) =>
          client.readFragment({
            id: cache.identify({
              id: artifactId,
              __typename: artifactNodeType,
            }),
            fragment: ArtifactAssigneeFragment,
          })
        )
        .find((response) => response)
    : null;

  const artifactAssignee = mapUserNodeToMentions(
    cachedArtifactAssignee?.assignee
  );

  // Meeting group
  const meetingParticipantsGroupData = meetingGroupId
    ? cache.readQuery<
        GetCachedMeetingGroupSuggestionsQueryQuery,
        GetCachedMeetingGroupSuggestionsQueryQueryVariables
      >({
        query: getMeetingGroupCachedSuggestionsQuery,
        variables: { meetingGroupId, hasMeeting: false },
      })
    : null;
  const meetingGroupParticipants = (
    meetingParticipantsGroupData?.meetingGroup?.participants?.edges || []
  ).map(mapUserParticipantsEdgesToMentions);

  // Meeting
  const meetingParticipantsData = cache.readQuery<
    GetCachedMeetingSuggestionsQueryQuery,
    GetCachedMeetingSuggestionsQueryQueryVariables
  >({
    query: getMeetingCachedSuggestionsQuery,
    variables: { meetingId },
  });
  const meetingParticipants = (
    meetingParticipantsData?.meeting?.participants?.edges || []
  ).map(mapUserParticipantsEdgesToMentions);

  // get users from cache
  const cachedArtifactsVar = Array.from(artifactsVar());
  const cachedUsersVar = Array.from(usersVar());
  const cachedUsers = cachedUsersVar.map((id: any) =>
    mapUserNodeToMentions(
      client.readFragment({
        id,
        fragment: UserMentionFragment,
      })
    )
  );
  const cachedArtifacts = cachedArtifactsVar
    .map((id: any) =>
      mapArtifactNodeToMention(
        client.readFragment({
          id,
          fragment: ArtifactMentionFragment,
        })
      )
    )
    .filter(
      (artifact) =>
        artifact &&
        !(
          artifact.__typename === "GoalArtifactNode" &&
          artifact.goalVisibility === "private"
        )
    );
  const cacheArtifactsGroupedByType = groupBy(cachedArtifacts, "artifactType");

  // filter mentions
  const userMentions = filterAndLimitMentions(
    compact([
      ...cachedRelatedUsers,
      artifactAssignee,
      ...meetingParticipants,
      ...meetingGroupParticipants,
      ...cachedUsers,
    ]),
    query
  );
  const actionItemsMentions = filterAndLimitMentions(
    cacheArtifactsGroupedByType[ArtifactType.ActionItem],
    query
  );
  const goalsMentions = filterAndLimitMentions(
    cacheArtifactsGroupedByType[ArtifactType.Goal],
    query
  );
  const dataActionItems = !onlyUsers ? actionItemsMentions : [];
  const dataGoals = !onlyUsers ? goalsMentions : [];
  return compact([...userMentions, ...dataActionItems, ...dataGoals]);
};

export const allSuggestion = ({
  artifactId,
  meetingId,
  meetingGroupId,
  hideSearchModal = false,
  onlyUsers = false,
}: {
  artifactId?: number;
  meetingId?: number;
  meetingGroupId?: number;
  hideSearchModal?: boolean;
  onlyUsers?: boolean;
}) => {
  return {
    allowSpaces: false,
    items: ({ query }: { query: string }) => {
      const currentUser = currentUserVar();
      const mentions = getCachedMentions({
        artifactId,
        meetingId,
        meetingGroupId,
        currentUser,
        onlyUsers,
        query,
      });

      // if no query we show some random users.
      if (mentions.length > 0) {
        return (lastPromise = Promise.resolve(mentions));
      }

      const currentPromise = debouncedSearchApi({ query, onlyUsers }).then(
        (response) => {
          if (lastPromise === currentPromise) {
            const users = response.data.users?.edges.map(
              mapUserEdgesToMentions
            );
            const artifacts = response.data.artifacts.edges.map(
              mapArtifactEdgesToMentions
            );
            return compact([].concat(users, artifacts));
          }
          return null;
        }
      );
      lastPromise = currentPromise;
      return lastPromise;
    },

    render: () => {
      let component: any;
      let popup: any;

      return {
        onStart: (props: any) => {
          component = new ReactRenderer(MentionList, {
            props: { ...props, hideSearchModal },
            editor: props.editor,
          });
          popup = tippy("body", {
            getReferenceClientRect: props.clientRect,
            appendTo: () => document.body,
            content: component.element,
            showOnCreate: true,
            interactive: true,
            trigger: "manual",
            placement: "bottom-start",
            theme: "headless",
            zIndex: tooltipZIndex, // tippyZIndex in tailwind.config.js
          });
        },

        onUpdate(props: any) {
          component?.updateProps(props);
          if (popup && popup[0]) {
            popup[0].setProps({
              getReferenceClientRect: props.clientRect,
            });
          }
        },

        onKeyDown(props: any) {
          if (popup && popup[0] && props.event.key === "Escape") {
            popup[0].hide();
            return true;
          }
          return component?.ref?.onKeyDown(props);
        },

        onExit() {
          if (popup && popup[0]) {
            if (!popup[0].state.isDestroyed) {
              popup[0].destroy();
            }
          }
          component?.destroy();
        },
      };
    },
  };
};
