import { useCallback } from "react";

import { editorVersionVar } from "@cache/cache";
import usePusher from "@components/use-pusher/use-pusher";

export default function PusherSystemUpdate({
  channelName,
}: {
  channelName: string;
}) {
  const handleEditorVersionUpdate = useCallback(
    ({ old_version, new_version }: { old_version: any; new_version: any }) => {
      editorVersionVar({ oldVersion: old_version, newVersion: new_version });
    },
    []
  );
  usePusher(channelName, "editor_version_update", handleEditorVersionUpdate);

  return null;
}
