import { DashboardInsightFragment } from "types/graphql-schema";
import { BasicUser } from "types/topicflow";

import { InsightButton, InsightLayout } from "../insight";

const MissingManagerInsight = ({
  insight,
  selectedUser,
  onDismissInsight,
}: {
  insight: DashboardInsightFragment;
  selectedUser: BasicUser;
  onDismissInsight: () => void;
}) => {
  return (
    <InsightLayout
      onDismissInsight={onDismissInsight}
      isRequired={!!insight.complianceProgram?.id}
    >
      <div className="flex-1">
        <div>{selectedUser.name} does not have a manager.</div>
      </div>
      <div>
        <InsightButton url="/account">Set a manager</InsightButton>
      </div>
    </InsightLayout>
  );
};

export default MissingManagerInsight;
