import { useMutation } from "@apollo/client";
import { range } from "lodash";
import { useCallback, useMemo, useState } from "react";
import {
  AssessmentQuestionType,
  AssessmentType,
  MultiChoiceAssessmentQuestionNode,
  RangeAssessmentQuestionNode,
  SaveAssessmentQuestionMutation,
  SaveAssessmentQuestionMutationVariables,
  TextAssessmentQuestionNode,
} from "types/graphql-schema";

import useLabel from "@apps/use-label/use-label";
import { currentOrganizationVar } from "@cache/cache";
import Button, { buttonTheme } from "@components/button/button";
import Loading from "@components/loading/loading";
import Modal from "@components/modal/modal";
import ModalTitle from "@components/modal/modal-title";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { assertNonNull } from "@helpers/helpers";

import createOrUpdateQuestionMutation from "../graphql/create-or-update-question-mutation";
import {
  emptyAssessmentQuestion,
  getAssessmentQuestionScale,
  getValidAssessmentQuestionMessage,
} from "../helpers";
import AssessmentQuestionForm, {
  AssessmentQuestion,
} from "./assessment-question-form";

const NewAssessmentQuestionModal = ({
  assessmentType,
  onClose,
  onNewQuestionCreated,
}: {
  assessmentType: AssessmentType;
  onClose: () => void;
  onNewQuestionCreated: (
    question:
      | Pick<
          RangeAssessmentQuestionNode,
          | "__typename"
          | "id"
          | "title"
          | "description"
          | "questionType"
          | "assessmentType"
          | "responses"
          | "responseVisibility"
          | "startValue"
          | "endValue"
          | "labelDescriptions"
          | "labels"
        >
      | Pick<
          MultiChoiceAssessmentQuestionNode,
          | "__typename"
          | "id"
          | "title"
          | "description"
          | "questionType"
          | "assessmentType"
          | "responses"
          | "responseVisibility"
          | "options"
          | "optionDescriptions"
        >
      | Pick<
          TextAssessmentQuestionNode,
          | "__typename"
          | "id"
          | "title"
          | "description"
          | "questionType"
          | "assessmentType"
          | "responses"
          | "responseVisibility"
        >
  ) => void;
}) => {
  const label = useLabel();
  const currentOrganization = currentOrganizationVar();
  const [proposedQuestion, setProposedQuestion] = useState<AssessmentQuestion>(
    emptyAssessmentQuestion(AssessmentQuestionType.Range, assessmentType)
  );
  const [createOrUpdateQuestion, { loading: loadingSave }] = useMutation<
    SaveAssessmentQuestionMutation,
    SaveAssessmentQuestionMutationVariables
  >(createOrUpdateQuestionMutation);

  const scale = useMemo(
    () => getAssessmentQuestionScale(proposedQuestion),
    [proposedQuestion]
  );

  const handleSaveForm = useCallback(() => {
    const labels = range(0, scale.scaleDiff).map((x) => {
      return proposedQuestion.labels?.[x] || "";
    });
    const labelDescriptions = range(0, scale.scaleDiff).map((x) => {
      return proposedQuestion.labelDescriptions?.[x] || "";
    });
    const options = range(0, proposedQuestion.optionCount).map((x) => {
      return proposedQuestion.options?.[x] || "";
    });
    const optionDescriptions = range(0, proposedQuestion.optionCount).map(
      (x) => {
        return proposedQuestion.optionDescriptions?.[x] || "";
      }
    );
    createOrUpdateQuestion({
      variables: {
        title: proposedQuestion.title,
        description: proposedQuestion.description,
        startValue: proposedQuestion.startValue,
        endValue: proposedQuestion.endValue,
        isCommentMandatory: proposedQuestion.isCommentMandatory,
        responses: proposedQuestion.responses,
        responseVisibility: proposedQuestion.responseVisibility,
        labels,
        labelDescriptions,
        options,
        optionDescriptions,
        organizationId: currentOrganization?.id,
        assessmentType: proposedQuestion.assessmentType,
        questionType: proposedQuestion.questionType,
        categories: proposedQuestion.categories.map(({ title }) => title),
      },
      onCompleted: (response) => {
        onNewQuestionCreated(
          assertNonNull(response.createOrUpdateAssessmentQuestion?.question)
        );
      },
      onError: onNotificationErrorHandler(),
    });
  }, [
    createOrUpdateQuestion,
    currentOrganization,
    scale,
    proposedQuestion,
    onNewQuestionCreated,
  ]);

  const canUpdateMessage = getValidAssessmentQuestionMessage(proposedQuestion);
  return (
    <Modal fullWidth open onClose={onClose} alignedTop>
      <div className="p-6 flex flex-col gap-4">
        <ModalTitle onClose={onClose}>
          New {label("review")} question
        </ModalTitle>
        <AssessmentQuestionForm
          canUpdate
          proposedQuestion={proposedQuestion}
          isNew
          hideAssessmentType
          onChangeQuestion={setProposedQuestion}
        />
        <div className="flex items-center gap-2 justify-end">
          <Button
            disabled={loadingSave}
            theme={buttonTheme.text}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            disabled={loadingSave || !!canUpdateMessage}
            theme={buttonTheme.primary}
            onClick={handleSaveForm}
            tooltip={canUpdateMessage}
            aria-label="Confirm modal confirm button"
          >
            Confirm {loadingSave && <Loading mini size="5" className="ml-2" />}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default NewAssessmentQuestionModal;
