import { gql } from "@apollo/client";

export default gql`
  fragment FavouritesGroupFragment on FavouritesGroupNode {
    id
    users {
      edges {
        node {
          id
          name
          email
          avatar
        }
      }
    }
  }
`;
