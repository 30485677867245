import { gql } from "@apollo/client";

export default gql`
  mutation createOrUpdateTemplateCategoryMutation(
    $title: String!
    $organizationId: Int
    $topicTemplateCategoryId: Int
  ) {
    createOrUpdateTopicTemplateCategory(
      topicTemplateCategoryId: $topicTemplateCategoryId
      organizationId: $organizationId
      title: $title
    ) {
      topicTemplateCategory {
        id
        title
        ordinal
      }
    }
  }
`;
