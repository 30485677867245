import { UserCircleIcon } from "@heroicons/react/solid";
import { useState } from "react";
import { TbUserOff } from "react-icons/tb";
import { BasicUser } from "types/topicflow";

import Tooltip from "@components/tooltip/tooltip";
import { classNames } from "@helpers/css";

export type AvatarProps = {
  user?:
    | BasicUser
    | { name: string; email?: string | null; avatar?: null | string }
    | null;
  size?: string | number;
  className?: string;
  disabled?: boolean;
  showTooltip?: boolean;
  unassignedTooltipText?: string;
  tooltipPrefix?: string;
  tooltipSuffix?: string;
};

const Avatar: React.FC<AvatarProps> = ({
  user,
  size = "12",
  className = "",
  disabled = false,
  showTooltip = true,
  unassignedTooltipText = "Unassigned",
  tooltipPrefix = "",
  tooltipSuffix = "",
}) => {
  const [imageLoaded, setImageLoaded] = useState(true);

  const getImage = () => (
    <img
      className={classNames(
        `h-${size} w-${size} flex flex-none text-white bg-gray-400 rounded-full m-0`,
        className,
        disabled ? "grayscale contrast-50" : false
      )}
      alt={user?.name}
      aria-label={`Avatar ${user?.name}`}
      src={user?.avatar ?? undefined}
      referrerPolicy="no-referrer"
      onError={(e) => {
        e.currentTarget.onerror = null;
        setImageLoaded(false);
      }}
    />
  );
  // The referrerpolicy attr is from this suggestion to fix broken google avatar images:
  // https://stackoverflow.com/a/61042200
  const ExistingUserIcon = (
    <UserCircleIcon
      className={classNames(
        `h-${size} w-${size} block flex-none text-white bg-gray-300 rounded-full`,
        className
      )}
      aria-label={`Avatar ${user?.name || user?.email}`}
    />
  );
  const NoUserIcon = (
    <TbUserOff
      className={classNames(
        `h-${size} w-${size} block flex-none rounded-full text-gray-400`,
        className
      )}
      aria-label={`Avatar ${user?.name || user?.email || "unassigned"}`}
    />
  );
  return user ? (
    user.avatar && imageLoaded ? (
      <>
        {showTooltip ? (
          <Tooltip
            text={`${tooltipPrefix || ""}${user.name || user.email}${
              tooltipSuffix || ""
            }`}
          >
            {getImage()}
          </Tooltip>
        ) : (
          getImage()
        )}
      </>
    ) : (
      <>
        {showTooltip ? (
          <Tooltip
            text={`${tooltipPrefix || ""}${user?.name || user?.email}${
              tooltipSuffix || ""
            }`}
          >
            {ExistingUserIcon}
          </Tooltip>
        ) : (
          ExistingUserIcon
        )}
      </>
    )
  ) : (
    <>
      {showTooltip ? (
        <Tooltip text={unassignedTooltipText}>
          <span className="inline-block">{NoUserIcon}</span>
        </Tooltip>
      ) : (
        NoUserIcon
      )}
    </>
  );
};

export default Avatar;
