import { useQuery } from "@apollo/client";
import { Editor } from "@tiptap/react";
import { useEffect, useState } from "react";
import { usePopper } from "react-popper";

import { onNotificationErrorHandler } from "@components/use-error/use-error";
import getInlineCommentsQuery from "@components/wysiwyg/graphql/get-inline-comments-query";

import CommentContainer from "./comment-container";

const CommentPopover = ({
  editor,
  atMentionSuggestions,
  uploadVariable,
  topicId,
  artifactId,
  uuid,
  onClearUuid,
}: {
  editor: Editor;
  atMentionSuggestions?: any;
  uploadVariable: () => void;
  topicId?: number;
  artifactId?: number;
  uuid: string;
  onClearUuid: () => void;
}) => {
  const reference = editor.view.dom.querySelector(`[data-comment="${uuid}"]`);
  const [popperElement, setPopperElement] = useState<
    HTMLDivElement | undefined | null
  >(null);
  const { styles, attributes } = usePopper(reference, popperElement, {
    placement: "auto-start",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 8],
        },
      },
    ],
  });

  const { data } = useQuery(getInlineCommentsQuery, {
    fetchPolicy: "cache-only",
    variables: { uuid },
    skip: !uuid,
    onError: onNotificationErrorHandler(),
  });

  const removeFlaggedComments = () => {
    const { state, dispatch } = editor.view;
    state.doc.descendants((node, pos) => {
      if (!node.isInline) {
        return;
      }
      const mark = node.marks.find(
        (mark) =>
          mark.type === state.schema.marks.comment &&
          mark.attrs.comment === uuid
      );
      if (mark) {
        dispatch(state.tr.removeMark(pos, pos + node.nodeSize, mark));
      }
    });
  };

  const handleResolveComment = () => {
    removeFlaggedComments();
    onClearUuid();
  };

  const handleClickCancelForm = () => {
    if (!data || data?.comments.totalCount === 0) {
      removeFlaggedComments();
    }
    onClearUuid();
  };

  // Detect outside click and close container if the click was not in the popper element.
  useEffect(() => {
    const handleClick = (event: any) => {
      if (
        // click outside of popover
        event.target.dataset.comment !== uuid &&
        !event.target.closest(`[data-comment="${uuid}"]`) &&
        !event.target.isSameNode(popperElement) &&
        !popperElement?.contains(event.target)
      ) {
        handleClickCancelForm();
      }
    };
    document.addEventListener("click", handleClick, this);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [popperElement, data]);

  return (
    <div className="not-prose js-comment-menu">
      <div
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
        className="gap-px border bg-white flex rounded shadow text-gray-800 z-tooltip"
      >
        <CommentContainer
          atMentionSuggestions={atMentionSuggestions}
          uploadVariable={uploadVariable}
          uuid={uuid}
          topicId={topicId}
          artifactId={artifactId}
          onResolve={handleResolveComment}
          onCancel={handleClickCancelForm}
        />
      </div>
    </div>
  );
};
export default CommentPopover;
