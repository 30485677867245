import { gql } from "@apollo/client";

const ArtifactAssigneeFragment = gql`
  fragment ArtifactAssigneeFragment on ArtifactInterface {
    id
    artifactType
    ... on ActionItemArtifactNode {
      assignee {
        id
        name
        avatar
      }
    }
  }
`;

export default ArtifactAssigneeFragment;
