import { gql } from "@apollo/client";

export default gql`
  mutation saveOrgWideCareerTrack(
    $careerTrackId: Int
    $organizationId: Int
    $teamId: Int
    $title: String
    $roles: [CareerTrackRoleInput]
  ) {
    createOrUpdateCareerTrack(
      careerTrackId: $careerTrackId
      organizationId: $organizationId
      teamId: $teamId
      title: $title
      roles: $roles
    ) {
      careerTrack {
        id
        title
      }
    }
  }
`;
