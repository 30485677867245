import { compact } from "lodash";
import {
  MeetingViewMeetingNodeNewPageFragmentFragment,
  UserNode,
} from "types/graphql-schema";

import useUiPreferenceCache, {
  UiPreferenceCache,
} from "@apps/use-ui-preference-cache/use-ui-preference-cache";
import { currentUserVar } from "@cache/cache";

const useMeetingSidebarSection = ({
  meeting,
  collapsibleFilterName,
}: {
  meeting: MeetingViewMeetingNodeNewPageFragmentFragment;
  collapsibleFilterName: "Recognitions" | "Goals" | "ActionItems" | "Feedback";
}) => {
  const currentUser = currentUserVar();
  const { uiPreferenceCache } = useUiPreferenceCache();
  const meetingGroup = meeting.meetingGroup;
  let filterAssigneeId = currentUser.id;
  let subject = null;

  const participants = compact(
    meeting.participants?.edges
      ? meeting.participants.edges.map((edge) => edge?.node?.user)
      : []
  ) as UserNode[];
  const participantIds = participants.map(({ id }) => id);

  if (meetingGroup?.isFormalOneonone) {
    const facilitatorId = meetingGroup.facilitator?.id || participantIds[0];
    subject = participants.find(
      (participant) => participant.id !== facilitatorId
    );
    if (subject) {
      filterAssigneeId = subject.id;
    }
  }

  const isFormalOneonone = !!meetingGroup?.isFormalOneonone && !!subject;
  const keyJustMe = (
    isFormalOneonone
      ? `oneonone${collapsibleFilterName}JustMe`
      : `meeting${collapsibleFilterName}JustMe`
  ) as keyof UiPreferenceCache;
  const keyJustMeeting = (
    isFormalOneonone
      ? `oneonone${collapsibleFilterName}JustMeeting`
      : `meeting${collapsibleFilterName}JustMeeting`
  ) as keyof UiPreferenceCache;
  const toggledAssignee = uiPreferenceCache[keyJustMe];
  const toggledJustMeeting = uiPreferenceCache[keyJustMeeting];

  return {
    subject,
    isFormalOneonone,
    toggledAssignee,
    toggledJustMeeting,
    filterAssigneeId,
    participantIds,
  };
};

export default useMeetingSidebarSection;
