import { ErrorBoundary } from "@sentry/react";
import { useContext, useMemo } from "react";
import {
  MeetingViewMeetingNodeNewPageFragmentFragment,
  TopicNodeNewPageFragmentFragment,
} from "types/graphql-schema";

import Error from "@components/error/error";
import Loading from "@components/loading/loading";
import { isEmptyValue } from "@components/wysiwyg/helpers";
import WYSIWYG from "@components/wysiwyg/wysiwyg";
import { tempCacheIdPrefix } from "@helpers/constants";
import { classNames } from "@helpers/css";
import { parseStringToJSON } from "@helpers/helpers";

import { MeetingWebsocketProviderContext } from "../context";
import { meetingPaddingClassName } from "../helpers";

const DiscussionNotes = ({
  topic,
  meeting,
  className,
}: {
  topic: TopicNodeNewPageFragmentFragment;
  meeting: MeetingViewMeetingNodeNewPageFragmentFragment;
  className?: string;
}) => {
  const isTemporary = String(topic.id).includes(tempCacheIdPrefix);

  const topicValue = parseStringToJSON(topic.discussionNotes);
  let overlayValue = null;
  if (topic.linkedTemplateTopic?.id) {
    if (isEmptyValue(topicValue) && topic.linkedTemplateTopic.discussionNotes) {
      overlayValue = parseStringToJSON(
        topic.linkedTemplateTopic.discussionNotes
      );
    }
  }

  const meetingGroupId = meeting.meetingGroup?.id;
  const organizationId = meeting.organization?.id;
  const uploadVariable = useMemo(() => ({ topicId: topic.id }), [topic.id]);

  const mentionsConfig = useMemo(
    () => ({
      meetingGroupId: meetingGroupId,
      meetingId: meeting.id,
    }),
    [meeting.id, meetingGroupId]
  );
  const extraContext = useMemo(
    () => ({
      topicId: topic.id,
      relatedTopicId: topic.id,
      meetingId: meeting.id,
      meetingDate: meeting.startDatetime || undefined,
      meetingGroupId: meetingGroupId || undefined,
      organizationId: organizationId,
    }),
    [
      meeting.id,
      topic.id,
      meetingGroupId,
      meeting.startDatetime,
      organizationId,
    ]
  );

  const { sharedNotes } = useContext(MeetingWebsocketProviderContext);

  return (
    <div aria-label="Meeting topic shared notes">
      <div>
        {isTemporary ? (
          <div className="pb-4">
            <Loading size="5" />
          </div>
        ) : (
          <ErrorBoundary
            fallback={
              <Error
                title="An unexpected error occurred."
                description={
                  "The editor could not be rendered. We have been notified. Please refresh the page."
                }
              />
            }
          >
            <div id={`editor-topic-${topic.id}`}>
              <WYSIWYG
                key={`topic-${topic.id}`}
                value={topicValue}
                enableComment={topic.canUpdate.permission}
                className={classNames(
                  "text-base",
                  // important to have padding so the +/drag hover buttons on each line is clickable and we can detect correctly when user's mouse leaves the editor.
                  // the buttons need to be inside the editor.
                  "pt-0.5 pb-6", // adding bottom padding here so the white space is clickable
                  meetingPaddingClassName,
                  topic.canUpdate.permission && "min-h-24",
                  className
                )}
                ydoc={sharedNotes.ydoc}
                providerWebsocket={sharedNotes.providerWebsocket}
                editable={topic.canUpdate.permission}
                placeholder="Add meeting notes, '/' for commands"
                showPlusButton={topic.canUpdate.permission}
                uploadVariable={uploadVariable}
                overlayValue={overlayValue}
                webSocketDocumentId={`topic-${topic.id}`}
                mentionsConfig={mentionsConfig}
                extraContext={extraContext}
              />
            </div>
          </ErrorBoundary>
        )}
      </div>
    </div>
  );
};

export default DiscussionNotes;
