import { useMutation, useQuery } from "@apollo/client";
import { FormEvent, MouseEvent, useState } from "react";
import {
  ArtifactType,
  GetRatingsQueryQuery,
  GetRatingsQueryQueryVariables,
} from "types/graphql-schema";

import RatingsEdit from "@apps/ratings/components/edit";
import { currentOrganizationVar, isAdminVar } from "@cache/cache";
import Button, { buttonTheme } from "@components/button/button";
import Loading from "@components/loading/loading";
import Modal from "@components/modal/modal";
import { Select } from "@components/select/select";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { classNames } from "@helpers/css";
import { assertEdgesNonNull } from "@helpers/helpers";

import updateArtifactMutation from "../graphql/create-or-update-artifact-mutation";
import getRatingsQuery from "../graphql/get-ratings-query";

const SelectRatingForm = ({
  node,
  updateAttributes,
  extension,
  deleteNode,
}: {
  node: any;
  updateAttributes: (attributes: any) => void;
  extension: any;
  deleteNode: () => void;
}) => {
  const currentOrganization = currentOrganizationVar();
  const isAdmin = isAdminVar();
  const [selectedOption, setSelectedOption] = useState<number | null>(null);
  const [openedDialog, setOpenedDialog] = useState(false);
  const { data, loading, refetch } = useQuery<
    GetRatingsQueryQuery,
    GetRatingsQueryQueryVariables
  >(getRatingsQuery, {
    onCompleted: (response) => {
      const id =
        parseInt(node.attrs.ratingId) ||
        response.ratings?.edges[0]?.node?.id ||
        null;
      setSelectedOption(id);
    },
    onError: onNotificationErrorHandler(),
  });
  const [createOrUpdateRating, { loading: saveLoading }] = useMutation(
    updateArtifactMutation
  );
  const ratings = data?.ratings ? assertEdgesNonNull(data.ratings) : [];
  const options = ratings.map(({ id, title }) => ({ value: id, label: title }));
  const handleSubmitForm = (e: FormEvent) => {
    e.preventDefault();
  };

  const handleSaveForm = (e?: MouseEvent<HTMLButtonElement>) => {
    e?.preventDefault();
    if (selectedOption) createRatingArtifact(selectedOption);
  };

  const createRatingArtifact = (createWithRatingId: number) => {
    createOrUpdateRating({
      onError: onNotificationErrorHandler(),
      variables: {
        artifactId: node.attrs.id,
        additionalFields: {
          ratingId: createWithRatingId,
        },
        artifactType: ArtifactType.Rating,
        meetingId: extension.options.meetingId,
        topicId: extension.options.relatedTopicId,
        organizationId: currentOrganization.id,
      },
      onCompleted: (response) => {
        const artifactId = response.createOrUpdateArtifact.artifact.id;
        updateAttributes({
          id: artifactId,
        });
      },
    });
  };

  const handleSaveRating = (rating: { id: number }) => {
    setOpenedDialog(false);
    refetch().then(() => {
      setSelectedOption(rating.id);
      createRatingArtifact(rating.id);
    });
  };

  return (
    <form
      className="flex flex-col gap-6 border p-4 rounded bg-gray-50"
      contentEditable={false}
      onSubmit={handleSubmitForm}
      aria-label="Artifact rating form"
    >
      {loading && <Loading mini size="5" />}
      {selectedOption && (
        <>
          <div>
            <Select
              disabled={saveLoading}
              options={options}
              value={selectedOption}
              onChange={(newOption) => setSelectedOption(newOption.value)}
            />
          </div>
          <div className="flex gap-2 items-center">
            <Button
              type="button"
              onClick={handleSaveForm}
              small
              text="Insert selected rating"
              theme={buttonTheme.primary}
              disabled={saveLoading}
            />
            <Button text="Cancel" small onClick={deleteNode} />
            {saveLoading && <Loading mini size="5" />}
          </div>
        </>
      )}

      {isAdmin ? (
        <div
          className={classNames(
            "text-gray-500 text-sm flex gap-2 items-center",
            selectedOption !== null && "pt-6 border-t"
          )}
        >
          {selectedOption && "or"}
          <Button
            text="Create new rating"
            small
            onClick={() => {
              setOpenedDialog(true);
            }}
          />
          {openedDialog && (
            <Modal onClose={() => setOpenedDialog(false)}>
              <div className="flex flex-col gap-6 p-6">
                <RatingsEdit
                  onCancel={() => setOpenedDialog(false)}
                  onSave={handleSaveRating}
                />
              </div>
            </Modal>
          )}
        </div>
      ) : (
        options.length === 0 && (
          <div className="text-gray-500 text-sm">
            There are no ratings available for your organization. Only admins
            can create ratings. Please ask your organization admins to create
            one.
          </div>
        )
      )}
    </form>
  );
};
export default SelectRatingForm;
