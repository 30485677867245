import { useQuery } from "@apollo/client";
import { useState } from "react";
import { ArtifactType } from "types/graphql-schema";

import ArtifactDropdownMenu from "@apps/artifact/artifact-dropdown-menu";
import ActionItemAssignee from "@apps/artifact/components/artifact-assignee";
import ArtifactDueDate from "@apps/artifact/components/artifact-due-date";
import GoalParticipants from "@apps/artifact/components/goal-participants";
import ArtifactRecognitionRecipients from "@apps/artifact/components/recognition-recipients";
import ObjectiveCheckinDialog from "@apps/checkin-dialog/checkin-dialog";
import GoalProgress from "@apps/goal-progress/goal-progress";
import Loading from "@components/loading/loading";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { artifactType } from "@helpers/constants";

import getWysiwygArtifactQuery from "./graphql/get-wysiwyg-artifact-query";

/**
 * We need a useQuery for 2 reasons:
 * - To fetch initially the artifact using the extension attr id
 * - Then to keep the artifact data up to date when its cache is updated in another view
 *
 * Then we use useMutation to create or update the artifact. We define a no-cache policy and ignore api response
 * to prevent the cache to be updated after the api call succeed, instead we update the cache optimistically before the api call.
 * We prevent async update of the artifact which would provide a broken UX while user types.
 *
 */
const ArtifactInfos = ({
  syncedArtifact,
  commentId,
  descriptionIsExpanded,
  onToggleDescription,
  loading,
  onDelete,
  onSavedComment,
  onRemoveComment,
}: {
  syncedArtifact: {
    id: number;
    artifactType: ArtifactType;
  };
  commentId: number;
  descriptionIsExpanded: boolean;
  onToggleDescription: () => void;
  loading: boolean;
  onDelete: () => void;
  onSavedComment: () => void;
  onRemoveComment: () => void;
}) => {
  const [isShowingCheckinDialog, setIsShowingCheckinDialog] = useState(false);
  const isActionItem = syncedArtifact.artifactType === artifactType.actionItem;
  const isGoal = syncedArtifact.artifactType === artifactType.goal;
  const { data } = useQuery(getWysiwygArtifactQuery, {
    fetchPolicy: "cache-only", // rely only on cached content to render this view
    variables: { artifactId: Number(syncedArtifact.id) },
    onError: onNotificationErrorHandler(),
  });
  const artifact = data?.artifact || syncedArtifact;

  return (
    <div className="mt-0.5 flex items-center gap-1">
      {loading && (
        <div className="flex">
          <Loading mini size="4" />
        </div>
      )}
      {isShowingCheckinDialog && (
        <ObjectiveCheckinDialog
          artifact={artifact}
          onClose={() => setIsShowingCheckinDialog(false)}
        />
      )}

      {artifact.id && (isActionItem || isGoal) && (
        <div className="hidden @md/wysiwyg-artifact:flex items-center text-xs">
          <ArtifactDueDate artifact={artifact} labelName="due date" />
        </div>
      )}
      {artifact.id && isGoal && (
        <GoalProgress artifact={artifact} withPopover={true} />
      )}

      {artifact.id && isActionItem && (
        <div
          contentEditable={false}
          className="text-gray-400 hover:text-gray-600 hidden @md/wysiwyg-artifact:flex"
        >
          <ActionItemAssignee artifact={artifact} showName={false} />
        </div>
      )}
      {artifact.id && artifact.artifactType === ArtifactType.Recognition && (
        <div
          contentEditable={false}
          className="text-gray-400 hover:text-gray-600 hidden @md/wysiwyg-artifact:flex"
        >
          <ArtifactRecognitionRecipients artifact={artifact} />
        </div>
      )}
      {artifact.id && isGoal && (
        <div
          contentEditable={false}
          className="text-gray-400 hover:text-gray-600 hidden @md/wysiwyg-artifact:flex"
        >
          <GoalParticipants artifact={artifact} />
        </div>
      )}

      <div className="flex">
        <ArtifactDropdownMenu
          isInWysiwyg
          artifact={artifact}
          size="5"
          className="hover:bg-black/5 p-0.5"
          onDelete={() => onDelete()}
          descriptionIsExpanded={descriptionIsExpanded}
          onToggleDescription={onToggleDescription}
          onSavedComment={onSavedComment}
          onRemoveComment={commentId ? onRemoveComment : undefined}
        />
      </div>
    </div>
  );
};

export default ArtifactInfos;
