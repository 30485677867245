import { XIcon } from "@heroicons/react/outline";
import { ReactNode, useState } from "react";
import { useLocation } from "react-router-dom";
import { MeetingViewMeetingNodeNewPageFragmentFragment } from "types/graphql-schema";

import CalendarConnectNew from "@apps/account/components/calendar-connect-new";
import getSidebarDataQuery from "@apps/main/graphql/get-sidebar-data-query";
import MeetingDialog, {
  meetingDialogAction,
} from "@apps/meeting-dialog/meeting-dialog";
import Button, { buttonTheme } from "@components/button/button";
import Modal from "@components/modal/modal";
import { getUrl } from "@helpers/helpers";

import getMeetingQuery from "../graphql/get-meeting-query";

const PublishMeetingButton = ({
  meeting,
  children,
}: {
  meeting: MeetingViewMeetingNodeNewPageFragmentFragment;
  children: ReactNode | string;
}) => {
  const [showConnectCalendarModal, setShowConnectCalendarModal] =
    useState(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const openEditModal = queryParams.get("openEditModal") === "1";
  const [showCreateMeetingModal, setShowCreateMeetingModal] =
    useState(openEditModal);

  const handleClickPublish = () => {
    setShowCreateMeetingModal(true);
  };

  const handleHideMeetingDialog = () => {
    setShowCreateMeetingModal(false);
  };

  return (
    <>
      {showCreateMeetingModal && (
        <MeetingDialog
          meetingId={meeting.id}
          meetingGroupId={meeting.meetingGroup?.id}
          onClose={handleHideMeetingDialog}
          defaultAction={meetingDialogAction.publish}
          onSaved={handleHideMeetingDialog}
          refetchQueries={[getSidebarDataQuery, getMeetingQuery]}
        />
      )}
      <Modal open={showConnectCalendarModal}>
        <div
          className="p-6 flex justify-between items-start gap-8"
          aria-label="Connect calendar modal"
        >
          <div className="flex-1">
            <CalendarConnectNew
              next={getUrl({
                meetingGroupId: meeting.meetingGroup?.id,
                meetingId: meeting.id,
              })}
            />
          </div>
          <button
            onClick={() => setShowConnectCalendarModal(false)}
            className="p-1 rounded hover:bg-gray-100 text-gray-700"
            aria-label="Close modal"
          >
            <XIcon className="w-7 h-7" />
          </button>
        </div>
      </Modal>
      <Button
        theme={buttonTheme.primary}
        onClick={handleClickPublish}
        className="gap-2 items-center truncate overflow-hidden"
        small
      >
        {children}
      </Button>
    </>
  );
};

export default PublishMeetingButton;
