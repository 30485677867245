import { useQuery } from "@apollo/client";
import { compact, uniqBy } from "lodash";
import moment from "moment";
import { useState } from "react";
import { rrulestr } from "rrule";
import {
  GetArtifactExplorerMeetingGroupQueryQuery,
  GetArtifactExplorerMeetingGroupQueryQueryVariables,
} from "types/graphql-schema";

import { currentOrganizationVar } from "@cache/cache";
import ComboboxGeneric, {
  ComboboxGenericOption,
} from "@components/combobox/generic-combobox";
import useDebounce from "@components/use-debounce/use-debounce";
import { useNotificationError } from "@components/use-error/use-error";
import { graphqlNone } from "@helpers/constants";
import { assertEdgesNonNull, assertNonNull } from "@helpers/helpers";

import getArtifactExplorerMeetingGroupQuery from "../graphql/get-artifact-explorer-meeting-group-query";
import ExplorerComboboxButton from "./explorer-combobox-button";

const ExplorerMeetingGroupCombobox = ({
  meetingGroupId,
  prefix = "",
  ariaLabel = "Explorer meeting group combobox",
  onSelectMeetingGroupId,
  unassignedText = "None",
  className = "",
}: {
  meetingGroupId?: number | null;
  prefix: string;
  ariaLabel?: string;
  onSelectMeetingGroupId: (meetingGroupId: number | null) => void;
  unassignedText?: string;
  className?: string;
}) => {
  const organization = currentOrganizationVar();
  const unassignedOption = {
    label: unassignedText,
    value: graphqlNone,
  };
  // Hooks
  const { onError } = useNotificationError();
  const [query, setQuery] = useState("");
  const debouncedQuery = useDebounce(query, 500);
  const fetchMeetingGroup = !!(meetingGroupId && meetingGroupId > 0);
  const { data, loading } = useQuery<
    GetArtifactExplorerMeetingGroupQueryQuery,
    GetArtifactExplorerMeetingGroupQueryQueryVariables
  >(getArtifactExplorerMeetingGroupQuery, {
    variables: {
      search: query,
      organizationId: organization.id,
      meetingGroupId: fetchMeetingGroup ? meetingGroupId : -1, // random int to prevent query to fail
      fetchMeetingGroup,
    },
    onError,
  });

  // Computed data
  const meetingGroups = data?.meetingGroups
    ? assertEdgesNonNull(data.meetingGroups)
    : [];
  const matchingMeetingGroup = data?.meetingGroup
    ? assertNonNull(data.meetingGroup)
    : null;
  const allMeetingGroups = uniqBy(
    [...meetingGroups, matchingMeetingGroup],
    "id"
  );
  const options = allMeetingGroups.map((meetingGroup) => {
    const lastMeeting = meetingGroup?.meetings?.edges[0]?.node;
    if (lastMeeting) {
      return {
        ...meetingGroup,
        label: lastMeeting.title || "Meeting",
        value: meetingGroup.id,
        description: meetingGroup.recurrenceRule
          ? rrulestr(meetingGroup.recurrenceRule).toText()
          : moment(lastMeeting.startDatetime).format("LLLL"),
      };
    }
    return null;
  });
  const validOptions = compact([unassignedOption, ...options]);
  const selectedOption =
    validOptions.find(({ value }) => value === meetingGroupId) || null;
  const comboboxLoading = loading || query !== debouncedQuery;

  const handleChangeValue = (option: ComboboxGenericOption<number | null>) => {
    onSelectMeetingGroupId(option.value);
  };

  const handleClearValue = () => {
    onSelectMeetingGroupId(null);
  };

  return (
    <div className="flex-1 min-w-0">
      <ComboboxGeneric<number | null>
        aria-label={ariaLabel}
        loading={comboboxLoading}
        width="full"
        value={selectedOption}
        options={validOptions}
        clearable={!!selectedOption}
        onChangeValue={handleChangeValue}
        query={query}
        setQuery={setQuery}
      >
        {({ value, onClickButton, clearable, setReferenceElement }) => (
          <ExplorerComboboxButton
            prefix={prefix}
            ariaLabel="Explorer meeting group combobox"
            setReferenceElement={setReferenceElement}
            onClickButton={onClickButton}
            label={value?.label || "All"}
            clearable={clearable}
            onClear={handleClearValue}
            className={className}
          />
        )}
      </ComboboxGeneric>
    </div>
  );
};

export default ExplorerMeetingGroupCombobox;
