import { forwardRef } from "react";
import TextareaAutosize, {
  TextareaAutosizeProps,
} from "react-textarea-autosize";

interface Props extends TextareaAutosizeProps {
  label?: string;
}

const CustomTextareaAutosize = forwardRef<HTMLTextAreaElement, Props>(
  ({ minRows = 3, className = "", label, ...props }, ref) => {
    const input = (
      <TextareaAutosize
        ref={ref}
        className={className}
        data-lpignore="true"
        autoComplete="off"
        minRows={minRows}
        onKeyPress={(e) => {
          if (e.which === 13 || e.keyCode === 13 || e.key === "Enter") {
            e.stopPropagation();
          }
        }}
        {...props}
      />
    );
    if (!label) {
      return input;
    }
    return (
      <div>
        <label
          htmlFor={label}
          className="block text-sm font-medium text-gray-700"
        >
          {label}
        </label>
        <div className="mt-1">{input}</div>
      </div>
    );
  }
);

export default CustomTextareaAutosize;
