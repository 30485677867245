import { TrashIcon } from "@heroicons/react/outline";
import { CompetencyCriteriaInput } from "types/graphql-schema";

import Button, { buttonTheme } from "@components/button/button";
import Layout from "@components/layout/layout";
import TextareaWysiwyg from "@components/wysiwyg/textarea-wysiwyg";
import { classNames } from "@helpers/css";

const CriteriaEditor = ({
  disabled,
  editable,
  criteria,
  onAddCriteria,
  onCriteriaChange,
  onDeleteCriteria,
}: {
  onAddCriteria?: () => void;
  disabled?: boolean;
  editable?: boolean;
  criteria: (Pick<CompetencyCriteriaInput, "level" | "content"> & {
    uuid?: string;
  })[];
  onDeleteCriteria?: (uuid: string) => void;
  onCriteriaChange?: (uuid: string, content: string) => void;
}) => {
  const textAreaEnabled = !disabled && editable && !!onCriteriaChange;
  return (
    <div>
      {criteria.map((criteria) => {
        return (
          <div key={criteria.uuid} className="flex items-start gap-4">
            <div
              className={classNames(
                "bg-gray-200 rounded-full h-2 w-2",
                textAreaEnabled && "mt-6",
                !textAreaEnabled && "mt-2.5"
              )}
            ></div>
            <TextareaWysiwyg
              editable={textAreaEnabled}
              className="mt-1 w-full bg-white"
              value={criteria.content}
              deps={[criteria.level]}
              onChangeValue={(content) => {
                if (!criteria.uuid || !onCriteriaChange) {
                  return;
                }
                onCriteriaChange(criteria.uuid, content);
              }}
            />
            {editable && onDeleteCriteria && (
              <Button
                theme={buttonTheme.text}
                icon
                className={classNames(
                  "text-inherit mt-3",
                  Layout.headerIconButtonClassName
                )}
                onClick={() => {
                  if (!criteria.uuid) {
                    return;
                  }
                  onDeleteCriteria(criteria.uuid);
                }}
                disabled={disabled}
              >
                <TrashIcon className="h-5 w-5" />
              </Button>
            )}
          </div>
        );
      })}
      {editable && onAddCriteria && (
        <Button
          disabled={disabled}
          className="mt-2 ml-6"
          text="Add Criteria"
          onClick={onAddCriteria}
        />
      )}
    </div>
  );
};

export default CriteriaEditor;
