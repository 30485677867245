import { CareerTrackRoleType, InputMaybe } from "types/graphql-schema";

import Button from "@components/button/button";
import Input from "@components/input/input";

const RoleHeading = ({
  level,
  disabled,
  role,
  onRoleChange,
  onNewRole,
}: {
  level: number;
  disabled: boolean;
  role?: {
    title?: InputMaybe<string>;
    roleType?: InputMaybe<CareerTrackRoleType>;
  };
  onRoleChange: (role: {
    title?: InputMaybe<string>;
    roleType?: InputMaybe<CareerTrackRoleType>;
  }) => void;
  onNewRole: () => void;
}) => {
  return (
    <>
      <div className="text-xs text-gray-400">Level {level}</div>
      {role ? (
        <>
          <Input
            disabled={disabled}
            className="mt-2"
            value={role.title ?? ""}
            onChange={(evt) => {
              onRoleChange({
                ...role,
                title: evt.target.value,
              });
            }}
          />
          <div className="text-xs mt-1">
            <label className="flex items-center gap-1.5 cursor-pointer">
              <input
                type="checkbox"
                checked={role?.roleType === CareerTrackRoleType.Management}
                onChange={(evt) => {
                  onRoleChange({
                    ...role,
                    roleType: evt.target.checked
                      ? CareerTrackRoleType.Management
                      : CareerTrackRoleType.Ic,
                  });
                }}
              />
              Management role
            </label>
          </div>
        </>
      ) : (
        <Button
          disabled={disabled}
          small
          className="mt-2"
          text="Add Role"
          onClick={onNewRole}
        />
      )}
    </>
  );
};

export default RoleHeading;
