import { ReactNode, useCallback, useMemo, useState } from "react";

import Input from "@components/input/input";
import Loading from "@components/loading/loading";

import Table, {
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableContainer,
  TableHeadCell,
  TableHeadRow,
  TableProps,
  TableSortDir,
} from "./table";

export type ManagedTableColumn<T> = {
  header: string;
  headerRender?: () => ReactNode;
  field?: keyof T;
  render?: (rowData: T) => ReactNode;
  value?: (rowData: T) => string | number;
  sortKey?: string;
  onClick?: () => void;
  cellClassName?: string;
};

type ManagedTableProps<T> = TableProps & {
  data: T[];
  columns: ManagedTableColumn<T>[];
  sortKey?: string | null;
  sortDir?: TableSortDir | null;
  sortingEnabled?: boolean;
  rowClassName?: (rowData: T) => string;
  searchEnabled?: boolean;
  aboveTable?: (searchInput?: ReactNode) => JSX.Element;
  emptyPlaceholder?: ReactNode;
  isLoading?: boolean;
};

const ManagedTable = <T,>({
  columns,
  sortKey: initialSortKey,
  sortDir: initialSortDir,
  data,
  rowClassName,
  sortingEnabled,
  searchEnabled,
  aboveTable,
  isLoading,
  emptyPlaceholder,
  ...tableProps
}: ManagedTableProps<T>) => {
  const [sortKey, setSortKey] = useState<string | null | undefined>(
    sortingEnabled ? initialSortKey : null
  );
  const [sortDir, setSortDir] = useState<TableSortDir>(initialSortDir ?? "asc");
  const [searchStr, setSearchStr] = useState<string>("");

  const handleColumnSort = useCallback(
    (col: ManagedTableColumn<T>) => {
      if (col.onClick) {
        col.onClick();
      }
      if (!sortingEnabled || !col.sortKey) {
        return;
      }
      if (col.sortKey !== sortKey) {
        setSortKey(col.sortKey);
      } else {
        setSortDir(sortDir === "asc" ? "desc" : "asc");
      }
    },
    [sortDir, sortKey, sortingEnabled]
  );

  const sortedData = useMemo(() => {
    if (sortKey) {
      const columnData = columns.find((col) => col.sortKey === sortKey);
      const sorted = data.sort((a, b) => {
        if (columnData?.value) {
          return columnData
            .value(a)
            .toString()
            .localeCompare(columnData.value(b).toString());
        }
        const aValue = a[sortKey as keyof T] as string | number;
        const bValue = b[sortKey as keyof T] as string | number;
        return aValue.toString().localeCompare(bValue.toString());
      });
      return sortDir === "desc" ? sorted.reverse() : sorted;
    } else {
      return data;
    }
  }, [columns, data, sortDir, sortKey]);

  const filteredData = useMemo(() => {
    if (searchStr) {
      return sortedData.filter((row) => {
        return columns.some((col) => {
          const value = col.value
            ? col.value(row)
            : col.field
            ? row[col.field]
            : "";
          return `${value}`.toLowerCase().includes(searchStr.toLowerCase());
        });
      });
    } else {
      return sortedData;
    }
  }, [columns, searchStr, sortedData]);

  const searchInput = (
    <Input
      aria-label="Table search input"
      value={searchStr}
      onChange={(e) => setSearchStr(e.target.value)}
      placeholder="Search..."
      className="w-64"
    />
  );
  return (
    <>
      {searchEnabled && aboveTable ? (
        <>{aboveTable(searchInput)}</>
      ) : searchEnabled ? (
        <div className="flex items-center gap-4 mb-2">{searchInput}</div>
      ) : aboveTable ? (
        aboveTable()
      ) : null}

      {isLoading ? (
        <Loading className="p-6 w-full mx-auto">Loading</Loading>
      ) : (
        <TableContainer {...tableProps}>
          <Table>
            <TableHeadRow>
              {columns.map((col) => (
                <TableHeadCell
                  key={col.header}
                  sorted={col.sortKey === sortKey ? sortDir : undefined}
                  onClick={() => handleColumnSort(col)}
                >
                  {col.headerRender ? col.headerRender() : col.header}
                </TableHeadCell>
              ))}
            </TableHeadRow>
            <TableBody>
              {!!emptyPlaceholder && filteredData.length === 0 && (
                <TableBodyRow>
                  <TableBodyCell colSpan={columns.length}>
                    {emptyPlaceholder}
                  </TableBodyCell>
                </TableBodyRow>
              )}
              {filteredData.map((row, index) => (
                <TableBodyRow
                  key={index}
                  className={rowClassName && rowClassName(row)}
                >
                  {columns.map((col) => (
                    <TableBodyCell
                      key={col.header}
                      className={col.cellClassName}
                    >
                      {col.render
                        ? col.render(row)
                        : col.field
                        ? `${row[col.field]}`
                        : ""}
                    </TableBodyCell>
                  ))}
                </TableBodyRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default ManagedTable;
