import { PropsWithChildren } from "react";

import { classNames } from "@helpers/css";

type SettingsLayoutType = {
  Container: React.FC<PropsWithChildren>;
  Row: React.FC<PropsWithChildren>;
  Left: React.FC<PropsWithChildren<{ label?: string }>>;
  Right: React.FC<PropsWithChildren<{ className?: string }>>;
};

const SettingsLayout: SettingsLayoutType = {
  Container: ({ children }) => (
    <div className="flex flex-col divide-y">{children}</div>
  ),
  Row: ({ children }) => (
    <div className="grid sm:grid-cols-7 py-8 gap-8">{children}</div>
  ),
  Left: ({ children, label = "" }) => (
    <div className="col-span-2">
      <div className="text-gray-800 text-base font-semibold">{children}</div>
      {label && (
        <div className="mt-1 text-gray-500 text-xs tracking-tight">{label}</div>
      )}
    </div>
  ),
  Right: ({ children, className }) => (
    <div className={classNames("col-span-5 text-gray-800", className)}>
      {children}
    </div>
  ),
};

export default SettingsLayout;
