import { gql } from "@apollo/client";

import { ExplorerBookmarkFragment } from "@apps/main/graphql/get-logged-in-user-query";

export default gql`
  ${ExplorerBookmarkFragment}
  mutation saveExplorerBookmarkMutation(
    $explorerBookmarkId: Int
    $filters: String
    $organizationId: Int
    $private: Boolean
    $title: String
  ) {
    createOrUpdateExplorerBookmark(
      explorerBookmarkId: $explorerBookmarkId
      filters: $filters
      organizationId: $organizationId
      private: $private
      title: $title
    ) {
      user {
        id
        explorerBookmarks {
          edges {
            node {
              id
            }
          }
        }
      }
      explorerBookmark {
        ...ExplorerBookmarkFragment
      }
    }
  }
`;
