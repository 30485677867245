import { gql } from "@apollo/client";

export const MeetingGroupParticipantsFragment = gql`
  fragment MeetingGroupParticipantsFragment on MeetingGroupNode {
    facilitator {
      id
      name
      firstName
      lastName
      avatar
    }
    participants(first: 5, search: $participantSearch) {
      edges {
        node {
          id
          user {
            id
            name
            firstName
            lastName
            avatar
          }
        }
      }
    }
  }
`;
