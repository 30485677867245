import { gql } from "@apollo/client";

export default gql`
  mutation createOrUpdateKpiMutation(
    $title: String
    $description: String
    $visibility: KPIVisibility
    $organizationId: Int
    $kpiId: Int
    $integration: KPIIntegrationInput
    $defaultSummaryPeriod: KPISummaryPeriod
    $defaultSummaryMethod: KPISummaryMethod
    $targetType: KPITargetType
    $targetValue: Float
    $targetCondition: KPITargetCondition
    $groupIds: [Int]
    $teamIds: [Int]
  ) {
    createOrUpdateKpi(
      organizationId: $organizationId
      kpiId: $kpiId
      title: $title
      groupIds: $groupIds
      teamIds: $teamIds
      description: $description
      visibility: $visibility
      integration: $integration
      defaultSummaryMethod: $defaultSummaryMethod
      defaultSummaryPeriod: $defaultSummaryPeriod
      targetType: $targetType
      targetValue: $targetValue
      targetCondition: $targetCondition
    ) {
      kpi {
        id
        title
        groups {
          edges {
            node {
              id
              title
            }
          }
        }
        description
        visibility
        defaultSummaryMethod
        defaultSummaryPeriod
        targetType
        targetValue
        targetCondition
      }
    }
  }
`;
