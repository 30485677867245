import { useEffect, useState } from "react";

type DocumentExtended = Document & {
  msHidden?: boolean;
  webkitHidden?: boolean;
};

function getBrowserDocumentHiddenProp(document: DocumentExtended) {
  if (typeof document.hidden !== "undefined") {
    return "hidden";
  } else if (typeof document.msHidden !== "undefined") {
    return "msHidden";
  } else if (typeof document.webkitHidden !== "undefined") {
    return "webkitHidden";
  }
}

function getIsDocumentVisible(document: DocumentExtended) {
  const hiddenProp = getBrowserDocumentHiddenProp(document);
  if (!hiddenProp) {
    return true;
  }
  return !document[hiddenProp];
}

export const isPageHidden = () => !getIsDocumentVisible(document);

const usePageVisible = (delayWhenVisible = 0) => {
  const [isVisible, setIsVisible] = useState(getIsDocumentVisible(document));

  useEffect(() => {
    let st: undefined | number;
    const handleVisibilityChange = () => {
      if (st) clearTimeout(st);
      const newIsVisible = getIsDocumentVisible(document);
      if (!newIsVisible) {
        setIsVisible(false);
      } else {
        st = window.setTimeout(() => {
          setIsVisible(getIsDocumentVisible(document));
        }, delayWhenVisible);
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      if (st) clearTimeout(st);
    };
  }, []);

  return isVisible;
};

export default usePageVisible;
