import { XCircleIcon } from "@heroicons/react/solid";
import toLower from "lodash/toLower";
import { useState } from "react";

import { classNames } from "@helpers/css";

const GraphqlError = ({
  whatDidNotWork = "An error occurred",
  descriptionList = [
    "An unexpected error occurred. We have been notified. Please try again.",
  ],
  error,
  className = "",
  parser = [],
}: {
  whatDidNotWork?: string;
  descriptionList?: Array<string>;
  error?: any;
  className?: string;
  parser?: Array<{ match: string; message: any }>;
}) => {
  const [showMore, setShowMore] = useState(false);
  if (!error) {
    return null;
  }

  // Format error message
  let apiErrorMessage = null;
  if (error) {
    const errorStr = toLower(String(error));
    if (error.networkError && errorStr.includes("failed to fetch")) {
      apiErrorMessage =
        "Make sure you are connected to the internet and try again.";
    } else if (parser.length > 0) {
      const matchingParser = parser.find(({ match }) =>
        errorStr.includes(toLower(match))
      );
      if (matchingParser) {
        apiErrorMessage = matchingParser.message;
      }
    }
  }

  // Render errors
  return (
    <div
      className={classNames(
        "rounded-md bg-red-50 border border-red-600 border-opacity-20 p-2",
        className
      )}
      data-testid="error"
    >
      <div className="flex text-red-800 text-sm">
        <div className="flex-shrink-0">
          <XCircleIcon className="h-5 w-5 text-red-500" />
        </div>
        <div className="ml-3 flex-1">
          <h3 className="font-medium">{whatDidNotWork}</h3>
          {apiErrorMessage ? (
            <div className="mt-2 text-xs">{apiErrorMessage}</div>
          ) : descriptionList.length > 1 ? (
            <ul className="list-disc ml-4 mt-2 text-xs">
              {descriptionList.map((description) => (
                <li className="my-1" key={description}>
                  {description}
                </li>
              ))}
            </ul>
          ) : descriptionList.length === 1 ? (
            <div className="mt-2 text-xs">{descriptionList[0]}</div>
          ) : null}

          {error && error.graphQLErrors?.length > 0 && (
            <div className="mt-2 text-xs">
              <button
                type="button"
                onClick={() => setShowMore(!showMore)}
                className="text-xs"
              >
                &raquo; show more
              </button>
              {showMore && (
                <ul className="list-disc ml-4 mt-2 font-mono">
                  {error.graphQLErrors.map(
                    (graphQLError: { message: string }) => (
                      <li key={graphQLError.message}>{graphQLError.message}</li>
                    )
                  )}
                </ul>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default GraphqlError;
