import { XIcon } from "@heroicons/react/outline";
import moment from "moment";
import { forwardRef } from "react";
import DatePicker from "react-datepicker";
import { TbCalendarPlus } from "react-icons/tb";

import Button, { buttonTheme } from "@components/button/button";
import { classNames } from "@helpers/css";

import {
  MeetingDialogFormType,
  meetingDialogInputClassName,
} from "../meeting-dialog";
import MeetingRecurrence from "./recurrence";

const MeetingDates = ({
  isReadOnly,
  form,
  onChangeForm,
}: {
  isReadOnly: boolean;
  form: MeetingDialogFormType;
  onChangeForm: (form: MeetingDialogFormType) => void;
}) => {
  const handleChangeStartDatetime = (date?: Date | null) => {
    if (!date) {
      return;
    }
    const currentMinuteDifference = moment(form.endDatetime).diff(
      form.startDatetime,
      "minutes"
    );
    const endDatetime = moment(date).isSameOrAfter(form.endDatetime, "minutes")
      ? moment(date).add(currentMinuteDifference, "minute").format()
      : form.endDatetime;
    onChangeForm({
      ...form,
      startDatetime: moment(date).format(),
      endDatetime,
    });
  };

  const handleChangeEndDatetime = (date?: Date | null) => {
    if (!date) {
      return;
    }
    const currentMinuteDifference = moment(form.endDatetime).diff(
      form.startDatetime,
      "minutes"
    );
    const startDatetime = moment(date).isSameOrBefore(
      form.startDatetime,
      "minutes"
    )
      ? moment(date).subtract(currentMinuteDifference, "minutes").format()
      : form.startDatetime;
    onChangeForm({
      ...form,
      startDatetime,
      endDatetime: moment(date).format(),
    });
  };

  const handleChangeMeetingRecurrence = (meetingFrequency: any) => {
    onChangeForm({ ...form, recurrence: meetingFrequency });
  };

  const handleClickSetDate = () => {
    onChangeForm({
      ...form,
      startDatetime: moment()
        .hour(moment().hour())
        .add(1, "hour")
        .startOf("hour")
        .format(),
      endDatetime: moment()
        .hour(moment().hour())
        .add(1, "hour")
        .startOf("hour")
        .add(30, "minutes")
        .format(),
    });
  };

  const handleClickClearDate = () => {
    onChangeForm({
      ...form,
      startDatetime: undefined,
      endDatetime: undefined,
      recurrence: null,
    });
  };

  const CustomInput = forwardRef((datePickerProps: any, ref) => (
    <input type="text" ref={ref} {...datePickerProps} />
  ));

  return !form.startDatetime && !form.endDatetime ? (
    <div className="flex flex-col gap-1 -ml-2">
      <button
        type="button"
        className={classNames(
          meetingDialogInputClassName,
          "text-blue-link flex items-center gap-2"
        )}
        onClick={handleClickSetDate}
      >
        <TbCalendarPlus className="h-4 w-4" />
        Add date
      </button>
    </div>
  ) : (
    <div className="flex flex-col gap-1 -ml-2 w-full">
      <div className="flex items-center justify-between w-full">
        <div className="flex-1 flex items-center">
          <DatePicker
            selected={moment(form.startDatetime).toDate()}
            dateFormat="EEE, MMM d, yyyy"
            onChange={handleChangeStartDatetime}
            className={classNames(
              meetingDialogInputClassName,
              "px-2 w-36",
              isReadOnly && "hover:bg-white"
            )}
            customInput={
              <CustomInput
                data-lpignore="true"
                autoComplete="off"
                disabled={isReadOnly}
              />
            }
            ariaLabelledBy="Meeting dialog start date input"
            readOnly={isReadOnly}
          />
          <div className="ml-3 flex items-center">
            <DatePicker
              selected={moment(form.startDatetime).toDate()}
              onChange={handleChangeStartDatetime}
              timeIntervals={15}
              showTimeSelect
              showTimeSelectOnly
              dateFormat="h:mma"
              className={classNames(
                meetingDialogInputClassName,
                "text-center px-0 w-16",
                isReadOnly && "hover:bg-white"
              )}
              data-lpignore="true"
              autoComplete="off"
              ariaLabelledBy="Meeting dialog start time input"
              readOnly={isReadOnly}
            />
            <span className="text-sm mx-0.5">-</span>
            <DatePicker
              selected={moment(form.endDatetime).toDate()}
              onChange={handleChangeEndDatetime}
              timeIntervals={15}
              showTimeSelect
              showTimeSelectOnly
              dateFormat="h:mma"
              className={classNames(
                meetingDialogInputClassName,
                "text-center px-0 w-16",
                isReadOnly && "hover:bg-white"
              )}
              ariaLabelledBy="Meeting dialog end time input"
              data-lpignore="true"
              autoComplete="off"
              readOnly={isReadOnly}
            />
          </div>
          {!moment(form.startDatetime).isSame(form.endDatetime, "day") && (
            <div className="ml-3 flex items-center">
              <DatePicker
                selected={moment(form.endDatetime).toDate()}
                dateFormat="EEE, MMM d, yyyy"
                onChange={handleChangeEndDatetime}
                className={classNames(
                  meetingDialogInputClassName,
                  "px-2 w-36",
                  isReadOnly && "hover:bg-white"
                )}
                customInput={
                  <CustomInput
                    data-lpignore="true"
                    autoComplete="off"
                    disabled={isReadOnly}
                  />
                }
                ariaLabelledBy="Meeting dialog end date input"
              />
            </div>
          )}
        </div>
        {!isReadOnly && (
          <Button
            icon
            tooltip="Remove meeting date"
            theme={buttonTheme.iconGray}
            onClick={handleClickClearDate}
          >
            <XIcon className="h-5 w-5" />
          </Button>
        )}
      </div>
      <MeetingRecurrence
        isReadOnly={isReadOnly}
        buttonProps={{
          "aria-label": "Meeting dialog recurrence input",
        }}
        formStartDatetime={form.startDatetime}
        formMeetingFrequency={form.recurrence}
        onChangeMeetingFrequency={handleChangeMeetingRecurrence}
      />
    </div>
  );
};

export default MeetingDates;
