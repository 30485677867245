import { gql } from "@apollo/client";

export default gql`
  mutation updateGoalVisibilityMutation(
    $artifactId: Int!
    $additionalFields: AdditionalArtifactInput!
  ) {
    createOrUpdateArtifact(
      artifactId: $artifactId
      additionalFields: $additionalFields
    ) {
      artifact {
        ... on GoalArtifactNode {
          id
          goalVisibility
          canRead {
            permission
          }
        }
      }
    }
  }
`;
