import { useQuery } from "@apollo/client";
import { ChatAltIcon } from "@heroicons/react/solid";
import sortBy from "lodash/sortBy";
import moment from "moment";

import Avatar from "@components/avatar/avatar";
import GraphqlError from "@components/error/graphql-error";
import Loading from "@components/loading/loading";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import usePkParams from "@helpers/hooks/use-pk-params";

import Comment from "./components/comment";
import CommentForm from "./components/comment-form";
import TopicCreated from "./components/topic-created";
import getCommentsQuery from "./graphql/get-comments-query";

const Comments = ({
  topic,
  meetingId,
  meetingGroupId,
}: {
  topic: any;
  meetingId: any;
  meetingGroupId: any;
}) => {
  const commentId = usePkParams("commentId");
  const { loading, error, data } = useQuery(getCommentsQuery, {
    variables: { topicId: topic.id },
    onError: onNotificationErrorHandler(),
  });

  // Render
  if (error) {
    return (
      <GraphqlError
        error={error}
        whatDidNotWork="The comments could not be loaded."
      />
    );
  }
  if (!data && loading) {
    return <Loading />;
  }
  const comments = sortBy(
    data.comments.edges,
    ({ node }) => -moment(node.created).valueOf()
  );

  return (
    <div>
      <h2 className="text-xs mb-4 text-gray-700 flex items-center justify-between">
        <span className="uppercase">Comments</span>
        {loading && (
          <div className="text-xs font-normal text-gray-400 flex items-center">
            <span className="mr-2">Refreshing</span>
            <Loading mini size="4" />
          </div>
        )}
      </h2>

      {/* Comment Form */}
      <div className="mt-6 relative pb-6">
        {comments.length > 0 && (
          <span className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200" />
        )}
        <div className="flex space-x-3">
          <div className="flex-shrink-0">
            <div className="relative">
              <Avatar size="10" user={data.me} />
              <span className="absolute -bottom-0.5 -right-1 bg-gray-50 rounded-tl px-0.5 py-px">
                <ChatAltIcon className="h-5 w-5 text-gray-400" />
              </span>
            </div>
          </div>
          <div className="min-w-0 flex-1">
            <CommentForm
              key={topic.id} // prevent crashing when switching topic in sidebar
              topicId={topic.id}
              topic={topic}
              meetingGroupId={meetingGroupId}
              meetingId={meetingId}
            />
          </div>
        </div>
      </div>

      {/* Comment List */}
      <ul aria-label="Comments">
        {comments.map(({ node: comment }, index) => (
          <Comment
            key={comment.id}
            topic={topic}
            comment={comment}
            meetingGroupId={meetingGroupId}
            currentUserId={data.me.id}
            selected={commentId === comment.id.toString()}
          />
        ))}
        <TopicCreated topic={topic} />
      </ul>
    </div>
  );
};

export default Comments;
