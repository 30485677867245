import { gql } from "@apollo/client";

export default gql`
  query getAssessmentQuestions($organizationId: Int!, $archived: Boolean) {
    assessmentQuestions(organizationId: $organizationId, archived: $archived) {
      edges {
        node {
          ... on RangeAssessmentQuestionNode {
            id
            title
            description
            archived
            answersExist
            isCommentMandatory
            responses
            responseVisibility
            questionType
            created
            assessmentType
            canUpdate {
              permission
              reason
            }
            canDelete {
              permission
              reason
            }
            categories {
              edges {
                node {
                  id
                  title
                }
              }
            }
            creator {
              id
              name
            }
            startValue
            endValue
            labels
            labelDescriptions
          }
          ... on TextAssessmentQuestionNode {
            id
            title
            description
            archived
            answersExist
            isCommentMandatory
            responses
            responseVisibility
            questionType
            created
            assessmentType
            canUpdate {
              permission
              reason
            }
            canDelete {
              permission
              reason
            }
            categories {
              edges {
                node {
                  id
                  title
                }
              }
            }
            creator {
              id
              name
            }
          }
          ... on MultiChoiceAssessmentQuestionNode {
            id
            title
            description
            archived
            answersExist
            isCommentMandatory
            responses
            responseVisibility
            questionType
            created
            assessmentType
            canUpdate {
              permission
              reason
            }
            canDelete {
              permission
              reason
            }
            categories {
              edges {
                node {
                  id
                  title
                }
              }
            }
            creator {
              id
              name
            }
            options
            optionDescriptions
          }
        }
      }
    }
  }
`;
