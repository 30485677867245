import { gql } from "@apollo/client";

import TopicNodeFragment from "@apps/meeting-new/graphql/topic-node-fragment";

export default gql`
  ${TopicNodeFragment}
  mutation copyTopicToMeetingMutation($topicIds: [Int]!, $meetingId: Int!) {
    copyTopicsToMeeting(topicIds: $topicIds, meetingId: $meetingId) {
      copiedTopics {
        ...TopicNodeNewPageFragment
      }
      meeting {
        id
        topics {
          edges {
            node {
              id
            }
          }
        }
      }
    }
  }
`;
