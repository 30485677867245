import { useLocation } from "react-router-dom";
import {
  ArtifactActivityFragmentFragment,
  TopicNode,
} from "types/graphql-schema";
import { TFLocationState } from "types/topicflow";

import AppLink from "@components/link/link";
import { getUrl, toWithBackground } from "@helpers/helpers";

const ActivityArtifactReferencedFromTopic = ({
  activity,
}: {
  activity: ArtifactActivityFragmentFragment;
}) => {
  const location = useLocation<TFLocationState>();
  if (!activity?.changedFields?.[0]?.newValueObject) {
    return null;
  }
  const topic = activity.changedFields[0].newValueObject as TopicNode;
  if (!topic.meeting) return null;
  const meeting = topic.meeting;
  return (
    <AppLink
      to={toWithBackground({
        pathname: getUrl({
          meetingGroupId: meeting.meetingGroupId,
          topicId: topic.id,
          meetingId: meeting.id,
        }),
        location,
      })}
    >
      {topic.title}
    </AppLink>
  );
};

export default ActivityArtifactReferencedFromTopic;
