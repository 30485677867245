import { useQuery } from "@apollo/client";
import copy from "copy-to-clipboard";
import { useCallback } from "react";
import {
  GetKpiUpdateUrlQueryQuery,
  GetKpiUpdateUrlQueryQueryVariables,
} from "types/graphql-schema";

import { successNotificationVar } from "@cache/cache";
import Button, { buttonTheme } from "@components/button/button";
import Loading from "@components/loading/loading";
import { useNotificationError } from "@components/use-error/use-error";
import { assertNonNull } from "@helpers/helpers";

import getKpiUpdateUrlQuery from "../graphql/get-kpi-update-url-query";

const KPIUpdateUrlContentPopover = ({
  kpiId,
  open,
}: {
  kpiId: number;
  open: boolean;
}) => {
  const { onError } = useNotificationError();
  const { data, loading } = useQuery<
    GetKpiUpdateUrlQueryQuery,
    GetKpiUpdateUrlQueryQueryVariables
  >(getKpiUpdateUrlQuery, {
    variables: {
      kpiId,
    },
    skip: !open,
    onError,
  });

  const kpi = data?.kpi;

  const handleCopyUpdateUrl = useCallback(() => {
    copy(assertNonNull(kpi?.updateUrl));
    successNotificationVar({ title: "Update url copied.", timeout: 1000 });
  }, [kpi]);

  if (loading) {
    return (
      <div className="bg-white w-72 text-sm">
        <Loading>Loading webhook informations...</Loading>
      </div>
    );
  }
  if (!kpi) {
    return (
      <div className="bg-white w-72 text-sm">
        You do not have the permission to view the url to update this KPI.
      </div>
    );
  }

  return (
    <>
      <div className="max-w-96 mb-4">
        <div className="inline-flex items-center text-gray-400 text-xs border bg-gray-50 rounded">
          <code className="block px-2 py-0.5 text-red-700 select-all whitespace-pre-wrap">
            POST {kpi.updateUrl}
            <br />
            <br />
            Content-type: application/json
            <br />
            <br />
            {`{"measurement": ${kpi.currentMeasurement?.measurement || 0}}`}
            <br />
          </code>
        </div>
        <div className="my-2 text-sm">
          You can also specify an <code>increment</code> key instead of{" "}
          <code>measurement</code>, to create a measurement that is a delta from
          the last measurement. And you can specify an optional{" "}
          <code>timestamp</code> key, to set the timestamp of the measurement to
          something other than right now.
          <br />
          <br />
          <a
            className="hover:underline text-blue-link"
            href="https://www.topicflow.com/docs/connect-kpi-to-custom-webhook/"
          >
            More infos
          </a>
        </div>
      </div>
      <Button onClick={handleCopyUpdateUrl} theme={buttonTheme.primary} small>
        Copy URL
      </Button>
    </>
  );
};

export default KPIUpdateUrlContentPopover;
