import { useMutation } from "@apollo/client";
import { BasicUser } from "types/topicflow";

import getArtifactActivitiesQuery from "@apps/artifact-sidebar/graphql/get-artifact-activities-query";
import ActionItemPeopleDropdown from "@components/people-dropdown/action-item-people-dropdown";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { UserComboboxOption } from "@components/user-combobox/user-combobox-list";
import { graphqlNone } from "@helpers/constants";

import { refetchActionItemsInCollapsibleContainers } from "../../action-items-collapsible/helpers";
import updateArtifactMutation from "../graphql/update-artifact-mutation";

const ActionItemAssignee = ({
  artifact,
  showName = false,
  className = "",
}: {
  artifact: {
    id: number;
    assignee?: BasicUser | null;
  };
  showName?: boolean;
  className?: string;
}) => {
  const [updateArtifact] = useMutation(updateArtifactMutation);
  const unassignedId = graphqlNone;

  const handleSetAssignee = (selectedAssignee: UserComboboxOption | null) => {
    const assigneeId = !selectedAssignee
      ? null
      : selectedAssignee.id === unassignedId
      ? null
      : selectedAssignee?.id;
    const assignee = !selectedAssignee
      ? null
      : selectedAssignee?.id === unassignedId
      ? null
      : selectedAssignee;
    const variables = {
      artifactId: artifact.id,
      additionalFields: { assigneeId },
    };
    updateArtifact({
      variables,
      optimisticResponse: {
        createOrUpdateArtifact: {
          artifact: {
            ...artifact,
            assignee,
          },
          __typename: "CreateOrUpdateArtifactMutation",
        },
      },
      update: (cache, { data }) => {
        refetchActionItemsInCollapsibleContainers(
          data.createOrUpdateArtifact.artifact
        );
      },
      refetchQueries: [getArtifactActivitiesQuery],
      onError: onNotificationErrorHandler(),
    });
  };

  return (
    <ActionItemPeopleDropdown
      className={className}
      showName={showName}
      actionItem={artifact}
      onChangeAssignee={handleSetAssignee}
      unassignedId={unassignedId}
    />
  );
};

export default ActionItemAssignee;
