import { compact } from "lodash";
import {
  ArtifactActivityFragmentFragment,
  CommentNode,
  GetArtifactSidebarQueryQuery,
} from "types/graphql-schema";

import ActivityComment from "@apps/activities/components/comment";

const ActivityCommentAdded = ({
  activity,
  artifact,
}: {
  artifact: GetArtifactSidebarQueryQuery["artifact"];
  activity: ArtifactActivityFragmentFragment;
}) => {
  if (!activity?.changedFields || activity.changedFields.length === 0) {
    return null;
  }
  const comments = compact(activity.changedFields).map((changedField) => {
    const comment = changedField.newValueObject as CommentNode;
    return (
      <div key={changedField.fieldName}>
        <ActivityComment comment={comment} artifact={artifact} />
      </div>
    );
  });
  return <div>{comments}</div>;
};

export default ActivityCommentAdded;
