import { gql } from "@apollo/client";

import syncCredentialFragment from "./sync-credential-fragment";

export default gql`
  ${syncCredentialFragment}
  mutation startCalendarSyncMutation(
    $syncCredentialsId: Int!
    $fullSync: Boolean
  ) {
    startCalendarSync(
      syncCredentialsId: $syncCredentialsId
      fullSync: $fullSync
    ) {
      user {
        id
        syncCredentials {
          edges {
            node {
              ...AccountSyncCredentialFragment
            }
          }
        }
      }
    }
  }
`;
