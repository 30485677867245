import { gql } from "@apollo/client";

export default gql`
  fragment AccountOrganizationFragment on OrganizationNode {
    id
    name
    personal
    userMembership {
      id
      position
    }
    directorySync {
      id
      state
      type
      name
    }
    members {
      edges {
        node {
          id
          user {
            id
            name
            avatar
          }
        }
      }
    }
  }
`;
