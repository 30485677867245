import { useLazyQuery } from "@apollo/client";
import { useCallback } from "react";

import cache from "@cache/cache";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import usePusher from "@components/use-pusher/use-pusher";
import { maxKeyResults } from "@helpers/constants";

import getArtifactQuery from "../graphql/get-artifact-query";

export default function PusherArtifactUpdated({
  channelName,
}: {
  channelName: string;
}) {
  // Queries & Mutations
  const [fetchArtifact] = useLazyQuery(getArtifactQuery, {
    onError: onNotificationErrorHandler(),
  });

  const handleArtifactCreated = useCallback(
    ({ artifact }: { artifact: { id: number } }) => {
      // If it's in the cache then we update
      const artifactCacheId = cache.identify(artifact);
      if (artifactCacheId) {
        fetchArtifact({
          fetchPolicy: "network-only",
          variables: { artifactId: artifact.id, keyResultLimit: maxKeyResults },
        });
      }
    },
    [fetchArtifact]
  );

  usePusher(channelName, "artifact_created", handleArtifactCreated);

  return null;
}
