import { useLazyQuery } from "@apollo/client";
import { AnimatePresence } from "framer-motion";
import { compact, groupBy, range, take } from "lodash";
import moment from "moment";
import {
  ChangeEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  GetMeetingOverviewMeetingsQueryQuery,
  GetMeetingOverviewMeetingsQueryQueryVariables,
  MeetingDialogFragmentFragment,
} from "types/graphql-schema";

import useUiPreferenceCache from "@apps/use-ui-preference-cache/use-ui-preference-cache";
import { currentUserVar } from "@cache/cache";
import Button, { buttonTheme } from "@components/button/button";
import Loading from "@components/loading/loading";
import MotionDiv from "@components/motion/motion-div";
import Select from "@components/select/select";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import useUserComboboxQuery from "@components/user-combobox/use-user-combobox-query";
import UserCombobox from "@components/user-combobox/user-combobox";
import { UserComboboxOption } from "@components/user-combobox/user-combobox-list";
import { classNames } from "@helpers/css";
import { assertEdgesNonNull, assertNonNull } from "@helpers/helpers";

import getMeetingOverviewMeetingsQuery from "../graphql/get-meeting-overview-meetings-query";
import OverviewMeeting from "./overview-meeting";
import UnscheduledMeeting from "./unscheduled-meeting";

enum MeetingType {
  ALL,
  ONE_ON_ONE,
  GROUP,
}

const MeetingOverview = ({
  selectedUser,
}: {
  selectedUser: UserComboboxOption;
}) => {
  const currentUser = currentUserVar();
  const { uiPreferenceCache, saveUiPreference } = useUiPreferenceCache();
  const todayRef = useRef<HTMLDivElement>(null);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [initComplete, setInitComplete] = useState(false);
  const [loadMore, setLoadMore] = useState<"past" | "future" | null>(null);
  const [meetings, setMeetings] = useState<MeetingDialogFragmentFragment[]>([]);
  const [unscheduledMeetings, setUnscheduledMeetings] = useState<
    MeetingDialogFragmentFragment[]
  >([]);
  const [participantFilter, setParticipantFilter] = useState<number | null>(
    null
  );
  const [meetingTypeFilter, setMeetingTypeFilter] = useState(MeetingType.ALL);
  const [isShowingWeekends, setIsShowingWeekends] = useState(
    uiPreferenceCache.meetingOverviewShowWeekends
  );

  const [meetingDisplayedRange, setMeetingDisplayedRange] = useState<{
    start: string;
    end: string;
  }>({
    start: moment().startOf("isoWeek").format(),
    end: moment().startOf("isoWeek").add(1, "week").format(),
  });

  const [fetchMeetings, { loading }] = useLazyQuery<
    GetMeetingOverviewMeetingsQueryQuery,
    GetMeetingOverviewMeetingsQueryQueryVariables
  >(getMeetingOverviewMeetingsQuery, {
    onError: onNotificationErrorHandler(),
  });

  const handleReceiveMeetingsResponse = useCallback(
    (response: GetMeetingOverviewMeetingsQueryQuery) => {
      const unscheduled = compact(
        assertEdgesNonNull(response.unscheduledMeetings).map(
          (calendarItem) => calendarItem.meeting
        )
      );
      setUnscheduledMeetings(unscheduled);

      const meetings = compact(
        assertEdgesNonNull(response.calendar).map(
          (calendarItem) => calendarItem.meeting
        )
      );
      setMeetings((prev) => {
        const existingIds = prev.map(({ id }) => id);
        const updatedMeetings = prev.map((prevMeeting) => {
          if (meetings.map(({ id }) => id).includes(prevMeeting.id)) {
            return assertNonNull(
              meetings.find(({ id }) => id === prevMeeting.id)
            );
          }
          return prevMeeting;
        });
        const newMeetings = meetings.filter(
          ({ id }) => !existingIds.includes(id)
        );
        return [...updatedMeetings, ...newMeetings];
      });
      setInitComplete(true);
      setLoadMore(null);
    },
    []
  );

  useEffect(() => {
    if (initComplete) {
      const scrollInterval = setTimeout(() => {
        if (todayRef.current && scrollContainerRef.current) {
          const scrollTop = todayRef.current.offsetTop;
          scrollContainerRef.current.scrollTo({
            top: scrollTop - 10,
            behavior: "smooth",
          });
        }
      }, 700);
      return () => clearTimeout(scrollInterval);
    }
    // scroll to today only on page load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initComplete]);

  useEffect(() => {
    setInitComplete(false);
    setLoadMore(null);
    setMeetings([]);
    setUnscheduledMeetings([]);
    setMeetingDisplayedRange({
      start: moment().startOf("isoWeek").format(),
      end: moment().startOf("isoWeek").add(1, "week").format(),
    });
    // reset when user changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUser]);

  useEffect(() => {
    if (loadMore === null && initComplete) {
      return;
    }
    const isLoadingPastMeetings = loadMore === "past";
    const newStart = !initComplete
      ? meetingDisplayedRange.start
      : isLoadingPastMeetings
      ? moment(meetingDisplayedRange.start).subtract(1, "week").format()
      : meetingDisplayedRange.end;

    fetchMeetings({
      variables: {
        forUserId: selectedUser.id,
        dateRangeStart: newStart,
        dateRangeEnd: moment(newStart).add(1, "week").format(),
      },
      onCompleted: (response) => {
        handleReceiveMeetingsResponse(response);
        if (isLoadingPastMeetings) {
          setMeetingDisplayedRange((prev) => ({
            ...prev,
            start: newStart,
          }));
        } else {
          setMeetingDisplayedRange((prev) => ({
            ...prev,
            end: moment(newStart).add(1, "week").format(),
          }));
        }
      },
    });
    // only want to fetch more when index changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadMore, initComplete]);

  const {
    options: assessmentUserFilterOptions,
    query,
    setQuery,
  } = useUserComboboxQuery({});
  const userComboboxValue =
    assessmentUserFilterOptions.find(({ id }) => id === participantFilter) ||
    null;

  const handleChangeParticipantFilter = useCallback(
    (option?: UserComboboxOption) => {
      setParticipantFilter(option ? option.id : null);
    },
    []
  );

  const handleToggleShowWeekends: ChangeEventHandler<HTMLInputElement> =
    useCallback(
      (e) => {
        const showWeekends = e.target.checked;
        saveUiPreference({
          meetingOverviewShowWeekends: showWeekends,
        });
        setIsShowingWeekends(showWeekends);
      },
      [saveUiPreference]
    );

  const handleMeetingUpdated = useCallback(
    (data: { meeting: MeetingDialogFragmentFragment }) => {
      if (data.meeting.startDatetime) {
        // if meeting is scheduled, remove from unscheduledMeetings and update meetings list
        setUnscheduledMeetings((prev) =>
          prev.filter((meeting) => meeting.id !== data.meeting.id)
        );
        setMeetings((prev) => {
          if (prev.find((meeting) => meeting.id === data.meeting.id)) {
            return prev.map((meeting) => {
              if (meeting.id === data.meeting.id) {
                return data.meeting;
              }
              return meeting;
            });
          }
          return [...prev, data.meeting];
        });
      } else {
        // else remove from meetings list and update unscheduledMeetings
        setMeetings((prev) =>
          prev.filter((meeting) => meeting.id !== data.meeting.id)
        );
        setUnscheduledMeetings((prev) => {
          if (prev.find((meeting) => meeting.id === data.meeting.id)) {
            return prev.map((meeting) => {
              if (meeting.id === data.meeting.id) {
                return data.meeting;
              }
              return meeting;
            });
          }
          return [...prev, data.meeting];
        });
      }
    },
    []
  );

  const handleMeetingDeleted = useCallback((meetingId: number) => {
    setMeetings((prev) => {
      return prev.filter((meeting) => meeting.id !== meetingId);
    });
    setUnscheduledMeetings((prev) => {
      return prev.filter((meeting) => meeting.id !== meetingId);
    });
  }, []);

  const meetingsByStartDate = groupBy(meetings, (meeting) =>
    moment(meeting.startDatetime).format("YYYY-MM-DD")
  );
  const daysInRange = range(
    moment(meetingDisplayedRange.end).diff(meetingDisplayedRange.start, "days")
  )
    .map((dayIndex) => {
      return moment(meetingDisplayedRange.start)
        .add(dayIndex, "days")
        .format("YYYY-MM-DD");
    })
    .filter((day) => {
      return isShowingWeekends || ![6, 7].includes(moment(day).isoWeekday());
    });

  const showUnscheduledMeetings =
    selectedUser.id === currentUser.id && unscheduledMeetings.length > 0;

  return (
    <div>
      <div
        className={classNames(
          "sticky z-40 bg-white",
          showUnscheduledMeetings && "h-40",
          !showUnscheduledMeetings && "h-18"
        )}
      >
        {showUnscheduledMeetings && (
          <div className="mb-2 p-4 border-b">
            <div className="text-sm font-medium mb-2">{`Unscheduled meetings (${unscheduledMeetings.length})`}</div>
            <div className="flex gap-4">
              <AnimatePresence>
                {take(unscheduledMeetings, 3).map((meeting) => {
                  return (
                    <MotionDiv
                      key={meeting.id}
                      className="w-1/3"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                    >
                      <UnscheduledMeeting
                        meeting={meeting}
                        onMeetingUpdated={handleMeetingUpdated}
                        onMeetingDeleted={handleMeetingDeleted}
                      />
                    </MotionDiv>
                  );
                })}
              </AnimatePresence>
            </div>
          </div>
        )}
        <div
          className={classNames(
            "flex items-center gap-4 p-4",
            showUnscheduledMeetings && "py-2",
            !showUnscheduledMeetings && "border-b"
          )}
        >
          <div className="w-64">
            <UserCombobox
              onChangeValue={handleChangeParticipantFilter}
              options={assessmentUserFilterOptions}
              value={userComboboxValue}
              placeholder="All people"
              clearable={participantFilter !== null}
              query={query}
              onChangeQuery={setQuery}
              onClearValue={handleChangeParticipantFilter}
            />
          </div>
          <Select
            value={meetingTypeFilter}
            options={[
              {
                value: MeetingType.ALL,
                label: "All meetings",
              },
              {
                value: MeetingType.ONE_ON_ONE,
                label: "1-on-1s",
              },
              {
                value: MeetingType.GROUP,
                label: "Group meetings",
              },
            ]}
            onChange={(opt) => setMeetingTypeFilter(opt.value)}
            aria-label="Meeting type filter"
          />
          <label className="flex items-center gap-1 text-sm text-gray-600 cursor-pointer">
            <input
              type="checkbox"
              checked={isShowingWeekends}
              onChange={handleToggleShowWeekends}
            />
            Show weekends
          </label>
        </div>
      </div>

      <div
        className={classNames(
          "absolute inset-0 overflow-y-scroll px-24 pt-4 pb-12",
          unscheduledMeetings.length > 0 && "top-40",
          unscheduledMeetings.length === 0 && "top-18"
        )}
        ref={scrollContainerRef}
      >
        {/* Main loader on first page hit */}
        {loading && !initComplete && (
          <Loading className="p-6">Loading meetings...</Loading>
        )}

        {initComplete && (
          <>
            <div className="flex justify-center">
              <Button
                className="text-xs"
                theme={buttonTheme.text}
                disabled={loading}
                onClick={() => {
                  setLoadMore("past");
                }}
              >
                {loadMore === "past" ? (
                  <Loading size={4} />
                ) : (
                  `Load previous week`
                )}
              </Button>
            </div>
            <div className="flex flex-col gap-8">
              <AnimatePresence>
                {daysInRange.map((date) => {
                  const isToday = moment(date).isSame(moment(), "day");
                  const dayMeetings = meetingsByStartDate[date]
                    ? meetingsByStartDate[date]
                        .filter((meeting) => {
                          if (participantFilter === null) {
                            return true;
                          }
                          const participantIds = meeting.participants
                            ? meeting.participants.edges.map(
                                (participantNode) =>
                                  participantNode?.node?.user?.id
                              )
                            : [];
                          return participantIds.includes(participantFilter);
                        })
                        .filter((meeting) => {
                          if (meetingTypeFilter === MeetingType.ALL) {
                            return true;
                          }
                          return (
                            (meeting.meetingGroup?.isFormalOneonone &&
                              meetingTypeFilter === MeetingType.ONE_ON_ONE) ||
                            (!meeting.meetingGroup?.isFormalOneonone &&
                              meetingTypeFilter === MeetingType.GROUP)
                          );
                        })
                    : [];
                  const pastMeetings = dayMeetings.filter((meeting) => {
                    return meeting.endDatetime
                      ? moment().isAfter(meeting.endDatetime)
                      : false;
                  });
                  const futureMeetings = dayMeetings.filter((meeting) => {
                    return meeting.endDatetime
                      ? moment().isSameOrBefore(meeting.endDatetime)
                      : false;
                  });
                  return (
                    <MotionDiv
                      key={date}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                    >
                      <AnimatePresence>
                        <div
                          className={classNames(
                            "text-sm font-semibold mb-4",
                            isToday && "text-green-700",
                            !isToday && "text-gray-700"
                          )}
                          ref={isToday ? todayRef : undefined}
                          key="date"
                        >
                          {moment(date).format(
                            isToday ? "MMMM D ([Today])" : "MMM D (dddd)"
                          )}
                        </div>
                        <div key="meetings">
                          {dayMeetings.length === 0 && (
                            <MotionDiv
                              initial={{ opacity: 0 }}
                              animate={{ opacity: 1 }}
                              className="p-4 border-t text-sm text-gray-400"
                            >
                              No meetings
                            </MotionDiv>
                          )}
                          <div>
                            {pastMeetings.map((meeting) => {
                              return (
                                <OverviewMeeting
                                  key={meeting.id}
                                  meeting={meeting}
                                  onMeetingUpdated={handleMeetingUpdated}
                                />
                              );
                            })}
                          </div>
                          <div>
                            {futureMeetings.map((meeting) => {
                              return (
                                <OverviewMeeting
                                  key={meeting.id}
                                  meeting={meeting}
                                  onMeetingUpdated={handleMeetingUpdated}
                                />
                              );
                            })}
                          </div>
                        </div>
                      </AnimatePresence>
                    </MotionDiv>
                  );
                })}
              </AnimatePresence>
            </div>
            <div className="flex justify-center">
              <Button
                className="text-xs"
                theme={buttonTheme.text}
                disabled={loading}
                onClick={() => {
                  setLoadMore("future");
                }}
              >
                {loadMore === "future" ? (
                  <Loading size={4} />
                ) : (
                  `Load next week`
                )}
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default MeetingOverview;
