import { gql } from "@apollo/client";

export default gql`
  query getRelatedTopics($topicId: Int!, $meetingGroupId: Int!) {
    topic(topicId: $topicId) {
      id
      relatedTopics(meetingInMeetingGroupId: $meetingGroupId) {
        totalCount
        edges {
          node {
            id
            meeting {
              id
              startDatetime
            }
          }
        }
      }
    }
  }
`;
