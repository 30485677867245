import Azure from "@static/integration-logos/Azure.svg";
import BambooHR from "@static/integration-logos/BambooHR.svg";
import Breathe from "@static/integration-logos/Breathe.svg";
import Fourth from "@static/integration-logos/Fourth.svg";
import Google from "@static/integration-logos/Google.svg";
import Hibob from "@static/integration-logos/Hibob.svg";
import JumpCloud from "@static/integration-logos/JumpCloud.svg";
import Okta from "@static/integration-logos/Okta.svg";
import OneLogin from "@static/integration-logos/OneLogin.svg";
import PingFederate from "@static/integration-logos/PingFederate.svg";
import Rippling from "@static/integration-logos/Rippling.svg";
import SCIM from "@static/integration-logos/SCIM.svg";
import Workday from "@static/integration-logos/Workday.svg";
import { OrgSettingsOrganizationFragmentFragment } from "types/graphql-schema";

import Heading from "@components/heading/heading";
import { getAssetUrl } from "@helpers/helpers";

const DirectorySync = ({
  organization,
}: {
  organization: OrgSettingsOrganizationFragmentFragment;
}) => {
  return (
    <div>
      <Heading small title="Directory Sync" />

      {!!organization.directorySync ? (
        <ul className="mt-6 list-disc ml-4">
          <li>
            <span className="font-medium">Name:</span>{" "}
            {organization.directorySync?.name}
          </li>
          <li>
            <span className="font-medium">State:</span>{" "}
            {organization.directorySync?.state}
          </li>
          <li>
            <span className="font-medium">Type:</span>{" "}
            {organization.directorySync?.type}
          </li>
        </ul>
      ) : (
        <div className="mt-6">
          <a
            href="https://www.topicflow.com/contact-us/"
            className="text-blue-500 hover:underline"
          >
            Contact us
          </a>{" "}
          to sync your organization's HRIS.
        </div>
      )}

      <div className="mt-10">
        <div className="pb-12 text-center mx-auto grid grid-cols-2 auto-cols-[1fr] max-w-md lg:block lg:max-w-none">
          <div className="inline-block mt-8 mx-auto lg:mx-8">
            <img src={getAssetUrl(Azure)} loading="lazy" alt="" />
          </div>
          <div className="inline-block mt-8 mx-auto lg:mx-8">
            <img src={getAssetUrl(Okta)} loading="lazy" alt="" />
          </div>
          <div className="inline-block mt-8 mx-auto lg:mx-8">
            <img src={getAssetUrl(Google)} loading="lazy" alt="" />
          </div>
          <div className="inline-block mt-8 mx-auto lg:mx-8">
            <img src={getAssetUrl(BambooHR)} loading="lazy" alt="" />
          </div>
          <div className="inline-block mt-8 mx-auto lg:mx-8">
            <img src={getAssetUrl(Hibob)} loading="lazy" alt="" />
          </div>
          <div className="inline-block mt-8 mx-auto lg:mx-8">
            <img src={getAssetUrl(Workday)} loading="lazy" alt="" />
          </div>
          <div className="inline-block mt-8 mx-auto lg:mx-8">
            <img src={getAssetUrl(OneLogin)} loading="lazy" alt="" />
          </div>
          <div className="inline-block mt-8 mx-auto lg:mx-8">
            <img src={getAssetUrl(JumpCloud)} loading="lazy" alt="" />
          </div>
          <div className="inline-block mt-8 mx-auto lg:mx-8">
            <img src={getAssetUrl(SCIM)} loading="lazy" alt="" />
          </div>
          <div className="inline-block mt-8 mx-auto lg:mx-8">
            <img src={getAssetUrl(Fourth)} loading="lazy" alt="" />
          </div>
          <div className="inline-block mt-8 mx-auto lg:mx-8">
            <img src={getAssetUrl(Rippling)} loading="lazy" alt="" />
          </div>
          <div className="inline-block mt-8 mx-auto lg:mx-8">
            <img src={getAssetUrl(PingFederate)} loading="lazy" alt="" />
          </div>
          <div className="inline-block mt-8 mx-auto lg:mx-8">
            <img src={getAssetUrl(Breathe)} loading="lazy" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DirectorySync;
