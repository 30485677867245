import logoDevShort from "@static/img/topicflow-logo-dev-short.png";
import logoDev from "@static/img/topicflow-logo-dev.png";
import logoShort from "@static/img/topicflow-logo-no-text.svg";
import logo from "@static/img/topicflow-logo.svg";

import Link from "@components/link/link";
import { getAssetUrl } from "@helpers/helpers";

const Logo = ({ menuIsCollapsed = false }: { menuIsCollapsed?: boolean }) => (
  <Link
    to="/"
    className="font-light text-2xl flex items-center"
    id="sidebar-logo"
  >
    {import.meta.env.DEV ? (
      <img
        src={getAssetUrl(menuIsCollapsed ? logoDevShort : logoDev)}
        alt="Topicflow"
        className="block h-6"
      />
    ) : (
      <img
        src={getAssetUrl(menuIsCollapsed ? logoShort : logo)}
        alt="Topicflow"
        className="block h-8"
      />
    )}
  </Link>
);

export default Logo;
