import { ReactElement } from "react";

export const CollapsibleEmpty = ({
  children,
}: {
  children: ReactElement | string[] | string;
}) => {
  return <div className="py-1 text-gray-400 text-sm">{children}</div>;
};

export default CollapsibleEmpty;
