import { compact, sortBy, uniqBy } from "lodash";
import { useCallback } from "react";

import { currentOrganizationVar } from "@cache/cache";
import Combobox, {
  ComboboxGenericOption,
} from "@components/combobox/generic-combobox";
import { classNames } from "@helpers/css";
import { assertEdgesNonNull } from "@helpers/helpers";

import ExplorerComboboxButton from "./explorer-combobox-button";

const allCoreValuesOption = {
  label: "All",
  title: "All",
  value: null,
  id: null,
};

type Props = {
  prefix: string;
  ariaLabel: string;
  className: string;
  coreValueId?: number | null;
  onSelectCoreValue: (coreValue: number | null) => void;
};

const ExplorerCoreValueCombobox: React.FC<Props> = ({
  coreValueId,
  className,
  ariaLabel,
  prefix,
  onSelectCoreValue,
}) => {
  const organization = currentOrganizationVar();
  const coreValues = organization.coreValues
    ? assertEdgesNonNull(organization.coreValues)
    : [];
  const selectedCoreValue = coreValues.find(({ id }) => id === coreValueId);

  const mergedOptions = sortBy(
    compact([selectedCoreValue, ...coreValues]),
    ({ title }) => title.toLowerCase()
  );
  const options = uniqBy([allCoreValuesOption, ...mergedOptions], "id").map(
    (option) => ({
      ...option,
      label: option.title,
      value: option.id,
    })
  );
  const selectedOption =
    options.find(({ value }) => value === coreValueId) || allCoreValuesOption;

  const handleChangeValue = useCallback(
    (option: ComboboxGenericOption<number | null>) => {
      onSelectCoreValue(option.value);
    },
    [onSelectCoreValue]
  );

  const handleClearValue = useCallback(() => {
    onSelectCoreValue(null);
  }, [onSelectCoreValue]);

  return (
    <div className="flex-1 min-w-0">
      <Combobox<number | null>
        width="full"
        value={selectedOption}
        options={options}
        clearable={false}
        onChangeValue={handleChangeValue}
        onClearValue={handleClearValue}
        aria-label={ariaLabel}
        placeholder="Search core value..."
        name="core-value-combobox"
      >
        {({ value, onClickButton, setReferenceElement }) => (
          <ExplorerComboboxButton
            prefix={prefix}
            ariaLabel="Explorer core value picker button"
            setReferenceElement={setReferenceElement}
            onClickButton={onClickButton}
            label={value?.label || "Unknown"}
            onClear={handleClearValue}
            className={classNames(className)}
          />
        )}
      </Combobox>
    </div>
  );
};

export default ExplorerCoreValueCombobox;
