import {
  OnlineConference,
  SyncCredentialsTypeEnum,
} from "types/graphql-schema";

import { onlineConferenceName } from "@helpers/constants";

const OnlineConferenceName = ({
  provider,
  type,
}: {
  provider?: SyncCredentialsTypeEnum | null;
  type: OnlineConference;
}) => {
  if (type === OnlineConference.Native) {
    if (provider === SyncCredentialsTypeEnum.GoogleCalendar)
      return "Google Meet";
    if (provider === SyncCredentialsTypeEnum.OutlookCalendar)
      return "Microsoft Teams";
    if (provider === SyncCredentialsTypeEnum.Zoom) return "Zoom";
  }
  return onlineConferenceName[type];
};

export default OnlineConferenceName;
