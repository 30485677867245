import { gql } from "@apollo/client";

export default gql`
  mutation createOrUpdateKpiMeasurementMutation(
    $kpiMeasurementId: Int
    $kpiId: Int
    $measurement: Float!
    $timestamp: DateTime
  ) {
    createOrUpdateKpiMeasurement(
      kpiMeasurementId: $kpiMeasurementId
      kpiId: $kpiId
      measurement: $measurement
      timestamp: $timestamp
    ) {
      kpi {
        id
      }
      kpiMeasurement {
        id
        measurement
        timestamp
      }
    }
  }
`;
