import { useMutation } from "@apollo/client";
import { TrashIcon } from "@heroicons/react/outline";
import ColorHash from "color-hash";
import { compact, last } from "lodash";
import moment from "moment";
import {
  DeleteMeetingTranscriptMessagesMutation,
  DeleteMeetingTranscriptMessagesMutationVariables,
  GetMeetingTranscriptQueryQuery,
  MeetingTranscriptMessageNode,
} from "types/graphql-schema";

import { onNotificationErrorHandler } from "@components/use-error/use-error";
import useConfirm from "@helpers/hooks/use-confirm";

import deleteTranscriptMessagesMutation from "./graphql/delete-transcript-messages-mutation";

type GroupMessageType = {
  speaker: string;
  id: string;
  messages: MeetingTranscriptMessageNode[];
};

const TranscriptionTextItem = ({
  captionGroup,
  meetingId,
}: {
  captionGroup: GroupMessageType;
  meetingId: number;
}) => {
  const colorHash = new ColorHash({ lightness: [0.3, 0.5] });
  const messageIds = compact(
    (captionGroup?.messages || []).map(({ messageId }) => messageId)
  );

  const [deleteMessages, { loading: loadingDelete }] = useMutation<
    DeleteMeetingTranscriptMessagesMutation,
    DeleteMeetingTranscriptMessagesMutationVariables
  >(deleteTranscriptMessagesMutation);
  const { ConfirmationDialog, confirm } = useConfirm(
    "Are you sure?",
    "Are you sure you want to delete this paragraph?"
  );

  const handleClickDeleteMessageIds = async () => {
    const confirmation = await confirm();
    if (confirmation) {
      deleteMessages({
        variables: {
          meetingId: meetingId,
          removeMessageIds: messageIds,
        },
        onError: onNotificationErrorHandler(),
      });
    }
  };

  if (messageIds.length === 0) return null;

  return (
    <li className="group flex items-center hover:bg-gray-100 rounded py-1 px-2">
      <ConfirmationDialog />
      <div className="flex-1">
        <div>
          <span
            className="font-medium text-gray-800 mr-2 tracking-tight"
            style={{
              color: colorHash.hex(captionGroup.speaker || "unknown"),
            }}
          >
            {captionGroup.speaker || "Unknown"}
          </span>
          <span
            className="text-xs text-gray-400"
            title={moment(captionGroup.messages[0].created).toString()}
          >
            {moment(captionGroup.messages[0].created).format("H:mma")}
          </span>
        </div>
        <div>
          {captionGroup.messages.map((message) => message.text).join(" ")}
        </div>
      </div>
      <button
        type="button"
        disabled={loadingDelete}
        onClick={handleClickDeleteMessageIds}
        className="opacity-0 group-hover:opacity-100 p-0.5 rounded hover:bg-gray-200 text-gray-500 hover:text-gray-800"
      >
        <TrashIcon className="w-5 h-5" />
      </button>
    </li>
  );
};

const TranscriptionText = ({
  meeting,
}: {
  meeting: NonNullable<GetMeetingTranscriptQueryQuery["meeting"]>;
}) => {
  const orderedTranscriptData = meeting.transcript?.orderedTranscriptData || [];

  const groupedMessages = orderedTranscriptData.reduce((groups, message) => {
    if (!message || !message.created || !message.messageId || !message.speaker)
      return groups;
    // if message if from a different participant or has a long delay in between
    // we add a new group.
    const lastGroup = last(groups);
    const isDifferentParticipant = lastGroup?.speaker !== message.speaker;
    const lastMessageIndex = (lastGroup?.messages.length || 0) - 1;
    const lastMessageCreated =
      lastGroup?.messages[lastMessageIndex]?.created || 0;
    const hasTimeDelay =
      !isDifferentParticipant &&
      lastMessageCreated + 1 * 5000 < message.created;
    if (isDifferentParticipant || hasTimeDelay) {
      return [
        ...groups,
        {
          id: message.messageId,
          speaker: message.speaker,
          messages: [message],
        },
      ];
    }

    // add message to group
    if (lastGroup && message.created && message.messageId && message.speaker) {
      const newMessages = [...(lastGroup ? lastGroup.messages : []), message];
      groups[groups.length - 1] = { ...lastGroup, messages: newMessages };
    }
    return groups;
  }, [] as GroupMessageType[]);

  return (
    <ul className="text-sm -ml-2">
      {groupedMessages.map((captionGroup) => (
        <TranscriptionTextItem
          captionGroup={captionGroup}
          meetingId={meeting.id}
          key={`${captionGroup.id}-${captionGroup.speaker}`}
        />
      ))}
    </ul>
  );
};

export default TranscriptionText;
