import { MouseEvent } from "react";
import { GoalProgressType } from "types/graphql-schema";

import StartCurrentTargetTooltip from "@components/tooltip/start-current-target-tooltip";
import { progressTypeBooleanTargetValue } from "@helpers/constants";
import { classNames } from "@helpers/css";

const CurrentValueInput = ({
  currentValue,
  goalOrKeyresult,
  className = "",
  disabled = false,
  onChange,
}: {
  currentValue: number;
  goalOrKeyresult: any;
  className?: string;
  disabled?: boolean;
  onChange: any;
}) => {
  return goalOrKeyresult.progressType === GoalProgressType.Boolean ? (
    <label
      className={classNames(
        "border rounded-lg flex items-center gap-2 px-2 text-sm w-40",
        className
      )}
    >
      <input
        type="checkbox"
        aria-label="Goal current value input"
        className="min-w-0 px-1 rounded text-left focus:outline-none focus:ring-0 tracking-tight"
        checked={currentValue === progressTypeBooleanTargetValue}
        onChange={(e) => onChange(e, goalOrKeyresult)}
        disabled={disabled}
      />
      <span className="py-1 text-gray-600">Completed</span>
    </label>
  ) : (
    <div
      className={classNames(
        "border rounded-lg flex gap-1 px-2 text-sm w-40",
        disabled && "bg-gray-50",
        className
      )}
    >
      <input
        type="text"
        aria-label="Goal current value input"
        className={classNames(
          "min-w-0 px-1 rounded text-left focus:outline-none focus:ring-0 tracking-tight",
          disabled && "bg-gray-50 text-gray-400"
        )}
        value={currentValue}
        onClick={(e: MouseEvent<HTMLInputElement>) => {
          e.currentTarget.select();
        }}
        disabled={disabled}
        onChange={(e) => onChange(e, goalOrKeyresult)}
      />
      <span className="py-1 text-gray-400">/</span>
      <StartCurrentTargetTooltip goalOrKeyresult={goalOrKeyresult}>
        <span className="py-1 text-gray-400 tracking-tight">
          {goalOrKeyresult.targetValue}
        </span>
      </StartCurrentTargetTooltip>
    </div>
  );
};

export default CurrentValueInput;
