import { gql } from "@apollo/client";

export default gql`
  mutation unlinkIntegrationFromOrganizationMutation(
    $organizationIntegrationId: Int!
  ) {
    unlinkIntegrationFromOrganization(
      organizationIntegrationId: $organizationIntegrationId
    ) {
      organization {
        id
        integrations {
          edges {
            node {
              id
              integrationType
              creator {
                id
                name
              }
              created
            }
          }
        }
      }
    }
  }
`;
