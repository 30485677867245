import BulletList from "@tiptap/extension-bullet-list";
import Document from "@tiptap/extension-document";
import OrderList from "@tiptap/extension-ordered-list";
import Placeholder from "@tiptap/extension-placeholder";
import Typography from "@tiptap/extension-typography";
import StarterKit from "@tiptap/starter-kit";

import TipTapCustomImage, { asyncUploadImage } from "../image";
import CustomLink from "../link";
import CustomListItem from "../list-item";
import { CustomMention } from "../mention";

export const CommentDocument = Document.extend({
  name: "comment-doc",
});

export const CommentExtensions = (
  atMentionSuggestions: any,
  uploadVariable: any
) => [
  StarterKit.configure({
    codeBlock: false,
    bulletList: false,
    orderedList: false,
    listItem: false,
  }),
  CustomListItem.configure({
    HTMLAttributes: {
      class: "ml-4",
    },
  }),
  BulletList.configure({
    itemTypeName: "listItem",
    HTMLAttributes: {
      class: "list-disc",
    },
  }),
  OrderList.configure({
    itemTypeName: "listItem",
    HTMLAttributes: {
      class: "list-decimal",
    },
  }),
  TipTapCustomImage(asyncUploadImage(uploadVariable)),
  CustomLink.configure({
    openOnClick: true,
    autolink: true,
    HTMLAttributes: {
      class: "text-blue-600 hover:underline",
    },
  }),
  Placeholder.configure({ placeholder: "Add a comment..." }),
  CommentDocument,
  CustomMention.configure({
    HTMLAttributes: {
      class: "mention",
    },
    suggestion: atMentionSuggestions,
  }),
  Typography,
];
