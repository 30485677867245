import { useLazyQuery } from "@apollo/client";
import * as EmailValidator from "email-validator";
import { compact, uniqBy } from "lodash";
import {
  AttendeeRole,
  AttendeeStatus,
  GetTeamMembersQueryQuery,
  GetTeamMembersQueryQueryVariables,
  UserStatus,
} from "types/graphql-schema";

import { onNotificationErrorHandler } from "@components/use-error/use-error";
import useUserComboboxQuery from "@components/user-combobox/use-user-combobox-query";
import UserCombobox from "@components/user-combobox/user-combobox";
import {
  UserComboboxOption,
  UserComboboxOptionType,
  UserComboboxUserOption,
} from "@components/user-combobox/user-combobox-list";
import { assertEdgesNonNull } from "@helpers/helpers";

import getTeamMembersQuery from "../graphql/get-team-members-query";

export type Attendee = {
  email: string;
  name: string;
  avatar?: string | null;
  id: number | null;
  role?: AttendeeRole | null;
  participantStatus?: AttendeeStatus | null;
  status?: UserStatus | null;
};

const uniqueEmails = (users: ReadonlyArray<Attendee>) =>
  uniqBy(users, ({ email }) => email);

const newEmailId = -2;

const AttendeeInput = ({
  attendees,
  isFormalOneonone,
  onChange,
}: {
  attendees: Attendee[];
  isFormalOneonone: boolean;
  onChange: (attendees: Attendee[]) => void;
}) => {
  const attendeeIds = attendees.map(({ id }) => id);
  const [getTeamMembers] = useLazyQuery<
    GetTeamMembersQueryQuery,
    GetTeamMembersQueryQueryVariables
  >(getTeamMembersQuery, { onError: onNotificationErrorHandler() });

  const {
    options: _options,
    loading,
    query,
    setQuery,
  } = useUserComboboxQuery({
    fetchOutsideCurrentOrganization: true,
    excludeUserIds: compact(attendeeIds),
    types: isFormalOneonone
      ? [UserComboboxOptionType.USER]
      : [UserComboboxOptionType.TEAM, UserComboboxOptionType.USER],
  });
  const emailCanBeAddedAsOption =
    EmailValidator.validate(query) &&
    !_options.find(
      (option) =>
        option.type === UserComboboxOptionType.USER &&
        (option.email?.toLowerCase() === query.toLowerCase() ||
          option.name?.toLowerCase() === query.toLowerCase())
    ) &&
    !attendees.find(
      (attendee) =>
        attendee.email?.toLowerCase() === query.toLowerCase() ||
        attendee.name?.toLowerCase() === query.toLowerCase()
    );
  const optionToCreate: UserComboboxUserOption = {
    id: -1,
    name: query,
    email: query,
    type: UserComboboxOptionType.USER,
  };
  const options = compact([
    ..._options,
    !loading && emailCanBeAddedAsOption && optionToCreate,
  ]);

  const handleSelectChange = (option: UserComboboxOption) => {
    if (option.type === UserComboboxOptionType.USER) {
      const newAttendee = {
        ...option,
        id: option.id === newEmailId ? null : option.id,
        role: AttendeeRole.Required,
        email: option.email || option.name,
        participantStatus: AttendeeStatus.NotResponded,
      };
      onChange(uniqueEmails([...attendees, newAttendee]));
    } else if (option.type === UserComboboxOptionType.TEAM) {
      getTeamMembers({
        variables: { teamId: option.id },
        onCompleted: (data) => {
          const members = data.team?.members
            ? assertEdgesNonNull(data.team.members)
            : [];
          const newAttendees = members.map((member) => ({
            ...member,
            role: AttendeeRole.Required,
            email: member.email || member.name,
            participantStatus: AttendeeStatus.NotResponded,
          }));
          onChange(uniqueEmails([...attendees, ...newAttendees]));
        },
      });
    }
  };

  return (
    <div className="text-sm flex flex-col gap-1">
      <div className="flex mb-2">
        <div className="relative flex-1">
          <UserCombobox
            className="px-3 py-1.5"
            value={null}
            query={query}
            onChangeQuery={setQuery}
            options={options}
            showEmail
            placeholder={"Add guests"}
            onChangeValue={handleSelectChange}
          />
        </div>
      </div>
    </div>
  );
};

export default AttendeeInput;
