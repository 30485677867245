import { DotsHorizontalIcon } from "@heroicons/react/solid";
import {
  Menu,
  MenuDivider,
  MenuHeader,
  MenuItem,
  MenuRadioGroup,
  RadioChangeEvent,
} from "@szhsin/react-menu";
import { Fragment, ReactNode } from "react";

import { classNames } from "@helpers/css";

export type DropdownOptionButtonType = {
  type?: "button";
  label: string | ReactNode;
  description?: string | ReactNode;
  onClick: (data: { value?: any; syntheticEvent: any }) => void;
};
export type DropdownOptionDividerType = {
  type: "divider";
};
export type DropdownOptionRadioType = {
  type: "radio";
  label: string;
  value: boolean | string | number;
  onChangeValue: (e: RadioChangeEvent) => void;
  options: {
    label: string;
    value: boolean | string | number;
  }[];
};

export type DropdownOptionType =
  | DropdownOptionButtonType
  | DropdownOptionRadioType
  | DropdownOptionDividerType;

const Dropdown = ({
  options,
  className = "",
  children,
  align = "start",
  ...props
}: {
  children?: JSX.Element;
  "aria-label"?: string;
  align?: "start" | "end";
  className?: string;
  options: DropdownOptionType[];
}) => {
  return (
    <Menu
      className="relative inline-block text-left text-sm not-prose z-dropdown fs-unmask"
      portal
      align={align}
      menuButton={
        children ? (
          children
        ) : (
          <button
            aria-label={props["aria-label"]}
            className={classNames("text-gray-400 rounded", className)}
          >
            <span className="sr-only">Open options</span>
            <DotsHorizontalIcon className={`h-5 w-5`} />
          </button>
        )
      }
    >
      {options.map((option, i) =>
        option.type === "divider" ? (
          <MenuDivider key={i} />
        ) : option.type === "radio" ? (
          <Fragment key={i}>
            <MenuHeader>{option.label}</MenuHeader>
            <MenuRadioGroup
              value={option.value}
              onRadioChange={option.onChangeValue}
            >
              {option.options.map((subOption, subOptionIndex) => (
                <MenuItem
                  key={String(subOption.value) || subOptionIndex}
                  type="radio"
                  value={subOption.value}
                >
                  {subOption.label}
                </MenuItem>
              ))}
            </MenuRadioGroup>
          </Fragment>
        ) : (
          <MenuItem
            key={i}
            className={classNames(
              "block w-full text-left px-4 py-2 text-sm",
              "text-gray-700 hover:bg-gray-100 hover:text-gray-900"
            )}
            onClick={option.onClick}
          >
            <div>{option.label}</div>
            {option.description && (
              <div className="text-gray-500 text-xs tracking-tight">
                {option.description}
              </div>
            )}
          </MenuItem>
        )
      )}
    </Menu>
  );
};

export default Dropdown;
