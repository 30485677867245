import { Node, mergeAttributes } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";

import {
  getPluginClickOn,
  getPluginHandlePaste,
} from "@components/wysiwyg/helpers";
import { regexp } from "@helpers/constants";
import { isValidUrl } from "@helpers/helpers";

import AsanaArtifactComponent from "./asana-artifact-component";

const AsanaExtension = Node.create({
  name: "asana",
  group: "block",
  marks: "",
  draggable: true,
  selectable: true,
  allowGapCursor: true,
  atom: true,
  isolating: true,
  defining: true,

  addAttributes() {
    // Return an object with attribute configuration
    return {
      id: {
        default: null,
        parseHTML: (node) => {
          if (node.dataset.asanataskid) {
            return node.dataset.asanataskid;
          }
          return null;
        },
        renderHTML: (attributes) => {
          return {
            "data-asanataskid": attributes.id,
          };
        },
      },
      url: {
        default: null,
      },
      // used to show placeholder when a user creates a rating in CRDT
      createdByUser: {
        default: this.options.currentUser,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "div",
        getAttrs: (node: HTMLElement | string) => {
          if (
            node &&
            node instanceof HTMLDivElement &&
            node.dataset?.asanataskid
          ) {
            return null;
          }
          return false;
        },
      },
    ];
  },

  addOptions() {
    return {
      relatedArtifactId: null,
      topicId: null,
      meetingId: null,
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(AsanaArtifactComponent);
  },

  renderText({ node }) {
    return `${window.location.origin}/asana/${node.attrs.id}`;
  },

  renderHTML({ HTMLAttributes }) {
    return ["div", mergeAttributes(HTMLAttributes)];
  },

  addCommands() {
    return {
      insertAsana:
        (attrs) =>
        ({ chain }) => {
          return chain().insertContent({ type: "asana", attrs }).run();
        },
    };
  },

  addProseMirrorPlugins() {
    return [
      getPluginClickOn(this.name),
      getPluginHandlePaste(this, (text, view) => {
        if (!text || !isValidUrl(text)) {
          return false;
        }

        // Detect Asana task url
        if (regexp.asanaTaskUrl.exec(text)) {
          const { tr } = view.state;
          tr.replaceSelectionWith(this.type.create({ url: text }));
          view.dispatch(tr);
          return true;
        }
        return false;
      }),
    ];
  },
});

export default AsanaExtension;
