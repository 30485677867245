import { CheckIcon } from "@heroicons/react/outline";
import { MenuItem, SubMenu } from "@szhsin/react-menu";

import { ExplorerFilterType } from "../helpers";
import { ExplorerMenuButtonOption } from "./explorer-menu-button";

export const ExplorerMenuOptions = ({
  options,
  filterKey,
  onSelectMenuItem,
}: {
  options: ExplorerMenuButtonOption[];
  filterKey: keyof ExplorerFilterType;
  onSelectMenuItem: (
    updatedFilters: ExplorerFilterType,
    filterKey: keyof ExplorerFilterType
  ) => void;
}) => {
  return (
    <>
      {options.map((option) =>
        option.submenu ? (
          <SubMenu label={option.label} key={option.value}>
            {option.submenu.map((subMenuOption) => (
              <MenuItem
                className="text-sm block"
                key={subMenuOption.value}
                onClick={
                  subMenuOption.onClick
                    ? subMenuOption.onClick
                    : () =>
                        onSelectMenuItem(subMenuOption.filters, option.value)
                }
              >
                <div className="flex items-center gap-2">
                  <div className="flex-1">{subMenuOption.label}</div>
                  {subMenuOption.selected && (
                    <CheckIcon className="h-5 w-5 text-blue-link" />
                  )}
                </div>
                {subMenuOption.description && (
                  <div className="text-2xs text-gray-400 tracking-tight">
                    {subMenuOption.description}
                  </div>
                )}
              </MenuItem>
            ))}
          </SubMenu>
        ) : (
          <MenuItem
            className="text-sm block"
            key={option.value}
            onClick={
              option.onClick
                ? option.onClick
                : () => onSelectMenuItem(option.filters, filterKey)
            }
          >
            <div className="flex items-center gap-2">
              <div className="flex-1">{option.label}</div>
              {option.selected && (
                <CheckIcon className="h-5 w-5 text-blue-link" />
              )}
            </div>
            {option.description && (
              <div className="text-2xs text-gray-400 tracking-tight">
                {option.description}
              </div>
            )}
          </MenuItem>
        )
      )}
    </>
  );
};

export default ExplorerMenuOptions;
