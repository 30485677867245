import { forwardRef } from "react";

import {
  classNames,
  inputBorderClassName,
  inputFocusClassName,
} from "@helpers/css";

type LabelProps = {
  label: string;
  name?: string;
  className?: string;
};

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  testId?: string;
  className?: string;
  autocomplete?: string;
  label?: string;
  name?: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
};

export const InputLabel = ({
  name = "",
  label,
  className = "",
}: LabelProps) => (
  <label
    htmlFor={name}
    className={classNames("block text-sm font-medium text-gray-700", className)}
  >
    {label}
  </label>
);

const Input = forwardRef<HTMLInputElement, Props>(
  (
    {
      autocomplete = "",
      label,
      name,
      testId = "",
      onChange,
      className,
      disabled,
      ...restProps
    },
    ref
  ) => {
    const input = (
      <input
        id={name}
        ref={ref}
        data-testid={testId}
        autoComplete={autocomplete}
        onChange={onChange}
        disabled={disabled}
        className={classNames(
          "px-4 py-2 block w-full sm:text-sm",
          !disabled && "bg-white",
          disabled && "bg-gray-100",
          inputBorderClassName,
          inputFocusClassName,
          className
        )}
        {...restProps}
      />
    );
    if (!label) {
      return input;
    }
    return (
      <div>
        <label
          htmlFor={name}
          className="block text-sm font-medium text-gray-700"
        >
          {label}
        </label>
        <div className={classNames(label ? "mt-1" : "")}>{input}</div>
      </div>
    );
  }
);

export default Input;
