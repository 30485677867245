import { useQuery } from "@apollo/client";
import {
  GetExplorerResultsQueryQuery,
  GetExplorerResultsQueryQueryVariables,
} from "types/graphql-schema";

import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { assertNonNull } from "@helpers/helpers";

import getExplorerResultsQuery from "./graphql/get-explorer-results-query";
import {
  ExplorerFilterType,
  explorerHubspotType,
  extractPageInfoFromData,
  getQueryVariablesForExplorerFilters,
  getSanitizedExplorerFilters,
  isIntegrationSetup,
  isIntegrationType,
} from "./helpers";

const useExplorerQuery = (
  filters: ExplorerFilterType,
  limit: number,
  options: {
    skip: boolean;
  } = {
    skip: false,
  }
) => {
  const sanitizedFilters = getSanitizedExplorerFilters(filters);
  const skip =
    isIntegrationType(sanitizedFilters) &&
    (sanitizedFilters.type !== explorerHubspotType ||
      !sanitizedFilters.hubspotStageId) &&
    (!isIntegrationSetup(sanitizedFilters.type) ||
      sanitizedFilters.search === "");
  const variables = getQueryVariablesForExplorerFilters(
    sanitizedFilters,
    limit
  );
  const { loading, data, fetchMore, refetch } = useQuery<
    GetExplorerResultsQueryQuery,
    GetExplorerResultsQueryQueryVariables
  >(getExplorerResultsQuery, {
    variables,
    ...options,
    onError: onNotificationErrorHandler(),
  });
  const results = data?.search?.edges
    ? data?.search?.edges.map((edge) => assertNonNull(edge?.node))
    : [];
  const pageInfo = extractPageInfoFromData(data);
  return {
    needSearchQueryForIntegrations: skip,
    results,
    data,
    pageInfo,
    loadingFirstTime: !data && loading,
    loading,
    fetchMore,
    refetch,
  };
};

export default useExplorerQuery;
