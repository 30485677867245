import { gql } from "@apollo/client";

export default gql`
  query getMeetingGroupOneononeStatusQuery($meetingGroupId: Int!) {
    meetingGroup(meetingGroupId: $meetingGroupId) {
      id
      title
      isFormalOneonone
      canUpdate {
        permission
      }
      canDelete {
        permission
      }
      participants {
        edges {
          node {
            id
            user {
              id
              firstName
              name
              avatar
            }
          }
        }
      }
      facilitator {
        id
        name
        avatar
      }
    }
  }
`;
