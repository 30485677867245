import { gql } from "@apollo/client";

const MeetingTranscriptFragment = gql`
  fragment MeetingTranscriptFragment on MeetingNode {
    hasTranscript
    notesSummary
    transcript {
      id
      summary
      mediumSummary
      longSummary
      orderedTranscriptData {
        text
        speaker
        created
        messageId
      }
      suggestedArtifacts(hidden: false) {
        edges {
          node {
            id
            title
            description
            hidden
            artifactType
            suggestedAssignee {
              id
              name
              avatar
            }
          }
        }
      }
      highlights
      highlightList {
        edges {
          node {
            id
            highlight
          }
        }
      }
    }
  }
`;

export default MeetingTranscriptFragment;
