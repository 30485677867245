import { gql } from "@apollo/client";

import syncCredentialFragment from "./sync-credential-fragment";

export default gql`
  ${syncCredentialFragment}
  mutation removeIntegrationCredentialsMutation($credentialsId: Int!) {
    removeIntegrationCredentials(credentialsId: $credentialsId) {
      user {
        id
        syncCredentials {
          edges {
            node {
              ...AccountSyncCredentialFragment
            }
          }
        }
      }
    }
  }
`;
