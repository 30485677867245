import { HTMLMotionProps } from "framer-motion";
import { motion } from "framer-motion";
import { PropsWithChildren } from "react";

const MotionDiv = ({
  children,
  className,
  role,
  onClick,
  ...props
}: PropsWithChildren<HTMLMotionProps<"div">>) => {
  if (window.Cypress) {
    return (
      <div className={className} role={role} onClick={onClick}>
        {children}
      </div>
    );
  }
  return (
    <motion.div className={className} role={role} onClick={onClick} {...props}>
      {children}
    </motion.div>
  );
};

export default MotionDiv;
