import { MenuItem } from "@szhsin/react-menu";

const CalendarEventMenu = ({ externalUrl }: { externalUrl: string }) => (
  <MenuItem
    className={"text-sm"}
    href={externalUrl}
    target="_blank"
    onClick={({ syntheticEvent }) => {
      syntheticEvent.preventDefault();
      syntheticEvent.stopPropagation();
      window.open(externalUrl, "_blank");
    }}
  >
    View calendar event
  </MenuItem>
);

export default CalendarEventMenu;
