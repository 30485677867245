/* eslint-disable import/no-extraneous-dependencies */
import { Editor } from "@tiptap/core";
import { Mark, getMarkRange, mergeAttributes } from "@tiptap/react";
import { Plugin } from "prosemirror-state";
import { v4 as uuidv4 } from "uuid";

import { tiptapExtensionPriority } from "@helpers/constants";

export const Comment = Mark.create({
  // keep the mark higher than other marks, prevent breaking the mark in multiple piece.
  priority: tiptapExtensionPriority.comment,
  name: "comment",
  inclusive: false,

  addOptions() {
    return {
      onClickCommentUuid: null,
    };
  },

  addAttributes() {
    return {
      // we should not be able to copy/paste a comment
      comment: {
        default: null,
        parseHTML: (el) => el.getAttribute("data-comment"),
        renderHTML: (attrs) => ({ "data-comment": attrs.comment }),
      },
    };
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "span",
      mergeAttributes(
        {
          class:
            "bg-amber-100 border-b-2 border-amber-200 hover:bg-amber-300 hover:cursor-pointer",
        },
        this.options.HTMLAttributes,
        HTMLAttributes
      ),
      0,
    ];
  },

  addCommands() {
    return {
      setComment:
        (uuid: string) =>
        ({ commands, editor }: { commands: any; editor: Editor }) => {
          if (editor.isActive(this.name)) {
            return false;
          }
          return commands.setMark("comment", { comment: uuid });
        },
      unsetComment:
        () =>
        ({ commands }: { commands: any }) =>
          commands.unsetMark("comment"),
    };
  },

  addKeyboardShortcuts() {
    return {
      "Mod-Alt-m": () => {
        if (this.editor.isActive(this.name)) {
          return false;
        }
        const { selection } = this.editor.state;
        const { empty } = selection;
        if (!empty) {
          const commentUuid = uuidv4();
          this.editor.commands.setComment(commentUuid);
          this.options?.onClickCommentUuid(commentUuid);
        }
        return false;
      },
    };
  },

  addProseMirrorPlugins() {
    const { options } = this;
    return [
      new Plugin({
        props: {
          handleClick(view, pos) {
            const { schema, doc } = view.state;
            const range = getMarkRange(doc.resolve(pos), schema.marks.comment);
            if (!range) return false;
            const editor = (view.dom as HTMLElement & { editor: Editor })
              .editor;
            const comment = editor.getAttributes("comment").comment;
            options?.onClickCommentUuid(comment);
            return true;
          },
        },
      }),
    ];
  },
});
