import { gql } from "@apollo/client";

export default gql`
  query getRecognitionBoardCoreValuesQuery(
    $organizationId: Int!
    $recipientId: Int
    $coreValueId: Int
    $receivedByMembersOfTeamId: Int
    $createdBetweenDates: [Date]
    $statsStartDate: Date
    $statsEndDate: Date
  ) {
    organization(pk: $organizationId) {
      id
      coreValues {
        edges {
          node {
            id
            title
            description
            emoji
            image
            recognitions(
              recognitionRecipient: $recipientId
              recognitionReceivedByMembersOfTeam: $receivedByMembersOfTeamId
              createdBetweenDates: $createdBetweenDates
            ) {
              totalCount
            }
          }
        }
      }
      recognitionStats(
        recipientId: $recipientId
        recognitionReceivedByMembersOfTeam: $receivedByMembersOfTeamId
        startDate: $statsStartDate
        endDate: $statsEndDate
        coreValueId: $coreValueId
      ) {
        recognitionCount
        userWhoGaveMostRecognition {
          id
          avatar
          name
        }
        userWhoGaveMostRecognitionCount
        userWhoReceivedMostRecognitionCount
        userWhoReceivedMostRecognition {
          id
          name
          avatar
        }
      }
    }
  }
`;
