import { gql } from "@apollo/client";

export default gql`
  mutation createOrUpdateRatingMutation(
    $title: String
    $description: String
    $organizationId: Int
    $ratingId: Int
    $startValue: Int
    $endValue: Int
    $labels: [String]!
  ) {
    createOrUpdateRating(
      organizationId: $organizationId
      ratingId: $ratingId
      title: $title
      description: $description
      startValue: $startValue
      endValue: $endValue
      labels: $labels
    ) {
      rating {
        id
        title
      }
    }
  }
`;
