import { gql } from "@apollo/client";

export default gql`
  mutation updateUserTimezone($userId: Int!, $timezone: String!) {
    updateUser(id: $userId, timezone: $timezone) {
      user {
        id
        timezone
      }
    }
  }
`;
