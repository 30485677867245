import { useQuery } from "@apollo/client";
import {
  GetHubspotStagesQueryQuery,
  GetHubspotStagesQueryQueryVariables,
} from "types/graphql-schema";

import { useNotificationError } from "@components/use-error/use-error";
import { assertEdgesNonNull } from "@helpers/helpers";

import getHubspotStagesQuery from "./graphql/get-hubspot-stages-query";

const useHubspotComboboxQuery = ({
  skip = false,
  stageId,
}: {
  skip?: boolean;
  stageId: number | null;
}) => {
  const { onError } = useNotificationError();
  const { data, loading } = useQuery<
    GetHubspotStagesQueryQuery,
    GetHubspotStagesQueryQueryVariables
  >(getHubspotStagesQuery, {
    skip,
    onError,
  });
  const hubspotStages = data?.hubspotPipelineStages
    ? assertEdgesNonNull(data.hubspotPipelineStages)
    : [];
  const options = hubspotStages.map((stage) => ({
    ...stage,
    label: `${stage.pipelineName}: ${stage.stageName}`,
    value: stage.id,
  }));
  const selectedOption = options.find(({ value }) => value === stageId) || null;

  return {
    loading,
    options,
    selectedOption,
  };
};

export default useHubspotComboboxQuery;
