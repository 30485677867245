import { gql } from "@apollo/client";

export default gql`
  mutation createCommentMutation(
    $comment: String!
    $topicId: Int
    $artifactId: Int
    $meetingId: Int
    $meetingGroupId: Int
  ) {
    createOrUpdateComment(
      comment: $comment
      topicId: $topicId
      artifactId: $artifactId
      meetingId: $meetingId
      meetingGroupId: $meetingGroupId
    ) {
      comment {
        id
        creator {
          id
          name
          avatar
        }
        created
        comment
        topic {
          id
          commentCount
        }
        artifact {
          id
          comments {
            totalCount
          }
        }
      }
    }
  }
`;
