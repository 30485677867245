import { PropsWithChildren } from "react";

import { classNames } from "@helpers/css";

type Props = {
  className?: string;
};

const Placeholder: React.FC<PropsWithChildren<Props>> = ({
  children,
  className,
}) => {
  return (
    <div className={classNames("text-gray-500 text-sm", className)}>
      {children}
    </div>
  );
};

export default Placeholder;
