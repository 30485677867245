import { useMutation } from "@apollo/client";
import { Listbox } from "@headlessui/react";
import { SelectorIcon } from "@heroicons/react/outline";
import { ChangeEvent, useEffect, useState } from "react";

import useLabel from "@apps/use-label/use-label";
import BillingDialogCTA from "@components/billing-dialog-cta/billing-dialog-cta";
import Heading from "@components/heading/heading";
import Input, { InputLabel } from "@components/input/input";
import Label from "@components/label/label";
import Select, { SelectOption } from "@components/select/select";
import useDebounce from "@components/use-debounce/use-debounce";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { months, pricing } from "@helpers/constants";
import {
  classNames,
  inputBorderClassName,
  listBoxButtonClassName,
} from "@helpers/css";
import { assertNonNull } from "@helpers/helpers";
import { capitalize } from "@helpers/string";

import {
  CreateOrUpdateOrganizationSettingsMutation,
  CreateOrUpdateOrganizationSettingsMutationVariables,
  DecisionState,
  GoalState,
  OrgSettingsOrganizationFragmentFragment,
  OrganizationMembershipSetting,
} from "../../../types/graphql-schema";
import updateOrganizationMutation from "../graphql/update-organization-mutation";
import SettingsContainer from "./settings-container";

const goalLabelOptions = ["objective", "goal", "rock"];
const teamLabelOptions = ["department", "group", "team"];
const orgLabelOptions = ["organization", "company"];
const recognitionLabelOptions = ["recognition", "shoutout", "kudo"];
const reviewLabelOptions = ["review", "assessment", "snapshot"];
const expectationLabelOptions = ["expectation", "alignment"];
const conversationLabelOptions = ["conversation", "coaching"];
const developmentLabelOptions = ["development", "reviews", "growth"];
const competencyLabelOptions = ["competency", "skill", "trait"];

const Settings = ({
  organization,
}: {
  organization: OrgSettingsOrganizationFragmentFragment;
}) => {
  const label = useLabel();
  const [membershipSetting, setMembershipSetting] = useState(
    organization.membershipSetting
  );
  const [name, setName] = useState(organization.name);
  const [goalLabel, setGoalLabel] = useState(organization.goalLabel);
  const [teamLabel, setTeamLabel] = useState(organization.teamLabel);
  const [orgLabel, setOrgLabel] = useState(organization.orgLabel);
  const [recognitionLabel, setRecognitionLabel] = useState(
    organization.recognitionLabel
  );
  const [reviewLabel, setReviewLabel] = useState(organization.reviewLabel);
  const [expectationLabel, setExpectationLabel] = useState(
    organization.expectationLabel
  );
  const [conversationLabel, setConversationLabel] = useState(
    organization.conversationLabel
  );
  const [developmentLabel, setDevelopmentLabel] = useState(
    organization.developmentLabel
  );
  const [competencyLabel, setCompetencyLabel] = useState(
    organization.competencyLabel
  );
  const [enableMeetingSummarization, setEnableMeetingSummarization] = useState(
    organization.enableMeetingSummarization
  );
  const [defaultGoalState, setDefaultGoalState] = useState(
    organization.defaultGoalState
  );
  const [defaultDecisionState, setDefaultDecisionState] = useState(
    organization.defaultDecisionState
  );
  const debouncedName = useDebounce(name, 300);
  const debouncedGoalLabel = useDebounce(goalLabel, 300);
  const debouncedTeamLabel = useDebounce(teamLabel, 300);
  const debouncedOrgLabel = useDebounce(orgLabel, 300);
  const debouncedRecognitionLabel = useDebounce(recognitionLabel, 300);
  const debouncedReviewLabel = useDebounce(reviewLabel, 300);
  const debouncedExpectationLabel = useDebounce(expectationLabel, 300);
  const debouncedConversationLabel = useDebounce(conversationLabel, 300);
  const debouncedDevelopmentLabel = useDebounce(developmentLabel, 300);
  const debouncedCompetencyLabel = useDebounce(competencyLabel, 300);
  const [updateOrganization] = useMutation<
    CreateOrUpdateOrganizationSettingsMutation,
    CreateOrUpdateOrganizationSettingsMutationVariables
  >(updateOrganizationMutation);

  const saveChanges = () => {
    if (
      debouncedName !== organization.name ||
      debouncedGoalLabel !== organization.goalLabel ||
      debouncedTeamLabel !== organization.teamLabel ||
      debouncedOrgLabel !== organization.orgLabel ||
      debouncedRecognitionLabel !== organization.recognitionLabel ||
      debouncedReviewLabel !== organization.reviewLabel ||
      debouncedExpectationLabel !== organization.expectationLabel ||
      debouncedConversationLabel !== organization.conversationLabel ||
      debouncedDevelopmentLabel !== organization.developmentLabel ||
      debouncedCompetencyLabel !== organization.competencyLabel ||
      membershipSetting !== organization.membershipSetting ||
      enableMeetingSummarization !== organization.enableMeetingSummarization ||
      defaultGoalState !== organization.defaultGoalState ||
      defaultDecisionState !== organization.defaultDecisionState
    ) {
      updateOrganization({
        variables: {
          organizationId: organization.id,
          name,
          goalLabel,
          teamLabel,
          orgLabel,
          recognitionLabel,
          reviewLabel,
          expectationLabel,
          conversationLabel,
          developmentLabel,
          competencyLabel,
          membershipSetting,
          enableMeetingSummarization,
          defaultGoalState,
          defaultDecisionState,
        },
        onError: onNotificationErrorHandler(),
        optimisticResponse: {
          createOrUpdateOrganization: {
            organization: {
              ...organization,
              name: debouncedName,
              goalLabel: debouncedGoalLabel,
              teamLabel: debouncedTeamLabel,
              orgLabel: debouncedOrgLabel,
              recognitionLabel: debouncedRecognitionLabel,
              reviewLabel: debouncedReviewLabel,
              expectationLabel: debouncedExpectationLabel,
              conversationLabel: debouncedConversationLabel,
              developmentLabel: debouncedDevelopmentLabel,
              competencyLabel: debouncedCompetencyLabel,
              membershipSetting,
              enableMeetingSummarization,
              defaultGoalState,
              defaultDecisionState,
            },
            __typename: "CreateOrUpdateOrganizationMutation",
          },
          __typename: "Mutation",
        },
      });
    }
  };

  const handleChangeMembership = (value: OrganizationMembershipSetting) => {
    setMembershipSetting(value);
  };

  const handleChangeEnableMeetingSummarization = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    setEnableMeetingSummarization(e.target.checked);
  };

  const handleChangeTranscribeMeetingsByDefault = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const transcribeMeetingsByDefault = e.target.checked;
    updateOrganization({
      variables: {
        organizationId: organization.id,
        transcribeMeetingsByDefault,
      },
      optimisticResponse: {
        createOrUpdateOrganization: {
          organization: {
            ...organization,
            transcribeMeetingsByDefault,
          },
          __typename: "CreateOrUpdateOrganizationMutation",
        },
        __typename: "Mutation",
      },
      onError: onNotificationErrorHandler(),
    });
  };

  const handleChangeQuarterStartMonth = (option: SelectOption<number>) => {
    const quarterStartMonth = option.value;
    updateOrganization({
      variables: {
        organizationId: organization.id,
        quarterStartMonth,
      },
      optimisticResponse: {
        createOrUpdateOrganization: {
          organization: {
            ...organization,
            quarterStartMonth,
          },
          __typename: "CreateOrUpdateOrganizationMutation",
        },
        __typename: "Mutation",
      },
      onError: onNotificationErrorHandler(),
    });
  };

  const handleChangeGoalState = (option: SelectOption<GoalState>) => {
    setDefaultGoalState(option.value);
  };

  const handleChangeDecisionState = (option: SelectOption<DecisionState>) => {
    setDefaultDecisionState(option.value);
  };

  const handleToggleFeatureFlags =
    (key: keyof OrgSettingsOrganizationFragmentFragment["featureFlags"]) =>
    (e: ChangeEvent<HTMLInputElement>) => {
      const { __typename, ...featureFlags } = organization.featureFlags;
      const newFeatureFlags = {
        ...featureFlags,
        [`${key}`]: e.target.checked,
      };
      updateOrganization({
        variables: {
          organizationId: organization.id,
          featureFlags: newFeatureFlags,
        },
        onError: onNotificationErrorHandler(),
        optimisticResponse: {
          createOrUpdateOrganization: {
            organization: {
              ...organization,
              featureFlags: {
                ...organization.featureFlags,
                ...newFeatureFlags,
              },
            },
            __typename: "CreateOrUpdateOrganizationMutation",
          },
          __typename: "Mutation",
        },
      });
    };

  useEffect(() => {
    saveChanges();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    debouncedName,
    debouncedGoalLabel,
    debouncedTeamLabel,
    debouncedOrgLabel,
    debouncedRecognitionLabel,
    debouncedReviewLabel,
    debouncedExpectationLabel,
    debouncedConversationLabel,
    debouncedDevelopmentLabel,
    debouncedCompetencyLabel,
    membershipSetting,
    enableMeetingSummarization,
    defaultGoalState,
    defaultDecisionState,
  ]);

  useEffect(() => {
    setName(organization.name);
    setGoalLabel(organization.goalLabel);
    setTeamLabel(organization.teamLabel);
    setOrgLabel(organization.orgLabel);
    setRecognitionLabel(organization.recognitionLabel);
    setReviewLabel(organization.reviewLabel);
    setExpectationLabel(organization.expectationLabel);
    setConversationLabel(organization.conversationLabel);
    setDevelopmentLabel(organization.developmentLabel);
    setCompetencyLabel(organization.competencyLabel);
    setMembershipSetting(organization.membershipSetting);
    setEnableMeetingSummarization(organization.enableMeetingSummarization);
    setDefaultGoalState(organization.defaultGoalState);
    setDefaultDecisionState(organization.defaultDecisionState);
  }, [organization]);

  const isFreeOrg = pricing.free === organization.pricingTier;
  return (
    <div aria-label="Org settings > settings">
      <Heading small title="Settings" />

      <SettingsContainer label="Organization name">
        <Input
          value={name}
          onChange={(e) => setName(e.target.value)}
          aria-label="Org settings: org name"
        />
      </SettingsContainer>

      <SettingsContainer label="Quarter">
        <div className="flex">
          <Select
            label="Starting month"
            value={organization.quarterStartMonth}
            options={months.map((month, i) => ({
              value: i + 1,
              label: month,
            }))}
            onChange={handleChangeQuarterStartMonth}
            aria-label="Org settings: quarter start month"
          />
        </div>
      </SettingsContainer>

      <SettingsContainer label="Features" className="border-t">
        <Label label="Enabled features" />
        <div className="flex flex-col w-full gap-0.5">
          <label className="text-sm flex items-center gap-2 cursor-pointer">
            <input
              type="checkbox"
              checked={!!organization.featureFlags.actionItems}
              onChange={handleToggleFeatureFlags("actionItems")}
            />
            {label("action item", { capitalize: true, pluralize: true })}
          </label>
          <label className="text-sm flex items-center gap-2 cursor-pointer">
            <input
              type="checkbox"
              checked={!!organization.featureFlags.decisions}
              onChange={handleToggleFeatureFlags("decisions")}
            />
            {label("decision", { capitalize: true, pluralize: true })}
          </label>
          <label className="text-sm flex items-center gap-2 cursor-pointer">
            <input
              type="checkbox"
              checked={!!organization.featureFlags.goals}
              onChange={handleToggleFeatureFlags("goals")}
            />
            {label("goal", { capitalize: true, pluralize: true })}
          </label>
          <label className="text-sm flex items-center gap-2 cursor-pointer">
            <input
              type="checkbox"
              checked={!!organization.featureFlags.documents}
              onChange={handleToggleFeatureFlags("documents")}
            />
            {label("document", { capitalize: true, pluralize: true })}
          </label>
          <label className="text-sm flex items-center gap-2 cursor-pointer">
            <input
              type="checkbox"
              checked={!!organization.featureFlags.recognitions}
              onChange={handleToggleFeatureFlags("recognitions")}
            />
            {label("recognition", { capitalize: true, pluralize: true })}
          </label>
          <label className="text-sm flex items-center gap-2 cursor-pointer">
            <input
              type="checkbox"
              checked={!!organization.featureFlags.feedbacks}
              onChange={handleToggleFeatureFlags("feedbacks")}
            />
            {label("Feedback", { capitalize: true })}
          </label>
          <label className="text-sm flex items-center gap-2 cursor-pointer">
            <input
              type="checkbox"
              checked={!!organization.featureFlags.kpis}
              onChange={handleToggleFeatureFlags("kpis")}
            />
            KPIs
          </label>
          <label className="text-sm flex items-center gap-2 cursor-pointer">
            <input
              type="checkbox"
              checked={!!organization.featureFlags.explorer}
              onChange={handleToggleFeatureFlags("explorer")}
            />
            Explorer
          </label>
          <label className="text-sm flex items-center gap-2 cursor-pointer">
            <input
              type="checkbox"
              checked={!!organization.featureFlags.homepageMyConnections}
              onChange={handleToggleFeatureFlags("homepageMyConnections")}
            />
            Home {">"} My Connections
          </label>
        </div>
      </SettingsContainer>

      <SettingsContainer label="Custom labels" className="border-t">
        <div className="flex flex-col w-full gap-4">
          <div className="flex gap-4 items-center">
            <Label className="w-36 mb-0" label="Goal label" />
            <Select
              className="w-96"
              options={goalLabelOptions.map((value) => ({
                value,
                label: capitalize(value),
              }))}
              value={goalLabel}
              onChange={(opt) => setGoalLabel(opt.value)}
              aria-label="Org settings: goal label"
            />
          </div>
          <div className="flex gap-4 items-center">
            <Label className="w-36 mb-0" label="Team label" />
            <Select
              className="w-96"
              options={teamLabelOptions.map((value) => ({
                value,
                label: capitalize(value),
              }))}
              value={teamLabel}
              onChange={(opt) => setTeamLabel(opt.value)}
              aria-label="Org settings: team label"
            />
          </div>
          <div className="flex gap-4 items-center">
            <Label className="w-36 mb-0" label="Organization label" />
            <Select
              className="w-96"
              options={orgLabelOptions.map((value) => ({
                value,
                label: capitalize(value),
              }))}
              value={orgLabel}
              onChange={(opt) => setOrgLabel(opt.value)}
              aria-label="Org settings: organization label"
            />
          </div>
          <div className="flex gap-4 items-center">
            <Label className="w-36 mb-0" label="Recognition label" />
            <Select
              className="w-96"
              options={recognitionLabelOptions.map((value) => ({
                value,
                label: capitalize(value),
              }))}
              value={recognitionLabel}
              onChange={(opt) => setRecognitionLabel(opt.value)}
              aria-label="Org settings: recognition label"
            />
          </div>
          <div className="flex gap-4 items-center">
            <Label className="w-36 mb-0" label="Review label" />
            <Select
              className="w-96"
              options={reviewLabelOptions.map((value) => ({
                value,
                label: capitalize(value),
              }))}
              value={reviewLabel}
              onChange={(opt) => setReviewLabel(opt.value)}
              aria-label="Org settings: review label"
            />
          </div>
          <div className="flex gap-4 items-center">
            <Label className="w-36 mb-0" label="Expectation label" />
            <Select
              className="w-96"
              options={expectationLabelOptions.map((value) => ({
                value,
                label: capitalize(value),
              }))}
              value={expectationLabel}
              onChange={(opt) => setExpectationLabel(opt.value)}
              aria-label="Org settings: expectation label"
            />
          </div>
          <div className="flex gap-4 items-center">
            <Label className="w-36 mb-0" label="Conversation label" />
            <Select
              className="w-96"
              options={conversationLabelOptions.map((value) => ({
                value,
                label: capitalize(value),
              }))}
              value={conversationLabel}
              onChange={(opt) => setConversationLabel(opt.value)}
              aria-label="Org settings: conversation label"
            />
          </div>
          <div className="flex gap-4 items-center">
            <Label className="w-36 mb-0" label="Development label" />
            <Select
              className="w-96"
              options={developmentLabelOptions.map((value) => ({
                value,
                label: capitalize(value),
              }))}
              value={developmentLabel}
              onChange={(opt) => setDevelopmentLabel(opt.value)}
              aria-label="Org settings: development label"
            />
          </div>
          <div className="flex gap-4 items-center">
            <Label className="w-36 mb-0" label="Competency label" />
            <Select
              className="w-96"
              options={competencyLabelOptions.map((value) => ({
                value,
                label: capitalize(value),
              }))}
              value={competencyLabel}
              onChange={(opt) => setCompetencyLabel(opt.value)}
              aria-label="Org settings: competency label"
            />
          </div>
        </div>
      </SettingsContainer>

      <SettingsContainer label="Membership" className="border-t">
        <div>
          <label className="text-sm flex items-center py-1 cursor-pointer">
            <input
              type="radio"
              name="membership"
              value={OrganizationMembershipSetting.AnyoneWithValidDomain}
              className="mr-2"
              checked={membershipSetting === "anyone_with_valid_domain"}
              disabled={isFreeOrg}
              onChange={() =>
                handleChangeMembership(
                  OrganizationMembershipSetting.AnyoneWithValidDomain
                )
              }
              aria-label="Org settings: membership anyone_with_valid_domain"
            />{" "}
            Allow people using{" "}
            {(organization.domains || [])
              .map((email) => `@${email}`)
              .join(", ")}{" "}
            to join this organization
          </label>
          <label className="text-sm flex items-center py-1 cursor-pointer">
            <input
              type="radio"
              name="membership"
              value={OrganizationMembershipSetting.ManualInvitesOnly}
              onChange={() =>
                handleChangeMembership(
                  OrganizationMembershipSetting.ManualInvitesOnly
                )
              }
              className="mr-2"
              disabled={isFreeOrg}
              checked={membershipSetting === "manual_invites_only"}
              aria-label="Org settings: membership manual_invites_only"
            />{" "}
            Manual invitation only
          </label>
          {isFreeOrg && (
            <div className="mt-2 text-xs bg-amber-50 rounded border border-amber-200 px-2 py-1 text-amber-900">
              Only organizations under a paid plan can change this setting.
            </div>
          )}
        </div>
      </SettingsContainer>
      <SettingsContainer label="Meetings" className="border-t">
        <label className="mb-2 text-left flex gap-2 items-center">
          <input
            aria-label="Enable meeting transcription input"
            type="checkbox"
            checked={!!organization.transcribeMeetingsByDefault}
            onChange={handleChangeTranscribeMeetingsByDefault}
          />
          Automatically add Topicflow notetaker to meetings.
        </label>
        <label className="text-left flex gap-2 items-center">
          <div>
            <input
              aria-label="Enable meeting summarization input"
              type="checkbox"
              checked={enableMeetingSummarization}
              onChange={handleChangeEnableMeetingSummarization}
              disabled={organization.effectivePricingTier !== "ultimate"}
            />
          </div>
          <div className="flex-1 ">
            Enable AI Meeting Summarization{" "}
            <span className="text-gray-500">(sent to 3rd party/OpenAI)</span>
            {organization.effectivePricingTier !== "ultimate" && (
              <span>
                {" "}
                (
                <BillingDialogCTA className="text-blue-link underline">
                  Upgrade
                </BillingDialogCTA>{" "}
                to enable)
              </span>
            )}
          </div>
        </label>
      </SettingsContainer>
      <SettingsContainer label="Artifacts" className="border-t">
        <div className="flex flex-col w-full gap-6">
          <div>
            <InputLabel label="Default goal state" className="mb-1 " />
            <Select
              options={[
                {
                  value: GoalState.Draft,
                  label: "Draft",
                },
                {
                  value: GoalState.Open,
                  label: "In progress",
                },
                {
                  value: GoalState.Closed,
                  label: `Closed`,
                },
              ]}
              value={defaultGoalState}
              onChange={handleChangeGoalState}
            >
              {({ selected, setReferenceElement, disabled }) => (
                <Listbox.Button
                  className={classNames(
                    listBoxButtonClassName({ disabled }),
                    inputBorderClassName,
                    "px-4 py-2"
                  )}
                  aria-label="Goal state dropdown button"
                  ref={setReferenceElement}
                >
                  <div className="flex items-center gap-1.5">
                    {assertNonNull(selected?.label)}
                  </div>
                  {!disabled && (
                    <SelectorIcon
                      className={`h-4 w-4 text-gray-400 pointer-events-none`}
                    />
                  )}
                </Listbox.Button>
              )}
            </Select>
          </div>
          <div>
            <InputLabel label="Default decision state" className="mb-1 " />
            <Select
              options={[
                {
                  value: DecisionState.Draft,
                  label: "Draft",
                },
                {
                  value: DecisionState.Decided,
                  label: "Decided",
                },
              ]}
              value={defaultDecisionState}
              onChange={handleChangeDecisionState}
            >
              {({ selected, setReferenceElement, disabled }) => (
                <Listbox.Button
                  className={classNames(
                    listBoxButtonClassName({ disabled }),
                    inputBorderClassName,
                    "px-4 py-2"
                  )}
                  aria-label="Decision state dropdown button"
                  ref={setReferenceElement}
                >
                  <div className="flex items-center gap-1.5">
                    {assertNonNull(selected?.label)}
                  </div>
                  {!disabled && (
                    <SelectorIcon
                      className={`h-4 w-4 text-gray-400 pointer-events-none`}
                    />
                  )}
                </Listbox.Button>
              )}
            </Select>
          </div>
        </div>
      </SettingsContainer>
    </div>
  );
};

export default Settings;
