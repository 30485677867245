import { gql } from "@apollo/client";

export default gql`
  mutation updateArtifactTitleMutation(
    $artifactId: Int!
    $title: String
    $additionalFields: AdditionalArtifactInput
  ) {
    createOrUpdateArtifact(
      artifactId: $artifactId
      title: $title
      additionalFields: $additionalFields
    ) {
      artifact {
        id
        title
        ... on DecisionArtifactNode {
          decision
        }
      }
    }
  }
`;
