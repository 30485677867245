import {
  GetCommentsQuery,
  GetCommentsQueryVariables,
} from "types/graphql-schema";

import getCommentsQuery from "@apps/comments/graphql/get-comments-query";
import cache from "@cache/cache";

const removeCommentFromCache = ({
  commentId,
  topicId,
  artifactId,
}: {
  commentId: number;
  topicId?: number;
  artifactId?: number;
}) => {
  const commentCacheId = cache.identify({
    id: commentId,
    __typename: "CommentNode",
  });
  if (commentCacheId) {
    cache.evict({ id: commentCacheId });
    cache.gc();
  }
  const existingData = cache.readQuery<
    GetCommentsQuery,
    GetCommentsQueryVariables
  >({
    query: getCommentsQuery,
    variables: { topicId, artifactId },
  });
  if (existingData) {
    const newEdges =
      existingData.comments?.edges.filter(
        (edge) => edge?.node?.id !== commentId
      ) || [];
    cache.writeQuery({
      query: getCommentsQuery,
      variables: { topicId, artifactId },
      data: {
        ...existingData,
        comments: {
          ...existingData.comments,
          totalCount: newEdges.length,
          edges: newEdges,
        },
      },
    });
  }
};

export default removeCommentFromCache;
