import { gql } from "@apollo/client";

export default gql`
  query getAdoptionReport(
    $organizationId: Int!
    $attributes: [AdoptionReportAttribute]!
    $metrics: [AdoptionReportMetric]!
    $filters: [ReportFilterInput]!
  ) {
    adoptionReport(
      organizationId: $organizationId
      attributes: $attributes
      metrics: $metrics
      filters: $filters
    ) {
      totalValues
      data {
        attributeValues
        metricValues
      }
    }
  }
`;
