import { gql } from "@apollo/client";

const createAsanaArtifactMutation = gql`
  mutation createAsanaArtifactMutation(
    $additionalFields: AdditionalArtifactInput
    $title: String
    $meetingId: Int
    $relatedTopicId: Int
    $organizationId: Int
    $relatedArtifactId: Int
  ) {
    createOrUpdateArtifact(
      title: $title
      meetingId: $meetingId
      artifactType: asana_task
      topicId: $relatedTopicId
      organizationId: $organizationId
      relatedArtifactId: $relatedArtifactId
      additionalFields: $additionalFields
    ) {
      artifact {
        ... on AsanaTaskArtifactNode {
          id
          title
          isSynced
          completedAt
          dueDate
          assignee {
            id
            name
          }
        }
      }
    }
  }
`;

export default createAsanaArtifactMutation;
