import { useEffect } from "react";
import { Route, Switch, useLocation } from "react-router-dom";

import Login from "@apps/login";

import { apolloPersistCacheKey } from "../../helpers/constants";

const LoggedOutRoutes: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    localStorage?.removeItem(apolloPersistCacheKey);
  }, []);

  return (
    <>
      <Switch location={location}>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/register">
          <Login isRegisterPage />
        </Route>
        {/* Not found */}
        <Route path="*">
          <Login />
        </Route>
      </Switch>
    </>
  );
};

export default LoggedOutRoutes;
