import DirectorySyncItem from "@apps/account/components/directorysync-item";
import Heading from "@components/heading/heading";

const DirectorySync = ({ me }: { me: any }) => {
  const directorySyncers = me.organizations.edges
    .map((orgEdge: { node: { name: any; directorySync: any } }) => {
      return {
        orgName: orgEdge.node.name,
        directorySyncer: orgEdge.node.directorySync,
      };
    })
    .filter(
      (directorySyncData: { directorySyncer: any }) =>
        directorySyncData.directorySyncer
    );

  return (
    <div>
      <Heading small title="Directory sync" />
      <div className="sm:w-3/4">
        {directorySyncers.length > 0 ? (
          directorySyncers.map(
            ({
              orgName,
              directorySyncer,
            }: {
              orgName: any;
              directorySyncer: any;
            }) => (
              <div key={directorySyncer.id}>
                <DirectorySyncItem
                  orgName={orgName}
                  directorySyncer={directorySyncer}
                />
              </div>
            )
          )
        ) : (
          <p>
            To configure automatic user creation and updates for your
            organization, please{" "}
            <a className="text-blue-500" href="mailto:hello@topicflow.com">
              contact us at hello@topicflow.com
            </a>
          </p>
        )}
      </div>
    </div>
  );
};

export default DirectorySync;
