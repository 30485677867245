import { ChartSquareBarIcon } from "@heroicons/react/outline";

import { classNames } from "@helpers/css";

const KeyResultIcon = ({ className = "" }: { className?: string }) => (
  <ChartSquareBarIcon
    className={classNames("h-4 w-4 text-gray-400 shrink-0", className)}
  />
);

export default KeyResultIcon;
