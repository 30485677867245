import { gql } from "@apollo/client";

export default gql`
  query getKpiUpdateUrlQuery($kpiId: Int!) {
    kpi(kpiId: $kpiId) {
      id
      updateUrl
      currentMeasurement {
        measurement
      }
    }
  }
`;
