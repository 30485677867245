import { useQuery } from "@apollo/client";
import { Route, Switch, useParams, useRouteMatch } from "react-router-dom";
import {
  GetMeetingGroupNewPageQueryQuery,
  GetMeetingGroupNewPageQueryQueryVariables,
} from "types/graphql-schema";

import ArtifactSidebarRoute from "@apps/artifact-sidebar/artifact-sidebar-route";
import { artifactTypePaths } from "@apps/main/routes";
import TopicSidebar from "@apps/topic-sidebar/topic-sidebar";
import { currentUserVar } from "@cache/cache";
import Container from "@components/container/container";
import GraphqlError from "@components/error/graphql-error";
import Layout from "@components/layout/layout";
import Loading from "@components/loading/loading";
import { onNotificationErrorHandler } from "@components/use-error/use-error";

import AllMeetingsTab from "./components/meetings-tab";
import getMeetingGroupQuery from "./graphql/get-meeting-group-query";

const MeetingPage = () => {
  const { path } = useRouteMatch();
  let { meetingGroupId } = useParams<{
    meetingGroupId: any;
  }>();
  const currentUser = currentUserVar();

  meetingGroupId = parseInt(meetingGroupId);

  const { loading, error, data } = useQuery<
    GetMeetingGroupNewPageQueryQuery,
    GetMeetingGroupNewPageQueryQueryVariables
  >(getMeetingGroupQuery, {
    fetchPolicy: "cache-first",
    nextFetchPolicy: "cache-first",
    variables: {
      meetingGroupId: meetingGroupId,
    },
    onError: onNotificationErrorHandler(),
  });

  // Renders
  if (loading || !data) {
    return (
      <Container>
        <Loading />
      </Container>
    );
  }
  if ((error && !data) || !data.meetingGroup) {
    return (
      <Container>
        <GraphqlError
          error={new Error("Meeting group could not be loaded")}
          whatDidNotWork="We can't show you this meeting right now."
          descriptionList={[
            "The meeting might have been deleted by you or another participant.",
            "The meeting is a draft meeting and is only accessible by its creator.",
            `Or, make sure you're logged in using the email address matching the meeting invite. You're currently logged in as ${currentUser.emails.join(
              ", "
            )}.`,
            "Or, an unexpected error might have occurred on our side. We have been notified. Try again by refreshing the page.",
          ]}
        />
      </Container>
    );
  }

  return (
    <Layout aria-label="Meeting page" className="bg-white">
      <Switch>
        <Route path={[`${path}/:meetingId(\\d+)`, `${path}`]}>
          <AllMeetingsTab meetingGroup={data.meetingGroup} />
        </Route>
      </Switch>

      <Route
        path={`${path}/:meetingId(\\d+)/(${artifactTypePaths})/:artifactId`}
      >
        <ArtifactSidebarRoute />
      </Route>
      <Route path={`${path}/:meetingId(\\d+)/topic/:topicId`}>
        <TopicSidebar />
      </Route>
    </Layout>
  );
};

export default MeetingPage;
