import { gql } from "@apollo/client";

export default gql`
  query getComments($topicId: Int, $artifactId: Int) {
    me {
      id
      avatar
      name
      avatar
    }
    comments(
      topicId: $topicId
      artifactId: $artifactId
      isInlineComment: false
    ) {
      totalCount
      edges {
        node {
          id
          creator {
            id
            name
            avatar
          }
          created
          comment
        }
      }
    }
  }
`;
