import { gql } from "@apollo/client";

export default gql`
  query getExtensionMeetingsQuery(
    $startToday: DateTime!
    $endToday: DateTime!
  ) {
    meetings(
      startDatetime_Gte: $startToday
      startDatetime_Lte: $endToday
      ignored: false
    ) {
      edges {
        node {
          id
          meetingGroupId
          title
          isRecurring
          startDatetime
          endDatetime
        }
      }
    }
  }
`;
