import { useParams } from "react-router-dom";

const usePkParams = <T extends { [K in keyof T]?: string | undefined }>(
  key: keyof T
) => {
  const params = useParams<T>();
  if (params[key]) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return parseInt(params[key]!);
  }
  return NaN;
};

export default usePkParams;
