import { gql } from "@apollo/client";

export default gql`
  mutation deleteExplorerBookmarkMutation($explorerBookmarkId: Int!) {
    deleteExplorerBookmark(explorerBookmarkId: $explorerBookmarkId) {
      user {
        id
        explorerBookmarks {
          edges {
            node {
              id
            }
          }
        }
      }
    }
  }
`;
