import { ReactNode } from "react";

import { classNames } from "@helpers/css";

const Loading = ({
  size = "8",
  children = null,
  mini = false,
  color = "black",
  className = "",
  ...props
}: {
  size?: number | string;
  children?: ReactNode[] | null | string;
  mini?: boolean;
  color?: string;
  className?: string;
}) =>
  mini ? (
    <div
      aria-label="Loading spinner"
      className={classNames(
        `loader-${color} ease-linear rounded-full h-${size} w-${size} shrink-0`,
        className
      )}
      data-testid="loading"
      {...props}
    />
  ) : (
    <div className={className} aria-label="Loading spinner" {...props}>
      <div className="flex justify-center items-center" data-testid="loading">
        <div
          className={`loader-${color} ease-linear rounded-full h-${size} w-${size} shrink-0`}
        />
      </div>
      {children && (
        <div className="flex justify-center items-center text-gray-600 mt-1.5">
          {children}
        </div>
      )}
    </div>
  );

export default Loading;
