import { useState } from "react";

import Button, { buttonTheme } from "@components/button/button";
import Modal from "@components/modal/modal";
import ModalTitle from "@components/modal/modal-title";

import { ExplorerFilterType } from "../helpers";
import ExplorerFilters from "./filters";

const FilterModal = ({
  filters,
  onClose,
  onChangeFilters,
  onClickBookmark,
}: {
  filters: ExplorerFilterType;
  onClose: () => void;
  onChangeFilters: (filters: ExplorerFilterType) => void;
  onClickBookmark: (filters: ExplorerFilterType) => void;
}) => {
  const [localFilters, setLocalFilters] = useState(filters);

  const handleChangeFilters = (updatedFilters: ExplorerFilterType) => {
    setLocalFilters({ ...localFilters, ...updatedFilters });
  };

  const handleSaveFilters = () => {
    onChangeFilters(localFilters);
  };

  return (
    <Modal open onClose={onClose} dialogContainerClassName="items-start">
      <div className="p-4">
        <ModalTitle onClose={onClose}>Explorer filters</ModalTitle>
        <div className="flex flex-col mt-4 gap-4 relative">
          <ExplorerFilters
            filters={localFilters}
            onChangeFilters={handleChangeFilters}
            onClickBookmark={onClickBookmark}
            hideExportButton
          />
        </div>
        <div className="flex gap-4 mt-4">
          <Button theme={buttonTheme.primary} onClick={handleSaveFilters}>
            Search
          </Button>
          <Button onClick={onClose}>Cancel</Button>
        </div>
      </div>
    </Modal>
  );
};

export default FilterModal;
