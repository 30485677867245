import { useCallback, useMemo, useState } from "react";
import {
  AttendeeRole,
  AttendeeStatus,
  DashboardInsightFragment,
} from "types/graphql-schema";
import { BasicUser } from "types/topicflow";

import MeetingDialog from "@apps/meeting-dialog/meeting-dialog";
import { currentUserVar } from "@cache/cache";
import { assertEdgesNonNull, assertNonNull } from "@helpers/helpers";

import { InsightButton, InsightLayout } from "../insight";

const CreateComplianceProgramMeetingInsight = ({
  insight,
  selectedUser,
  onDismissInsight,
}: {
  insight: DashboardInsightFragment;
  selectedUser: BasicUser;
  onDismissInsight: () => void;
}) => {
  const currentUser = currentUserVar();
  const [showCreateMeetingModal, setShowCreateMeetingModal] = useState(false);

  const handleClickCreateMeeting = useCallback(() => {
    setShowCreateMeetingModal(true);
  }, []);
  const handleCloseCreateMeeting = useCallback(() => {
    setShowCreateMeetingModal(false);
  }, []);

  const manager = useMemo(() => assertNonNull(insight.manager), [insight]);
  const report = useMemo(() => assertNonNull(insight.report), [insight]);
  const program = useMemo(
    () => assertNonNull(insight.complianceProgram),
    [insight]
  );
  const requiredTemplate = assertNonNull(
    program.requiredTopicTemplates.edges[0]?.node
  );
  const matchingUnscheduledOneonones = useMemo(
    () =>
      assertEdgesNonNull(
        insight.unscheduledMatchingOneononesForComplianceProgram
      ),
    [insight]
  );

  return (
    <InsightLayout
      user={report}
      onDismissInsight={onDismissInsight}
      isRequired={!!insight.complianceProgram?.id}
    >
      <div className="flex-1">
        <div>
          {currentUser.id === selectedUser.id
            ? "You are required to "
            : `${selectedUser.name} is required to `}
          have a meeting with {report.firstName || report.name} using the{" "}
          {requiredTemplate.title} template.
        </div>
      </div>
      {matchingUnscheduledOneonones.length === 0 && (
        <div>
          {showCreateMeetingModal && (
            <MeetingDialog
              onClose={handleCloseCreateMeeting}
              formOptions={{
                isFormalOneonone: true,
                title: `${manager.firstName} <> ${report.firstName} ${program.title}`,
                templateId: requiredTemplate.id,
                attendees: [
                  {
                    ...report,
                    role: AttendeeRole.Required,
                    participantStatus: AttendeeStatus.NotResponded,
                  },
                  {
                    ...manager,
                    role: AttendeeRole.Required,
                    participantStatus: AttendeeStatus.NotResponded,
                  },
                ],
              }}
            />
          )}
          <InsightButton onClick={handleClickCreateMeeting}>
            Create Meeting
          </InsightButton>
        </div>
      )}
      {matchingUnscheduledOneonones.length > 0 && (
        <div>
          <InsightButton
            url={`/meeting/${matchingUnscheduledOneonones[0].meetingGroupId}/${matchingUnscheduledOneonones[0].id}?action=edit-meeting`}
          >
            Schedule Existing Meeting
          </InsightButton>
        </div>
      )}
    </InsightLayout>
  );
};

export default CreateComplianceProgramMeetingInsight;
