import { Cache, InMemoryCache } from "@apollo/client";
import { makeVar } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { compact } from "lodash";
import uniqBy from "lodash/uniqBy";
import { ReactElement } from "react";
import {
  GetLoggedInUserQuery,
  LoggedInUserOrgFragment,
} from "types/graphql-schema";
import { v4 as uuidv4 } from "uuid";

import { graphqlNone } from "@helpers/constants";
import { getUrl } from "@helpers/helpers";

export const ignoredCacheKeys = [
  "SuggestedArtifactNode",
  "MeetingWithRelevantSectionsNode",
  "SummarizedMeasurements",
];
export const artifactNodeTypes = [
  "BaseArtifactNode",
  "ActionItemArtifactNode",
  "FeedbackArtifactNode",
  "DecisionArtifactNode",
  "GoalArtifactNode",
  "RatingArtifactNode",
  "KPIArtifactNode",
  "DocumentArtifactNode",
  "RecognitionArtifactNode",
];
export type allArtifactTypes =
  | "BaseArtifactNode"
  | "ActionItemArtifactNode"
  | "FeedbackArtifactNode"
  | "DecisionArtifactNode"
  | "GoalArtifactNode"
  | "RatingArtifactNode"
  | "KPIArtifactNode"
  | "DocumentArtifactNode"
  | "RecognitionArtifactNode";

export const urlPreviewCardNodeTypes = [
  "InfoCardInterface",
  "JiraIssueNode",
  "SalesforceOpportunityNode",
  "ClickupTaskNode",
  "GithubIssueNode",
  "HubspotDealNode",
];

export type CurrentUserType = GetLoggedInUserQuery["me"];
export const isAdminVar = makeVar(false);
export const clientUuidVar = makeVar(uuidv4());
export const currentUserVar = makeVar<NonNullable<CurrentUserType>>({
  __typename: "UserNode",
  id: graphqlNone,
  email: "",
  emails: [],
  name: "",
  firstName: "",
  lastName: "",
  favouriteArtifacts: { __typename: "ArtifactConnection", edges: [] },
  organizations: { __typename: "OrganizationNodeConnection", edges: [] },
  paidFeatures: { __typename: "PaidFeaturesNode" },
  directReports: { __typename: "UserNodeConnection", edges: [] },
  managers: { __typename: "UserNodeConnection", edges: [] },
});
export const currentOrganizationVar = makeVar<LoggedInUserOrgFragment>({
  __typename: "OrganizationNode",
  id: graphqlNone,
  name: "",
  goalLabel: "",
  keyResultLabel: "",
  teamLabel: "",
  orgLabel: "",
  recognitionLabel: "",
  oneononeLabel: "",
  reviewLabel: "",
  expectationLabel: "",
  conversationLabel: "",
  developmentLabel: "",
  competencyLabel: "",
  quarterStartMonth: 1,
  enableMeetingSummarization: false,
  actionItemStates: [],
  featureFlags: { __typename: "OrganizationFeatureFlags" },
});
export const currentTiptapJWTVar = makeVar<string>("");

export const appVersionVar = makeVar(null);
export const checkinPopoverGoalIdVar = makeVar<null | number>(null);
export const editorVersionVar = makeVar({
  oldVersion: null,
  newVersion: null,
});

export enum NotificationType {
  success = "success",
  error = "error",
}

type Notication = {
  type?: NotificationType;
  title?: string;
  description?: string | ReactElement;
  timeout?: number | null;
};

/**
 * Trigger a new notification
 * Please use errorNotificationVar or successNotificationVar instead.
 */
export const notificationVar = makeVar<Notication | null>(null);
export const errorNotificationVar = (notification: Notication) =>
  notificationVar({
    type: NotificationType.error,
    ...notification,
  });
export const successNotificationVar = (notification: Notication) =>
  notificationVar({
    type: NotificationType.success,
    ...notification,
  });
export const artifactsVar = makeVar(new Set());
export const usersVar = makeVar(new Set());

/** Cache Policies */
const defaultMerge = (existing: any, incoming: any, options: any) => {
  // no existing data in cache, so we return incoming data
  if (!existing || !existing.edges) {
    return incoming;
  }

  // if we are paginating, then merge the data, otherwise use incoming
  const edges = options.variables?.merge
    ? uniqBy(
        compact(existing.edges.concat(incoming.edges)),
        (edge: any) => edge?.node?.__ref
      )
    : incoming.edges;
  return {
    ...incoming,
    pageInfo: incoming.pageInfo,
    edges,
  };
};

const canReadArtifactRef = (artifactRef: any, canRead: any, readField: any) => {
  if (!canRead(artifactRef)) {
    return null;
  }
  // if user has not permission, return null
  const canReadPermission = readField("canRead", artifactRef);
  if (canReadPermission && canReadPermission.permission === false) {
    return null;
  }
  return artifactRef;
};

const artifactMerge: {
  keyArgs: string[];
  merge: (existing: any, incoming: any, options: any) => void;
  read: (existing: any, options: any) => void;
} = {
  // merge only when the keyArgs are the same
  keyArgs: [
    // NEVER UNCOMMENT THE LINE BELOW
    // WE USE IT TO REFRESH ITEMS IN AN APOLLO QUERY CACHE
    // BY MERGING THEM INTO THE CACHE DATA
    // "idsToMergeInApolloCache",
    "ids",
    "actionItemAssignee",
    "actionItemIsComplete",
    "actionItemState",
    "actionItemCompletedSincePreviousMeeting",
    "assignedToMembersOfOrganizationId",
    "actionItemAssignedToMembersOfTeam",
    "artifactType",
    "artifactTypes",
    "goalOwners",
    "goalContributors",
    "goalInvolvingUsers",
    "goalScope",
    "goalScopes",
    "goalState",
    "goalStates",
    "goalStatus",
    "isStale",
    "goalVisibility",
    "orderBy",
    "owners",
    "search",
    "teamGoalsOfUser",
    "forUserId",
    "organizationId",
    "feedbackRecipient",
    "feedbackRecipients",
    "feedbackSender",
    "feedbackSenders",
    "feedbackState",
    "feedbackStates",
    "recognitionRecipient",
    "recognitionRecipients",
    "recognitionCoreValue",
    "createdBy",
    "createdByIds",
    "createdInMeetingId",
    "actionItemDueBetweenDates",
    "createdBetweenDates",
    "goalDueBetweenDates",
    "goalsCompletedInTheLastXDays",
    "goalParentIds",
    "recognitionReceivedByMembersOfTeam",
  ],
  merge: (
    existing: any,
    incoming: any,
    { args, variables }: { args: any; variables: any }
  ) => {
    // console.log("merge", { incoming });
    // no existing data in cache, so we return incoming data
    if (!existing || !existing.edges) {
      return incoming;
    }
    // we merge the data
    const edges = variables?.merge
      ? uniqBy(
          existing.edges
            .concat(incoming.edges)
            .filter(({ node }: { node: any }) => !!node),
          (edge: any) => edge.node.__ref
        )
      : incoming.edges;
    return {
      ...incoming,
      pageInfo: incoming.pageInfo,
      edges,
    };
  },
  read: (
    existingArtifacts = { edges: [] },
    { canRead, readField }: { canRead: Function; readField: Function }
  ) => {
    // we remove any dangling references
    // https://www.apollographql.com/docs/react/caching/garbage-collection/#dangling-references
    const filteredArtifacts =
      existingArtifacts.edges?.filter((edge: any) =>
        canReadArtifactRef(edge.node, canRead, readField)
      ) || [];
    return existingArtifacts
      ? { ...existingArtifacts, edges: filteredArtifacts }
      : { edges: [] };
  },
};

const meetingsMerge = {
  // merge only when the keyArgs are the same
  keyArgs: [
    "meetingGroupId",
    "templateId",
    "fromMeetingId",
    "startDatetime_Gte",
    "startDatetime_Lte",
    "startDatetime_Gt",
    "startDatetime_Lt",
    "endDatetime_Gte",
    "endDatetime_Lte",
    "endDatetime_Gt",
    "endDatetime_Lt",
    "participants",
    "participantCount",
    "status",
    "status_In",
    "ignored",
    "draft",
    "search",
    "isFormalOneonone",
  ],
  merge: defaultMerge,
};

const redirectCacheRead =
  (attributeName: any, nodeName: any) =>
  (_: any, { args, toReference }: any) => {
    return toReference({
      __typename: nodeName,
      id: args[`${attributeName}`],
    });
  };

const cache = new InMemoryCache({
  possibleTypes: {
    ArtifactInterface: artifactNodeTypes,
    SearchUnion: [
      "UserNode",
      "TopicNode",
      "MeetingWithRelevantSectionsNode",
      "CommentNode",
      "BaseArtifactNode",
      "JiraIssueNode",
      "GithubIssueNode",
      "SalesforceOpportunityNode",
      "ClickupTaskNode",
      "HubspotDealNode",
    ],
  },
  typePolicies: {
    Query: {
      fields: {
        feed: {
          keyArgs: false,
          merge(existing, incoming) {
            if (!existing) {
              return incoming;
            }
            return [...existing, ...incoming];
          },
        },
        meeting: {
          keyArgs: ["meetingId", "googleMeetUrl"],
          merge: (existing, incoming, { mergeObjects }) => {
            return mergeObjects(existing, incoming);
          },
          read: redirectCacheRead("meetingId", "MeetingNode"),
        },
        pendingFeedbackRequests: {
          keyArgs: ["creatorId", "createdByReportsOfUserId"],
          merge: (existing, incoming, { mergeObjects }) => {
            return mergeObjects(existing, incoming);
          },
        },
        meetings: meetingsMerge,
        search: {
          // merge only when the keyArgs are the same
          keyArgs: ["options", "searchTerm"],
          merge: defaultMerge,
        },
        artifacts: artifactMerge,
        goalsForUser: artifactMerge,
        topics: {
          // merge only when the keyArgs are the same
          keyArgs: [
            "hasArtifacts",
            "hasActionItems",
            "hasDecisions",
            "meetingInMeetingGroupId",
          ],
          merge: defaultMerge,
        },
        meetingsByManagerReport: {
          // merge only when the keyArgs are the same
          keyArgs: ["organizationId", "personId", "templateId"],
          merge: defaultMerge,
        },
        topicTemplates: {
          // merge only when the keyArgs are the same
          keyArgs: [
            "adhocTemplates",
            "publicTemplate",
            "globalTemplate",
            "includesRatingId",
            "organizationId",
          ],
          merge: defaultMerge,
        },
        artifact: {
          // this helps query artifact(artifactId: x) to find matching node in cache
          read: function (
            existingRef: any,
            { args, toReference, canRead, readField }: any
          ) {
            // if artifact has existing ref, we return it if the user can read it
            if (existingRef) {
              return canReadArtifactRef(existingRef, canRead, readField);
            }
            // Otherwise we look in the cache to see if we have a matching artifact already cached.
            const match = artifactNodeTypes
              .map((nodeType) => {
                const ref = toReference({
                  __typename: nodeType,
                  id: args.artifactId,
                });
                return ref;
              })
              .find((ref) => {
                if (!canRead(ref)) return false;
                return canReadArtifactRef(ref, canRead, readField);
              });
            return match;
          },
        },
        user: {
          read: redirectCacheRead("userId", "UserNode"),
        },
        comment: {
          read: redirectCacheRead("commentId", "CommentNode"),
        },
        workflow: {
          read: redirectCacheRead("workflowId", "WorkflowNode"),
        },
        meetingGroup: {
          read: redirectCacheRead("meetingGroupId", "MeetingGroupNode"),
        },
        users: {
          keyArgs: [
            "search",
            "isActive",
            "isActiveOrInvited",
            "excludeIds",
            "organization",
          ],
          merge: defaultMerge,
        },
        compliancePrograms: {
          keyArgs: [
            "assessmentType",
            "state",
            "search",
            "applicableUser",
            "organizationId",
            "dueDate_Lte",
            "dueDate_Gte",
          ],
          merge: defaultMerge,
        },
        assessmentDeliveries: {
          keyArgs: ["state", "targetId", "creatorId", "organizationId"],
          merge: defaultMerge,
        },
        assessments: {
          keyArgs: ["state", "targetId", "responderId", "organizationId"],
          merge: defaultMerge,
        },
        assessmentsOpenForNominations: {
          keyArgs: ["targetId", "complianceProgramId"],
          merge: defaultMerge,
        },
        unmetPerformanceAssessmentCompliancePrograms: {
          keyArgs: ["organizationId"],
          merge: defaultMerge,
        },
        unmetPeerAssessmentCompliancePrograms: {
          keyArgs: ["organizationId"],
          merge: defaultMerge,
        },
        unmetManagerEffectivenessCompliancePrograms: {
          keyArgs: ["organizationId"],
          merge: defaultMerge,
        },
      },
    },
    MeetingGroupNode: {
      fields: {
        artifacts: artifactMerge,
        goals: artifactMerge,
      },
    },
    FlowNode: {
      fields: {
        meetings: meetingsMerge,
      },
    },
    UserNode: {
      merge: (existing, incoming, { isReference }) => {
        const users = usersVar();
        const id = isReference(incoming)
          ? incoming.__ref
          : cache.identify(incoming);
        users.add(id);
        usersVar(users);
        return incoming;
      },
      fields: {
        organizationsAvailableToJoin: {
          merge(existing, incoming) {
            return incoming;
          },
        },
        url: {
          read(_, { readField }) {
            if (readField("id"))
              return getUrl({
                userId: readField("id"),
              });
            return null;
          },
        },
        managers: {
          keyArgs: ["search", "isActive", "isActiveOrInvited"],
        },
        directReports: {
          keyArgs: ["search", "isActive", "isActiveOrInvited"],
        },
      },
    },
    ComplianceProgramNode: {
      fields: {
        nominationsForUser: {
          keyArgs: ["userId"],
        },
        assessments: {
          keyArgs: ["state", "assessmentType", "selfAssessment"],
          merge: defaultMerge,
        },
      },
    },
    FavouritesGroupNode: {
      fields: {
        users: {
          keyArgs: ["search", "isActive", "isActiveOrInvited"],
        },
      },
    },
    ChatSessionNode: {
      fields: {
        messages: {
          merge(existing, incoming) {
            return incoming;
          },
        },
      },
    },
    HubspotDealNode: {
      keyFields: ["url"],
    },
    GithubIssueNode: {
      keyFields: ["url"],
    },
    JiraIssueNode: {
      keyFields: ["url"],
    },
    MeetingWithRelevantSectionsNode: {
      keyFields: ["meeting", ["id"]],
      fields: {
        relevantSections: {
          merge(existing, incoming) {
            return incoming;
          },
        },
      },
    },
    OrganizationNode: {
      fields: {
        members: {
          keyArgs: [
            "hasManager",
            "excludeUserId",
            "excludeUserIds",
            "search",
            "membershipStatus",
          ],
          merge: defaultMerge,
        },
        recognitionStats: {
          keyArgs: [
            "recipientId",
            "coreValueId",
            "startDate",
            "endDate",
            "recognitionReceivedByMembersOfTeam",
          ],
        },
      },
    },
    SlackNotificationForChannelNode: {
      keyFields: false,
      merge: defaultMerge,
    },
    SummarizedMeasurements: {
      keyFields: ["aggregateMeasurementId"],
    },
    KPINode: {
      fields: {
        summarizedMeasurements: {
          keyArgs: ["summaryPeriod", "summaryMethod"],
          merge: defaultMerge,
        },
      },
    },
    TeamNode: {
      fields: {
        members: {
          keyArgs: false,
          merge: defaultMerge,
        },
      },
    },
    RatingNode: {
      fields: {
        answers: {
          keyArgs: ["creator"],
          merge: defaultMerge,
        },
      },
    },
    ArtifactInterface: {
      merge: (existing, incoming, { isReference }) => {
        const artifactSet = artifactsVar();
        const id = isReference(incoming)
          ? incoming.__ref
          : cache.identify(incoming);
        artifactSet.add(id);
        artifactsVar(artifactSet);
        return incoming;
      },
      fields: {
        url: {
          read(_, { readField }) {
            if (readField("id") && readField("artifactType"))
              return getUrl({
                artifactId: readField("id"),
                artifactType: readField("artifactType"),
              });
            return null;
          },
        },
        activities: {
          keyArgs: ["activityType"],
          merge: defaultMerge,
        },
        childGoals: {
          keyArgs: ["goalDueBetweenDates", "goalStates"],
          merge(existing, incoming) {
            return incoming;
          },
        },
      },
    },
    GoalArtifactNode: {
      fields: {
        keyResults: {
          keyArgs: false,
          merge(existing, incoming) {
            return incoming;
          },
        },
        owners: {
          keyArgs: ["first"],
          merge(existing, incoming) {
            return incoming;
          },
        },
        contributors: {
          keyArgs: ["first"],
          merge(existing, incoming) {
            return incoming;
          },
        },
        childGoals: {
          keyArgs: ["goalDueBetweenDates", "goalStates"],
          merge(existing, incoming) {
            return incoming;
          },
        },
      },
    },
    MeetingNode: {
      fields: {
        topics: {
          keyArgs: ["meetingId", "hasMeetingId", "meetingGroupId", "today"],
          merge: (existing, incoming, { mergeObjects }) => {
            return mergeObjects(existing, incoming);
          },
        },
        recentlyCompletedAssessmentDeliveries: {
          keyArgs: ["assessmentType"],
          merge: defaultMerge,
        },
        artifacts: artifactMerge,
      },
    },
    TopicNode: {
      fields: {
        comments: {
          merge(existing, incoming) {
            if (!existing) {
              return incoming;
            }
            const existingEdges = existing?.edges || [];
            const incomingEdges = incoming?.edges || [];
            const newEdges = uniqBy(
              [...existingEdges, ...incomingEdges],
              ({ node }) => node.__ref
            );
            return {
              totalCount: newEdges.length,
              edges: newEdges,
            };
          },
        },
        relatedTopics: {
          // merge only when the keyArgs are the same
          keyArgs: [
            "hasArtifacts",
            "hasActionItems",
            "hasDecisions",
            "meetingInMeetingGroupId",
            "actionItemIsComplete",
            "orderBy",
          ],
          merge: defaultMerge,
        },
        artifacts: artifactMerge,
      },
    },
  },
});

export const cacheModify = (options: Cache.ModifyOptions) => {
  const state = cache.modify(options);
  if (!state) {
    Sentry.captureException("Cache update failed.", options as any);
  }
};

export default cache;
