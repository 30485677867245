import { gql } from "@apollo/client";

const GoalTeamsFragment = gql`
  fragment GoalTeamsFragment on GoalArtifactNode {
    teams {
      edges {
        node {
          id
          title
        }
      }
    }
  }
`;

export default GoalTeamsFragment;
