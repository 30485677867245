import { useMutation } from "@apollo/client";
import {
  AdjustmentsIcon,
  CheckIcon,
  SwitchHorizontalIcon,
} from "@heroicons/react/outline";
import { MenuDivider, MenuItem, SubMenu } from "@szhsin/react-menu";
import { compact } from "lodash";
import { useState } from "react";
import { MeetingNode } from "types/graphql-schema";
import { BasicUser } from "types/topicflow";

import getMeetingQuery from "@apps/meeting-new/graphql/get-meeting-query";
import updateMeetingGroupMutation from "@apps/meeting-new/graphql/update-meeting-group-mutation";
import getMeetingOverviewMeetingsQuery from "@apps/meetings/graphql/get-meeting-overview-meetings-query";
import useLabel from "@apps/use-label/use-label";
import useUiPreferenceCache from "@apps/use-ui-preference-cache/use-ui-preference-cache";
import {
  ErrorMatchType,
  onNotificationErrorHandler,
} from "@components/use-error/use-error";
import { assertEdgesNonNullWithStringId } from "@helpers/helpers";
import useConfirm from "@helpers/hooks/use-confirm";

const MeetingTypeDropdownOptions = ({
  meetingGroup,
  meeting,
  showIcons = false,
}: {
  meeting: {
    id: number;
    participants?: MeetingNode["participants"];
  };
  meetingGroup: {
    id: number;
    isFormalOneonone: boolean;
    facilitator?: BasicUser | null;
  };
  showIcons?: boolean;
}) => {
  const label = useLabel();
  const { saveUiPreference } = useUiPreferenceCache();
  const participants = meeting.participants
    ? assertEdgesNonNullWithStringId(meeting.participants)
    : [];
  const { confirm, ConfirmationDialog } = useConfirm(
    `Are you sure you want to make this meeting to a formal ${label(
      "1-on-1"
    )}?`,
    `The meeting has more than 2 participants. Usually a ${label(
      "1-on-1"
    )} is between 2 participants.`
  );
  const participantUsers = compact(
    participants.map((participant) => participant.user)
  );
  const [facilitator, setFacilitator] = useState(
    meetingGroup.facilitator || participantUsers[0]
  );
  const subject = participantUsers.find(({ id }) => id !== facilitator.id);

  const [updateMeetingGroup] = useMutation(updateMeetingGroupMutation, {
    refetchQueries: [getMeetingQuery, getMeetingOverviewMeetingsQuery],
  });

  const errorMatches: ErrorMatchType[] = [
    {
      title:
        "Cannot mark a meeting group as being a formal 1-on-1 if there are not 2 participants",
      match:
        "Cannot mark a meeting group as being a formal 1-on-1 if there are not 2 participants",
    },
  ];

  const handleClickSwitch = () => {
    if (!subject) return;
    setFacilitator(subject);
    if (meetingGroup.isFormalOneonone) {
      updateMeetingGroup({
        variables: {
          meetingGroupId: meetingGroup.id,
          facilitatorId: subject.id,
        },
        onError: onNotificationErrorHandler(errorMatches),
        optimisticResponse: {
          updateMeetingGroup: {
            meetingGroup: {
              ...meetingGroup,
              facilitator: subject,
            },
          },
        },
      });
    }
  };

  const handleClickChangeToFormalOneonone = async () => {
    if (participants.length !== 2) {
      const confirmation = await confirm();
      if (!confirmation) return;
    }
    updateMeetingGroup({
      variables: {
        meetingGroupId: meetingGroup.id,
        isFormalOneonone: true,
        facilitatorId: facilitator.id,
      },
      onError: onNotificationErrorHandler(errorMatches),
      optimisticResponse: {
        updateMeetingGroup: {
          meetingGroup: {
            ...meetingGroup,
            isFormalOneonone: true,
            facilitator,
          },
        },
      },
      onCompleted: () => {
        saveUiPreference({
          oneononeGoalsJustMe: true,
          oneononeActionItemsJustMe: true,
        });
      },
    });
  };

  const handleClickRevertToNormalMeeting = () => {
    updateMeetingGroup({
      variables: {
        meetingGroupId: meetingGroup.id,
        isFormalOneonone: false,
      },
      optimisticResponse: {
        updateMeetingGroup: {
          meetingGroup: {
            ...meetingGroup,
            isFormalOneonone: false,
          },
        },
      },
      onError: onNotificationErrorHandler(errorMatches),
      onCompleted: () => {
        saveUiPreference({
          meetingGoalsJustMe: false,
          meetingActionItemsJustMe: false,
        });
      },
    });
  };

  // Don't show anything if group meeting
  return (
    <>
      <ConfirmationDialog />
      {meetingGroup.isFormalOneonone && (
        <>
          <MenuItem
            onClick={handleClickSwitch}
            className="text-sm flex items-center gap-1"
          >
            {showIcons && (
              <SwitchHorizontalIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
            )}
            Switch facilitator & subject
          </MenuItem>
          <MenuDivider />
        </>
      )}
      <>
        <SubMenu
          label={
            <div className="flex gap-1 items-center text-sm">
              {showIcons && (
                <AdjustmentsIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
              )}
              Change meeting type
            </div>
          }
        >
          <MenuItem
            onClick={handleClickChangeToFormalOneonone}
            disabled={meetingGroup.isFormalOneonone}
            className="flex items-center justify-between text-sm"
          >
            {label("oneonone", { pluralize: false, capitalize: true })}
            {meetingGroup.isFormalOneonone && (
              <CheckIcon className="text-indigo-600 h-5 w-5" />
            )}
          </MenuItem>
          <MenuItem
            onClick={handleClickRevertToNormalMeeting}
            disabled={!meetingGroup.isFormalOneonone}
            className="flex items-center justify-between text-sm"
          >
            Group meeting
            {!meetingGroup.isFormalOneonone && (
              <CheckIcon className="text-indigo-600 h-5 w-5" />
            )}
          </MenuItem>
        </SubMenu>
        <MenuDivider />
      </>
    </>
  );
};

export default MeetingTypeDropdownOptions;
