import { XIcon } from "@heroicons/react/outline";

const TopicSidebarNotFound = ({ onClose }: { onClose: () => void }) => {
  return (
    <div className="-mt-3" aria-label="Error sidebar topic">
      <div className="flex justify-end">
        <button
          type="button"
          className="ml-3 bg-white rounded p-1 text-gray-400 hover:text-gray-500 hover:bg-gray-100"
          aria-label="Close topic sidebar"
          onClick={onClose}
        >
          <span className="sr-only">Close topic sidebar</span>
          <XIcon className="h-6 w-6" />
        </button>
      </div>
      <div className="pt-2">This topic could not be found.</div>
    </div>
  );
};

export default TopicSidebarNotFound;
