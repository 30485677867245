import { gql } from "@apollo/client";

const getKPIGroupsQuery = gql`
  query getKPIGroupsQuery {
    kpiGroups {
      edges {
        node {
          id
          title
          kpis {
            totalCount
          }
        }
      }
    }
    teams {
      edges {
        node {
          id
          title
          kpis {
            totalCount
          }
        }
      }
    }
  }
`;

export default getKPIGroupsQuery;
