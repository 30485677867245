import { useMutation, useQuery } from "@apollo/client";
import { EyeOffIcon, PencilIcon, TrashIcon } from "@heroicons/react/outline";
import { compact, flatMap } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { MdContentCopy } from "react-icons/md";
import { useLocation } from "react-router-dom";
import {
  AssessmentType,
  ComplianceProgramParticipantStatus,
  ComplianceProgramState,
  DeleteProgramMutation,
  DeleteProgramMutationVariables,
  ExportComplianceProgramToCsvMutationMutation,
  ExportComplianceProgramToCsvMutationMutationVariables,
  GetComplianceProgramQuery,
  GetComplianceProgramQueryVariables,
  SaveComplianceProgramMutation,
  SaveComplianceProgramMutationVariables,
  SendOneononeProgramReminderMutation,
  SendOneononeProgramReminderMutationVariables,
} from "types/graphql-schema";
import { TFLocationState } from "types/topicflow";

import exportComplianceProgramToCsvMutation from "@apps/programs/graphql/export-compliance-program-to-csv-mutation";
import sendProgramMeetingReminderMutation from "@apps/programs/graphql/send-program-meeting-reminder-mutation";
import useLabel from "@apps/use-label/use-label";
import {
  currentOrganizationVar,
  currentUserVar,
  successNotificationVar,
} from "@cache/cache";
import Avatar from "@components/avatar/avatar";
import Button, { buttonTheme } from "@components/button/button";
import Link, { useLink } from "@components/link/link";
import Loading from "@components/loading/loading";
import Select, { SelectOption } from "@components/select/select";
import Table, {
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableContainer,
  TableHeadCell,
  TableHeadRow,
} from "@components/table/table";
import Tooltip from "@components/tooltip/tooltip";
import {
  onNotificationErrorHandler,
  useNotificationError,
} from "@components/use-error/use-error";
import { ProgramStatusLabel, assessmentTypeLabels } from "@helpers/constants";
import { assertEdgesNonNull, assertNonNull } from "@helpers/helpers";
import useConfirm from "@helpers/hooks/use-confirm";
import usePkParams from "@helpers/hooks/use-pk-params";

import createOrUpdateComplianceProgramMutation from "../graphql/create-or-update-compliance-program-mutation";
import deleteComplianceProgramMutation from "../graphql/delete-compliance-program-mutation";
import getComplianceProgramQuery from "../graphql/get-compliance-program-query";
import getComplianceProgramsQuery from "../graphql/get-compliance-programs-query";
import ComplianceProgramAssessmentView from "./compliance-program-assessment-view";
import ComplianceProgramCompletionTable from "./compliance-program-completion-table";
import ComplianceProgramSidebar from "./compliance-program-sidebar";

export enum ProgramTabType {
  ASSESSMENTS = "assessments",
  SELF_ASSESSMENTS = "self-assessments",
  ONE_ON_ONES = "one-on-ones",
  SUMMARY = "summary",
}

export type ProgramTab = {
  type: ProgramTabType;
  key: string;
  assessmentGroup: number | null;
  assessmentType?: AssessmentType;
  selfAssessment?: boolean;
};

const ComplianceProgram = () => {
  const label = useLabel();
  const link = useLink();
  const currentUser = currentUserVar();
  const organization = currentOrganizationVar();
  const { onError } = useNotificationError();
  const location = useLocation<TFLocationState>();
  const backUrl = location.state?.previousPathname || "/programs";
  const complianceProgramId = usePkParams("complianceProgramId");
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const { ConfirmationDialog: ConfirmDeleteDialog, confirm: confirmDelete } =
    useConfirm(
      "Are you sure?",
      "Are you sure you want to delete this program? All assessment data will be deleted."
    );

  const [saveComplianceProgram, { loading: isPublishingProgram }] = useMutation<
    SaveComplianceProgramMutation,
    SaveComplianceProgramMutationVariables
  >(createOrUpdateComplianceProgramMutation);

  const [exportToCSV, { loading: isExportingProgram }] = useMutation<
    ExportComplianceProgramToCsvMutationMutation,
    ExportComplianceProgramToCsvMutationMutationVariables
  >(exportComplianceProgramToCsvMutation);

  const [deleteProgram, { loading: isDeletingProgram }] = useMutation<
    DeleteProgramMutation,
    DeleteProgramMutationVariables
  >(deleteComplianceProgramMutation);

  const [sendOneononeReminder, { loading: isSendingOneononeReminder }] =
    useMutation<
      SendOneononeProgramReminderMutation,
      SendOneononeProgramReminderMutationVariables
    >(sendProgramMeetingReminderMutation);

  const publishProgram = useCallback(() => {
    saveComplianceProgram({
      variables: {
        complianceProgramId,
        state: ComplianceProgramState.Published,
        organizationId: organization.id,
      },
      onError,
      refetchQueries: [getComplianceProgramQuery],
      onCompleted: () =>
        successNotificationVar({
          title: "Program published",
        }),
    });
  }, [complianceProgramId, onError, organization, saveComplianceProgram]);

  const exportProgram = useCallback(() => {
    exportToCSV({ variables: { complianceProgramId }, onError });
    successNotificationVar({
      title: `CSV export will be emailed to ${currentUser.email}`,
    });
  }, [exportToCSV, complianceProgramId, onError, currentUser]);

  const sendOneononeEmailReminder = useCallback(() => {
    sendOneononeReminder({
      variables: { complianceProgramId },
      onError,
      onCompleted: () => successNotificationVar({ title: "Reminder sent" }),
    });
  }, [complianceProgramId, onError, sendOneononeReminder]);

  const handleDeleteProgram = useCallback(async () => {
    const confirm = await confirmDelete();
    if (!confirm) return;
    deleteProgram({
      variables: {
        complianceProgramId,
      },
      onError,
      refetchQueries: [getComplianceProgramsQuery],
      onCompleted: () => {
        successNotificationVar({ title: "Program deleted" });
        link.redirect(`/programs`);
      },
    });
  }, [confirmDelete, deleteProgram, complianceProgramId, onError, link]);

  const { data: complianceProgramData, loading: isLoadingComplianceProgram } =
    useQuery<GetComplianceProgramQuery, GetComplianceProgramQueryVariables>(
      getComplianceProgramQuery,
      {
        variables: { complianceProgramId },
        onError: onNotificationErrorHandler(),
      }
    );

  const complianceProgram = useMemo(
    () => complianceProgramData?.complianceProgram,
    [complianceProgramData]
  );

  const performanceAssessmentGroup = useMemo(
    () =>
      complianceProgram?.performanceAssessmentGroup
        ? complianceProgram.performanceAssessmentGroup
        : null,
    [complianceProgram]
  );
  const managerAssessmentGroup = useMemo(
    () =>
      complianceProgram?.managerAssessmentGroup
        ? complianceProgram.managerAssessmentGroup
        : null,
    [complianceProgram]
  );
  const peerAssessmentGroup = useMemo(
    () =>
      complianceProgram?.peerAssessmentGroup
        ? complianceProgram.peerAssessmentGroup
        : null,
    [complianceProgram]
  );
  const allAssessmentGroups = useMemo(
    () =>
      compact([
        performanceAssessmentGroup,
        managerAssessmentGroup,
        peerAssessmentGroup,
      ]),
    [managerAssessmentGroup, peerAssessmentGroup, performanceAssessmentGroup]
  );
  const topicTemplate = useMemo(
    () =>
      complianceProgram?.requiredTopicTemplates
        ? assertEdgesNonNull(complianceProgram.requiredTopicTemplates)[0]
        : null,
    [complianceProgram]
  );

  const tabOptions: SelectOption<ProgramTab>[] = useMemo(() => {
    const assessmentTabs = flatMap(
      [performanceAssessmentGroup, managerAssessmentGroup, peerAssessmentGroup],
      (assessmentGroup) => {
        return [
          assessmentGroup && {
            label: `${
              assessmentTypeLabels[assessmentGroup.assessmentType]
            } ${label("review", { pluralize: true })}`,
            value: {
              key: `${ProgramTabType.ASSESSMENTS}_${assessmentGroup.id}`,
              type: ProgramTabType.ASSESSMENTS,
              assessmentType: assessmentGroup.assessmentType,
              assessmentGroup: assessmentGroup.id,
              selfAssessment: false,
            },
          },
          assessmentGroup &&
            assessmentGroup.hasSelfAssessment && {
              label: `${
                assessmentTypeLabels[assessmentGroup.assessmentType]
              } self ${label("review", { pluralize: true })}`,
              value: {
                key: `${ProgramTabType.SELF_ASSESSMENTS}_${assessmentGroup.id}`,
                type: ProgramTabType.SELF_ASSESSMENTS,
                assessmentType: assessmentGroup.assessmentType,
                assessmentGroup: assessmentGroup.id,
                selfAssessment: true,
              },
            },
        ];
      }
    );
    return compact([
      {
        label: "Summary",
        value: {
          key: ProgramTabType.SUMMARY,
          type: ProgramTabType.SUMMARY,
          assessmentGroup: null,
        },
      },
      ...assessmentTabs,
      topicTemplate && {
        label: "Meetings",
        value: {
          key: ProgramTabType.ONE_ON_ONES,
          type: ProgramTabType.ONE_ON_ONES,
          assessmentGroup: null,
        },
      },
    ]);
  }, [
    managerAssessmentGroup,
    peerAssessmentGroup,
    performanceAssessmentGroup,
    topicTemplate,
    label,
  ]);

  const tabContent = useMemo(() => {
    if (tabOptions.length === 0) {
      return null;
    }
    const { value: tab } = tabOptions[selectedTabIndex];

    if (tab.type === ProgramTabType.SUMMARY && complianceProgram) {
      return (
        <ComplianceProgramCompletionTable
          assessmentGroups={allAssessmentGroups}
          hasOneOnOneMeeting={!!topicTemplate}
          participantStatus={complianceProgram.participantStatus.edges.map(
            (edge) => assertNonNull(edge?.node)
          )}
        />
      );
    }

    if (
      [ProgramTabType.ASSESSMENTS, ProgramTabType.SELF_ASSESSMENTS].includes(
        tab.type
      ) &&
      complianceProgram
    ) {
      return (
        <ComplianceProgramAssessmentView
          tab={tab}
          complianceProgramId={complianceProgram.id}
        />
      );
    }
    if (tab.type === ProgramTabType.ONE_ON_ONES && complianceProgram) {
      const missingOneononeUsers =
        complianceProgram.participantStatus.edges.filter(
          (participantStatusEdge) =>
            participantStatusEdge?.node?.oneononeStatus ===
            ComplianceProgramParticipantStatus.NotStarted
        );
      return (
        <>
          <div className="mt-8">
            <div className="flex items-center mb-2">
              <div className="font-bold mr-4">
                {label("oneonone", { pluralize: true, capitalize: true })}
              </div>
            </div>
            <TableContainer>
              <Table>
                <TableHeadRow>
                  <TableHeadCell>Name</TableHeadCell>
                  <TableHeadCell>Manager</TableHeadCell>
                  <TableHeadCell>Status</TableHeadCell>
                  <TableHeadCell>1-on-1</TableHeadCell>
                </TableHeadRow>
                <TableBody>
                  {complianceProgram.participantStatus.edges
                    .filter(
                      (participantStatusEdge) =>
                        participantStatusEdge?.node?.oneononeStatus !==
                        ComplianceProgramParticipantStatus.NotStarted
                    )
                    .map((participantStatusEdge) => {
                      const node = assertNonNull(participantStatusEdge?.node);
                      const manager = node.user.managers.edges[0]?.node;
                      return (
                        <TableBodyRow key={node.user.id}>
                          <TableBodyCell>
                            <div className="flex items-center">
                              <Avatar
                                className="mr-2"
                                size="8"
                                user={node.user}
                              />{" "}
                              {node.user.name}
                            </div>
                          </TableBodyCell>
                          <TableBodyCell>
                            <div className="flex items-center">
                              {manager && (
                                <Avatar
                                  className="mr-2"
                                  size="8"
                                  user={manager}
                                />
                              )}{" "}
                              {manager?.name}
                            </div>
                          </TableBodyCell>
                          <TableBodyCell>
                            {ProgramStatusLabel[node.oneononeStatus]}
                          </TableBodyCell>
                          <TableBodyCell>
                            {node.meeting ? (
                              <Link
                                className="text-blue-link"
                                to={`/meeting/${node.meeting.meetingGroupId}/${node.meeting.id}`}
                              >
                                {node.meeting.title}
                              </Link>
                            ) : (
                              <Tooltip text="You do not have access to this meeting">
                                <span className="text-gray-500 ml-3">
                                  <EyeOffIcon className="inline mr-1 text-gray-600 h-4 w-4" />
                                  <span>Private meeting</span>
                                </span>
                              </Tooltip>
                            )}
                          </TableBodyCell>
                        </TableBodyRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          {missingOneononeUsers.length > 0 && (
            <div className="mt-8">
              <div className="flex items-center mb-2">
                <div className="font-bold mr-4">
                  Missing{" "}
                  {label("oneonone", { pluralize: true, capitalize: true })}
                </div>
                <Button
                  theme={buttonTheme.lightBlue}
                  disabled={isSendingOneononeReminder}
                  onClick={sendOneononeEmailReminder}
                >
                  Send email reminder to managers
                </Button>
              </div>
              <TableContainer>
                <Table>
                  <TableHeadRow>
                    <TableHeadCell>Name</TableHeadCell>
                    <TableHeadCell>Manager</TableHeadCell>
                  </TableHeadRow>
                  <TableBody>
                    {missingOneononeUsers.map((participantStatusEdge) => {
                      const node = assertNonNull(participantStatusEdge?.node);
                      const manager = node.user.managers.edges[0]?.node;
                      if (!manager) {
                        return null;
                      }
                      return (
                        <TableBodyRow key={node.user.id}>
                          <TableBodyCell>
                            <div className="flex items-center">
                              <Avatar
                                className="mr-2"
                                size="8"
                                user={node.user}
                              />{" "}
                              {node.user.name}
                            </div>
                          </TableBodyCell>
                          <TableBodyCell>
                            <div className="flex items-center">
                              {manager && (
                                <Avatar
                                  className="mr-2"
                                  size="8"
                                  user={manager}
                                />
                              )}{" "}
                              {manager?.name}
                            </div>
                          </TableBodyCell>
                        </TableBodyRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )}
        </>
      );
    }
  }, [
    allAssessmentGroups,
    complianceProgram,
    isSendingOneononeReminder,
    label,
    selectedTabIndex,
    sendOneononeEmailReminder,
    tabOptions,
    topicTemplate,
  ]);

  if (isLoadingComplianceProgram) {
    return (
      <div className="flex-1 flex justify-center p-10">
        <Loading>Loading program..</Loading>
      </div>
    );
  }

  if (!complianceProgram) {
    return (
      <div className="flex-1 flex justify-center p-10">Program not found</div>
    );
  }

  return (
    <div className="sm:flex flex-1 w-full" aria-label="Compliance Program view">
      <ConfirmDeleteDialog />
      <ComplianceProgramSidebar complianceProgram={complianceProgram} />
      <div className="flex-1 p-6 flex flex-col gap-4 bg-white overflow-scroll">
        <div className="flex items-center justify-between">
          <Button to={backUrl} disabled={isPublishingProgram}>
            Back
          </Button>
          <div className="flex items-center gap-2">
            <Button
              tooltip={
                !complianceProgram.canUpdate?.permission
                  ? complianceProgram.canUpdate?.reason ?? "Can't edit"
                  : "Edit program"
              }
              disabled={
                !complianceProgram.canUpdate?.permission ||
                isPublishingProgram ||
                isDeletingProgram ||
                isExportingProgram
              }
              to={`/programs/${complianceProgram.id}/edit`}
            >
              <PencilIcon className="h-5 w-5" />
            </Button>
            <Button
              disabled={
                isPublishingProgram || isDeletingProgram || isExportingProgram
              }
              tooltip="Clone program"
              to={`/programs/${complianceProgram.id}/duplicate`}
            >
              <MdContentCopy className="h-5 w-5" />
            </Button>
            <Button
              theme={buttonTheme.redDanger}
              tooltip={
                !complianceProgram.canDelete?.permission
                  ? complianceProgram.canDelete?.reason ?? "Can't edit"
                  : "Delete program"
              }
              disabled={
                !complianceProgram.canDelete?.permission ||
                isPublishingProgram ||
                isDeletingProgram ||
                isExportingProgram
              }
              onClick={handleDeleteProgram}
            >
              <TrashIcon className="h-5 w-5" />
            </Button>
            {complianceProgram.state !== ComplianceProgramState.Published && (
              <Button
                className="hidden sm:flex"
                disabled={isPublishingProgram}
                onClick={publishProgram}
                theme={buttonTheme.primary}
              >
                Publish
              </Button>
            )}
            {complianceProgram.state === ComplianceProgramState.Published && (
              <Button
                className="hidden sm:flex"
                disabled={isExportingProgram}
                onClick={exportProgram}
                theme={buttonTheme.primary}
              >
                Export to CSV
              </Button>
            )}
          </div>
        </div>

        {complianceProgram.state !== ComplianceProgramState.Published ? (
          <div className="mx-auto w-92 text-center text-gray-400 mt-6">
            Program data will be displayed here after publishing
          </div>
        ) : (
          <div>
            {tabOptions.length > 1 && (
              <div className="flex items-center gap-4 pb-4 border-b mb-4">
                <div className="font-bold">View:</div>
                <Select
                  className="flex-1"
                  aria-label="Compliance program view select"
                  value={tabOptions[selectedTabIndex].value}
                  options={tabOptions}
                  onChange={(opt) =>
                    setSelectedTabIndex(
                      tabOptions.findIndex((t) => t.value.key === opt.value.key)
                    )
                  }
                />
              </div>
            )}
            {tabContent}
          </div>
        )}
      </div>
    </div>
  );
};

export default ComplianceProgram;
