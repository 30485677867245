import { useMutation } from "@apollo/client";
import logo from "@static/img/topicflow-logo-no-text.svg";
import { useCallback, useState } from "react";
import { TbArrowsLeftRight } from "react-icons/tb";

import linkCalendarToOrganizationMutation from "@apps/account/graphql/link-calendar-to-organization-mutation";
import removeIntegrationCredentialsMutation from "@apps/account/graphql/remove-integration-credentials-mutation";
import getSidebarDataQuery from "@apps/main/graphql/get-sidebar-data-query";
import Dropdown from "@components/dropdown/dropdown";
import Loading from "@components/loading/loading";
import ProviderImage from "@components/provider-image/provider-image";
import { useNotificationError } from "@components/use-error/use-error";
import { providerUrl } from "@helpers/constants";
import { getAssetUrl } from "@helpers/helpers";
import useConfirm from "@helpers/hooks/use-confirm";

import startCalendarSyncMutation from "../../graphql/start-calendar-sync-mutation";
import CalendarChangeOrganizationDialog from "./calendar-change-organization-dialog";

const Calendar = ({
  me,
  refetch,
  calendar,
}: {
  me: any;
  refetch: any;
  calendar: any;
}) => {
  const [openedModal, setOpenedModal] = useState(false);
  const { ConfirmationDialog, confirm } = useConfirm(
    "Are you sure?",
    "Are you sure you want to disconnect this calendar?"
  );

  const nonPersonalOrganizations = me.organizations.edges.filter(
    ({ node }: { node: any }) => !node.personal
  );
  const personalOrganization = me.organizations.edges.find(
    ({ node }: { node: any }) => node.personal
  ).node;

  const [linkCalendarToOrganization, { loading: linkCalendarLoading }] =
    useMutation(linkCalendarToOrganizationMutation, {
      refetchQueries: [getSidebarDataQuery],
    });
  const [removeCalendar, { loading: removeCalendarLoading }] = useMutation(
    removeIntegrationCredentialsMutation
  );
  const [syncCalendar, { loading: syncCalendarLoading }] = useMutation(
    startCalendarSyncMutation,
    {
      refetchQueries: [getSidebarDataQuery],
    }
  );
  const { onError } = useNotificationError();

  const handleChangeOrganization = (value: any) => {
    setOpenedModal(false);
    if (value) {
      linkCalendarToOrganization({
        variables: {
          credentialsId: calendar.id,
          organizationId: value,
        },
        onError,
        onCompleted: () => {
          refetch();
        },
      });
    } else {
      linkCalendarToOrganization({
        variables: {
          credentialsId: calendar.id,
          organizationId: personalOrganization.id,
        },
        onError,
        onCompleted: () => {
          refetch();
        },
      });
    }
  };

  const handleRemoveCalendar = useCallback(async () => {
    const confirmation = await confirm();
    if (confirmation) {
      removeCalendar({
        variables: { credentialsId: calendar.id },
        onError,
      });
    }
  }, [calendar, confirm, onError, removeCalendar]);

  const handleSyncCalendar = () => {
    syncCalendar({
      variables: { syncCredentialsId: calendar.id, fullSync: true },
      onError,
    });
  };

  const handleCloseDialog = () => {
    setOpenedModal(false);
  };

  return (
    <div
      className="border border-gray-300 p-6 rounded-md mb-6"
      aria-label={`Calendar settings: ${calendar.credentialsUid}`}
    >
      <ConfirmationDialog />
      {nonPersonalOrganizations.length > 0 && (
        <CalendarChangeOrganizationDialog
          key={calendar.organization?.id || "none"} // force state refresh in modal
          open={openedModal}
          me={me}
          calendar={calendar}
          onClose={handleCloseDialog}
          onSaveNewOrganization={handleChangeOrganization}
        />
      )}
      <div className="flex justify-between">
        <div className="flex gap-4">
          <ProviderImage provider={calendar.provider} size="6" />
          <div className="text-lg font-medium">{calendar.credentialsUid}</div>
        </div>
        <Dropdown
          aria-label="Calendar dropdown"
          options={[
            {
              label: "Force calendar sync now",
              onClick: handleSyncCalendar,
            },
            {
              label: "Disconnect",
              onClick: handleRemoveCalendar,
            },
          ]}
        />
      </div>
      <div className="rounded-md p-4 bg-gray-50 mt-4 text-sm text-gray-700">
        <div className="flex gap-2 items-center">
          <ProviderImage provider={calendar.provider} size="5" />
          <TbArrowsLeftRight className="h-5 text-gray-500" />
          <img src={getAssetUrl(logo)} alt="Topicflow" className="block h-5" />
          <span className="text-base font-medium">
            Calendar sync is{" "}
            {calendar.hasValidCalendarCredentials &&
            calendar.hasCalendarWriteCredentials ? (
              <span className="font-semibold text-emerald-500">active</span>
            ) : calendar.hasValidCalendarCredentials &&
              !calendar.hasCalendarWriteCredentials ? (
              <span className="font-semibold text-orange-500">active</span>
            ) : (
              <span className="font-semibold text-gray-500">inactive</span>
            )}
          </span>
        </div>
        {calendar.hasValidCalendarCredentials &&
          !calendar.hasCalendarWriteCredentials && (
            <div className="mt-4">
              <span className="font-semibold text-orange-500">
                Read access only.
              </span>{" "}
              Topicflow can’t add an agenda link to your calendar events.{" "}
              <a
                href={`/sso/${
                  providerUrl[calendar.provider as keyof typeof providerUrl]
                }?request_calendar_scope=true&request_write_scope=true&next=/account/profile`}
                className="text-blue-600 hover:underline font-medium"
              >
                Allow write access
              </a>
            </div>
          )}
        {waffle.flag_is_active(
          "can-change-calendar-organization-relationship"
        ) &&
          nonPersonalOrganizations.length > 0 && (
            <div className="flex items-center mt-4 gap-4">
              <div className="text-xs">
                Events in this calendar belong to{" "}
                <span className="font-medium">
                  {!calendar.organization ||
                  calendar.organization.id === personalOrganization.id
                    ? "no organizations"
                    : calendar.organization.name}
                </span>{" "}
                <button
                  disabled={
                    linkCalendarLoading ||
                    removeCalendarLoading ||
                    syncCalendarLoading
                  }
                  onClick={() => setOpenedModal(true)}
                  className="ml-2 text-blue-600 font-medium"
                >
                  Move to {calendar.organization ? "another" : "an"}{" "}
                  organization
                </button>
              </div>
              {(linkCalendarLoading ||
                removeCalendarLoading ||
                syncCalendarLoading) && <Loading mini size="5" />}
            </div>
          )}
      </div>
    </div>
  );
};

export default Calendar;
