import { ReactElement } from "react";

const SidebarLabel = ({
  children,
}: {
  children?: ReactElement | string | string[];
}) => (
  <div className="shrink-0 w-26 text-sm tracking-tight text-gray-400 flex items-center gap-1">
    <div className="flex-1 min-w-0 truncate">{children}</div>
  </div>
);

export default SidebarLabel;
