import { useQuery } from "@apollo/client";
import { Editor } from "@tiptap/react";
import { FormEvent, useState } from "react";
import {
  GetKpiGroupsQueryQuery,
  GetKpiGroupsQueryQueryVariables,
} from "types/graphql-schema";

import useLabel from "@apps/use-label/use-label";
import Button, { buttonTheme } from "@components/button/button";
import Loading from "@components/loading/loading";
import Select from "@components/select/select";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { assertEdgesNonNull } from "@helpers/helpers";

import getKPIGroupsQuery from "../graphql/get-kpi-groups-query";

const SelectGroupKpiForm = ({
  updateAttributes,
  deleteNode,
  editor,
}: {
  updateAttributes: (attributes: any) => void;
  deleteNode: () => void;
  editor: Editor;
}) => {
  const label = useLabel();
  const [selectedOption, setSelectedOption] = useState<number | string | null>(
    null
  );
  const { data, loading } = useQuery<
    GetKpiGroupsQueryQuery,
    GetKpiGroupsQueryQueryVariables
  >(getKPIGroupsQuery, {
    onCompleted: (response) => {
      setSelectedOption(response?.kpiGroups?.edges?.[0]?.node?.id || null);
    },
    onError: onNotificationErrorHandler(),
  });
  const kpiGroups = data?.kpiGroups ? assertEdgesNonNull(data.kpiGroups) : [];
  const kpiTeams = data?.teams ? assertEdgesNonNull(data.teams) : [];
  const groupOptions = kpiGroups.map(({ id, title, kpis }) => ({
    value: id,
    label: `Group: ${title} (${kpis?.totalCount || 0})`,
  }));
  const teamOptions = kpiTeams.map(({ id, title, kpis }) => ({
    value: `team-${id}`,
    label: `${label("team", { capitalize: true })}: ${title} (${
      kpis?.totalCount || 0
    })`,
  }));
  const options = [...groupOptions, ...teamOptions];

  const handleSubmitForm = (e: FormEvent) => {
    e.preventDefault();
    updateAttributes({
      id: selectedOption,
    });
  };

  return (
    <form
      className="flex flex-col gap-6 border p-4 rounded bg-gray-50"
      contentEditable={false}
      onSubmit={handleSubmitForm}
      aria-label="Artifact kpi group form"
    >
      <div>
        {loading || !selectedOption ? (
          <Loading mini size="5" />
        ) : (
          <Select<number | string>
            disabled={!editor.options.editable}
            options={options}
            value={selectedOption}
            onChange={(newOption) => setSelectedOption(newOption.value)}
          />
        )}
      </div>
      <div className="flex gap-2 items-center">
        <Button
          type="button"
          onClick={handleSubmitForm}
          small
          text="Save"
          disabled={!editor.options.editable}
          theme={buttonTheme.primary}
        />
        <Button
          text="Cancel"
          small
          onClick={deleteNode}
          disabled={!editor.options.editable}
        />
      </div>
    </form>
  );
};
export default SelectGroupKpiForm;
