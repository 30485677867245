import { useCallback } from "react";

import cache from "@cache/cache";
import removeTopicFromCache from "@cache/remove-topic";
import usePusher from "@components/use-pusher/use-pusher";

export default function PusherTopicDeleted({
  channelName,
}: {
  channelName: string;
}) {
  // const currentUser = currentUserVar();

  const handleTopicDeleted = useCallback(
    ({
      topic,
      meeting,
    }: {
      topic: { id: number };
      meeting: { id: number } | null;
    }) => {
      const topicCacheId = cache.identify(topic);
      // If it's in the cache then we remove
      if (topicCacheId) {
        removeTopicFromCache({
          topic,
          meeting,
        });
      }
    },
    []
  );

  usePusher(channelName, "topic_deleted", handleTopicDeleted);

  return null;
}
