import { useQuery } from "@apollo/client";
import { ExternalLinkIcon } from "@heroicons/react/outline";
import moment from "moment";
import {
  GetExtensionMeetingsQueryQuery,
  GetExtensionMeetingsQueryQueryVariables,
} from "types/graphql-schema";

import Container from "@components/container/container";
import GraphqlError from "@components/error/graphql-error";
import AppLink from "@components/link/link";
import Loading from "@components/loading/loading";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { assertEdgesNonNull } from "@helpers/helpers";

import getExtensionMeetingsQuery from "../graphql/get-extension-meetings-query";

const ExtensionMeetings = () => {
  const { loading, error, data } = useQuery<
    GetExtensionMeetingsQueryQuery,
    GetExtensionMeetingsQueryQueryVariables
  >(getExtensionMeetingsQuery, {
    variables: {
      startToday: moment().startOf("day").format(),
      endToday: moment().endOf("day").format(),
    },
    onError: onNotificationErrorHandler(),
  });
  const meetings = data?.meetings ? assertEdgesNonNull(data.meetings) : [];

  // Renders
  if (error) {
    return (
      <Container>
        <div className="mx-4">
          <GraphqlError
            error={error}
            whatDidNotWork="The meetings could not be loaded."
          />
        </div>
      </Container>
    );
  }

  if (loading) {
    return (
      <Container>
        <div className="mx-4">
          <Loading>Loading meetings</Loading>
        </div>
      </Container>
    );
  }

  return (
    <div className="">
      <div className="flex justify-between">
        <div className="font-medium text-lg p-4 text-gray-600">
          Today's meetings
        </div>
        <div className="flex items-center px-4">
          <a
            target="_blank"
            href="/"
            className="p-1 rounded-md text-gray-600 hover:bg-black/5"
          >
            <ExternalLinkIcon className="w-5 h-5" />
          </a>
        </div>
      </div>
      <div className="w-full flex flex-col divide-y border-b border-t">
        {meetings.map((meeting) => (
          <AppLink
            to={`/extension/meeting/${meeting.meetingGroupId}/${meeting.id}`}
            key={meeting.id}
            className="py-4 block  px-4 group text-blue-600 bg-white"
          >
            <div className="group-hover:underline">{meeting.title}</div>
            <div className="mt-1 text-sm text-gray-500">
              {moment(meeting.startDatetime).format("H:mma")} -{" "}
              {moment(meeting.endDatetime).format("H:mma")}
            </div>
          </AppLink>
        ))}
      </div>
    </div>
  );
};

export default ExtensionMeetings;
