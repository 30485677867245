import Container from "@components/container/container";

const GoogleCalSetup = () => (
  <Container>
    <div className="p-4">
      <div className="bg-white rounded shadow p-8 mt-24 w-full sm:w-1/2 lg:w-1/3 sm:mx-auto text-center">
        <a
          href="/"
          className="block text-gray-700 font-normal text-3xl mb-10 mx-8 pb-8 border-b"
        >
          Topicflow
        </a>
        <p className="mb-4">
          Would you like to connect your account to Google Calendar, in order to
          automatically sync your meetings and events?
        </p>
        <a
          href="/sso/google"
          className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 30 30"
            className="h-5 w-5 fill-current text-white mr-2"
          >
            <path d="M 15.003906 3 C 8.3749062 3 3 8.373 3 15 C 3 21.627 8.3749062 27 15.003906 27 C 25.013906 27 27.269078 17.707 26.330078 13 L 25 13 L 22.732422 13 L 15 13 L 15 17 L 22.738281 17 C 21.848702 20.448251 18.725955 23 15 23 C 10.582 23 7 19.418 7 15 C 7 10.582 10.582 7 15 7 C 17.009 7 18.839141 7.74575 20.244141 8.96875 L 23.085938 6.1289062 C 20.951937 4.1849063 18.116906 3 15.003906 3 z"></path>
          </svg>
          Connect to Google Calendar
        </a>
      </div>
    </div>
  </Container>
);

export default GoogleCalSetup;
