import { gql } from "@apollo/client";

export default gql`
  query getTopicTemplateCategory($categoryId: Int!) {
    topicTemplateCategory(categoryId: $categoryId) {
      id
      title
      ordinal
      canUpdate {
        permission
      }
      canDelete {
        permission
      }
    }
  }
`;
