import { SearchCircleIcon } from "@heroicons/react/outline";
import { Content, Editor } from "@tiptap/react";
import { compact } from "lodash";
import {
  MouseEvent,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { ArtifactType } from "types/graphql-schema";

import ArtifactIcon from "@apps/artifact/components/artifact-icon";
import SearchModal from "@apps/search/search";
import useLabel from "@apps/use-label/use-label";
import Avatar from "@components/avatar/avatar";
import Loading from "@components/loading/loading";
import { artifactType } from "@helpers/constants";
import { classNames } from "@helpers/css";
import { getTiptapSchema } from "@helpers/helpers";

export const searchModalOption = {
  id: "search-modal",
  label: "Search all Topicflow",
};

type MentionItemType = { id: number | string; type: string; values: any[] };

export const MentionList = forwardRef(
  (
    props: {
      command: (item: MentionItemType) => void;
      editor: Editor;
      range: any;
      items: MentionItemType[];
      hideSearchModal: boolean;
      query: string;
    },
    ref
  ) => {
    const l = useLabel();
    const [isLoading, setIsLoading] = useState(false);
    const [isSearchModalOpened, setIsSearchModalOpened] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [optionsByType, setOptionsByType] = useState<any[]>([]);
    const options = optionsByType.reduce((allOptions, { values }) => {
      return allOptions.concat(values);
    }, [] as MentionItemType[]);

    const selectItem = (index: number) => {
      const item = options[index];
      if (item) {
        if (item.id === searchModalOption.id) {
          setIsSearchModalOpened(true);
        } else if (item.type === "user") {
          props.command(item);
        } else {
          props.editor
            .chain()
            .focus()
            .deleteRange(props.range)
            .insertContent({ type: item.type, attrs: item })
            .run();
        }
      }
    };

    const upHandler = () => {
      setSelectedIndex((selectedIndex + options.length - 1) % options.length);
    };

    const downHandler = () => {
      setSelectedIndex((selectedIndex + 1) % options.length);
    };

    const enterHandler = () => {
      selectItem(selectedIndex);
    };

    useEffect(() => {
      setIsLoading(false);
      setSelectedIndex(0);
      if (props.items === null) {
        return;
      }
      const users = props.items.filter(({ type }) => type === "user");
      const actionItems = props.items.filter(
        ({ type }) => type === artifactType.actionItem
      );
      const decisions = props.items.filter(
        ({ type }) => type === artifactType.decision
      );
      const recognitions = props.items.filter(
        ({ type }) => type === ArtifactType.Recognition
      );
      const goals = props.items.filter(
        ({ type }) => type === artifactType.goal
      );

      // Group options by type and give an index
      const data = compact([
        {
          id: "user",
          values: users,
        },
        {
          id: artifactType.goal,
          values: goals,
        },
        {
          id: artifactType.actionItem,
          values: actionItems,
        },
        {
          id: artifactType.decision,
          values: decisions,
        },
        {
          id: artifactType.recognition,
          values: recognitions,
        },
        !props.hideSearchModal && {
          id: searchModalOption.id,
          values: [searchModalOption],
        },
      ])
        .filter(({ values }) => values.length > 0)
        .reduce(
          ({ index, optionsByType }, optionType) => {
            const valuesWithIndex = optionType.values.map((val, i) => ({
              ...val,
              index: index + i,
            }));
            const newOptionsByType = optionsByType.concat({
              ...optionType,
              values: valuesWithIndex,
            });
            return {
              index: index + valuesWithIndex.length,
              optionsByType: newOptionsByType,
            };
          },
          {
            index: 0,
            optionsByType: [],
          } as { index: number; optionsByType: any[] }
        );
      setOptionsByType(data.optionsByType);
    }, [props.items]);

    useImperativeHandle(ref, () => ({
      onKeyDown: ({ event }: { event: KeyboardEvent }) => {
        if (event.key === "ArrowUp") {
          upHandler();
          return true;
        }

        if (event.key === "ArrowDown") {
          downHandler();
          return true;
        }

        if (event.key === "Enter" || event.key === "Tab") {
          enterHandler();
          return true;
        }
        setIsLoading(true);
        return false;
      },
    }));

    const handleSelectModalResult = (result: any) => {
      setIsSearchModalOpened(false);
      const content = getTiptapSchema(result) as Content;
      if (content) {
        props.editor
          .chain()
          .focus()
          .deleteRange(props.range)
          .insertContent(content)
          .run();
      } else {
        throw new Error(`Missing result: ${result.__typename}`);
      }
    };

    const handleClick =
      (index: number) => (e: MouseEvent<HTMLButtonElement>) => {
        selectItem(index);
        e.stopPropagation();
      };

    return (
      <div
        className="relative rounded bg-white border text-sm shadow js-wysiwyg-mention z-tooltip"
        aria-label="mention dropdown"
      >
        {isSearchModalOpened && (
          <SearchModal
            initialFilters={{ search: props.query }}
            onClose={() => setIsSearchModalOpened(false)}
            onClickResult={handleSelectModalResult}
          />
        )}
        {optionsByType.length > 0 &&
          optionsByType.map(({ id, values }) => (
            <div key={id}>
              {id && (
                <div className="uppercase py-0.5 text-2xs text-gray-600 font-medium px-3 bg-gray-200 w-36 min-w-full">
                  {id === "user"
                    ? "User"
                    : id === searchModalOption.id
                    ? "More results"
                    : l(id)}
                </div>
              )}
              {values.map((item: any) => (
                <button
                  className={classNames(
                    `block w-full text-left px-3 py-1.5 text-gray-800 hover:bg-gray-100 border-b border-gray-1`,
                    item.index === selectedIndex && "bg-gray-100"
                  )}
                  key={item.id}
                  onClick={handleClick(item.index)}
                >
                  {item.id === searchModalOption.id ? (
                    <div className="flex items-center">
                      <SearchCircleIcon className="h-4 w-4 text-gray-400 shrink-0" />
                      <span className="ml-2">{item.label}</span>
                    </div>
                  ) : id === "user" ? (
                    <div className="flex">
                      <Avatar
                        size="4"
                        user={item}
                        className="mt-0.5 shrink-0"
                      />
                      <span className="ml-2">{item.name}</span>
                    </div>
                  ) : (
                    <div className="flex items-center">
                      <ArtifactIcon
                        artifact={item}
                        size="4"
                        isStatic
                        className="shrink-0"
                      />
                      <span className="ml-2">{item.label}</span>
                    </div>
                  )}
                </button>
              ))}
            </div>
          ))}
        {optionsByType.length === 0 && (
          <div className="item w-36 px-2 py-1 text-gray-800">No result</div>
        )}
        {isLoading && (
          <div className="item w-36 px-3 py-1.5 text-gray-800 flex items-center gap-2">
            <Loading mini size="4" />
            <span>Loading</span>
          </div>
        )}
      </div>
    );
  }
);

export default MentionList;
