import { gql } from "@apollo/client";

export default gql`
  query getOrganizationCoreValues($organizationId: Int!) {
    organization(pk: $organizationId) {
      id
      coreValues {
        edges {
          node {
            id
            title
            description
            emoji
            image
            status
          }
        }
      }
    }
  }
`;
