import { ReactElement, Ref, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import DashboardUserSelector from "@apps/dashboard-new/dashboard-user-selector";
import MeetingDialog from "@apps/meeting-dialog/meeting-dialog";
import { MeetingsViewEnum } from "@apps/use-ui-preference-cache/use-ui-preference-cache";
import { currentUserVar } from "@cache/cache";
import Button, { buttonTheme } from "@components/button/button";
import Layout from "@components/layout/layout";
import { useLink } from "@components/link/link";
import {
  ToggleButtonGroup,
  ToggleButtonGroupTheme,
  ToggleButtonGroupType,
} from "@components/toggle-button-group/toggle-button-group";
import useUserComboboxQuery from "@components/user-combobox/use-user-combobox-query";
import UserCombobox from "@components/user-combobox/user-combobox";
import {
  UserComboboxOption,
  UserComboboxOptionType,
} from "@components/user-combobox/user-combobox-list";

const MeetingsLayout = ({
  view,
  selectedUser,
  children,
}: {
  selectedUser: UserComboboxOption;
  view: MeetingsViewEnum;
  children: ReactElement;
}) => {
  const { pathname } = useLocation();
  const currentUser = currentUserVar();
  const history = useHistory();
  const link = useLink();
  const {
    loading: loadingUserQuery,
    query,
    setQuery,
    options,
  } = useUserComboboxQuery({
    selected: selectedUser,
  });
  const [showCreateMeetingModal, setShowCreateMeetingModal] = useState(false);
  const currentPath =
    view === MeetingsViewEnum.calendar
      ? `calendar`
      : pathname.includes("/meetings/1-on-1")
      ? `meetings/1-on-1`
      : `meetings/group`;

  const handleCloseCreateMeeting = () => {
    setShowCreateMeetingModal(false);
  };

  const handleChangeView = (
    button: ToggleButtonGroupType<MeetingsViewEnum>
  ) => {
    if (button.value === MeetingsViewEnum.overview) {
      if (pathname.includes("/meetings/1-on-1")) {
        link.redirect(`/meetings/1-on-1/user/${selectedUser.id}`);
      } else {
        link.redirect(`/meetings/group/user/${selectedUser.id}`);
      }
    } else {
      link.redirect(`/calendar/user/${selectedUser.id}`);
    }
  };

  const handleChangeAssignee = (assignee: UserComboboxOption) => {
    history.push(`/${currentPath}/user/${assignee.id}`);
  };

  const handleClearAssignee = () => {
    history.push(`/${currentPath}/user/${currentUser.id}`);
  };

  return (
    <Layout
      aria-label="Calendar container"
      absoluteInsetPosition={view !== MeetingsViewEnum.calendar}
      className="@container/calendar fs-unmask flex-1"
    >
      {showCreateMeetingModal && (
        <MeetingDialog onClose={handleCloseCreateMeeting} />
      )}
      <Layout.Header
        title={
          <div className="flex items-center gap-4">
            <div className="w-48 sm:w-64">
              <UserCombobox
                disabled={loadingUserQuery}
                aria-label="Dashboard user dropdown"
                width="full"
                query={query}
                value={selectedUser}
                options={options}
                clearable={selectedUser && currentUser.id !== selectedUser.id}
                onChangeValue={handleChangeAssignee}
                onChangeQuery={setQuery}
                onClearValue={handleClearAssignee}
              >
                {({
                  value,
                  setReferenceElement,
                  clearable,
                  onClearValue,
                  onClickButton,
                }: {
                  value: UserComboboxOption | null;
                  setReferenceElement?: Ref<HTMLButtonElement>;
                  clearable: boolean;
                  onClearValue?: () => void;
                  onClickButton?: () => void;
                }) =>
                  value?.type === UserComboboxOptionType.USER ? (
                    <DashboardUserSelector
                      setReferenceElement={setReferenceElement}
                      value={value}
                      clearable={clearable}
                      onClearValue={onClearValue}
                      onClickButton={onClickButton}
                    />
                  ) : null
                }
              </UserCombobox>
            </div>
            {waffle.flag_is_active("meeting-overview") && (
              <ToggleButtonGroup<MeetingsViewEnum>
                theme={ToggleButtonGroupTheme.roundedNormal}
                buttons={[
                  {
                    title: "Overview",
                    value: MeetingsViewEnum.overview,
                    active: view === MeetingsViewEnum.overview,
                  },
                  {
                    title: "Calendar",
                    value: MeetingsViewEnum.calendar,
                    active: view === MeetingsViewEnum.calendar,
                  },
                ]}
                onClick={handleChangeView}
              />
            )}
          </div>
        }
      >
        <div className="flex items-center gap-2">
          <Button
            theme={buttonTheme.primary}
            aria-label="Calendar create meeting button"
            small
            onClick={() => setShowCreateMeetingModal(true)}
          >
            Create<span className="hidden sm:inline ml-1">meeting</span>
          </Button>
          {waffle.flag_is_active("meeting-overview") && (
            <Button
              theme={buttonTheme.default}
              aria-label="Calendar templates button"
              small
              to="/templates"
            >
              <span className="hidden sm:inline">Meeting templates</span>
              <span className="inline sm:hidden">Templates</span>
            </Button>
          )}
        </div>
      </Layout.Header>

      <Layout.Container>
        <Layout.Main fullWidth>{children}</Layout.Main>
      </Layout.Container>
    </Layout>
  );
};

export default MeetingsLayout;
