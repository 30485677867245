import { gql } from "@apollo/client";

import { DashboardTeamUserFragment } from "./dashboard-team-user-fragment";

export default gql`
  ${DashboardTeamUserFragment}
  query getDashboardSelectedUserQuery($userId: Int!) {
    user(userId: $userId) {
      ...DashboardTeamUserFragment
    }
  }
`;
