import { InformationCircleIcon } from "@heroicons/react/outline";

import Tooltip from "./tooltip";

const InfoTooltip = ({
  text,
  size = "4",
}: {
  text: string;
  size?: number | string;
}) => (
  <Tooltip text={text}>
    <span>
      <InformationCircleIcon className={`h-${size} w-${size} text-gray-400`} />
    </span>
  </Tooltip>
);

export default InfoTooltip;
