import { Route, Switch, useRouteMatch } from "react-router-dom";

import AppLink from "@components/link/link";
import useDocumentTitle from "@components/use-document-title/use-document-title";

import TemplateCategory from "./components/category";
import TemplateEdit from "./components/edit";
import TemplateList from "./components/list";
import TemplateView from "./components/view";

const Templates = () => {
  useDocumentTitle("Templates");
  const { path } = useRouteMatch();

  // RENDER
  return (
    <div aria-label="Templates" className="flex flex-col flex-1 fs-unmask">
      <div className="flex flex-wrap items-center justify-between h-14 px-4 sm:px-6 border-b bg-gray-50">
        <AppLink
          className="mb-2 sm:mb-0 text-2xl font-semibold mr-8 flex items-center"
          to="/templates"
        >
          Templates
        </AppLink>
      </div>
      <div className="p-4 sm:p-6">
        <div className="bg-white flex rounded-lg shadow-md overflow-hidden">
          <Switch>
            <Route exact path={[`${path}`, `${path}/group/:groupId`]}>
              <TemplateList />
            </Route>
            <Route
              exact
              path={[`${path}/category/new`, `${path}/category/:categoryId`]}
            >
              <TemplateCategory />
            </Route>
            <Route
              exact
              path={[
                `${path}/new`,
                `${path}/new/:duplicateId`,
                `${path}/:templateId(\\d+)/edit`,
                `${path}/:templateId(\\d+)/duplicate`,
              ]}
            >
              <TemplateEdit />
            </Route>
            <Route exact path={`${path}/:templateId(\\d+)`}>
              <TemplateView />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default Templates;
