import { gql } from "@apollo/client";

export default gql`
  mutation removeSalesforceIntegrationMutation {
    removeSalesforceIntegration {
      user {
        id
        hasSalesforceAuth
      }
    }
  }
`;
