import { useCallback } from "react";

import cache, { currentUserVar } from "@cache/cache";
import usePusher from "@components/use-pusher/use-pusher";

export default function PusherRecallBotStatusChange({
  channelName,
}: {
  channelName: string;
}) {
  const currentUser = currentUserVar();

  const handleChange = useCallback(
    ({
      meeting_id,
      is_recording,
      is_in_meeting,
      is_joining_meeting,
    }: {
      meeting_id: number;
      is_recording: boolean;
      is_in_meeting: boolean;
      is_joining_meeting: boolean;
    }) => {
      const meetingCacheId = cache.identify({
        id: meeting_id,
        __typename: "MeetingNode",
      });
      if (meetingCacheId) {
        cache.modify({
          id: meetingCacheId,
          fields: {
            hasBotInMeeting() {
              return is_in_meeting;
            },
            botIsRecording() {
              return is_recording;
            },
            botIsWaitingToJoinMeeting() {
              return is_joining_meeting;
            },
          },
        });
      }
    },
    [currentUser]
  );

  usePusher(channelName, "recall_bot_status_change", handleChange);

  return null;
}
