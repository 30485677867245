import { useMutation, useQuery } from "@apollo/client";
import { CheckIcon } from "@heroicons/react/solid";
import githubLogo from "@static/img/github.png";
import salesforceLogo from "@static/img/salesforce.png";
import slackLogo from "@static/img/slack.png";
import teamsLogo from "@static/img/teams-64.png";
import moment from "moment";
import { useCallback } from "react";

import useLabel from "@apps/use-label/use-label";
import { successNotificationVar } from "@cache/cache";
import Button, { buttonTheme } from "@components/button/button";
import AppLink from "@components/link/link";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { assertNonNull, getAssetUrl } from "@helpers/helpers";

import {
  GetOrganizationIntegrationsQuery,
  GetOrganizationIntegrationsQueryVariables,
  IntegrationTypes,
} from "../../../types/graphql-schema";
import getOrganizationIntegrations from "../graphql/get-organization-integrations";
import removeGithubFromOrganizationMutation from "../graphql/remove-github-from-organization-mutation";
import removeSlackIntegrationMutation from "../graphql/remove-slack-integration-mutation";
import unlinkOrganizationFromIntegrationMutation from "../graphql/unlink-organization-from-integration-mutation";

export const IntegrationRow = ({
  logoAssetUrl,
  logoAlt,
  text,
  buttons,
  connections,
}: {
  logoAssetUrl: string | URL;
  logoAlt: string;
  text: JSX.Element;
  buttons: JSX.Element;
  connections?: JSX.Element | null;
}) => (
  <li className="flex gap-8 py-6 border-0 border-b border-gray-200">
    <div className="col font-medium mb-4 flex items-start w-28 shrink-0">
      <div className="border rounded flex justify-center items-center h-24 w-28 p-4">
        <img
          src={getAssetUrl(logoAssetUrl)}
          alt={logoAlt}
          className="object-contain h-16"
        />
      </div>
    </div>
    <div className="flex-1">
      {text}
      <div>
        {connections ? (
          <div className="text-xl font-bold">Connections</div>
        ) : null}
        {connections}
      </div>
    </div>
    <div className="w-64 shrink-0 flex flex-col gap-2 items-center">
      {buttons}
    </div>
  </li>
);

const Integrations = ({ organization }: { organization: any }) => {
  const label = useLabel();

  const { data } = useQuery<
    GetOrganizationIntegrationsQuery,
    GetOrganizationIntegrationsQueryVariables
  >(getOrganizationIntegrations, {
    variables: { organizationId: organization.id },
    onError: onNotificationErrorHandler(),
  });

  const [unlinkOrganizationFromIntegration] = useMutation(
    unlinkOrganizationFromIntegrationMutation
  );

  const salesforceIntegrations = data?.organization?.integrations.edges
    .filter(
      (edge) => edge?.node?.integrationType === IntegrationTypes.Salesforce
    )
    .map((edge) => assertNonNull(edge?.node));
  const [removeSlackIntegration] = useMutation(removeSlackIntegrationMutation);
  const [removeGithub] = useMutation(removeGithubFromOrganizationMutation);
  const handleRemoveSlackIntegration = () => {
    removeSlackIntegration({
      variables: { organizationId: organization.id },
      onError: onNotificationErrorHandler(),
    });
  };
  const handleRemoveGithub = useCallback(() => {
    removeGithub({
      variables: { organizationId: organization.id },
      onError: onNotificationErrorHandler(),
      onCompleted: () => {
        successNotificationVar({ title: "GitHub integration removed" });
      },
    });
  }, [organization.id, removeGithub]);
  const handleRemoveSalesforceIntegration = (id: number) => {
    unlinkOrganizationFromIntegration({
      variables: {
        organizationIntegrationId: id,
      },
      onError: onNotificationErrorHandler(),
    });
  };

  return (
    <div>
      <h2 className="text-2xl pb-2 font-medium text-gray-800">Integrations</h2>
      <ul className="bg-white py-4" aria-label="Integration list">
        <IntegrationRow
          logoAlt={"Slack"}
          logoAssetUrl={slackLogo}
          text={
            <>
              <p>Connect Slack to your organization.</p>
              <ul className="list-disc ml-4 py-2 italic">
                <li>Post meeting agendas and summaries to Slack</li>
                <li>
                  <AppLink
                    to={`/settings/organization/${organization.id}/notifications`}
                    className="hover:underline text-blue-600"
                  >
                    Send notifications
                  </AppLink>{" "}
                  of{" "}
                  {label("recognition", {
                    pluralize: true,
                    capitalize: false,
                  })}{" "}
                  and{" "}
                  {label("goal", {
                    pluralize: false,
                    capitalize: false,
                  })}{" "}
                  check-ins to Slack
                </li>
              </ul>
            </>
          }
          buttons={
            organization.hasSlackConnection ? (
              <div>
                <Button
                  theme={buttonTheme.green}
                  rounded
                  className="mx-3 flex"
                  url={`/sso/slack?next=/settings/organization/${organization.id}/integrations&org=${organization.id}`}
                >
                  <CheckIcon className="text-green-500 h-5 w-5 mr-2" />
                  Connected
                </Button>
                <Button
                  theme={buttonTheme.text}
                  onClick={() => {
                    handleRemoveSlackIntegration();
                  }}
                  className="font-light text-xs mt-2 mx-3 py-1 px-2 flex"
                >
                  Disconnect from Slack
                </Button>
              </div>
            ) : (
              <Button
                rounded
                url={`/sso/slack?next=/settings/organization/${organization.id}/integrations&org=${organization.id}`}
              >
                Connect to Slack
              </Button>
            )
          }
        />
        <IntegrationRow
          logoAlt={"Salesforce"}
          logoAssetUrl={salesforceLogo}
          text={
            <>
              <p>Connect your Topicflow and Salesforce accounts.</p>
              <ul className="list-disc ml-4 py-2 italic">
                <li>
                  Preview Salesforce Opportunities in Topicflow meeting notes
                </li>
                <li>Link Salesforce data to Topicflow KPIs</li>
              </ul>
            </>
          }
          buttons={
            salesforceIntegrations && salesforceIntegrations.length > 0 ? (
              <>
                <Button theme={buttonTheme.green} rounded className="flex">
                  <CheckIcon className="text-green-500 h-5 w-5 mr-2" />
                  Connected
                </Button>
              </>
            ) : (
              <Button
                rounded
                url={`/sso/salesforce?next=/settings/organization/${organization.id}/integrations&integration_link_to_org_id=${organization.id}`}
              >
                Connect to Salesforce
              </Button>
            )
          }
          connections={
            salesforceIntegrations?.length ? (
              <ul>
                {salesforceIntegrations?.map((integration) => (
                  <li key={integration.id} className="flex my-2">
                    <span className="font-light text-xs">
                      Connected to{" "}
                      {
                        JSON.parse(integration.socialAuth.extraData)
                          .instance_url
                      }{" "}
                      by {integration.creator.name} on{" "}
                      {moment(integration.created).format("MMM D, YYYY")}
                    </span>
                    <Button
                      theme={buttonTheme.redDanger}
                      onClick={() => {
                        handleRemoveSalesforceIntegration(integration.id);
                      }}
                      className="font-light text-xs py-1 px-2"
                    >
                      Disconnect
                    </Button>
                  </li>
                ))}

                <li className="mt-4">
                  <Button
                    theme={buttonTheme.green}
                    rounded
                    url={`/sso/salesforce?next=/settings/organization/${organization.id}/integrations&integration_link_to_org_id=${organization.id}`}
                  >
                    Add Additional Connection
                  </Button>
                </li>
              </ul>
            ) : null
          }
        />
        <IntegrationRow
          logoAssetUrl={teamsLogo}
          logoAlt="Teams"
          buttons={
            organization.hasTeamsTranscriptConnection ? (
              <Button theme={buttonTheme.green} rounded className="flex">
                <CheckIcon className="text-green-500 h-5 w-5 mr-2" />
                Connected
              </Button>
            ) : (
              <Button
                rounded
                url={`/sso/microsoft?request_transcript_scopes=true&request_calendar_scope=true&next=/msteams/subscribe_to_transcripts?org_id=${organization.id}`}
              >
                Connect to Teams
              </Button>
            )
          }
          text={
            <div>
              <p>
                Connect your organization to Teams to automatically synchronize
                transcripts from your Teams meetings to Topicflow
              </p>
              <p className="mt-8">
                See{" "}
                <a href="https://www.topicflow.com/docs/teams-transcription/">
                  https://www.topicflow.com/docs/teams-transcription/
                </a>{" "}
                for instructions on how to configure this integration.
                Specifically, you must perform some Microsoft application
                configuration before clicking the "Connect to Teams" button
              </p>
            </div>
          }
        />
        <IntegrationRow
          logoAlt={"Github"}
          logoAssetUrl={githubLogo}
          text={
            <>
              <p>Connect your Topicflow and GitHub accounts.</p>
              <ul className="list-disc ml-4 py-2 italic">
                <li>
                  Preview GitHub issues and pull requests in Topicflow meeting
                  notes
                </li>
                <li>
                  Search for GitHub issues and pull requests from the Explorer,
                  and embed lists in meeting notes
                </li>
                <li>Link GitHub metrics to Topicflow KPIs</li>
              </ul>
              <p className="font-bold uppercase py-2 text-gray-400">Note:</p>
              <ul className="list-disc ml-4 text-gray-400">
                <li>
                  Install the Topicflow GitHub app for the organization you want
                  to link with Topicflow
                </li>
              </ul>
            </>
          }
          buttons={
            organization.hasGithubInstallation ? (
              <>
                <Button
                  theme={buttonTheme.green}
                  rounded
                  className="flex"
                  onClick={handleRemoveGithub}
                >
                  <CheckIcon className="text-green-500 h-5 w-5 mr-2" />
                  Connected
                </Button>
              </>
            ) : (
              <Button
                rounded
                url={
                  import.meta.env.DEV
                    ? `https://github.com/apps/topicflow-dev/installations/select_target`
                    : `https://github.com/apps/topicflow/installations/select_target`
                }
              >
                Connect to Github
              </Button>
            )
          }
        />
      </ul>
    </div>
  );
};

export default Integrations;
