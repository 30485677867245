import { useMutation } from "@apollo/client";
import { Editor } from "@tiptap/core";
import moment from "moment";
import { FormEvent, KeyboardEvent, MouseEvent, useState } from "react";
import { useRef } from "react";
import { useBeforeunload } from "react-beforeunload";
import { Prompt } from "react-router";
import {
  CreateCommentMutationMutation,
  CreateCommentMutationMutationVariables,
  GetCommentsQuery,
  TopicNode,
} from "types/graphql-schema";

import { currentUserVar } from "@cache/cache";
import Button from "@components/button/button";
import GraphqlError from "@components/error/graphql-error";
import Form from "@components/form/form";
import Loading from "@components/loading/loading";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import CommentWYSIWYG from "@components/wysiwyg/comment-wysiwyg";
import { isEmptyValue } from "@components/wysiwyg/helpers";
import {
  assertNonNull,
  clearLocalstorageComment,
  getLocalstorageCommentForArtifactId,
  getLocalstorageCommentForTopicId,
} from "@helpers/helpers";

import createCommentMutation from "../graphql/create-comment-mutation";
import getCommentsQuery from "../graphql/get-comments-query";

const CommentForm = ({
  topic,
  topicId,
  artifact,
  artifactId,
  meetingId,
  meetingGroupId,
  autofocus = false,
  onSavedComment,
}: {
  topic?: TopicNode;
  topicId?: number;
  artifact?: {
    id: number;
  };
  artifactId?: number;
  meetingId?: number;
  meetingGroupId?: number;
  autofocus?: boolean;
  onSavedComment?: (comment: any) => void;
}) => {
  const currentUser = currentUserVar();
  const key = artifactId ? `artifact-${artifactId}` : `topic-${topicId}`;
  const defaultComment = artifactId
    ? getLocalstorageCommentForArtifactId(artifactId)
    : topicId
    ? getLocalstorageCommentForTopicId(topicId)
    : null;
  const wysiwygRef = useRef<Editor>(null);
  const [showCommentButton, setShowCommentButton] = useState(autofocus);
  const [comment, setComment] = useState(defaultComment);
  const [createComment, { loading, error }] = useMutation<
    CreateCommentMutationMutation,
    CreateCommentMutationMutationVariables
  >(createCommentMutation, {
    onCompleted: (response) => {
      clearLocalstorageComment();
      wysiwygRef.current?.chain().setContent("").run();
      setComment("");
      if (onSavedComment) {
        onSavedComment(assertNonNull(response.createOrUpdateComment?.comment));
      }
    },
    update: (cache, { data }) => {
      const newCommentFromResponse = data?.createOrUpdateComment?.comment;
      const existingData = cache.readQuery<GetCommentsQuery>({
        query: getCommentsQuery,
        variables: { topicId, artifactId },
      });
      if (newCommentFromResponse && existingData) {
        const newEdges = [
          ...(existingData.comments?.edges || []),
          { node: newCommentFromResponse, __typename: "CommentNodeEdge" },
        ];

        cache.writeQuery({
          query: getCommentsQuery,
          variables: { topicId, artifactId },
          data: {
            ...existingData,
            comments: {
              ...existingData.comments,
              totalCount: newEdges.length,
              edges: newEdges,
            },
          },
        });
      }
    },
  });

  // Hooks
  useBeforeunload((event) => {
    if (!isEmptyValue(comment)) {
      event.preventDefault();
    }
  });

  // Handlers
  const handleSubmitForm = (e: FormEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleSaveForm = (e: MouseEvent<HTMLButtonElement> | KeyboardEvent) => {
    e.preventDefault();
    if (isEmptyValue(comment)) {
      return;
    }
    const commentId = -1;
    const variables: {
      meetingId?: number;
      meetingGroupId?: number;
      topicId?: number;
      artifactId?: number;
      comment: string;
    } = {
      topicId,
      artifactId,
      comment: JSON.stringify(comment),
      meetingId: undefined,
      meetingGroupId: undefined,
    };
    if (meetingId) {
      variables.meetingId = Number(meetingId);
    } else if (meetingGroupId) {
      variables.meetingGroupId = Number(meetingGroupId);
    }
    createComment({
      variables,
      optimisticResponse: {
        __typename: "Mutation",
        createOrUpdateComment: {
          __typename: "CreateOrUpdateCommentMutation",
          comment: {
            __typename: "CommentNode",
            id: commentId,
            comment: comment,
            created: moment.utc().format(),
            creator: currentUser,
            topic: null,
            artifact: null,
          },
        },
      },
      onError: onNotificationErrorHandler(),
    });
  };

  const handleChangeComment = (value: any) => {
    localStorage.setItem(
      "comment",
      JSON.stringify({ topicId, artifactId, comment: value })
    );
    setComment(value);
  };

  const handleCancelCommentForm = () => {
    handleChangeComment(null);
    if (wysiwygRef.current) {
      wysiwygRef.current.chain().clearContent().run();
    }
    setShowCommentButton(false);
  };

  // Render
  return (
    <Form
      onSubmit={handleSubmitForm}
      onCMDEnter={handleSaveForm}
      data-testid="comment-form"
      aria-label="Create comment form"
    >
      <Prompt
        when={!isEmptyValue(comment)}
        message="Leave page? Changes you made may not be saved."
      />
      <div>
        <label htmlFor="comment" className="sr-only">
          Comment
        </label>
        <div className="shadow-sm block w-full">
          <CommentWYSIWYG
            ref={wysiwygRef}
            key={key}
            autofocus={autofocus}
            editable
            meetingGroupId={meetingGroupId}
            className="px-3 py-1 bg-white border rounded-md text-sm"
            value={comment}
            onChange={handleChangeComment}
            onFocus={() => setShowCommentButton(true)}
            topicId={topicId}
            uploadVariable={{ topicId: topic?.id, artifactId: artifact?.id }}
            mentionsConfig={{
              meetingGroupId,
              meetingId,
              artifactId: artifact?.id,
            }}
            placeholder="Leave a comment"
          />
        </div>
      </div>
      {showCommentButton && (
        <div className="mt-2 flex items-center justify-end space-x-2">
          {loading && <Loading mini size="4" />}
          <Button
            type="button"
            small
            text="Cancel"
            onClick={handleCancelCommentForm}
          />
          <Button
            type="button"
            small
            text={loading ? "Saving comment..." : "Comment"}
            theme="primary"
            aria-label="Comment form submit button"
            disabled={isEmptyValue(comment) || loading}
            onClick={handleSaveForm}
          />
        </div>
      )}
      {error && (
        <div className="mt-2">
          <GraphqlError
            error={error}
            whatDidNotWork="The comment could not be saved."
          />
        </div>
      )}
    </Form>
  );
};

export default CommentForm;
