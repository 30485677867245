import { gql } from "@apollo/client";

export default gql`
  mutation removeGitHubIntegrationMutation {
    removeGithubIntegration {
      user {
        id
        hasGithubAuth
      }
    }
  }
`;
