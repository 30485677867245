import { gql } from "@apollo/client";

import GoalAlignmentDialogGoalFragment from "./goal-alignment-dialog-goal-fragment";

export default gql`
  ${GoalAlignmentDialogGoalFragment}
  query getGoalToAlignQuery($artifactId: Int!) {
    artifact(artifactId: $artifactId) {
      ...GoalAlignmentDialogGoalFragment
      ... on GoalArtifactNode {
        childGoals {
          edges {
            node {
              id
            }
          }
        }
      }
    }
  }
`;
