import { gql } from "@apollo/client";

export default gql`
  query getTeamMembersQuery($teamId: Int!) {
    team(teamId: $teamId) {
      id
      title

      members {
        edges {
          node {
            id
            name
            firstName
            email
            avatar
          }
        }
      }
    }
  }
`;
