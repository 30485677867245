import { useEffect } from "react";

const defaultTitle = window.TF.debug ? "(DEV) Topicflow" : "Topicflow";

function useDocumentTitle(title: string, resetToDefaultOnUnmount = true) {
  useEffect(() => {
    document.title = `${defaultTitle}${title ? ` - ${title}` : ``}`;
  }, [title]);

  useEffect(
    () => () => {
      if (resetToDefaultOnUnmount) {
        document.title = defaultTitle;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
}

export default useDocumentTitle;
