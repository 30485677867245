import { gql } from "@apollo/client";

import syncCredentialFragment from "./sync-credential-fragment";

export default gql`
  ${syncCredentialFragment}
  mutation linkCalendarToOrganizationMutation(
    $credentialsId: Int!
    $organizationId: Int!
  ) {
    linkCalendarToOrganization(
      credentialsId: $credentialsId
      organizationId: $organizationId
    ) {
      user {
        id
        syncCredentials {
          edges {
            node {
              ...AccountSyncCredentialFragment
            }
          }
        }
      }
    }
  }
`;
