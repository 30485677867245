import { gql } from "@apollo/client";

import CommonGoalArtifactFragment from "@graphql/common-goal-artifact-fragment";

export default gql`
  ${CommonGoalArtifactFragment}
  query getRelatedTopicArtifacts(
    $topicId: Int!
    $meetingGroupId: Int!
    $after: String
  ) {
    topic(topicId: $topicId) {
      id
      artifacts {
        edges {
          node {
            id
            artifactType
            title
            canDelete {
              permission
            }
            ... on ActionItemArtifactNode {
              isComplete
              dueDate
              assignee {
                id
                name
                avatar
              }
            }
            ...CommonGoalArtifactFragment
            ... on DecisionArtifactNode {
              decision
              decisionState
              isDraft
            }
          }
        }
      }
      meeting {
        id
        meetingGroupId
        startDatetime
      }
      relatedTopics(
        orderBy: "-meeting__start_datetime"
        hasArtifacts: true
        meetingInMeetingGroupId: $meetingGroupId
        after: $after
        first: 20
      ) {
        totalCount
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            id
            title
            meeting {
              id
              meetingGroupId
              startDatetime
            }
            artifacts(
              relatedToTopic: $topicId
              meetingGroupId: $meetingGroupId
              artifactTypes: [
                action_item
                decision
                goal
                recognition
                document
              ]
            ) {
              edges {
                node {
                  id
                  artifactType
                  title
                  canDelete {
                    permission
                  }
                  created
                  updated
                  creator {
                    id
                    name
                    avatar
                  }
                  ... on GoalArtifactNode {
                    ...CommonGoalArtifactFragment
                  }
                  ... on ActionItemArtifactNode {
                    isComplete
                    assignee {
                      id
                      name
                      avatar
                    }
                  }
                  ... on RecognitionArtifactNode {
                    recipients {
                      totalCount
                      edges {
                        node {
                          id
                          name
                          avatar
                        }
                      }
                    }
                  }
                  ... on DecisionArtifactNode {
                    decision
                    decisionState
                    isDraft
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
