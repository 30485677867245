import { gql } from "@apollo/client";

import KPIFragment from "./kpi-fragment";
import KPISummarizedMeasurementFragment from "./kpi-summarized-measurement-fragment";

export default gql`
  ${KPIFragment}
  ${KPISummarizedMeasurementFragment}
  query getKpiSummaryQuery(
    $kpiId: Int!
    $summaryPeriod: KPISummaryPeriod
    $summaryMethod: KPISummaryMethod
    $first: Int = 20
  ) {
    kpi(kpiId: $kpiId) {
      ...KPIFragment
      summarizedMeasurements(
        first: $first
        summaryMethod: $summaryMethod
        summaryPeriod: $summaryPeriod
      ) {
        edges {
          node {
            ...KPISummarizedMeasurementFragment
          }
        }
      }
    }
  }
`;
