import { useQuery } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { ReactElement } from "react";
import {
  GetNewDashboardFeedbackQueryQuery,
  GetNewDashboardFeedbackQueryQueryVariables,
} from "types/graphql-schema";

import Artifact from "@apps/artifact/artifact";
import useUiPreferenceCache, {
  UiPreferenceCache,
} from "@apps/use-ui-preference-cache/use-ui-preference-cache";
import Error from "@components/error/error";
import Layout from "@components/layout/layout";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { assertEdgesNonNull } from "@helpers/helpers";

import getDashboardFeedbackQuery from "../graphql/get-dashboard-feedback-query";

const FeedbackCollapsibleContainer = ({
  title,
  filters,
  titleRightSide = null,
  localStorageKey,
}: {
  title: string;
  localStorageKey: keyof UiPreferenceCache;
  roundedBottom?: boolean;
  filters: GetNewDashboardFeedbackQueryQueryVariables;
  titleRightSide?: ReactElement | null;
}) => {
  const { uiPreferenceCache } = useUiPreferenceCache();

  const { loading, data, fetchMore } = useQuery<
    GetNewDashboardFeedbackQueryQuery,
    GetNewDashboardFeedbackQueryQueryVariables
  >(getDashboardFeedbackQuery, {
    notifyOnNetworkStatusChange: true,
    variables: {
      after: null,
      ...filters,
      fetchData: !!uiPreferenceCache[localStorageKey],
    },
    onError: onNotificationErrorHandler(),
  });
  const totalCount = data?.artifacts?.totalCount;
  const feedback = data?.artifacts?.edges
    ? assertEdgesNonNull(data.artifacts)
    : [];

  const handleClickMore = () => {
    if (data?.artifacts) {
      fetchMore({
        variables: {
          after: data.artifacts.pageInfo.endCursor,
          merge: true,
        },
      });
    }
  };

  return (
    <Layout.SidebarSubSection
      expandedUiPreferenceKey={localStorageKey}
      title={title}
      titleRightSide={titleRightSide}
      loading={feedback.length === 0 && loading}
      count={totalCount}
    >
      <Layout.SidebarSubSectionList
        aria-label={`${title} feedback dashboard container`}
        loading={loading}
        hasNextPage={!!data?.artifacts?.pageInfo.hasNextPage}
        onClickMore={handleClickMore}
      >
        {feedback.map(
          (feedbackItem) =>
            feedbackItem.__typename === "FeedbackArtifactNode" && (
              <Layout.SidebarSubSectionListItem key={feedbackItem.id}>
                <Artifact artifact={feedbackItem} />
              </Layout.SidebarSubSectionListItem>
            )
        )}
      </Layout.SidebarSubSectionList>
    </Layout.SidebarSubSection>
  );
};

export default Sentry.withErrorBoundary(FeedbackCollapsibleContainer, {
  fallback: <Error description={"The feedback could not be rendered."} />,
});
