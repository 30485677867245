import { useMutation, useQuery } from "@apollo/client";
import { range } from "lodash";
import {
  ChangeEventHandler,
  FormEventHandler,
  MouseEventHandler,
  useState,
} from "react";
import { MdRadioButtonUnchecked } from "react-icons/md";
import { useParams } from "react-router-dom";
import {
  CreateOrUpdateRatingMutationMutation,
  CreateOrUpdateRatingMutationMutationVariables,
  GetRatingQuery,
  GetRatingQueryVariables,
} from "types/graphql-schema";

import { currentOrganizationVar } from "@cache/cache";
import Button, { buttonTheme } from "@components/button/button";
import Input, { InputLabel } from "@components/input/input";
import { useLink } from "@components/link/link";
import Loading from "@components/loading/loading";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import TextareaWysiwyg from "@components/wysiwyg/textarea-wysiwyg";
import { classNames } from "@helpers/css";
import { assertNonNull, parseStringToJSON } from "@helpers/helpers";

import createOrUpdateRatingMutation from "../graphql/create-or-update-rating-mutation";
import getRatingQuery from "../graphql/get-rating-query";
import { getRatingScaleClassName } from "../helpers";

const scaleMin = 1;
const scaleMax = 10;

type Rating = {
  title: string;
  description: string | null;
  startValue: number;
  endValue: number;
  labels: (string | null)[];
};

type Props = {
  onCancel?: () => void | null;
  onSave?: (rating: { id: number; title: string }) => void | null;
};

const RatingsEdit: React.FC<Props> = ({ onCancel = null, onSave = null }) => {
  const link = useLink();
  const { ratingId: ratingIdParam } = useParams<{ ratingId: string }>();
  const ratingId = parseInt(ratingIdParam);
  const currentOrganization = currentOrganizationVar();
  const [form, setForm] = useState<Rating>({
    title: "",
    description: null,
    startValue: 1,
    endValue: 5,
    labels: ["", "", "", "", ""],
  });
  const { loading } = useQuery<GetRatingQuery, GetRatingQueryVariables>(
    getRatingQuery,
    {
      variables: { ratingId },
      skip: !ratingId,
      onCompleted: (response) => {
        const rating = assertNonNull(response.rating);
        setForm({
          ...form,
          title: rating.title,
          description: parseStringToJSON(rating.description),
          startValue: rating.startValue,
          endValue: rating.endValue,
          labels: [...rating.labels],
        });
      },
      onError: onNotificationErrorHandler(),
    }
  );

  const canSubmit = form.title.trim().length > 0;
  const scaleStartValue = Math.max(1, form.startValue);
  const scaleEndValue = Math.min(scaleMax, form.endValue);
  const scaleDiff = scaleEndValue - scaleStartValue + 1;
  const gridScaleClassName = getRatingScaleClassName(
    scaleStartValue,
    scaleEndValue
  );

  const [createOrUpdateRating, { loading: loadingSave }] = useMutation<
    CreateOrUpdateRatingMutationMutation,
    CreateOrUpdateRatingMutationMutationVariables
  >(createOrUpdateRatingMutation);

  const handleSubmitForm: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
  };

  const handleSaveForm = () => {
    const labels = range(0, scaleDiff).map((x) => {
      return form.labels?.[x] || "";
    });
    createOrUpdateRating({
      variables: {
        ...form,
        labels,
        organizationId: currentOrganization?.id,
        ratingId,
      },
      onError: onNotificationErrorHandler(),
      onCompleted: (response) => {
        const createdRating = assertNonNull(
          response.createOrUpdateRating?.rating
        );
        if (onSave) {
          onSave(createdRating);
        } else {
          link.redirect(`/ratings/${createdRating.id}`);
        }
      },
    });
  };

  const handleChangeLabel =
    (labelIndexX: number): ChangeEventHandler<HTMLInputElement> =>
    (e) => {
      const labels = range(0, scaleDiff).map((x) => {
        if (x === labelIndexX) {
          return e.target.value;
        }
        return form.labels?.[x] || "";
      });
      setForm({ ...form, labels });
    };

  const handleChangeStartValue: ChangeEventHandler<HTMLInputElement> = (e) => {
    const startValue = parseInt(e.target.value);
    setForm({ ...form, startValue });
  };
  const handleChangeEndValue: ChangeEventHandler<HTMLInputElement> = (e) => {
    const endValue = parseInt(e.target.value);
    setForm({ ...form, endValue });
  };
  const handleSelectAlTextOnClick: MouseEventHandler<HTMLInputElement> = (
    e
  ) => {
    const target = e.target as HTMLInputElement;
    target.select();
  };
  const handleChangeDescription = (description: string) => {
    setForm({ ...form, description });
  };

  // RENDER
  return (
    <form
      className="w-full flex flex-col gap-6"
      aria-label="Ratings form"
      onSubmit={handleSubmitForm}
    >
      <div className="text-xl font-medium">
        {ratingId ? "Edit" : "Create"} rating
      </div>
      {loading ? (
        <Loading>Loading rating</Loading>
      ) : (
        <div className="w-full flex flex-col gap-6">
          <div className="w-full">
            <Input
              label="Title"
              aria-label="Rating title input"
              className="w-full"
              value={form.title}
              placeholder="Rating title..."
              onChange={(e) => setForm({ ...form, title: e.target.value })}
            />
          </div>
          <div className="w-full">
            <InputLabel label="Description" />
            <TextareaWysiwyg
              editable
              className="mt-1"
              value={form.description}
              onChangeValue={handleChangeDescription}
            />
          </div>
          <div>
            <InputLabel label="Scale" />
            <div className="mt-1 flex gap-6">
              <Input
                aria-label="Rating start value input"
                className="w-18"
                type="number"
                min={scaleMin}
                max={Math.min(scaleMax, scaleEndValue - 1)}
                step={1}
                pattern="\d*"
                value={form.startValue}
                placeholder="Start value"
                onChange={handleChangeStartValue}
              />
              <Input
                aria-label="Rating end value input"
                className="w-18"
                type="number"
                min={Math.max(scaleMin, scaleStartValue + 1)}
                max={scaleMax}
                step={1}
                pattern="\d*"
                value={form.endValue}
                placeholder="End value"
                onChange={handleChangeEndValue}
              />
            </div>
          </div>
          <div>
            <InputLabel label="Labels" />
            <div className={classNames("mt-1 gap-2 grid", gridScaleClassName)}>
              {range(form.startValue, form.endValue + 1).map(
                (value, indexX) => (
                  <span
                    key={value}
                    className="flex flex-col gap-1 items-center border bg-gray-100 rounded p-1"
                  >
                    <input
                      type="text"
                      value={form.labels?.[indexX] || ""}
                      onChange={handleChangeLabel(indexX)}
                      className="border rounded text-sm py-0.5 px-1 text-center w-full"
                      onClick={handleSelectAlTextOnClick}
                    />
                    <MdRadioButtonUnchecked className="h-4 w-4 text-gray-500" />
                  </span>
                )
              )}
            </div>
          </div>
          <div className="flex gap-4 items-center">
            <Button
              text="Save"
              type="submit"
              theme={buttonTheme.primary}
              disabled={loadingSave || !canSubmit}
              onClick={handleSaveForm}
            />
            <Button
              text="Cancel"
              to={
                onCancel
                  ? undefined
                  : ratingId
                  ? `/ratings/${ratingId}`
                  : "/ratings"
              }
              onClick={onCancel ? onCancel : undefined}
            />
            {loadingSave && <Loading mini size="4" />}
          </div>
        </div>
      )}
    </form>
  );
};

export default RatingsEdit;
