import { useMutation, useQuery } from "@apollo/client";
import { InformationCircleIcon } from "@heroicons/react/outline";
import { max, omit, range } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  CareerTrackRoleInput,
  CareerTrackRoleType,
  CompetencyAppliesTo,
  CompetencyCriteriaUniqueness,
  GetOrganizationCareerTrackQuery,
  GetOrganizationCareerTrackQueryVariables,
  GetOrganizationCompetenciesQuery,
  GetOrganizationCompetenciesQueryVariables,
  SaveOrgWideCareerTrackMutation,
  SaveOrgWideCareerTrackMutationVariables,
} from "types/graphql-schema";
import { v4 as uuidv4 } from "uuid";

import { competencyInfo } from "@apps/org-settings/constants";
import createOrUpdateCareerTrackMutation from "@apps/org-settings/graphql/create-or-update-career-track-mutation";
import getOrganizationCareerTrackQuery from "@apps/org-settings/graphql/get-organization-career-track-query";
import getOrganizationCompetenciesQuery from "@apps/org-settings/graphql/get-organization-competencies-query";
import useLabel from "@apps/use-label/use-label";
import { successNotificationVar } from "@cache/cache";
import Button, { buttonTheme } from "@components/button/button";
import Heading from "@components/heading/heading";
import Input from "@components/input/input";
import { useLink } from "@components/link/link";
import Loading from "@components/loading/loading";
import { useNotificationError } from "@components/use-error/use-error";
import useUserComboboxQuery from "@components/user-combobox/use-user-combobox-query";
import UserCombobox from "@components/user-combobox/user-combobox";
import {
  UserComboboxOption,
  UserComboboxOptionType,
} from "@components/user-combobox/user-combobox-list";
import { assertEdgesNonNull, assertNonNull } from "@helpers/helpers";
import useUrlQueryParams from "@helpers/hooks/use-url-query-params";

import CriteriaEditor from "../criteria-editor";
import { CriteriaByCompetencyIdMap } from "../types";
import RoleHeading from "./role-heading";

const CareerTrackForm = ({ organizationId }: { organizationId: number }) => {
  const label = useLabel();
  const link = useLink();
  const { onError } = useNotificationError();
  const params = useParams<{ careerTrackId: string }>();
  const { teamStr } = useUrlQueryParams<{ teamStr?: string }>({
    teamStr: undefined,
  });
  const [levelCount, setLevelCount] = useState(1);

  const careerTrackId = useMemo(() => {
    if (params.careerTrackId?.match(/\d+/)) {
      return parseInt(params.careerTrackId);
    }
    return null;
  }, [params]);
  const isNew = careerTrackId === null;

  const [careerTrackData, setCareerTrackData] = useState<
    Omit<SaveOrgWideCareerTrackMutationVariables, "roles">
  >({
    title: "",
    organizationId,
  });
  const [associatedTeam, setAssociatedTeam] =
    useState<UserComboboxOption | null>(
      teamStr
        ? {
            id: parseInt(teamStr.split(":")[0]),
            title: teamStr.split(":")[1],
            type: UserComboboxOptionType.TEAM,
          }
        : null
    );
  const [roles, setRoles] = useState<
    (CareerTrackRoleInput & {
      uuid: string;
      criteriaByCompetencyId: CriteriaByCompetencyIdMap;
    })[]
  >([]);

  const { data: competenciesData, loading: isCompetenciesLoading } = useQuery<
    GetOrganizationCompetenciesQuery,
    GetOrganizationCompetenciesQueryVariables
  >(getOrganizationCompetenciesQuery, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    variables: {
      organizationId,
    },
    onError,
  });

  const { data, loading: isCareerTrackLoading } = useQuery<
    GetOrganizationCareerTrackQuery,
    GetOrganizationCareerTrackQueryVariables
  >(getOrganizationCareerTrackQuery, {
    fetchPolicy: "no-cache",
    nextFetchPolicy: "no-cache",
    variables: {
      careerTrackId: careerTrackId ?? 0,
    },
    skip: isNew,
    onError,
    onCompleted: (data) => {
      const roles = assertEdgesNonNull(data?.careerTrack?.roles);
      setCareerTrackData({
        ...data.careerTrack,
      });
      setRoles(
        roles.map((role) => {
          const roleCompetencies = assertEdgesNonNull(role.competencies);
          const criteriaByCompetencyId: CriteriaByCompetencyIdMap =
            roleCompetencies.reduce((acc, competency) => {
              const criteria = assertEdgesNonNull(competency.criteria).map(
                ({ id, content, level }) => ({
                  id,
                  content,
                  level,
                  uuid: uuidv4(),
                })
              );
              return {
                ...acc,
                [competency.id]: [...(acc[competency.id] ?? []), ...criteria],
              };
            }, {} as CriteriaByCompetencyIdMap);
          return {
            level: role.level,
            title: role.title,
            roleType: role.roleType,
            id: role.id,
            uuid: uuidv4(),
            criteriaByCompetencyId,
          };
        })
      );
      setLevelCount(assertNonNull(max(roles.map((role) => role.level))));
      setAssociatedTeam({
        id: assertNonNull(data.careerTrack?.team?.id),
        title: assertNonNull(data.careerTrack?.team?.title),
        type: UserComboboxOptionType.TEAM,
      });
    },
  });

  const [saveCareerTrack, { loading: isSaving }] = useMutation<
    SaveOrgWideCareerTrackMutation,
    SaveOrgWideCareerTrackMutationVariables
  >(createOrUpdateCareerTrackMutation, {
    onCompleted: () => {
      successNotificationVar({
        title: `Careeer track saved`,
      });
      link.redirect(`/settings/organization/${organizationId}/competencies`);
    },
  });

  const handleSaveCompetency = useCallback(() => {
    saveCareerTrack({
      variables: {
        ...careerTrackData,
        roles: roles.map((item) =>
          omit(item, "uuid", "criteriaByCompetencyId")
        ),
        careerTrackId: careerTrackId,
        teamId: assertNonNull(associatedTeam?.id),
      },
      onError,
    });
  }, [
    associatedTeam?.id,
    careerTrackData,
    careerTrackId,
    onError,
    roles,
    saveCareerTrack,
  ]);

  const { options, setQuery, query } = useUserComboboxQuery({
    types: [UserComboboxOptionType.TEAM],
    selected: associatedTeam ? associatedTeam : undefined,
  });

  const fullCompetenciesList = useMemo(() => {
    return competenciesData
      ? assertEdgesNonNull(competenciesData?.competencies).filter(
          (competency) => {
            return (
              competency.appliesTo === CompetencyAppliesTo.AllRoles ||
              (competency.appliesToManagementRoles &&
                (roles.length === 0 ||
                  roles.some(
                    (role) => role.roleType === CareerTrackRoleType.Management
                  ))) ||
              (competency.appliesToIcRoles &&
                (roles.length === 0 ||
                  roles.some(
                    (role) => role.roleType === CareerTrackRoleType.Ic
                  ))) ||
              (associatedTeam &&
                assertEdgesNonNull(competency.appliesToTeams)
                  .map((team) => team.id)
                  .includes(associatedTeam.id)) ||
              (careerTrackId &&
                assertEdgesNonNull(competency.appliesToCareerTracks)
                  .map((track) => track.id)
                  .includes(careerTrackId))
            );
          }
        )
      : [];
  }, [associatedTeam, careerTrackId, competenciesData, roles]);

  const loading = isCareerTrackLoading || isCompetenciesLoading;
  const saveTooltip = useMemo(() => {
    if (!associatedTeam) return "Please select a team";
    if (roles.every((role) => role.title?.trim() === ""))
      return "Every role must have a title";
    if (!careerTrackData.title?.trim()) return "Please enter a title";
    return null;
  }, [associatedTeam, careerTrackData.title, roles]);
  const canSave = !saveTooltip;

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <Heading small title={`${!isNew ? "Edit" : "New"} Career Track`} />
      </div>

      {loading && (
        <div className="flex justify-center mt-8">
          <Loading />
        </div>
      )}
      {!loading && !data?.careerTrack && !isNew && (
        <div className="flex justify-center mt-8">
          <div className="text-gray-500">Career Track not found</div>
        </div>
      )}

      {!loading && (data?.careerTrack || isNew) && (
        <div className="my-6">
          <div className="flex flex-col gap-8">
            <div className="flex">
              <div className="mt-5 text-gray-500 text-xs uppercase font-semibold w-48">
                Name
              </div>
              <Input
                aria-label="Competency title input"
                disabled={isSaving}
                className="w-fit flex-1 mt-1"
                value={careerTrackData.title ?? ""}
                onChange={(evt) => {
                  setCareerTrackData({
                    ...careerTrackData,
                    title: evt.target.value,
                  });
                }}
              />
            </div>
            <div className="flex">
              <div className="mt-3 text-gray-500 text-xs uppercase font-semibold w-48">
                Associated {label("team")}
              </div>
              <UserCombobox
                options={options}
                value={associatedTeam}
                onChangeValue={setAssociatedTeam}
                query={query}
                onChangeQuery={setQuery}
                placeholder={`${label("team", { capitalize: true })}: None`}
              />
            </div>
          </div>

          <div className="flex items-center justify-between mt-8 mb-4">
            <div className="font-bold">
              Competencies, Responsibilities, and KPIs
            </div>
            <Button
              disabled={isSaving}
              text="Add Level"
              onClick={() => setLevelCount((levelCount) => levelCount + 1)}
            />
          </div>

          <div className="m-auto w-full">
            <div className="relative overflow-x-auto">
              <table className="text-sm table-fixed">
                <thead>
                  <tr>
                    <th className="w-60 max-w-60 min-w-60 border border-l-0 sticky left-0 bg-white"></th>
                    {range(levelCount).map((level) => {
                      const role = roles.find(
                        (role) => role.level === level + 1
                      );
                      return (
                        <th
                          key={level}
                          className="w-92 max-w-92 min-w-92 p-2 border text-left font-normal align-top"
                        >
                          <RoleHeading
                            level={level + 1}
                            disabled={isSaving}
                            role={role}
                            onNewRole={() =>
                              setRoles((prev) => [
                                ...prev,
                                {
                                  uuid: uuidv4(),
                                  level: level + 1,
                                  title: "",
                                  roleType: CareerTrackRoleType.Ic,
                                  criteriaByCompetencyId: {},
                                },
                              ])
                            }
                            onRoleChange={(newRole) => {
                              if (!role) {
                                return;
                              }
                              setRoles((prev) =>
                                prev.map((oldRole) => {
                                  if (oldRole.uuid === role.uuid) {
                                    return {
                                      ...oldRole,
                                      ...newRole,
                                    };
                                  }
                                  return oldRole;
                                })
                              );
                            }}
                          />
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  <tr className="border-t border-b">
                    <td className="p-2 font-bold sticky w-60 min-w-60 max-w-60 left-0 bg-gray-100">
                      Competencies
                    </td>
                    {range(levelCount).map((level) => {
                      return (
                        <td
                          className="p-4 border w-92 max-w-92 min-w-92 bg-gray-100"
                          key={level}
                        ></td>
                      );
                    })}
                  </tr>
                  {fullCompetenciesList.map((competency) => {
                    return (
                      <tr key={competency.id} className="h-52 align-top">
                        <td className="p-4 border border-l-0 sticky w-60 min-w-60 max-w-60 left-0 bg-white z-10">
                          <div className="font-medium">{competency.title}</div>
                          <div className="flex justify-between gap-2 bg-gray-100 mt-1 p-2 text-xs rounded-md">
                            <div className="w-5/6">
                              {competencyInfo(competency)}
                            </div>
                            <InformationCircleIcon className="text-gray-400 h-4 w-4" />
                          </div>
                        </td>
                        {range(levelCount).map((level) => {
                          const role = roles.find(
                            (role) => role.level === level + 1
                          );

                          const criteria = role
                            ? competency.criteriaUniqueness ===
                              CompetencyCriteriaUniqueness.SameAcrossRoles
                              ? assertEdgesNonNull(competency.criteria).filter(
                                  (criteria) => criteria.level === level + 1
                                )
                              : role.criteriaByCompetencyId[competency.id] ?? []
                            : [];

                          return (
                            <td
                              className="p-4 border w-92 max-w-92 min-w-92"
                              key={level}
                            >
                              <CriteriaEditor criteria={criteria} />
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>

          <div className="flex items-center justify-end gap-2 mt-4">
            <Button
              disabled={isSaving}
              theme={buttonTheme.text}
              text="Discard changes"
              to={`/settings/organization/${organizationId}/competencies`}
            />
            <Button
              disabled={isSaving || !canSave}
              theme="primary"
              tooltip={saveTooltip}
              text={`Save Career track`}
              onClick={handleSaveCompetency}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default CareerTrackForm;
