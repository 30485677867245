import { gql } from "@apollo/client";

import CommonRecognitionArtifactFragment from "@graphql/common-recognition-artifact-fragment";

export default gql`
  ${CommonRecognitionArtifactFragment}
  query getRecognitionBoardQuery(
    $organizationId: Int!
    $limit: Int!
    $after: String
    $coreValueId: Int
    $recipientId: Int
    $receivedByMembersOfTeamId: Int
    $createdBetweenDates: [Date]
  ) {
    artifacts(
      artifactType: recognition
      orderBy: "-created"
      first: $limit
      after: $after
      recognitionCoreValue: $coreValueId
      recognitionRecipient: $recipientId
      recognitionReceivedByMembersOfTeam: $receivedByMembersOfTeamId
      organizationId: $organizationId
      createdBetweenDates: $createdBetweenDates
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          ... on RecognitionArtifactNode {
            ...CommonRecognitionArtifactFragment
          }
        }
      }
    }
  }
`;
