import { gql } from "@apollo/client";

export default gql`
  mutation updateTopicTitleDescriptionMutation(
    $topicId: Int!
    $title: String
    $description: String
  ) {
    createOrUpdateTopic(
      topicId: $topicId
      title: $title
      description: $description
    ) {
      topic {
        id
        title
        description
      }
    }
  }
`;
