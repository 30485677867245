import { useMutation } from "@apollo/client";
import { useEffect, useState } from "react";

import updateOrganizationMembershipMutation from "@apps/account/graphql/update-organization-membership-mutation";
import Input from "@components/input/input";
import Label from "@components/label/label";
import Loading from "@components/loading/loading";
import useDebounce from "@components/use-debounce/use-debounce";
import { useNotificationError } from "@components/use-error/use-error";

import OrganizationManagerCombobox from "./organization-manager-combobox";

const Organization = ({ me, organization }: { me: any; organization: any }) => {
  const [position, setPosition] = useState(
    organization.userMembership.position || ""
  );
  const debouncedPosition = useDebounce(position, 300);
  const [updateOrganizationMembership, { loading: positionLoading }] =
    useMutation(updateOrganizationMembershipMutation);
  const { onError } = useNotificationError();
  const excludeUserIds = (
    me.managers.edges.map(({ node }: { node: any }) => node.id) || []
  ).concat(me.id);

  const handleSaveUser = () => {
    updateOrganizationMembership({
      variables: { organizationId: organization.id, position, userId: me.id },
      onError,
    });
  };

  useEffect(() => {
    if (
      (position || organization.userMembership.position) &&
      position !== organization.userMembership.position
    ) {
      handleSaveUser();
    }
  }, [debouncedPosition]);
  const teamsForOrg = me.teams.edges.filter(({ node }: { node: any }) =>
    node.organization.id === organization.id ? node : null
  );
  const teamTitles = teamsForOrg.map(({ node }: { node: any }) => node.title);

  return (
    <div
      className="border border-gray-300 p-6 rounded-md mb-4"
      aria-label={`Account settings organization: ${organization.name}`}
    >
      <div className="flex justify-between">
        <div className="flex gap-4">
          <div className="text-lg font-medium">{organization.name}</div>
        </div>
      </div>
      <div className="rounded-md p-4 bg-gray-50 mt-4 text-sm text-gray-700 grid grid-cols-2 gap-4">
        <div className="flex gap-2">
          <div className="flex-1">
            <Input
              value={position}
              onChange={(e) => {
                setPosition(e.target.value);
              }}
              placeholder="Your position"
              label="Position"
              aria-label="Organization position"
            />
          </div>
          {positionLoading && (
            <div className="mt-8">
              <Loading mini size="5" />
            </div>
          )}
        </div>
        <div>
          <Label label="Manager" />
          <div className="flex flex-col gap-2">
            {me.managers.edges.map(({ node }: { node: any }) => (
              <OrganizationManagerCombobox
                key={node.id}
                manager={node}
                organization={organization}
                excludeUserIds={excludeUserIds}
              />
            ))}
            <OrganizationManagerCombobox
              key={null}
              manager={null}
              organization={organization}
              excludeUserIds={excludeUserIds}
            />
          </div>
        </div>
      </div>
      {teamsForOrg.length > 0 && <div>Teams: {teamTitles.join(", ")}</div>}
    </div>
  );
};

export default Organization;
