import { gql } from "@apollo/client";

export default gql`
  mutation removeAsanaIntegrationMutation {
    removeAsanaIntegration {
      user {
        id
        hasAsanaAuth
      }
    }
  }
`;
