import { gql } from "@apollo/client";

import KeyResultFragment from "@graphql/key-result-fragment";

export default gql`
  ${KeyResultFragment}
  query getGoalProgressQuery($artifactId: Int!) {
    artifact(artifactId: $artifactId) {
      id
      title
      ... on GoalArtifactNode {
        status
        startValue
        currentValue
        targetValue
        progress
        progressType
        keyResults {
          edges {
            node {
              ...KeyResultFragment
            }
          }
        }
      }
    }
  }
`;
