import { PropsWithChildren, useState } from "react";

import { currentOrganizationVar } from "@cache/cache";

import BillingDialog from "./billing-dialog";

const BillingDialogCTA = ({
  children,
  className = "",
}: PropsWithChildren<{
  className?: string;
}>) => {
  const organization = currentOrganizationVar();
  const [opened, setOpened] = useState(false);
  const handleClose = () => {
    setOpened(false);
  };
  return (
    <>
      {opened && (
        <BillingDialog onClose={handleClose} organization={organization} />
      )}
      <button
        type="button"
        className={className}
        onClick={() => setOpened(!opened)}
      >
        {children}
      </button>
    </>
  );
};

export default BillingDialogCTA;
