import { useCallback } from "react";
import { useLocation } from "react-router-dom";
import { CommonRecognitionArtifactFragmentFragment } from "types/graphql-schema";
import { TFLocationState } from "types/topicflow";

import useLabel from "@apps/use-label/use-label";
import Avatars from "@components/avatar/avatars";
import CoreValueIcon from "@components/core-value-icon/core-value-icon";
import AppLink from "@components/link/link";
import TimeAgoCustom from "@components/time-ago/time-ago";
import { classNames } from "@helpers/css";
import {
  assertEdgesNonNull,
  assertNonNull,
  getUrl,
  toWithBackground,
} from "@helpers/helpers";

const maxRecipients = 4;

const RecognitionBoardItem = ({
  recognition,
}: {
  recognition: CommonRecognitionArtifactFragmentFragment;
}) => {
  const label = useLabel();
  const location = useLocation<TFLocationState>();

  const renderRecipientNames = useCallback(
    (recognition: CommonRecognitionArtifactFragmentFragment) => {
      const maxWithExtraCopy = maxRecipients - 1;
      const recipients = recognition.recipients
        ? assertEdgesNonNull(recognition.recipients)
        : [];
      if (recipients.length <= maxWithExtraCopy) {
        return recipients.map(({ name }) => name).join(", ");
      }
      const names = recipients
        .map(({ name }) => name)
        .slice(0, maxWithExtraCopy)
        .join(", ");
      return `${names} & ${recipients.length - maxWithExtraCopy} others`;
    },
    []
  );

  // Render
  const recipients = recognition.recipients
    ? assertEdgesNonNull(recognition.recipients)
    : [];
  return (
    <AppLink
      className="p-4 bg-white rounded-lg border hover:border-blue-link flex flex-col gap-4"
      key={recognition.id}
      to={toWithBackground({
        pathname: getUrl({
          artifactId: recognition.id,
          artifactType: assertNonNull(recognition.artifactType),
        }),
        location,
      })}
    >
      <div className="flex w-full justify-center gap-2 md:gap-4">
        <span
          className={classNames(
            "shrink-0",
            !recognition.recognitionCoreValue?.image &&
              "flex items-center justify-center text-4xl h-16 w-16 rounded-full border-4"
          )}
        >
          <CoreValueIcon
            emoji={recognition.recognitionCoreValue?.emoji}
            image={recognition.recognitionCoreValue?.image}
            imagePxSize={64}
          />
        </span>
        <div className="flex justify-center shrink-0">
          <Avatars
            users={recipients}
            max={maxRecipients}
            size="16"
            className={classNames(
              "flex items-center justify-center",
              recipients.length === 1 ? "w-16" : "pl-10" // otherwise single avatar gets squished
            )}
            avatarClassName={classNames(recipients.length > 1 && "-ml-10")}
            extraClassName="ml-2"
          />
        </div>
      </div>
      <div className="text-center font-medium">
        {renderRecipientNames(recognition)}
      </div>
      {recognition.recognitionCoreValue ? (
        <div className="flex flex-col gap-1.5">
          <div className="text-center text-xs text-gray-400 tracking-tight">
            received a {label("recognition")} for
          </div>
          <div className="text-center text-sm uppercase tracking-tight font-medium">
            {recognition.recognitionCoreValue.title}
          </div>
          <div className="text-center text-xs text-gray-400 tracking-tight">
            <TimeAgoCustom date={recognition.created} /> by{" "}
            <span className="font-medium">
              {assertNonNull(recognition.creator).name}
            </span>
          </div>
        </div>
      ) : (
        <div className="flex flex-col gap-0.5">
          <div className="text-center text-xs text-gray-400 tracking-tight">
            received a {label("recognition")}
            <br />
            <TimeAgoCustom date={recognition.created} /> by{" "}
            <span className="font-medium">
              {assertNonNull(recognition.creator).name}
            </span>
          </div>
        </div>
      )}
      <div className="text-center text-gray-600">{recognition.title}</div>
    </AppLink>
  );
};

export default RecognitionBoardItem;
