import { useMutation } from "@apollo/client";
import { ChangeEventHandler, useState } from "react";

import deleteSlackNotificationMutation from "@apps/org-settings/graphql/delete-slack-notification";
import useLabel from "@apps/use-label/use-label";
import Button, { buttonTheme } from "@components/button/button";
import ComboboxGeneric from "@components/combobox/generic-combobox";
import Dropdown from "@components/dropdown/dropdown";
import Loading from "@components/loading/loading";
import { onNotificationErrorHandler } from "@components/use-error/use-error";

import createOrUpdateSlackNotificationForChannelMutation from "../graphql/create-or-update-slack-notification";

const SlackNotification = ({
  organization,
  slackNotification,
  channels,
  existingChannelIds,
}: {
  organization: any;
  slackNotification: any;
  channels: any;
  existingChannelIds: any;
}) => {
  const label = useLabel();
  const noSelectedChannel = {
    label: "Select Slack channel",
    value: null,
    placeholder: true,
  };
  const matchingChannel =
    channels.find(
      ({ value }: { value: any }) => value === slackNotification?.channel
    ) || noSelectedChannel;
  const [selectedChannel, setSelectedChannel] = useState(matchingChannel);
  const [recognitions, setRecognitions] = useState(
    slackNotification ? slackNotification.recognitions : true
  );
  const [goalCheckins, setGoalCheckins] = useState(
    slackNotification ? slackNotification.goalCheckins : true
  );
  const [createOrUpdateSlackNotificationForChannel, { loading: loadingSave }] =
    useMutation(createOrUpdateSlackNotificationForChannelMutation);
  const [deleteSlackNotification, { loading: loadingDelete }] = useMutation(
    deleteSlackNotificationMutation
  );

  // Computed data
  const defaultChannels =
    matchingChannel.value === noSelectedChannel.value
      ? [noSelectedChannel]
      : [];
  const filteredChannels = defaultChannels
    .concat(channels)
    .filter(({ value }) => !existingChannelIds.includes(value));

  const handleSaveSlackNotification = () => {
    if (selectedChannel.value === noSelectedChannel.value) {
      handleDeleteSlackNotification();
      return;
    }
    createOrUpdateSlackNotificationForChannel({
      variables: {
        organizationId: organization.id,
        notificationId: slackNotification?.id || null,
        channel: selectedChannel.value,
        recognitions: recognitions,
        goalCheckins: goalCheckins,
      },
      onError: onNotificationErrorHandler(),
      onCompleted() {
        if (!slackNotification) {
          setSelectedChannel(noSelectedChannel);
        }
      },
    });
  };
  const handleDeleteSlackNotification = () => {
    deleteSlackNotification({
      variables: {
        notificationId: slackNotification.id,
      },
      onError: onNotificationErrorHandler(),
    });
  };

  const handleRecognitions: ChangeEventHandler<HTMLInputElement> = (e) => {
    setRecognitions(e.target.checked);
  };

  const handleGoalCheckins: ChangeEventHandler<HTMLInputElement> = (e) => {
    setGoalCheckins(e.target.checked);
  };

  const loading = loadingSave || loadingDelete;
  return (
    <div className="py-4">
      <div className="w-full flex gap-3 items-center pb-2">
        <ComboboxGeneric
          className="max-w-96 flex-1"
          options={filteredChannels}
          loading={loading}
          disabled={loading}
          onChangeValue={setSelectedChannel}
          value={selectedChannel}
        />
        {((slackNotification &&
          (matchingChannel.value !== selectedChannel.value ||
            recognitions !== slackNotification.recognitions ||
            goalCheckins !== slackNotification.goalCheckins)) ||
          !slackNotification) && (
          <Button
            text={
              slackNotification ? "Update" : "Create new Slack notification"
            }
            disabled={
              (matchingChannel.value === selectedChannel.value &&
                recognitions === slackNotification?.recognitions &&
                goalCheckins === slackNotification?.goalCheckins) ||
              loading
            }
            theme={buttonTheme.primary}
            onClick={handleSaveSlackNotification}
          />
        )}
        {slackNotification && (
          <Dropdown
            className="px-0"
            options={[
              {
                label: "Delete",
                onClick: handleDeleteSlackNotification,
              },
            ]}
          />
        )}
        {loading && <Loading mini size="5" />}
      </div>
      <div>
        <label className={"pr-4"}>
          <input
            aria-label="Send recognitions to slack"
            type="checkbox"
            checked={recognitions}
            onChange={handleRecognitions}
          />
          <span className="pl-1 text-sm">
            {label("recognition", {
              pluralize: true,
              capitalize: true,
            })}
          </span>
        </label>
        <label>
          <input
            aria-label="Send goal check-ins to slack"
            type="checkbox"
            checked={goalCheckins}
            onChange={handleGoalCheckins}
          />

          <span className="pl-1 text-sm">
            {label("goal", {
              pluralize: false,
              capitalize: true,
            })}{" "}
            Checkins
          </span>
        </label>
      </div>
    </div>
  );
};

export default SlackNotification;
