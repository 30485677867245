import { gql } from "@apollo/client";

export default gql`
  mutation removeBotFromMeetingNewPageMutation($meetingId: Int!) {
    removeBotFromMeeting(meetingId: $meetingId) {
      meeting {
        hasBotInMeeting
        botIsWaitingToJoinMeeting
        id
      }
    }
  }
`;
