import { PropsWithChildren } from "react";

type Props = {
  title: string;
  small?: boolean;
};

const Heading: React.FC<PropsWithChildren<Props>> = ({
  title,
  children,
  small = false,
}) =>
  small ? (
    <h2 className="text-2xl font-medium text-gray-800">{title}</h2>
  ) : (
    <header className="pb-4 sm:pb-6">
      <h1 className="capitalize text-3xl font-bold text-gray-800">{title}</h1>
      {children}
    </header>
  );

export default Heading;
