import { gql } from "@apollo/client";

const getWysiwygArtifactCommentQuery = gql`
  query getWysiwygArtifactCommentQuery($commentId: Int!) {
    comment(commentId: $commentId) {
      id
      artifact {
        id
      }
      creator {
        id
        name
        avatar
      }
      created
      comment
      canUpdate {
        permission
      }
      canDelete {
        permission
      }
    }
  }
`;

export default getWysiwygArtifactCommentQuery;
