import { Editor, Extension } from "@tiptap/core";
import { ReactRenderer } from "@tiptap/react";
import Suggestion from "@tiptap/suggestion";
import a from "indefinite";
import { defer } from "lodash";
import compact from "lodash/compact";
import { Component, createRef } from "react";
import tippy from "tippy.js";
import { ArtifactType } from "types/graphql-schema";

import {
  ExplorerBookmarkLinks,
  getExplorerBookmarks,
} from "@apps/explorer/components/bookmark-popover";
import {
  explorerGithubType,
  explorerHubspotType,
  explorerJiraType,
  getExplorerFiltersUrl,
} from "@apps/explorer/helpers";
import SearchModal from "@apps/search/search";
import { getLabel } from "@apps/use-label/use-label";
import { currentOrganizationVar, currentUserVar } from "@cache/cache";
import { tooltipZIndex } from "@helpers/constants";
import { classNames } from "@helpers/css";
import { getTiptapSchema } from "@helpers/helpers";

import EmbedUrlDialog from "./embeds/embed-url-dialog";
import GiphyDialog from "./embeds/giphy-dialog";

const Commands = Extension.create<{ suggestion: any }>({
  name: "command",
  addOptions: () => {
    return {
      suggestion: {
        char: "/",
        startOfLine: false,
        command: ({
          editor,
          range,
          props,
        }: {
          editor: Editor;
          range: any;
          props: any;
        }) => {
          props.command({ editor, range, props });
        },
      },
    };
  },
  addProseMirrorPlugins() {
    return [
      Suggestion({
        editor: this.editor,
        ...this.options.suggestion,
      }),
    ];
  },
});

export default Commands;

const focusDeleteRange = (editor: Editor, range: any) =>
  editor.chain().focus().deleteRange(range);

const giphyCommandItem = {
  id: "giphy",
  title: "Giphy",
  command: ({
    editor,
    range,
    props,
  }: {
    editor: Editor;
    range: any;
    props: any;
  }) => {
    focusDeleteRange(editor, range)
      .setImage({
        src: props.gif.images.original.webp,
        alt: props.gif.title,
        title: props.gif.title,
      })
      .run();
  },
};
const embedUrlCommandItem = {
  id: "embedUrl",
  title: "Embed URL",
};
const githubCommandItem = {
  ...embedUrlCommandItem,
  title: "Github",
};
const clickupCommandItem = {
  ...embedUrlCommandItem,
  title: "ClickUp",
};
const hubspotCommandItem = {
  ...embedUrlCommandItem,
  title: "Hubspot deal",
};
const salesforceCommandItem = {
  ...embedUrlCommandItem,
  title: "Salesforce opportunity",
};
const jiraCommandItem = {
  ...embedUrlCommandItem,
  title: "Jira issue",
};
const iframeCommandItem = {
  ...embedUrlCommandItem,
  title: "Iframe",
};
const youtubeCommandItem = {
  ...embedUrlCommandItem,
  title: "Youtube",
};
const figmaCommandItem = {
  ...embedUrlCommandItem,
  title: "Figma",
};
const loomCommandItem = {
  ...embedUrlCommandItem,
  title: "Loom",
};
const miroCommandItem = {
  ...embedUrlCommandItem,
  title: "Miro",
};
const vimeoCommandItem = {
  ...embedUrlCommandItem,
  title: "Vimeo",
};
const asanaCommandItem = {
  ...embedUrlCommandItem,
  title: "Asana",
};

const explorerCommandItem = {
  id: "explorer",
  title: "Explorer",
  command: ({ editor, range }: { editor: Editor; range: any }) => {
    const currentUser = currentUserVar();
    focusDeleteRange(editor, range)
      .addEmptyExplorer({
        creatorName: currentUser.name,
        creatorId: currentUser.id,
      })
      .run();
  },
};
const searchCommandItem = {
  id: "search",
  keywords: "find",
  title: "Search",
  searchFilters: {},
  command: ({ editor }: { editor: Editor }) => {
    editor.chain().focus().run();
  },
};

const label = getLabel();

const findActionItemsCommandItem = {
  id: "find-action-items",
  keywords: "search",
  title: "Find an action item",
  searchFilters: { type: ArtifactType.ActionItem },
  command: ({ editor }: { editor: Editor }) => {
    editor.chain().focus().run();
  },
};
const findGoalsCommandItem = {
  id: "find-goals",
  keywords: "search",
  title: `Find ${a(label("goal"))}`,
  searchFilters: { type: ArtifactType.Goal },
  command: ({ editor }: { editor: Editor }) => {
    editor.chain().focus().run();
  },
};
const findRecognitionCommandItem = {
  id: "find-recognition",
  keywords: "search",
  title: `Find ${a(label("recognition"))}`,
  searchFilters: { type: ArtifactType.Recognition },
  command: ({ editor }: { editor: Editor }) => {
    editor.chain().focus().run();
  },
};
const findDocumentCommandItem = {
  id: "find-document",
  keywords: "search",
  title: `Find ${a("document")}`,
  searchFilters: { type: ArtifactType.Document },
  command: ({ editor }: { editor: Editor }) => {
    editor.chain().focus().run();
  },
};
const findDecisionCommandItem = {
  id: "find-decision",
  keywords: "search",
  title: `Find ${a("decision")}`,
  searchFilters: { type: ArtifactType.Decision },
  command: ({ editor }: { editor: Editor }) => {
    editor.chain().focus().run();
  },
};
const findHubspotDealCommandItem = {
  id: "find-hubspot-deal",
  keywords: "search",
  title: `Find a Hubspot deal`,
  searchFilters: { type: explorerHubspotType },
  command: ({ editor }: { editor: Editor }) => {
    editor.chain().focus().run();
  },
};
const findJiraIssueCommandItem = {
  id: "find-jira-issue",
  keywords: "search",
  title: `Find a Jira issue`,
  searchFilters: { type: explorerJiraType },
  command: ({ editor }: { editor: Editor }) => {
    editor.chain().focus().run();
  },
};
const findGithubIssueCommandItem = {
  id: "find-github-issue",
  keywords: "search",
  title: `Find a Github issue`,
  searchFilters: { type: explorerGithubType },
  command: ({ editor }: { editor: Editor }) => {
    editor.chain().focus().run();
  },
};

export const getSuggestionItems =
  ({
    permissionDecision,
    permissionGoal,
  }: {
    permissionDecision: boolean;
    permissionGoal: boolean;
  }) =>
  ({ query }: { query: string }) => {
    const l = getLabel();
    const currentOrganization = currentOrganizationVar();
    const currentUser = currentUserVar();
    const explorerBookmarkCommands = compact(getExplorerBookmarks())
      .reduce(
        (memo, group) => [...memo, ...group.links],
        [] as ExplorerBookmarkLinks
      )
      .filter(({ showInSlashCommand }) => showInSlashCommand)
      .map((explorerLink) => ({
        id: explorerLink.title,
        title: explorerLink.title,
        command: ({ editor, range }: { editor: Editor; range: any }) => {
          focusDeleteRange(editor, range)
            .addEmptyExplorer({
              title: explorerLink.title,
              href: getExplorerFiltersUrl(explorerLink.filters),
              creatorName: currentUser.name,
              creatorId: currentUser.id,
            })
            .run();
        },
      }));
    const commands = compact([
      {
        id: "create-headline",
        title: "Create",
        subItems: compact([
          currentOrganization.featureFlags.actionItems && {
            id: "action-item",
            title: "New action item",
            command: ({ editor, range }: { editor: Editor; range: any }) => {
              // we focus on editor first, so it'll focus automatically on the artifact input when added.
              editor.chain().focus().run();
              defer(() => {
                focusDeleteRange(editor, range)
                  .insertActionItem({
                    createdByUser: currentUser,
                    title: "",
                  })
                  .focus()
                  .run();
              });
            },
          },
          currentOrganization.featureFlags.decisions && {
            id: "decision",
            title: `New decision${permissionDecision ? "" : " (upgrade)"}`,
            command: ({ editor, range }: { editor: Editor; range: any }) => {
              if (!permissionDecision) {
                return (document.location.href = "/billing");
              }
              // we focus on editor first, so it'll focus automatically on the artifact input when added.
              editor.chain().focus().run();
              defer(() => {
                focusDeleteRange(editor, range)
                  .insertDecision({
                    createdByUser: currentUser,
                    title: "",
                  })
                  .run();
              });
            },
          },
          currentOrganization.featureFlags.goals && {
            id: "goal",
            title: `New ${l("goal")}${permissionGoal ? "" : " (upgrade)"}`,
            command: ({ editor, range }: { editor: Editor; range: any }) => {
              if (!permissionGoal) {
                return (document.location.href = "/billing");
              }
              // we focus on editor first, so it'll focus automatically on the artifact input when added.
              editor.chain().focus().run();
              defer(() => {
                focusDeleteRange(editor, range)
                  .insertGoal({
                    createdByUser: currentUser,
                    title: "",
                  })
                  .run();
              });
            },
          },
          currentOrganization.featureFlags.documents && {
            id: "document",
            title: "New document",
            command: ({ editor, range }: { editor: Editor; range: any }) => {
              // we focus on editor first, so it'll focus automatically on the artifact input when added.
              editor.chain().focus().run();
              defer(() => {
                focusDeleteRange(editor, range)
                  .insertDocument({
                    createdByUser: currentUser,
                    title: "",
                  })
                  .run();
              });
            },
          },
          currentOrganization.featureFlags.recognitions && {
            id: "recognition",
            title: `New ${l("recognition")}`,
            command: ({ editor, range }: { editor: Editor; range: any }) => {
              focusDeleteRange(editor, range)
                .insertRecognition({
                  createdByUser: currentUser,
                  title: "",
                })
                .run();
            },
          },
        ]),
      },
      {
        id: "insert-headline",
        title: "Insert",
        subItems: compact([
          currentOrganization.featureFlags.kpis && {
            id: "kpi",
            title: "KPI",
            command: ({ editor, range }: { editor: Editor; range: any }) => {
              focusDeleteRange(editor, range)
                .insertKPIEmbed({
                  createdByUser: currentUser,
                })
                .run();
            },
          },
          currentOrganization.featureFlags.kpis && {
            id: "kpi-group",
            title: "KPI Group",
            command: ({ editor, range }: { editor: Editor; range: any }) => {
              focusDeleteRange(editor, range)
                .insertKPIGroupEmbed({
                  createdByUser: currentUser,
                })
                .run();
            },
          },
          {
            id: "button",
            title: "Button",
            command: ({ editor, range }: { editor: Editor; range: any }) => {
              focusDeleteRange(editor, range)
                .addButton({
                  createdByUser: currentUser,
                })
                .run();
            },
          },
          currentOrganization.featureFlags.explorer && searchCommandItem,
          {
            id: "timer",
            title: "Timer",
            command: ({ editor, range }: { editor: Editor; range: any }) => {
              focusDeleteRange(editor, range).addTimer().run();
            },
          },
        ]),
      },
      currentOrganization.featureFlags.explorer && {
        id: "explorer-headline",
        title: "Explorer",
        subItems: [explorerCommandItem].concat(explorerBookmarkCommands),
      },
      currentOrganization.featureFlags.explorer && {
        id: "find-headline",
        title: "Find",
        subItems: compact([
          currentOrganization.featureFlags.actionItems &&
            findActionItemsCommandItem,
          currentOrganization.featureFlags.goals && findGoalsCommandItem,
          currentOrganization.featureFlags.documents && findDocumentCommandItem,
          currentOrganization.featureFlags.recognitions &&
            findRecognitionCommandItem,
          currentOrganization.featureFlags.decisions && findDecisionCommandItem,
          currentUser.hasHubspotAuth && findHubspotDealCommandItem,
          currentUser.hasJiraAuth && findJiraIssueCommandItem,
          currentUser.hasGithubAuth && findGithubIssueCommandItem,
        ]),
      },
      {
        id: "text-layout-headline",
        title: "Text & layout",
        subItems: [
          {
            id: "table",
            title: "Table",
            command: ({ editor, range }: { editor: Editor; range: any }) => {
              focusDeleteRange(editor, range).insertTable().run();
            },
          },
          {
            id: "h1",
            title: "H1",
            command: ({ editor, range }: { editor: Editor; range: any }) => {
              focusDeleteRange(editor, range)
                .setNode("heading", { level: 1 })
                .run();
            },
          },
          {
            id: "h2",
            title: "H2",
            command: ({ editor, range }: { editor: Editor; range: any }) => {
              focusDeleteRange(editor, range)
                .setNode("heading", { level: 2 })
                .run();
            },
          },
          {
            id: "h3",
            title: "H3",
            command: ({ editor, range }: { editor: Editor; range: any }) => {
              focusDeleteRange(editor, range)
                .setNode("heading", { level: 3 })
                .run();
            },
          },
          {
            id: "bullet-list",
            title: "Bullet list",
            command: ({ editor, range }: { editor: Editor; range: any }) => {
              focusDeleteRange(editor, range).toggleBulletList().run();
            },
          },
          {
            id: "ordered-list",
            title: "Ordered list",
            command: ({ editor, range }: { editor: Editor; range: any }) => {
              focusDeleteRange(editor, range).toggleOrderedList().run();
            },
          },
        ],
      },
      {
        id: "integration-headline",
        title: "Integrations",
        subItems: compact([
          embedUrlCommandItem,
          githubCommandItem,
          jiraCommandItem,
          hubspotCommandItem,
          salesforceCommandItem,
          clickupCommandItem,
          asanaCommandItem,
        ]),
      },
      {
        id: "media-headline",
        title: "Media",
        subItems: [
          iframeCommandItem,
          giphyCommandItem,
          figmaCommandItem,
          miroCommandItem,
          loomCommandItem,
          youtubeCommandItem,
          vimeoCommandItem,
        ],
      },
    ]);

    // filter commands and flatten array
    return commands.reduce((memo: any, command: any) => {
      const matchingSubItems = command.subItems.filter(
        ({ title, keywords }: { title: string; keywords?: string }) => {
          const searchableString = title + (keywords || "");
          return searchableString.toLowerCase().includes(query.toLowerCase());
        }
      );
      if (matchingSubItems.length > 0) {
        return [...memo, command, ...matchingSubItems];
      }
      return memo;
    }, []);
  };

const defaultIndex = 1;

type Props = {
  items: any[];
  editor: any;
  command: any;
  range: any;
};

export class CommandList extends Component<Props> {
  state = {
    selectedIndex: defaultIndex,
    showDialog: null,
  };
  containerRef;

  constructor(props: Props) {
    super(props);
    this.containerRef = createRef<HTMLDivElement>();
  }

  componentDidUpdate(oldProps: any, oldState: any) {
    if (this.props.items !== oldProps.items) {
      this.setState({ selectedIndex: defaultIndex });
    }
    if (
      this.state.selectedIndex !== oldState.selectedIndex &&
      this.containerRef.current
    ) {
      const child = this.containerRef.current.children.item(
        this.state.selectedIndex
      ) as HTMLDivElement;
      const parentHeight = this.containerRef.current.offsetHeight;
      const parentScrollTop = this.containerRef.current.scrollTop;
      if (!child) return;
      const childTop = child.offsetTop;
      const childHeight = child.offsetHeight;

      if (this.state.selectedIndex === 1) {
        // make title visible
        this.containerRef.current.scrollTo(0, 0);
      } else if (childTop + childHeight >= parentScrollTop + parentHeight) {
        const y = childTop + childHeight - parentHeight;
        this.containerRef.current.scrollTo(0, y);
      } else if (childTop < parentScrollTop) {
        this.containerRef.current.scrollTo(0, childTop);
      }
    }
  }

  onKeyDown({ event }: any) {
    if (event.key === "ArrowUp") {
      this.upHandler();
      return true;
    }
    if (event.key === "ArrowDown") {
      this.downHandler();
      return true;
    }
    if (event.key === "Enter") {
      if (this.props.items.length > 0) {
        this.enterHandler();
        return true;
      }
      return false;
    }
    if (event.key === "Tab") {
      this.enterHandler();
      return true;
    }
    return false;
  }

  upHandler() {
    let newIndex = this.state.selectedIndex - 1;
    if (newIndex > 0 && this.props.items[newIndex].subItems) {
      newIndex = newIndex - 1;
    }
    if (newIndex > 0) {
      this.setState({ selectedIndex: newIndex });
    }
  }

  downHandler() {
    let newIndex = this.state.selectedIndex + 1;
    if (
      newIndex < this.props.items.length &&
      this.props.items[newIndex].subItems
    ) {
      newIndex = newIndex + 1;
    }
    if (newIndex < this.props.items.length) {
      this.setState({ selectedIndex: newIndex });
    }
  }

  enterHandler() {
    this.selectItem(this.state.selectedIndex);
  }

  selectItem(index: number) {
    const item = this.props.items[index];
    if (item) {
      const dialogIds = [
        giphyCommandItem.id,
        iframeCommandItem.id,
        youtubeCommandItem.id,
        figmaCommandItem.id,
        loomCommandItem.id,
        miroCommandItem.id,
        vimeoCommandItem.id,
        asanaCommandItem.id,
        embedUrlCommandItem.id,
        searchCommandItem.id,
        findActionItemsCommandItem.id,
        findGoalsCommandItem.id,
        findDocumentCommandItem.id,
        findRecognitionCommandItem.id,
        findDecisionCommandItem.id,
        findHubspotDealCommandItem.id,
        findJiraIssueCommandItem.id,
        findGithubIssueCommandItem.id,
      ];
      if (dialogIds.includes(item.id)) {
        return this.setState({ showDialog: item.id });
      }
      // necessary though to let the tooltip/command dialog to close
      // allows to bring focus into artifact field
      setTimeout(() => {
        this.props.command(item);
      }, 150); // timeout number is made up
    }
  }

  handleClickSearchResult = (result: any) => {
    this.setState({ showDialog: false });
    const content = getTiptapSchema(result) as any;
    if (content) {
      this.props.editor
        .chain()
        .focus()
        .deleteRange(this.props.range)
        .insertContent(content)
        .run();
    } else {
      throw new Error(`Missing result: ${result.__typename}`);
    }
  };

  handleCloseDialog = () => {
    this.setState({ showDialog: false });
  };

  render() {
    const { items } = this.props;
    const { showDialog } = this.state;
    const matchingCommand = items.find(({ id }) => id === showDialog);
    // const showSearchDialog
    return (
      <>
        {matchingCommand?.searchFilters && (
          <SearchModal
            initialFilters={{
              meetingGroupId:
                this.props.editor?.options?.editorProps?.meetingGroupId || null,
              ...matchingCommand.searchFilters,
            }}
            onClickResult={this.handleClickSearchResult}
            onClose={this.handleCloseDialog}
          />
        )}
        {showDialog === giphyCommandItem.id && (
          <GiphyDialog
            command={this.props.command}
            item={giphyCommandItem}
            onClose={this.handleCloseDialog}
          />
        )}
        {showDialog === embedUrlCommandItem.id && (
          <EmbedUrlDialog
            range={this.props.range}
            editor={this.props.editor}
            onClose={this.handleCloseDialog}
          />
        )}

        {!showDialog && items.length > 0 && (
          <div
            className="relative rounded bg-white border text-sm shadow js-wysiwyg-command overflow-y-auto max-h-72"
            aria-label="wysiwyg-commands"
            ref={this.containerRef}
          >
            {items.map((item, index: number) => {
              return item.subItems ? (
                <div
                  key={String(item.id + item.title)}
                  className="pl-3 pr-2 py-0.5 pt-1 text-2xs text-gray-500 uppercase tracking-tight font-medium border-b bg-gray-50"
                >
                  {item.title}
                </div>
              ) : (
                <button
                  className={classNames(
                    `block w-48 text-left pl-3 pr-2 py-1.5 text-gray-800 hover:bg-gray-50 hover:text-blue-600 border-b font-medium tracking-tight`,
                    index === this.state.selectedIndex &&
                      "text-blue-600 bg-gray-100"
                  )}
                  key={String(item.id + item.title)}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.selectItem(index);
                  }}
                >
                  {item.element || item.title}
                </button>
              );
            })}
          </div>
        )}
      </>
    );
  }
}

export const renderItems = () => {
  let component: any;
  let popup: any;

  return {
    onStart: (props: any) => {
      component = new ReactRenderer(CommandList, {
        props,
        editor: props.editor,
      });

      popup = tippy("body", {
        getReferenceClientRect: props.clientRect,
        appendTo: () => document.body,
        content: component.element,
        showOnCreate: true,
        interactive: true,
        trigger: "manual",
        placement: "bottom-start",
        theme: "headless",
        zIndex: tooltipZIndex,
      });
    },
    onUpdate(props: any) {
      component.updateProps(props);
      popup[0].setProps({
        getReferenceClientRect: props.clientRect,
      });
    },
    onKeyDown(props: any) {
      if (props.event.key === "Escape") {
        popup[0].hide();
        return true;
      }
      return component.ref?.onKeyDown(props);
    },
    onExit() {
      if (popup[0]) {
        if (!popup[0].state.isDestroyed) {
          popup[0].destroy();
        }
      }
      component.destroy();
    },
  };
};
