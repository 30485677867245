import { useState } from "react";
import { SyncCredentialsTypeEnum } from "types/graphql-schema";

import ButtonSSO from "@components/button-sso/button-sso";

const CalendarConnectNew = ({
  next,
  extraUrl = "",
  onClickButton,
}: {
  next: string;
  extraUrl?: string;
  onClickButton?: ({
    allowWriteAccess,
    provider,
  }: {
    allowWriteAccess: boolean;
    provider: SyncCredentialsTypeEnum;
  }) => void;
}) => {
  const [allowWriteAccess, setAllowWriteAccess] = useState(true);

  const handleClickButton =
    (options: {
      allowWriteAccess: boolean;
      provider: SyncCredentialsTypeEnum;
    }) =>
    () => {
      if (onClickButton) {
        onClickButton(options);
      }
    };

  return (
    <div>
      <h2 className="font-semibold text-xl mb-6">Connect a calendar</h2>
      <div>
        <div className="mt-2 ml-0.5 text-base text-gray-700 flex items-center">
          <input
            type="checkbox"
            id="write-access"
            checked={allowWriteAccess}
            onChange={() => setAllowWriteAccess(!allowWriteAccess)}
          />
          <label htmlFor="write-access" className="font-medium pl-3">
            Allow write access to this calendar{" "}
          </label>
        </div>
        <div className="mt-2 ml-7 text-sm text-gray-500">
          This allows Topicflow to add an agenda link to your calendar events
        </div>
        <div className="mt-8 flex items-center gap-4 flex-wrap">
          <ButtonSSO
            sso="google"
            url={
              !onClickButton
                ? `/sso/google?request_calendar_scope=true&request_write_scope=${allowWriteAccess}${extraUrl}&next=${next}&prompt=select_account+consent`
                : null
            }
            onClick={handleClickButton({
              allowWriteAccess,
              provider: SyncCredentialsTypeEnum.GoogleCalendar,
            })}
          />
          <ButtonSSO
            sso="microsoft"
            url={
              !onClickButton
                ? `/sso/microsoft?request_calendar_scope=true&request_write_scope=${allowWriteAccess}${extraUrl}&next=${next}`
                : null
            }
            onClick={handleClickButton({
              allowWriteAccess,
              provider: SyncCredentialsTypeEnum.OutlookCalendar,
            })}
          />
        </div>
      </div>
    </div>
  );
};

export default CalendarConnectNew;
