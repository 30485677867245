import { useQuery } from "@apollo/client";
import { withErrorBoundary } from "@sentry/react";
import { compact } from "lodash";
import { useState } from "react";
import {
  GetMeetingInsightsAndRequirementsNewPageQueryQuery,
  GetMeetingInsightsAndRequirementsNewPageQueryQueryVariables,
  InsightType,
  MeetingViewMeetingNodeNewPageFragmentFragment,
} from "types/graphql-schema";

import getMeetingInsightsAndRequirementsQuery from "@apps/meeting-new/graphql/get-meeting-insights-and-requirements-query";
import TemplatesDialog from "@apps/templates/dialog";
import useLabel from "@apps/use-label/use-label";
import { currentUserVar } from "@cache/cache";
import AppError from "@components/error/error";
import Layout from "@components/layout/layout";
import Link from "@components/link/link";
import AppLink from "@components/link/link";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import {
  assertEdgesNonNull,
  assertEdgesNonNullWithStringId,
} from "@helpers/helpers";

const RelatedRequirements = ({
  meeting,
}: {
  meeting: MeetingViewMeetingNodeNewPageFragmentFragment;
}) => {
  const label = useLabel();
  const [isExpandedTemplates, setIsExpandedTemplates] = useState(true);
  const [isExpandedAssessments, setIsExpandedAssessments] = useState(false);
  const currentUser = currentUserVar();
  const { data, loading } = useQuery<
    GetMeetingInsightsAndRequirementsNewPageQueryQuery,
    GetMeetingInsightsAndRequirementsNewPageQueryQueryVariables
  >(getMeetingInsightsAndRequirementsQuery, {
    notifyOnNetworkStatusChange: true,
    variables: { meetingId: meeting.id },
    onError: onNotificationErrorHandler(),
  });
  const relatedAssessments = data?.meeting?.relatedAssessments
    ? assertEdgesNonNull(data?.meeting.relatedAssessments)
    : [];
  const insights = data?.meeting?.insights
    ? assertEdgesNonNull(data.meeting.insights)
    : [];
  const meetingTemplateRequirements = insights.filter(
    (insight) => insight.type === InsightType.RequiredTemplate
  );
  const [openTemplateDialog, setOpenTemplatesDialog] = useState(false);
  const [templateDialogTemplateId, setTemplateDialogTemplateId] = useState<
    number | null
  >(null);

  const participants = assertEdgesNonNullWithStringId(meeting.participants);
  const currentUserIsParticipant = participants.find(
    (participant) => participant.user?.id === currentUser.id
  );

  if (
    relatedAssessments.length === 0 &&
    !(insights.length > 0 && currentUserIsParticipant)
  ) {
    return null;
  }

  const otherParticipant = participants.filter(
    (participant) => participant.user?.id !== currentUser.id
  )[0]?.user;

  const assessmentGroups = compact(
    insights
      .filter((insight) => insight.type === InsightType.CompleteAssessment)
      .map((insight) => {
        const allGroups = compact([
          insight.complianceProgram?.performanceAssessmentGroup,
          insight.complianceProgram?.managerAssessmentGroup,
          insight.complianceProgram?.peerAssessmentGroup,
        ]);
        return allGroups.find((g) => g.id === insight.assessmentGroup?.id);
      })
  );

  const performanceAssessmentRequirements = compact(
    assessmentGroups.map((assessmentGroup) => {
      const matchingAssessment = assertEdgesNonNull(
        assessmentGroup.assessments
      ).find(
        (assessment) =>
          assessment.target?.id &&
          assessment.responder?.id === currentUser.id &&
          assessment.target.id === otherParticipant?.id
      );
      if (matchingAssessment) {
        return {
          assessmentGroup,
          assessment: matchingAssessment,
        };
      }
      return null;
    })
  );

  function openTemplateDialogForTemplateId(id: number) {
    setTemplateDialogTemplateId(id);
    setOpenTemplatesDialog(true);
  }

  if (!currentUserIsParticipant) return null;

  return (
    <>
      {openTemplateDialog && (
        <TemplatesDialog
          meeting={meeting}
          meetingGroup={meeting.meetingGroup}
          initialTemplateId={templateDialogTemplateId}
          onClose={() => setOpenTemplatesDialog(false)}
        />
      )}
      <Layout.SidebarSection
        title="Related Requirements"
        expandedUiPreferenceKey="meetingSidebarRelatedRequirementsContainerExpanded"
      >
        <Layout.SidebarSubSection
          title={`Templates`}
          isExpanded={isExpandedTemplates}
          onToggleIsExpanded={setIsExpandedTemplates}
          loading={loading}
          count={meetingTemplateRequirements.length}
        >
          <Layout.SidebarSubSectionList>
            {meetingTemplateRequirements.map((node) => {
              const template = assertEdgesNonNull(
                node.complianceProgram?.requiredTopicTemplates
              )[0];
              return (
                <Layout.SidebarSubSectionListItem
                  className="text-sm flex justify-between gap-2"
                  key={node.id}
                >
                  <div className="flex-grow-0 flex-shrink">
                    You are required to discuss the{" "}
                    <Link
                      to={`/templates/${template.id}`}
                      className="text-blue-link text-sm"
                    >
                      {template.title}
                    </Link>{" "}
                    topics with {otherParticipant?.firstName}
                  </div>
                  <div className="flex-grow flex-shrink-0 basis-auto">
                    <button
                      className="text-amber-900 "
                      onClick={() => {
                        openTemplateDialogForTemplateId(template.id);
                      }}
                    >
                      Add Topics
                    </button>
                  </div>
                </Layout.SidebarSubSectionListItem>
              );
            })}
          </Layout.SidebarSubSectionList>
        </Layout.SidebarSubSection>
        <Layout.SidebarSubSection
          title={`${label("review", {
            capitalize: true,
            pluralize: performanceAssessmentRequirements.length,
          })}`}
          isExpanded={isExpandedAssessments}
          onToggleIsExpanded={setIsExpandedAssessments}
          loading={loading}
          count={performanceAssessmentRequirements.length}
        >
          <Layout.SidebarSubSectionList>
            {performanceAssessmentRequirements.map(
              ({ assessment, assessmentGroup }) => {
                const targetUserNameStr = assessment.target?.name;
                return (
                  <Layout.SidebarSubSectionListItem
                    className="text-sm flex gap-2 justify-between"
                    key={`${assessmentGroup.id}-${assessment.id}`}
                  >
                    <div className="flex-1">
                      Complete{" "}
                      <span className="font-medium text-black">
                        {assessmentGroup.title}
                      </span>{" "}
                      Assessment for {targetUserNameStr}
                    </div>
                    <div className="">
                      <AppLink
                        className="text-amber-900 "
                        to={`/assessments/assessment/${assessment.id}`}
                      >
                        Complete
                      </AppLink>
                    </div>
                  </Layout.SidebarSubSectionListItem>
                );
              }
            )}
          </Layout.SidebarSubSectionList>
        </Layout.SidebarSubSection>
      </Layout.SidebarSection>
    </>
  );
};

export default withErrorBoundary(RelatedRequirements, {
  fallback: (
    <AppError
      description={"The related requirements could not be displayed."}
    />
  ),
});
