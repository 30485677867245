import * as Sentry from "@sentry/react";
import { ArtifactType } from "types/graphql-schema";
import { BasicUser } from "types/topicflow";

import useLabel from "@apps/use-label/use-label";
import { currentUserVar } from "@cache/cache";
import Error from "@components/error/error";
import Layout from "@components/layout/layout";

import RecognitionsCollapsibleContainer from "./recognitions-collapsible-container";

const Recognitions = ({
  selectedUser,
  createdBetweenDates,
}: {
  selectedUser: BasicUser;
  createdBetweenDates?: string[];
}) => {
  const currentUser = currentUserVar();
  const label = useLabel();

  return (
    <>
      <Layout.SidebarSection
        aria-label="Dashboard recognition container"
        title={
          selectedUser.id === currentUser.id
            ? label("recognition", { pluralize: true, capitalize: true })
            : `${selectedUser.firstName || selectedUser.name}'s ${label(
                "recognition",
                {
                  pluralize: true,
                  capitalize: true,
                }
              )}`
        }
        expandedUiPreferenceKey="dashboardSidebarRecognitionContainerExpanded"
        createArtifactFormOptions={{
          artifactType: ArtifactType.Recognition,
          recognitionRecipients:
            selectedUser.id !== currentUser.id ? [selectedUser] : [],
        }}
      >
        <RecognitionsCollapsibleContainer
          title={`${label("recognition", { capitalize: true })} received`}
          filters={{
            fetchData: true,
            recognitionRecipient: selectedUser.id,
            asUser: selectedUser.id,
            first: 5,
            createdBetweenDates,
          }}
          localStorageKey="dashboardRecognitionReceivedExpanded"
        />
        <RecognitionsCollapsibleContainer
          title={`${label("recognition", { capitalize: true })} given`}
          filters={{
            fetchData: true,
            createdBy: selectedUser.id,
            asUser: selectedUser.id,
            first: 5,
            createdBetweenDates,
          }}
          localStorageKey="dashboardRecognitionGivenExpanded"
          roundedBottom
        />
      </Layout.SidebarSection>
    </>
  );
};

export default Sentry.withErrorBoundary(Recognitions, {
  fallback: <Error description={"The recognitions could not be rendered."} />,
});
