import { gql } from "@apollo/client";

import CommonFeedbackArtifactFragment from "@graphql/common-feedback-artifact-fragment";
import CommonRecognitionArtifactFragment from "@graphql/common-recognition-artifact-fragment";

export const CreatedArtifactFragment = gql`
  ${CommonFeedbackArtifactFragment}
  ${CommonRecognitionArtifactFragment}
  fragment CreatedArtifactFragment on ArtifactInterface {
    artifactType
    id
    title
    ... on ActionItemArtifactNode {
      title
      organization {
        id
      }
      canDelete {
        permission
      }
      actionItemState
      isComplete
      dueDate
      assignee {
        id
        avatar
        name
      }
    }
    ... on GoalArtifactNode {
      scope
      goalState: state
      parentGoalId
      parentGoal {
        id
        childGoals {
          edges {
            node {
              id
            }
          }
        }
      }
      childGoals {
        edges {
          node {
            id
          }
        }
      }
    }
    ... on DecisionArtifactNode {
      decision
    }
    ... on RecognitionArtifactNode {
      ...CommonRecognitionArtifactFragment
    }
    ... on FeedbackArtifactNode {
      ...CommonFeedbackArtifactFragment
    }
  }
`;

export default gql`
  ${CreatedArtifactFragment}
  mutation createArtifactMutation(
    $artifactType: ArtifactType!
    $title: String!
    $description: JSONString
    $organizationId: Int!
    $meetingId: Int
    $additionalFields: AdditionalArtifactInput
  ) {
    createOrUpdateArtifact(
      artifactType: $artifactType
      title: $title
      description: $description
      meetingId: $meetingId
      organizationId: $organizationId
      additionalFields: $additionalFields
    ) {
      artifact {
        ...CreatedArtifactFragment
      }
    }
  }
`;
