import { gql } from "@apollo/client";

export default gql`
  mutation updateTeamMutation(
    $id: Int
    $organizationId: Int
    $memberIds: [Int]
    $title: String
  ) {
    createOrUpdateTeam(
      teamId: $id
      organizationId: $organizationId
      members: $memberIds
      title: $title
    ) {
      team {
        id
        title
        members {
          totalCount
          edges {
            node {
              id
              name
              avatar
            }
          }
        }
      }
    }
  }
`;
