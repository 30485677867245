import { useMutation } from "@apollo/client";
import {
  ChangeEventHandler,
  KeyboardEventHandler,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useBeforeunload } from "react-beforeunload";
import { Prompt } from "react-router";
import { TopicSidebarTopicNodeFragment } from "types/graphql-schema";

import Button from "@components/button/button";
import GraphqlError from "@components/error/graphql-error";
import Form from "@components/form/form";
import TextareaAutosize from "@components/textarea-autosize/textarea-autosize";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import {
  classNames,
  inputBorderClassName,
  inputFocusClassName,
} from "@helpers/css";
import { isCommandEnterEvent, isEnterEvent } from "@helpers/helpers";
import { removeLineBreaks } from "@helpers/helpers";

import updateTopicMutation from "../graphql/update-topic-title-description-mutation";

const Header = ({ topic }: { topic: TopicSidebarTopicNodeFragment }) => {
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [formTopic, setFormTopic] = useState(topic);
  const canEditTitle = topic.canUpdate.permission && !topic.isMandatory;

  // when topic changes we hide form
  // and reset form values to current topic
  useEffect(() => {
    if (topic.id !== formTopic.id) {
      setFormTopic(topic);
      setIsEditingTitle(false);
    }
  }, [topic, formTopic]);

  const [saveTopic, { error, loading }] = useMutation(updateTopicMutation, {
    update: (cache, { data }) => {
      const topicId = cache.identify(topic);
      if (topicId) {
        cache.modify({
          id: topicId,
          fields: {
            title() {
              return data.createOrUpdateTopic.topic.title;
            },
            description() {
              return data.createOrUpdateTopic.topic.description;
            },
          },
        });
      }
    },
  });

  const handleSaveTitle = (e: SyntheticEvent) => {
    e.preventDefault();
    if (topic.title !== formTopic.title) {
      saveTopic({
        variables: { topicId: topic.id, title: formTopic.title },
        onError: onNotificationErrorHandler(),
        optimisticResponse: {
          createOrUpdateTopic: {
            topic: {
              ...topic,
              title: formTopic.title,
            },
            __typename: "CreateOrUpdateTopicMutation",
          },
        },
      });
    }
    setIsEditingTitle(false);
  };

  const handleKeyDownTitle: KeyboardEventHandler<HTMLTextAreaElement> = (e) => {
    if (isCommandEnterEvent(e)) {
      handleSaveTitle(e);
    }
  };

  const handleClickEdit = useCallback(() => {
    if (canEditTitle) {
      setIsEditingTitle(true);
    }
  }, [canEditTitle]);

  const handleChangeTitle: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    setFormTopic({ ...formTopic, title: e.target.value });
  };

  const handlePreventEnter: KeyboardEventHandler<HTMLTextAreaElement> = (e) => {
    if (isEnterEvent(e)) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }
  };

  const handleCancelTitle = () => {
    setIsEditingTitle(false);
    setFormTopic({ ...formTopic, title: topic.title });
  };

  useBeforeunload((event) => {
    if (isEditingTitle && topic.title !== formTopic.title) {
      event.preventDefault();
    }
  });

  // Render
  return (
    <div data-testid="topic-sidebar-header">
      <div className="flex gap-2">
        {isEditingTitle ? (
          <Form
            name="title-form"
            onSubmit={handleSaveTitle}
            data-lpignore="true"
            autoComplete="off"
            aria-label="Topic title form"
            className="flex-1 mb-4"
          >
            <Prompt
              when={formTopic.title !== topic.title}
              message="Leave page? Changes you made may not be saved."
            />
            <TextareaAutosize
              placeholder="Topic title..."
              minRows={1}
              name="title"
              className={classNames(
                inputBorderClassName,
                inputFocusClassName,
                "w-full block font-medium text-2xl px-2 py-1"
              )}
              onChange={handleChangeTitle}
              value={removeLineBreaks(formTopic.title)}
              onKeyDown={handleKeyDownTitle}
              onKeyPress={handlePreventEnter}
              autoFocus
              aria-label="Textbox topic title"
            />
            <div className="mt-2 text-right">
              <Button
                onClick={handleCancelTitle}
                text="Cancel"
                small
                className="mr-4"
                disabled={loading}
              />
              <Button
                type="submit"
                text="Save"
                theme="primary"
                small
                disabled={loading}
              />
            </div>
          </Form>
        ) : (
          <h2
            className={classNames(
              "font-medium text-2xl px-2 -ml-2 -mr-2 py-1 rounded border-white flex-1",
              canEditTitle &&
                "hover:bg-gray-100 hover:border-gray-200 focus:border-gray-100"
            )}
            aria-label="Sidebar topic title"
            onClick={handleClickEdit}
          >
            {topic.title}
          </h2>
        )}
      </div>

      {error && (
        <div className="mt-3">
          <GraphqlError
            error={error}
            whatDidNotWork="The topic title could not be saved."
          />
        </div>
      )}
    </div>
  );
};

export default Header;
