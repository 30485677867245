import { gql } from "@apollo/client";

export default gql`
  mutation updateMeetingGroupNewPageMutation(
    $meetingGroupId: Int!
    $facilitatorId: Int
    $allowManagementTreeVisibility: Boolean
    $allowAdminVisibility: Boolean
    $allowOrgVisibility: Boolean
    $isFormalOneonone: Boolean
    $transcribeMeetingsByDefault: Boolean
    $addTranscriptionBotToMeetingsByDefault: Boolean
  ) {
    updateMeetingGroup(
      meetingGroupId: $meetingGroupId
      facilitatorId: $facilitatorId
      allowAdminVisibility: $allowAdminVisibility
      allowManagementTreeVisibility: $allowManagementTreeVisibility
      allowOrgVisibility: $allowOrgVisibility
      isFormalOneonone: $isFormalOneonone
      transcribeMeetingsByDefault: $transcribeMeetingsByDefault
      addTranscriptionBotToMeetingsByDefault: $addTranscriptionBotToMeetingsByDefault
    ) {
      meetingGroup {
        id
        allowAdminVisibility
        allowManagementTreeVisibility
        allowOrgVisibility
        isFormalOneonone
        transcribeMeetingsByDefault
        addTranscriptionBotToMeetingsByDefault
        facilitator {
          id
          name
          avatar
        }
      }
    }
  }
`;
