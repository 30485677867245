import { useQuery } from "@apollo/client";
import { Dialog, RadioGroup } from "@headlessui/react";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  XIcon,
} from "@heroicons/react/outline";
import { values } from "lodash";
import { useEffect, useState } from "react";

import useLabel from "@apps/use-label/use-label";
import GraphqlError from "@components/error/graphql-error";
import Loading from "@components/loading/loading";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { classNames } from "@helpers/css";

import TemplatePreview from "./components/template-preview";
import getTemplatesQuery from "./graphql/get-templates-query";

type Template = {
  id: number;
  ordinal: number;
  title: string;
  templates: any[];
};

const TemplatesDialog = ({
  onClose,
  meeting,
  meetingGroup,
  initialTemplateId = null,
}: {
  onClose: any;
  meeting: any;
  meetingGroup: any;
  initialTemplateId?: number | null;
}) => {
  const label = useLabel();
  const organizationTemplates: Template = {
    id: 0,
    title: `${label("organization", { capitalize: true })} templates`,
    templates: [],
    ordinal: -3,
  };
  const personalTemplates: Template = {
    id: -1,
    ordinal: -1,
    title: "Personal templates",
    templates: [],
  };
  const [expandedCategoryId, setExpandedCategoryId] = useState(
    organizationTemplates.id
  );
  const [selectedTemplateId, setSelectedTemplateId] =
    useState(initialTemplateId);
  const { error, data } = useQuery(getTemplatesQuery, {
    fetchPolicy: "cache-and-network",
    onError: onNotificationErrorHandler(),
  });
  const templatesByCategoryId = data?.topicTemplates.edges.reduce(
    (memo: { [key: number]: Template }, { node: template }: { node: any }) => {
      const newMemo = { ...memo };

      if (!template.publicTemplate) {
        newMemo[personalTemplates.id] = {
          ...newMemo[personalTemplates.id],
          templates: newMemo[personalTemplates.id].templates.concat(template),
        };
      }

      if (!template.globalTemplate && template.publicTemplate) {
        newMemo[organizationTemplates.id] = {
          ...newMemo[organizationTemplates.id],
          templates:
            newMemo[organizationTemplates.id].templates.concat(template),
        };
      }

      if (template.categoriesList.length > 0) {
        template.categoriesList.forEach((category: any) => {
          if (!newMemo[category.id]) {
            newMemo[category.id] = { ...category, templates: [] };
          }
          newMemo[category.id].templates.push(template);
        });
      }
      return newMemo;
    },
    {
      [organizationTemplates.id]: organizationTemplates,
      [personalTemplates.id]: personalTemplates,
    }
  );
  const categories = values(templatesByCategoryId);
  categories.sort((a, b) => {
    return a.ordinal - b.ordinal;
  });
  const selectedTemplate = data?.topicTemplates.edges.find(
    ({ node }: { node: any }) => node.id === selectedTemplateId
  )?.node;

  useEffect(() => {
    if (templatesByCategoryId && !selectedTemplateId) {
      const categoryWithTemplate = categories.find(
        (category) => category.templates.length > 0
      );
      if (categoryWithTemplate) {
        setSelectedTemplateId(categoryWithTemplate.templates[0]?.id || null);
        if (categoryWithTemplate.id) {
          setExpandedCategoryId(categoryWithTemplate.id);
        }
      }
    }
  }, [templatesByCategoryId]);

  return (
    <Dialog
      as="div"
      className={classNames("fixed z-modal inset-0 overflow-y-auto")}
      open={true}
      onClose={onClose}
    >
      <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75" />
      <div className="flex min-h-full items-center justify-center p-4 text-center">
        <div className="w-full md:max-w-4xl mt-4 sm:mt-20 align-top transform rounded-lg bg-white text-left shadow-xl">
          <span className="z-dropdown p-1 rounded-full bg-white absolute -top-4 right-0">
            <button
              type="button"
              aria-label="Dialog close button"
              className="p-1 rounded-full text-gray-400 hover:bg-gray-100 focus:outline-none"
              onClick={onClose}
            >
              <span className="sr-only">Close panel</span>
              <XIcon className="h-5 w-5" />
            </button>
          </span>
          {error ? (
            <div className="mt-5 p-4">
              <GraphqlError
                error={error}
                whatDidNotWork="The templates could not be loaded."
              />
            </div>
          ) : data === undefined ? (
            <div className="mt-5 p-4">
              <Loading>Loading templates...</Loading>
            </div>
          ) : (
            <div className="flex min-h-96" aria-label="Templates dialog">
              <div className="w-1/3 flex flex-col bg-gray-100 border-r rounded-l-lg">
                <div className="divide-y" aria-label="Templates dialog list">
                  {categories.map((category, i) => (
                    <div key={category.id}>
                      <button
                        className={classNames(
                          "w-full text-left text-xs py-2 px-4 text-gray-600 flex justify-between items-center bg-gray-100 hover:bg-black/10",
                          i === 0 && "rounded-tl-lg"
                        )}
                        onClick={() => setExpandedCategoryId(category.id)}
                      >
                        <span>{category.title}</span>
                        {expandedCategoryId !== category.id ? (
                          <ChevronUpIcon className="h-4 w-4" />
                        ) : (
                          <ChevronDownIcon className="h-4 w-4" />
                        )}
                      </button>
                      <RadioGroup
                        value={selectedTemplateId}
                        onChange={setSelectedTemplateId}
                        className={classNames(
                          "p-2 bg-white",
                          // categories.length > 1 &&
                          expandedCategoryId !== category.id && "hidden"
                        )}
                      >
                        <div className="flex flex-col gap-1">
                          {category.templates.length > 0 &&
                            category.templates.map((template: any) => (
                              <RadioGroup.Option
                                key={template.id}
                                value={template.id}
                                className={classNames(
                                  "cursor-pointer flex items-center text-sm px-3 py-1.5 rounded",
                                  template.id === selectedTemplateId
                                    ? "bg-blue-100 hover:bg-black/5"
                                    : "hover:bg-black/5"
                                )}
                              >
                                <div className="flex-1">{template.title}</div>
                                <div className="ml-2 text-xs text-gray-500">
                                  {template.topicsList.length} topics
                                </div>
                              </RadioGroup.Option>
                            ))}
                          {category.templates.length === 0 && (
                            <div className="text-gray-500 text-xs px-3 py-1.5">
                              No templates
                            </div>
                          )}
                        </div>
                      </RadioGroup>
                    </div>
                  ))}
                </div>
              </div>
              <div className="flex-1 py-4 px-8">
                {selectedTemplate && (
                  <div>
                    <TemplatePreview
                      template={selectedTemplate}
                      meeting={meeting}
                      meetingGroup={meetingGroup}
                      onClose={onClose}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default TemplatesDialog;
