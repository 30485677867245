import { useQuery } from "@apollo/client";
import { useRouteMatch } from "react-router-dom";
import {
  GetMeetingRecognitionsNewPageQueryQuery,
  GetMeetingRecognitionsNewPageQueryQueryVariables,
  MeetingViewMeetingNodeNewPageFragmentFragment,
} from "types/graphql-schema";

import Artifact from "@apps/artifact/artifact";
import getMeetingRecognitionsQuery from "@apps/meeting-new/graphql/get-meeting-recognitions-query";
import useUiPreferenceCache, {
  UiPreferenceCache,
} from "@apps/use-ui-preference-cache/use-ui-preference-cache";
import Layout from "@components/layout/layout";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { assertEdgesNonNull } from "@helpers/helpers";

const MeetingRecognitionCollapsible = ({
  meeting,
  expandedUiPreferenceKey,
  title,
  variables,
}: {
  meeting: MeetingViewMeetingNodeNewPageFragmentFragment;
  expandedUiPreferenceKey: keyof UiPreferenceCache;
  title: string;
  variables: GetMeetingRecognitionsNewPageQueryQueryVariables;
}) => {
  const { url } = useRouteMatch();
  const { uiPreferenceCache } = useUiPreferenceCache();

  const { data, fetchMore, loading } = useQuery<
    GetMeetingRecognitionsNewPageQueryQuery,
    GetMeetingRecognitionsNewPageQueryQueryVariables
  >(getMeetingRecognitionsQuery, {
    notifyOnNetworkStatusChange: true,
    variables: {
      ...variables,
      fetchData: !!uiPreferenceCache[`${expandedUiPreferenceKey}`],
    },
    onError: onNotificationErrorHandler(),
  });

  const handleClickMore = () => {
    fetchMore({
      variables: {
        after: data?.artifacts?.pageInfo.endCursor,
        merge: true,
      },
    });
  };

  const totalCount = data?.artifacts?.totalCount;
  const recognitions = data?.artifacts?.edges
    ? assertEdgesNonNull(data.artifacts)
    : [];

  return (
    <Layout.SidebarSubSection
      expandedUiPreferenceKey={expandedUiPreferenceKey}
      title={title}
      loading={recognitions.length === 0 && loading}
      count={totalCount}
    >
      <Layout.SidebarSubSectionList
        loading={loading}
        hasNextPage={!!data?.artifacts?.pageInfo.hasNextPage}
        onClickMore={handleClickMore}
      >
        {recognitions.map((recognition) =>
          recognition.__typename === "RecognitionArtifactNode" ? (
            <Layout.SidebarSubSectionListItem key={recognition.id}>
              <Artifact artifact={recognition} urlPrefix={url} />
            </Layout.SidebarSubSectionListItem>
          ) : null
        )}
      </Layout.SidebarSubSectionList>
    </Layout.SidebarSubSection>
  );
};

export default MeetingRecognitionCollapsible;
