import { gql } from "@apollo/client";

export default gql`
  mutation deleteTopicTemplateMutation($topicTemplateId: Int!) {
    deleteTopicTemplate(topicTemplateId: $topicTemplateId) {
      topicTemplate {
        id
      }
    }
  }
`;
