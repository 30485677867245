import { gql } from "@apollo/client";

export default gql`
  mutation saveUserUiPreferenceMutation(
    $userId: Int!
    $uiPreferenceCache: JSONString
  ) {
    updateUser(id: $userId, uiPreferenceCache: $uiPreferenceCache) {
      user {
        id
        uiPreferenceCache
      }
    }
  }
`;
