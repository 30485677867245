import { LinkIcon } from "@heroicons/react/outline";
import { compact } from "lodash";
import { useCallback, useState } from "react";
import {
  AlignmentGoalFragmentFragment,
  GoalArtifactSidebarFragmentFragment,
  KeyResultFragmentFragment,
} from "types/graphql-schema";

import useLabel from "@apps/use-label/use-label";
import Avatar from "@components/avatar/avatar";
import Dropdown from "@components/dropdown/dropdown";
import ProgressBar from "@components/progress-bar/progress-bar";
import StartCurrentTargetTooltip from "@components/tooltip/start-current-target-tooltip";
import Tooltip from "@components/tooltip/tooltip";
import { classNames } from "@helpers/css";
import {
  getProgressFromStartCurrentTargetValues,
  getProgressLabel,
} from "@helpers/helpers";

import GoalKeyResultModal from "./goal-key-result-modal";

const GoalKeyResultItem = ({
  keyResult,
  goalArtifact,
  onDelete,
  isReadOnly = false,
  className = "",
}: {
  keyResult: KeyResultFragmentFragment;
  goalArtifact:
    | GoalArtifactSidebarFragmentFragment
    | ({ __typename: "GoalArtifactNode" } & AlignmentGoalFragmentFragment);
  onDelete?: (keyResult: KeyResultFragmentFragment) => void;
  isReadOnly?: boolean;
  className?: string;
}) => {
  const label = useLabel();
  const [isEditing, setIsEditing] = useState(false);

  const handleHideEditing = () => {
    setIsEditing(false);
  };

  const handleDelete = useCallback(() => {
    if (
      onDelete &&
      window.confirm(
        `Are you sure you want to delete this ${label("key result")}?`
      )
    ) {
      onDelete(keyResult);
    }
  }, [onDelete, label, keyResult]);

  const progress = getProgressFromStartCurrentTargetValues(keyResult);

  return (
    <>
      {isEditing && (
        <GoalKeyResultModal
          keyResult={keyResult}
          goalArtifact={goalArtifact}
          onClose={handleHideEditing}
        />
      )}
      <div
        className={classNames(
          "flex gap-2.5 items-center w-full text-gray-700",
          className
        )}
      >
        <div className="flex-1 text-sm fs-mask flex items-start gap-1.5">
          <span className="mx-1.5 h-2 w-2 mt-1.5 rounded-full bg-gray-200" />
          {keyResult.title}
        </div>
        {keyResult.kpi?.id && (
          <Tooltip
            text={`<div>Connected to KPI: ${
              keyResult.kpi.title
            }.</div><div>The value of this ${label(
              "key result"
            )} will be updated when the KPI's value is updated.</div>`}
          >
            <a
              className="rounded p-0.5 -mx-0.5 hover:bg-gray-100"
              aria-label="KR connected to KPI icon"
              href={`/kpis/${keyResult.kpi.id}`}
            >
              <LinkIcon className="h-4 w-4 text-gray-500" />
            </a>
          </Tooltip>
        )}
        <StartCurrentTargetTooltip goalOrKeyresult={keyResult}>
          <ProgressBar
            label={getProgressLabel(keyResult, { shortVersion: true })}
            progress={progress}
          />
        </StartCurrentTargetTooltip>

        <Avatar
          user={keyResult.assignee}
          tooltipPrefix="Assigned to "
          size="5"
        />

        {(goalArtifact.canUpdate.permission ||
          goalArtifact.canDelete?.permission) &&
          !isReadOnly && (
            <Dropdown
              align="end"
              options={compact([
                goalArtifact.canUpdate.permission && {
                  label: "Edit",
                  onClick: () => setIsEditing(true),
                },
                goalArtifact.canDelete?.permission && {
                  label: "Delete",
                  onClick: handleDelete,
                },
              ])}
            />
          )}
      </div>
    </>
  );
};

export default GoalKeyResultItem;
