import pluralize from "pluralize";
import { useMemo } from "react";
import { GetAccountQuery } from "types/graphql-schema";

import { assertEdgesNonNull, assertNonNull } from "@helpers/helpers";

import OrganizationInvitation from "./invitation";
import Organization from "./organization";

const Organizations = ({ me }: { me: GetAccountQuery["me"] }) => {
  const organizations = useMemo(
    () => (me?.organizations ? assertEdgesNonNull(me.organizations) : []),
    [me]
  );
  const nonPersonalOrganizations = useMemo(
    () => organizations.filter((org) => !org.personal),
    [organizations]
  );
  const availableToJoin = useMemo(
    () => assertNonNull(me?.organizationsAvailableToJoin),
    [me]
  );
  return (
    <div>
      {nonPersonalOrganizations.length === 0 &&
        availableToJoin.length === 0 && (
          <div className="text-gray-500">
            You have not joined any organizations
          </div>
        )}

      {nonPersonalOrganizations.map((organization) => (
        <Organization
          organization={organization}
          me={me}
          key={organization.id}
        />
      ))}

      {availableToJoin.length > 0 && (
        <div className="rounded-lg border border-dashed bg-gray-50 p-4 flex flex-col gap-4">
          <div className="text-sm font-medium">
            {availableToJoin.length} Organization{" "}
            {pluralize("invitation", availableToJoin.length)}
          </div>
          {availableToJoin.map((organization) => {
            const org = assertNonNull(organization);
            return (
              <OrganizationInvitation
                organization={org}
                key={org.organizationId}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Organizations;
