import { useMutation } from "@apollo/client";
import copy from "copy-to-clipboard";
import { createRef, useCallback, useEffect, useState } from "react";
import {
  ActivityCommentFragmentFragment,
  ArtifactType,
  TopicNode,
} from "types/graphql-schema";

import deleteCommentMutation from "@apps/comments/graphql/delete-comment-mutation";
import updateCommentMutation from "@apps/comments/graphql/update-comment-mutation";
import { currentUserVar, successNotificationVar } from "@cache/cache";
import removeCommentFromCache from "@cache/remove-comment";
import Button from "@components/button/button";
import Dropdown from "@components/dropdown/dropdown";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import CommentWYSIWYG from "@components/wysiwyg/comment-wysiwyg";
import { isEmptyValue } from "@components/wysiwyg/helpers";
import {
  getCommentUrl,
  isCommandEnterEvent,
  parseStringToJSON,
} from "@helpers/helpers";
import useConfirm from "@helpers/hooks/use-confirm";

const ActivityComment = ({
  comment,
  meetingGroupId,
  meetingId,
  topic,
  artifact,
  selected = false,
}: {
  comment: ActivityCommentFragmentFragment;
  meetingGroupId?: number;
  meetingId?: number;
  topic?: TopicNode;
  artifact?: {
    id: number;
    artifactType: ArtifactType;
  };
  selected?: boolean;
}) => {
  const currentUser = currentUserVar();
  const url = getCommentUrl({
    topicId: topic?.id,
    artifactId: artifact?.id,
    artifactType: artifact?.artifactType,
    meetingId: meetingId,
    meetingGroupId: meetingGroupId,
    commentId: comment.id,
  });

  const [isEditing, setIsEditing] = useState(false);
  const [formComment, setFormComment] = useState(comment.comment);
  const [updateComment] = useMutation(updateCommentMutation);
  const [deleteComment] = useMutation(deleteCommentMutation, {
    update: () => {
      removeCommentFromCache({
        commentId: comment.id,
        topicId: topic?.id,
        artifactId: artifact?.id,
      });
    },
  });
  const { ConfirmationDialog, confirm } = useConfirm(
    "Are you sure?",
    "Are you sure you want to delete this comment?"
  );

  // Handlers
  const handleSubmitForm = (e: React.SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  // Handlers
  const handleSaveForm = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (isEmptyValue(formComment)) {
      return;
    }
    updateComment({
      variables: {
        comment: JSON.stringify(formComment),
        commentId: comment.id,
      },
      optimisticResponse: {
        createOrUpdateComment: {
          __typename: "CreateOrUpdateCommentMutation",
          comment: {
            ...comment,
            comment: JSON.stringify(formComment),
          },
        },
      },
      onError: onNotificationErrorHandler(),
    });
    setIsEditing(false);
  };

  const handleKeyDownForm = (e: React.KeyboardEvent) => {
    if (isCommandEnterEvent(e)) {
      handleSaveForm(e);
    }
  };

  const handleChangeComment = (value: JSON) => {
    setFormComment(value);
  };

  const handleDeleteComment = useCallback(async () => {
    const confirmation = await confirm();
    if (confirmation) {
      deleteComment({
        variables: { commentId: comment.id },
        onError: onNotificationErrorHandler(),
      });
    }
  }, [comment, confirm, deleteComment]);

  const handleEditComment = () => {
    setFormComment(comment.comment);
    setIsEditing(true);
  };

  const handleCopyLink = () => {
    copy(`${document.location.origin}${url}`);
    successNotificationVar({
      title: "Comment link copied!",
      timeout: 2000,
    });
  };

  const handleCancel = () => {
    setFormComment(comment.comment);
    setIsEditing(false);
  };

  const ref = createRef();
  useEffect(() => {
    if (ref.current && selected) {
      (ref.current as HTMLElement).scrollIntoView({ behavior: "smooth" });
    }
  }, [ref, selected]);

  // Render
  if (comment.deleted)
    return (
      <div className="italic text-gray-500">Comment has been deleted.</div>
    );
  return (
    <div className="text-sm text-gray-900">
      <ConfirmationDialog />
      {isEditing ? (
        <form
          aria-label="Edit comment form"
          onSubmit={handleSubmitForm}
          onKeyDown={handleKeyDownForm}
        >
          <div className="shadow-sm block w-full ">
            <CommentWYSIWYG
              value={parseStringToJSON(formComment)}
              editable
              meetingGroupId={meetingGroupId}
              onChange={handleChangeComment}
              className="py-1 px-2 my-2 bg-white border rounded-md text-sm"
              topicId={topic?.id}
              uploadVariable={{
                topicId: topic?.id,
                artifactId: artifact?.id,
              }}
              mentionsConfig={{
                meetingGroupId,
                meetingId,
                artifactId: artifact?.id,
              }}
            />
          </div>
          <div className="mt-2">
            <Button
              type="button"
              aria-label="Comment form submit button"
              text={"Save"}
              theme="primary"
              disabled={isEmptyValue(formComment)}
              className="mr-2"
              small
              onClick={handleSaveForm}
            />
            <Button type="button" text="Cancel" onClick={handleCancel} small />
          </div>
        </form>
      ) : (
        <div className="flex gap-2">
          <div className="flex-1">
            <CommentWYSIWYG
              key={comment.comment} // force re-rendering
              uploadVariable={{
                topicId: topic?.id,
                artifactId: artifact?.id,
              }}
              mentionsConfig={{
                meetingGroupId,
                meetingId,
                artifactId: artifact?.id,
              }}
              value={parseStringToJSON(comment.comment)}
              className="border bg-white rounded-lg px-2 py-0.5"
            />
          </div>
          {comment.creator?.id === currentUser.id && (
            <div className="mt-1">
              <Dropdown
                aria-label="Comment action dropdown"
                options={[
                  {
                    label: "Copy link",
                    onClick: handleCopyLink,
                  },
                  {
                    label: "Edit",
                    onClick: handleEditComment,
                  },
                  {
                    label: "Delete",
                    onClick: handleDeleteComment,
                  },
                ]}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ActivityComment;
