import { gql } from "@apollo/client";

import { MeetingDialogFragment } from "./meeting-dialog-fragment";

export default gql`
  ${MeetingDialogFragment}
  mutation updateAttendanceStatusMutation(
    $meetingId: Int
    $meetingGroupId: Int
    $status: AttendeeStatus!
  ) {
    updateAttendanceStatus(
      meetingGroupId: $meetingGroupId
      meetingId: $meetingId
      status: $status
    ) {
      meeting {
        ...MeetingDialogFragment
      }
    }
  }
`;
