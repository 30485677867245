import useLabel from "@apps/use-label/use-label";
import Heading from "@components/heading/heading";

import CompetenciesList from "./competencies-list";
import JobLadder from "./job-ladder";

const RolesAndCompetencies = ({
  organizationId,
}: {
  organizationId: number;
}) => {
  const label = useLabel();
  return (
    <div>
      <div className="mb-6">
        <Heading
          small
          title={`Roles and ${label("competency", {
            pluralize: true,
            capitalize: true,
          })}`}
        />
      </div>

      <div>
        <JobLadder organizationId={organizationId} />
        <CompetenciesList organizationId={organizationId} />
      </div>
    </div>
  );
};

export default RolesAndCompetencies;
