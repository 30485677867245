import { LoggedInUserOrgFragment, PricingTier } from "types/graphql-schema";

import { ContactSales } from "@apps/org-settings/components/billing";
import Modal from "@components/modal/modal";
import ModalTitle from "@components/modal/modal-title";

type Props = {
  organization: LoggedInUserOrgFragment;
  onClose?: () => void;
};

const BillingDialog: React.FC<Props> = ({ onClose }) => {
  return (
    <Modal open onClose={onClose}>
      <div className="p-6 flex flex-col gap-6">
        <ModalTitle onClose={onClose}>Upgrade plan</ModalTitle>
        <div className="flex items-center gap-6">
          <ContactSales pricingTier={PricingTier.Ultimate} />
          <a
            href="https://www.topicflow.com/pricing/"
            target="_blank"
            rel="noreferrer"
            className="underline"
          >
            View our detailed plan comparison
          </a>
        </div>
      </div>
    </Modal>
  );
};

export default BillingDialog;
