import { gql } from "@apollo/client";

import invitationFragment from "./invitation-fragment";
import organizationFragment from "./organization-fragment";
import syncCredentialFragment from "./sync-credential-fragment";

export default gql`
  ${syncCredentialFragment}
  ${invitationFragment}
  ${organizationFragment}
  mutation acceptInvitationMutation($invitationId: Int!) {
    acceptInvitation(invitationId: $invitationId) {
      user {
        id
        syncCredentials {
          edges {
            node {
              ...SyncCredentialFragment
            }
          }
        }
        organizations(personal: false) {
          edges {
            node {
              ...OrganizationFragment
            }
          }
        }
        invitations(status: pending) {
          edges {
            node {
              ...InvitationFragment
            }
          }
        }
      }
      invitation {
        ...InvitationFragment
      }
    }
  }
`;
