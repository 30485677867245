import { useMutation } from "@apollo/client";
import moment from "moment";
import { FormEvent, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import {
  CreateOrUpdateKpiMeasurementMutationMutation,
  CreateOrUpdateKpiMeasurementMutationMutationVariables,
} from "types/graphql-schema";

import Button, { buttonTheme } from "@components/button/button";
import Input from "@components/input/input";
import Loading from "@components/loading/loading";
import Modal from "@components/modal/modal";
import ModalTitle from "@components/modal/modal-title";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import {
  classNames,
  inputBorderClassName,
  inputFocusClassName,
} from "@helpers/css";

import createOrUpdateKpiMeasurementMutation from "../graphql/create-or-update-kpi-measurement-mutation";
import getKpiMeasurementsQuery from "../graphql/get-kpi-measurements-query";
import getKpiQuery from "../graphql/get-kpi-query";
import getKpiSummaryQuery from "../graphql/get-kpi-summary-query";
import getKpisQuery from "../graphql/get-kpis-query";

const KPIAddMeasurementDialog = ({
  measurementId,
  open,
  date = null,
  measurement = "",
  onClose,
  kpiId,
  onCreated,
}: {
  open: boolean;
  measurementId?: number;
  measurement?: string;
  date?: string | null;
  onClose: () => void;
  kpiId: number;
  onCreated?: () => void;
}) => {
  const inputRef = useRef<null | HTMLInputElement>(null);
  const [formValidationError, setFormValidationError] = useState("");
  const [form, setForm] = useState<{
    measurement: string;
    datetime: Date | null;
  }>({
    measurement: measurement,
    datetime: date
      ? moment(date)
          .hours(moment().hours())
          .minutes(moment().minutes())
          .toDate()
      : moment().toDate(),
  });
  const [createOrUpdateKpiMeasurement, { loading }] = useMutation<
    CreateOrUpdateKpiMeasurementMutationMutation,
    CreateOrUpdateKpiMeasurementMutationMutationVariables
  >(createOrUpdateKpiMeasurementMutation);

  let error = "";
  if (form.measurement.trim() === "") {
    error = "Enter a measurement value";
  } else if (Number.isNaN(Number(form.measurement))) {
    error = "Measurement value has to be a valid number";
  } else if (!form.datetime) {
    error = "Please enter a valid date";
  } else if (moment(form.datetime).isAfter(moment().add(1, "minute"))) {
    error = "Please enter a date that is not in the future.";
  }

  const handleSubmitForm = (e: FormEvent) => {
    e.preventDefault();
    if (error) {
      setFormValidationError(error);
      return;
    }
    createOrUpdateKpiMeasurement({
      variables: {
        kpiMeasurementId: measurementId,
        measurement: Number(form.measurement),
        timestamp: moment(form.datetime).format(),
        kpiId: kpiId,
      },
      refetchQueries: [
        getKpiQuery,
        getKpiMeasurementsQuery,
        getKpiSummaryQuery,
        getKpisQuery,
      ],
      onError: onNotificationErrorHandler(),
      onCompleted: onCreated,
    });
  };

  // RENDER
  return (
    <Modal open={open} onClose={onClose} initialFocus={inputRef}>
      <form className="flex flex-col gap-6 p-6" onSubmit={handleSubmitForm}>
        <ModalTitle onClose={onClose}>Add measurement</ModalTitle>
        <div className="max-w-96">
          <Input
            label="Measurement value"
            value={form.measurement}
            ref={inputRef}
            onChange={(e) =>
              setForm({
                ...form,
                measurement: e.target.value,
              })
            }
          />
        </div>
        <div className="max-w-96">
          <DatePicker
            withPortal
            portalId="portal-react-datepicker"
            popperProps={{ strategy: "fixed" }}
            selected={form.datetime}
            onChange={(datetime) => setForm({ ...form, datetime })}
            timeInputLabel="Time:"
            dateFormat="MM/dd/yyyy h:mm aa"
            maxDate={new Date()}
            showTimeInput
            placeholderText="Time stamp"
            className={classNames(
              "px-4 py-2 text-sm w-full",
              inputBorderClassName,
              inputFocusClassName
            )}
          />
        </div>
        {formValidationError && (
          <div className="text-sm text-red-700">{formValidationError}</div>
        )}
        <div className="flex gap-2">
          <Button
            disabled={loading || error !== ""}
            tooltip={error}
            text="Add measurement"
            theme={buttonTheme.primary}
            type="submit"
          />
          {loading && <Loading mini size="5" />}
        </div>
      </form>
    </Modal>
  );
};

export default KPIAddMeasurementDialog;
