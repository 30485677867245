import { gql } from "@apollo/client";

export const MeetingDialogFragment = gql`
  fragment MeetingDialogFragment on MeetingNode {
    id
    title
    description
    draft
    meetingGroupId
    ignored
    videoConferenceType
    videoConferenceUrl
    startDatetime
    endDatetime
    isRecurring
    organizer {
      id
      name
    }
    meetingGroup {
      id
      title
      description
      isFormalOneonone
      facilitator {
        id
        name
        avatar
      }
      topicTemplate {
        id
      }
      syncCredentials {
        id
        credentialsUid
      }
      recurrenceRule
      recurrenceParameters {
        byMonthDay
        byYearDay
        byWeekDay
        bySetPos
        frequency
        interval
      }
      onlineConference
    }
    canRead {
      permission
      reason
    }
    canUpdate {
      permission
      reason
    }
    canDelete {
      permission
      reason
    }
    creator {
      id
    }
    participants {
      totalCount
      edges {
        node {
          id
          status
          participantEmail
          role
          user {
            id
            name
            firstName
            lastName
            avatar
            email
            status
          }
        }
      }
    }
  }
`;
