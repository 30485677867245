import { gql } from "@apollo/client";

import { MeetingDialogFragment } from "./meeting-dialog-fragment";

export default gql`
  ${MeetingDialogFragment}
  query getMeetingToEditQuery($meetingId: Int!) {
    meeting(meetingId: $meetingId) {
      ...MeetingDialogFragment
    }
  }
`;
