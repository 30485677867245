import { gql } from "@apollo/client";

export default gql`
  mutation deleteArtifactMutation($artifactId: Int!) {
    deleteArtifact(artifactId: $artifactId) {
      artifact {
        id
      }
    }
  }
`;
