import { gql } from "@apollo/client";

export default gql`
  mutation alignGoalMutation($goalId: Int!, $parentGoalId: Int) {
    alignGoal(goalId: $goalId, parentGoalId: $parentGoalId) {
      goal {
        id
        ... on GoalArtifactNode {
          childGoals {
            edges {
              node {
                id
              }
            }
          }
          parentGoal {
            id
            childGoals {
              edges {
                node {
                  id
                }
              }
            }
          }
        }
      }
      previousParentGoal {
        id
        ... on GoalArtifactNode {
          childGoals {
            edges {
              node {
                id
              }
            }
          }
          parentGoal {
            id
          }
        }
      }
    }
  }
`;
