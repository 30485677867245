import { useReactiveVar } from "@apollo/client";
import { Dialog } from "@headlessui/react";

import { editorVersionVar } from "@cache/cache";
import Button, { buttonTheme } from "@components/button/button";

const OutdatedEditorLayout = () => {
  const editorVersion = useReactiveVar(editorVersionVar);
  const isDifferentVersion =
    editorVersion.oldVersion !== null &&
    editorVersion.newVersion !== null &&
    String(editorVersion.oldVersion) !== String(editorVersion.newVersion);

  return (
    <Dialog
      as="div"
      open={isDifferentVersion}
      className="fixed inset-0 flex z-tooltip"
      onClose={() => {}}
    >
      <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
      <div className="relative flex-1 flex flex-col w-full items-center justify-center p-4 rounded">
        <div className="bg-white rounded-lg p-8 w-max-xs flex flex-col gap-4">
          <div className="text-center font-medium">
            A new version of Topicflow is available
          </div>
          <div className="text-center">
            Reloading is required in order to continue.
          </div>
          <div className="flex justify-center items-center gap-4 mt-8">
            <Button
              text="Reload"
              theme={buttonTheme.primary}
              onClick={() => {
                document.location.reload();
              }}
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default OutdatedEditorLayout;
