import { RadioChangeEvent } from "@szhsin/react-menu";
import { compact } from "lodash";
import { ReactElement } from "react";

import { ArtifactCreationDialogFormType } from "@apps/artifact-creation-dialog/artifact-creation-dialog";
import useLabel from "@apps/use-label/use-label";
import useUiPreferenceCache, {
  UiPreferenceCache,
} from "@apps/use-ui-preference-cache/use-ui-preference-cache";
import Layout from "@components/layout/layout";

const MeetingSidebarSection = ({
  titleLabel,
  collapsibleFilterName,
  toggledAssignee,
  toggledJustMeeting,
  isFormalOneonone,
  createArtifactFormOptions,
  subject,
  expandedUiPreferenceKey,
  children,
}: {
  titleLabel: "recognition" | "goal" | "action item" | "feedback";
  collapsibleFilterName: "Recognitions" | "Goals" | "ActionItems" | "Feedback";
  toggledAssignee: boolean;
  toggledJustMeeting: boolean;
  isFormalOneonone: boolean;
  expandedUiPreferenceKey?: keyof UiPreferenceCache;
  createArtifactFormOptions: Partial<ArtifactCreationDialogFormType>;
  subject?: {
    id: number;
    firstName: string;
    email: string;
  } | null;
  filters?: ReactElement | ReactElement[];
  children: ReactElement | ReactElement[];
}) => {
  const label = useLabel();
  const { saveUiPreference } = useUiPreferenceCache();

  const handleToggleJustMePreference = (event: RadioChangeEvent) => {
    if (isFormalOneonone) {
      saveUiPreference({
        [`oneonone${collapsibleFilterName}JustMe`]: event.value,
      });
    } else {
      saveUiPreference({
        [`meeting${collapsibleFilterName}JustMe`]: event.value,
      });
    }
  };

  const handleToggleJustMeetingPreference = (event: RadioChangeEvent) => {
    if (isFormalOneonone) {
      saveUiPreference({
        [`oneonone${collapsibleFilterName}JustMeeting`]: event.value,
      });
    } else {
      saveUiPreference({
        [`meeting${collapsibleFilterName}JustMeeting`]: event.value,
      });
    }
  };

  const assigneeLabel =
    isFormalOneonone && subject ? subject.firstName || subject.email : "Me";

  return (
    <>
      <Layout.SidebarSection
        title={label(titleLabel, { pluralize: true, capitalize: true })}
        expandedUiPreferenceKey={expandedUiPreferenceKey}
        aria-label={`Meeting ${titleLabel}s sidebar`}
        createArtifactFormOptions={createArtifactFormOptions}
        options={compact([
          titleLabel !== "feedback" && {
            label: titleLabel === "action item" ? "Assigned to" : "Involving",
            type: "radio",
            value: toggledAssignee,
            onChangeValue: handleToggleJustMePreference,
            options: [
              {
                label: assigneeLabel,
                value: true,
              },
              {
                label: "All participants",
                value: false,
              },
            ],
          },
          { type: "divider" },
          {
            label: "Referenced in",
            type: "radio",
            value: toggledJustMeeting,
            onChangeValue: handleToggleJustMeetingPreference,
            options: [
              {
                label: "This meeting only",
                value: true,
              },
              {
                label: "All",
                value: false,
              },
            ],
          },
        ])}
      >
        {children}
      </Layout.SidebarSection>
    </>
  );
};

export default MeetingSidebarSection;
