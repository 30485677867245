import { gql } from "@apollo/client";

export const GoalArtifactComponentFragment = gql`
  fragment GoalArtifactComponentFragment on GoalArtifactNode {
    ... on GoalArtifactNode {
      id
      artifactType
      title
      updated
      canUpdate {
        permission
      }
      canDelete {
        permission
      }
      creator {
        id
        name
        avatar
      }
      firstOwner {
        id
        name
        avatar
      }
      state
      scope
      ownerCount
      dueDate
      goalVisibility
      status
      progress
      progressType
      currentValue
      startValue
      targetValue
      isStale
      owners(first: 1) {
        edges {
          node {
            id
            name
            avatar
          }
        }
      }
      parentGoalId
      activities(first: 1, activityType: goal_checkin) {
        edges {
          node {
            created
          }
        }
      }
    }
  }
`;

export const RecognitionArtifactComponentFragment = gql`
  fragment RecognitionArtifactComponentFragment on RecognitionArtifactNode {
    ... on RecognitionArtifactNode {
      recognitionCoreValue {
        id
        title
        emoji
        image
      }
      recipients {
        totalCount
        edges {
          node {
            id
            name
            avatar
          }
        }
      }
    }
  }
`;

const ArtifactComponentFragment = gql`
  ${GoalArtifactComponentFragment}
  ${RecognitionArtifactComponentFragment}
  fragment ArtifactComponentFragment on ArtifactInterface {
    id
    artifactType
    title
    updated
    canUpdate {
      permission
    }
    canDelete {
      permission
    }
    creator {
      id
      name
      avatar
    }
    created
    ... on ActionItemArtifactNode {
      isComplete
      assignee {
        id
        name
        avatar
      }
    }
    ... on RecognitionArtifactNode {
      ...RecognitionArtifactComponentFragment
    }
    ... on DecisionArtifactNode {
      decision
      decisionState
      isDraft
    }
    ... on GoalArtifactNode {
      ...GoalArtifactComponentFragment
    }
    ... on RatingArtifactNode {
      rating {
        id
        title
      }
    }
  }
`;

export default ArtifactComponentFragment;
