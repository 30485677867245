import { gql } from "@apollo/client";

export default gql`
  fragment ComplianceProgramTarget on UserNode {
    id
    name
    avatar
    managers {
      edges {
        node {
          id
        }
      }
    }
    teams {
      edges {
        node {
          id
        }
      }
    }
  }

  fragment ComplianceProgramForReportAssessmentGroup on AssessmentGroupNode {
    id
    title
    assessmentType
    isOnlySelfAssessment
    sections {
      edges {
        node {
          id
          title
          description
          questions {
            edges {
              node {
                id
                question {
                  ... on RangeAssessmentQuestionNode {
                    id
                    title
                    description
                    startValue
                    endValue
                    labels
                    questionType
                    responses
                  }
                  ... on MultiChoiceAssessmentQuestionNode {
                    id
                    title
                    description
                    options
                    questionType
                    responses
                  }
                  ... on TextAssessmentQuestionNode {
                    id
                    title
                    description
                    questionType
                    responses
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  query getComplianceProgramForReport($complianceProgramId: Int!) {
    complianceProgram(pk: $complianceProgramId) {
      id
      title
      performanceAssessmentGroup {
        ...ComplianceProgramForReportAssessmentGroup
      }
      managerAssessmentGroup {
        ...ComplianceProgramForReportAssessmentGroup
      }
      peerAssessmentGroup {
        ...ComplianceProgramForReportAssessmentGroup
      }
      assessments(state: submitted) {
        edges {
          node {
            id
            state
            responder {
              id
              name
              avatar
            }
            target {
              ...ComplianceProgramTarget
            }
            answers {
              edges {
                node {
                  ... on RangeAssessmentAnswerNode {
                    id
                    question {
                      id
                    }
                    integerAnswer
                    comment
                  }
                  ... on MultiChoiceAssessmentAnswerNode {
                    id
                    question {
                      id
                    }
                    choices
                    comment
                  }
                  ... on TextAssessmentAnswerNode {
                    id
                    question {
                      id
                    }
                    textAnswer
                    comment
                  }
                }
              }
            }
            group {
              id
            }
          }
        }
      }
      managerEffectivenessReport {
        edges {
          node {
            manager {
              ...ComplianceProgramTarget
              directReports {
                totalCount
              }
            }
            assessments {
              totalCount
            }
            questions {
              edges {
                node {
                  question {
                    id
                  }
                  avgAnswer
                }
              }
            }
          }
        }
      }
    }
  }
`;
