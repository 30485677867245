import { Menu, Portal } from "@headlessui/react";
import { CogIcon } from "@heroicons/react/outline";
import { ChevronDownIcon } from "@heroicons/react/solid";
import React, { useState } from "react";
import { TbArrowBarLeft, TbArrowBarRight } from "react-icons/tb";
import { usePopper } from "react-popper";
import { useLocation } from "react-router-dom";
import { TFLocationState } from "types/topicflow";

import useLabel from "@apps/use-label/use-label";
import { currentUserVar, successNotificationVar } from "@cache/cache";
import Avatar from "@components/avatar/avatar";
import Link from "@components/link/link";
import client from "@graphql/client";
import { chromeExtensionUrl, role } from "@helpers/constants";
import { classNames } from "@helpers/css";
import { toWithBackground } from "@helpers/helpers";

import { apolloPersistCacheKey } from "../../../helpers/constants";

type Props = {
  menuIsCollapsed?: boolean;
  onToggleMenu?: () => void;
};

const SidebarSettings: React.FC<Props> = ({
  menuIsCollapsed,
  onToggleMenu,
}) => {
  const location = useLocation<TFLocationState>();
  const currentUser = currentUserVar();
  const label = useLabel();
  const [referenceElement, setReferenceElement] =
    useState<HTMLElement | null>();
  const [popperElement, setPopperElement] = useState<HTMLElement | null>();
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "top-start",
  });

  const isAdminOfAnOrganization = currentUser.organizations.edges.find(
    (edge) =>
      (edge?.node || edge?.node?.userMembership) &&
      !edge.node.personal &&
      edge?.node?.userMembership?.role === role.administrator
  );

  const clearCache = () => {
    client.resetStore();
    client.clearStore();
    localStorage.removeItem(apolloPersistCacheKey);
  };

  const handleClearCache = () => {
    clearCache();
    successNotificationVar({ title: "Cache cleared" });
  };

  const handleLogout = () => {
    clearCache();
    window.location.href = "/logout";
    if (window.Intercom) {
      window.Intercom("shutdown");
    }
  };

  return (
    <div
      className={classNames(
        "flex px-1.5 w-full justify-between",
        "fs-unmask",
        menuIsCollapsed ? "flex-col items-center gap-2" : "gap-1"
      )}
    >
      <Menu
        as="div"
        className={classNames(menuIsCollapsed && "min-w-0 w-full")}
      >
        <Menu.Button
          as="div" // keep this to make the truncating work
          aria-label="Sidebar settings"
          className={classNames(
            "flex items-center min-w-0 text-left gap-1 text-gray-700 text-sm py-1.5 px-2 rounded-lg hover:bg-gray-200 group",
            menuIsCollapsed ? "justify-center w-full" : "justify-start"
          )}
          ref={setReferenceElement}
        >
          <CogIcon
            className={classNames(
              "shrink-0 text-gray-400 group-hover:text-gray-700",
              menuIsCollapsed ? "h-6 w-6" : "h-5 w-5"
            )}
          />
          {!menuIsCollapsed && (
            <div className="ml-1 truncate font-medium tracking-tight">
              Settings
            </div>
          )}
          {!menuIsCollapsed && <ChevronDownIcon className="h-4 w-4" />}
        </Menu.Button>
        <Portal>
          <Menu.Items
            className="absolute rounded-md shadow-lg bg-white text-sm border z-dropdown outline-none ring-0"
            aria-label="Sidebar settings dropdown"
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
          >
            <Menu.Item>
              {({ active }) => (
                <Link
                  to={toWithBackground({
                    pathname: "/invite",
                    location,
                  })}
                  className={classNames(
                    active ? "bg-gray-100" : "",
                    "block w-56 py-2 px-4 rounded-t-md border-b"
                  )}
                >
                  Invite People
                </Link>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <Link
                  to="/account"
                  className={classNames(
                    active ? "bg-gray-100" : "",
                    "block w-56 py-2 px-4"
                  )}
                >
                  My Account Settings
                </Link>
              )}
            </Menu.Item>
            {isAdminOfAnOrganization?.node && (
              <Menu.Item>
                {({ active }) => (
                  <Link
                    to={`/settings/organization/${
                      isAdminOfAnOrganization.node!.id
                    }/settings`}
                    className={classNames(
                      active ? "bg-gray-100" : "",
                      "block w-56 py-2 px-4"
                    )}
                  >
                    {label("organization", { capitalize: true })} Settings
                  </Link>
                )}
              </Menu.Item>
            )}
            <Menu.Item>
              {({ active }) => (
                <a
                  href={chromeExtensionUrl}
                  target="_blank"
                  rel="noreferrer"
                  className={classNames(
                    active ? "bg-gray-100" : "",
                    "block w-56 py-2 px-4 border-t"
                  )}
                >
                  Get the Chrome Extension
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  href="https://www.topicflow.com/changelog/"
                  target="_blank"
                  rel="noreferrer"
                  className={classNames(
                    active ? "bg-gray-100" : "",
                    "block w-56 py-2 px-4 border-b"
                  )}
                >
                  Changelog
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={handleClearCache}
                  className={classNames(
                    active ? "bg-gray-100" : "",
                    "block w-56 py-2 px-4 text-left border-b"
                  )}
                >
                  Clear cache
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={handleLogout}
                  className={classNames(
                    active ? "bg-gray-100" : "",
                    "flex gap-2 items-center w-56 py-2 px-4 rounded-b-md text-left"
                  )}
                >
                  <Avatar user={currentUser} size="5" />
                  Logout
                </button>
              )}
            </Menu.Item>
          </Menu.Items>
        </Portal>
      </Menu>
      <button
        onClick={onToggleMenu}
        aria-label="Toggle sidebar button"
        className={classNames(
          "hidden sm:flex items-center justify-center text-gray-500 text-sm px-1 py-1 rounded-lg hover:bg-gray-200 hover:text-gray-600 shrink-0",
          menuIsCollapsed ? "w-full" : "w-8 h-8"
        )}
      >
        {menuIsCollapsed ? (
          <TbArrowBarRight className="h-4 w-4 shrink-0" />
        ) : (
          <TbArrowBarLeft className="h-4 w-4 shrink-0" />
        )}
      </button>
    </div>
  );
};
export default SidebarSettings;
