import { MouseEvent, useState } from "react";
import { TbPlug } from "react-icons/tb";
import { GetLoggedInUserQuery } from "types/graphql-schema";

import ConnectCalendarModal from "@apps/connect-calendar-modal/connect-calendar-modal";
import AppLink from "@components/link/link";

const SidebarOnboarding = ({
  currentUser,
}: {
  currentUser: NonNullable<GetLoggedInUserQuery["me"]>;
}) => {
  const [open, setOpen] = useState(false);
  const hasCalendarCredentials = currentUser.syncCredentials?.edges.find(
    (edge) => edge?.node?.hasValidCalendarCredentials
  );
  if (
    hasCalendarCredentials ||
    currentUser.status === "demo" ||
    currentUser.calendarIsSyncingForFirstTime
  ) {
    return null;
  }
  const handleClick = (e: MouseEvent) => {
    e.preventDefault();
    setOpen(true);
  };
  return (
    <div>
      <ConnectCalendarModal open={open} onClose={setOpen} />
      <div className="text-orange-800 mt-8 mx-2">No calendar connected.</div>
      <AppLink
        to="/account"
        onClick={handleClick}
        className="text-base mt-4 text-blue-600 font-medium bg-white rounded-lg border border-dashed hover:border-solid hover:shadow-sm hover:text-blue-700 flex flex-col gap-2 justify-center items-center py-6"
      >
        <TbPlug className="h-8 w-8" />
        Connect your calendar
      </AppLink>
    </div>
  );
};
export default SidebarOnboarding;
