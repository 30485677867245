import { useMutation } from "@apollo/client";
import { Popover } from "@headlessui/react";
import compact from "lodash/compact";
import uniqBy from "lodash/uniqBy";
import { FC, Ref, useState } from "react";
import {
  TopicDataFragmentFragment,
  TopicNodeNewPageFragmentFragment,
  TopicSidebarTopicNodeFragment,
} from "types/graphql-schema";

import { errorNotificationVar } from "@cache/cache";
import Avatar from "@components/avatar/avatar";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import UserCombobox from "@components/user-combobox/user-combobox";
import {
  UserComboboxOption,
  UserComboboxOptionType,
  UserComboboxUserOption,
} from "@components/user-combobox/user-combobox-list";
import { graphqlNone } from "@helpers/constants";

import updateTopicAssigneeMutation from "./graphql/update-topic-assignee-mutation";

const TopicAssignee = ({
  topic,
  children,
  assignableUsers = [],
}: {
  topic:
    | TopicNodeNewPageFragmentFragment
    | TopicSidebarTopicNodeFragment
    | TopicDataFragmentFragment;
  children?: FC<{
    disabled?: boolean;
    setReferenceElement?: Ref<HTMLButtonElement>;
  }>;
  assignableUsers?: any[];
}) => {
  const [query, setQuery] = useState("");
  const [saveTopic] = useMutation(updateTopicAssigneeMutation, {
    update(cache, { data: { createOrUpdateTopic } }) {
      const topicId = cache.identify(topic);
      if (topicId) {
        cache.modify({
          id: topicId,
          fields: {
            assignee() {
              return createOrUpdateTopic.topic.assignee;
            },
          },
        });
      }
    },
    onError() {
      errorNotificationVar({
        description: "The topic assignee could not be saved.",
      });
    },
  });

  const handleChangeAssignee = (assignee: UserComboboxOption) => {
    saveTopic({
      variables: { topicId: topic.id, assignee: assignee.id },
      optimisticResponse: {
        createOrUpdateTopic: {
          topic: {
            ...topic,
            assignee: assignee.id === graphqlNone ? null : assignee,
          },
          __typename: "CreateOrUpdateTopicMutation",
        },
      },
      onError: onNotificationErrorHandler(),
    });
  };

  const emptyOption: UserComboboxUserOption = {
    id: graphqlNone,
    email: "Unassigned",
    name: "Unassigned",
    avatar: "",
    type: UserComboboxOptionType.USER,
  };
  const allAssignableUsers = compact([
    emptyOption,
    topic.assignee,
    ...assignableUsers,
  ]);
  const options: UserComboboxUserOption[] = uniqBy(
    allAssignableUsers,
    ({ id }) => id
  ).map((user) => ({ type: UserComboboxOptionType.USER, ...user }));
  const selectedOption: UserComboboxUserOption = topic.assignee
    ? { type: UserComboboxOptionType.USER, ...topic.assignee }
    : emptyOption;

  return (
    <UserCombobox
      aria-label="Action item people dropdown"
      width="64"
      query={query}
      value={selectedOption}
      options={options}
      disabled={!topic.canUpdate.permission}
      onChangeValue={handleChangeAssignee}
      onChangeQuery={setQuery}
    >
      {({ setReferenceElement, disabled }) =>
        children ? (
          children({ setReferenceElement, disabled })
        ) : (
          <div className={"flex justify-between bg-white rounded-md"}>
            <Popover.Button
              disabled={disabled}
              className="flex items-center text-sm"
              ref={setReferenceElement}
            >
              <Avatar
                user={topic.assignee}
                tooltipPrefix="Assigned to "
                size="5"
              />
            </Popover.Button>
          </div>
        )
      }
    </UserCombobox>
  );
};

export default TopicAssignee;
