import { useMemo } from "react";
import { GetAccountQuery } from "types/graphql-schema";

import CalendarConnectCTA from "@apps/onboarding/components/calendar/calendar-connect-cta";
import Heading from "@components/heading/heading";
import SettingsLayout from "@components/settings-layout/settings-layout";
import { assertEdgesNonNull } from "@helpers/helpers";

import Calendar from "./calendar";
import Name from "./name";
import Organizations from "./organizations";

const Profile = ({
  me,
  refetch,
}: {
  me: GetAccountQuery["me"];
  refetch: () => void;
}) => {
  const calendars = useMemo(
    () => (me?.syncCredentials ? assertEdgesNonNull(me.syncCredentials) : []),
    [me]
  );
  return (
    <div>
      <Heading small title="Account Settings" />

      <SettingsLayout.Container>
        <SettingsLayout.Row>
          <SettingsLayout.Left label="How you appear to others in Topicflow.">
            Your identity
          </SettingsLayout.Left>
          <SettingsLayout.Right>
            <div className="border border-gray-300 rounded-md p-6">
              <Name />
            </div>
          </SettingsLayout.Right>
        </SettingsLayout.Row>

        <SettingsLayout.Row>
          <SettingsLayout.Left label="Connect other emails and calendars to your Topicflow account.">
            Emails and calendars
          </SettingsLayout.Left>
          <SettingsLayout.Right>
            <div>
              {calendars.map((calendar) => (
                <Calendar
                  key={calendar.credentialsUid}
                  me={me}
                  calendar={calendar}
                  refetch={refetch}
                />
              ))}
              <div>
                <CalendarConnectCTA
                  organizationId={null}
                  redirectUrl={`/account/profile`}
                />
              </div>
            </div>
          </SettingsLayout.Right>
        </SettingsLayout.Row>

        <SettingsLayout.Row>
          <SettingsLayout.Left>Organizations</SettingsLayout.Left>
          <SettingsLayout.Right>
            <Organizations me={me} />
          </SettingsLayout.Right>
        </SettingsLayout.Row>
      </SettingsLayout.Container>
    </div>
  );
};

export default Profile;
