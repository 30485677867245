import { gql } from "@apollo/client";

import { MeetingViewMeetingGroupNewPageFragment } from "@apps/meeting-new/graphql/get-meeting-group-query";

export default gql`
  fragment SlackNotificationFragment on SlackNotificationConnectionNode {
    id
    channel
  }
  fragment SlackChannelFragment on SlackChannelNode {
    id
    name
  }
  ${MeetingViewMeetingGroupNewPageFragment}
  query getMeetingGroupSettingsQuery($meetingGroupId: Int!) {
    meetingGroup(meetingGroupId: $meetingGroupId) {
      ...MeetingViewMeetingGroupNewPageFragment
      id
      transcribeMeetingsByDefault
      canUpdate {
        permission
      }
      slackNotifications {
        edges {
          node {
            ...SlackNotificationFragment
          }
        }
      }
      organization {
        id
        hasSlackConnection
        publicSlackChannels {
          edges {
            node {
              ...SlackChannelFragment
            }
          }
        }
      }
    }
  }
`;
