import { XIcon } from "@heroicons/react/solid";
import {
  OnlineConference,
  SyncCredentialsTypeEnum,
  UserSyncCredentialsNode,
} from "types/graphql-schema";

import { currentUserVar } from "@cache/cache";
import Button, { buttonTheme } from "@components/button/button";
import OnlineConferenceImage from "@components/online-conference-image/online-conference-image";
import OnlineConferenceName from "@components/online-conference-name/online-conference-name";
import { onlineConferenceName } from "@helpers/constants";

const VideoConference = ({
  selectedCalendar,
  onlineConference,
  onChangeOnlineConference,
}: {
  selectedCalendar: Pick<UserSyncCredentialsNode, "provider">;
  onlineConference?: OnlineConference | null;
  onChangeOnlineConference: (conference: OnlineConference) => void;
}) => {
  const currentUser = currentUserVar();
  const handleClickVideoConferenceOption = (value: OnlineConference) => () => {
    onChangeOnlineConference(value);
  };
  return (
    <div className="pt-1 flex gap-2 items-center">
      {onlineConference && onlineConference !== OnlineConference.None && (
        <OnlineConferenceImage
          type={onlineConference}
          provider={selectedCalendar.provider}
          size={5}
        />
      )}
      {onlineConference && onlineConference !== OnlineConference.None ? (
        <div className="flex-1 flex items-center justify-between gap-2 text-sm">
          <div>
            <OnlineConferenceName
              type={onlineConference}
              provider={selectedCalendar.provider}
            />{" "}
            info will be added.
          </div>
          <div>
            <button
              className="text-gray-400 hover:bg-gray-100 p-1 rounded"
              onClick={handleClickVideoConferenceOption(OnlineConference.None)}
              aria-label="Remove video conference button"
            >
              <XIcon className="h-4 w-4" />
            </button>
          </div>
        </div>
      ) : (
        <div className="flex-1 flex items-center gap-2">
          {selectedCalendar.provider ===
            SyncCredentialsTypeEnum.GoogleCalendar && (
            <Button
              className="flex items-center gap-1.5"
              theme={buttonTheme.lightBlue}
              small
              onClick={handleClickVideoConferenceOption(
                OnlineConference.Native
              )}
            >
              <OnlineConferenceImage
                type={OnlineConference.Native}
                provider={SyncCredentialsTypeEnum.GoogleCalendar}
                size={4}
              />
              Add {onlineConferenceName[SyncCredentialsTypeEnum.GoogleCalendar]}
            </Button>
          )}
          {selectedCalendar.provider ===
            SyncCredentialsTypeEnum.OutlookCalendar && (
            <Button
              className="flex items-center gap-1.5"
              theme={buttonTheme.lightBlue}
              small
              onClick={handleClickVideoConferenceOption(
                OnlineConference.Native
              )}
            >
              <OnlineConferenceImage
                type={OnlineConference.Native}
                provider={SyncCredentialsTypeEnum.OutlookCalendar}
                size={4}
              />
              Add{" "}
              {onlineConferenceName[SyncCredentialsTypeEnum.OutlookCalendar]}
            </Button>
          )}
          {currentUser.hasZoomAuth && (
            <Button
              className="flex items-center gap-1.5"
              theme={buttonTheme.lightBlue}
              small
              onClick={handleClickVideoConferenceOption(OnlineConference.Zoom)}
            >
              <OnlineConferenceImage type={OnlineConference.Zoom} size={4} />
              Add Zoom
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default VideoConference;
