import { ReactElement, ReactNode, useState } from "react";
import { TbCaretDownFilled, TbCaretRightFilled } from "react-icons/tb";

const PrepGroup = ({
  title,
  collapsible = false,
  isCollapsedByDefault = false,
  children,
}: {
  title: string | ReactNode;
  collapsible?: boolean;
  isCollapsedByDefault?: boolean;
  children: ReactElement | ReactElement[];
}) => {
  const [isCollapsed, setIsCollapsed] = useState(isCollapsedByDefault);
  return (
    <div className="py-6">
      <div className="text-lg font-medium flex items-center gap-2">
        {title}
        {collapsible && (
          <button
            className="px-1 py-0.5 text-gray-500 hover:bg-gray-100 hover:text-gray-800 rounded"
            onClick={() => setIsCollapsed(!isCollapsed)}
          >
            {isCollapsed ? (
              <TbCaretRightFilled className="h-4 w-4" />
            ) : (
              <TbCaretDownFilled className="h-4 w-4" />
            )}
          </button>
        )}
      </div>
      {(!collapsible || !isCollapsed) && <div>{children}</div>}
    </div>
  );
};

export default PrepGroup;
