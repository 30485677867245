import { ExternalLinkIcon, TrashIcon } from "@heroicons/react/outline";
import { Node, PasteRule } from "@tiptap/core";
import { NodeViewWrapper, ReactNodeViewRenderer } from "@tiptap/react";
import getYouTubeID from "get-youtube-id";
import { debounce } from "lodash";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import useResizeObserver from "use-resize-observer";

import { regexp, tiptapExtensionPriority } from "@helpers/constants";
import { classNames } from "@helpers/css";
import { parseGoogleUrl } from "@helpers/urls";

const minimumIframeHeight = 100;
const defaultHeight = "400px";

const IframeComponent = memo(
  ({
    node,
    updateAttributes,
    selected,
    deleteNode,
  }: {
    node: any;
    updateAttributes: (attributes: any) => void;
    selected: boolean;
    deleteNode: () => void;
  }) => {
    const containerRef = useRef(null);
    const [initialHeight] = useState(node.attrs.height);
    const { height } = useResizeObserver({
      ref: containerRef,
      box: "border-box",
    });

    // on initial rendering, height might be equal to 0
    // so we ensure iframe as at least minimumIframeHeight
    const debounceHeight = Math.max(
      height || initialHeight,
      minimumIframeHeight
    );
    const debouncedUpdateAttributes = debounce(updateAttributes, 1000);
    const callbackUpdateAttribute = useCallback(debouncedUpdateAttributes, []);

    useEffect(() => {
      callbackUpdateAttribute({ ...node.attrs, height: debounceHeight });
    }, [debounceHeight]);

    // To prevent iframe to scroll parent view to the iframe
    // We render the iframe only when visible
    // That's what Notion does
    const { ref, inView } = useInView({
      threshold: 0,
      triggerOnce: true,
    });

    return (
      <NodeViewWrapper className="react-component">
        <div ref={ref}>
          <div
            className={classNames(
              "flex resize-y border-2 p-1.5 overflow-scroll relative group",
              selected && "border-blue-200"
            )}
            ref={containerRef}
            style={{ height: node.attrs.height || defaultHeight }}
          >
            <div className="z-1 absolute top-1 right-1 flex gap-px">
              <a
                href={node.attrs.url || node.attrs.src}
                target="_blank"
                rel="noreferrer"
                className="px-2 py-1 text-xs rounded-l bg-black/80 text-gray-100 hidden group-hover:block hover:bg-black hover:text-white group-hover:no-underline"
              >
                <ExternalLinkIcon className="w-4 h-4" />
              </a>
              <button
                onClick={deleteNode}
                className="px-2 py-1 text-xs rounded-r bg-black/80 text-gray-100 hidden group-hover:block hover:bg-black hover:text-white group-hover:no-underline"
              >
                <TrashIcon className="w-4 h-4" />
              </button>
            </div>
            {inView ? (
              <iframe
                title="Iframe prevent scroll"
                className="flex-1 relative p-0"
                frameBorder="0"
                width="100%"
                height="100%"
                srcDoc={`
                  <iframe
                    id="child-iframe"
                    style="position: fixed; top: 0; bottom: 0; left: 0; right: 0; width: 100%; height: 100%;"
                    frameBorder="0"
                    src="${node.attrs.src}"
                    allowFullScreen="${node.attrs.allowFullScreen}"
                    title="Iframe"
                  ></iframe>
              `}
              ></iframe>
            ) : (
              <div>{node.attrs.src}</div>
            )}
          </div>
        </div>
      </NodeViewWrapper>
    );
  }
);

const getIframeUrlFromText = (text: string) => {
  let src = null;
  const youtubeId = getYouTubeID(text);
  if (youtubeId) {
    src = `https://www.youtube.com/embed/${youtubeId}`;
  }

  // Figma
  if (regexp.figmaUrl.test(text)) {
    src = `https://www.figma.com/embed?embed_host=share&url=${encodeURIComponent(
      text
    )}`;
  }

  // Loom
  if (regexp.loomUrl.test(text)) {
    const regexpValue = regexp.loomUrl.exec(text);
    if (regexpValue) {
      src = `https://www.loom.com/embed/${regexpValue[2]}`;
    }
  }

  // Miro
  if (regexp.miroUrl.exec(text)) {
    const regexpValue = regexp.miroUrl.exec(text);
    if (regexpValue) {
      src = `https://miro.com/app/live-embed/${regexpValue[1]}/`;
    }
  }

  // Vimeo
  if (regexp.vimeoUrl.exec(text)) {
    const regexpValue = regexp.vimeoUrl.exec(text);
    if (regexpValue) src = `//player.vimeo.com/video/${regexpValue[3]}`;
  }

  // Google Docs (document, slides, etc..)
  if (parseGoogleUrl(text)) {
    src = text;
  }

  return src;
};

const Iframe = Node.create({
  name: "iframe",
  group: "block",
  atom: true,
  marks: "",

  addOptions() {
    return {
      allowFullscreen: true,
      HTMLAttributes: {},
    };
  },

  addAttributes() {
    return {
      src: {
        default: null,
        parseHTML: (element: HTMLAnchorElement | HTMLIFrameElement) => {
          if (element instanceof HTMLAnchorElement) {
            return getIframeUrlFromText(element.href);
          }
          if (element instanceof HTMLIFrameElement) {
            return element.src;
          }
          return null;
        },
      },
      url: {
        default: null,
      },
      allowFullScreen: {
        default: this.options.allowFullscreen,
        parseHTML: () => this.options.allowFullscreen,
      },
      height: {
        default: defaultHeight,
        parseHTML: () => this.options.height,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "iframe",
      },
      {
        tag: "a",
        priority: tiptapExtensionPriority.explorerLink,
        getAttrs: (node: HTMLElement | string) => {
          if (node && node instanceof HTMLAnchorElement) {
            const iframeSrc = getIframeUrlFromText(node.href);
            if (iframeSrc) return true && null;
          }
          return false;
        },
      },
    ];
  },

  addPasteRules() {
    return [
      new PasteRule({
        find: regexp.iframeHTML,
        handler: ({ state, range, match }) => {
          const parser = new DOMParser();
          const documentCopied = parser.parseFromString(match[0], "text/html");
          const iframe = documentCopied.querySelector("iframe");
          if (!iframe) {
            return;
          }

          // Add iframe in editor
          const { tr } = state;
          tr.replaceWith(
            range.from,
            range.to,
            this.type.create({
              src: iframe.src,
              allowFullScreen: iframe.allowFullscreen,
            })
          );
        },
      }),
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ["div", this.options.HTMLAttributes, ["iframe", HTMLAttributes]];
  },

  addNodeView() {
    return ReactNodeViewRenderer(IframeComponent);
  },

  addCommands() {
    return {
      setIframe:
        (options: any) =>
        ({ tr, dispatch }: { tr: any; dispatch: any }) => {
          const { selection } = tr;
          const node = this.type.create(options);

          if (dispatch) {
            tr.replaceRangeWith(selection.from, selection.to, node);
          }

          return true;
        },
    };
  },
});

export default Iframe;
