import { useQuery } from "@apollo/client";
import { useMemo } from "react";
import {
  GetRatingsQuery,
  GetRatingsQueryVariables,
} from "types/graphql-schema";

import Button, { buttonTheme } from "@components/button/button";
import AppLink from "@components/link/link";
import Loading from "@components/loading/loading";
import {
  Table,
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableContainer,
  TableHeadCell,
  TableHeadRow,
} from "@components/table/table";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { assertEdgesNonNull, assertNonNull } from "@helpers/helpers";

import getRatingsQuery from "../graphql/get-ratings-query";

const RatingsList = () => {
  const { data, loading } = useQuery<GetRatingsQuery, GetRatingsQueryVariables>(
    getRatingsQuery,
    {
      onError: onNotificationErrorHandler(),
    }
  );

  const ratings = useMemo(
    () => (data ? assertEdgesNonNull(data.ratings) : []),
    [data]
  );

  // RENDER
  return (
    <div className="w-full flex flex-col gap-6" aria-label="Ratings list">
      {!data && loading && (
        <div className="">
          <Loading>Loading ratings...</Loading>
        </div>
      )}

      {data && (
        <>
          <div className="flex w-full justify-between items-center">
            <div className="text-xl font-medium">
              All ratings ({ratings.length})
            </div>
            <Button
              theme={buttonTheme.default}
              to="/ratings/new"
              text="New rating"
            />
          </div>
          {ratings.length ? (
            <TableContainer>
              <Table>
                <TableHeadRow>
                  <TableHeadCell>Title</TableHeadCell>
                  <TableHeadCell>Answers</TableHeadCell>
                  <TableHeadCell className="w-24"></TableHeadCell>
                </TableHeadRow>
                <TableBody>
                  {ratings.map((rating) => {
                    const answerCount = assertNonNull(
                      rating.answers
                    ).totalCount;
                    return (
                      <TableBodyRow key={rating.id}>
                        <TableBodyCell>
                          <AppLink
                            to={`/ratings/${rating.id}`}
                            className="hover:underline text-blue-600"
                          >
                            {rating.title}
                          </AppLink>
                        </TableBodyCell>
                        <TableBodyCell>{answerCount}</TableBodyCell>
                        <TableBodyCell>
                          <AppLink
                            to={`/ratings/${rating.id}/edit`}
                            className="hover:underline text-blue-600"
                          >
                            Edit
                          </AppLink>
                        </TableBodyCell>
                      </TableBodyRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <div className="text-gray-500">No ratings...</div>
          )}
        </>
      )}
    </div>
  );
};

export default RatingsList;
