import { Popover } from "@headlessui/react";
import { PlusIcon } from "@heroicons/react/solid";
import { Ref, useState } from "react";

import { currentUserVar } from "@cache/cache";
import useUserComboboxQuery from "@components/user-combobox/use-user-combobox-query";
import UserCombobox from "@components/user-combobox/user-combobox";
import {
  UserComboboxOption,
  UserComboboxOptionType,
  UserComboboxUserOption,
} from "@components/user-combobox/user-combobox-list";
import { classNames } from "@helpers/css";

const RecipientDropdown = ({
  recipients,
  onChangeRecipient,
  className,
  children,
  showCurrentUser,
  excludeUserIds = [],
  placeholder,
}: {
  onChangeRecipient: (option: UserComboboxUserOption) => void;
  recipients: { id: number }[];
  excludeUserIds?: number[];
  className?: string;
  children?: React.FC<{
    setReferenceElement?: Ref<HTMLButtonElement>;
    onClickButton?: () => void;
  }>;
  showCurrentUser?: boolean;
  placeholder?: string;
}) => {
  const currentUser = currentUserVar();
  const recipientIds = recipients.map(({ id }) => id);
  const [canLoadData, setCanLoadData] = useState(false);
  const { loading, options, query, setQuery } = useUserComboboxQuery({
    queryOptions: {
      skip: !canLoadData,
    },
    excludeUserIds: [
      ...excludeUserIds,
      ...(showCurrentUser ? recipientIds : [...recipientIds, currentUser.id]),
    ],
  });

  const handleChangeOwner = (option: UserComboboxOption) => {
    if (option.type === UserComboboxOptionType.USER) {
      onChangeRecipient(option);
    }
  };

  const handlePreloadData = () => {
    setCanLoadData(true);
  };

  return (
    <UserCombobox
      aria-label="Recipient dropdown"
      loading={loading}
      width="full"
      query={query}
      value={null}
      options={options}
      onChangeValue={handleChangeOwner}
      onChangeQuery={setQuery}
      onClickButton={handlePreloadData}
      placeholder={placeholder}
    >
      {({ setReferenceElement, onClickButton }) =>
        children ? (
          children({ setReferenceElement, onClickButton })
        ) : (
          <div className={"flex justify-between bg-white rounded-md"}>
            <Popover.Button
              className={classNames(
                "flex items-center text-sm gap-1 border rounded-lg py-1 px-2",
                className
              )}
              onClick={onClickButton}
              ref={setReferenceElement}
            >
              <PlusIcon className="h-4 w-4" />
              {placeholder ? placeholder : "Pick a user"}
            </Popover.Button>
          </div>
        )
      }
    </UserCombobox>
  );
};

export default RecipientDropdown;
