import testPassedIcon from "@static/img/test-passed.png";

import { getAssetUrl } from "@helpers/helpers";

const MeetingEmptyPlaceholder = () => (
  <div
    className="mt-4 border-t flex flex-col py-8 text-gray-500 border-b border-gray-200"
    aria-label="Empty topic list"
  >
    <div className="flex justify-center mb-2">
      <img
        src={getAssetUrl(testPassedIcon)}
        alt="Meeting notes"
        style={{ width: "64px", height: "64px" }}
      />
    </div>
    <div className="text-gray-500 text-center text-lg">
      This meeting has no agenda
    </div>
  </div>
);

export default MeetingEmptyPlaceholder;
