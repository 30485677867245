import { gql } from "@apollo/client";

const ArtifactMentionFragment = gql`
  fragment ArtifactMentionFragment on ArtifactInterface {
    id
    title
    artifactType
    ... on DecisionArtifactNode {
      decision
      decisionState
      isDraft
    }
    ... on ActionItemArtifactNode {
      isComplete
      assignee {
        id
      }
    }
    ... on GoalArtifactNode {
      progress
      goalVisibility
      scope
      state
    }
  }
`;

export default ArtifactMentionFragment;
