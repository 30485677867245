import { useMutation } from "@apollo/client";
import { ChangeEvent, useEffect, useState } from "react";
import {
  SaveExplorerBookmarkMutationMutation,
  SaveExplorerBookmarkMutationMutationVariables,
} from "types/graphql-schema";

import { ExplorerFilterType } from "@apps/explorer/helpers";
import { currentOrganizationVar, successNotificationVar } from "@cache/cache";
import Button, { buttonTheme } from "@components/button/button";
import Input from "@components/input/input";
import Label from "@components/label/label";
import Modal from "@components/modal/modal";
import ModalTitle from "@components/modal/modal-title";
import { useNotificationError } from "@components/use-error/use-error";

import saveExplorerBookmarkMutation from "../graphql/save-explorer-bookmark-mutation";

const ExplorerBookmarkEditDialog = ({
  bookmark,
  onClose,
}: {
  bookmark: {
    id?: number;
    title: string;
    private: boolean;
    filters: ExplorerFilterType;
  };
  onClose: () => void;
}) => {
  const currentOrganization = currentOrganizationVar();
  const { onError } = useNotificationError();
  const [form, setForm] = useState({
    title: "",
    private: true,
  });
  const [saveExplorerBookmark, { loading }] = useMutation<
    SaveExplorerBookmarkMutationMutation,
    SaveExplorerBookmarkMutationMutationVariables
  >(saveExplorerBookmarkMutation);

  const handleSaveBookmark = () => {
    saveExplorerBookmark({
      variables: {
        organizationId: currentOrganization.id,
        filters: JSON.stringify(bookmark.filters),
        explorerBookmarkId: bookmark?.id,
        ...form,
      },
      onError,
      onCompleted: () => {
        successNotificationVar({
          title: `Bookmark saved.`,
          timeout: 2000,
        });
        onClose();
      },
    });
  };

  const handleCloseDialog = () => {
    onClose();
  };

  const handleChangeTitle = (e: ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, title: e.target.value });
  };

  const handleChangePrivate = (e: ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, private: e.target.value === "true" });
  };

  useEffect(() => {
    setForm({
      title: bookmark.title,
      private: bookmark.private,
    });
  }, [bookmark]);

  return (
    <Modal onClose={handleCloseDialog}>
      <div className="p-6 flex flex-col gap-6">
        <ModalTitle onClose={handleCloseDialog}>Save bookmark</ModalTitle>
        <div>
          <Input
            label="Title"
            value={form.title}
            placeholder="Bookmark title..."
            onChange={handleChangeTitle}
          />
        </div>
        <div className="">
          <Label label="Visible to" />
          <div className="flex items-center gap-4 text-sm">
            <label className="flex items-center gap-1 cursor-pointer">
              <input
                type="radio"
                name="bookmark-private"
                checked={form.private === true}
                value="true"
                autoFocus
                onChange={handleChangePrivate}
              />
              You only
            </label>
            <label className="flex items-center gap-1 cursor-pointer">
              <input
                type="radio"
                name="bookmark-private"
                value="false"
                checked={form.private === false}
                onChange={handleChangePrivate}
              />
              Everyone in {currentOrganization.name}
            </label>
          </div>
        </div>
        <div className="flex items-center gap-4">
          <Button
            theme={buttonTheme.primary}
            disabled={form.title.trim().length === 0 || loading}
            type="submit"
            text="Save"
            onClick={handleSaveBookmark}
          />
          <Button type="button" text="Cancel" onClick={handleCloseDialog} />
        </div>
      </div>
    </Modal>
  );
};

export default ExplorerBookmarkEditDialog;
