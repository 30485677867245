import { useMutation, useQuery } from "@apollo/client";
import { Popover } from "@headlessui/react";
import {
  CalendarIcon,
  LockClosedIcon,
  LockOpenIcon,
} from "@heroicons/react/outline";
import { compact } from "lodash";
import moment from "moment";
import { useCallback, useState } from "react";
import {
  Route,
  Switch,
  matchPath,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import {
  GetKpiQueryQuery,
  GetKpiQueryQueryVariables,
  KpiVisibility,
} from "types/graphql-schema";

import useLabel from "@apps/use-label/use-label";
import Button from "@components/button/button";
import Dropdown from "@components/dropdown/dropdown";
import AppLink, { useLink } from "@components/link/link";
import Loading from "@components/loading/loading";
import Tabs from "@components/tabs/tabs";
import {
  onNotificationErrorHandler,
  useNotificationError,
} from "@components/use-error/use-error";
import { isEmptyValue } from "@components/wysiwyg/helpers";
import TextareaWysiwyg from "@components/wysiwyg/textarea-wysiwyg";
import {
  assertEdgesNonNull,
  assertNonNull,
  parseStringToJSON,
} from "@helpers/helpers";
import usePkParams from "@helpers/hooks/use-pk-params";

import deleteKpiMutation from "../graphql/delete-kpi-mutation";
import getKpiQuery from "../graphql/get-kpi-query";
import KPIAddMeasurementDialog from "./add-measurement-dialog";
import KPIAddMeasurementForm from "./add-measurement-form";
import KPIGoals from "./goals";
import KPIMeasurementsTable from "./measurements-table";
import KPISummary from "./summary";
import KPIUpdateUrlContentPopover from "./update-url-content-popover";

const KPIView = () => {
  const { path } = useRouteMatch();
  const kpiId = usePkParams("kpiId");
  const { pathname } = useLocation();
  const { onError } = useNotificationError();
  const link = useLink();
  const label = useLabel();
  const [viewAddMeasurementDialog, setViewAddMeasurementDialog] =
    useState(false);
  const [deleteKpi, { loading: loadingDelete }] =
    useMutation(deleteKpiMutation);
  const { data, loading } = useQuery<
    GetKpiQueryQuery,
    GetKpiQueryQueryVariables
  >(getKpiQuery, {
    variables: {
      kpiId,
      hasKpi: true,
      hasOrganizationId: false,
    },
    onError,
  });

  const kpi = data?.kpi;

  const tabOptions = [
    {
      label: "Summary",
      value: "summary",
      paths: ["", "/summary"],
    },
    {
      label: "Measurements",
      value: "measurements",
      paths: ["/measurements"],
    },
    {
      label: `Connected ${label("goal", {
        capitalize: true,
        pluralize: true,
      })}`,
      value: "goals",
      paths: ["/goals"],
    },
  ];

  const handleClickDelete = () => {
    if (window.confirm("Are you sure you want to delete this KPI?")) {
      deleteKpi({
        variables: {
          kpiId,
        },
        update(cache) {
          const cacheId = cache.identify(assertNonNull(kpi));
          cache.evict({ id: cacheId });
          cache.gc();
        },
        onCompleted: () => {
          link.redirect("/kpis");
        },
        onError: onNotificationErrorHandler(),
      });
    }
  };

  const handleClickOption = useCallback(
    (value: string) => {
      link.redirect(`/kpis/${kpiId}/${value}`);
    },
    [kpiId, link]
  );

  if ((loading && !data) || !kpi) {
    return (
      <div className="bg-white flex justify-center rounded-lg shadow p-6">
        <Loading>Loading KPI...</Loading>
      </div>
    );
  }

  const displayedTabOption =
    tabOptions.find((option) => {
      const matchingPath = option.paths.find((optionPath) => {
        return matchPath(pathname, {
          path: `${path}${optionPath}`,
          exact: true,
        });
      });
      return matchingPath !== undefined;
    }) || tabOptions[0];

  return (
    <div className="bg-white flex rounded-lg shadow p-6">
      {viewAddMeasurementDialog && (
        <KPIAddMeasurementDialog
          onClose={() => setViewAddMeasurementDialog(false)}
          kpiId={kpi.id}
          onCreated={() => setViewAddMeasurementDialog(false)}
          open={viewAddMeasurementDialog}
        />
      )}
      <div className="w-full flex flex-col gap-8" aria-label="KPI view">
        <div>
          <div className="flex justify-between items-center">
            <AppLink
              to={`/kpis/${kpiId}`}
              className="text-xl font-medium hover:underline"
            >
              {kpi.title}
            </AppLink>
            <div className="shrink-0 flex">
              <div className="flex gap-3 items-center">
                {kpi.canUpdate.permission && (
                  <Popover className="relative">
                    {({ open }) => (
                      <>
                        <Popover.Button
                          as={Button}
                          small
                          disabled={loadingDelete || !kpi.canUpdate.permission}
                        >
                          Webhook
                        </Popover.Button>

                        <Popover.Panel className="absolute z-10 right-0 bg-white rounded p-4 border shadow">
                          <KPIUpdateUrlContentPopover
                            kpiId={kpiId}
                            open={open}
                          />
                        </Popover.Panel>
                      </>
                    )}
                  </Popover>
                )}
                {kpi.canUpdate.permission && (
                  <Button
                    small
                    text="Add measurement"
                    disabled={loadingDelete}
                    onClick={() => setViewAddMeasurementDialog(true)}
                  />
                )}
                <Dropdown
                  aria-label="KPI dropdown"
                  options={compact([
                    kpi.canUpdate.permission && {
                      label: "Edit",
                      onClick: () => link.redirect(`/kpis/${kpi.id}/edit`),
                    },
                    kpi.canDelete?.permission && {
                      label: "Delete",
                      onClick: handleClickDelete,
                    },
                    kpi.canUpdate.permission && {
                      label: "Add measurement",
                      onClick: () => setViewAddMeasurementDialog(true),
                    },
                  ])}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-2 mt-2">
            <div className="text-gray-500 text-xs flex gap-1 items-center">
              {kpi.visibility === KpiVisibility.Personal ? (
                <LockClosedIcon className="inline-block h-3 w-3 text-gray-500" />
              ) : (
                <LockOpenIcon className="inline-block h-3 w-3 text-gray-500" />
              )}
              {kpi.visibility === KpiVisibility.Org && (
                <span>
                  Visible to "{kpi.organization?.name}" {label("organization")}{" "}
                  members.
                </span>
              )}
              {kpi.visibility === KpiVisibility.Teams && (
                <span>
                  Visible to {label("team", { pluralize: true })}:{" "}
                  {assertEdgesNonNull(kpi.teams)
                    .map(({ title }) => title)
                    .join(", ")}
                  .
                </span>
              )}
              {kpi.visibility === KpiVisibility.Personal && (
                <span>Only visible to "{kpi.creator?.name}".</span>
              )}
            </div>
            <div className="text-gray-500 text-xs flex gap-1 items-center">
              <CalendarIcon className="inline-block h-3 w-3 text-gray-500" />
              {moment(kpi.created).format("LLLL")} by {kpi.creator?.name}
            </div>
            {!isEmptyValue(parseStringToJSON(kpi.description)) && (
              <div className="text-gray-600">
                <TextareaWysiwyg value={parseStringToJSON(kpi.description)} />
              </div>
            )}
          </div>
        </div>

        <div>
          <div className="mb-4">
            <Tabs
              options={tabOptions}
              selectedValue={displayedTabOption.value}
              onClickOptionValue={handleClickOption}
            />
          </div>

          <Switch>
            <Route exact path={[path, `${path}/summary`]}>
              <KPISummary kpi={kpi} />
            </Route>
            <Route exact path={`${path}/measurements`}>
              <KPIMeasurementsTable kpi={kpi} />
            </Route>
            <Route exact path={`${path}/add-measurement`}>
              <KPIAddMeasurementForm
                kpi={kpi}
                onCreated={() => link.redirect(`/kpis/${kpiId}/measurements`)}
              />
            </Route>
            <Route exact path={`${path}/goals`}>
              <KPIGoals kpi={kpi} />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default KPIView;
