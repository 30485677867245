import { GetMeetingGroupSettingsQueryQuery } from "types/graphql-schema";

import Heading from "@components/heading/heading";
import AppLink from "@components/link/link";
import SettingsLayout from "@components/settings-layout/settings-layout";
import {
  assertEdgesNonNull,
  assertEdgesNonNullWithStringId,
} from "@helpers/helpers";

import SettingsSlackNotification from "./settings-slack-notification";
import TemplateForm from "./template-form";

const MeetingSettingsContent = ({
  meetingGroup,
}: {
  meetingGroup: NonNullable<GetMeetingGroupSettingsQueryQuery["meetingGroup"]>;
}) => {
  // Computed data
  const slackNotifications = assertEdgesNonNull(
    meetingGroup.slackNotifications
  );
  const meetingOrganization = meetingGroup.organization;
  const channels = (
    meetingGroup.organization?.publicSlackChannels
      ? assertEdgesNonNullWithStringId(
          meetingGroup.organization?.publicSlackChannels
        )
      : []
  ).map((channel: any) => ({
    label: `#${channel.name}`,
    value: channel.id,
    ...channel,
  }));
  const existingChannelIds = slackNotifications.map(
    ({ channel }: { channel: any }) => channel
  );

  return (
    <div className="mb-6 p-6">
      <div className="p-6 rounded-xl bg-white shadow">
        <Heading small title="Settings" />

        <SettingsLayout.Container>
          {meetingOrganization && (
            <SettingsLayout.Row>
              <SettingsLayout.Left label="Post meeting reminders and summaries to Slack">
                Slack notifications
              </SettingsLayout.Left>
              <SettingsLayout.Right className="text-sm">
                {meetingOrganization?.hasSlackConnection ? (
                  <div>
                    <div className="flex flex-col divide-y divide-gray-100">
                      {slackNotifications.map((slackNotification: any) => (
                        <SettingsSlackNotification
                          key={slackNotification.id}
                          channels={channels}
                          slackNotification={slackNotification}
                          meetingGroup={meetingGroup}
                          existingChannelIds={existingChannelIds}
                        />
                      ))}
                      <SettingsSlackNotification
                        channels={channels}
                        slackNotification={null}
                        meetingGroup={meetingGroup}
                        existingChannelIds={existingChannelIds}
                      />
                    </div>
                  </div>
                ) : (
                  <div>
                    <AppLink
                      to={`/settings/organization/${meetingOrganization.id}/integrations`}
                      className="text-blue-link hover:underline"
                    >
                      Set up Slack on your organization
                    </AppLink>
                  </div>
                )}
              </SettingsLayout.Right>
            </SettingsLayout.Row>
          )}
          <SettingsLayout.Row>
            <SettingsLayout.Left label="Automate meeting agenda for your future meetings">
              Templates
            </SettingsLayout.Left>
            <SettingsLayout.Right>
              <TemplateForm meetingGroup={meetingGroup} />
            </SettingsLayout.Right>
          </SettingsLayout.Row>
        </SettingsLayout.Container>
      </div>
    </div>
  );
};

export default MeetingSettingsContent;
