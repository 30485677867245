import { gql } from "@apollo/client";

import CommonFeedbackArtifactFragment from "@graphql/common-feedback-artifact-fragment";

export default gql`
  ${CommonFeedbackArtifactFragment}
  query getMeetingFeedbackNewPageQuery(
    $feedbackRecipients: [Int]
    $feedbackSenders: [Int]
    $meetingGroupId: Int
    $fetchData: Boolean!
    $after: String
    $first: Int!
  ) {
    artifacts(
      artifactType: feedback
      first: $first
      orderBy: "-created"
      meetingGroupId: $meetingGroupId
      feedbackRecipients: $feedbackRecipients
      feedbackSenders: $feedbackSenders
      after: $after
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      totalCount
      edges @include(if: $fetchData) {
        node {
          canDelete {
            permission
          }
          created
          id
          artifactType
          canUpdate {
            permission
          }
          ...CommonFeedbackArtifactFragment
        }
      }
    }
  }
`;
