import { gql } from "@apollo/client";

export default gql`
  mutation unsubmitAssessment($assessmentId: Int!) {
    reopenAssessment(assessmentId: $assessmentId) {
      assessment {
        id
        state
      }
    }
  }
`;
