import { gql } from "@apollo/client";

import KeyResultFragment from "@graphql/key-result-fragment";

export default gql`
  ${KeyResultFragment}
  mutation deleteGoalKeyResults($keyResultId: Int!) {
    deleteKeyResult(keyResultId: $keyResultId) {
      keyResult {
        ...KeyResultFragment
      }
      goal {
        id
        ... on GoalArtifactNode {
          keyResults {
            edges {
              node {
                id
              }
            }
          }
        }
      }
    }
  }
`;
