import { gql } from "@apollo/client";

export default gql`
  mutation createOrUpdateSlackNotificationForChannelMutation(
    $channel: String
    $organizationId: Int
    $notificationId: Int
    $recognitions: Boolean
    $goalCheckins: Boolean
  ) {
    createOrUpdateSlackNotificationForChannel(
      channel: $channel
      organizationId: $organizationId
      notificationId: $notificationId
      recognitions: $recognitions
      goalCheckins: $goalCheckins
    ) {
      organization {
        id
        slackNotifications {
          edges {
            node {
              id
              channel
              recognitions
              goalCheckins
            }
          }
        }
      }
    }
  }
`;
