import { gql } from "@apollo/client";

import KPIFragment from "./kpi-fragment";
import KPISummarizedMeasurementFragment from "./kpi-summarized-measurement-fragment";

export const KPIDashboardKPIFragment = gql`
  ${KPIFragment}
  ${KPISummarizedMeasurementFragment}
  fragment KPIDashboardKPIFragment on KPINode {
    ...KPIFragment
    groups {
      edges {
        node {
          id
          title
        }
      }
    }
    summarizedMeasurements(summaryPeriod: $kpiSummaryPeriod, first: $first) {
      edges {
        node {
          ...KPISummarizedMeasurementFragment
        }
      }
    }
  }
`;

export default gql`
  ${KPIDashboardKPIFragment}
  query getKpisQuery($first: Int!, $kpiSummaryPeriod: KPISummaryPeriod!) {
    kpis {
      edges {
        node {
          ...KPIDashboardKPIFragment
        }
      }
    }
    kpiGroups {
      edges {
        node {
          id
          title
        }
      }
    }
  }
`;
