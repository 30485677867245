import {
  DetailedHTMLProps,
  FormHTMLAttributes,
  KeyboardEventHandler,
  PropsWithChildren,
} from "react";

import { isCommandEnterEvent } from "@helpers/helpers";

const Form = ({
  children,
  onSubmit,
  onCMDEnter,
  ...props
}: PropsWithChildren<
  DetailedHTMLProps<FormHTMLAttributes<HTMLFormElement>, HTMLFormElement> & {
    onCMDEnter?: KeyboardEventHandler<HTMLFormElement>;
  }
>) => {
  return (
    <form
      data-lpignore="true"
      autoComplete="off"
      onSubmit={onSubmit}
      onKeyDown={(e) => {
        if (isCommandEnterEvent(e) && onCMDEnter) {
          onCMDEnter(e);
        }
      }}
      {...props}
    >
      {children}
    </form>
  );
};

export default Form;
