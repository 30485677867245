import moment from "moment";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import AppLink from "@components/link/link";
import useDocumentTitle from "@components/use-document-title/use-document-title";

import WorkHistorySidebar from "./components/work-history-sidebar";

const WorkHistory = () => {
  useDocumentTitle("Work History");
  const { path } = useRouteMatch();

  return (
    <div aria-label="Work History" className="flex flex-col flex-1 fs-unmask">
      <div className="flex flex-wrap items-center justify-between h-14 px-4 sm:px-6 border-b bg-gray-50">
        <AppLink
          className="mb-2 sm:mb-0 text-2xl font-semibold mr-8 flex items-center"
          to="/programs"
        >
          Work History
        </AppLink>
      </div>
      <div className="p-6">
        <div className="bg-gray-50 flex rounded-lg shadow-md ">
          <Switch>
            <Route exact path={path}>
              <WorkHistorySidebar
                startDate={moment().subtract(2, "days").format("YYYY-MM-DD")}
                endDate={moment().format("YYYY-MM-DD")}
              />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default WorkHistory;
