import { compact, orderBy, uniq } from "lodash";
import {
  Cell,
  Pie,
  PieChart,
  Tooltip as RechartTooltip,
  ResponsiveContainer,
} from "recharts";
import { GetMeetingTranscriptQueryQuery } from "types/graphql-schema";

import Tooltip from "@components/tooltip/tooltip";

const TranscriptionParticipation = ({
  meeting,
}: {
  meeting: NonNullable<GetMeetingTranscriptQueryQuery["meeting"]>;
}) => {
  const orderedTranscriptData = compact(
    meeting.transcript?.orderedTranscriptData || []
  );
  const userNames = uniq(
    orderedTranscriptData.map((transcriptData) => transcriptData?.speaker)
  );

  const uniqUsers = userNames.map((user) => {
    const text = orderedTranscriptData
      .filter((transcriptData) => transcriptData.speaker === user)
      .map((transcriptData) => transcriptData.text)
      .join(" ");
    return {
      name: user,
      text,
      wordCount: text.trim().split(/\s+/).length,
    };
  });
  const totalWordCount = uniqUsers.reduce(
    (memo, user) => memo + user.wordCount,
    0
  );
  const uniqUsersOrderByWordCountDesc = orderBy(
    uniqUsers,
    "wordCount"
  ).reverse();

  const chartData = uniqUsersOrderByWordCountDesc.map((user) => ({
    name: user.name || "unknown",
    value: Math.round((user.wordCount / totalWordCount) * 100),
  }));

  const CustomTooltip = ({
    active,
    payload,
  }: {
    active?: boolean;
    payload?: any;
  }) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-white/90 border-2 border-solid border-gray-300 p-2">
          <p className="label">{`${payload[0].name} : ${payload[0].value}%`}</p>
        </div>
      );
    }

    return null;
  };

  const COLOURS = [
    "#1f77b4",
    "#ff7f0e",
    "#2ca02c",
    "#d62728",
    "#9467bd",
    "#8c564b",
    "#e377c2",
    "#7f7f7f",
    "#bcbd22",
    "#17becf",
    "#aec7e8",
    "#ffbb78",
    "#98df8a",
    "#ff9896",
    "#c5b0d5",
    "#c49c94",
    "#f7b6d2",
    "#c7c7c7",
    "#dbdb8d",
    "#9edae5",
    "#f0f0f0",
    "#ff6600",
    "#33cc33",
    "#ff3300",
    "#6600ff",
    "#cc00cc",
    "#00ccff",
    "#ffcc00",
    "#99ff99",
    "#ff99cc",
    "#99ccff",
    "#ccff99",
    "#ffccff",
    "#ff9999",
    "#cc6600",
    "#ff6666",
    "#33ffcc",
    "#ff6600",
    "#cc33ff",
    "#6699ff",
    "#ff3300",
    "#33ccff",
    "#ff9966",
    "#ff0066",
    "#00ff99",
    "#ffcc99",
    "#0099ff",
    "#ff3333",
    "#33ff99",
  ];

  return meeting.transcript ? (
    <div className="flex gap-2 items-center">
      <div className="w-[180px]">
        <ResponsiveContainer width="100%" height={180}>
          <PieChart>
            <Pie
              data={chartData}
              cx="50%"
              cy="50%"
              outerRadius={70}
              dataKey="value"
              nameKey="name"
              isAnimationActive={false}
            >
              <>
                {chartData.map((entry, index) => (
                  <Cell
                    key={`cell-${entry.name}`}
                    fill={COLOURS[index % COLOURS.length]}
                  />
                ))}
              </>
            </Pie>
            <RechartTooltip content={<CustomTooltip />} />
          </PieChart>
        </ResponsiveContainer>
      </div>
      <div>
        <ul className="flex-1 list-disc ml-5 text-sm">
          {chartData.map(({ name, value }, i) => (
            <li key={name}>
              <span
                className="mr-1"
                style={{ color: COLOURS[i % chartData.length] }}
              >
                {name}
              </span>
              <Tooltip text={`${value} % participation`}>
                <span className="text-gray-500 tracking-tight">({value}%)</span>
              </Tooltip>
            </li>
          ))}
        </ul>
      </div>
    </div>
  ) : (
    <div className="text-sm text-gray-500 px-1 py-2">
      No transcript has been recorded.
    </div>
  );
};

export default TranscriptionParticipation;
