import { useMutation, useQuery } from "@apollo/client";
import { TrashIcon } from "@heroicons/react/outline";
import { useCallback } from "react";
import {
  DeleteOrgWideCompetencyMutation,
  DeleteOrgWideCompetencyMutationVariables,
  GetOrganizationCompetenciesQuery,
  GetOrganizationCompetenciesQueryVariables,
} from "types/graphql-schema";

import { competencyInfo } from "@apps/org-settings/constants";
import deleteOrgWideCompetencyMutation from "@apps/org-settings/graphql/delete-org-wide-competency-mutation";
import useLabel from "@apps/use-label/use-label";
import { successNotificationVar } from "@cache/cache";
import Button, { buttonTheme } from "@components/button/button";
import Layout from "@components/layout/layout";
import AppLink, { useLink } from "@components/link/link";
import Loading from "@components/loading/loading";
import { useNotificationError } from "@components/use-error/use-error";
import { classNames } from "@helpers/css";
import { assertEdgesNonNull } from "@helpers/helpers";
import useConfirm from "@helpers/hooks/use-confirm";

import getOrganizationCompetencies from "../../graphql/get-organization-competencies-query";

const editCompetencyUrl = (organizationId: number, competencyId: number) =>
  `/settings/organization/${organizationId}/competencies/edit/${competencyId}`;

const CompetenciesList = ({ organizationId }: { organizationId: number }) => {
  const label = useLabel();
  const link = useLink();
  const { onError } = useNotificationError();

  const {
    confirm: confirmDeleteCompetency,
    ConfirmationDialog: ConfirmationDialogDeleteCompetency,
  } = useConfirm(
    `Are you sure?`,
    `Are you sure you want to remove this ${label("competency")}?`
  );

  const { data, loading } = useQuery<
    GetOrganizationCompetenciesQuery,
    GetOrganizationCompetenciesQueryVariables
  >(getOrganizationCompetencies, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    variables: {
      organizationId,
    },
    onError,
  });

  const [deleteCompetency, { loading: isDeleting }] = useMutation<
    DeleteOrgWideCompetencyMutation,
    DeleteOrgWideCompetencyMutationVariables
  >(deleteOrgWideCompetencyMutation, {
    onCompleted: () => {
      successNotificationVar({
        title: `${label("competency", { capitalize: true })} deleted`,
      });
    },
    refetchQueries: [getOrganizationCompetencies],
  });

  const handleDeleteCompetency = useCallback(
    async (competencyId: number) => {
      const confirmed = await confirmDeleteCompetency();
      if (confirmed) {
        deleteCompetency({
          variables: {
            competencyId,
          },
          onError,
        });
      }
    },
    [confirmDeleteCompetency, deleteCompetency, onError]
  );

  const competencies = data?.competencies
    ? assertEdgesNonNull(data.competencies)
    : [];

  return (
    <div>
      <ConfirmationDialogDeleteCompetency />

      <div className="flex justify-between items-center mb-6">
        <div className="font-bold">
          {label("competency", { pluralize: true, capitalize: true })}
        </div>
        <div>
          <Button
            text={`Add ${label("competency", { capitalize: true })}`}
            to={`/settings/organization/${organizationId}/competencies/new`}
          />
        </div>
      </div>

      {loading && (
        <div className="flex justify-center mt-8">
          <Loading />
        </div>
      )}
      {!loading && competencies.length === 0 && (
        <div className="flex justify-center mt-8">
          <div className="text-gray-500">
            No {label("competency", { pluralize: true, capitalize: true })} yet
          </div>
        </div>
      )}

      <div className="my-6 flex flex-col">
        {competencies.map((competency) => (
          <div
            key={competency.id}
            className="flex items-center border-t p-2 justify-between gap-4 hover:bg-gray-100"
            role="button"
            onClick={() =>
              link.redirect(editCompetencyUrl(organizationId, competency.id))
            }
          >
            <AppLink
              to={editCompetencyUrl(organizationId, competency.id)}
              className="text-sm font-medium max-w-[500px] flex-1 hover:underline"
            >
              {competency.title}
            </AppLink>
            <div className="w-[510px] text-sm text-gray-500">
              {competencyInfo(competency)}
            </div>
            <div className="flex items-center gap-2">
              <Button
                theme={buttonTheme.text}
                icon
                className={classNames(
                  "text-inherit",
                  Layout.headerIconButtonClassName
                )}
                onClick={(evt) => {
                  evt.stopPropagation();
                  handleDeleteCompetency(competency.id);
                }}
                disabled={isDeleting}
              >
                <TrashIcon className="h-5 w-5" />
              </Button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CompetenciesList;
