import cache from "@cache/cache";

export function removeMeeting(meeting: { id: number }) {
  const meetingCacheId = cache.identify(meeting);
  cache.evict({ id: meetingCacheId });
  cache.gc();
}

export function removeMeetingGroup(meetingGroup: { id: number }) {
  const meetingGroupCacheId = cache.identify(meetingGroup);
  cache.evict({ id: meetingGroupCacheId });
  cache.gc();
}
