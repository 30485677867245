import { useMutation } from "@apollo/client";
import { EyeOffIcon } from "@heroicons/react/outline";
import { ErrorBoundary } from "@sentry/react";
import { Editor, JSONContent } from "@tiptap/core";
import { delay } from "lodash";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  MeetingParticipantNode,
  MeetingViewMeetingNodeNewPageFragmentFragment,
  TopicNodeNewPageFragmentFragment,
} from "types/graphql-schema";

import cache, { currentUserVar } from "@cache/cache";
import Avatar from "@components/avatar/avatar";
import Error from "@components/error/error";
import Loading from "@components/loading/loading";
import Tooltip from "@components/tooltip/tooltip";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { isEmptyValue } from "@components/wysiwyg/helpers";
import WYSIWYG from "@components/wysiwyg/wysiwyg";
import { tempCacheIdPrefix } from "@helpers/constants";
import { classNames } from "@helpers/css";
import { assertEdgesNonNull, parseStringToJSON } from "@helpers/helpers";

import { MeetingWebsocketProviderContext } from "../context";
import toggleTopicIndividualNoteVisibilityMutation from "../graphql/toggle-topic-individual-note-visibility-mutation";

const IndividualNotes = ({
  topic,
  meeting,
  participant,
}: {
  topic: TopicNodeNewPageFragmentFragment;
  meeting: MeetingViewMeetingNodeNewPageFragmentFragment;
  participant: Pick<MeetingParticipantNode, "participantEmail" | "id"> & {
    user?: { id: number; firstName: string; name: string } | null;
  };
}) => {
  const [isReplacingNotes, setIsReplacingNotes] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);
  const currentNotesRef = useRef<JSONContent | null>(null);
  const currentUser = currentUserVar();
  const isTemporary = String(topic.id).includes(tempCacheIdPrefix);
  const isFormalOneonone = !!meeting.meetingGroup?.isFormalOneonone;
  const individualNotes = assertEdgesNonNull(topic.individualNotes) || [];

  const participantIsCurrentUser = participant.user?.id === currentUser.id;
  const participantIndividualNote = individualNotes.find(
    ({ creator }) => creator.id === participant?.user?.id
  );
  const isHiddenNote = participantIndividualNote
    ? participantIndividualNote.hidden
    : true;
  const currentUserNoteHidden = !participantIsCurrentUser
    ? false
    : isHiddenNote;

  const note = participantIndividualNote?.notes || "";
  const canEdit = participantIsCurrentUser && topic.canUpdate.permission;

  let overlayValue = null;
  if (isFormalOneonone && isEmptyValue(note) && topic.linkedTemplateTopic?.id) {
    const defaultNotes =
      meeting.meetingGroup?.facilitator?.id === participant.user?.id
        ? topic.linkedTemplateTopic.defaultFacilitatorNotes
        : topic.linkedTemplateTopic.defaultSubjectNotes;
    if (defaultNotes) overlayValue = parseStringToJSON(defaultNotes);
  }

  const [toggleIndividualNoteVisibility, { loading: loadingMutation }] =
    useMutation(toggleTopicIndividualNoteVisibilityMutation);
  const loading = loadingMutation || isReplacingNotes;

  const handleCurrentUserToggleIndividualNotes = () => {
    // tracks current note content
    if (ref.current && participantIsCurrentUser) {
      const el = ref.current?.querySelector(
        ".ProseMirror.js-topic-discussion-notes-input"
      ) as Element & { editor: Editor };
      if (el.editor) {
        currentNotesRef.current = el.editor?.getJSON();
      }
    }
    const hidden = !currentUserNoteHidden;
    toggleIndividualNoteVisibility({
      variables: {
        topicIds: [topic.id],
        hidden,
      },
      onError: onNotificationErrorHandler(),
      onCompleted: () => {
        // after toggling private flag
        // we replace content of private/public notes to keep them in sync.
        handleReplaceCurrentNoteWithNewNoteWhenSynced();
      },
    });
  };

  const handleUpdateContent = ({ editor }: { editor: Editor }) => {
    if (canEdit && participantIndividualNote) {
      cache.modify({
        id: cache.identify(participantIndividualNote),
        fields: {
          notes() {
            return editor.getJSON();
          },
        },
        // without broadcast false, it would update the active queries right away and
        // re-render everytime the user makes a change.
        broadcast: false,
      });
    }
  };

  const suffix = currentUserNoteHidden ? "-private" : "";
  const userId = participant.user?.id;
  const websocketId = `meeting-${meeting?.id}-participant-${userId}${suffix}`;
  const websocketDocumentId = `topic-${topic.id}-participant-${userId}${suffix}`;
  const context = useContext(MeetingWebsocketProviderContext);
  const { ydoc, providerWebsocket } = context[`${websocketId}`] || {
    ydoc: undefined,
    providerWebsocket: null,
  };

  const handleReplaceCurrentNoteWithNewNote = useCallback(() => {
    const el = ref.current?.querySelector(
      ".ProseMirror.js-topic-discussion-notes-input"
    ) as Element & { editor: Editor };
    if (el.editor) {
      if (
        JSON.stringify(el.editor.getJSON()) !==
        JSON.stringify(currentNotesRef.current)
      ) {
        el.editor
          .chain()
          .clearContent()
          .setContent(currentNotesRef.current)
          .run();
        currentNotesRef.current = null;
      }
    }
    setIsReplacingNotes(false);
  }, []);

  const handleReplaceCurrentNoteWithNewNoteWhenSynced = () => {
    if (!currentNotesRef.current) return;
    if (providerWebsocket) {
      setIsReplacingNotes(true);
      if (!providerWebsocket.isSynced) {
        // clean up event handler before
        providerWebsocket.off("synced", handleReplaceCurrentNoteWithNewNote);
        providerWebsocket.on("synced", handleReplaceCurrentNoteWithNewNote);
      } else {
        delay(() => {
          handleReplaceCurrentNoteWithNewNote();
        }, 1000);
      }
    }
  };

  // clean up event handlers
  useEffect(() => {
    return function cleanup() {
      providerWebsocket?.off("synced", handleReplaceCurrentNoteWithNewNote);
    };
  }, [providerWebsocket, handleReplaceCurrentNoteWithNewNote]);

  const uploadVariable = useMemo(() => ({ topicId: topic.id }), [topic.id]);
  const mentionsConfig = useMemo(
    () => ({
      meetingGroupId: meeting?.meetingGroup?.id,
      meetingId: meeting?.id,
    }),
    [meeting?.id, meeting?.meetingGroup?.id]
  );
  const extraContext = useMemo(
    () => ({
      topicId: topic.id,
      relatedTopicId: !currentUserNoteHidden ? topic.id : undefined,
      meetingId: meeting.id,
      meetingGroupId: meeting.meetingGroup?.id,
      organizationId: meeting.organization?.id,
      meetingDate: meeting.startDatetime ? meeting.startDatetime : undefined,
    }),
    [
      currentUserNoteHidden,
      meeting.id,
      meeting.meetingGroup?.id,
      meeting.organization?.id,
      meeting.startDatetime,
      topic.id,
    ]
  );

  return (
    <div
      key={participant.id}
      className={classNames(
        "rounded-lg border",
        (currentUser.id !== participant.user?.id ||
          !topic.canUpdate.permission) &&
          "bg-gray-50"
      )}
      ref={ref}
    >
      <div className="ml-4 mr-4 text-xs text-gray-500 mt-3 flex items-center justify-between gap-4">
        <div className="flex-1 flex items-center gap-1 tracking-tight">
          <Avatar user={participant.user} size={4} />
          <span>{participant.user?.name}</span>
        </div>
        {currentUser.id === participant.user?.id && topic.canUpdate.permission && (
          <div className="flex items-start gap-1">
            <Tooltip text="This makes your notes only visible to you. The other participants will not see this note unless you share your screen.">
              <label className="rounded-b flex items-center gap-1 text-2xs tracking-tight">
                <input
                  disabled={loading}
                  type="checkbox"
                  checked={currentUserNoteHidden}
                  onChange={handleCurrentUserToggleIndividualNotes}
                />
                <div>Make private</div>
              </label>
            </Tooltip>
          </div>
        )}
      </div>
      <div className="" aria-label="Meeting topic individual notes">
        <div>
          {isTemporary || ydoc === undefined || providerWebsocket === null ? (
            <div className="pb-4">
              <Loading size="5" />
            </div>
          ) : isHiddenNote && !participantIsCurrentUser ? (
            <div className="italic text-xs text-gray-500 flex items-center gap-1 p-4">
              <EyeOffIcon className="h-4 w-4 text-gray-400" />
              Notes are private.
            </div>
          ) : (
            <ErrorBoundary
              fallback={
                <Error
                  title="An unexpected error occurred."
                  description={
                    "The editor could not be rendered. We have been notified. Please refresh the page."
                  }
                />
              }
            >
              <div className={classNames("relative")}>
                {loading && (
                  <div className="z-1 border bg-gray-50 rounded-lg absolute inset-3 flex items-center justify-center text-gray-500 text-sm gap-2">
                    <Loading size="4" /> Updating
                  </div>
                )}
                <div id={websocketDocumentId} key={websocketDocumentId}>
                  <WYSIWYG
                    key={websocketDocumentId}
                    enableComment={false}
                    value={parseStringToJSON(note)}
                    className={classNames(
                      "js-individual-notes",
                      "text-base min-h-11",
                      // important to have padding so the + hover button on each line is clickable, as well as detecting when to hide the add/drag buttons when cursor leaves the editor
                      // https://github.com/Topicflow/topicflow/pull/1314
                      "-ml-[30px] pl-[46px] pt-2 pr-1",
                      "pb-8" // adding bottom padding here so the white space is clickable
                    )}
                    editable={canEdit}
                    placeholder={
                      canEdit
                        ? "Add my notes..."
                        : `${
                            participant.user?.firstName ||
                            participant?.participantEmail ||
                            "Participant"
                          }'s notes.`
                    }
                    overlayValue={overlayValue}
                    showPlaceholderOnlyWhenEditable={false}
                    showPlusButton={canEdit}
                    uploadVariable={uploadVariable}
                    ydoc={ydoc}
                    providerWebsocket={providerWebsocket}
                    webSocketDocumentId={websocketDocumentId}
                    mentionsConfig={mentionsConfig}
                    extraContext={extraContext}
                    onUpdateContent={handleUpdateContent}
                  />
                </div>
              </div>
            </ErrorBoundary>
          )}
        </div>
      </div>
    </div>
  );
};

export default IndividualNotes;
