import { gql } from "@apollo/client";

const IndividualNoteNodeFragment = gql`
  fragment IndividualNoteNodeFragment on IndividualNoteNode {
    id
    created
    creator {
      id
    }
    hidden
    enabled
    notes
  }
`;

export default IndividualNoteNodeFragment;
