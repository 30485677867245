import { gql } from "@apollo/client";

import organizationFragment from "./organization-fragment";
import syncCredentialFragment from "./sync-credential-fragment";

export default gql`
  ${syncCredentialFragment}
  ${organizationFragment}
  query getAccount {
    me {
      id
      email
      name
      firstName
      lastName
      emailPreference
      meetingSummaryEmailPreference
      timezone
      meetingPreparationEmailPreference
      enableDailySummaryEmails
      enableDailyCommitmentEmails
      enableDraftReminderEmails
      emails
      hasAsanaAuth
      hasZoomAuth
      hasGithubAuth
      hasJiraAuth
      hasClickupAuth
      hasSalesforceAuth
      hasHubspotAuth
      organizations {
        edges {
          node {
            ...AccountOrganizationFragment
          }
        }
      }
      managers {
        edges {
          node {
            id
            name
            avatar
          }
        }
      }
      relationships {
        edges {
          node {
            id
            organization {
              id
            }
            relationshipWithOtherUser
            otherUser {
              id
              name
              avatar
            }
          }
        }
      }
      teams {
        edges {
          node {
            id
            title
            organization {
              id
            }
          }
        }
      }
      organizationsAvailableToJoin {
        organizationId
        organizationName
        organizationMemberCount
      }
      syncCredentials {
        edges {
          node {
            ...AccountSyncCredentialFragment
          }
        }
      }
    }
  }
`;
