import { gql } from "@apollo/client";

export default gql`
  mutation removeTemplateSeriesFromMeetingMutation($meetingGroupId: Int!) {
    removeTemplateSeriesFromMeeting(meetingGroupId: $meetingGroupId) {
      meetingGroup {
        id
        templateSeries {
          edges {
            node {
              id
            }
          }
        }
      }
    }
  }
`;
