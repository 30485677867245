import { useLazyQuery } from "@apollo/client";
import { Listbox } from "@headlessui/react";
import { CalendarIcon, XIcon } from "@heroicons/react/outline";
import { ExclamationIcon } from "@heroicons/react/solid";
import { MouseEvent, useRef, useState } from "react";
import { useEffect } from "react";
import {
  SyncCredentialsTypeEnum,
  UserSyncCredentialsNode,
} from "types/graphql-schema";

import CalendarConnectNew from "@apps/account/components/calendar-connect-new";
import { currentUserVar } from "@cache/cache";
import Loading from "@components/loading/loading";
import Modal from "@components/modal/modal";
import ProviderImage from "@components/provider-image/provider-image";
import Select, { SelectOption } from "@components/select/select";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { providerUrl } from "@helpers/constants";
import { classNames } from "@helpers/css";
import { assertEdgesNonNull } from "@helpers/helpers";

import refreshCalendarsQuery from "../graphql/refresh-calendars-query";
import {
  meetingDialogAction,
  meetingDialogInputClassName,
} from "../meeting-dialog";

const popupParams = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=560,height=760`;
const newCalendarValue = -1;

const MeetingDialogCalendars = ({
  action,
  selectedCalendar,
  onChangeCalendar,
}: {
  action: meetingDialogAction;
  selectedCalendar?: null | Pick<
    UserSyncCredentialsNode,
    "id" | "provider" | "hasCalendarWriteCredentials"
  >;
  onChangeCalendar: (calendarId: number) => void;
}) => {
  const calendarPopupRef = useRef<Window | null>(null);
  const [showConnectCalendarModal, setShowConnectCalendarModal] =
    useState(false);
  const currentUser = currentUserVar();
  const [calendarPopupIsOpened, setCalendarPopupIsOpened] = useState(false);
  const [refreshCalendars, { loading: loadingRefreshCalendars }] = useLazyQuery(
    refreshCalendarsQuery,
    { onError: onNotificationErrorHandler() }
  );

  const validCalendars = assertEdgesNonNull(currentUser.syncCredentials).filter(
    ({ hasValidCalendarCredentials }) => hasValidCalendarCredentials
  );
  const _calendarOptions = validCalendars.map((node) => ({
    ...node,
    value: node.id,
    label: node.credentialsUid || "Unknow calendar",
  }));
  const calendarOptions = [
    ..._calendarOptions,
    {
      id: newCalendarValue,
      value: newCalendarValue,
      label: "Connect new calendar",
    },
  ];
  const selectedCalendarOption = validCalendars.find(
    ({ id }) => id === selectedCalendar?.id
  );

  const handleChangeCalendar = ({ value }: SelectOption<number>) => {
    if (newCalendarValue === value) {
      handleShowConnectCalendar();
    } else {
      onChangeCalendar(value);
    }
  };

  const handleGiveCalendarAccess = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (selectedCalendar && selectedCalendar.provider) {
      const url = `/sso/${
        providerUrl[selectedCalendar.provider]
      }?request_calendar_scope=true&request_write_scope=true&next=/sso/dialog-confirmation`;
      calendarPopupRef.current = window.open(
        url,
        "calendarAccess",
        popupParams
      );
      setCalendarPopupIsOpened(true);
    }
  };

  const handleShowConnectCalendar = (e?: MouseEvent) => {
    e?.preventDefault();
    setShowConnectCalendarModal(true);
  };

  const handleClickConnectCalendar = (option: {
    provider: SyncCredentialsTypeEnum;
    allowWriteAccess: boolean;
  }) => {
    setShowConnectCalendarModal(false);
    const url = `/sso/${
      providerUrl[option.provider]
    }?request_calendar_scope=true&request_write_scope=${String(
      option.allowWriteAccess
    )}&next=/sso/dialog-confirmation`;
    calendarPopupRef.current = window.open(url, "calendarAccess", popupParams);
    setCalendarPopupIsOpened(true);
  };

  // detect if dialog is opened/closed
  useEffect(() => {
    let interval: any;
    // if dialog is opened, then we check until it gets closed
    if (calendarPopupIsOpened) {
      interval = setInterval(() => {
        if (calendarPopupRef.current?.closed) {
          setCalendarPopupIsOpened(false);
        }
      }, 500);

      // when dialog was closed by user, we refresh calendars
    } else if (!calendarPopupIsOpened && calendarPopupRef.current) {
      refreshCalendars();
      calendarPopupRef.current = null;
    }
    return function cleanup() {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [calendarPopupIsOpened]);

  const hasWarning =
    action !== meetingDialogAction.readonly &&
    (validCalendars.length === 0 ||
      !selectedCalendar?.hasCalendarWriteCredentials);

  return (
    <div
      className={classNames(
        "flex items-center gap-1",
        hasWarning && "bg-orange-50"
      )}
    >
      <Modal open={showConnectCalendarModal} className="z-notification">
        <div
          className="p-6 flex justify-between items-start"
          aria-label="Connect calendar modal"
        >
          <div className="flex-1">
            <CalendarConnectNew
              next="/sso/dialog-confirmation"
              onClickButton={handleClickConnectCalendar}
            />
          </div>
          <button
            onClick={() => setShowConnectCalendarModal(false)}
            className="p-1 rounded hover:bg-gray-100 text-gray-700"
            aria-label="Close modal"
          >
            <XIcon className="w-7 h-7" />
          </button>
        </div>
      </Modal>
      <div className="py-1.5 text-gray-500">
        {action !== meetingDialogAction.readonly &&
        (hasWarning || !selectedCalendarOption) ? (
          <ExclamationIcon className="h-4 w-4 text-orange-600" />
        ) : !selectedCalendar ? (
          <CalendarIcon className="h-4 w-4" />
        ) : (
          <ProviderImage provider={selectedCalendar.provider} size="4" />
        )}
      </div>

      {action === meetingDialogAction.readonly ? (
        <div className="px-2 py-1 text-sm flex flex-col gap-2">
          {selectedCalendarOption
            ? selectedCalendarOption.credentialsUid
            : "No calendar"}
        </div>
      ) : action === meetingDialogAction.edit ? (
        <div className="px-2 py-1 text-sm flex flex-col gap-2">
          {selectedCalendarOption ? (
            <div>
              {selectedCalendarOption.credentialsUid}{" "}
              {!selectedCalendar?.hasCalendarWriteCredentials && (
                <span>
                  is read-only.{" "}
                  <button
                    onClick={handleGiveCalendarAccess}
                    className="text-blue-600 hover:underline"
                  >
                    Allow calendar write access
                  </button>
                  .
                </span>
              )}
            </div>
          ) : (
            <div>
              No calendar.{" "}
              {action === meetingDialogAction.edit && (
                <button
                  onClick={handleShowConnectCalendar}
                  className="text-blue-600 hover:underline"
                >
                  Connect your calendar
                </button>
              )}
            </div>
          )}
        </div>
      ) : currentUser.status === "demo" ? (
        <div className="px-2 py-1 text-sm flex flex-col gap-2">
          <div className="font-medium text-orange-600">
            Demo accounts can only create draft meetings, and cannot publish
            drafts to a calendar
          </div>
        </div>
      ) : validCalendars.length === 0 ? (
        <div className="px-2 py-1 text-sm flex flex-col gap-2">
          <div className="font-medium text-orange-600">
            No calendar connected
          </div>

          <div className="text-gray-500">
            <button
              onClick={handleShowConnectCalendar}
              className="text-blue-600 hover:underline"
            >
              Connect your calendar
            </button>{" "}
            before inviting guests.
          </div>

          {loadingRefreshCalendars && (
            <div className="mt-2 text-gray-500 flex items-center gap-2 text-sm">
              <Loading mini size="4" /> Refreshing calendars
            </div>
          )}
        </div>
      ) : (
        <div>
          <Select<number>
            options={calendarOptions}
            value={selectedCalendarOption?.id || null}
            width="72"
            onChange={handleChangeCalendar}
            className="w-full border-0 shadow-none"
          >
            {({ selected, setReferenceElement }) => (
              <Listbox.Button
                ref={setReferenceElement}
                aria-label="Calendar select"
                className={classNames(
                  meetingDialogInputClassName,
                  hasWarning && "hover:bg-orange-100"
                )}
              >
                <span
                  className={classNames(
                    "block truncate",
                    hasWarning && "text-orange-600"
                  )}
                >
                  {selected?.label}
                </span>
              </Listbox.Button>
            )}
          </Select>
          {!selectedCalendar?.hasCalendarWriteCredentials && (
            <div className="text-sm mt-4 ml-2">
              <div className="font-semibold text-gray-800 mb-2">
                The calendar is read-only.
              </div>
              <button
                onClick={handleGiveCalendarAccess}
                className="text-blue-600 hover:underline"
              >
                Allow calendar write access
              </button>{" "}
              before inviting guests.
            </div>
          )}
          {loadingRefreshCalendars && (
            <div className="mt-2 ml-2 text-gray-500 flex items-center gap-2 text-sm">
              <Loading mini size="5" /> Refreshing calendars
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default MeetingDialogCalendars;
