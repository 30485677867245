import { useQuery } from "@apollo/client";
import { compact, uniqBy } from "lodash";
import { ChangeEvent, useState } from "react";
import {
  GetGlobalTemplatesQueryQuery,
  GetGlobalTemplatesQueryQueryVariables,
  MeetingViewMeetingNodeNewPageFragmentFragment,
  SuggestedTemplateCategoryFragmentFragment,
} from "types/graphql-schema";

import Input from "@components/input/input";
import Loading from "@components/loading/loading";
import Select, { SelectOption } from "@components/select/select";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { assertEdgesNonNull } from "@helpers/helpers";

import getGlobalTemplatesQuery from "../graphql/get-global-templates-query";
import SuggestedTopicsSidebarTemplate from "./template-topic";

const SuggestedTopicsSidebarTemplates = ({
  meeting,
}: {
  meeting: MeetingViewMeetingNodeNewPageFragmentFragment;
}) => {
  const { data, loading } = useQuery<
    GetGlobalTemplatesQueryQuery,
    GetGlobalTemplatesQueryQueryVariables
  >(getGlobalTemplatesQuery, {
    variables: { meetingId: meeting.id },
    onError: onNotificationErrorHandler(),
  });
  const [formFilterText, setFilterText] = useState("");
  const filterText = formFilterText.trim();
  const [filterCategory, setFilterCategory] = useState<number | null>(null);

  const topicTemplates = data ? assertEdgesNonNull(data.topicTemplates) : [];
  const categories =
    topicTemplates.reduce((memo, template) => {
      const categories = template?.categories
        ? assertEdgesNonNull(template.categories)
        : [];
      return uniqBy([...memo, ...categories], "id");
    }, [] as SuggestedTemplateCategoryFragmentFragment[]) || [];
  const categoryOptions = [
    {
      label: "All categories",
      value: null,
    },
    ...categories.map((category) => ({
      value: category.id,
      label: category.title,
    })),
  ];

  const meetingSuggestedTemplates = data?.meeting?.suggestedTemplates
    ? assertEdgesNonNull(data.meeting.suggestedTemplates)
    : [];
  const templates = uniqBy(
    [...meetingSuggestedTemplates, ...topicTemplates],
    "id"
  );
  const topicsByTemplateGroups = compact(
    templates.map((template) => {
      const categories = assertEdgesNonNull(template.categories);
      const hasMatchingCategory =
        filterCategory === null ||
        !!categories.find((category) => category.id === filterCategory);
      const topics = assertEdgesNonNull(template.topics);
      const matchingTopics = topics.filter((topic) =>
        topic.title.toLowerCase().includes(filterText.toLowerCase())
      );
      const matchingTemplateTitle = template.title
        .toLowerCase()
        .includes(filterText.toLowerCase());

      if (!hasMatchingCategory) {
        return null;
      }
      if (matchingTopics.length === 0 && !matchingTemplateTitle) {
        return null;
      }
      return {
        template,
        categories,
        topics,
      };
    })
  );

  const handleChangeFilterText = (e: ChangeEvent<HTMLInputElement>) => {
    setFilterText(e.target.value);
  };

  const handleChangeFilterCategory = (option: SelectOption<number | null>) => {
    setFilterCategory(option.value || null);
  };

  return (
    <div className="flex flex-col gap-6">
      <div className="flex gap-6">
        <Input
          value={formFilterText}
          onChange={handleChangeFilterText}
          placeholder="Filter suggested topics..."
        />
        <Select<number | null>
          options={categoryOptions}
          value={filterCategory}
          onChange={handleChangeFilterCategory}
        />
      </div>
      {loading ? (
        <Loading>Loading suggested topics</Loading>
      ) : topicsByTemplateGroups.length > 0 ? (
        <div className="flex flex-col pb-24 gap-8">
          {topicsByTemplateGroups.length > 0 &&
            topicsByTemplateGroups.map((topicsByTemplateGroup) => (
              <SuggestedTopicsSidebarTemplate
                key={topicsByTemplateGroup.template.id}
                meeting={meeting}
                template={topicsByTemplateGroup.template}
                topics={topicsByTemplateGroup.topics}
              />
            ))}
        </div>
      ) : topicsByTemplateGroups.length === 0 ? (
        <div className="w-full flex text-gray-500">No suggested topics.</div>
      ) : null}
    </div>
  );
};

export default SuggestedTopicsSidebarTemplates;
