import { useLazyQuery } from "@apollo/client";
import { captureException } from "@sentry/react";
import { useCallback } from "react";

import cache from "@cache/cache";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import usePusher from "@components/use-pusher/use-pusher";
import { maxKeyResults } from "@helpers/constants";
import { matchApolloErrorMessage } from "@helpers/helpers";

import getArtifactQuery from "../graphql/get-artifact-query";

export default function PusherArtifactUpdated({
  channelName,
}: {
  channelName: string;
}) {
  // Queries & Mutations
  const [fetchArtifact] = useLazyQuery(getArtifactQuery, {
    onError: onNotificationErrorHandler(),
  });

  const handleArtifactUpdated = useCallback(
    ({ artifact }: { artifact: { id: number } }) => {
      // If it's in the cache then we update
      const artifactCacheId = cache.identify(artifact);
      if (artifactCacheId) {
        fetchArtifact({
          variables: { artifactId: artifact.id, keyResultLimit: maxKeyResults },
        }).catch((errors) => {
          if (matchApolloErrorMessage(errors, "Invalid id")) {
            return;
          }
          captureException(errors);
        });
      }
    },
    [fetchArtifact]
  );

  usePusher(channelName, "artifact_updated", handleArtifactUpdated);

  return null;
}
