import { gql } from "@apollo/client";

import TopicTemplateFragment from "./topic-template-fragment";

export default gql`
  ${TopicTemplateFragment}
  query getTopicTemplate($templateId: Int!) {
    topicTemplate(topicTemplateId: $templateId) {
      ...TopicTemplateFragment
    }
  }
`;
