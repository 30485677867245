import { gql } from "@apollo/client";

import KPIFragment from "./kpi-fragment";

export default gql`
  ${KPIFragment}
  query getKpiQuery(
    $kpiId: Int!
    $hasKpi: Boolean!
    $organizationId: Int
    $hasOrganizationId: Boolean!
  ) {
    kpiGroups {
      edges {
        node {
          id
          title
        }
      }
    }
    organization(pk: $organizationId) @include(if: $hasOrganizationId) {
      id
      integrations(integrationType: salesforce) {
        edges {
          node {
            id
            integrationType
            socialAuth {
              id
              extraData
            }
          }
        }
      }
    }
    kpi(kpiId: $kpiId) @include(if: $hasKpi) {
      ...KPIFragment
      teams {
        edges {
          node {
            id
            title
          }
        }
      }
      organization {
        id
        name
      }
      currentMeasurement {
        id
        measurement
      }
      created
      canDelete {
        permission
        reason
      }
      canUpdate {
        permission
        reason
      }
      description
      groups {
        edges {
          node {
            id
            title
          }
        }
      }
      integration {
        id
        integrationType
        organizationIntegrationConnection {
          id
        }
        ... on TopicflowKPIIntegrationNode {
          metricType
          timeBucket
          user {
            id
          }
          value
        }
        ... on GoogleSheetsKPIIntegrationNode {
          spreadsheetId
          cellId
          bottomRowFromColumn
          rightmostColumnFromRow
        }
        ... on SalesforceKPIIntegrationNode {
          soqlQuery
          kpiFieldName
        }
        ... on GithubKPIIntegrationNode {
          ghMetricType: metricType
          ghTimeBucket: timeBucket
          owner
          repository
        }
        ... on HubspotKPIIntegrationNode {
          hubspotMetricType: metricType
          hubspotTimeBucket: timeBucket
          dealStageId
        }
      }
    }
  }
`;
