import { gql } from "@apollo/client";

export default gql`
  mutation updateUserSettings(
    $userId: Int!
    $firstName: String
    $lastName: String
    $primaryEmailAddress: String
    $emailPreference: EmailPreference
    $meetingSummaryEmailPreference: MeetingSummaryEmailPreference
    $timezone: String
    $meetingPreparationEmailPreference: MeetingAgendaEmailPreference
    $enableDailySummaryEmails: Boolean
    $enableDraftReminderEmails: Boolean
    $enableDailyCommitmentEmails: Boolean
    $eventLinkInDescriptionPreference: EventDescriptionLinkSetting
  ) {
    updateUser(
      id: $userId
      firstName: $firstName
      lastName: $lastName
      primaryEmailAddress: $primaryEmailAddress
      emailPreference: $emailPreference
      meetingSummaryEmailPreference: $meetingSummaryEmailPreference
      timezone: $timezone
      meetingPreparationEmailPreference: $meetingPreparationEmailPreference
      enableDailySummaryEmails: $enableDailySummaryEmails
      enableDraftReminderEmails: $enableDraftReminderEmails
      enableDailyCommitmentEmails: $enableDailyCommitmentEmails
      eventLinkInDescriptionPreference: $eventLinkInDescriptionPreference
    ) {
      user {
        id
        emailPreference
        email
        meetingSummaryEmailPreference
        meetingPreparationEmailPreference
        enableDailySummaryEmails
        enableDailyCommitmentEmails
        enableDraftReminderEmails
        timezone
        eventLinkInDescriptionPreference
      }
    }
  }
`;
