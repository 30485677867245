import { useQuery } from "@apollo/client";
import { CalendarIcon } from "@heroicons/react/outline";
import { Node, mergeAttributes } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";
import { NodeViewWrapper } from "@tiptap/react";
import moment from "moment";
import { matchPath } from "react-router-dom";
import {
  GetMeetingEmbedQueryQuery,
  GetMeetingEmbedQueryQueryVariables,
} from "types/graphql-schema";

import Loading from "@components/loading/loading";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { tiptapExtensionPriority } from "@helpers/constants";
import { classNames } from "@helpers/css";
import { getUrl, isValidUrl } from "@helpers/helpers";

import getMeetingEmbedQuery from "../graphql/get-meeting-embed-query";
import { getPluginClickOn, getPluginHandlePaste } from "../helpers";

const MeetingEmbedComponent = ({
  node,
  selected,
}: {
  node: any;
  selected: boolean;
}) => {
  const { data, loading } = useQuery<
    GetMeetingEmbedQueryQuery,
    GetMeetingEmbedQueryQueryVariables
  >(getMeetingEmbedQuery, {
    variables: { meetingId: Number(node.attrs.meetingId) },
    onError: onNotificationErrorHandler(),
  });
  return (
    <NodeViewWrapper className="mb-3">
      <div
        className={classNames(
          "w-full flex items-center justify-between gap-2 border rounded-lg bg-gray-50 not-prose px-2 py-1",
          selected && "ring-2 ring-blue-200 ring-offset-2"
        )}
      >
        {loading ? (
          <Loading mini size="4" />
        ) : data?.meeting ? (
          <div className="w-full flex justify-between items-center gap-2 text-sm">
            <div className="flex gap-1 sm:gap-2 items-center">
              <CalendarIcon className="h-4 w-4 text-gray-400" />
              <a
                className="hover:underline text-blue-link font-medium"
                target="_blank"
                rel="noreferrer"
                href={getUrl({
                  meetingId: data.meeting.id,
                  meetingGroupId: data.meeting.meetingGroupId,
                })}
              >
                {data.meeting.title}
              </a>
            </div>
            {data?.meeting.startDatetime && (
              <div className="text-xs text-gray-500">
                {moment(data?.meeting.startDatetime).format("llll")}
              </div>
            )}
          </div>
        ) : (
          <div className="w-full flex justify-between items-center gap-2 text-sm">
            The meeting informations cannot be displayed. You don't seem to have
            access to it.
          </div>
        )}
      </div>
    </NodeViewWrapper>
  );
};

const MeetingEmbedExtension = Node.create({
  name: "meeting-embed",
  group: "block",
  marks: "",
  draggable: true,
  selectable: true,
  allowGapCursor: true,
  atom: true,
  isolating: true,
  defining: true,

  addAttributes() {
    // Return an object with attribute configuration
    return {
      meetingId: {
        default: null,
        parseHTML: (node: HTMLLinkElement) => {
          const regexp = new RegExp(
            `^${window.location.origin}/meeting/(\\d+)/(\\d+)$`
          );
          if (!node?.href) return null;
          const regexpResult = regexp.exec(node.href);
          if (!regexpResult) {
            return null;
          }
          return regexpResult[2];
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "a",
        priority: tiptapExtensionPriority.explorerLink,
        getAttrs: (node: HTMLElement | string) => {
          if (node && node instanceof HTMLAnchorElement) {
            const regexp = new RegExp(
              `^${window.location.origin}/meeting/(\\d+)/(\\d+)$`
            );
            const regexpResult = regexp.exec(node.href);
            if (!regexpResult) {
              return false;
            }
            // if a link created in wysiwyg, we don't embed it
            if (node.classList.contains("js-wysiwyg-link")) {
              return false;
            }
            // only embed if link text is same as href
            if (node.innerText !== node.href) {
              return false;
            }
            return null;
          }
          return false;
        },
      },
    ];
  },

  addNodeView() {
    return ReactNodeViewRenderer(MeetingEmbedComponent);
  },

  renderText({ node }) {
    return `${window.location.origin}/meeting/${node.attrs.id}`;
  },

  renderHTML({ HTMLAttributes }) {
    return ["div", mergeAttributes(HTMLAttributes)];
  },

  addProseMirrorPlugins() {
    return [
      getPluginClickOn(this.name),
      getPluginHandlePaste(this, (url, view) => {
        if (!url || !isValidUrl(url)) {
          return false;
        }
        const parsedUrl = new URL(url);
        if (parsedUrl.hostname !== document.location.hostname) return false;
        const matchedPath: {
          params: {
            meetingId: string;
          };
        } | null = matchPath(parsedUrl.pathname, {
          path: "/meeting/:meetingGroupId/:meetingId",
          exact: true,
          strict: true,
        });
        if (matchedPath?.params.meetingId) {
          const { tr } = view.state;
          tr.replaceSelectionWith(
            this.type.create({ meetingId: matchedPath.params.meetingId })
          );
          view.dispatch(tr);
          return true;
        }
        return false;
      }),
    ];
  },
});

export default MeetingEmbedExtension;
