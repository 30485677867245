import { GiphyFetch } from "@giphy/js-fetch-api";
import { Grid } from "@giphy/react-components";
import { useState } from "react";

import useDebounce from "@components/use-debounce/use-debounce";

import WYSIWYGDialog from "../dialog";

const gf = new GiphyFetch(import.meta.env.VITE_GIPHY_API_KEY);

const GiphyDialog = ({
  item,
  command,
  onClose,
}: {
  item: any;
  command: any;
  onClose: () => void;
}) => {
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 300);

  const insertGiphyText = (gif: any) => {
    command({ ...item, gif });
  };

  const fetchGifs = (offset: number) =>
    debouncedSearch
      ? gf.search(debouncedSearch, { offset, limit: 10 })
      : gf.trending({ offset, limit: 10 });

  return (
    <WYSIWYGDialog title="Embed Giphy" onClose={onClose}>
      <div className="">
        <div className="mb-4 flex justify-between">
          <input
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            className="border px-4 py-2"
            placeholder="Search Giphy..."
          />
        </div>
        <Grid
          width={620}
          columns={3}
          fetchGifs={fetchGifs}
          noLink
          key={debouncedSearch}
          onGifClick={insertGiphyText}
        />
      </div>
    </WYSIWYGDialog>
  );
};

export default GiphyDialog;
