import moment from "moment";
import TimeAgo from "react-timeago";

import Tooltip from "@components/tooltip/tooltip";

const TimeAgoCustom = ({
  date,
  prefix = "",
  tooltipFormat = "LLLL",
}: {
  date: string | Date;
  prefix?: string;
  tooltipFormat?: string;
}) => (
  <Tooltip text={`${prefix}${moment(date).format(tooltipFormat)}`}>
    <span>
      <TimeAgo title="" minPeriod={60} date={date} />
    </span>
  </Tooltip>
);

export default TimeAgoCustom;
