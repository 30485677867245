import { useLocation, useParams } from "react-router-dom";
import { TFLocationState } from "types/topicflow";

import { useLink } from "@components/link/link";
import { getUrl } from "@helpers/helpers";

import ArtifactSidebar from "./artifact-sidebar";

const ArtifactSidebarRoute = () => {
  const link = useLink();
  const location = useLocation<TFLocationState>();
  const params = useParams<{
    artifactId: string;
    meetingId?: string;
    meetingGroupId?: string;
  }>();
  const artifactId = Number(params.artifactId);
  const meetingId = Number(params.meetingId) || undefined;
  const meetingGroupId = Number(params.meetingGroupId) || undefined;

  const closeSidebar = ({ redirect = true }) => {
    if (location.state?.background) {
      if (redirect) {
        return link.redirect(location.state.background);
      }
      return link.replace(location.state.background);
    }
    if (meetingGroupId) {
      const url = getUrl({ meetingGroupId, meetingId });
      return link.redirect(url);
    }
    link.redirect("/");
  };

  return (
    <ArtifactSidebar
      artifactId={artifactId}
      meetingId={meetingId}
      meetingGroupId={meetingGroupId}
      onClose={closeSidebar}
    />
  );
};

export default ArtifactSidebarRoute;
