import { toInteger } from "lodash";
import { useRef } from "react";

import Sidebar from "@components/sidebar/sidebar";

import ArtifactSidebarContent from "./artifact-sidebar-content";

const ArtifactSidebar = ({
  artifactId,
  meetingId,
  meetingGroupId,
  onClose,
}: {
  artifactId: number;
  meetingId?: number;
  meetingGroupId?: number;
  onClose: (option: { redirect: boolean }) => void;
}) => {
  const focusRef = useRef<HTMLElement>(null);
  const handleCloseSidebar = () => {
    onClose({ redirect: false });
  };

  return (
    <Sidebar
      show
      className="bg-gray-50"
      onClose={handleCloseSidebar}
      focusRef={focusRef}
    >
      <ArtifactSidebarContent
        artifactId={artifactId}
        meetingId={meetingId ? toInteger(meetingId) : undefined}
        meetingGroupId={meetingGroupId ? toInteger(meetingGroupId) : undefined}
        onClose={onClose}
      />
    </Sidebar>
  );
};

export default ArtifactSidebar;
