import moment from "moment";
import { useMemo } from "react";
import {
  ComplianceProgramAppliesTo,
  ComplianceProgramRecurrence,
  GetComplianceProgramQuery,
} from "types/graphql-schema";

import useLabel from "@apps/use-label/use-label";
import { assertEdgesNonNull } from "@helpers/helpers";

import ComplianceProgramRecurrencTooltip from "./compliance-program-recurrence-tooltip";

const appliesToLabels = {
  [ComplianceProgramAppliesTo.Organization]: "Entire organization",
  [ComplianceProgramAppliesTo.Departments]: "Departments",
  [ComplianceProgramAppliesTo.Managers]: "Managers",
  [ComplianceProgramAppliesTo.Users]: "Users",
};
export enum ProgramTabType {
  ASSESSMENTS = "assessments",
  SELF_ASSESSMENTS = "self-assessments",
  ONE_ON_ONES = "one-on-ones",
  SUMMARY = "summary",
}

export type ProgramTab = {
  type: ProgramTabType;
  key: string;
  assessmentGroup: number | null;
};

const ComplianceProgramSidebar = ({
  complianceProgram,
}: {
  complianceProgram: NonNullable<
    GetComplianceProgramQuery["complianceProgram"]
  >;
}) => {
  const label = useLabel();
  const performanceAssessmentGroup = useMemo(
    () =>
      complianceProgram?.performanceAssessmentGroup
        ? complianceProgram.performanceAssessmentGroup
        : null,
    [complianceProgram]
  );
  const managerAssessmentGroup = useMemo(
    () =>
      complianceProgram?.managerAssessmentGroup
        ? complianceProgram.managerAssessmentGroup
        : null,
    [complianceProgram]
  );
  const peerAssessmentGroup = useMemo(
    () =>
      complianceProgram?.peerAssessmentGroup
        ? complianceProgram.peerAssessmentGroup
        : null,
    [complianceProgram]
  );
  const topicTemplate = useMemo(
    () =>
      complianceProgram?.requiredTopicTemplates
        ? assertEdgesNonNull(complianceProgram.requiredTopicTemplates)[0]
        : null,
    [complianceProgram]
  );

  const excludedUsers = assertEdgesNonNull(complianceProgram.excludedUsers);

  return (
    <div className="sm:w-72 flex flex-col text-sm gap-4 bg-gray-50 sm:border-r p-4 sm:p-6 rounded-l-lg">
      <div className="font-bold text-lg">{complianceProgram.title}</div>
      <div className="text-gray-500">
        Start date:{" "}
        <span className="font-bold">
          {moment(complianceProgram.startDate).format("MMM D, YYYY")}
        </span>
      </div>
      <div className="flex items-center gap-2 text-gray-500">
        <div>
          Due date:{" "}
          <span className="font-bold">
            {moment(complianceProgram.dueDate).format("MMM D, YYYY")}
          </span>
        </div>
        {complianceProgram.recurrence !== ComplianceProgramRecurrence.None && (
          <ComplianceProgramRecurrencTooltip
            recurrence={complianceProgram.recurrence}
          />
        )}
      </div>
      <div className="text-gray-500">
        Program period:{" "}
        <span className="font-bold">
          {complianceProgram.periodStartDate && complianceProgram.periodEndDate
            ? `${moment(complianceProgram.periodStartDate).format(
                "MMM D, YYYY"
              )} - ${moment(complianceProgram.periodEndDate).format(
                "MMM D, YYYY"
              )}`
            : "Not set"}
        </span>
      </div>
      <div className="text-gray-500">
        Meeting template:{" "}
        <span className="font-bold">
          {topicTemplate ? topicTemplate.title : "Not set"}
        </span>
      </div>
      <div className="text-gray-500">
        {`Performance ${label("review", { capitalize: true })} template: `}
        <span className="font-bold">
          {performanceAssessmentGroup
            ? performanceAssessmentGroup.title
            : "Not set"}
        </span>
      </div>
      <div className="text-gray-500">
        {`Manager ${label("review", { capitalize: true })} template: `}
        <span className="font-bold">
          {managerAssessmentGroup ? managerAssessmentGroup.title : "Not set"}
        </span>
      </div>
      <div className="text-gray-500">
        {`Peer ${label("review", { capitalize: true })} template: `}
        <span className="font-bold">
          {peerAssessmentGroup ? peerAssessmentGroup.title : "Not set"}
        </span>
      </div>
      <div className="text-gray-500">
        Applies to:{" "}
        <span className="font-bold">
          {appliesToLabels[complianceProgram.appliesTo]}
        </span>
        {complianceProgram.appliesTo ===
          ComplianceProgramAppliesTo.Managers && (
          <span>
            {" "}
            (
            {complianceProgram.appliesToManagers.edges
              .map((edge) => edge?.node?.name)
              .join(", ")}
            )
          </span>
        )}
        {complianceProgram.appliesTo === ComplianceProgramAppliesTo.Users && (
          <span>
            {" "}
            (
            {complianceProgram.appliesToUsers.edges
              .map((edge) => edge?.node?.name)
              .join(", ")}
            )
          </span>
        )}
        {complianceProgram.appliesTo ===
          ComplianceProgramAppliesTo.Departments && (
          <span>
            {" "}
            (
            {complianceProgram.appliesToTeams.edges
              .map((edge) => edge?.node?.title)
              .join(", ")}
            )
          </span>
        )}
      </div>
      {(excludedUsers.length > 0 ||
        complianceProgram.excludedHireDateAfter) && (
        <div className="text-gray-500">
          Excluded:{" "}
          <span className="font-bold">
            {`${excludedUsers.map((user) => user.name).join(", ")}${
              complianceProgram.excludedHireDateAfter &&
              excludedUsers.length > 0
                ? ", "
                : ""
            }`}
            {complianceProgram.excludedHireDateAfter
              ? `Users hired after ${moment(
                  complianceProgram.excludedHireDateAfter
                ).format("MMM D, YYYY")}`
              : ""}
          </span>
        </div>
      )}
    </div>
  );
};

export default ComplianceProgramSidebar;
