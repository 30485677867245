import { useQuery } from "@apollo/client";
import { compact } from "lodash";
import {
  Redirect,
  Route,
  Switch,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import {
  GetAccountQuery,
  GetAccountQueryVariables,
} from "types/graphql-schema";

import Button from "@components/button/button";
import Container from "@components/container/container";
import GraphqlError from "@components/error/graphql-error";
import Heading from "@components/heading/heading";
import Link from "@components/link/link";
import Loading from "@components/loading/loading";
import useDocumentTitle from "@components/use-document-title/use-document-title";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import client from "@graphql/client";
import { classNames } from "@helpers/css";
import { assertNonNull } from "@helpers/helpers";

import DirectorySync from "./components/directorysync";
import Email from "./components/email";
import Export from "./components/export";
import Integrations from "./components/integrations";
import Preferences from "./components/preferences";
import Profile from "./components/profile/profile";
import getAccountQuery from "./graphql/get-account-query";

const Nav = () => {
  useDocumentTitle("Account settings");
  const tabs = compact([
    {
      name: "Account",
      id: "profile",
    },
    {
      name: "Preferences",
      id: "preferences",
    },
    {
      name: "Notifications",
      id: "email",
    },
    { name: "Integrations", id: "integrations" },
    { name: "Export", id: "export" },
    { name: "Directory Sync", id: "directorysync" },
  ]);
  const { navId } = useParams<{ navId: string }>();
  const displayedTabId = navId || tabs[0].id;
  return (
    <div className="mb-6">
      <nav className="-mb-px flex gap-6" aria-label="Tabs">
        {tabs.map((tab) => (
          <Link
            key={tab.name}
            to={`/account/${tab.id}`}
            className={classNames(
              tab.id === displayedTabId
                ? "border-blue-700 text-gray-700"
                : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
              "whitespace-nowrap py-2 px-1 border-b-3 font-medium text-base"
            )}
            aria-current={tab.id === displayedTabId ? "page" : undefined}
          >
            {tab.name}
          </Link>
        ))}
      </nav>
    </div>
  );
};

const Account = () => {
  const { path } = useRouteMatch();
  const { loading, error, data, refetch } = useQuery<
    GetAccountQuery,
    GetAccountQueryVariables
  >(getAccountQuery, {
    fetchPolicy: "network-only",
    onError: onNotificationErrorHandler(),
  });

  const handleLogout = () => {
    client.resetStore();
    window.location.href = "/logout";
    if (window.Intercom) {
      window.Intercom("shutdown");
    }
  };

  return (
    <Container ariaLabel="Account">
      <Heading title="Settings" />

      {error ? (
        <GraphqlError
          error={error}
          whatDidNotWork="Your account could not be loaded."
        />
      ) : loading ? (
        <Loading>loading people...</Loading>
      ) : data ? (
        <div className="">
          <div className="flex justify-between">
            <div className="flex-1 flex overflow-x-auto">
              <Switch>
                <Route exact path={path}>
                  <Nav />
                </Route>
                <Route exact path={`${path}/:navId`}>
                  <Nav />
                </Route>
              </Switch>
            </div>
            <div className="shrink-0">
              <Button onClick={handleLogout}>Log out</Button>
            </div>
          </div>

          <div className="p-6 bg-white shadow sm:rounded-md text-gray-800">
            <Switch>
              <Route exact path={path}>
                <Profile me={data.me} refetch={refetch} />
              </Route>
              <Route exact path={`${path}/profile`}>
                <Profile me={data.me} refetch={refetch} />
              </Route>
              <Route exact path={`${path}/email`}>
                <Email me={assertNonNull(data.me)} />
              </Route>
              <Route exact path={`${path}/integrations`}>
                <Integrations me={data.me} />
              </Route>
              <Route exact path={`${path}/calendar`}>
                <Redirect to="/account/profile" />
              </Route>
              <Route exact path={`${path}/preferences`}>
                <Preferences me={data.me} />
              </Route>
              <Route exact path={`${path}/export`}>
                <Export />
              </Route>
              <Route exact path={`${path}/directorysync`}>
                <DirectorySync me={data.me} />
              </Route>
            </Switch>
          </div>
        </div>
      ) : (
        <GraphqlError
          whatDidNotWork="Your account could not be loaded."
          error={new Error("Account could not be loaded")}
        />
      )}
    </Container>
  );
};

export default Account;
