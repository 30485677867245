import { useMutation } from "@apollo/client";
import { PlusIcon } from "@heroicons/react/outline";
import { useState } from "react";
import {
  ArtifactType,
  CreatedArtifactFragmentFragment,
} from "types/graphql-schema";

import ArtifactCreationDialog from "@apps/artifact-creation-dialog/artifact-creation-dialog";
import linkArtifactToChatMessageMutation from "@apps/ask-ai/graphql/link-artifact-to-chat-message-mutation";
import useLabel from "@apps/use-label/use-label";
import { currentOrganizationVar } from "@cache/cache";
import CoreValueIcon from "@components/core-value-icon/core-value-icon";
import ActionItemPeopleDropdown from "@components/people-dropdown/action-item-people-dropdown";
import Tooltip from "@components/tooltip/tooltip";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { graphqlNone } from "@helpers/constants";
import { classNames } from "@helpers/css";

const AskAISuggestedArtifact = ({
  suggestedArtifact,
  chatSessionId,
  messageId,
}: {
  suggestedArtifact: any;
  chatSessionId: number | null;
  messageId: string;
}) => {
  const [showArtifactDialog, setShowArtifactDialog] = useState(false);
  const currentOrganization = currentOrganizationVar();
  const label = useLabel();
  const emptyAssignee = {
    id: graphqlNone,
    name: "Unassigned",
  };

  const [assignee, setAssignee] = useState(
    suggestedArtifact.suggestedAssignee || emptyAssignee
  );
  const [linkSuggestedArtifact, { loading: loadingLink }] = useMutation(
    linkArtifactToChatMessageMutation
  );
  const artifactLabel = label(suggestedArtifact.artifactType);

  const handleCloseDialog = (
    createdArtifact?: CreatedArtifactFragmentFragment
  ) => {
    setShowArtifactDialog(false);
    if (createdArtifact) {
      linkSuggestedArtifact({
        variables: {
          artifactId: createdArtifact.id,
          chatSessionId: chatSessionId,
          messageId: messageId,
        },
        onError: onNotificationErrorHandler(),
      });
    }
  };

  return (
    <div
      key={suggestedArtifact.id}
      className="flex gap-2 group p-3 items-center"
    >
      {showArtifactDialog && (
        <ArtifactCreationDialog
          formOptions={{
            title: suggestedArtifact.title,
            feedbackMessage:
              suggestedArtifact.artifactType === ArtifactType.Feedback
                ? JSON.stringify({
                    type: "doc",
                    content: [
                      {
                        type: "paragraph",
                        content: [
                          { type: "text", text: suggestedArtifact.description },
                        ],
                      },
                    ],
                  })
                : undefined,
            artifactType: suggestedArtifact.artifactType,
            organizationId: currentOrganization.id,
            assignee:
              suggestedArtifact.artifactType === ArtifactType.ActionItem &&
              assignee.id !== graphqlNone
                ? assignee
                : undefined,
            recognitionRecipients:
              suggestedArtifact.artifactType === ArtifactType.Recognition &&
              assignee.id !== graphqlNone
                ? [assignee]
                : [],
            recognitionCoreValueId: suggestedArtifact.coreValue?.id,
            feedbackRecipients:
              suggestedArtifact.artifactType === ArtifactType.Feedback &&
              assignee.id !== graphqlNone
                ? [assignee]
                : [],
          }}
          onClose={handleCloseDialog}
        />
      )}
      {suggestedArtifact.coreValue && (
        <span
          className={classNames(
            "shrink-0",
            !suggestedArtifact.coreValue?.image &&
              "flex items-center justify-center rounded-full"
          )}
        >
          <CoreValueIcon
            emoji={suggestedArtifact.coreValue?.emoji}
            image={suggestedArtifact.coreValue?.image}
            imagePxSize={20}
          />
        </span>
      )}
      <button
        type="button"
        className="flex-1 text-left p-1 -ml-1 fs-mask"
        onClick={() => setShowArtifactDialog(true)}
      >
        {suggestedArtifact.title}
      </button>
      <div className="flex items-center gap-2 mt-1">
        <ActionItemPeopleDropdown
          actionItem={{ assignee }}
          onChangeAssignee={setAssignee}
          showName={false}
          unassignedId={graphqlNone}
        />
        <Tooltip text={`Create ${artifactLabel}`}>
          <button
            className="p-0.5 rounded-full hover:bg-gray-100 text-gray-500"
            onClick={() => setShowArtifactDialog(true)}
            disabled={loadingLink}
          >
            <PlusIcon className="h-4 w-4" />
          </button>
        </Tooltip>
      </div>
    </div>
  );
};

export default AskAISuggestedArtifact;
