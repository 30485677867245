import {
  CompetencyAppliesTo,
  CompetencyCriteriaUniqueness,
} from "types/graphql-schema";

export const competencyAppliesToLabels: {
  [key in CompetencyAppliesTo]: string;
} = {
  [CompetencyAppliesTo.SomeRoles]: "Applies to some roles",
  [CompetencyAppliesTo.AllRoles]: "Applies to all roles",
};

export const competencyCriteriaUniquenessLabels: {
  [key in CompetencyCriteriaUniqueness]: string;
} = {
  [CompetencyCriteriaUniqueness.SameAcrossRoles]:
    "Criteria for each level is the same across roles",
  [CompetencyCriteriaUniqueness.DifferentForEachRole]:
    "Criteria for each level is different across roles",
};

export const competencyInfo = (competency: {
  appliesTo: CompetencyAppliesTo;
  criteriaUniqueness: CompetencyCriteriaUniqueness;
}) => {
  return `${
    competencyAppliesToLabels[competency.appliesTo]
  }, ${competencyCriteriaUniquenessLabels[
    competency.criteriaUniqueness
  ].toLocaleLowerCase()}`;
};
