import { DragDropContext, Droppable } from "@hello-pangea/dnd";
import { JSONContent } from "@tiptap/core";
import { ChangeEvent } from "react";

import { NotesRequirement } from "../../../types/graphql-schema";
import { EditTemplateTopicType } from "../helpers";
import EditTopic from "./edit-topic";

const TemplateEditTopics = ({
  formTopics,
  onChangeFormTopics,
  oneononeTemplate,
  autoFocus,
}: {
  oneononeTemplate: boolean;
  formTopics: EditTemplateTopicType[];
  onChangeFormTopics: (topics: EditTemplateTopicType[]) => void;
  autoFocus: boolean;
}) => {
  const handleChangeTitle = (
    e: ChangeEvent<HTMLTextAreaElement>,
    id: number | string
  ) => {
    const updatedTopics = formTopics.map((topic) => {
      if (topic.id === id) {
        return { ...topic, title: e.target.value };
      }
      return topic;
    });
    onChangeFormTopics(updatedTopics);
  };

  const handleChangeTopicRequirements = (
    isMandatory: boolean,
    notesRequirement: NotesRequirement,
    id: number | string
  ) => {
    const updatedTopics = formTopics.map((topic) => {
      if (topic.id === id) {
        return {
          ...topic,
          isMandatory: isMandatory,
          notesRequirement: notesRequirement,
        };
      }
      return topic;
    });
    onChangeFormTopics(updatedTopics);
  };

  const handleChangeDefaultSubjectNotes = (
    defaultSubjectNotes: JSONContent,
    id: number | string
  ) => {
    const updatedTopics = formTopics.map((topic) => {
      if (topic.id === id) {
        return { ...topic, defaultSubjectNotes };
      }
      return topic;
    });
    onChangeFormTopics(updatedTopics);
  };

  const handleChangeDefaultFacilitatorNotes = (
    defaultFacilitatorNotes: JSONContent,
    id: number | string
  ) => {
    const updatedTopics = formTopics.map((topic) => {
      if (topic.id === id) {
        return { ...topic, defaultFacilitatorNotes };
      }
      return topic;
    });
    onChangeFormTopics(updatedTopics);
  };

  const handleChangeDiscussionNotes = (
    discussionNotes: JSONContent,
    id: number | string
  ) => {
    const updatedTopics = formTopics.map((topic) => {
      if (topic.id === id) {
        return { ...topic, discussionNotes };
      }
      return topic;
    });
    onChangeFormTopics(updatedTopics);
  };

  const handleChangeIncludesIndividualNotes = (
    e: ChangeEvent<HTMLInputElement>,
    id: number | string
  ) => {
    const updatedTopics = formTopics.map((topic) => {
      if (topic.id === id) {
        return {
          ...topic,
          includesIndividualNotes: e.target.checked,
          includesSharedNotes:
            !e.target.checked && !topic.includesSharedNotes
              ? true
              : topic.includesSharedNotes,
        };
      }
      return topic;
    });
    onChangeFormTopics(updatedTopics);
  };

  const handleChangeIncludesSharedNotes = (
    e: ChangeEvent<HTMLInputElement>,
    id: number | string
  ) => {
    const updatedTopics = formTopics.map((topic) => {
      if (topic.id === id) {
        return {
          ...topic,
          includesSharedNotes: e.target.checked,
          includesIndividualNotes:
            !e.target.checked && !topic.includesIndividualNotes
              ? true
              : topic.includesIndividualNotes,
        };
      }
      return topic;
    });
    onChangeFormTopics(updatedTopics);
  };

  const handleDelete = (id: number | string) => {
    const updatedTopics = formTopics.filter((topic) => topic.id !== id);
    onChangeFormTopics(updatedTopics);
  };

  const handleDragEnd = ({
    destination,
    source,
    reason,
  }: {
    destination: any;
    source: any;
    reason: string;
  }) => {
    if (reason === "DROP") {
      const newFormTopics = [...formTopics];
      const elm = newFormTopics.splice(source.index, 1)[0];
      newFormTopics.splice(destination.index, 0, elm);
      onChangeFormTopics(newFormTopics);
    }
  };

  return (
    <div>
      {formTopics.length > 0 && (
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <ul
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="divide-y"
                aria-label="Template topic list"
              >
                {formTopics.map((formTopic, index) => (
                  <EditTopic
                    key={formTopic.id}
                    index={index}
                    showDelete={
                      formTopics.length > 0 &&
                      formTopic.id !== formTopics[formTopics.length - 1].id // cannot delete last one
                    }
                    topic={formTopic}
                    oneononeTemplate={oneononeTemplate}
                    onChangeTitle={handleChangeTitle}
                    onChangeDiscussionNotes={handleChangeDiscussionNotes}
                    onChangeDefaultSubjectNotes={
                      handleChangeDefaultSubjectNotes
                    }
                    onChangeDefaultFacilitatorNotes={
                      handleChangeDefaultFacilitatorNotes
                    }
                    onChangeTopicRequirements={handleChangeTopicRequirements}
                    onChangeIncludesIndividualNotes={
                      handleChangeIncludesIndividualNotes
                    }
                    onChangeIncludesSharedNotes={
                      handleChangeIncludesSharedNotes
                    }
                    onDelete={handleDelete}
                    autoFocus={autoFocus}
                  />
                ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </div>
  );
};

export default TemplateEditTopics;
