import { gql } from "@apollo/client";

import { ArtifactActivityFragment } from "@apps/artifact-sidebar/graphql/get-artifact-activities-query";
import KeyResultFragment from "@graphql/key-result-fragment";

export default gql`
  ${ArtifactActivityFragment}
  ${KeyResultFragment}
  query getLastCheckinActivityQuery($artifactId: Int!) {
    artifact(artifactId: $artifactId) {
      id
      ... on GoalArtifactNode {
        currentValue
        targetValue
        progress
        startValue

        keyResults {
          edges {
            node {
              ...KeyResultFragment
            }
          }
        }
        canUpdate {
          permission
        }
        activities(first: 1, activityType: goal_checkin) {
          edges {
            node {
              ...ArtifactActivityFragment
            }
          }
        }
      }
    }
  }
`;
