import cache from "@cache/cache";

export function deleteArtifactFromCache({
  artifact,
}: {
  artifact: { id: number };
}) {
  const artifactCacheId = cache.identify(artifact);

  // delete artifact
  if (artifactCacheId) {
    cache.evict({ id: artifactCacheId });
    const baseArtifactCacheId = cache.identify({
      __typename: "BaseArtifactNode",
      id: artifact.id,
    });
    cache.evict({ id: baseArtifactCacheId });
    cache.gc();
  }
}
