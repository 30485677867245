import { sumBy } from "lodash";
import { ReactElement } from "react";
import { Cell, Pie, PieChart, Tooltip } from "recharts";
import {
  ArtifactType,
  GoalOverviewGoalFragmentFragment,
  GoalScope,
  GoalState,
  GoalStatus,
} from "types/graphql-schema";
import { DateRangeEnum } from "types/topicflow";

import {
  ExplorerFilterType,
  getExplorerFiltersUrl,
} from "@apps/explorer/helpers";
import useUiPreferenceCache, {
  GoalOverviewGoalRoleFilterEnum,
} from "@apps/use-ui-preference-cache/use-ui-preference-cache";
import GoalStatusPill from "@components/goal-status-pill/goal-status-pill";
import AppLink, { useLink } from "@components/link/link";
import {
  UserComboboxOption,
  UserComboboxOptionType,
} from "@components/user-combobox/user-combobox-list";

const StatContainer = ({
  children,
  label,
}: {
  children: ReactElement;
  label: string;
}) => (
  <div className="bg-white shadow rounded-md px-4 py-3 flex flex-col gap-3">
    <div className="text-gray-400 uppercase text-xs">{label}</div>
    {children}
  </div>
);

const GoalOverviewGoalStats = ({
  goals,
  selected,
  selectedDateRange,
}: {
  goals: GoalOverviewGoalFragmentFragment[];
  selected: UserComboboxOption;
  selectedDateRange: DateRangeEnum;
}) => {
  const { uiPreferenceCache } = useUiPreferenceCache();
  const link = useLink();

  const draftObjectives = goals.filter(
    (objective) => objective.state === GoalState.Draft
  );
  const openObjectives = goals.filter(
    (objective) => objective.state === GoalState.Open
  );
  const closedObjectives = goals.filter(
    (objective) => objective.state === GoalState.Closed
  );
  const objectivesWithNoStatus = goals.filter(
    (objective) => objective.status === GoalStatus.None
  );
  const objectivesAtRisk = goals.filter(
    (objective) => objective.status === GoalStatus.AtRisk
  );
  const objectivesOnTrack = goals.filter(
    (objective) => objective.status === GoalStatus.OnTrack
  );
  const objectivesOffTrack = goals.filter(
    (objective) => objective.status === GoalStatus.OffTrack
  );
  const sumProgress = sumBy(goals, "progress");
  const overallProgress =
    goals.length === 0 ? 0 : Math.round(sumProgress / goals.length);
  const overallProgressPercentage = Math.max(0, Math.min(100, overallProgress));

  const commonFilters = {
    type: ArtifactType.Goal,
    dueBetweenDates: selectedDateRange,
    ...(selected.type === UserComboboxOptionType.TEAM
      ? { goalScope: GoalScope.Team }
      : {}),
    ...(selected.type === UserComboboxOptionType.ORG
      ? { goalScope: GoalScope.Organization }
      : {}),
    ...(selected.type === UserComboboxOptionType.USER
      ? {
          ...(uiPreferenceCache.goalOverviewGoalRoleFilter ===
          GoalOverviewGoalRoleFilterEnum.owners
            ? { goalOwners: [selected.id] }
            : {}),
          ...(uiPreferenceCache.goalOverviewGoalRoleFilter ===
          GoalOverviewGoalRoleFilterEnum.contributors
            ? { goalContributors: [selected.id] }
            : {}),
          ...(uiPreferenceCache.goalOverviewGoalRoleFilter ===
          GoalOverviewGoalRoleFilterEnum.both
            ? { goalInvolvingUsers: [selected.id] }
            : {}),
        }
      : {}),
    ...(selected.type === UserComboboxOptionType.TEAM
      ? { goalTeams: [selected.id] }
      : {}),
  } as ExplorerFilterType;

  const statePieChartData = [
    {
      name: "Draft",
      value: draftObjectives.length,
      color: "#e2e8f0",
      url: getExplorerFiltersUrl({
        ...commonFilters,
        goalState: [GoalState.Draft],
      }),
    },
    {
      name: "Open",
      value: openObjectives.length,
      color: "#94a3b8",
      url: getExplorerFiltersUrl({
        ...commonFilters,
        goalState: [GoalState.Open],
        goalScope: GoalScope.Personal,
      }),
    },
    {
      name: "Closed",
      value: closedObjectives.length,
      color: "#475569",
      url: getExplorerFiltersUrl({
        ...commonFilters,
        goalState: [GoalState.Closed],
      }),
    },
  ];

  const statusPieChartData = [
    {
      name: "No updates",
      value: objectivesWithNoStatus.length,
      color: "#9ca3af",
      status: GoalStatus.None,
      url: getExplorerFiltersUrl({
        ...commonFilters,
        goalStatus: GoalStatus.None,
      }),
    },
    {
      name: "Off track",
      value: objectivesOffTrack.length,
      color: "#e11d48",
      status: GoalStatus.OffTrack,
      url: getExplorerFiltersUrl({
        ...commonFilters,
        goalStatus: GoalStatus.OffTrack,
      }),
    },
    {
      name: "At risk",
      value: objectivesAtRisk.length,
      color: "#f59e0b",
      status: GoalStatus.AtRisk,
      url: getExplorerFiltersUrl({
        ...commonFilters,
        goalStatus: GoalStatus.AtRisk,
      }),
    },
    {
      name: "On track",
      value: objectivesOnTrack.length,
      color: "#059669",
      status: GoalStatus.OnTrack,
      url: getExplorerFiltersUrl({
        ...commonFilters,
        goalStatus: GoalStatus.OnTrack,
      }),
    },
  ];

  const handleClickPie = ({ url }: { url: string }) => {
    link.redirect(url);
  };

  return (
    <div className="grid grid-cols-3 gap-4">
      <StatContainer label="Overall progress">
        <div className="flex-1 flex items-end w-full">
          <div className="flex w-full flex-col gap-2 pb-2">
            <div className="text-3xl font-medium">
              {overallProgressPercentage}%
            </div>
            <div
              className="w-full bg-gray-100 shadow-sm rounded-full h-4 m-0 p-0 relative overflow-hidden group/goal-progress"
              aria-label="Goal progress bar container"
            >
              <div
                className="bg-tfcolor h-4"
                style={{
                  width:
                    overallProgressPercentage === 0
                      ? "0%"
                      : `${Math.max(3, overallProgressPercentage)}%`,
                }}
              />
            </div>
          </div>
        </div>
      </StatContainer>
      <StatContainer label="State">
        <div className="flex justify-between gap-4">
          <div className="shrink-0 text-sm text-gray-500">
            {statePieChartData.map((pieChartData) => (
              <AppLink
                key={pieChartData.name}
                to={pieChartData.url}
                className="flex items-center gap-1.5 hover:underline"
              >
                <span>
                  {pieChartData.name}:{" "}
                  <span className="text-black">{pieChartData.value}</span>
                </span>
              </AppLink>
            ))}
          </div>
          <PieChart width={80} height={80}>
            <Pie
              data={statePieChartData}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={40}
              innerRadius={26}
              fill="#8884d8"
              onClick={handleClickPie}
            >
              {statePieChartData.map((pieChartData) => (
                <Cell
                  key={`cell-${pieChartData.name}`}
                  fill={pieChartData.color}
                />
              ))}
            </Pie>
            <Tooltip contentStyle={{ padding: "0 10px", fontSize: "12px" }} />
          </PieChart>
        </div>
      </StatContainer>
      <StatContainer label="Status">
        <div className="flex justify-between gap-4">
          <div className="shrink-0 text-sm text-gray-500">
            {statusPieChartData.map((pieChartData) => (
              <AppLink
                key={pieChartData.name}
                to={pieChartData.url}
                className="flex items-center gap-1.5 hover:underline"
              >
                <GoalStatusPill
                  state={GoalState.Open}
                  status={pieChartData.status}
                />
                <span>
                  {pieChartData.name}:{" "}
                  <span className="text-black">{pieChartData.value}</span>
                </span>
              </AppLink>
            ))}
          </div>
          <PieChart width={80} height={80}>
            <Pie
              data={statusPieChartData}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={40}
              innerRadius={26}
              fill="#8884d8"
              onClick={handleClickPie}
            >
              {statusPieChartData.map((pieChartData) => (
                <Cell
                  key={`cell-${pieChartData.name}`}
                  fill={pieChartData.color}
                />
              ))}
            </Pie>
            <Tooltip contentStyle={{ padding: "0 10px", fontSize: "12px" }} />
          </PieChart>
        </div>
      </StatContainer>
    </div>
  );
};

export default GoalOverviewGoalStats;
