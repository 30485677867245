import { gql } from "@apollo/client";

export const ActivityCommentFragment = gql`
  fragment ActivityCommentFragment on CommentNode {
    id
    comment
    deleted
    creator {
      id
    }
    creator {
      id
      name
      avatar
    }
  }
`;

export const ArtifactActivityFragment = gql`
  ${ActivityCommentFragment}
  fragment ArtifactActivityFragment on ArtifactActivityNode {
    id
    created
    canDelete {
      permission
      reason
    }
    canUpdate {
      permission
      reason
    }
    activityType
    actor {
      id
      name
      avatar
    }
    changedFields {
      fieldName
      changedFields {
        newValue
        previousValue
        fieldName
      }
      previousValue
      newValue
      newValueObject {
        ... on CommentNode {
          ...ActivityCommentFragment
        }
        ... on UserNode {
          id
          name
          avatar
        }
        ... on KeyResultNode {
          id
          title
        }
        ... on TopicNode {
          id
          title
          meeting {
            id
            title
            meetingGroupId
          }
        }
        ... on BaseArtifactNode {
          id
          title
          artifactType
        }
      }
      previousValueObject {
        ... on KeyResultNode {
          id
          title
        }
        ... on UserNode {
          id
          name
          avatar
        }
        ... on TopicNode {
          id
          title
          meeting {
            id
            title
            meetingGroupId
          }
        }
        ... on BaseArtifactNode {
          id
          title
          artifactType
        }
      }
    }
  }
`;

export default gql`
  ${ArtifactActivityFragment}
  query getArtifactActivitiesQuery(
    $artifactId: Int!
    $first: Int = 10
    $after: String
  ) {
    artifact(artifactId: $artifactId) {
      id
      activities(first: $first, after: $after) {
        pageInfo {
          hasNextPage
          endCursor
        }
        totalCount
        edges {
          node {
            ...ArtifactActivityFragment
          }
        }
      }
    }
  }
`;
