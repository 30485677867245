import { isEqual } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { TbFilter } from "react-icons/tb";
import { useLocation } from "react-router-dom";
import store from "store2";
import { TFLocationState } from "types/topicflow";

import { useLink } from "@components/link/link";
import useDebounce from "@components/use-debounce/use-debounce";
import { buttonBorderClassName, classNames } from "@helpers/css";
import { isBackgroundLocation } from "@helpers/helpers";

import FilterModal from "./components/filter-modal";
import ExplorerFilters from "./components/filters";
import ExplorerContent from "./explorer-content";
import {
  ExplorerFilterType,
  getDefaultFilters,
  getExplorerFiltersUrl,
  getSanitizedExplorerFilters,
  getSanitizedExplorerFiltersFromUrlSearch,
} from "./helpers";
import useExplorerQuery from "./use-explorer-query";

const Explorer = () => {
  const [showFilterDialog, setShowFilterDialog] = useState(false);
  const location = useLocation<TFLocationState>();
  const { replace } = useLink();
  const savedFilters = store("explorer:filter");

  const getFilters = useCallback(() => {
    const params = new URLSearchParams(location.search);
    if (params.toString()) {
      return getSanitizedExplorerFiltersFromUrlSearch(location.search);
    } else if (savedFilters) {
      return getSanitizedExplorerFilters(savedFilters);
    } else {
      return getDefaultFilters();
    }
  }, [location.search, savedFilters]);

  const defaultFilters = getDefaultFilters();
  const [filters, setFilters] = useState(getFilters());

  const debouncedSearch = useDebounce(filters.search, 500);
  const {
    needSearchQueryForIntegrations,
    loading,
    loadingFirstTime,
    data,
    fetchMore,
    results,
    pageInfo,
  } = useExplorerQuery({ ...filters, search: debouncedSearch }, 20);

  const handleClickMore = () => {
    fetchMore({
      variables: { after: pageInfo.endCursor, merge: true },
    });
  };

  const handleChangeFilters = useCallback(
    (updatedFilters: ExplorerFilterType) => {
      const newFilters = {
        ...defaultFilters,
        ...filters,
        ...updatedFilters,
      };
      setFilters(getSanitizedExplorerFilters(newFilters));
    },
    [defaultFilters, filters]
  );

  const handleClickShowFilterDialog = () => {
    setShowFilterDialog(true);
  };

  const handleClickBookmark = (updatedFilters: ExplorerFilterType) => {
    handleChangeFilters(updatedFilters);
    setShowFilterDialog(false);
  };

  const handleClickCloseFilterDialog = () => {
    setShowFilterDialog(false);
  };

  const handleClickCloseAndSaveFilterDialog = (
    updatedFilters: ExplorerFilterType
  ) => {
    handleChangeFilters(updatedFilters);
    setShowFilterDialog(false);
  };

  // if new router url is different than current explorer url
  // we update filters
  useEffect(() => {
    const newFilters = getFilters();
    if (
      !isEqual(newFilters, filters) &&
      location.pathname.startsWith("/explorer")
    ) {
      handleChangeFilters(getFilters());
    }
  }, [location]);

  useEffect(() => {
    const handleFetchMore = () => {
      if (
        pageInfo?.hasNextPage &&
        !loading &&
        Math.ceil(window.innerHeight + window.scrollY) >=
          document.documentElement.scrollHeight
      ) {
        fetchMore({
          variables: { after: pageInfo.endCursor, merge: true },
        });
      }
    };

    window.addEventListener("scroll", handleFetchMore);
    handleFetchMore();
    return function cleanup() {
      window.removeEventListener("scroll", handleFetchMore);
    };
  }, [data, fetchMore, loading, pageInfo?.endCursor, pageInfo?.hasNextPage]);

  useEffect(() => {
    if (isBackgroundLocation(location)) {
      store("explorer:filter", filters);
      replace(getExplorerFiltersUrl(filters));
    }
  }, [filters, replace]);

  // RENDER
  return (
    <div className="p-4 sm:p-6 flex flex-col gap-4">
      {showFilterDialog && (
        <FilterModal
          filters={filters}
          onClose={handleClickCloseFilterDialog}
          onChangeFilters={handleClickCloseAndSaveFilterDialog}
          onClickBookmark={handleClickBookmark}
        />
      )}
      <div className="@4xl:hidden">
        <button
          onClick={handleClickShowFilterDialog}
          className={classNames(
            "text-sm flex items-center gap-1 px-2.5 py-1 bg-white shadow-sm",
            buttonBorderClassName
          )}
        >
          <TbFilter className="w-4 h-4 text-gray-500" />
          <span className="">Filters</span>
        </button>
      </div>
      <div className="flex gap-6">
        <div className="w-64 hidden @4xl:flex flex-col gap-6">
          <ExplorerFilters
            filters={filters}
            onChangeFilters={handleChangeFilters}
          />
        </div>
        <ExplorerContent
          className="flex-1"
          needSearchQueryForIntegrations={needSearchQueryForIntegrations}
          loadingFirstTime={loadingFirstTime}
          loading={loading}
          filters={filters}
          results={results}
          pageInfo={pageInfo}
          onClickMore={handleClickMore}
        />
      </div>
    </div>
  );
};

export default Explorer;
