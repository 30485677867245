import { SearchIcon } from "@heroicons/react/outline";
import { useState } from "react";
import { TbPlus } from "react-icons/tb";
import { useLocation } from "react-router-dom";
import { CreatedArtifactFragmentFragment } from "types/graphql-schema";
import { TFLocationState } from "types/topicflow";

import ArtifactCreationDialog from "@apps/artifact-creation-dialog/artifact-creation-dialog";
import { currentOrganizationVar } from "@cache/cache";
import { useLink } from "@components/link/link";
import Tooltip from "@components/tooltip/tooltip";
import { classNames } from "@helpers/css";
import { getUrl, toWithBackground } from "@helpers/helpers";

const LayoutAddSearch = ({
  onShowSearchModal,
  menuIsCollapsed,
}: {
  menuIsCollapsed: boolean;
  onShowSearchModal: () => void;
}) => {
  const location = useLocation<TFLocationState>();
  const currentOrganization = currentOrganizationVar();
  const [showCreationDialog, setShowCreationDialog] = useState(false);

  const link = useLink();
  const handleCloseCreationDialog = (
    artifact?: CreatedArtifactFragmentFragment
  ) => {
    setShowCreationDialog(false);
    if (artifact) {
      link.redirect(
        toWithBackground({
          pathname: getUrl({
            artifactId: artifact.id,
            artifactType: artifact.artifactType,
          }),
          location,
        })
      );
    }
  };

  return (
    <div
      className={classNames(
        "flex items-center gap-1",
        menuIsCollapsed && "hidden"
      )}
    >
      {currentOrganization.featureFlags.explorer && (
        <Tooltip text="Search">
          <button
            className="rounded-lg py-1 px-1 text-gray-400 hover:bg-gray-100 hover:text-gray-500"
            onClick={onShowSearchModal}
            aria-label="Sidebar search button"
          >
            <SearchIcon className="h-6 w-6" />
          </button>
        </Tooltip>
      )}
      <Tooltip text="Create">
        <div className="flex items-center">
          <button
            className="rounded-lg py-1 px-1 text-gray-400 hover:bg-gray-100 hover:text-gray-500"
            aria-label="Sidebar create artifact button"
            onClick={() => setShowCreationDialog(true)}
          >
            <TbPlus className="h-6 w-6" />
          </button>
        </div>
      </Tooltip>
      {showCreationDialog && (
        <ArtifactCreationDialog onClose={handleCloseCreationDialog} />
      )}
    </div>
  );
};

export default LayoutAddSearch;
