import { Node, mergeAttributes } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";
import { ArtifactType } from "types/graphql-schema";
import { v4 as uuidv4 } from "uuid";

import { clientUuidVar, currentUserVar } from "@cache/cache";
import { getPluginClickOn } from "@components/wysiwyg/helpers";

import artifactInputRule from "../artifact-input-rule";
import ArtifactComponent from "./artifact-component";
import {
  RegexpArtifactType,
  artifactInputRegex,
  baseArtifactAttributes,
  baseArtifactOptions,
  baseArtifactParseHtml,
  baseArtifactRenderHtml,
  getBaseArtifactPluginKeyDown,
  getBaseArtifactPluginPaste,
} from "./base-artifact-extension";

export default Node.create({
  group: "block",
  marks: "",
  draggable: true,
  selectable: true,
  allowGapCursor: true,
  atom: true,
  isolating: true,
  defining: true,
  name: ArtifactType.Decision,

  addOptions() {
    return baseArtifactOptions;
  },

  addAttributes() {
    return baseArtifactAttributes(this.name as RegexpArtifactType);
  },

  addInputRules() {
    if (this.options.permission) {
      return [
        artifactInputRule({
          find: artifactInputRegex[ArtifactType.Decision],
          type: this.type,
          getAttributes: () => {
            const currentUser = currentUserVar();
            return {
              createdByUser: currentUser,
              uuid: uuidv4(),
              clientUuid: clientUuidVar(),
            };
          },
        }),
      ];
    }
    return [];
  },

  parseHTML() {
    return baseArtifactParseHtml(this.name as RegexpArtifactType);
  },

  renderText({ node }: { node: any }) {
    return baseArtifactRenderHtml(node, this.name as RegexpArtifactType);
  },

  renderHTML({ HTMLAttributes }: { HTMLAttributes: any }) {
    return ["div", mergeAttributes(HTMLAttributes)];
  },

  addNodeView() {
    return ReactNodeViewRenderer(ArtifactComponent);
  },

  addProseMirrorPlugins() {
    return [
      getBaseArtifactPluginKeyDown(this.editor, this.name),
      getPluginClickOn(this.name),
      getBaseArtifactPluginPaste(this),
    ];
  },

  addCommands() {
    return {
      insertDecision:
        (attrs: any) =>
        ({ chain }: { chain: any }) => {
          if (this.options.permission) {
            return chain()
              .insertContent({
                type: this.name,
                attrs: { uuid: uuidv4(), ...attrs },
              })
              .run();
          }
        },
    };
  },
});
