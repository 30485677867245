import { useQuery } from "@apollo/client";
import pluralize from "pluralize";
import {
  GetRatingArtifactAnswersQuery,
  GetRatingArtifactAnswersQueryVariables,
} from "types/graphql-schema";

import CloseButton from "@components/close-button/close-button";
import Loading from "@components/loading/loading";
import Modal from "@components/modal/modal";
import {
  Table,
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableContainer,
  TableHeadCell,
  TableHeadRow,
} from "@components/table/table";
import TimeAgoCustom from "@components/time-ago/time-ago";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { assertNonNull } from "@helpers/helpers";

import getRatingArtifactAnswersQuery from "../graphql/get-rating-artifact-answers-query";

const RatingAnswersModal = ({
  rating,
  artifact,
  onClose,
}: {
  rating: any;
  artifact: any;
  onClose: () => void;
}) => {
  const { data, loading } = useQuery<
    GetRatingArtifactAnswersQuery,
    GetRatingArtifactAnswersQueryVariables
  >(getRatingArtifactAnswersQuery, {
    variables: { artifactId: artifact.id },
    onError: onNotificationErrorHandler(),
  });

  const ratingArtifact =
    data && data.artifact.__typename === "RatingArtifactNode"
      ? data.artifact
      : null;

  return (
    <Modal onClose={onClose} open>
      <div className="p-6 flex flex-col gap-6">
        <div className="flex justify-between items-center">
          <div className="font-medium text-xl">{rating.title}</div>
          <CloseButton onClick={onClose} />
        </div>
        <div>
          {loading || !ratingArtifact ? (
            <Loading>Loading answers</Loading>
          ) : (
            <div className="flex flex-col gap-2">
              <div className="text-sm font-medium">{`${
                ratingArtifact.answers?.totalCount
              } ${pluralize(
                "answers",
                ratingArtifact.answers?.totalCount ?? 0
              )}`}</div>
              <div className="text-sm">
                {ratingArtifact.answers?.edges &&
                ratingArtifact.answers?.edges.length > 0 ? (
                  <TableContainer>
                    <Table>
                      <TableHeadRow>
                        <TableHeadCell>Name</TableHeadCell>
                        <TableHeadCell>Answer</TableHeadCell>
                        <TableHeadCell>Created</TableHeadCell>
                      </TableHeadRow>
                      <TableBody>
                        {ratingArtifact.answers?.edges.map((edge) => (
                          <TableBodyRow key={edge?.node?.id}>
                            <TableBodyCell className="text-gray-800">
                              {edge?.node?.creator.name}
                            </TableBodyCell>
                            <TableBodyCell>
                              {edge?.node?.integerAnswer}
                            </TableBodyCell>
                            <TableBodyCell>
                              <TimeAgoCustom
                                date={assertNonNull(edge?.node?.created)}
                              />
                            </TableBodyCell>
                          </TableBodyRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <div className="text-gray-500 italic">No answers</div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default RatingAnswersModal;
