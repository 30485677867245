import { useMutation } from "@apollo/client";
import { InformationCircleIcon } from "@heroicons/react/outline";
import { compact } from "lodash";
import moment from "moment/moment";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import {
  AccountToOrganizationMembershipStatus,
  InviteUsersMutation,
  InviteUsersMutationVariables,
  OrganizationMemberFragment,
  OrganizationRole,
  RemoveUserFromOrganizationMutationMutation,
  RemoveUserFromOrganizationMutationMutationVariables,
  UpdateMemberMutationMutation,
  UpdateMemberMutationMutationVariables,
  UserStatus,
} from "types/graphql-schema";

import inviteUsersMutation from "@apps/invite-dialog/graphql/invite-users-mutation";
import {
  currentUserVar,
  errorNotificationVar,
  successNotificationVar,
} from "@cache/cache";
import Avatar from "@components/avatar/avatar";
import Dropdown from "@components/dropdown/dropdown";
import Input from "@components/input/input";
import Loading from "@components/loading/loading";
import Modal from "@components/modal/modal";
import ModalTitle from "@components/modal/modal-title";
import Select, { SelectOption } from "@components/select/select";
import { TableBodyCell, TableBodyRow } from "@components/table/table";
import Tooltip from "@components/tooltip/tooltip";
import useDebounce from "@components/use-debounce/use-debounce";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { lastSeenStr } from "@helpers/constants";
import { classNames, inputBorderClassName } from "@helpers/css";
import { assertEdgesNonNull, assertNonNull } from "@helpers/helpers";
import useConfirm from "@helpers/hooks/use-confirm";

import getOrganizationMembersQuery from "../graphql/get-organization-members-query";
import removeUserFromOrganizationMutation from "../graphql/remove-user-from-organization-mutation";
import updateMemberMutation from "../graphql/update-member-mutation";
import MemberManagerCombobox from "./member-manager-combobox";

const adminOption = {
  value: OrganizationRole.Administrator,
  label: "Admin",
};
const memberOption = { value: OrganizationRole.Member, label: "Member" };
const roleOptions = [adminOption, memberOption];

const MemberItem = ({
  organization,
  member,
  onRemoveUser,
}: {
  organization: any;
  member: OrganizationMemberFragment;
  onRemoveUser: (member: OrganizationMemberFragment) => void;
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [updateMember, { loading: loadingMutation }] = useMutation<
    UpdateMemberMutationMutation,
    UpdateMemberMutationMutationVariables
  >(updateMemberMutation);
  const [removeUserFromOrg, { loading: removeLoading }] = useMutation<
    RemoveUserFromOrganizationMutationMutation,
    RemoveUserFromOrganizationMutationMutationVariables
  >(removeUserFromOrganizationMutation);
  const [inviteUsers] = useMutation<
    InviteUsersMutation,
    InviteUsersMutationVariables
  >(inviteUsersMutation);

  const user = assertNonNull(member.user);
  const userId = user.id;
  const userName = user.name;
  const userEmail = user.email;
  const isActiveMember =
    member.membershipStatus === AccountToOrganizationMembershipStatus.Active;

  const {
    ConfirmationDialog: ConfirmationRemovalDialog,
    confirm: confirmRemoval,
  } = useConfirm(
    "Are you sure?",
    "Are you sure you want to remove this person from the organization?"
  );
  const {
    ConfirmationDialog: ConfirmationDeactivationDialog,
    confirm: confirmDeactivation,
  } = useConfirm(
    "Are you sure?",
    "Are you sure you want to deactivate this person from the organization?"
  );
  const {
    ConfirmationDialog: ConfirmationReactivationDialog,
    confirm: confirmReactivation,
  } = useConfirm(
    "Are you sure?",
    "Are you sure you want to reactivate this person?"
  );
  const [employeeId, setEmployeeId] = useState(member.employeeId);
  const [jobLevel, setJobLevel] = useState(member.jobLevel);
  const [position, setPosition] = useState(member.position);
  const debouncedEmployeeId = useDebounce(employeeId, 300);
  const debouncedJobLevel = useDebounce(jobLevel, 300);
  const debouncedPosition = useDebounce(position, 300);
  const currentUser = currentUserVar();
  const managers = member.user?.managers
    ? assertEdgesNonNull(member.user.managers)
    : [];
  const managerIds = managers.map(({ id }: { id: number }) => id);
  const excludeUserIds = [userId, ...managerIds];

  const handleChangeRole = (option: SelectOption<OrganizationRole>) => {
    updateMember({
      variables: {
        organizationId: organization.id,
        role: option.value,
        userId: userId,
      },
      onError: onNotificationErrorHandler(),
    });
  };

  const handleChangeMembershipStatus = (
    membershipStatus: AccountToOrganizationMembershipStatus
  ) => {
    updateMember({
      variables: {
        organizationId: organization.id,
        membershipStatus,
        userId,
      },
      onError: onNotificationErrorHandler(),
      refetchQueries: [getOrganizationMembersQuery],
    });
  };

  const saveChanges = () => {
    if (
      debouncedEmployeeId !== member.employeeId ||
      debouncedPosition !== member.position ||
      debouncedJobLevel !== member.jobLevel
    ) {
      updateMember({
        variables: {
          organizationId: organization.id,
          userId: userId,
          employeeId: employeeId,
          position: position,
          jobLevel: jobLevel,
        },
        onError: onNotificationErrorHandler(),
      });
    }
  };

  useEffect(() => {
    saveChanges();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedEmployeeId, debouncedPosition, debouncedJobLevel]);

  const handleClickRemoveUser = async () => {
    const isConfirmed = await confirmRemoval();
    if (isConfirmed) {
      removeUserFromOrg({
        variables: {
          organizationId: organization.id,
          userToRemoveId: userId,
        },
        onError: onNotificationErrorHandler(),
        onCompleted: () => {
          successNotificationVar({
            title: "User removed.",
            timeout: 1000,
          });
          onRemoveUser(member);
        },
      });
    }
  };

  const handleClickDeactivateUser = async () => {
    const isConfirmed = await confirmDeactivation();
    if (isConfirmed) {
      handleChangeMembershipStatus(
        AccountToOrganizationMembershipStatus.Inactive
      );
    }
  };
  const handleClickReactivateUser = async () => {
    const isConfirmed = await confirmReactivation();
    if (isConfirmed) {
      handleChangeMembershipStatus(
        AccountToOrganizationMembershipStatus.Active
      );
    }
  };

  const handleChangeStartdate = (date: Date | null) => {
    updateMember({
      variables: {
        organizationId: organization.id,
        startDate: date ? moment(date).format("YYYY-MM-DD") : "-1",
        userId: userId,
      },
      onError: onNotificationErrorHandler(),
    });
  };

  const handleClickInvite = () => {
    inviteUsers({
      variables: {
        invitationRequests: [
          {
            inviteeEmail: userEmail,
            inviteMessage: "",
            role: member.role || OrganizationRole.Member,
            organizationId: organization.id,
          },
        ],
      },
      onError: onNotificationErrorHandler(),
      onCompleted: (data) => {
        const error = data?.createInvitations?.invitationErrors?.[0];
        if (error) {
          errorNotificationVar({
            title: `${userEmail} could not be invited`,
            description: error.message || "",
          });
        } else {
          successNotificationVar({ title: "User invitation sent." });
        }
      },
    });
  };

  return (
    <TableBodyRow
      data-testid={userId}
      aria-label={`Org settings member: ${userName}`}
      className={classNames(!isActiveMember && "bg-gray-100")}
    >
      <TableBodyCell>
        <ConfirmationRemovalDialog />
        <ConfirmationDeactivationDialog />
        <ConfirmationReactivationDialog />
        {isEditing && (
          <Modal
            aria-label="Org settings member modal"
            onClose={() => setIsEditing(false)}
          >
            <div className="p-6 flex flex-col gap-4">
              <ModalTitle onClose={() => setIsEditing(false)}>
                <div className="flex items-center gap-2">
                  Edit {userName}
                  {loadingMutation && <Loading mini size={4} />}
                </div>
              </ModalTitle>

              <div className="flex gap-4 items-center">
                <div className="w-24 text-sm font-medium">Managers</div>
                <div className="text-sm flex flex-col gap-2">
                  {managers.map((manager) => (
                    <div className="w-full" key={manager.id}>
                      <MemberManagerCombobox
                        key={manager.id}
                        manager={manager}
                        user={user}
                        organization={organization}
                        excludeUserIds={excludeUserIds}
                      />
                    </div>
                  ))}
                  <div className="w-full" key={null}>
                    <MemberManagerCombobox
                      key={null}
                      manager={null}
                      user={user}
                      organization={organization}
                      excludeUserIds={excludeUserIds}
                    />
                  </div>
                </div>
              </div>
              <div className="flex gap-4 items-center">
                <div className="w-24 text-sm font-medium">Employee ID</div>
                <Input
                  value={employeeId || ""}
                  onChange={(e) => setEmployeeId(e.target.value)}
                  aria-label="Member employee id"
                  placeholder="No id"
                  className="w-36"
                />
              </div>
              <div className="flex gap-4 items-center">
                <div className="w-24 text-sm font-medium">Position</div>
                <Input
                  value={position || ""}
                  onChange={(e) => setPosition(e.target.value)}
                  aria-label="Member position"
                  placeholder="No position"
                  className="w-36"
                />
              </div>
              <div className="flex gap-4 items-center">
                <div className="w-24 text-sm font-medium">Job Level</div>
                <Input
                  value={jobLevel || ""}
                  onChange={(e) => setJobLevel(e.target.value)}
                  aria-label="Member job level"
                  placeholder="No job level"
                  className="w-36"
                />
              </div>
              <div className="flex gap-4 items-center">
                <div className="w-24 text-sm font-medium">Start date</div>
                <DatePicker
                  selected={
                    member.startDate ? moment(member.startDate).toDate() : null
                  }
                  portalId="portal-react-datepicker"
                  popperProps={{ strategy: "fixed" }}
                  onChange={handleChangeStartdate}
                  dateFormat="yyyy-MM-dd"
                  placeholderText="No start date"
                  className={classNames(
                    "w-36 text-sm px-4 py-2",
                    inputBorderClassName
                  )}
                />
              </div>
              <div className="flex gap-4 items-center">
                <div className="w-24 text-sm font-medium">Role</div>
                {currentUser.id === userId ? (
                  <div className="flex items-center gap-1 text-sm text-gray-600">
                    {member.role === OrganizationRole.Administrator
                      ? "Admin"
                      : "Member"}
                    <Tooltip text="You cannot edit your own role.">
                      <InformationCircleIcon className="h-4 w-4 text-gray-500" />
                    </Tooltip>
                  </div>
                ) : (
                  <Select
                    options={roleOptions}
                    value={member.role}
                    width="28"
                    onChange={handleChangeRole}
                    aria-label="Member role select"
                  />
                )}
              </div>
            </div>
          </Modal>
        )}
        <div className="flex text-gray-800 items-center">
          <div className="mr-3 flex-none">
            <Avatar user={user} size="9" />
          </div>
          <div className="tracking-tight flex flex-col justify-center gap-0.5">
            <div className="font-medium">{user.name}</div>
            <div className="text-xs text-gray-600">{user.email}</div>
            <div className="text-xs text-gray-400">
              {user.lastSeenStr ? lastSeenStr[user.lastSeenStr] : ""}
            </div>
          </div>
          {!isActiveMember && (
            <div className="ml-4 p-1.5 rounded-xl bg-gray-200 text-xs font-bold uppercase text-gray-500">
              Deactivated
            </div>
          )}
        </div>
      </TableBodyCell>
      <TableBodyCell className="text-sm">
        {managers.length > 0
          ? managers.map((manager) => (
              <div key={manager.name}>{manager.name}</div>
            ))
          : "No managers"}
      </TableBodyCell>
      <TableBodyCell className="text-sm">{employeeId || "None"}</TableBodyCell>
      <TableBodyCell className="text-sm">{position || "None"}</TableBodyCell>
      <TableBodyCell className="text-sm">{jobLevel || "None"}</TableBodyCell>

      <TableBodyCell className="text-sm">
        {member.startDate
          ? moment(member.startDate).format("LL")
          : "No start date"}
      </TableBodyCell>
      <TableBodyCell className="text-sm">
        <div className="flex gap-2 justify-between items-center">
          {member.role === OrganizationRole.Administrator ? "Admin" : "Member"}
          {removeLoading ? (
            <Loading mini size="5" />
          ) : (
            <Dropdown
              aria-label="Action dropdown"
              options={compact([
                user.status !== UserStatus.Active && {
                  label: "Re-invite",
                  onClick: handleClickInvite,
                },
                {
                  label: "Edit",
                  onClick: () => setIsEditing(true),
                },
                user.status === UserStatus.Active &&
                  isActiveMember &&
                  userId !== currentUser.id && {
                    label: "Deactivate",
                    onClick: handleClickDeactivateUser,
                  },
                user.status === UserStatus.Active &&
                  !isActiveMember && {
                    label: "Reactivate",
                    onClick: handleClickReactivateUser,
                  },
                currentUser.id !== userId && {
                  label: "Remove",
                  onClick: handleClickRemoveUser,
                },
              ])}
            />
          )}
        </div>
      </TableBodyCell>
    </TableBodyRow>
  );
};

export default MemberItem;
