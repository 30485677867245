import {
  ChevronLeftIcon,
  ChevronRightIcon,
  XIcon,
} from "@heroicons/react/outline";
import moment from "moment";

import Tooltip from "@components/tooltip/tooltip";

const DatePickerCustomHeaderWithClearButton = ({
  date,
  onChangeDate,
  clearable,
  currentDate,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}: {
  date: Date | null;
  onChangeDate: (date: Date | null) => void;
  clearable: boolean;
  currentDate: Date;
  decreaseMonth: () => void;
  increaseMonth: () => void;
  prevMonthButtonDisabled: boolean;
  nextMonthButtonDisabled: boolean;
}) => (
  <div className="flex justify-between items-center px-2 py-1">
    <button
      onClick={decreaseMonth}
      disabled={prevMonthButtonDisabled}
      className="py-0.5 px-1 text-gray-600 rounded hover:bg-black/5 hover:text-gray-800"
    >
      <ChevronLeftIcon className="h-5 w-5" />
    </button>
    <div className="font-medium text-sm flex gap-1.5 items-center">
      {moment(currentDate).format("MMMM YYYY")}
      {date && clearable && (
        <Tooltip text="Clear selected date">
          <button
            className="text-gray-400 p-1 rounded-full hover:bg-black/5 hover:text-gray-800"
            onClick={() => onChangeDate(null)}
            aria-label="Clear date button"
          >
            <XIcon className="h-4 w-4" />
          </button>
        </Tooltip>
      )}
    </div>
    <button
      onClick={increaseMonth}
      disabled={nextMonthButtonDisabled}
      className="py-0.5 px-1 text-gray-600 rounded hover:bg-black/5 hover:text-gray-800"
    >
      <ChevronRightIcon className="h-5 w-5" />
    </button>
  </div>
);

export default DatePickerCustomHeaderWithClearButton;
