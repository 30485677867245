import { useMemo } from "react";
import { DashboardInsightFragment } from "types/graphql-schema";
import { BasicUser } from "types/topicflow";

import { currentUserVar } from "@cache/cache";
import { assertNonNull } from "@helpers/helpers";

import { InsightButton, InsightLayout } from "../insight";

const OfftrackGoalInsight = ({
  selectedUser,
  insight,
  onDismissInsight,
}: {
  selectedUser: BasicUser;
  insight: DashboardInsightFragment;
  onDismissInsight: () => void;
}) => {
  const currentUser = currentUserVar();
  const report = useMemo(() => assertNonNull(insight.report), [insight]);
  return (
    <InsightLayout
      user={report}
      onDismissInsight={onDismissInsight}
      isRequired={!!insight.complianceProgram?.id}
    >
      <div className="flex-1">
        <div>
          {currentUser.id === selectedUser.id
            ? "You have "
            : `${selectedUser.name} has `}
          off-track goals.
        </div>
      </div>
      <div>
        <InsightButton
          url={`/explorer?type=goal&goalOwners=${selectedUser.id}&goalStatus=off_track`}
        >
          View
        </InsightButton>
      </div>
    </InsightLayout>
  );
};

export default OfftrackGoalInsight;
