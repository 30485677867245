import { gql } from "@apollo/client";

export default gql`
  query getRatings {
    ratings {
      edges {
        node {
          id
          title
          created
          creator {
            id
            name
          }
          answers {
            totalCount
          }
        }
      }
    }
  }
`;
