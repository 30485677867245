import { gql } from "@apollo/client";

export default gql`
  mutation deleteSlackNotificationForChannelMutation($notificationId: Int!) {
    deleteSlackNotificationForChannel(notificationId: $notificationId) {
      organization {
        id
        slackNotifications {
          edges {
            node {
              id
              channel
              recognitions
              goalCheckins
            }
          }
        }
      }
    }
  }
`;
