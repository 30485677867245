import { gql } from "@apollo/client";

export default gql`
  mutation hideSuggestedArtifactMutation($suggestedArtifactId: Int!) {
    updateSuggestedArtifact(
      suggestedArtifactId: $suggestedArtifactId
      hidden: true
    ) {
      suggestedArtifact {
        id
        hidden
      }
    }
  }
`;
