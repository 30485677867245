import { useQuery } from "@apollo/client";
import { withErrorBoundary } from "@sentry/react";
import { sortBy } from "lodash";
import { useMemo } from "react";
import {
  GetDashboardInsightsQuery,
  GetDashboardInsightsQueryVariables,
  InsightType,
} from "types/graphql-schema";
import { BasicUser } from "types/topicflow";

import useUiPreferenceCache from "@apps/use-ui-preference-cache/use-ui-preference-cache";
import Error from "@components/error/error";
import Layout from "@components/layout/layout";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { assertEdgesNonNull } from "@helpers/helpers";

import getDashboardInsightsQuery from "../graphql/get-dashboard-insights-query";
import Insight from "./insight";

const requestTypes = [InsightType.FeedbackRequest];

const Insights = ({ selectedUser }: { selectedUser: BasicUser }) => {
  const { uiPreferenceCache } = useUiPreferenceCache();
  const { data, loading } = useQuery<
    GetDashboardInsightsQuery,
    GetDashboardInsightsQueryVariables
  >(getDashboardInsightsQuery, {
    notifyOnNetworkStatusChange: true,
    skip: !uiPreferenceCache.dashboardRequirementsContainerExpanded,
    fetchPolicy: "network-only",
    variables: { personId: selectedUser.id },
    onError: onNotificationErrorHandler(),
  });
  const allInsights = useMemo(() => {
    const userInsights = data?.user?.insights
      ? assertEdgesNonNull(data.user.insights)
      : [];
    return sortBy(userInsights, "type");
  }, [data]);

  const requirementInsights = useMemo(() => {
    return allInsights.filter(
      (insight) =>
        !!insight.complianceProgram?.id && !requestTypes.includes(insight.type)
    );
  }, [allInsights]);
  const insights = useMemo(() => {
    return allInsights.filter(
      (insight) =>
        !insight.complianceProgram && !requestTypes.includes(insight.type)
    );
  }, [allInsights]);
  const requests = useMemo(() => {
    return allInsights.filter((insight) => requestTypes.includes(insight.type));
  }, [allInsights]);

  return (
    <Layout.MainSection
      title="Requirements & Insights"
      expandedUiPreferenceKey="dashboardRequirementsContainerExpanded"
    >
      <Layout.MainSubSection
        title="Requirements"
        emptyPlaceholder={
          requirementInsights.length === 0 && "No requirements."
        }
        loading={!data && loading}
      >
        <Layout.MainSubSectionList>
          {requirementInsights.map((requirementInsight) => (
            <Layout.MainSubSectionListItem key={requirementInsight.id}>
              <Insight
                insight={requirementInsight}
                selectedUser={selectedUser}
              />
            </Layout.MainSubSectionListItem>
          ))}
        </Layout.MainSubSectionList>
      </Layout.MainSubSection>
      <Layout.MainSubSection
        title="Requests"
        emptyPlaceholder={requests.length === 0 && "No requests."}
        loading={!data && loading}
      >
        <Layout.MainSubSectionList>
          {requests.map((request) => (
            <Layout.MainSubSectionListItem key={request.id}>
              <Insight insight={request} selectedUser={selectedUser} />
            </Layout.MainSubSectionListItem>
          ))}
        </Layout.MainSubSectionList>
      </Layout.MainSubSection>
      <Layout.MainSubSection
        title="Insights"
        emptyPlaceholder={insights.length === 0 && "No insights."}
        loading={!data && loading}
      >
        <Layout.MainSubSectionList>
          {insights.map((insight) => (
            <Layout.MainSubSectionListItem key={insight.id}>
              <Insight insight={insight} selectedUser={selectedUser} />
            </Layout.MainSubSectionListItem>
          ))}
        </Layout.MainSubSectionList>
      </Layout.MainSubSection>
    </Layout.MainSection>
  );
};

export default withErrorBoundary(Insights, {
  fallback: <Error description={"The insights could not be rendered."} />,
});
