import { AlignmentGoalFragmentFragment } from "types/graphql-schema";

import {
  AlignmentChildGoalIdsByParentIdType,
  AlignmentGoalsByIdType,
  isGoalInSearchResultPath,
} from "./goal-alignment";
import GoalAlignmentTreeItem from "./goal-alignment-tree-item";

const GoalAlignmentTree = ({
  goals,
  goalsById,
  childGoalIdsByParentId,
  isShowingKeyResults,
  indent,
  searchResultGoalIds,
  goalIdsInSearchResultPaths,
}: {
  indent: number;
  goals: (AlignmentGoalFragmentFragment & { __typename: "GoalArtifactNode" })[];
  goalsById: AlignmentGoalsByIdType;
  childGoalIdsByParentId: AlignmentChildGoalIdsByParentIdType;
  isShowingKeyResults: boolean;
  searchResultGoalIds?: number[];
  goalIdsInSearchResultPaths?: number[];
}) => {
  const filteredGoals = goals.filter((goal) =>
    isGoalInSearchResultPath(
      goal.id,
      searchResultGoalIds,
      goalIdsInSearchResultPaths
    )
  );
  return (
    <div className="">
      {filteredGoals.map((goal) => (
        <GoalAlignmentTreeItem
          key={goal.id}
          goal={goal}
          goalsById={goalsById}
          childGoalIdsByParentId={childGoalIdsByParentId}
          isShowingKeyResults={isShowingKeyResults}
          indent={indent}
          searchResultGoalIds={searchResultGoalIds}
          goalIdsInSearchResultPaths={goalIdsInSearchResultPaths}
        />
      ))}
    </div>
  );
};

export default GoalAlignmentTree;
