import CommentForm from "@apps/comments/components/comment-form";
import Modal from "@components/modal/modal";
import ModalTitle from "@components/modal/modal-title";

type Props = {
  artifactId: number;
  meetingId?: number;
  meetingGroupId?: number;
  onClose: () => void;
  onSavedComment: (comment: any) => void;
};

const CommentFormDialog: React.FC<Props> = ({
  artifactId,
  meetingId,
  meetingGroupId,
  onClose,
  onSavedComment,
}) => {
  return (
    <Modal open onClose={onClose} alignedTop>
      <div className="p-6 flex flex-col gap-4">
        <ModalTitle onClose={onClose}>Add a comment</ModalTitle>
        <CommentForm
          autofocus
          artifactId={artifactId}
          meetingId={meetingId}
          meetingGroupId={meetingGroupId}
          onSavedComment={onSavedComment}
        />
      </div>
    </Modal>
  );
};

export default CommentFormDialog;
