import { gql } from "@apollo/client";

export default gql`
  fragment KPIMeasurementTable on KPIMeasurementNode {
    id
    canUpdate {
      permission
      reason
    }
    canDelete {
      permission
      reason
    }
    measurement
    created
    timestamp
  }
  query getKpiMeasurementsQuery(
    $kpiId: Int!
    $first: Int = 20
    $after: String
  ) {
    kpi(kpiId: $kpiId) {
      id
      measurements(first: $first, after: $after, orderBy: "-timestamp") {
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            ...KPIMeasurementTable
          }
        }
      }
    }
  }
`;
