import { gql } from "@apollo/client";

export default gql`
  query getMeetingsByManagerReportQuery(
    $organizationId: Int!
    $personId: Int
    $templateId: Int
    $after: String
    $first: Int
  ) {
    meetingsByManagerReport(
      after: $after
      first: $first
      organizationId: $organizationId
      personId: $personId
      templateId: $templateId
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          reports {
            edges {
              node {
                user {
                  id
                  name
                  email
                  avatar
                }
                lastMeeting {
                  id
                  meeting {
                    id
                    meetingGroupId
                    title
                  }
                  startDatetime
                }
                nextMeeting {
                  id
                  meeting {
                    id
                    meetingGroupId
                    title
                  }
                  startDatetime
                }
              }
            }
          }
          manager {
            id
            name
            avatar
            email
            managers {
              edges {
                node {
                  id
                  name
                  avatar
                  email
                }
              }
            }
          }
        }
      }
    }
    users(
      isActive: true
      hasNoManagerInOrg: $organizationId
      organization: $organizationId
    ) {
      totalCount
    }
  }
`;
