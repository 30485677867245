import { useMemo } from "react";
import { useLocation } from "react-router-dom";

function useUrlQueryParams<T>(defaultValues: T): T {
  const location = useLocation();
  return useMemo(() => {
    const params = new URLSearchParams(location.search);
    const entries = Array.from(params.entries());
    const queryParams = Object.fromEntries(entries);
    return { ...defaultValues, ...queryParams };
  }, [location.search, defaultValues]);
}

export default useUrlQueryParams;
