import { Editor } from "@tiptap/core";
import { Plugin, PluginKey } from "prosemirror-state";
import { ArtifactType } from "types/graphql-schema";
import { v4 as uuidv4 } from "uuid";

import { clientUuidVar, currentUserVar } from "@cache/cache";
import { artifactTypeUrl, tiptapExtensionPriority } from "@helpers/constants";
import {
  isArrowEvent,
  isBackSpaceEvent,
  isCommandCopyEvent,
  isEnterEvent,
} from "@helpers/helpers";

export const artifactInputRegex = {
  [`${ArtifactType.ActionItem}`]: /^\s*(\[([ |x]?)\])\s$/,
  [`${ArtifactType.Decision}`]: new RegExp(`^(=>)\\s$`),
};

const meetingRegexp = `(?:\\/meeting\\/\\d+\\/\\d+)*`;
const fullScreenRegexp = `(?:\\/fullscreen|\\/comment\\/(\\d+)|\\/)*`;

export type RegexpArtifactType =
  | ArtifactType.ActionItem
  | ArtifactType.Decision
  | ArtifactType.Goal
  | ArtifactType.Document
  | ArtifactType.Recognition
  | ArtifactType.Feedback;

export const artifactPasteRegex = {
  [`${ArtifactType.ActionItem}`]: new RegExp(
    `^${window.location.origin}${meetingRegexp}/${
      artifactTypeUrl[ArtifactType.ActionItem]
    }/(\\d+)${fullScreenRegexp}$`,
    ""
  ),
  [`${ArtifactType.Decision}`]: new RegExp(
    `^${window.location.origin}${meetingRegexp}/${
      artifactTypeUrl[ArtifactType.Decision]
    }/(\\d+)${fullScreenRegexp}$`,
    ""
  ),
  [`${ArtifactType.Goal}`]: new RegExp(
    `^${window.location.origin}${meetingRegexp}/${
      artifactTypeUrl[ArtifactType.Goal]
    }/(\\d+)${fullScreenRegexp}$`,
    ""
  ),
  [`${ArtifactType.Document}`]: new RegExp(
    `^${window.location.origin}${meetingRegexp}/${
      artifactTypeUrl[ArtifactType.Document]
    }/(\\d+)${fullScreenRegexp}$`,
    ""
  ),
  [`${ArtifactType.Recognition}`]: new RegExp(
    `^${window.location.origin}${meetingRegexp}/${
      artifactTypeUrl[ArtifactType.Recognition]
    }/(\\d+)${fullScreenRegexp}$`,
    ""
  ),
  [`${ArtifactType.Feedback}`]: new RegExp(
    `^${window.location.origin}${meetingRegexp}/${
      artifactTypeUrl[ArtifactType.Feedback]
    }/(\\d+)${fullScreenRegexp}$`,
    ""
  ),
};
export const artifactParseUrlRegex = {
  [`${ArtifactType.ActionItem}`]: new RegExp(
    `^${window.location.origin}${meetingRegexp}/${
      artifactTypeUrl[ArtifactType.ActionItem]
    }/(\\d+)${fullScreenRegexp}$`,
    ""
  ),
  [`${ArtifactType.Decision}`]: new RegExp(
    `^${window.location.origin}${meetingRegexp}/${
      artifactTypeUrl[ArtifactType.Decision]
    }/(\\d+)${fullScreenRegexp}$`,
    ""
  ),
  [`${ArtifactType.Goal}`]: new RegExp(
    `^${window.location.origin}${meetingRegexp}/${
      artifactTypeUrl[ArtifactType.Goal]
    }/(\\d+)${fullScreenRegexp}$`,
    ""
  ),
  [`${ArtifactType.Document}`]: new RegExp(
    `^${window.location.origin}${meetingRegexp}/${
      artifactTypeUrl[ArtifactType.Document]
    }/(\\d+)${fullScreenRegexp}$`,
    ""
  ),
  [`${ArtifactType.Recognition}`]: new RegExp(
    `^${window.location.origin}${meetingRegexp}/${
      artifactTypeUrl[ArtifactType.Recognition]
    }/(\\d+)${fullScreenRegexp}$`,
    ""
  ),
  [`${ArtifactType.Feedback}`]: new RegExp(
    `^${window.location.origin}${meetingRegexp}/${
      artifactTypeUrl[ArtifactType.Feedback]
    }/(\\d+)${fullScreenRegexp}$`,
    ""
  ),
};

export const baseArtifactOptions = {
  permission: true,
  relatedArtifactId: null,
  organizationId: null,
  relatedTopicId: null,
  meetingId: null,
  meetingGroupId: null,
  createdByUser: null,
};

export const baseArtifactAttributes = (name: RegexpArtifactType) => {
  return {
    id: {
      default: null,
    },
    copyId: {
      default: null,
      parseHTML: (element: any) => {
        // if parsing link
        if (element.tagName.toLocaleLowerCase() === "a") {
          const regexp = artifactParseUrlRegex[name];
          const matches = element.href.match(regexp);
          if (matches && matches[1]) {
            return parseInt(matches[1]);
          }
        } else if (element.id) {
          return parseInt(element.id);
        }
        return null;
      },
    },
    uuid: {
      default: () => uuidv4(),
    },
    clientUuid: {
      default: () => clientUuidVar(),
      rendered: false,
    },
    "data-type": {
      // keep so copy/pasting works
      default: name,
    },
    expandedUserIds: {
      default: "",
    },
    title: {
      default: () => {
        return "";
      },
    },
    // used to show placeholder when a user creates an artifact in CRDT
    createdByUser: {
      default: null,
      parseHTML: (element: any) => {
        if (element.tagName.toLocaleLowerCase() === "a") {
          const url = element.href;
          const matches = url.match(artifactPasteRegex[name]);
          if (matches) {
            const { id, name: userName, avatar } = currentUserVar();
            return { id, name: userName, avatar };
          }
        }
        return null;
      },
    },
    commentId: {
      default: null,
      parseHTML: (element: any) => {
        // if parsing link
        if (element.tagName.toLocaleLowerCase() === "a") {
          const url = element.href;
          const matches = url.match(artifactParseUrlRegex[name]);
          if (url.includes("/comment/") && matches && matches[2]) {
            return parseInt(matches[2]);
          }
        }
        return null;
      },
    },
  };
};

export const baseArtifactParseHtml = (name: RegexpArtifactType) => {
  return [
    {
      tag: `div[data-type="${name}"]`,
      priority: tiptapExtensionPriority.artifact,
    },
    {
      tag: "a",
      priority: tiptapExtensionPriority.artifact,
      getAttrs: (node: any) => {
        const result =
          artifactPasteRegex[name] &&
          !!node.href.match(artifactPasteRegex[name]) &&
          !node.classList.contains("js-wysiwyg-link") && // parse only links not coming from the wysiwyg
          null;
        return result;
      },
    },
  ];
};

export const baseArtifactRenderHtml = (node: any, name: RegexpArtifactType) => {
  const artifactType = artifactTypeUrl[name];
  const id = node.attrs.id;
  return `${window.location.origin}/${artifactType}/${id}`;
};

export function getBaseArtifactPluginKeyDown(editor: Editor, name: string) {
  return new Plugin({
    key: new PluginKey(`${name}-key-down`),
    props: {
      // https://prosemirror.net/docs/ref/#view.EditorProps.handleKeyDown
      handleKeyDown: (editorView, event: any) => {
        // we let user navigate with arrows
        if (editor.isActive(name)) {
          if (isCommandCopyEvent(event)) {
            return false;
          }
          if (isEnterEvent(event)) {
            const { state } = editorView;
            const { selection } = state;
            const { $anchor } = selection;
            const node = (editorView.nodeDOM($anchor.pos) ||
              editorView.domAtPos($anchor.pos).node) as HTMLElement;
            if (node) {
              const element = node.querySelector(".js-artifact-input") as any;
              element.editor.chain().focus("end").run();
            }
            return true;
          }
          if (isBackSpaceEvent(event)) {
            return false;
          }
          if (!isArrowEvent(event)) {
            return true;
          }
        }
        return false;
      },
    },
  });
}

export function getBaseArtifactPluginPaste(self: any) {
  return new Plugin({
    key: new PluginKey(`${self.name}-paste`),
    priority: tiptapExtensionPriority.artifact + 1,
    props: {
      handlePaste: (view, event) => {
        if (!artifactPasteRegex[self.name as RegexpArtifactType]) {
          return false;
        }
        if (!event.clipboardData) {
          return false;
        }
        // don’t create a new code block within code blocks
        if (self.editor.isActive(self.type.name)) {
          return false;
        }
        const text = event.clipboardData.getData("text/plain");
        if (!text) {
          return false;
        }
        const regexpResult =
          artifactPasteRegex[self.name as RegexpArtifactType].exec(text);
        if (!regexpResult) {
          return false;
        }
        const artifactId = regexpResult[1];
        if (
          String(artifactId) ===
          String(self.editor.options.editorProps.relatedArtifactId)
        ) {
          return false;
        }
        // Add extension in text
        const { id, name, avatar } = currentUserVar();
        const { tr } = view.state;
        tr.replaceSelectionWith(
          self.type.create({
            id: artifactId,
            createdByUser: { id, name, avatar },
          })
        );
        view.dispatch(tr);
        return true;
      },
    },
  });
}
