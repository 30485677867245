import { gql } from "@apollo/client";

import { ArtifactActivityFragment } from "@apps/artifact-sidebar/graphql/get-artifact-activities-query";
import CommonGoalArtifactFragment from "@graphql/common-goal-artifact-fragment";
import CommonRecognitionArtifactFragment from "@graphql/common-recognition-artifact-fragment";
import KeyResultFragment from "@graphql/key-result-fragment";

export default gql`
  ${ArtifactActivityFragment}
  ${KeyResultFragment}
  ${CommonGoalArtifactFragment}
  ${CommonRecognitionArtifactFragment}
  fragment WYSIWYGArtifactFragment on ArtifactInterface {
    id
    uuid
    title
    artifactType
    websocketToken
    description
    organization {
      id
    }
    canDelete {
      permission
    }
    canUpdate {
      permission
    }
    canRead {
      permission
    }
    ... on ActionItemArtifactNode {
      isComplete
      actionItemState
      assignee {
        id
        avatar
        name
      }
      dueDate
    }
    ... on FeedbackArtifactNode {
      sender {
        id
        name
      }
    }
    ... on GoalArtifactNode {
      ...CommonGoalArtifactFragment
      currentUserIsOwner
      activities(first: 1, activityType: goal_checkin) {
        edges {
          node {
            ...ArtifactActivityFragment
          }
        }
      }
      # maxKeyResults
      keyResults(first: 20) {
        edges {
          node {
            id
            ...KeyResultFragment
          }
        }
      }
    }
    ... on DecisionArtifactNode {
      decision
      decisionState
      isDraft
    }
    ... on DocumentArtifactNode {
      documentVisibility
    }
    ... on RecognitionArtifactNode {
      ...CommonRecognitionArtifactFragment
    }
    ... on RatingArtifactNode {
      answers {
        totalCount
        edges {
          node {
            id
            integerAnswer
            creator {
              id
            }
          }
        }
      }
      canAnswer
      canViewAnswers
      rating {
        id
        title
        description
        startValue
        endValue
        labels
      }
    }
    ... on KPIArtifactNode {
      measurement {
        id
        kpi {
          id
          title
        }
      }
    }
  }
`;
