import { gql } from "@apollo/client";

const setCurrentUserRelationshipsMutation = gql`
  mutation setRelationshipsMutation(
    $organizationId: Int!
    $relationships: [RelationshipInput]
    $userId: Int!
    $favouriteUserIds: [[Int]!]!
    $invitations: [InvitationInput]!
  ) {
    setRelationships(
      organizationId: $organizationId
      relationships: $relationships
      userId: $userId
    ) {
      me {
        id
      }
    }
    setFavouriteGroups(userIds: $favouriteUserIds) {
      me {
        id
      }
    }
    createInvitations(invitationRequests: $invitations) {
      invitationErrors {
        inviteeEmail
        message
      }
    }
  }
`;

export default setCurrentUserRelationshipsMutation;
