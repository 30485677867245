import { Node, mergeAttributes } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";
import { ArtifactType } from "types/graphql-schema";
import { v4 as uuidv4 } from "uuid";

import { getPluginClickOn } from "@components/wysiwyg/helpers";

import ArtifactComponent from "./artifact-component";
import {
  RegexpArtifactType,
  baseArtifactAttributes,
  baseArtifactOptions,
  baseArtifactParseHtml,
  baseArtifactRenderHtml,
  getBaseArtifactPluginKeyDown,
  getBaseArtifactPluginPaste,
} from "./base-artifact-extension";

export default Node.create({
  group: "block",
  marks: "",
  draggable: true,
  selectable: true,
  allowGapCursor: true,
  atom: true,
  isolating: true,
  defining: true,
  name: ArtifactType.Feedback,

  addOptions() {
    return baseArtifactOptions;
  },

  addAttributes() {
    return baseArtifactAttributes(this.name as RegexpArtifactType);
  },

  parseHTML() {
    return baseArtifactParseHtml(this.name as RegexpArtifactType);
  },

  renderText({ node }: { node: any }) {
    return baseArtifactRenderHtml(node, this.name as RegexpArtifactType);
  },

  renderHTML({ HTMLAttributes }: { HTMLAttributes: any }) {
    return ["div", mergeAttributes(HTMLAttributes)];
  },

  addNodeView() {
    return ReactNodeViewRenderer(ArtifactComponent);
  },

  addProseMirrorPlugins() {
    return [
      getBaseArtifactPluginKeyDown(this.editor, this.name),
      getPluginClickOn(this.name),
      getBaseArtifactPluginPaste(this),
    ];
  },

  addCommands() {
    return {
      insertFeedback:
        (attrs: any) =>
        ({ chain }: { chain: any }) => {
          return chain()
            .insertContent({
              type: this.name,
              attrs: { uuid: uuidv4(), ...attrs },
            })
            .run();
        },
    };
  },
});
