import { gql } from "@apollo/client";

export default gql`
  mutation deleteAssessmentAnswerMutation(
    $assessmentId: Int!
    $questionId: Int!
  ) {
    deleteAssessmentAnswer(
      assessmentId: $assessmentId
      questionId: $questionId
    ) {
      ok
    }
  }
`;
