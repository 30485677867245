import { gql } from "@apollo/client";

export default gql`
  query getGoalOverviewSelectedEntityQuery(
    $teamId: Int!
    $userId: Int!
    $organizationId: Int!
    $hasTeamId: Boolean!
    $hasUserId: Boolean!
    $hasOrganizationId: Boolean!
  ) {
    user(userId: $userId) @include(if: $hasUserId) {
      id
      email
      name
      avatar
    }
    team(teamId: $teamId) @include(if: $hasTeamId) {
      id
      title
    }
    organization(pk: $organizationId) @include(if: $hasOrganizationId) {
      id
      name
    }
  }
`;
