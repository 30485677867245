import { useLazyQuery } from "@apollo/client";
import { useCallback } from "react";

import cache from "@cache/cache";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import usePusher from "@components/use-pusher/use-pusher";

import getPusherMeetingTranscriptionStatusQuery from "../graphql/get-meeting-transcription-status-query";

export default function PusherTranscriptionStatusChange({
  channelName,
}: {
  channelName: string;
}) {
  // Queries & Mutations
  const [getMeetingTranscriptionStatus] = useLazyQuery(
    getPusherMeetingTranscriptionStatusQuery,
    { onError: onNotificationErrorHandler() }
  );

  const handleTranscriptionStatusChange = useCallback(
    ({
      meeting_id,
      transcription_status,
    }: {
      meeting_id: number;
      transcription_status: boolean;
    }) => {
      const meetingCacheId = cache.identify({
        __typename: "MeetingNode",
        id: meeting_id,
      });
      if (meetingCacheId) {
        cache.modify({
          id: meetingCacheId,
          fields: {
            transcriptionStatus() {
              return transcription_status;
            },
          },
        });
        getMeetingTranscriptionStatus({
          variables: { meetingId: meeting_id },
        });
      }
    },
    [getMeetingTranscriptionStatus]
  );

  usePusher(
    channelName,
    "transcription_status_change",
    handleTranscriptionStatusChange
  );

  return null;
}
