import { useQuery } from "@apollo/client";
import { Dialog } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { noop } from "lodash";
import { useLocation } from "react-router-dom";
import { TFLocationState } from "types/topicflow";

import GraphqlError from "@components/error/graphql-error";
import { useLink } from "@components/link/link";
import Loading from "@components/loading/loading";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import ArtifactWYSIWYG from "@components/wysiwyg/artifact-wysiwyg";
import { classNames } from "@helpers/css";
import usePkParams from "@helpers/hooks/use-pk-params";

import getFullscreenArtifactQuery from "./graphql/get-fullscreen-artifact-query";

export const meetingDialogInputClassName =
  "hover:bg-gray-100 rounded text-sm px-2 py-1.5 focus:outline-0 focus:ring-0";

const ArtifactFullscreen = () => {
  const artifactId = usePkParams("artifactId");
  const link = useLink();
  const location = useLocation<TFLocationState>();
  const { data, loading, error } = useQuery(getFullscreenArtifactQuery, {
    variables: { artifactId },
    fetchPolicy: "cache-and-network",
    onError: onNotificationErrorHandler(),
  });

  const handleClose = () => {
    if (location.state?.previousPathname) {
      link.redirect(location.state.previousPathname);
    } else {
      link.redirect("/");
    }
  };

  return (
    <Dialog
      as="div"
      aria-label="Artifact fullscreen dialog"
      className="fixed z-presentation inset-0 bg-white"
      open
      onClose={noop}
    >
      <div className="text-gray-800 flex flex-col h-full">
        <div className="bg-gray-800 text-gray-200 flex items-center justify-between py-2 px-4">
          <div className="flex items-center gap-8">
            <div className="text-gray-100 text-xl">
              {loading ? "Loading" : data?.artifact.title || ""}
            </div>
          </div>
          <button
            onClick={handleClose}
            className="flex items-center gap-2 rounded-full border border-gray-600 text-sm px-3 py-1.5 hover:outline-none hover:bg-gray-700 hover:ring-0 focus:ring-0 focus:outline-none"
          >
            <XIcon className="h-5 w-5 text-gray-400" />
            Close
          </button>
        </div>
        <div className="flex-1 overflow-y-auto min-h-0">
          <div className="h-12 bg-white w-full z-1" />
          <div className="w-full max-w-4xl mx-auto border-t">
            {loading ? (
              <Loading>Loading...</Loading>
            ) : error ? (
              <GraphqlError
                error={error}
                whatDidNotWork="We could not load this page."
              />
            ) : (
              <ArtifactWYSIWYG
                artifact={data.artifact}
                className={classNames(
                  "bg-white/60 pl-5 pr-5 pt-8 -mb-0.5 pb-144 min-h-full",
                  // add padding so the + and drag button are within the editor. Needs to keep some space between buttons and editor so we handle correctly hiding the buttons when mouse leaves editor
                  // https://github.com/Topicflow/topicflow/pull/1314
                  "pl-[54px]"
                )}
                editable
                showFixedMenu={true}
                organizationId={data.artifact.organization?.id}
                showPlusButton={true}
                fixedMenuShowFullScreen={false}
              />
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default ArtifactFullscreen;
