import { gql } from "@apollo/client";

const CommonRecognitionArtifactFragment = gql`
  fragment CommonRecognitionArtifactFragment on RecognitionArtifactNode {
    id
    artifactType
    title
    created
    creator {
      id
      name
      avatar
    }
    recipients {
      totalCount
      edges {
        node {
          id
          name
          avatar
        }
      }
    }
    recognitionCoreValue {
      id
      title
      description
      emoji
      image
    }
  }
`;

export default CommonRecognitionArtifactFragment;
