import { gql } from "@apollo/client";

const AsanaArtifactFragment = gql`
  fragment AsanaArtifactFragment on AsanaTaskArtifactNode {
    id
    title
    isSynced
    completedAt
    dueDate
    assignee {
      id
      name
    }
  }
`;

const getAsanaArtifactQuery = gql`
  ${AsanaArtifactFragment}
  query getAsanaArtifactQuery($id: Int!) {
    artifact(artifactId: $id) {
      ...AsanaArtifactFragment
    }
  }
`;

export default getAsanaArtifactQuery;
