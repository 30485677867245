import { gql } from "@apollo/client";

export default gql`
  mutation createOrUpdateKpiGroupMutation(
    $title: String
    $kpiGroupId: Int
    $organizationId: Int
  ) {
    createOrUpdateKpiGroup(
      organizationId: $organizationId
      kpiGroupId: $kpiGroupId
      title: $title
    ) {
      kpiGroup {
        id
        title
      }
    }
  }
`;
