import { NodeViewContent, NodeViewWrapper } from "@tiptap/react";

const CodeBlock = ({
  node,
  updateAttributes,
  extension,
}: {
  node: any;
  updateAttributes: (attributes: any) => void;
  extension: any;
}) => {
  return (
    <NodeViewWrapper className="relative">
      <select
        contentEditable={false}
        defaultValue={node.attrs.language}
        onChange={(event) => updateAttributes({ language: event.target.value })}
        className="absolute right-1 top-1 text-sm rounded"
      >
        <option value="null">auto</option>
        <option disabled>—</option>
        {extension.options.lowlight
          .listLanguages()
          .map((lang: any, index: number) => (
            <option key={index} value={lang}>
              {lang}
            </option>
          ))}
      </select>
      <pre>
        <NodeViewContent as="code" />
      </pre>
    </NodeViewWrapper>
  );
};

export default CodeBlock;
