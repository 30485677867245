import { gql } from "@apollo/client";

export const DashboardPeopleTableUserMeetingFragment = gql`
  fragment DashboardPeopleTableUserMeetingFragment on MeetingNode {
    id
    meetingGroupId
    startDatetime
  }
`;

export const DashboardPeopleTableUserFragment = gql`
  fragment DashboardPeopleTableUserFragment on UserNode {
    id
    name
    firstName
    email
    avatar
    goals: ownedGoals(goalState: open, first: $peopleTableGoalMax) {
      totalCount
      edges {
        node {
          id
          ... on GoalArtifactNode {
            title
            artifactType
            state
            isStale
            status
            canUpdate {
              permission
            }
            progress
            startValue
            currentValue
            targetValue
            progressType
          }
        }
      }
    }
    recognitions: artifacts(
      artifactType: recognition
      last: 1
      orderBy: "created"
    ) {
      edges {
        node {
          id
          ... on RecognitionArtifactNode {
            artifactType
            created
          }
        }
      }
    }
  }
`;

export default gql`
  ${DashboardPeopleTableUserFragment}
  ${DashboardPeopleTableUserMeetingFragment}
  query getPeopleTableQuery(
    $userId: Int!
    $now: DateTime!
    $peopleTableGoalMax: Int!
  ) {
    user(userId: $userId) {
      id
      directReports(isActiveOrInvited: true) {
        edges {
          node {
            ...DashboardPeopleTableUserFragment
            meetings(
              isFormalOneonone: true
              participants: [$userId]
              startDatetime_Gte: $now
              first: 1
              orderBy: "startDatetime"
            ) {
              edges {
                node {
                  ...DashboardPeopleTableUserMeetingFragment
                }
              }
            }
          }
        }
      }
      managers(isActiveOrInvited: true) {
        edges {
          node {
            ...DashboardPeopleTableUserFragment
            directReports(isActiveOrInvited: true) {
              edges {
                node {
                  ...DashboardPeopleTableUserFragment
                  meetings(
                    isFormalOneonone: false
                    participants: [$userId]
                    startDatetime_Gte: $now
                    first: 1
                    orderBy: "startDatetime"
                  ) {
                    edges {
                      node {
                        ...DashboardPeopleTableUserMeetingFragment
                      }
                    }
                  }
                }
              }
            }
            meetings(
              isFormalOneonone: true
              participants: [$userId]
              startDatetime_Gte: $now
              first: 1
              orderBy: "startDatetime"
            ) {
              edges {
                node {
                  ...DashboardPeopleTableUserMeetingFragment
                }
              }
            }
          }
        }
      }
      favouritesGroups {
        edges {
          node {
            id
            users(isActiveOrInvited: true) {
              edges {
                node {
                  ...DashboardPeopleTableUserFragment
                  meetings(
                    isFormalOneonone: false
                    participants: [$userId]
                    startDatetime_Gte: $now
                    orderBy: "startDatetime"
                    first: 1
                  ) {
                    edges {
                      node {
                        ...DashboardPeopleTableUserMeetingFragment
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
