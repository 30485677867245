import { isEqual } from "lodash";
import { useState } from "react";

import {
  ExplorerFilterType,
  getDefaultFilters,
  getTitleForExplorerFilters,
} from "@apps/explorer/helpers";
import Tooltip from "@components/tooltip/tooltip";
import { classNames } from "@helpers/css";

import ExplorerBookmarkEditDialog from "./bookmark-edit-dialog";
import {
  explorerSelectClassName,
  explorerSelectDisabledClassName,
} from "./filters";

const ExplorerSaveBookmarkButton = ({
  filters,
}: {
  filters: ExplorerFilterType;
}) => {
  const [isShowingDialog, setIsShowingDialog] = useState(false);
  const isDefaultFilters = isEqual(filters, getDefaultFilters());

  const handleCloseDialog = () => {
    setIsShowingDialog(false);
  };

  return (
    <div>
      {isShowingDialog && (
        <ExplorerBookmarkEditDialog
          bookmark={{
            title: getTitleForExplorerFilters(filters),
            private: true,
            filters,
          }}
          onClose={handleCloseDialog}
        />
      )}
      <Tooltip
        text={
          isDefaultFilters ? "Select filters in order to create a bookmark" : ""
        }
      >
        <button
          className={classNames(
            explorerSelectClassName,
            "pr-2",
            isDefaultFilters && explorerSelectDisabledClassName
          )}
          onClick={() => setIsShowingDialog(true)}
          disabled={isDefaultFilters}
        >
          Save bookmark
        </button>
      </Tooltip>
    </div>
  );
};

export default ExplorerSaveBookmarkButton;
