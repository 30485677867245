import { gql } from "@apollo/client";

import { MeetingDialogFragment } from "./meeting-dialog-fragment";

export default gql`
  ${MeetingDialogFragment}
  mutation createOrUpdateMeetingInExternalCalendarMutation(
    $attendees: [AttendeeInput]!
    $onlineConference: OnlineConference!
    $editType: MeetingEditTypeEnum!
    $credentialsId: Int
    $description: String
    $startDatetime: DateTime
    $endDatetime: DateTime
    $recurrence: FrequencyInput
    $startTimezone: String
    $endTimezone: String
    $meetingId: Int
    $organizationId: Int
    $publish: Boolean!
    $title: String!
    $isFormalOneonone: Boolean!
    $facilitatorId: Int
    $templateId: Int
  ) {
    createOrUpdateMeetingInExternalCalendar(
      attendees: $attendees
      editType: $editType
      onlineConference: $onlineConference
      credentialsId: $credentialsId
      description: $description
      endDatetime: $endDatetime
      endTimezone: $endTimezone
      meetingId: $meetingId
      organizationId: $organizationId
      publish: $publish
      recurrence: $recurrence
      startDatetime: $startDatetime
      startTimezone: $startTimezone
      title: $title
      isFormalOneonone: $isFormalOneonone
      facilitatorId: $facilitatorId
      templateId: $templateId
    ) {
      meeting {
        ...MeetingDialogFragment
      }
    }
  }
`;
