import { InputRule, callOrReturn, selectionToInsertionEnd } from "@tiptap/core";

const artifactInputRule = ({
  find,
  type,
  getAttributes,
}: {
  find: any;
  type: any;
  getAttributes: () => any;
}) => {
  return new InputRule({
    find,
    handler: ({ state, range, match }) => {
      const $start = state.doc.resolve(range.from);
      const attributes = callOrReturn(getAttributes, undefined, match) || {};

      // if cursor is not at root of document, we stop here and don't show extension
      // if ($start.depth > 1) {
      //   return null;
      // }

      // if current node cannot be converted to extension, we don't proceed
      if (
        !$start
          .node(-1)
          .canReplaceWith($start.index(-1), $start.indexAfter(-1), type)
      ) {
        return null;
      }

      // Delete shortcut key and add extension
      state.tr.delete(range.from, range.to);
      state.tr.replaceSelectionWith(type.create(attributes), false);
      selectionToInsertionEnd(state.tr, state.tr.steps.length - 1, -1);
    },
  });
};

export default artifactInputRule;
