import { gql } from "@apollo/client";

import CommonFeedbackArtifactFragment from "@graphql/common-feedback-artifact-fragment";

export default gql`
  ${CommonFeedbackArtifactFragment}
  query getFeedbackPageQuery(
    $pendingRequestRecipientId: Int
    $pendingRequestRecipientsOfManagerId: Int
    $fetchPendingRequest: Boolean!
    $organizationId: Int!
    $feedbackRecipient: Int
    $feedbackRecipients: [Int]
    $feedbackSender: Int
    $feedbackState: FeedbackState
    $limit: Int!
    $after: String
  ) {
    artifacts(
      artifactType: feedback
      orderBy: "-created"
      first: $limit
      after: $after
      organizationId: $organizationId
      feedbackRecipient: $feedbackRecipient
      feedbackRecipients: $feedbackRecipients
      feedbackSender: $feedbackSender
      feedbackState: $feedbackState
    ) @skip(if: $fetchPendingRequest) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          ... on FeedbackArtifactNode {
            ...CommonFeedbackArtifactFragment
          }
        }
      }
    }
    pendingFeedbackRequests(
      recipientId: $pendingRequestRecipientId
      recipientsOfManagerId: $pendingRequestRecipientsOfManagerId
    ) @include(if: $fetchPendingRequest) {
      edges {
        node {
          id
          title
          created
          sender {
            id
            name
            avatar
          }
          creator {
            id
            name
            avatar
          }
          feedbackState
          recipients {
            edges {
              node {
                id
                name
                avatar
              }
            }
          }
        }
      }
    }
  }
`;
