import { compact } from "lodash";
import {
  ArtifactActivityFragmentFragment,
  KeyResultNode,
} from "types/graphql-schema";

import ActivityChangedField from "./changed-field";

const ActivityArtifactKeyResultUpdated = ({
  activity,
  artifactId,
}: {
  artifactId: number;
  activity: ArtifactActivityFragmentFragment;
}) => {
  if (!activity?.changedFields?.[0]?.newValueObject) {
    return null;
  }

  const changedFields = activity.changedFields.map((changedField) => {
    if (
      changedField?.fieldName === "key_result" &&
      changedField?.newValueObject
    ) {
      const keyResult = changedField.newValueObject as KeyResultNode;
      const keyResultChangedFields = compact(
        (changedField?.changedFields || []).map((changedField) => (
          <ActivityChangedField
            key={changedField?.fieldName}
            changedField={changedField}
            artifactId={artifactId}
          />
        ))
      );
      if (keyResultChangedFields.length > 0) {
        return <div key={keyResult.id}>{keyResultChangedFields}</div>;
      }
    }
    return null;
  });
  return <div className="flex flex-col gap-2">{compact(changedFields)}</div>;
};

export default ActivityArtifactKeyResultUpdated;
