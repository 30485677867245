import { XIcon } from "@heroicons/react/outline";
import { delay } from "lodash";
import { ChangeEvent, useEffect, useRef } from "react";

import { classNames } from "@helpers/css";

import { ExplorerFilterType } from "../helpers";
import { explorerSelectClassName } from "./filters";

const ExplorerSearchInput = ({
  filters,
  onChangeFilters,
}: {
  filters: ExplorerFilterType;
  onChangeFilters: (filters: ExplorerFilterType) => void;
}) => {
  const ref = useRef<HTMLInputElement | null>(null);

  const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    onChangeFilters({ search: e.target.value });
  };
  const handleClearSearch = () => {
    onChangeFilters({ search: "" });
  };

  useEffect(() => {
    delay(() => {
      ref?.current?.focus();
    }, 300);
  }, []);

  // RENDER
  return (
    <div className="flex relative">
      {(filters.search || "").trim() !== "" && (
        <button
          className="absolute right-2 top-1.5 p-0.5 rounded hover:bg-gray-100"
          onClick={handleClearSearch}
        >
          <XIcon className="w-4 h-4 text-gray-500" />
        </button>
      )}
      <input
        className={classNames(explorerSelectClassName, "w-full block pr-7")}
        value={filters.search}
        autoFocus
        ref={ref}
        placeholder={`Search...`}
        aria-label="Explorer search input"
        onChange={handleChangeSearch}
      />
    </div>
  );
};

export default ExplorerSearchInput;
