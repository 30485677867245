import { gql } from "@apollo/client";

import CommonFeedbackArtifactFragment from "@graphql/common-feedback-artifact-fragment";
import CommonRecognitionArtifactFragment from "@graphql/common-recognition-artifact-fragment";

import { GoalArtifactSidebarFragment } from "./get-artifact-sidebar-query";

export default gql`
  ${GoalArtifactSidebarFragment}
  ${CommonRecognitionArtifactFragment}
  ${CommonFeedbackArtifactFragment}
  mutation updateSidebarArtifactMutation(
    $artifactId: Int!
    $title: String
    $additionalFields: AdditionalArtifactInput
    $keyResultLimit: Int = 20
  ) {
    createOrUpdateArtifact(
      artifactId: $artifactId
      title: $title
      additionalFields: $additionalFields
    ) {
      artifact {
        id
        title
        artifactType
        canDelete {
          permission
        }
        ... on ActionItemArtifactNode {
          isComplete
          dueDate
          assignee {
            id
            avatar
            name
          }
        }
        ...GoalArtifactSidebarFragment
        ... on DecisionArtifactNode {
          decision
          decisionState
          isDraft
        }
        ... on RecognitionArtifactNode {
          ...CommonRecognitionArtifactFragment
        }
        ... on FeedbackArtifactNode {
          ...CommonFeedbackArtifactFragment
        }
      }
    }
  }
`;
