import { useLazyQuery } from "@apollo/client";
import { useCallback } from "react";

import cache, { currentUserVar } from "@cache/cache";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import usePusher from "@components/use-pusher/use-pusher";

import getMeetingGroupOneononeStatus from "../graphql/get-meeting-group-oneonone-status";

export default function PusherMeetingGroupOneononeStatusChange({
  channelName,
}: {
  channelName: string;
}) {
  const currentUser = currentUserVar();

  const [fetchMeetingGroup] = useLazyQuery(getMeetingGroupOneononeStatus, {
    onError: onNotificationErrorHandler(),
  });

  const handleMeetingGroupOneononeStatusChange = useCallback(
    ({ meeting_group_id }: { meeting_group_id: number }) => {
      const meetingGroupCacheId = cache.identify({
        id: meeting_group_id,
        __typename: "MeetingGroupNode",
      });
      if (meetingGroupCacheId) {
        fetchMeetingGroup({ variables: { meetingGroupId: meeting_group_id } });
      }
    },
    [fetchMeetingGroup, currentUser]
  );

  usePusher(
    channelName,
    "meeting_group_oneonone_status_change",
    handleMeetingGroupOneononeStatusChange
  );
  return null;
}
