import { useEffect } from "react";

import { pusher } from "@helpers/pusher";

/**
 *
 * @param {*} eventChannelName
 * @param {*} event
 * @param {*} callback Make sure to use react hook useCallback to prevent multiple cleanup
 */
function usePusher(
  eventChannelName: string,
  event: string,
  callback: (data: any) => void
) {
  useEffect(() => {
    if (eventChannelName && event && pusher) {
      const channel = pusher.subscribe(eventChannelName);
      channel.bind(event, callback);

      return function cleanup() {
        channel.unbind(event, callback);
        channel.unsubscribe();
      };
    }
  }, [eventChannelName, event, callback]);
}

export default usePusher;
