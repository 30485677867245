import { gql } from "@apollo/client";

export default gql`
  mutation setAgendaAsTemplate($meetingId: Int!) {
    setMeetingAgendaAsTemplate(meetingId: $meetingId) {
      meeting {
        id
      }
    }
  }
`;
