import { gql } from "@apollo/client";

import MyWorkHistoryItemFragment from "./my-work-history-item-fragment";

export default gql`
  ${MyWorkHistoryItemFragment}
  query getMyWorkHistory(
    $userId: Int!
    $organizationId: Int!
    $start: Date!
    $end: Date!
  ) {
    workHistory(
      organizationId: $organizationId
      userId: $userId
      start: $start
      end: $end
    ) {
      ...MyWorkHistoryItemFragment
    }
  }
`;
