import { useQuery } from "@apollo/client";
import { RefreshIcon, XIcon } from "@heroicons/react/outline";
import { Node, mergeAttributes } from "@tiptap/core";
import { NodeViewWrapper, ReactNodeViewRenderer } from "@tiptap/react";
import { useState } from "react";
import {
  GetUrlPreviewQuery,
  GetUrlPreviewQueryVariables,
} from "types/graphql-schema";

import { ExplorerResultPreview } from "@apps/explorer/components/explorer-result";
import AppLink from "@components/link/link";
import Loading from "@components/loading/loading";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { regexp, tiptapExtensionPriority } from "@helpers/constants";
import { classNames } from "@helpers/css";
import { isValidUrl } from "@helpers/helpers";

import getUrlPreviewQuery from "../graphql/get-url-preview";
import { getPluginClickOn, getPluginHandlePaste } from "../helpers";

const UrlPreviewComponent = ({
  node,
  selected,
  deleteNode,
}: {
  node: any;
  selected: boolean;
  deleteNode: () => void;
}) => {
  const url = node.attrs.url;
  const isGithub = regexp.githubIssueUrl.exec(url);
  const isJira = regexp.jiraIssueUrl.exec(url);
  const isClickup = regexp.clickupTaskUrl.exec(url);
  const isSalesforce = regexp.salesforceOpportunityUrl.exec(url);
  const isHubspot = regexp.hubspotDealUrl.exec(url);
  const [forceRefresh, setForceRefresh] = useState(false);

  const { data, error, refetch, loading } = useQuery<
    GetUrlPreviewQuery,
    GetUrlPreviewQueryVariables
  >(getUrlPreviewQuery, {
    variables: { url, forceRefresh },
    skip: !url,
    onError: onNotificationErrorHandler(),
  });
  const issue = data?.urlToInfoCard;

  const handleClickRefresh = () => {
    setForceRefresh(true);
    refetch();
  };

  return (
    <NodeViewWrapper className="w-full mb-3 not-prose">
      <div
        className={classNames(
          "flex items-start text-sm relative border rounded-lg py-1.5 px-3 no-underline bg-gray-50 gap-2",
          selected && "ring-2 ring-blue-200 ring-offset-2 extension-is-selected"
        )}
        contentEditable={false}
      >
        {issue ? (
          <ExplorerResultPreview result={issue} />
        ) : (
          <div className="flex-1 flex gap-2 items-center truncate min-w-0 tracking-tight">
            {loading && <Loading mini size="4" />}
            <div>
              <a
                href={url}
                target="_blank"
                rel="noreferrer"
                className="hover:underline font-medium"
              >
                {url}
              </a>
              {error &&
                isGithub &&
                String(error).toLowerCase().includes("github not setup") && (
                  <AppLink
                    to="/account/integrations"
                    className="ml-2 text-blue-link hover:underline text-xs"
                  >
                    Setup Github preview
                  </AppLink>
                )}
              {error &&
                isJira &&
                String(error).toLowerCase().includes("jira not setup") && (
                  <AppLink
                    to="/account/integrations"
                    className="ml-2 text-blue-link hover:underline text-xs"
                  >
                    Setup Jira preview
                  </AppLink>
                )}
              {error &&
                isClickup &&
                String(error).toLowerCase().includes("clickup not setup") && (
                  <AppLink
                    to="/account/integrations"
                    className="ml-2 text-blue-link hover:underline text-xs"
                  >
                    Setup ClickUp preview
                  </AppLink>
                )}
              {error &&
                isSalesforce &&
                String(error)
                  .toLowerCase()
                  .includes("salesforce not setup") && (
                  <AppLink
                    to="/account/integrations"
                    className="ml-2 text-blue-link hover:underline text-xs"
                  >
                    Setup Salesforce preview
                  </AppLink>
                )}
              {error &&
                isHubspot &&
                String(error).toLowerCase().includes("hubspot not setup") && (
                  <AppLink
                    to="/account/integrations"
                    className="ml-2 text-blue-link hover:underline text-xs"
                  >
                    Setup Hubspot preview
                  </AppLink>
                )}
            </div>
          </div>
        )}
        {issue && (
          <button onClick={handleClickRefresh}>
            <RefreshIcon className="h-5 w-5 text-gray-400 hover:text-gray-800" />
          </button>
        )}
        <button
          className="shrink-0 p-px rounded text-gray-400 hover:text-gray-700"
          onClick={deleteNode}
        >
          <XIcon className="h-5 w-5" />
        </button>
      </div>
    </NodeViewWrapper>
  );
};

export const isValidForUrlPreview = (url: string) => {
  return (
    !!regexp.clickupTaskUrl.exec(url) ||
    !!regexp.salesforceOpportunityUrl.exec(url) ||
    !!regexp.hubspotDealUrl.exec(url) ||
    !!regexp.githubIssueUrl.exec(url) ||
    !!regexp.jiraIssueUrl.exec(url)
  );
};

const UrlPreviewExtension = Node.create({
  name: "url-preview",
  group: "block",
  marks: "",
  draggable: true,
  selectable: true,
  allowGapCursor: true,
  atom: true,
  isolating: true,
  defining: true,

  addAttributes() {
    return {
      url: {
        default: null,
        parseHTML: (node) => {
          if (node.dataset.url && isValidForUrlPreview(node.dataset.url)) {
            return node.dataset.url;
          }
          return null;
        },
        renderHTML: (attributes) => {
          return {
            "data-url": attributes.url,
          };
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "div",
        priority: tiptapExtensionPriority.link,
        getAttrs: (node: HTMLElement | string) => {
          if (
            node &&
            node instanceof HTMLDivElement &&
            node.dataset?.url &&
            isValidForUrlPreview(node?.dataset?.url)
          ) {
            return null;
          }
          return false;
        },
      },
    ];
  },

  addNodeView() {
    return ReactNodeViewRenderer(UrlPreviewComponent);
  },

  renderHTML({ HTMLAttributes }) {
    return ["div", mergeAttributes(HTMLAttributes)];
  },

  addCommands() {
    return {
      previewUrl:
        (attrs: any) =>
        ({ chain }: { chain: any }) => {
          return chain().insertContent({ type: "url-preview", attrs }).run();
        },
    };
  },

  addProseMirrorPlugins() {
    return [
      getPluginClickOn(this.name),
      getPluginHandlePaste(this, (url, view) => {
        if (!url || !isValidUrl(url)) {
          return false;
        }
        if (isValidForUrlPreview(url)) {
          const { tr } = view.state;
          tr.replaceSelectionWith(this.type.create({ url }));
          view.dispatch(tr);
          return true;
        }
        return false;
      }),
    ];
  },
});

export default UrlPreviewExtension;
