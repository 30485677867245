import { gql } from "@apollo/client";

export default gql`
  mutation exportExplorerToCsv($search: String!, $options: SearchOptions) {
    searchCsvExport(searchTerm: $search, options: $options) {
      user {
        email
      }
    }
  }
`;
